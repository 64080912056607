import * as React from 'react';

import * as Styled from './rate-dropdown.styles';
import { getTeacherRatesInfo, TEACHER_RATES } from '@utils/constants';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DownArrow from '@components/Icons/down_dropdown.svg';

interface Props {
  chosenOption: TEACHER_RATES;
  setOption: (role: TEACHER_RATES) => void;
}

const RateDropDown = ({ chosenOption, setOption }: Props) => {
  const [open, setOpen] = React.useState(false);

  const [rateInfo, setRateInfo] = React.useState(() => {
    return getTeacherRatesInfo(chosenOption);
  });

  React.useEffect(() => {
    setRateInfo(getTeacherRatesInfo(chosenOption));
  }, [chosenOption]);

  const handleOptionClick = (role: TEACHER_RATES) => {
    setOption(role);
    setOpen(!open);
  };

  const getRateTitle = (rate: TEACHER_RATES) => {
    const rateTitle = getTeacherRatesInfo(rate);
    return rateTitle.title;
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styled.RateDropdownContainer>
        <Styled.ChosenOption onClick={() => setOpen(!open)}>
          {rateInfo.title}
          <img src={DownArrow} alt='' />
        </Styled.ChosenOption>
        {open && (
          <Styled.Options>
            {Object.keys(TEACHER_RATES).map((key: string) => (
              <Styled.OptionItem key={key} onClick={() => handleOptionClick(TEACHER_RATES[key])}>
                {getRateTitle(TEACHER_RATES[key])}
              </Styled.OptionItem>
            ))}
          </Styled.Options>
        )}
      </Styled.RateDropdownContainer>
    </ClickAwayListener>
  );
};

export default RateDropDown;
