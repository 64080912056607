import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Editor, EditorState } from 'draft-js'
import Spinner from 'react-spinkit'
import { HtmlTooltip } from '@components/HtmlTooltip'
import { AddRowIcon, RemoveRowIcon } from '@assets/icons'
import Move from '@assets/images/blocks/move'
import Copy from '@assets/images/blocks/copy'
import Delete from '@assets/images/blocks/delete'

import { EditorActions } from './EditorActions'
import * as Styled from './TableBlock.styles'
import { paddingDiff, insertPreloadedState, convertTableForSave, createEmptyCell } from './helpers'
import { IState, Nullable, CellContent, TableBlockProps, AlignType } from './types'
import { colorStyleMap } from './constants'
import LoaderChecked from '../../../assets/icons/checked.svg'

const TableBlock: FC<TableBlockProps> = ({
  onStateChange,
  id,
  preloadedState,
  onClickDelete,
  onClickCopy,
  dragRef,
  preview,
  opacity,
}) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const pageX = useRef<Nullable<number>>()
  const curCol = useRef<Nullable<HTMLTableRowElement>>()
  const nxtCol = useRef<Nullable<HTMLTableRowElement>>()
  const curColWidth = useRef<Nullable<number>>()
  const nxtColWidth = useRef<Nullable<number>>()

  const tableHeight = tableRef.current?.offsetHeight

  const [currentEditedCell, setCurrentEditedCell] = useState<{
    rowIndex: number
    tdIndex: number
  }>(null)
  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)
  const [state, setState] = useState<IState<EditorState>>(
    preloadedState
      ? insertPreloadedState(preloadedState)
      : {
          table: {
            '0': [createEmptyCell(), createEmptyCell()],
            '1': [createEmptyCell(), createEmptyCell()],
          },
          colWidths: [50, 50],
          hideBorder: false,
        }
  )

  const isInitiallyConfigured = useRef<boolean>(false)

  const recalculateColWidth = useCallback(
    (updatedState: IState<EditorState>) => {
      const cols = tableRef.current.getElementsByTagName('tr')[0]?.children
      Array.from(cols).map(
        (c, tdIndex) =>
          ((c as HTMLTableColElement).style.width = `${(tableRef.current?.offsetWidth *
            updatedState.colWidths[tdIndex]) /
            100}px`)
      )
    },
    [tableRef]
  )

  useEffect(() => {
    if (!tableRef.current || isInitiallyConfigured.current) return
    const cols = tableRef.current.getElementsByTagName('tr')[0]?.children
    Array.from(cols).map(
      (c, tdIndex) =>
        ((c as HTMLTableColElement).style.width = `${(tableRef.current?.offsetWidth *
          state.colWidths[tdIndex]) /
          100}px`)
    )
    isInitiallyConfigured.current = true
  }, [tableRef, isInitiallyConfigured])

  const onMouseDown = useCallback(e => {
    curCol.current = (e.target as HTMLDivElement).parentElement as HTMLTableRowElement
    if (!curCol.current) return
    nxtCol.current = curCol.current.nextElementSibling as HTMLTableRowElement
    pageX.current = e.pageX
    let padding = paddingDiff(curCol.current)
    curColWidth.current = curCol.current.offsetWidth - padding
    if (nxtCol.current) nxtColWidth.current = nxtCol.current.offsetWidth - padding
  }, [])

  const onMouseMove = useCallback(e => {
    if (!curCol.current || !pageX.current || !nxtColWidth.current || !curColWidth.current) return
    const diffX = e.pageX - pageX.current
    if (nxtCol.current) nxtCol.current.style.width = nxtColWidth.current - diffX + 'px'
    curCol.current.style.width = curColWidth.current + diffX + 'px'
  }, [])

  const onMouseUp = useCallback(() => {
    if (curCol.current) {
      const tableWidth = tableRef.current?.offsetWidth
      const cols = tableRef.current?.getElementsByTagName('tr')[0]?.children
      const colWidths = Array.from(cols).map(c =>
        Number((((c as HTMLTableCellElement).offsetWidth / tableWidth) * 100).toFixed(4))
      )
      onStateChange(id, {
        table: convertTableForSave(state.table),
        colWidths,
        hideBorder: state.hideBorder,
      })
      setState({ table: state.table, colWidths, hideBorder: state.hideBorder })
    }

    curCol.current = null
    nxtCol.current = null
    pageX.current = null
    nxtColWidth.current = null
    curColWidth.current = null
  }, [onStateChange, id, state])

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })

  const handleChange = useCallback(
    (rowIndex: number, colIndex: number) => (editor: EditorState) => {
      setState(s => {
        const table: IState<EditorState>['table'] = {
          ...s.table,
          [rowIndex]: s.table[rowIndex].map((r, idx) =>
            idx === colIndex
              ? {
                  ...r,
                  rowContent: editor,
                }
              : r
          ),
        }
        onStateChange(id, {
          hideBorder: s.hideBorder,
          colWidths: s.colWidths,
          table: convertTableForSave(table),
        })
        return { hideBorder: s.hideBorder, colWidths: s.colWidths, table }
      })
    },
    [id, setState, onStateChange]
  )

  const handleOnStateChange = useCallback(
    (updatedState: CellContent<EditorState>) => {
      setState(s => {
        const table: IState<EditorState>['table'] = {
          ...s.table,
          [currentEditedCell.rowIndex]: s.table[currentEditedCell.rowIndex].map((r, idx) =>
            idx === currentEditedCell.tdIndex ? updatedState : r
          ),
        }
        onStateChange(id, {
          hideBorder: s.hideBorder,
          colWidths: s.colWidths,
          table: convertTableForSave(table),
        })
        return { hideBorder: s.hideBorder, colWidths: s.colWidths, table }
      })
    },
    [currentEditedCell, handleChange, id, setState, onStateChange]
  )

  const onBorderToggle = useCallback(() => {
    onStateChange(id, {
      hideBorder: !state.hideBorder,
      colWidths: state.colWidths,
      table: convertTableForSave(state.table),
    })
    setState({
      hideBorder: !state.hideBorder,
      colWidths: state.colWidths,
      table: state.table,
    })
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }, [id, onStateChange, state])

  const addNewCol = useCallback(() => {
    const colsLength = state.colWidths.length
    const tableWidth = tableRef.current?.offsetWidth
    const newColWidth = (tableWidth / (colsLength + 1) / tableWidth) * 100
    const colWidths = state.colWidths.map(w =>
      Number((Number(w) - newColWidth / colsLength).toFixed(4))
    )
    const tableKeys = Object.keys(state.table)
    const updatedTable: IState<EditorState>['table'] = tableKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: [...state.table[key], createEmptyCell()],
      }),
      {}
    )
    const updatedState: IState<EditorState> = {
      table: updatedTable,
      colWidths: [...colWidths, Number(newColWidth.toFixed(4))],
      hideBorder: state.hideBorder,
    }
    onStateChange(id, {
      table: convertTableForSave(updatedTable),
      colWidths: updatedState.colWidths,
      hideBorder: updatedState.hideBorder,
    })
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
    setState(updatedState)
    recalculateColWidth(updatedState)
  }, [id, state, onStateChange])

  const removeLastCol = useCallback(() => {
    const colsLength = state.colWidths.length
    const lastColWidth = state.colWidths[colsLength - 1]
    const colWidths = state.colWidths
      .slice(0, colsLength - 1)
      .map(w => Number((Number(w) + lastColWidth / (colsLength - 1)).toFixed(4)))
    const tableKeys = Object.keys(state.table)
    const updatedTable: IState<EditorState>['table'] = tableKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: state.table[key].slice(0, state.table[key].length - 1),
      }),
      {}
    )
    const updatedState: IState<EditorState> = {
      table: updatedTable,
      colWidths,
      hideBorder: state.hideBorder,
    }
    onStateChange(id, {
      table: convertTableForSave(updatedTable),
      colWidths: updatedState.colWidths,
      hideBorder: updatedState.hideBorder,
    })
    setState(updatedState)
    recalculateColWidth(updatedState)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }, [id, state, onStateChange])

  const addNewRow = useCallback(() => {
    const colsLength = state.colWidths.length
    const tableKeysLength = Object.keys(state.table).length
    const updatedTable: IState<EditorState>['table'] = {
      ...state.table,
      [tableKeysLength]: Array(colsLength)
        .fill('')
        .map(() => createEmptyCell()),
    }
    const updatedState: IState<EditorState> = {
      table: updatedTable,
      colWidths: state.colWidths,
      hideBorder: state.hideBorder,
    }
    onStateChange(id, {
      table: convertTableForSave(updatedTable),
      colWidths: updatedState.colWidths,
      hideBorder: updatedState.hideBorder,
    })
    setState(updatedState)
  }, [id, state, onStateChange])

  const removeLastRow = useCallback(() => {
    const tableKeys = Object.keys(state.table)
    const tableKeysLength = tableKeys.length - 1
    const updatedTable: IState<EditorState>['table'] = tableKeys.reduce(
      (acc, key) =>
        Number(key) === tableKeysLength
          ? acc
          : {
              ...acc,
              [key]: state.table[key],
            },
      {}
    )
    const updatedState: IState<EditorState> = {
      table: updatedTable,
      colWidths: state.colWidths,
      hideBorder: state.hideBorder,
    }
    onStateChange(id, {
      table: convertTableForSave(updatedTable),
      colWidths: updatedState.colWidths,
      hideBorder: updatedState.hideBorder,
    })
    setState(updatedState)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }, [id, state, onStateChange])

  const tableAlignType = useMemo<AlignType>(() => {
    const isCenter = Object.keys(state.table).every(rowKey =>
      state.table[rowKey].every(({ align }) => align === 'center')
    )
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
    if (isCenter) return 'center'
    const isLeft = Object.keys(state.table).every(rowKey =>
      state.table[rowKey].every(({ align }) => align === 'left')
    )
    if (isLeft) return 'left'
    return null
  }, [state])

  const handleToggleAllAlign = useCallback(
    (alignType: AlignType) => {
      const tableKeys = Object.keys(state.table)
      const updatedTable: IState<EditorState>['table'] = tableKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: state.table[key].map(cell => ({
            ...cell,
            align: alignType,
          })),
        }),
        {}
      )
      const updatedState: IState<EditorState> = {
        table: updatedTable,
        colWidths: state.colWidths,
        hideBorder: state.hideBorder,
      }
      onStateChange(id, {
        table: convertTableForSave(updatedTable),
        colWidths: updatedState.colWidths,
        hideBorder: updatedState.hideBorder,
      })
      setState(updatedState)
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setShowCheckmark(true)
        setTimeout(() => {
          setShowCheckmark(false)
        }, 2000)
      }, 3000)
    },
    [id, state, onStateChange]
  )

  return (
    <div style={{ opacity }} ref={preview}>
      <div className="block">
        <Styled.ActionsContainer className="actions-container">
          <div ref={dragRef} className="left-side-actions">
            <img src={Move} alt="move" />
          </div>
          <div className="right-side-actions">
            <EditorActions
              editorState={state.table[currentEditedCell?.rowIndex]?.[currentEditedCell?.tdIndex]}
              onStateChange={handleOnStateChange}
              toggleBorder={onBorderToggle}
              isHiddenBorder={state.hideBorder}
              toggleAlignAll={handleToggleAllAlign}
              tableAlignType={tableAlignType}
            />
            <img src={Copy} onClick={onClickCopy} alt="Copy" />
            <img src={Delete} onClick={onClickDelete} alt="Delete" />
          </div>
        </Styled.ActionsContainer>
        <div className="content-container">
          <Styled.TableWrapper>
            <Styled.Table ref={tableRef}>
              <tbody>
                {Object.values(state.table).map((row, rowIndex) => (
                  <Styled.Row key={rowIndex} hideBorder={state.hideBorder}>
                    {row.map((td, tdIndex) => (
                      <Styled.Cell
                        key={tdIndex}
                        selectedBackground={td.bgColor}
                        selectedAlign={td.align}
                        hideBorder={state.hideBorder}
                      >
                        <Editor
                          customStyleMap={colorStyleMap}
                          editorState={td.rowContent}
                          onChange={handleChange(rowIndex, tdIndex)}
                          onBlur={() => {
                            setCurrentEditedCell(null)
                          }}
                          onFocus={() => {
                            setCurrentEditedCell({
                              rowIndex,
                              tdIndex,
                            })
                          }}
                        />
                        {rowIndex === 0 && tdIndex + 1 !== row.length && (
                          <Styled.Cursor onMouseDown={onMouseDown} tableHeight={tableHeight} />
                        )}
                      </Styled.Cell>
                    ))}
                  </Styled.Row>
                ))}
              </tbody>
            </Styled.Table>
            <Styled.ColActions>
              <HtmlTooltip title="Добавить ряд" placement="top">
                <span>
                  <AddRowIcon onClick={addNewCol} />
                </span>
              </HtmlTooltip>
              {state.table[0].length > 2 && (
                <HtmlTooltip title="Удалить ряд" placement="top">
                  <span>
                    <RemoveRowIcon onClick={removeLastCol} />
                  </span>
                </HtmlTooltip>
              )}
            </Styled.ColActions>
          </Styled.TableWrapper>
          <Styled.FooterRow>
            <Styled.RowActions>
              <HtmlTooltip title="Добавить строку" placement="top">
                <span>
                  <AddRowIcon onClick={addNewRow} />
                </span>
              </HtmlTooltip>
              {Object.keys(state.table).length > 2 && (
                <HtmlTooltip title="Удалить строку" placement="top">
                  <span>
                    <RemoveRowIcon onClick={removeLastRow} />
                  </span>
                </HtmlTooltip>
              )}
            </Styled.RowActions>
            <div style={{ width: '20px', position: 'relative' }}>
              {isLoading && (
                <Spinner
                  name="line-spin-fade-loader"
                  color="#FF852D"
                  style={{
                    scale: '0.4',
                    top: '0px',
                  }}
                />
              )}
              {showCheckmark && (
                <img
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    right: '3px',
                  }}
                  className="checkmark"
                  src={LoaderChecked}
                  alt=""
                />
              )}
            </div>
          </Styled.FooterRow>
        </div>
      </div>
    </div>
  )
}

export { TableBlock }
