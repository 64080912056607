import React, { useState, useRef } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'

import Button from '@components/common/Button'
import Spinner from 'react-spinkit'
import Image from '@assets/images/blocks/Audio/music.svg'
import Cross from '@assets/images/blocks/cross.svg'
import Eye from '@assets/images/blocks/eye.svg'
import EyeOff from '@assets/images/blocks/eye-off.svg'
import LoaderChecked from '../../assets/icons/checked.svg'
import { Tooltip, withStyles } from '@material-ui/core'
import { useRouteMatch } from 'react-router'
import { storageRef } from '@configs/firebase'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(197, 207, 221, 1)',
    color: '#414752',
    fontSize: 10,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
    fontWeight: 'bold',
  },
}))(Tooltip)

const AudioBlock = ({ onStateChange, id, preloadedState }) => {
  const ref = useRef(null)
  const initialState = {
    isVisDesc: false,
    isForStdnt: true,
    description: '',
    audio: null,
  }

  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)

  const changeState = state => {
    onStateChange(id, {
      ...preloadedState,
      ...state,
    })
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }
  let state = preloadedState || initialState
  const { courseId } = useRouteMatch().params

  const [, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item) {
      ;(async () => {
        let uploadTask = null
        if (item.files.length) {
          const file = item.files[0]
          const fileName = (Math.random() + 2).toString(36).substring(2) + '-' + file.name
          uploadTask = storageRef.ref(`COURSES/${courseId}/audio/${fileName}`).put(file)
          uploadTask.on(
            'state_changed',
            snapshot => {},
            error => {},
            () => {
              storageRef
                .ref('COURSES')
                .child(`${courseId}`)
                .child('audio')
                .child(fileName)
                .getDownloadURL()
                .then(audio => {
                  changeState({
                    audio: audio,
                  })
                })
            }
          )
        }
      })()
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const handleDescription = e => {
    e.stopPropagation()
    changeState({
      isVisDesc: true,
    })
  }

  const handleCloseDescription = e => {
    e.stopPropagation()
    changeState({
      isVisDesc: false,
    })
  }

  const handleShowForStudent = e => {
    e.stopPropagation()
    changeState({
      isForStdnt: !state.isForStdnt,
    })
  }

  const handleChangeDescription = e => {
    e.stopPropagation()
    changeState({
      description: e.target.value,
    })
  }

  const handleChooseFile = e => {
    let uploadTask = null
    if (e.target.files.length) {
      const file = e.target.files[0]
      const fileName = (Math.random() + 2).toString(36).substring(2) + '-' + file.name
      uploadTask = storageRef.ref(`COURSES/${courseId}/audio/${fileName}`).put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {},
        () => {
          storageRef
            .ref('COURSES')
            .child(`${courseId}`)
            .child('audio')
            .child(fileName)
            .getDownloadURL()
            .then(audio => {
              changeState({
                audio: audio,
              })
            })
        }
      )
    }
  }

  return (
    <div
      ref={drop}
      className="aaa"
      onClick={() => {
        ref.current.click()
      }}
    >
      {!state.audio ? (
        <>
          <img src={Image} className="block-icon" alt="" />
          <span className="block-title">Добавить аудио</span>
          <span className="block-description">
            Кликните на выделенную область или перетащите файл
          </span>
          {state.isVisDesc ? (
            <div className="block-description-area-container">
              <textarea
                className="block-description-area"
                placeholder="Описание аудио"
                onChange={handleChangeDescription}
                onClick={e => {
                  e.stopPropagation()
                }}
                value={state.description}
              />
              <div className="block-actions-area" style={{ position: 'relative', width: '100%' }}>
                <img
                  onClick={handleCloseDescription}
                  src={Cross}
                  alt=""
                  style={{ marginBottom: 22 }}
                />
                {state.isForStdnt ? (
                  <HtmlTooltip title="Текст виден ученику" placement="left">
                    <img onClick={handleShowForStudent} src={Eye} alt="" />
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip title="Текст не виден ученику" placement="left">
                    <img onClick={handleShowForStudent} src={EyeOff} alt="" />
                  </HtmlTooltip>
                )}

                {isLoading && (
                  <Spinner
                    name="line-spin-fade-loader"
                    color="#FF852D"
                    style={{
                      position: 'absolute',
                      scale: '0.4',
                      top: '58px',
                      left: '-18px',
                      zIndex: 1000,
                    }}
                  />
                )}
                {showCheckmark && (
                  <img
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '80px',
                      zIndex: 1000,
                    }}
                    className="checkmark"
                    src={LoaderChecked}
                    alt=""
                  />
                )}
              </div>
            </div>
          ) : null}
          <input
            style={{ display: 'none' }}
            ref={ref}
            onChange={handleChooseFile}
            type="file"
            accept="audio/*"
          />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <audio src={state.audio} controls />
            <img
              style={{
                marginLeft: 20,
              }}
              onClick={() => {
                changeState({
                  audio: null,
                })
              }}
              src={Cross}
              alt=""
            />
          </div>
        </>
      )}
      {!state.isVisDesc ? (
        <Button className="block-action" onClick={handleDescription}>
          Добавить описание
        </Button>
      ) : null}
      {state.isVisDesc && state.audio ? (
        <div className="block-description-area-container" style={{ marginTop: 20 }}>
          <textarea
            className="block-description-area"
            placeholder="Описание аудио"
            onChange={handleChangeDescription}
            value={state.description}
          />
          <div className="block-actions-area">
            <img onClick={handleCloseDescription} src={Cross} alt="" style={{ marginBottom: 22 }} />
            {state.isForStdnt ? (
              <HtmlTooltip title="Текст виден ученику" placement="left">
                <img onClick={handleShowForStudent} src={Eye} alt="" />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip title="Текст не виден ученику" placement="left">
                <img onClick={handleShowForStudent} src={EyeOff} alt="" />
              </HtmlTooltip>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AudioBlock
