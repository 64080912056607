import { areEqualsInputs } from '@components/LessonWidget/components/Exercise/InsertWordExercise'
import {
  IHomeworkProgress,
  ILessonHomework,
  ILessonHomeworkAnswers,
  ILessonPageStateType,
  IRealPageStates,
  HomeworkStatuses,
  ILessonPageState,
  ITestContent,
  IExerciseContent,
  IClearedState,
} from '@models'

const autoFinishedPageStateTypes = [
  ILessonPageStateType.editor,
  ILessonPageStateType['action-editor'],
  ILessonPageStateType.vocabulary,
  ILessonPageStateType.link,
  ILessonPageStateType.image,
  ILessonPageStateType.audio,
  ILessonPageStateType.video,
  ILessonPageStateType.table,
]

export const getNewStatus = (
  progress: ILessonHomeworkAnswers['progress'] = {}
): HomeworkStatuses => {
  const values = Object.values(progress ?? {})
  if (values.filter(Boolean).length === values.length) return HomeworkStatuses.DONE
  else if (values.filter(Boolean).length > 0 && values.filter(Boolean).length < values.length)
    return HomeworkStatuses.IN_PROGRESS
  else {
    return HomeworkStatuses.NOT_READY
  }
}

const isFinishedExercise = (
  pageId: string,
  exercise: ILessonPageState,
  pageStates: IRealPageStates
): boolean => {
  let result: boolean
  const currentProgress = pageStates[`pageId-${pageId}`]?.[`exerciseId-${exercise.id}`] ?? {}
  const values = Object.values(currentProgress)
  if (
    exercise.type !== ILessonPageStateType.essay &&
    (!exercise.content || !exercise.content.text?.trim())
  )
    return true
  switch (exercise.type) {
    case ILessonPageStateType.test:
      const answers = Object.values(exercise.content?.answers ?? {}) as any

      if ((currentProgress as IClearedState).clear) return false
      if ((exercise.content as ITestContent)?.isVarious) {
        result =
          answers.filter(item => item.selected).length ===
          Object.values(currentProgress).filter(Boolean).length
      } else {
        result =
          answers.filter(item => currentProgress[`i-${item.id}`] === item.selected).length > 0
      }
      break
    case ILessonPageStateType.exercise:
      const length = values.length

      switch ((exercise.content as IExerciseContent)?.variant) {
        case 1:
          result =
            length > 0 &&
            values.filter(({ droppedIndex, correctIndex }) => droppedIndex === correctIndex)
              .length === length
          break
        case 2:
          result =
            length > 0 &&
            values.filter(
              ({ current, correct }) => current && areEqualsInputs(current, correct, false)
            ).length === length
          break
        case 3:
          result =
            length > 0 &&
            values.filter(
              ({ current, correct }) =>
                (current && areEqualsInputs(current, correct, true)) || !correct
            ).length === length

          break
        case 4:
          if ((exercise.content as IExerciseContent)?.sentences?.length !== length) {
            result = false
            break
          }
          result = values.every(group => {
            const groupValues = Object.values(group ?? {}) as any
            const groupValuesLength = groupValues.length

            return (
              groupValuesLength > 0 &&
              groupValues.filter(({ droppedIndex, text }) => {
                const correctFind = groupValues.find(value => value.correctIndex === droppedIndex)

                return correctFind?.text?.trim() === text.trim()
              }).length === groupValuesLength
            )
          })
          break
        default:
          result = false
      }
      break

    case ILessonPageStateType.essay:
      if (currentProgress.answer) {
        return (result = true)
      }
      break

    default:
      result = false
  }

  return result
}

export const getNewProgress = ({
  homeWork,
  pageId,
  pageStates,
  homeworkProgress,
}: {
  homeWork: ILessonHomework
  pageId: string
  pageStates: IRealPageStates
  homeworkProgress: IHomeworkProgress
}): IHomeworkProgress =>
  homeWork.pages.reduce(
    (acc, { id, pageState }) => ({
      ...acc,
      [`pageId-${id}`]:
        id.toString() === pageId
          ? pageState.every(
              exercise =>
                autoFinishedPageStateTypes.includes(exercise.type) ||
                isFinishedExercise(id.toString(), exercise, pageStates)
            )
          : Boolean(homeworkProgress[`pageId-${id}`]),
    }),
    {}
  )

export const getPageCountInfo = (
  progress: ILessonHomeworkAnswers['progress'] = {}
): { passedCount: number; totalCount: number } => {
  if (!progress) return { passedCount: 0, totalCount: 0 }
  const values = Object.values(progress)
  return {
    passedCount: values.filter(Boolean).length,
    totalCount: values.length,
  }
}
