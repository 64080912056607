import { ROLES, TEACHER_RATES, STUDENT_RATES, LEVELS, CURRENCY_CODES } from '../utils/constants'
import { IPassedLesson, IRequisites } from './IPersonal'
import { ICurrentCourseInfo } from './IClassroom'
import firebase from 'firebase/app'

export enum LangEnum {
  ru = 'ru',
  en = 'en',
  es = 'es',
  pt = 'pt',
}

export type Test = {
  isPassed: boolean
  id: string
}

export interface IRates {
  after: number
  rate: number
  currency: CURRENCY_CODES
}
export interface IProfile {
  audio: any
  assignedTests: Test[]
  aboutMe: string
  avatar: string
  birthDate: string
  businessHours?: IBusinessHours[]
  cityAddress: string
  email: string
  isEnrolmentClosed?: boolean
  gender: string
  lastName: string
  manager?: boolean
  name: string
  phone: string
  lang?: LangEnum
  deactivated?: boolean
  skype: string
  teachingExperience: string
  telegram: string
  timeZone: string
  id?: string
  googleDrive?: IGoogleDrive
  students: string[]
  password?: string
  weeklyLoadLessons: number
  role?: ROLES
  isTrialLessonsEnabled?: boolean
  rate: TEACHER_RATES
  passedLessons?: IPassedLesson[]
  payments?: IPayment[]
  requisites: IRequisites
  voxData: VoxData
  cr?: string
  competitionPlace?: number
  IsSelfRegistration?: boolean
  isNative?: boolean
  summ_rubl?: number
  summ_euro?: number
  summ_usd?: number
  interests: Array<string>
  comments: string
  typeOfLang: string
  keyFeatures?: string[]
  isTeachFamily: boolean
  isTeachChildren: boolean
  bhUpdated?: firebase.firestore.Timestamp
  isTgOn?: boolean
  isEmailOn?: boolean
  a0Course?: boolean
  linkVideoTraining?: string
  notifications?: { cid: string }
  showNewHomework?: string
  amoCRMId?: string
  levelsOfStudents?: LEVELS[]
  isUnreadMessages?: boolean
  isRecord?: boolean
  rates?: IRates[]
  createdAt: firebase.firestore.Timestamp
}

export interface VoxData {
  appName: string
  applicationId: string
  customerName: string
  key: string
}

export interface IStudentProfile {
  id?: string
  avatar: string
  birthDate: string
  email: string
  timeZone: string
  assignedTests: Test[]
  gender?: string
  lastName: string
  createdAt?: number
  checkAt?: number
  name: string
  phone: string
  skype: string
  telegram?: string
  level: string
  levelGrade: string
  levelLastUpdate: firebase.firestore.Timestamp
  intermediateGoals?: { goal: string; deadline: string }[]
  goal: string
  goals: string[]
  deadline: firebase.firestore.Timestamp
  attitudeToHomework?: string
  classFrequency?: number[]
  interests: Array<string>
  student?: boolean
  balanceCount?: number
  promoCode?: string
  passedLessons?: number
  teachers?: string[]
  workingHours?: number
  rate?: STUDENT_RATES
  payments?: IPayment[]
  currentCourseId?: string
  currentLessonId?: string
  currentCourse?: ICurrentCourseInfo
  packages?: IPackage[]
  cr?: string
  competitionPlace?: number
  isSelfRegistration: boolean
  lang: LangEnum
  voxData: VoxData
  euroPackages?: IPackage[]
  usdPackages?: IPackage[]
  kztPackages?: IPackage[]
  tutorId?: string
  tutorName?: string
  tutorAvatar?: string
  comments?: string
  daysP?: string
  priorities?: { [Name: string]: string }
  notifications?: { cid: string; isReadyToChat: boolean }
  showNewHomework?: number
  isNetworking?: boolean
  researchGroups?: string[]
  isUnreadMessages?: boolean
  isConference?: boolean
  isStripe?: boolean
  isRecord?: boolean
  countOfCancel?: { m: number; c: number }
}

export interface IGoogleDrive {
  refreshToken: string
  folders: Folders
}

export interface IPayment {
  amount: number
  currency: string
  date: any
  package: number
  desc?: string
  promoCode?: string
  type?: string
  n?: string
  s?: string
  balance?: number
  ls?: string
  c?: string
  cs?: string
}

export interface ICourse {
  lessons: number
  price: number
}

export interface IPackage {
  type: string
  courses: ICourse[]
}

type Folders = {
  video: string
  audio: string
  docs: string
  image: string
}
export interface ILibraryFile {
  file_name?: string
  type?: string
  url?: string
}

export interface IBusinessHours {
  daysOfWeek: any[]
  endTime: string
  startTime: string
}

export interface IProfileState {
  profile?: IProfile | IStudentProfile
  profileTeacher?: IProfile
  files: ILibraryFile[]
  allFilesLoading: boolean
  googleDrive: IGoogleDrive | null
  isStudent: boolean | null
}
