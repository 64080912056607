import React from 'react'

export default function DeleteIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6665 5.00016C1.6665 4.53993 2.0396 4.16683 2.49984 4.16683H17.4998C17.9601 4.16683 18.3332 4.53993 18.3332 5.00016C18.3332 5.4604 17.9601 5.8335 17.4998 5.8335H2.49984C2.0396 5.8335 1.6665 5.4604 1.6665 5.00016Z"
        fill="#C2CFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33317 2.50016C8.11216 2.50016 7.9002 2.58796 7.74392 2.74424C7.58763 2.90052 7.49984 3.11248 7.49984 3.3335V4.16683H12.4998V3.3335C12.4998 3.11248 12.412 2.90052 12.2558 2.74424C12.0995 2.58796 11.8875 2.50016 11.6665 2.50016H8.33317ZM14.1665 4.16683V3.3335C14.1665 2.67045 13.9031 2.03457 13.4343 1.56573C12.9654 1.09689 12.3295 0.833496 11.6665 0.833496H8.33317C7.67013 0.833496 7.03425 1.09689 6.5654 1.56573C6.09656 2.03457 5.83317 2.67045 5.83317 3.3335V4.16683H4.1665C3.70627 4.16683 3.33317 4.53993 3.33317 5.00016V16.6668C3.33317 17.3299 3.59656 17.9658 4.0654 18.4346C4.53424 18.9034 5.17013 19.1668 5.83317 19.1668H14.1665C14.8295 19.1668 15.4654 18.9034 15.9343 18.4346C16.4031 17.9658 16.6665 17.3299 16.6665 16.6668V5.00016C16.6665 4.53993 16.2934 4.16683 15.8332 4.16683H14.1665ZM4.99984 5.8335V16.6668C4.99984 16.8878 5.08763 17.0998 5.24392 17.2561C5.4002 17.4124 5.61216 17.5002 5.83317 17.5002H14.1665C14.3875 17.5002 14.5995 17.4124 14.7558 17.2561C14.912 17.0998 14.9998 16.8878 14.9998 16.6668V5.8335H4.99984Z"
        fill="#C2CFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33317 8.3335C8.79341 8.3335 9.1665 8.70659 9.1665 9.16683V14.1668C9.1665 14.6271 8.79341 15.0002 8.33317 15.0002C7.87293 15.0002 7.49984 14.6271 7.49984 14.1668V9.16683C7.49984 8.70659 7.87293 8.3335 8.33317 8.3335Z"
        fill="#C2CFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6665 8.3335C12.1267 8.3335 12.4998 8.70659 12.4998 9.16683V14.1668C12.4998 14.6271 12.1267 15.0002 11.6665 15.0002C11.2063 15.0002 10.8332 14.6271 10.8332 14.1668V9.16683C10.8332 8.70659 11.2063 8.3335 11.6665 8.3335Z"
        fill="#C2CFE0"
      />
    </svg>
  )
}
