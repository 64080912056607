import styled from 'styled-components'

/* Info block */
export const InfoBlock = styled.div`
  max-width: 1100px;
  min-height: 188px;
  background-color: rgb(225, 236, 255, 0.5);
  border-radius: 0px 0px 20px 60px;
  margin-top: -20px;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1439px) {
    max-width: 1015px;
  }
`

export const Reschedulelink = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Grey middle_ default text */

  color: #7e92ac;
  width: fit-content;
`

export const Button = styled.button`
  width: fit-content;
  height: 50px;
  background: linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%);
  border-radius: 36px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  span {
    margin: 10px;
    min-width: 180px;
  }
`

export const InfoBlockContainer = styled.div`
  display: flex;
  // height: 168px;
  flex: 1;
  padding: 20px 0px 20px 60px;
  align-items: flex-end;
`

export const InfoImg = styled.img``

export const InfoContent = styled.div<{ bgImg: any }>`
  background-image: ${props => `url(${props.bgImg})`};
  width: 560px;
  min-height: 118px;
  border-radius: 20px;
  margin-left: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -1px;
  padding: 20px 30px 20px 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LinkToChangeClassTime = styled.a`
  text-decoration: underline;
  margin-top: 5px;
`
