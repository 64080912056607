import * as React from 'react';

const LeftVectorIcon = (props) => {
  return (
    <svg {...props} width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M0.584015 6.33757L12.0347 16.2762C12.3223 16.5258 12.7721 16.3752 12.8515 16.0029L16.0187 1.14837C16.0995 0.769564 15.7375 0.446728 15.3703 0.570187L0.752383 5.48605C0.385879 5.6093 0.291996 6.08412 0.584015 6.33757Z'
        fill='#9ED1FF'
      />
    </svg>
  );
};

export default LeftVectorIcon;
