import * as Styled from '../personal-info.styles'
import { ProfileAvatar } from '@pages/student/Profile/student-profile.styles'
import DefaultAvatar from '@images/default_avatar.png'
import React, { useState } from 'react'
import { IconButton, Input } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'

export default function({
  onChange,
  editType,
  student,
  years,
  rate,
  handleClickEdit,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  student: any
  years: number
  rate: any
  onChange: (data: string) => void
}) {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  return (
    <Styled.Wrapper>
      <Styled.Div>
        <ProfileAvatar
          src={student.avatar || DefaultAvatar}
          alt=""
          style={{ width: 130, height: 130 }}
        />
      </Styled.Div>
      <Styled.PersonalInfoItem>
        {isVisible ? (
          <Styled.Name className="personal-info">
            <>
              <span className="personal-name">{`${student.name} ${student.lastName}`}</span>
              <span className="personal-data">
                {years > 0 && <span className="personal-years">{years} years</span>}
                <Styled.PersonalRate rate={student.rate}>{rate.title}</Styled.PersonalRate>
              </span>
            </>

            <div className="hover-block" />
          </Styled.Name>
        ) : null}
        {editType === 'name' && (
          <Styled.Name className="personal-info">
            <Input
              autoFocus={true}
              disableUnderline={true}
              multiline
              className="personal-name"
              defaultValue={`${student.name} ${student.lastName}`}
              onChange={e => onChange(e.target.value)}
            />
            <span className="personal-data">
              {years > 0 && <span className="personal-years">{years} years</span>}
              <Styled.PersonalRate rate={student.rate}>{rate.title}</Styled.PersonalRate>
            </span>
            <div className="hover-block" />
          </Styled.Name>
        )}

        <IconButton
          className="edit-btn"
          size="small"
          onClick={() => {
            handleClickEdit('name')
            setIsVisible(!isVisible)
          }}
        >
          {editType === 'name' ? <CheckMarkIcon /> : <EditIcon />}
        </IconButton>
      </Styled.PersonalInfoItem>
    </Styled.Wrapper>
  )
}
