import React from 'react'
export default function RedoIcon(props) {
  let opacity = {}
  opacity = props.disabled ? { opacity: 0.5 } : null
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      {...opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.52432V0.5H15.5V7.25H8.75V5.75H13.0487C12.6346 5.21183 12.0302 4.41797 11.7131 4.06993C10.4992 2.7376 9.19337 2 7.625 2C3.79611 2 2 4.54789 2 8H0.5C0.5 3.80917 2.83276 0.5 7.625 0.5C9.67889 0.5 11.3495 1.44364 12.8219 3.0597C13.137 3.40552 13.6256 4.03395 14 4.52432Z"
        fill="white"
      />
    </svg>
  )
}
