import React, { Component } from 'react'
import _ from 'lodash'

import { classroomsRef } from '@configs/firebase'
import { changeBlockPosition, cursorPositionChanged, lockBlock } from '@servise/classroomAPI'

import { Tools, SketchField } from '@components'
import SettingsBlock from './Settings'
import { CloseIcon, CursorIcon, HideIcon, MinusMath, SearchIcon } from '@components/Icons'

import * as Styled from './classroom-files.styles'
import UpArrow from '@images/UpArrow.svg'
import DownArrow from '@images/DownArrow.svg'
import { connect } from 'react-redux'
import OpenLock from '@images/OpenLock.svg'
import ClosedLock from '@images/ClosedLock.svg'
import PlusMath from '@components/Icons/PlusMath'
import ZoomImage from './ZoomImage'
import { Tooltip, TooltipContainer } from '@pages/student/student.styles'
import { APP_CONFIG, ROLES } from '@utils/constants'
import { getCachedImage } from '@utils/uncachedImage'

class SketchBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineWidth: 8,
      lineColor: '#336A93',
      tool: Tools.DefaultTool,
      canUndo: true,
      canRedo: true,
      canClear: true,
      text: APP_CONFIG.appName,
      image: false,
      canvasWidth: null,
      canvasHeight: null,
      position: {},
      fontSize: 24,
      zoomImage: 1,
      IsOpen: false,
      fileId: null,
      containerHover: false,
      fileUrl: '',
      isImageLoaded: false,
    }
    this._onSketchChange = _.debounce(this._onSketchChange, 500)
    this.zoomImageContainer = React.createRef()
  }
  setCachedImage(url) {
    this.setState({ isImageLoaded: false })
    getCachedImage(url)
      .then(cachedUrl => this.setState({ fileUrl: cachedUrl }))
      .finally(() => {
        this.setState({ isImageLoaded: true })
      })
  }
  componentDidMount() {
    const { isBoard } = this.props

    isBoard && this.setState({ tool: Tools.Pencil })
    if (this.props.fileData.sketchData) {
      const sketchData = JSON.parse(this.props.fileData.sketchData)
      if (sketchData.objects.length > 0) {
        this.setState({ canClear: false, canUndo: false })
      }
    }
    if (this.props.fileData.url) {
      this.setCachedImage(this.props.fileData.url)
    } else {
      this.setState({ isImageLoaded: true })
    }
  }

  updateCanvasSize = (canvasWidth, canvasHeight) => {
    this.setState({
      canvasWidth,
      canvasHeight,
    })
  }

  setLineColor = lineColor => {
    this.setState({ lineColor }, () => {
      this._sketch.setActiveLineColor(lineColor)
    })
  }

  setLineWidth = lineWidth => {
    this.setState({ lineWidth, tool: Tools.Pencil })
  }

  undo = () => {
    this._sketch.undo()

    this.setState({
      canClear: this._sketch.canClear(),
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    })
  }

  redo = () => {
    this._sketch.redo()

    this.setState({
      canClear: this._sketch.canClear(),
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    })
  }
  clear = () => {
    this._sketch.clear()

    this.setState({
      canClear: this._sketch.canClear(),
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    })
  }

  addText = position => {
    this._sketch.addText(this.state.text, position, {
      fontFamily: 'Open Sans',
      fill: this.state.lineColor,
      fontWeight: this.state.fontSize ? 'bold' : 'normal',
      fontSize: this.state.fontSize,
    })
  }

  deleteObject = () => {
    this._sketch.removeSelected()
  }
  _onSketchChange = e => {
    const { canvasWidth, canvasHeight, tool } = this.state
    if (this._sketch && tool !== Tools.DefaultTool) {
      const sketchData = JSON.stringify({
        objects: this._sketch.toJSON().objects,
        canvasHeight,
        canvasWidth,
      })

      if (this.props.onSketchChange) {
        this.props.onSketchChange(sketchData)
      } else {
        classroomsRef
          .doc(this.props.classID)
          .collection('files')
          .doc(this.props.fileData.id)
          .update({ sketchData })
      }
    }
    let prev = this.state.canUndo
    let now = this._sketch.canUndo()
    if (prev !== now) {
      this.setState({ canUndo: now, canClear: now })
    }
  }
  onChangeTool = tool => {
    this.setState({ tool })
  }
  ZoomImageMenu = () => {
    this.setState({ ...this.state, IsOpen: true })
  }

  setImagePositions = () => {
    const parentElement = this.zoomImageContainer.current.parentElement
    const containerHeight = this.zoomImageContainer.current.getBoundingClientRect().height
    const windowHeight = window.innerHeight
    const containerWidth = this.zoomImageContainer.current.getBoundingClientRect().width
    const windowWidth = window.innerWidth
    const diffHeight = windowHeight - containerHeight
    const diffWidth = windowWidth - containerWidth

    if (diffHeight >= 0 && diffWidth >= 0) {
      this.zoomImageContainer.current.style.transformOrigin = 'center'
      parentElement.style.alignItems = 'center'
      parentElement.style.justifyContent = 'center'
    } else if (diffHeight < 0 && diffWidth < 0) {
      this.zoomImageContainer.current.style.transformOrigin = 'top left'
      parentElement.style.alignItems = 'baseline'
      parentElement.style.justifyContent = 'initial'
    } else if (diffHeight < 0) {
      this.zoomImageContainer.current.style.transformOrigin = 'top'
      parentElement.style.alignItems = 'baseline'
      parentElement.style.justifyContent = 'center'
    } else if (diffWidth < 0) {
      this.zoomImageContainer.current.style.transformOrigin = 'left'
      parentElement.style.alignItems = 'center'
      parentElement.style.justifyContent = 'initial'
    }
  }

  ZoomImageIn = () => {
    this.setImagePositions()
    this.setState({ ...this.state, zoomImage: this.state.zoomImage + 0.2 })
  }
  ZoomImageOut = () => {
    if (this.state.zoomImage.toFixed() - 0.2 > 0) {
      this.setImagePositions()
      this.setState({ ...this.state, zoomImage: this.state.zoomImage - 0.2 })
    }
  }

  sketchContainer = () => {
    const { fileData, isBoard } = this.props
    const { zoomImage, fileUrl } = this.state

    return (
      <>
        <Styled.SketchZoomImageContainer
          id={fileData.id}
          ref={this.zoomImageContainer}
          board={isBoard}
          hide={fileData.isHidden}
          onClick={this.onPositionChanged}
          scale={zoomImage}
        >
          <img src={fileUrl} alt="img" />
        </Styled.SketchZoomImageContainer>
        <Styled.CloseIcon>
          <button
            onClick={() => {
              this.setState({ ...this.state, IsOpen: false, zoomImage: 1 })
            }}
          >
            <CloseIcon fill={'white'} />
          </button>
        </Styled.CloseIcon>
        <Styled.PlusIcon scale={zoomImage}>
          <button onClick={this.ZoomImageIn}>
            <PlusMath zoom={zoomImage} />
          </button>
        </Styled.PlusIcon>
        <Styled.MinusIcon scale={zoomImage}>
          <button onClick={this.ZoomImageOut}>
            <MinusMath zoom={zoomImage} />
          </button>
        </Styled.MinusIcon>
        {/* </div> */}
      </>
    )
  }

  onPositionChanged = event => {
    const { canvasHeight, canvasWidth, tool } = this.state
    const { fileData, classID } = this.props
    let currentTargetRect = event.currentTarget.getBoundingClientRect()
    const position = {
      x: event.pageX - currentTargetRect.left,
      y: event.pageY - currentTargetRect.top,
    }
    if (tool === Tools.DefaultTool) {
      const data = {
        width: canvasWidth,
        height: canvasHeight,
        classID,
        fileID: fileData.id,
        position,
      }
      if (this.props.onCursorPositionChange) {
        this.props.onCursorPositionChange(data)
      } else {
        cursorPositionChanged(data)
      }
    } else if (tool === Tools.Select) {
      this.addText(position)
    }
  }

  handleUpSide = () => {
    this.props.changeBlockPosition(this.props.classID, this.props.fileData.id, true)
  }
  handleDownSide = () => {
    this.props.changeBlockPosition(this.props.classID, this.props.fileData.id, false)
  }

  handleLockElement = () => {
    lockBlock(this.props.classID, this.props.fileData.id, !this.props.fileData.isLocked)
  }

  render() {
    const { fileData, isBoard, cursorState, classID, isStudent, isHomeWork, role } = this.props
    const {
      tool,
      lineColor,
      lineWidth,
      canRedo,
      canUndo,
      canvasWidth,
      canvasHeight,
      canClear,
      IsOpen,
      fileUrl,
      isImageLoaded,
    } = this.state
    return (
      <>
        <ZoomImage open={IsOpen} children={this.sketchContainer()} />
        <Styled.FileContainer
          isCoursePage={!classID}
          isPreview={this.props.isPreview}
          isManager={role === ROLES.MANAGER}
          id={fileData.id}
        >
          <Styled.SketchContainer
            id={`skecth-container-${fileData.id}`}
            ref={c => (this._container = c)}
            board={isBoard}
            hide={fileData.isHidden}
            onClick={this.onPositionChanged}
            scale={this.state.zoomImage}
            style={{}}
            onMouseEnter={() => this.setState({ ...this.state, containerHover: true })}
            onMouseLeave={() => this.setState({ ...this.state, containerHover: false })}
            onMouseOver={() => this.setState({ ...this.state, containerHover: true })}
          >
            <canvas
              id={`canvas-${fileData.id}`}
              ref={c => {
                this._canvas = c
                if (this.state.fileId !== fileData.id) {
                  this.setState({ fileId: fileData.id })
                }
              }}
            />
            {this.props.isImage && this.state.containerHover && isStudent && (
              <Styled.SearchIcon>
                <button onClick={() => this.ZoomImageMenu()}>
                  <SearchIcon fill={'white'} />
                </button>
              </Styled.SearchIcon>
            )}

            {this._canvas && isImageLoaded && (
              <SketchField
                ref={c => {
                  this._sketch = c
                }}
                onChange={this._onSketchChange}
                fileID={fileData.id}
                tool={
                  !isStudent || (isStudent && !this.props.fileData.isLocked) ? tool : 'default-tool'
                }
                width={fileData.width || canvasWidth}
                height={fileData.height || canvasHeight}
                updateSettings={this.updateSettings}
                updateCanvasSize={this.updateCanvasSize}
                imageUrl={fileUrl}
                value={fileData.sketchData}
                image={this._image}
                canvas={this._canvas}
                lineColor={lineColor}
                lineWidth={lineWidth}
              />
            )}

            {tool === Tools.DefaultTool &&
              !fileData.isHidden &&
              cursorState.fileID === fileData.id &&
              canvasHeight &&
              canvasWidth && (
                <CursorIcon
                  style={{
                    position: 'absolute',
                    top: cursorState.position.y * canvasHeight,
                    left: cursorState.position.x * canvasWidth,
                  }}
                />
              )}

            {fileData.isHidden && <HideIcon />}
          </Styled.SketchContainer>
          {(!isStudent && isHomeWork) ||
            (!isHomeWork && (
              <SettingsBlock
                fileData={fileData}
                isBoard={isBoard}
                onChangeTool={this.onChangeTool}
                undo={this.undo}
                redo={this.redo}
                canClear={canClear}
                canUndo={canUndo}
                clear={this.clear}
                canRedo={canRedo}
                lineColor={lineColor}
                setLineColor={this.setLineColor}
                setLineWidth={this.setLineWidth}
                addText={fontSize => this.setState({ fontSize, tool: Tools.Select })}
                tool={tool}
                hideClassActions={this.props.hideClassActions}
              />
            ))}

          {(!isStudent && isHomeWork) ||
            (!isStudent && !isHomeWork && classID && (
              <>
                <Tooltip
                  title={
                    <TooltipContainer>Запретить рисовать ученику на картинке</TooltipContainer>
                  }
                >
                  <Styled.LockIcon
                    isStudent={isStudent}
                    onClick={!isStudent ? this.handleLockElement : null}
                  >
                    {fileData.isLocked ? (
                      <img src={ClosedLock} alt="Closed Lock" />
                    ) : (
                      <img src={OpenLock} alt="Opened Lock" />
                    )}
                  </Styled.LockIcon>
                </Tooltip>
                <Styled.UpDownBlock type={this.props.fileData.type}>
                  <img src={UpArrow} alt="UpArrow" onClick={this.handleUpSide} />
                  <img src={DownArrow} alt="DownArrow" onClick={this.handleDownSide} />
                </Styled.UpDownBlock>
              </>
            ))}
        </Styled.FileContainer>
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    isStudent: state.profile?.isStudent,
    role: state.profile?.profile?.role,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeBlockPosition: (classID, fileDataId, IsUpside) =>
      dispatch(changeBlockPosition(classID, fileDataId, IsUpside)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SketchBoard)
