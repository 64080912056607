import * as React from 'react'

const CheckMarkIcon = ({ rect, fill }) => {
  if (rect) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="4" fill={fill || '#C2CFE0'} />
        <path
          d="M8.125 12.3826L5.62122 9.87878L5.5 9.75756L5.37878 9.87878L4.50378 10.7538L4.38256 10.875L4.50378 10.9962L8.00378 14.4962L8.125 14.6174L8.24622 14.4962L15.7462 6.99622L15.8674 6.875L15.7462 6.75378L14.8712 5.87878L14.75 5.75756L14.6288 5.87878L8.125 12.3826Z"
          fill="white"
          stroke="white"
          stroke-width="0.342857"
        />
      </svg>
    )
  }
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3566_44331)">
        <circle cx="12" cy="12.8203" r="10" fill="white" />
        <path
          d="M12 2.82031C6.47305 2.82031 2 7.29297 2 12.8203C2 18.3473 6.47266 22.8203 12 22.8203C17.527 22.8203 22 18.3477 22 12.8203C22 7.29336 17.5273 2.82031 12 2.82031ZM9.62055 17.0387C9.62055 17.0387 6.16656 13.5833 5.91828 13.3355C5.36598 12.7832 5.36598 11.8844 5.91828 11.3326C6.47199 10.7789 7.36746 10.7789 7.92117 11.3326L10.1573 13.5687C10.4353 13.8467 10.8866 13.8448 11.1623 13.5654L16.072 8.5848C16.6239 8.02496 17.5255 8.02117 18.082 8.57773C18.63 9.12578 18.6334 10.0208 18.0891 10.5731C12.7573 15.9827 11.7448 17.0382 11.7448 17.0382C11.1594 17.6235 10.2064 17.624 9.62055 17.0387Z"
          fill="#35BA62"
        />
      </g>
      <defs>
        <clipPath id="clip0_3566_44331">
          <rect width="20" height="20" fill="white" transform="translate(2 2.82031)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckMarkIcon
