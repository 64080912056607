import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Select, { components } from 'react-select'

import { authRef } from '@configs/firebase'
import { compareHours, getWeekDay } from '@utils/helpers'
import { BUSINESS_HOURS, Gender_Options, SelectType, levelOptions } from '@utils/constants'

import { IBusinessHours } from '@models'
import { updateBusinessHoursProfile } from '@servise'

import { TeacherButton as Button, Checkbox } from '@components'

import * as Styled from './styles'

type PropsType = {
  businessHours: IBusinessHours[]
  updateBusinessHours?: (userID: string, data: IBusinessHours[]) => Promise<void>
  timeZone?: string
  cancelHandler: () => void
  gender?: SelectType
  leadsAt?: SelectType
  native?: SelectType
  accent?: SelectType
  location?: SelectType
  studentLevel?: SelectType
  handleSetLeadsAt?: (value: SelectType) => void
  handleSetGender?: (value: SelectType) => void
  handleSetNative?: (value: SelectType) => void
  handleSetAccent?: (value: SelectType) => void
  handleSetStudentLevel?: (value: SelectType) => void
  handleSetLocation?: (value: SelectType) => void
  handleClearFilter?: () => void
  handleChangeTime?: (businessHours: IBusinessHours[]) => void
  handleSelectRole?: (value: SelectType) => void
  isEnabled: boolean
  setAvailability: (value: boolean) => void
}

type StateType = {
  businessHours: IBusinessHours[]
  sending: boolean
}

class SettingsBusinessHours extends Component<PropsType, StateType> {
  constructor(props) {
    super(props)

    this.state = {
      businessHours: [],
      sending: false,
    }
  }

  componentDidMount() {
    let businessHours = this.props.businessHours

    BUSINESS_HOURS.forEach(search => {
      let hoursIndex = this.props.businessHours.findIndex(
        item => item.startTime === search.startTime
      )
      if (hoursIndex === -1) {
        businessHours.push(search)
      }
    })

    businessHours = businessHours.sort(compareHours)

    this.setState({ businessHours: businessHours })
  }
  handleChange = ({ target: { checked, name } }, index) => {
    let businessHours = this.state.businessHours
    let hoursName = parseInt(name)
    if (hoursName === 7) {
      if (checked) {
        businessHours[index].daysOfWeek = [6, 5, 4, 3, 2, 1, 0]
      } else {
        businessHours[index].daysOfWeek = []
      }
    } else {
      if (checked) {
        businessHours[index].daysOfWeek.push(hoursName)
      } else {
        var i = businessHours[index].daysOfWeek.indexOf(hoursName)

        if (i > -1) {
          businessHours[index].daysOfWeek.splice(i, 1)
        }
      }
    }

    this.setState({
      businessHours: businessHours,
    })
  }

  filterDaysOfWeek = (val, index) => {
    let businessHours = this.state.businessHours
    const hasDay =
      businessHours[index].daysOfWeek.filter(currentValue => {
        return currentValue === val
      }).length > 0
    return hasDay
  }

  clearFilter = () => {
    this.setState({ businessHours: JSON.parse(JSON.stringify(BUSINESS_HOURS)) })
    this.props.handleClearFilter()
  }

  handleSubmit = () => {
    const state = this.state
    const { cancelHandler, updateBusinessHours, timeZone, handleChangeTime } = this.props

    if (handleChangeTime) {
      handleChangeTime(state.businessHours)
    } else {
      this.setState({ sending: true })

      let tz = parseInt(
        moment
          .tz(timeZone)
          .format('Z')
          .substr(0, 3)
      )

      let businessHours = state.businessHours.map((item, i) => {
        let daysOfWeek = item.daysOfWeek
        let time = i - (tz - 3)

        if (time < 0) {
          time = 24 + time

          daysOfWeek = item.daysOfWeek.map(weekDay => {
            return getWeekDay(false, weekDay)
          })
        } else if (time >= 24) {
          time = time - 24
          daysOfWeek = item.daysOfWeek.map(weekDay => {
            return getWeekDay(true, weekDay)
          })
        }

        return {
          startTime: ('0' + time).slice(-2) + ':' + '00',
          endTime: ('0' + (time + 1)).slice(-2) + ':' + '00',
          daysOfWeek,
        }
      })

      businessHours = businessHours.sort(compareHours)

      const uid = authRef.currentUser.uid
      updateBusinessHours(uid, businessHours).then(() => {
        this.setState({ sending: false })
        cancelHandler()
      })
    }
  }

  render() {
    const { sending, businessHours } = this.state
    const {
      cancelHandler,
      handleSetGender,
      gender,
      timeZone,
      leadsAt,
      handleSetLeadsAt,
      native,
      handleSetNative,
      accent,
      location,
      handleSetAccent,
      handleSetLocation,
      studentLevel,
      handleSetStudentLevel,
    } = this.props

    const tableList = businessHours.map((row, index) => {
      return (
        <tr>
          <td className="col1">
            {row.startTime} - {row.endTime}
          </td>
          <td className="col2">
            <Checkbox
              type="checkbox"
              className="table-checkbox-all"
              name="7"
              checked={row.daysOfWeek.length === 7 ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="1"
              checked={this.filterDaysOfWeek(1, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="2"
              checked={this.filterDaysOfWeek(2, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="3"
              checked={this.filterDaysOfWeek(3, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="4"
              checked={this.filterDaysOfWeek(4, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="5"
              checked={this.filterDaysOfWeek(5, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="6"
              checked={this.filterDaysOfWeek(6, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
          <td>
            <Checkbox
              type="checkbox"
              className="table-checkbox"
              name="0"
              checked={this.filterDaysOfWeek(0, index) ? true : false}
              onChange={e => this.handleChange(e, index)}
            />
          </td>
        </tr>
      )
    })

    return (
      <Styled.ModalContainer>
        <header>
          {' '}
          {timeZone ? 'Настройка рабочего времени' : 'Настроить фильтр показа преподавателей'}{' '}
        </header>
        <main>
          {!timeZone && (
            <Styled.SelectContainer>
              <Styled.SelectField>
                <Styled.SelectLabel>Ведет у </Styled.SelectLabel>
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStyles(110)}
                  options={[
                    { value: 0, label: 'любого' },
                    { value: 1, label: 'детей' },
                    { value: 2, label: 'пар' },
                  ]}
                  name="leadsAt"
                  value={leadsAt}
                  onChange={handleSetLeadsAt}
                />
              </Styled.SelectField>
              <Styled.SelectField>
                <Styled.SelectLabel>Носитель </Styled.SelectLabel>
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStyles(110)}
                  options={[
                    { value: 0, label: 'любой' },
                    { value: 1, label: 'нет' },
                    { value: 2, label: 'да' },
                  ]}
                  name="native"
                  value={native}
                  onChange={handleSetNative}
                />
              </Styled.SelectField>

              <Styled.SelectField>
                <Styled.SelectLabel>Пол </Styled.SelectLabel>
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStyles(110)}
                  options={[{ value: 0, label: 'Любой' }, ...Gender_Options]}
                  name="gender"
                  value={gender}
                  onChange={handleSetGender}
                />
              </Styled.SelectField>

              <Styled.SelectField>
                <Styled.SelectLabel>Акцент </Styled.SelectLabel>
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStyles(140)}
                  options={[
                    { value: 0, label: 'любой' },
                    { value: 'spanishOfSpain', label: 'Испанский Испании' },
                    { value: 'latinAmericanSpanish', label: 'Латиноамериканский испанский' },
                  ]}
                  name="accent"
                  value={accent}
                  onChange={handleSetAccent}
                />
              </Styled.SelectField>

              <Styled.SelectField>
                <Styled.SelectLabel>Обучение для </Styled.SelectLabel>
                <Select
                  components={{ DropdownIndicator }}
                  styles={Styled.customSelectStyles(110)}
                  options={[{ value: 0, label: 'любого' }, ...levelOptions]}
                  name="studentLevel"
                  value={studentLevel}
                  onChange={handleSetStudentLevel}
                />
              </Styled.SelectField>
            </Styled.SelectContainer>
          )}
          <table className="bussines-hours">
            <thead>
              <tr>
                <th className="col1"></th>
                <th className="col2">Все дни</th>
                <th>Пн</th>
                <th>Вт</th>
                <th>Ср</th>
                <th>Чт</th>
                <th>Пт</th>
                <th>Сб</th>
                <th>Вс</th>
              </tr>
            </thead>

            <tbody>{tableList}</tbody>
          </table>
        </main>

        <footer>
          <Button
            whiteTheme
            children={timeZone ? 'Сохранить' : 'Поиск'}
            loading={sending}
            bgWhite
            onClick={this.handleSubmit}
          />
          <button
            className="mt-20 bold-text text-base"
            children={timeZone ? 'Отмена' : 'Очистить все'}
            type="button"
            onClick={() => {
              if (timeZone) {
                cancelHandler()
              } else {
                this.clearFilter()
              }
            }}
          />
        </footer>
      </Styled.ModalContainer>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  updateBusinessHours: (userID, data) => dispatch(updateBusinessHoursProfile(userID, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsBusinessHours)

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5.5 6L10 1" stroke={'#C2CFE0'} />
      </svg>
    </components.DropdownIndicator>
  )
}
