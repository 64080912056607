import React from 'react';

const BorderedTableIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.81783 4H16.5455C17.3488 4 18 4.63207 18 5.41176V14.5882C18 15.3679 17.3488 16 16.5455 16H7.81783H6.36328H3.45455C2.65122 16 2 15.3679 2 14.5882V5.41176C2 4.63207 2.65122 4 3.45455 4H6.36328H7.81783ZM6.36328 5.41181H3.45703V7.52952H6.36328V5.41181ZM7.81783 7.52952V5.41181H16.5479V7.52952H7.81783ZM6.36328 8.94128H3.45703V11.0586H6.36328V8.94128ZM7.81783 11.0586V8.94128H16.5479V11.0586H7.81783ZM6.36328 12.4703H3.45703V14.5883H6.36328V12.4703ZM7.81783 14.5883V12.4703H16.5479V14.5883H7.81783Z"
      fill="currentColor"
    />
  </svg>
);

export { BorderedTableIcon };
