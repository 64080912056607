import React, { useState, useCallback, useRef } from 'react'

import Button from '@components/common/Button'

import PreloadedMedia from '@assets/images/Modal/preloadedmedia.png'
import { storageRef } from '@configs/firebase'
import { convertToMegaBytes } from '@utils/sizeConvert'
import { useRouteMatch } from 'react-router'

const ModalMedia = ({
  preloadedImage,
  onChooseImage,
  localization: { mediaTitle, inputFileTitle, inputFileSize },
  isNewClass = false,
}) => {
  const [state, setState] = useState({
    info: '',
    image: preloadedImage || PreloadedMedia,
    error: null,
  })
  const hiddenFileInput = useRef(null)

  const handleClickFile = useCallback(() => {
    hiddenFileInput.current.click()
  }, [])
  const { courseId } = useRouteMatch().params
  const handleChooseFile = useCallback(
    async e => {
      let uploadTask = null
      if (e.target.files.length) {
        const file = e.target.files[0]
        const fileName = (Math.random() + 2).toString(36).substring(2) + '-' + file.name
        if (convertToMegaBytes(file.size) < 5000) {
          uploadTask = storageRef.ref(`COURSES/${courseId}/images/${fileName}`).put(file)
          uploadTask.on(
            'state_changed',
            snapshot => {},
            error => {},

            () => {
              storageRef
                .ref('COURSES')
                .child(`${courseId}`)
                .child('images')
                .child(fileName)
                .getDownloadURL()
                .then(image => {
                  onChooseImage(image)
                  setState({ ...state, image })
                })
            }
          )
        } else {
          setState({
            ...state,
            error: true,
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChooseImage, courseId]
  )

  const { info, image, error } = state

  return (
    <>
      <div className="modal-media-container" style={{ paddingBottom: isNewClass && '10px' }}>
        <div className="left-side">
          <div
            className="image-container"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </div>
        <div className="right-side">
          <span className="media-title">{mediaTitle}</span>
          <Button className="fileButton" onClick={handleClickFile}>
            <span className="uploadText">{inputFileTitle}</span>
            <span className="fileSizeText">{inputFileSize}</span>
          </Button>
          <input
            className="hiddenFileInput"
            ref={hiddenFileInput}
            onChange={handleChooseFile}
            type="file"
            accept="image/*"
          />
          <span className="fileInfo">{info}</span>
          {error && <span className="fileInfoError">Файл слишком тяжелый :(</span>}
        </div>
      </div>
    </>
  )
}

export default ModalMedia
