import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'
import Cross from '@assets/images/blocks/cross.svg'
import Comment from '@assets/images/blocks/comment.svg'
import Plus from '@assets/images/blocks/plus.svg'
import Tick from '@assets/images/blocks/tick.svg'
import { Tooltip, withStyles } from '@material-ui/core'
import Spinner from 'react-spinkit'
import LoaderChecked from '../../assets/icons/checked.svg'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(197, 207, 221, 1)',
    color: '#414752',
    fontSize: 10,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
    fontWeight: 600,
  },
}))(Tooltip)

export const TestBlock = ({ onStateChange, id, preloadedState, isFactory, additions }) => {
  const initialState = {
    question: '',
    isVarious: false,
    answers: [
      {
        id: 1,
        text: '',
        comment: false,
        commentText: '',
        selected: true,
      },
    ],
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState(
    preloadedState || (additions?.withMarks ? { ...initialState, mark: 0 } : initialState)
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)

  useEffect(() => {
    onStateChange(id, state)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, id])

  const handleChangeVarious = () => {
    setState(prevState => ({
      ...prevState,
      isVarious: !state.isVarious,
      answers: prevState.answers.map(item => ({
        ...item,
        selected: false,
      })),
    }))
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }
  return (
    <div
      className="aaa"
      style={{
        marginLeft: isFactory ? 40 : 0,
      }}
    >
      <div
        className="test-block"
        style={{
          gridTemplateColumns: isFactory ? '100%  60px' : 'calc(100% - 60px) 60px',
        }}
      >
        <input
          type="text"
          className="test-area"
          placeholder="Enter a question in Spanish"
          onChange={e => {
            setState({
              ...state,
              question: e.target.value,
            })
            setLoading(true)

            setTimeout(() => {
              setLoading(false)
              setShowCheckmark(true)
              setTimeout(() => {
                setShowCheckmark(false)
              }, 2000)
            }, 3000)
          }}
          value={state.question}
        />
        {!isFactory && (
          <img
            onClick={() => {
              setState({
                ...state,
                question: '',
              })
              setLoading(true)

              setTimeout(() => {
                setLoading(false)
                setShowCheckmark(true)
                setTimeout(() => {
                  setShowCheckmark(false)
                }, 2000)
              }, 3000)
            }}
            style={{ padding: '0 20px', cursor: 'pointer' }}
            src={Cross}
            alt=""
          />
        )}
      </div>
      <div className="title-block-switch">
        <Switch
          className="test-switch"
          width={30}
          height={16}
          handleDiameter={12}
          uncheckedIcon={null}
          offHandleColor="#8CA4C0"
          onHandleColor="#2C82FF"
          offColor="#DDE4EF"
          onColor="#C7DBFF"
          activeBoxShadow={0}
          checkedIcon={<></>}
          onChange={handleChangeVarious}
          checked={state.isVarious}
        />
        <span className="test-title-switch">A few correct answers</span>
      </div>
      {state.answers.map(({ id, text, comment, commentText, selected }) => (
        <React.Fragment key={id}>
          <div
            className="test-block-answer-container"
            style={{
              gridTemplateColumns: isFactory
                ? ' 40px calc(100% - 80px)'
                : ' 40px calc(100% - 100px)',
            }}
          >
            {state.isVarious ? (
              <div
                className="test-checkbox"
                style={{
                  border: selected ? 0 : '',
                  background: selected ? '#0053D7' : '',
                }}
                onClick={() => {
                  setState({
                    ...state,
                    answers: state.answers.map(answer => {
                      if (answer.id === id) {
                        return {
                          ...answer,
                          selected: !answer.selected,
                        }
                      }
                      return answer
                    }),
                  })
                  setLoading(true)

                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
              >
                {selected ? <img src={Tick} alt="" /> : null}
              </div>
            ) : (
              <div
                style={{ backgroundColor: selected ? '#0053D7' : '' }}
                className="test-check-box"
                onClick={() => {
                  setState({
                    ...state,
                    answers: state.answers.map(answer => {
                      if (answer.id === id) {
                        return {
                          ...answer,
                          selected: true,
                        }
                      } else {
                        return {
                          ...answer,
                          selected: false,
                        }
                      }
                    }),
                  })
                  setLoading(true)

                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
              />
            )}
            <div className="test-block-answer">
              <input
                type="text"
                className="input-test-answer"
                placeholder="Enter answer text"
                onChange={e => {
                  setState({
                    ...state,
                    answers: state.answers.map(answer => {
                      if (answer.id === id) {
                        return {
                          ...answer,
                          text: e.target.value,
                        }
                      }
                      return answer
                    }),
                  })
                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
                value={text}
              />
              <HtmlTooltip
                placement="left"
                title="Комментарий к варианту ответа. Ученик его увидит после того,как выберет этот ответ"
              >
                <img
                  alt=""
                  style={{ marginRight: 22 }}
                  src={Comment}
                  onClick={() => {
                    setState({
                      ...state,
                      answers: state.answers.map(answer => {
                        if (answer.id === id) {
                          return {
                            ...answer,
                            comment: true,
                          }
                        }
                        return answer
                      }),
                    })
                    setLoading(true)

                    setTimeout(() => {
                      setLoading(false)
                      setShowCheckmark(true)
                      setTimeout(() => {
                        setShowCheckmark(false)
                      }, 2000)
                    }, 3000)
                  }}
                />
              </HtmlTooltip>
              <img
                onClick={() => {
                  setState({
                    ...state,
                    answers: state.answers.filter(item => item.id !== id),
                  })
                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
                src={Cross}
                alt=""
              />
            </div>
          </div>
          {comment ? (
            <div className="test-block-comment">
              <input
                className="test-block-comment-area"
                value={commentText}
                onChange={e => {
                  setState({
                    ...state,
                    answers: state.answers.map(answer => {
                      if (answer.id === id) {
                        return {
                          ...answer,
                          commentText: e.target.value,
                        }
                      }
                      return answer
                    }),
                  })
                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
              />
              <img
                onClick={() => {
                  setState({
                    ...state,
                    answers: state.answers.map(answer => {
                      if (answer.id === id) {
                        return {
                          ...answer,
                          comment: false,
                        }
                      }
                      return answer
                    }),
                  })
                  setTimeout(() => {
                    setLoading(false)
                    setShowCheckmark(true)
                    setTimeout(() => {
                      setShowCheckmark(false)
                    }, 2000)
                  }, 3000)
                }}
                style={{ marginLeft: 22 }}
                src={Cross}
                alt=""
              />
            </div>
          ) : null}
        </React.Fragment>
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          className="centered-icon-voc"
        >
          <div style={additions?.withMarks ? { width: 330 } : {}}></div>
          <img
            onClick={() => {
              setState({
                ...state,
                answers: [
                  ...state.answers,
                  {
                    id: state.answers.length ? state.answers[state.answers.length - 1].id + 1 : 0,
                    text: '',
                    comment: null,
                    selected: false,
                  },
                ],
              })
              setLoading(true)

              setTimeout(() => {
                setLoading(false)
                setShowCheckmark(true)
                setTimeout(() => {
                  setShowCheckmark(false)
                }, 2000)
              }, 3000)
            }}
            style={{ cursor: 'pointer' }}
            src={Plus}
            alt=""
          />
          <div>
            {additions?.withMarks ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: 60,
                }}
              >
                <span style={{ color: '#7E92AC', paddingRight: 16, fontWeight: 400, fontSize: 16 }}>
                  Балл за верный ответ:
                </span>
                <input
                  style={{
                    border: '1px solid #C2CFE0',
                    padding: 10,
                    textAlign: 'center',
                    color: 'rgb(65, 71, 82)',
                    width: 80,
                    fontSize: 16,
                  }}
                  type="number"
                  max="100"
                  value={state.mark}
                  onChange={event => {
                    const value = event.target.value

                    if (parseInt(value) > 100) {
                      setState({ ...state, mark: 100 })
                    } else {
                      setState({ ...state, mark: parseInt(value) })
                    }
                  }}
                />
                <div style={{ width: '20px', position: 'relative' }}>
                  {isLoading && (
                    <Spinner
                      name="line-spin-fade-loader"
                      color="#FF852D"
                      style={{
                        scale: '0.4',
                        top: '15px',
                        left: '-20px',
                        marginLeft: 'auto',
                      }}
                    />
                  )}
                  {showCheckmark && (
                    <img
                      style={{
                        right: '20px',
                        top: '10px',
                        position: 'absolute',
                      }}
                      className="checkmark"
                      src={LoaderChecked}
                      alt=""
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
