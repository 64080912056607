import React from 'react'


export default function PlusIcon({ withBG, ...rest }) {
	if ( withBG ) {
		return (
			<svg { ...rest } viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
				<path d='m10 0c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm5 11h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z' />
			</svg>
		)
	} else {
		return (
			<svg { ...rest } viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
				<path d='m30 7.5c-12.426 0-22.5 10.074-22.5 22.5s10.074 22.5 22.5 22.5 22.5-10.074 22.5-22.5-10.074-22.5-22.5-22.5zm-27.5 22.5c0-15.188 12.312-27.5 27.5-27.5s27.5 12.312 27.5 27.5-12.312 27.5-27.5 27.5-27.5-12.312-27.5-27.5z' />
				<path d='m30 17.5c1.3807 0 2.5 1.1193 2.5 2.5v20c0 1.3807-1.1193 2.5-2.5 2.5s-2.5-1.1193-2.5-2.5v-20c0-1.3807 1.1193-2.5 2.5-2.5z' />
				<path d='m17.5 30c0-1.3807 1.1193-2.5 2.5-2.5h20c1.3807 0 2.5 1.1193 2.5 2.5s-1.1193 2.5-2.5 2.5h-20c-1.3807 0-2.5-1.1193-2.5-2.5z' />
			</svg>
		)
	}
}

PlusIcon.defaultProps = {
	withBG: false
}