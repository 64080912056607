const GET_CONSTRUCTOR = 'GET_CONSTRUCTOR';
const GET_CONSTRUCTOR_FULFILLED = 'GET_CONSTRUCTOR_FULFILLED';
const GET_CONSTRUCTOR_REJECTED = 'GET_CONSTRUCTOR_REJECTED';

const CREATE_CONSTRUCTOR = 'CREATE_CONSTRUCTOR';
const RESET_CONSTRUCTOR = 'RESET_CONSTRUCTOR';

export {
  GET_CONSTRUCTOR,
  GET_CONSTRUCTOR_FULFILLED,
  GET_CONSTRUCTOR_REJECTED,
  CREATE_CONSTRUCTOR,
  RESET_CONSTRUCTOR
};
