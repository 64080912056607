import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import EmptyPageImg from '@images/empty_page_on_spanish.svg'

import { Text } from '../Text'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 160px;
  align-items: center;
`

const EmptyPage = ({
  text,
  customContainerStyles,
}: {
  text: string
  customContainerStyles?: CSSProperties
}) => {
  return (
    <Container style={customContainerStyles}>
      <img src={EmptyPageImg} />
      <Text
        display="smallContent"
        center
        style={{ fontWeight: 600, maxWidth: '331px', marginTop: '60px' }}
      >
        {text}
      </Text>
    </Container>
  )
}

export default EmptyPage
