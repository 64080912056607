import React from 'react'
function TelegramIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.8002 17.5C9.41144 17.5 9.4775 17.3532 9.34342 16.9831L8.2002 13.2206L17.0002 8"
        fill="#C8DAEA"
      />
      <path
        d="M9.7998 17.5C10.0998 17.5 10.2324 17.3628 10.3998 17.2L11.9998 15.6442L10.004 14.4407"
        fill="#A9C9DD"
      />
      <path
        d="M10.0037 14.441L14.8397 18.0139C15.3916 18.3184 15.7899 18.1607 15.9273 17.5015L17.8958 8.22524C18.0974 7.41722 17.5878 7.05074 17.0599 7.29042L5.50088 11.7475C4.71187 12.064 4.71647 12.5042 5.35706 12.7003L8.32336 13.6262L15.1907 9.29365C15.5149 9.09706 15.8124 9.20275 15.5682 9.41949"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.0012"
          y1="1.0008"
          x2="3.0012"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37AEE2" />
          <stop offset="1" stopColor="#1E96C8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="10.4975"
          y1="12.7371"
          x2="11.7797"
          y2="16.8846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF7FC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TelegramIcon
