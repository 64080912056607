import * as React from 'react'

const FacebookIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM19.8761 18.7907V28.5836H15.8243V18.791H13.8V15.4163H15.8243V13.3902C15.8243 10.6371 16.9673 9 20.2149 9H22.9185V12.3751H21.2285C19.9643 12.3751 19.8807 12.8467 19.8807 13.7269L19.8761 15.416H22.9377L22.5794 18.7907H19.8761Z"
        fill="#9ABDFC"
      />
    </svg>
  )
}

export default FacebookIcon
