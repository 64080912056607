import { studentsRef, promoCodesRef, paymentsRef, authRef } from '@configs/firebase'
import { IPayment } from '@models/IProfile'

export const checkPromoCode = async (promoCode: string): Promise<boolean> => {
  const { uid } = authRef.currentUser

  // check if the promo code is activated
  const paymentsSnap = await paymentsRef.doc(uid).get()
  const payments: IPayment[] = paymentsSnap.exists ? paymentsSnap.data().data : []
  if (payments.find(payment => payment?.promoCode === promoCode)) return false

  const isNotPaid = (payments.length === 1 && payments[0].package === 0) || payments.length === 0
  const isTrialLesson =
    payments.filter(payment => payment.package === 0 && payment.amount === 0).length > 0

  // checking if other students have a promo code
  const studentsSnap = await studentsRef
    .where('promoCode', '==', promoCode)
    .where('id', '!=', uid)
    .limit(1)
    .get()
  // we have and paid zero times

  if (!studentsSnap.empty && payments.length < 2) {
    return true
  } else if (!studentsSnap.empty && payments.length >= 2) {
    return false
  } else {
    // is there a promo code not from users
    const promoCodeSnap = await promoCodesRef.doc(promoCode).get()
    if (promoCodeSnap.exists) {
      if (
        promoCodeSnap.data().firstPayment &&
        promoCodeSnap.data().isTrial === false &&
        !isTrialLesson &&
        !isNotPaid
      ) {
        return true
      }
      if (!isNotPaid && (promoCodeSnap.data().isFirst || promoCodeSnap.data().firstPayment)) {
        return false // check only for first time
      }
      return !promoCodeSnap.data().firstPayment || isNotPaid
    } else {
      return false
    }
  }
}

export const checkMiniPromoCode = async (promoCode: string): Promise<boolean> => {
  const promoCodeSnap = await promoCodesRef.doc(promoCode).get()
  if (promoCodeSnap.exists) {
    return promoCodeSnap.data().miniCourseID.length > 0
  }
  return false
}

export const fetchMiniDiscount = async (promoCode: string, courseId: string): Promise<number> => {
  const promoCodeSnap = await promoCodesRef.doc(promoCode.trim()).get()
  if (promoCodeSnap.exists) {
    if (promoCodeSnap.data().miniCourseID?.find((id: string) => courseId === id)) {
      return promoCodeSnap.data().discount || 0
    }
  }
  return 0
}
