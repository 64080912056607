import * as React from 'react'

const ProfileMenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.36095 1.72454C6.28678 1.40852 6.06672 1.15443 5.77698 1.05025C5.48724 0.946068 5.16764 1.00612 4.92885 1.20961L2.68395 3.12266C2.51897 3.26325 2.40581 3.4613 2.36438 3.68199L1.01744 10.8559C0.977237 11.07 1.00702 11.2923 1.10184 11.4859L6.4896 22.4859C6.64435 22.8019 6.95116 23 7.28572 23H16.7143C17.0488 23 17.3556 22.8019 17.5104 22.4859L22.8982 11.4859C22.993 11.2923 23.0228 11.07 22.9826 10.8559L21.6356 3.68199C21.5889 3.43323 21.4514 3.21442 21.2532 3.0737L18.5593 1.16066C18.2838 0.964982 17.9295 0.946739 17.6375 1.11319C17.3455 1.27964 17.1633 1.60377 17.1633 1.95653V3.71435L16.5161 5.78262H7.48395L6.80156 3.60194L6.36095 1.72454ZM18.9592 3.86958V3.74381L19.9475 4.44567L21.1594 10.9002L18.5124 16.3045H5.48761L2.84061 10.9002L4.06127 4.39883L4.95804 3.63463L5.06763 4.10157C5.0732 4.12532 5.07963 4.14883 5.0869 4.17205L5.98486 7.04162C6.10708 7.43221 6.45023 7.69566 6.83674 7.69566H17.1633C17.5498 7.69566 17.8929 7.43221 18.0151 7.04162L18.9131 4.17205C18.9436 4.07452 18.9592 3.97238 18.9592 3.86958ZM6.42461 18.2175L7.83004 21.087H16.17L17.5754 18.2175H6.42461ZM9.03079 13.4348C9.03079 14.2272 8.42775 14.8696 7.68385 14.8696C6.93996 14.8696 6.33691 14.2272 6.33691 13.4348C6.33691 12.6424 6.93996 12 7.68385 12C8.42775 12 9.03079 12.6424 9.03079 13.4348ZM16.7652 14.8696C17.509 14.8696 18.1121 14.2272 18.1121 13.4348C18.1121 12.6424 17.509 12 16.7652 12C16.0213 12 15.4182 12.6424 15.4182 13.4348C15.4182 14.2272 16.0213 14.8696 16.7652 14.8696Z"
      />
    </svg>
  )
}

export default ProfileMenuIcon
