import React from 'react';

const NotBorderedTableIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5455 4H7.81783H6.36328H3.45455C2.65122 4 2 4.63207 2 5.41176V14.5882C2 15.3679 2.65122 16 3.45455 16H6.36328H7.81783H11.7578C11.4733 15.575 11.2593 15.0989 11.1325 14.5883H7.81783V12.4703H11.1183C11.2376 11.961 11.4433 11.485 11.7192 11.0586H7.81783V8.94128H16.5479V9.12266C17.0738 9.24809 17.5638 9.46582 18 9.75777V5.41176C18 4.63207 17.3488 4 16.5455 4ZM3.45703 5.41181H6.36328V7.52952H3.45703V5.41181ZM7.81783 5.41181V7.52952H16.5479V5.41181H7.81783ZM3.45703 8.94128H6.36328V11.0586H3.45703V8.94128ZM3.45703 12.4703H6.36328V14.5883H3.45703V12.4703Z"
      fill="currentColor"
    />
    <rect
      x="13"
      y="11.8332"
      width="1.17851"
      height="5.89256"
      rx="0.589256"
      transform="rotate(-45 13 11.8332)"
      fill="currentColor"
    />
    <rect
      width="1.17851"
      height="5.89256"
      rx="0.589256"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 18 11.8332)"
      fill="currentColor"
    />
  </svg>
);

export { NotBorderedTableIcon };
