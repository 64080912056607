import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(65, 71, 82, 0.8)',
    color: '#fff',
    fontSize: 14,
    fontFamily: 'Open Sans',
    padding: '6px 10px',
    borderRadius: 8,
  },
}))(Tooltip);

export { HtmlTooltip };
