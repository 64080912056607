import React from 'react'
export default function EnterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17 6.5C17 6.22386 16.7761 6 16.5 6C16.2239 6 16 6.22386 16 6.5H17ZM16.5 13.5V14H17V13.5H16.5ZM4.64645 13.1464C4.45118 13.3417 4.45118 13.6583 4.64645 13.8536L7.82843 17.0355C8.02369 17.2308 8.34027 17.2308 8.53553 17.0355C8.7308 16.8403 8.7308 16.5237 8.53553 16.3284L5.70711 13.5L8.53553 10.6716C8.7308 10.4763 8.7308 10.1597 8.53553 9.96447C8.34027 9.7692 8.02369 9.7692 7.82843 9.96447L4.64645 13.1464ZM16 6.5V13.5H17V6.5H16ZM16.5 13L5 13V14L16.5 14V13Z"
        fill="#90A4BD"
      />
    </svg>
  )
}
