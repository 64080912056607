import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { CheckedIcon, UncheckedIcon } from '../Student/Icons'

export const CustomControlLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  &:nth-child(2n) {
    padding: 15px 0;
  }
  & .MuiButtonBase-root {
    padding: 0;
    margin-right: 10px;
  }
  & .MuiFormControlLabel-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #414752;
  }
`

interface Props {
  checked: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  name: string
  style?: React.CSSProperties
  className?: string
  checkedIcon?: React.ReactNode
  icon?: React.ReactNode
}

const CustomMaterialCheckbox = forwardRef(
  (
    { checked, onChange, name, style, className, checkedIcon, icon }: Props,
    ref
  ) => {
    return (
      <CustomControlLabel
        style={style}
        className={className}
        ref={ref}
        control={
          <Checkbox
            checked={checked ?? false}
            onChange={onChange}
            name={name}
            color="primary"
            checkedIcon={checkedIcon ?? <CheckedIcon />}
            icon={icon ?? <UncheckedIcon />}
          />
        }
        label={name}
      />
    )
  }
)

export default CustomMaterialCheckbox
