import React from 'react'

const EditIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.75 16L4.04289 15.2929C3.85536 15.4804 3.75 15.7348 3.75 16H4.75ZM17.75 3L18.4571 2.29289C18.0666 1.90237 17.4334 1.90237 17.0429 2.29289L17.75 3ZM21.75 7L22.4571 7.70711C22.8476 7.31658 22.8476 6.68342 22.4571 6.29289L21.75 7ZM8.75 20V21C9.01522 21 9.26957 20.8946 9.45711 20.7071L8.75 20ZM4.75 20H3.75C3.75 20.5523 4.19772 21 4.75 21V20ZM12.75 19C12.1977 19 11.75 19.4477 11.75 20C11.75 20.5523 12.1977 21 12.75 21V19ZM21.25 21C21.8023 21 22.25 20.5523 22.25 20C22.25 19.4477 21.8023 19 21.25 19V21ZM5.45711 16.7071L18.4571 3.70711L17.0429 2.29289L4.04289 15.2929L5.45711 16.7071ZM17.0429 3.70711L21.0429 7.70711L22.4571 6.29289L18.4571 2.29289L17.0429 3.70711ZM21.0429 6.29289L8.04289 19.2929L9.45711 20.7071L22.4571 7.70711L21.0429 6.29289ZM8.75 19H4.75V21H8.75V19ZM5.75 20V16H3.75V20H5.75ZM14.0429 6.70711L18.0429 10.7071L19.4571 9.29289L15.4571 5.29289L14.0429 6.70711ZM12.75 21H21.25V19H12.75V21Z"
      fill="#7E92AC"
    />
  </svg>
)
export { EditIcon }
