import Dropdown from 'rc-dropdown'
import React, { useMemo, useState } from 'react'
import * as Styled from './result-teachers.styles'
import { useTranslation } from 'react-i18next'

export default function({
  items,
  selected,
  onChange,
  title,
  width,
}: {
  items: { label: string; value: string }[]
  selected?: string
  onChange: (value: string) => void
  title: string
  width: number
}) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const onVisible = (val: boolean) => {
    setOpen(val)
  }

  const selectedItem = useMemo(() => {
    return items.find(item => item.value === selected)
  }, [selected, items])

  return (
    <Styled.DropdownContainer>
      <Styled.DropDownTitle>{title}</Styled.DropDownTitle>

      <Dropdown
        trigger="click"
        animation="slide-up"
        overlayStyle={{ top: -10 }}
        onVisibleChange={onVisible}
        overlay={
          <Styled.DropdownItems style={{ minWidth: width, maxWidth: width }}>
            {items.map(item => (
              <Styled.DropdownItem
                key={item.value}
                onClick={() => {
                  setOpen(false)
                  onChange(item.value)
                }}
              >
                {t(item.label)}
              </Styled.DropdownItem>
            ))}
          </Styled.DropdownItems>
        }
      >
        <Styled.DropdownLabel open={open} style={{ minWidth: width }}>
          <span style={{ marginRight: 10 }}>{t(selectedItem?.label)} </span>

          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L11.3033 1.75736C11.5962 1.46447 11.5962 0.989593 11.3033 0.696699C11.0104 0.403806 10.5355 0.403806 10.2426 0.696699L6 4.93934L1.75736 0.696699C1.46447 0.403806 0.989593 0.403806 0.696699 0.696699C0.403806 0.989593 0.403806 1.46447 0.696699 1.75736L5.46967 6.53033ZM5.25 5V6H6.75V5H5.25Z"
              fill="#9ABDFC"
            />
          </svg>
        </Styled.DropdownLabel>
      </Dropdown>
    </Styled.DropdownContainer>
  )
}
