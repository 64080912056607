import React from 'react'

const IconInfo = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
        fill="white"
      />
      <path
        d="M8 11.3333L8 6.66667M8 4.67331V4.66665M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
        stroke="#4284FF"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default IconInfo
