import React from 'react';

const FontSizePlusIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.26536 11.6667L6.2487 6.39166L8.2237 11.6667H4.26536ZM5.41536 4.16666L0.832031 15.8333H2.70703L3.64036 13.3333H8.8487L9.79036 15.8333H11.6654L7.08203 4.16666H5.41536ZM14.9987 5.83333L10.832 10.0583L12.007 11.25L14.1654 9.08333V14.1667H15.832V9.08333L17.9904 11.25L19.1654 10.0583L14.9987 5.83333Z"
      fill="currentColor"
    />
  </svg>
);

export { FontSizePlusIcon };
