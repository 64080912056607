import * as React from 'react';

const VectorRightIcon = (props) => {
  return (
    <svg {...props} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M0.458259 1.61635L6.17957 15.6578C6.32323 16.0104 6.79253 16.0794 7.03167 15.7832L16.5717 3.96476C16.815 3.66337 16.638 3.21175 16.2547 3.15592L0.993368 0.932899C0.610734 0.877162 0.312353 1.25826 0.458259 1.61635Z'
        fill='#9ED1FF'
      />
    </svg>
  );
};

export default VectorRightIcon;
