import styled, { css } from 'styled-components'
import { colors } from './global'
import { rem } from './fuctions'

export const SectionTitle = styled.h1`
  margin: 19px 45px 35px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  font-size: 30px;
  text-transform: capitalize;
  color: ${colors.black};
`

interface IUnderLineInputProps {
  underLine?: boolean
  white?: boolean
}
export const UnderLineInput = styled.input<IUnderLineInputProps>`
  ${props =>
    props.underLine &&
    css`
      width: 100%;
      border: 1px solid transparent;
      border-bottom-color: ${colors.underLine};
      caret-color: ${colors.underLineCaret};
      margin: 30px 0 60px;
      padding: 2px 15px;
      font-size: 20px;
      box-sizing: border-box;
      &:focus {
        border-bottom-color: ${colors.underLineCaret};
      }
    `}

  ${props =>
    props.white &&
    css`
      max-width: 360px;
      padding: 0 20px;
      height: 50px;
      width: 100%;
      border: 1px solid ${colors.subGray};
      box-sizing: border-box;
      border-radius: 40px;
      display: block;
      transition: all 0.3s;

      &[disabled] {
        background-color: ${colors.gainsborough};
      }

      &:focus {
        border-color: ${colors.orange};
      }
    `}
`

export const ErrorText = styled.p`
  color: ${colors.red};
  font-size: 18px;
  margin-bottom: 20px;
`

export const SubHeader = styled.p`
  font-weight: 600;
`

export const Divider = styled.div`
  transform: rotate(-0.28deg);
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c2cfe0;
  margin: 20px 35% 20px 0;
  padding: 0;
`

interface InputProps {
  small?: boolean
  medium?: boolean
  error?: boolean
}
export const StyledLabelError = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  line-height: 16px;
  margin-left: 45px;
  color: #ff0000;
`

export const StyledInput = styled.input<InputProps>`
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  max-width: 500px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  border-radius: 20px;
  margin-left: 20px;
  background-color: ${props => props.theme.colors.bgDashboard};

  color: ${props => (props.error ? '#FF0000' : '#414752')};

  &:focus {
    border-color: ${colors.underLineCaret};
  }

  ${props =>
    props.medium &&
    css`
      height: 40px;
      width: 280px;
      font-size: 18px;

      padding: 7.5px 20px;
    `}

  ${props =>
    props.small &&
    css`
      width: 132px;
      font-size: 16px;
      padding: 8px 15px;
      height: 36px;
    `}
`

StyledInput.defaultProps = {
  medium: true,
}

export const StyledLabel = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;

  color: #90a4bd;

  width: 190px;
  font-size: 14px;

  @media (max-width: 576px) {
    margin-bottom: ${rem(16)};
  }
`

export const StyledField = styled.div`
  display: flex;
  margin-bottom: 14px;

  @media (min-width: 576px) {
    align-items: center;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const TextAreaContent = styled.div`
  display: flex;
  margin-bottom: 14px;
  @media (max-width: 576px) {
    flex-direction: column;
  }

  textarea::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #90a4bd;
  }
  textarea {
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.bgDashboard};

    color: #414752;
    resize: 'none';
    &:focus {
      border-color: ${colors.underLineCaret};
    }

    @media (min-width: 576px) {
      width: 560px;
      height: 136px;
      border-radius: 20px;
      font-weight: 500;
      font-size: 14px;
      padding: 15px 20px;
      border: 1px solid #c2cfe0;
    }

    @media (max-width: 576px) {
      width: ${rem(400)};
      height: ${rem(100)};
      border-radius: ${rem(20)};
      font-weight: ${rem(500)};
      font-size: ${rem(12)};
      padding: ${rem(15)} ${rem(20)};
      border: ${rem(1)} solid #c2cfe0;
    }
  }
`

export const ImageDowload = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  .avatar {
    width: 150px;
    height: 150px;
    background: '#DADADA';
  }
`

export const WidthInput = styled.span<InputProps>`
  border: 1px solid #c2c2c2;
  box-sizing: border-box;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  border-radius: 20px;
  margin-left: 20px;
  position: absolute;
  visibility: hidden;

  color: #414752;
  &:focus {
    border-color: ${colors.underLineCaret};
  }

  ${props =>
    props.medium &&
    css`
      height: 40px;
      width: 280px;
      max-width: 400px;
      font-size: 18px;

      padding: 7.5px 20px;
    `}

  ${props =>
    props.small &&
    css`
      max-width: 280px;
      font-size: 16px;
      padding: 8px 15px;
      height: 36px;
    `}
`
