import styled from 'styled-components';

export const CurrencySwitchContainer = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const ChosenOption = styled.div`
  color: #346a93;
  padding: 11px 10px;
  cursor: pointer;
  display: flex;
  align-items:center;
  > img {
    margin-left: 12px;
  }
`;

export const Options = styled.div`
  background: #ffffff;
  box-shadow: 10px 10px 40px rgba(204, 222, 253, 0.4);
  border-radius: 20px;
  padding: 20px 0;
  box-sizing: border-box;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
`;
export const OptionItem = styled.div`
  cursor: pointer;
  color: #414752;
  padding: 5px 20px;
  &:hover {
    background-color:#9ABDFC;
  }
`;
