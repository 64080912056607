import React from 'react';

const BackgroundColorIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.668 1.66666H3.33464C2.41416 1.66666 1.66797 2.41286 1.66797 3.33333V16.6667C1.66797 17.5871 2.41416 18.3333 3.33464 18.3333H16.668C17.5884 18.3333 18.3346 17.5871 18.3346 16.6667V3.33333C18.3346 2.41286 17.5884 1.66666 16.668 1.66666ZM3.33464 16.6667V3.33333H16.668V16.6667H3.33464ZM12.3346 12.5H7.6679L6.66797 15H5.0013L9.16797 5H10.8346L15.0013 15H13.3346L12.3346 12.5ZM10.0011 6.66666L11.6678 10.8333H8.3345L10.0011 6.66666Z"
      fill="currentColor"
    />
  </svg>
);

export { BackgroundColorIcon };
