import React from 'react'
function MuteVolumeIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57489 3.07403C8.83474 3.19892 9 3.4617 9 3.75001V14.25C9 14.5383 8.83474 14.8011 8.57489 14.926C8.31504 15.0509 8.00661 15.0158 7.78148 14.8357L4.23691 12H1.5C1.08579 12 0.75 11.6642 0.75 11.25V6.75001C0.75 6.33579 1.08579 6.00001 1.5 6.00001H4.23691L7.78148 3.16436C8.00661 2.98425 8.31504 2.94914 8.57489 3.07403ZM7.5 5.31048L4.96852 7.33566C4.83554 7.44205 4.6703 7.50001 4.5 7.50001H2.25V10.5H4.5C4.6703 10.5 4.83554 10.558 4.96852 10.6644L7.5 12.6895V5.31048Z"
        fill={props.color}
      />
    </svg>
  )
}

export default MuteVolumeIcon
