import {
  ProfileActionsTypes,
  SET_PROFILE,
  UPDATE_BUSINESS_HOURS,
  SET_PROFILE_FILES,
  SET_PROFILE_TEACHER,
  UPDATE_PROFILE_FILE,
  DELETE_PROFILE_FILE,
  SET_IS_STUDENT,
  SET_STUDENT_PROMO_CODE,
  SET_STUDENT_PAYMENT,
} from './types'
import { IProfile, IBusinessHours, ILibraryFile, IStudentProfile, IPayment } from '@models/IProfile'

export const setProfileAction = (profileData: IProfile | IStudentProfile): ProfileActionsTypes => {
  return {
    type: SET_PROFILE,
    payload: profileData,
  }
}

export const setProfileTeacherAction = (profileData: IProfile): ProfileActionsTypes => {
  return {
    type: SET_PROFILE_TEACHER,
    payload: profileData,
  }
}
export const updateBusinessHoursProfileAction = (data: IBusinessHours[]): ProfileActionsTypes => {
  return {
    type: UPDATE_BUSINESS_HOURS,
    payload: data,
  }
}

export const setProfileFilesAction = (data: ILibraryFile[]): ProfileActionsTypes => {
  return {
    type: SET_PROFILE_FILES,
    payload: data,
  }
}

export const updateProfileFileAction = (data: ILibraryFile): ProfileActionsTypes => {
  return {
    type: UPDATE_PROFILE_FILE,
    payload: data,
  }
}

export const deleteProfileFileAction = (data: string): ProfileActionsTypes => {
  return {
    type: DELETE_PROFILE_FILE,
    payload: data,
  }
}

export const setIsStudent = (data: boolean): ProfileActionsTypes => {
  return {
    type: SET_IS_STUDENT,
    payload: data,
  }
}

export const setStudentPromoCode = (data: string): ProfileActionsTypes => {
  return {
    type: SET_STUDENT_PROMO_CODE,
    payload: data,
  }
}

export const setPayment = (data: IPayment[]): ProfileActionsTypes => {
  return {
    type: SET_STUDENT_PAYMENT,
    payload: data,
  }
}
