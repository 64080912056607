import { IClassroom, IPublishedCourseLessonInfo } from '@models'
import Pattern1 from '@components/Icons/pattern1.jpg'
import Pattern2 from '@components/Icons/pattern2.jpg'
import Pattern3 from '@components/Icons/pattern3.jpg'

export const filterClassRooms = (
  classRooms: IClassroom[],
  activeTab: string
): IClassroom[] => {
  if (activeTab === 'allClasses') return classRooms
  return classRooms.filter(({ student_uid }) => student_uid === activeTab)
}

export const rand = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const patterns: [string, string, string] = [Pattern1, Pattern2, Pattern3]

export const sortByOrder = (
  a: IPublishedCourseLessonInfo,
  b: IPublishedCourseLessonInfo
): number => a.order - b.order

export const sortByIsCompleted = (
  a: IPublishedCourseLessonInfo,
  b: IPublishedCourseLessonInfo
): number => Number(b.isCompleted) - Number(a.isCompleted)

export const sortLessons = (
  lessons: IPublishedCourseLessonInfo[]
): IPublishedCourseLessonInfo[] => {
  let completedLessons: IPublishedCourseLessonInfo[] = [],
    notStartedLessons: IPublishedCourseLessonInfo[] = [],
    currentLesson: IPublishedCourseLessonInfo = null
  lessons.forEach(l =>
    l.isCurrent
      ? (currentLesson = l)
      : l.isCompleted
      ? completedLessons.push(l)
      : notStartedLessons.push(l)
  )
  return [
    ...completedLessons.sort(sortByOrder),
    currentLesson,
    ...notStartedLessons.sort(sortByOrder),
  ].filter(Boolean)
}
