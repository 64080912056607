import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Card from '@components/common/Card'
import CreationModal from '@components/CreationModal'
import ActionCard from '@components/ActionCard'
import WarningModal from '@components/WarningModal'
import EditingModal from '@components/CreationModal'

import { createTest, deleteTest, duplicateTest, editTest } from '@actions'
import FeatherIcon from '@assets/images/Constructor/feather.svg'
import { toast } from 'react-toastify'

const testColors = ['#C3D0FF', '#DCC0FF', '#FFC8E2', '#C4FAF3', '#FFF6AA', '#FFE7C3']

const useMemoizedRandomColor = colors => {
  const memoizedColors = useMemo(() => {
    const generatedColors = {}
    return index => {
      if (!generatedColors[index]) {
        const randomIndex = Math.floor(Math.random() * colors.length)
        generatedColors[index] = colors[randomIndex]
      }
      return generatedColors[index]
    }
  }, [colors])

  return memoizedColors
}

const TestsTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedTestId, setSelectedTestId] = useState(null)
  const [visibilityCreationModal, setVisibilityCreationModal] = useState(false)
  const [visibilityDeletingModal, setVisibilityDeletingModal] = useState(false)
  const [visibilityEditModal, setVisibilityEditModal] = useState(false)
  const [testState, setTestState] = useState(null)

  const testsList = useSelector(store => store.tests.testsList)

  const handleOpenCreationModal = useCallback(() => {
    setVisibilityCreationModal(true)
  }, [])
  const getRandomColor = useMemoizedRandomColor(testColors)
  const handleCreateTest = useCallback(
    state => {
      setVisibilityCreationModal(false)
      dispatch(
        createTest({ ...state, timestamp: new Date().getTime(), color: getRandomColor(state.id) })
      )
      toast('Тест успешно создан :)', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-create-test-success',
      })
    },
    [dispatch, getRandomColor]
  )

  const handleDeleteTest = useCallback(id => {
    setVisibilityDeletingModal(true)
    setSelectedTestId(id)
  }, [])

  const handleDuplicateTest = useCallback(
    id => {
      dispatch(duplicateTest({ id }))
    },
    [dispatch]
  )

  const handleEditTest = useCallback(
    id => {
      const test = testsList.find(test => test.id === id)
      if (test) {
        setVisibilityEditModal(true)
        setTestState({ ...test })
      }
    },
    [testsList]
  )

  const handleRedirect = useCallback(id => {
    history.push(`tests/${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mockTestsList = useMemo(
    () =>
      testsList.map(({ id, name, time, description, color, updatedAt }) => {
        return (
          <ActionCard
            color={color}
            updatedAt={updatedAt}
            key={id}
            title={name}
            description={description}
            time={time}
            actions={[
              {
                id: 'edit-action',
                name: t('ActionCard.EditTestTitle'),
                onClick: () => {
                  handleEditTest(id)
                  setTestState({ id, name, time, description, updatedAt })
                },
              },
              {
                id: 'copy-action',
                name: t('ActionCard.CopyTestTitle'),
                onClick: () => {
                  navigator.clipboard.writeText(`'https://${window.location.hostname}/tests/${id}`)
                },
              },
              {
                id: 'duplicate-action',
                name: t('ActionCard.DuplicateTestTitle'),
                onClick: () => handleDuplicateTest(id),
              },
              {
                id: 'delete-action',
                name: t('ActionCard.DeleteTestTitle'),
                onClick: () => handleDeleteTest(id),
              },
            ]}
            onClick={() => handleRedirect(id)}
          />
        )
      }),
    [testsList, t, handleEditTest, handleDuplicateTest, handleDeleteTest, handleRedirect]
  )

  return (
    <>
      <div className="coursesTab cards-container">
        <Card className="big" onClick={handleOpenCreationModal}>
          <div className="creation-block-container">
            <img src={FeatherIcon} alt="feather" draggable={false} />
            <span>{t('Tests.CreateNewTest')}</span>
          </div>
        </Card>
        {mockTestsList}
        <CreationModal
          visibility={visibilityCreationModal}
          onSubmit={handleCreateTest}
          onClose={() => {
            setVisibilityCreationModal(false)
          }}
          isMediaBlockHidden
          isDescriptionBlockHidden
          localization={{
            title: t('TestModal.Title'),
            mediaTitle: t('TestModal.MediaTitle'),
            inputFileTitle: t('TestModal.InputFileTitle'),
            inputFileSize: t('TestModal.InputFileSize'),
            inputPlaceholderName: t('TestModal.InputPlaceholderName'),
            inputPlaceholderDescription: t('TestModal.InputPlaceholderDescription'),
            actionCreate: t('TestModal.ActionCreate'),
            actionCancelCreation: t('TestModal.ActionCancelCreation'),
            timePlaceholderTitle: t('TestModal.TimeTitle'),
          }}
        />
        <EditingModal
          isDescriptionBlockHidden
          visibility={visibilityEditModal}
          onSubmit={state => {
            setVisibilityEditModal(false)
            dispatch(
              editTest({ testId: testState.id, testData: { ...state, updatedAt: Date.now() } })
            )
            setTestState(null)
          }}
          onClose={() => {
            setVisibilityEditModal(false)
            setTestState(null)
          }}
          preloadedState={testsList.find(({ id }) => id === testState?.id)}
          isMediaBlockHidden
          localization={{
            title: t('TestModal.Title'),
            mediaTitle: t('TestModal.MediaTitle'),
            inputFileTitle: t('TestModal.InputFileTitle'),
            inputFileSize: t('TestModal.InputFileSize'),
            inputPlaceholderName: t('TestModal.InputPlaceholderName'),
            inputPlaceholderDescription: t('TestModal.InputPlaceholderDescription'),
            actionCreate: t('TestModal.ActionEdit'),
            actionCancelCreation: t('TestModal.ActionCancelCreation'),
            timePlaceholderTitle: t('TestModal.TimeTitle'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityDeletingModal}
          onSubmit={() => {
            selectedTestId && dispatch(deleteTest({ id: selectedTestId }))
            setVisibilityDeletingModal(false)
            setSelectedTestId(null)
          }}
          onClose={() => {
            setVisibilityDeletingModal(false)
            setSelectedTestId(null)
          }}
          localization={{
            title: t('TestWarningModalDeleteAction.Title'),
            description: t('TestWarningModalDeleteAction.Description'),
            actionCreate: t('TestWarningModalDeleteAction.ActionDelete'),
            actionCancelCreation: t('TestWarningModalDeleteAction.ActionCancelDelete'),
          }}
        />
      </div>
    </>
  )
}

export default TestsTab
