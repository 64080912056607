import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as moment from 'moment'
import { database as db, storageRef } from '@configs/firebase'
import { NAVIGATOR } from '@utils/constants'

import { IProfile, IStudentProfile } from '@models/IProfile'

import { fetchTeacherProfileByUid } from '@servise'

import { RootState } from '@store'

import { MainModal, Text } from '@components'

import InfoImg from '@images/info_bull.svg'
import InfoStartedLesson from '@images/info_started_lesson.svg'
import InfoStudentNotPayed from '@images/info_student_not_payed.svg'
import InfoStudentPayed from '@images/info_student_payed.svg'
import InfoFirstLoginStudent from '@images/info_first_login_student.svg'

import InfoContent from '@images/info_content.png'
import { useTranslation } from 'react-i18next'
import * as Styled from './info-block.styles'
import TeacherCard from '../TeacherCard'
import { Tooltip, TooltipContainer } from '@pages/student/student.styles'
import RescheduleLesson from '../RescheduleLesson'
import { IEvent } from '@models/ICalendar'
import { calendarUtil } from '@utils/calendarUtil'

interface INotificationContent {
  title: string
  description: string
  btnText?: string
  link?: string
  img?: any
}

const EVENT_SOON: INotificationContent = {
  title: 'EventSoonTitle',
  description: 'EventSoonDescription',
  btnText: 'EventSoonButton',
  link: NAVIGATOR.student_classes.path,
  img: InfoStartedLesson,
}

const STUDENT_PAID_FIRST_TIME: INotificationContent = {
  title: 'StudentPaidFistTimeTitle',
  description: 'StudentPaidFistTimeDesc2',
  img: InfoStudentPayed,
  btnText: 'StudentPaidFistTimeBtnText2',
  link: NAVIGATOR.auto_teacher_selection.path,
}
const STUDENT_PAID_SECOND_TIME: INotificationContent = {
  title: '¡Bienvenido, amigo!',
  description: 'StudentPaidSecondTimeDesc2',
  img: InfoStudentPayed,
  btnText: 'StudentPaidSecondTimeBtnText2 ',
  link: NAVIGATOR.auto_teacher_selection.path,
}

const STUDENT_NOT_PAID_SECOND_TIME: INotificationContent = {
  title: 'StudentNotPaidFirstTimeTitle',
  description: 'StudentNotPaidFirstTimeDesc',
  btnText: 'BuyNow',
  img: InfoStudentNotPayed,
}
const STUDENT_NOT_PAID_FIRST_TIME: INotificationContent = {
  title: 'StudentNotPaidSecondTimeTitle',
  description: 'StudentNotSecondFirstTimeDesc',
  btnText: 'BuyNow',
  img: InfoStudentNotPayed,
}

const InfoBlock = () => {
  const states = useSelector((state: RootState) => ({
    profile: { ...state.profile.profile } as IStudentProfile,
    classes: state.classroom.classrooms.length,
    events: state.calendar.events,
  }))

  const [notification, setNotification] = useState<INotificationContent>(null)
  const [prevNotification, setPrevNotification] = useState<INotificationContent>(null)
  const [hasEvent, setHasEvent] = useState<boolean>(false)
  const [rescheduleEvent, setRescheduleEvent] = useState<IEvent | null>(null)
  const [teacherEvent, setTeacherEvent] = useState<null | IProfile>(null)
  const [activeClass, setActiveClass] = useState(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenTransferLesson, setIsOpenTransferLesson] = useState(false)
  const { t, i18n } = useTranslation()

  const nowIsLesson = React.useMemo(() => {
    if (notification && notification.title === 'EventSoonTitle') {
      return true
    } else {
      return false
    }
  }, [notification])

  const history = useHistory()

  useEffect(() => {
    if (nowIsLesson) {
      const playAudio = () => {
        storageRef
          .ref('audio')
          .child('confinroom.mp3')
          .getDownloadURL()
          .then(url => {
            const audio = new Audio(url)
            audio.play()
          })
      }
      playAudio()
    }
  }, [nowIsLesson])

  useEffect(() => {
    if (activeClass) {
      setHasEvent(false)
      setNotification({ ...EVENT_SOON, link: activeClass })

      return
    }

    const isTrialLesson =
      (
        states?.profile?.payments?.filter(
          item => item.package > 0 || (item.amount === 0 && item.package === 0)
        ) || []
      ).length === 0

    if (isTrialLesson) {
      const nearestEvent = calendarUtil.getNearestEvent(states.events, states.profile.timeZone)

      if (nearestEvent) {
        if (nearestEvent?.isConfirm) {
          setNotification({
            ...STUDENT_PAID_FIRST_TIME,
            description: 'ScheduleIsInConfirmationMode',
          })
        } else {
          setHasEvent(true)
          setRescheduleEvent(nearestEvent)
          fetchTeacherProfileByUid(nearestEvent.teacherId).then(teacher => {
            setTeacherEvent({ ...teacher, id: nearestEvent.teacherId })
          })
          setNotification({
            img: InfoImg,
            title: 'NextLesson',
            description: nearestEvent.rrule.dtstart,
          })
        }
      } else {
        setNotification({
          ...STUDENT_PAID_FIRST_TIME,
        })
      }

      return
    }

    if (states.profile.balanceCount <= 0) {
      if (states.profile.passedLessons > 1) {
        setNotification({ ...STUDENT_NOT_PAID_SECOND_TIME })
      } else {
        setNotification({
          ...STUDENT_NOT_PAID_FIRST_TIME,
        })
      }
    } else if (states.events.length > 0) {
      const nearestEvent = calendarUtil.getNearestEvent(states.events, states.profile.timeZone)

      if (nearestEvent) {
        if (nearestEvent.isConfirm) {
          setNotification({
            ...STUDENT_PAID_FIRST_TIME,
            description: 'ScheduleIsInConfirmationMode',
          })
        } else {
          setHasEvent(true)
          setRescheduleEvent(nearestEvent)
          fetchTeacherProfileByUid(nearestEvent.teacherId).then(teacher => {
            setTeacherEvent({ ...teacher, id: nearestEvent.teacherId })
          })
          setNotification({
            img: InfoImg,
            title: 'NextLesson',
            description: nearestEvent.rrule.dtstart,
          })
        }
      } else {
        setNotification(STUDENT_PAID_SECOND_TIME)
      }
    } else {
      setNotification(STUDENT_PAID_FIRST_TIME)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    states.events,
    activeClass,
    states.profile.balanceCount,
    states.profile.student,
    states.profile.timeZone,
    states.profile.passedLessons,
  ])

  useEffect(() => {
    let intervalStartLesson
    let lessonListener = db.ref(`isStartLesson/${states.profile.id}`).on('value', snapshot => {
      const data = snapshot.val()
      if (data && data.start) {
        // previous classId behaviour for classes
        intervalStartLesson = setInterval(() => {
          const diffMinutes = moment()
            .tz(states.profile.timeZone)
            .diff(moment(data.start), 'minutes')

          if (diffMinutes < 55) {
            if (!prevNotification) {
              setPrevNotification({ ...notification })
            }

            if (data.classId) {
              // setActiveClass(data.classId)
              setNotification({ ...EVENT_SOON })
            }
            // new functionality for courses/lessons
            if (data.pathname) {
              setHasEvent(false)
              setNotification({
                ...EVENT_SOON,
                link: data.pathname.replace(
                  NAVIGATOR.teacher_courseroom.path,
                  NAVIGATOR.student_courseroom.path
                ),
              })
            }
          } else {
            if (prevNotification) {
              setNotification({ ...prevNotification })
            }

            setPrevNotification(null)
            setActiveClass(null)
            clearInterval(intervalStartLesson)
          }
        }, 1000)
      } else {
        setActiveClass(null)
        if (prevNotification) {
          setNotification({ ...prevNotification })
        }

        setPrevNotification(null)
      }
    })

    return () => {
      clearInterval(intervalStartLesson)
      db.ref(`isStartLesson/${states.profile.id}`).off('value', lessonListener)
    }
  }, [notification, prevNotification, states.profile.id, states.profile.timeZone])

  moment.locale(i18n.language)

  const dateIntimeZone = moment(new Date(notification?.description))

  return (
    <Styled.InfoBlock>
      <Styled.InfoBlockContainer>
        <Styled.InfoImg src={notification?.img || InfoFirstLoginStudent} />
        {!hasEvent && notification && (
          <Styled.InfoContent bgImg={InfoContent}>
            <div style={{ width: 'fit-content' }}>
              <Text display="infoTitle">{t(notification.title)}</Text>
              <Text display="infoContent" style={{ marginTop: '10px' }}>
                {t(notification.description)}
              </Text>
            </div>
            {notification.btnText && (
              <Styled.Button
                onClick={() => {
                  if (!notification.link) window.open(NAVIGATOR.buy.path, '_blank')
                  else history.push(notification.link)
                }}
              >
                <span>{t(notification.btnText)}</span>
              </Styled.Button>
            )}
          </Styled.InfoContent>
        )}
        {hasEvent && notification && notification.description !== EVENT_SOON.description && (
          <Styled.InfoContent bgImg={InfoContent} style={{ width: 561 }}>
            <div style={{ width: 'fit-content' }}>
              <Text display="infoTitle">{t(notification.title)}</Text>
              <Text display="infoDesc">
                <span style={{ textTransform: 'capitalize' }}>
                  {dateIntimeZone.locale(states.profile.lang || 'ru').format('dddd,')}{' '}
                </span>
                {dateIntimeZone.locale(states.profile.lang || 'ru').format('DD MMM')} {t('In')}{' '}
                {dateIntimeZone.format('HH:mm')}{' '}
                {calendarUtil.getTimezoneAbbr(states.profile.timeZone)}
              </Text>
              <Tooltip
                placement="right-start"
                title={<TooltipContainer>{t('Tooltip.Reschedule')}</TooltipContainer>}
              >
                <Styled.Reschedulelink
                  onClick={() => {
                    setIsOpenTransferLesson(true)
                  }}
                >
                  {t('Reschedule')}
                </Styled.Reschedulelink>
              </Tooltip>
            </div>
            {teacherEvent && (
              <div style={{ alignSelf: 'flex-end', marginLeft: 10 }}>
                <Text display="smallContent">{t('Teacher')}:</Text>
                <button onClick={() => setIsOpen(true)}>
                  <Text
                    display="smallContent"
                    underline
                  >{`${teacherEvent.name} ${teacherEvent.lastName}`}</Text>
                </button>
              </div>
            )}
          </Styled.InfoContent>
        )}
      </Styled.InfoBlockContainer>

      <MainModal
        open={isOpen}
        children={() => (
          <TeacherCard handleClose={() => setIsOpen(false)} teacherData={teacherEvent} />
        )}
        handleClose={() => setIsOpen(false)}
        maxWidth={'685px'}
      />

      <MainModal
        open={isOpenTransferLesson}
        children={() => (
          <RescheduleLesson
            event={rescheduleEvent}
            onClose={() => setIsOpenTransferLesson(false)}
          />
        )}
        handleClose={() => setIsOpenTransferLesson(false)}
        maxWidth={'650px'}
      />
    </Styled.InfoBlock>
  )
}

export default InfoBlock
