import { IFactoryExercise } from '@models/IFactory'

export const ADD_EXERCISE = 'ADD_EXERCISE'
export const SET_EXERCISES = 'SET_EXERCISES'
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE'
export const DELETE_EXERCISE = 'DELETE_EXERCISE'

export interface AddExerciseAction {
  type: typeof ADD_EXERCISE
  payload: IFactoryExercise
}
export interface SetExercisesAction {
  type: typeof SET_EXERCISES
  payload: IFactoryExercise[]
}
export interface UpdateExercisesAction {
  type: typeof UPDATE_EXERCISE
  payload: IFactoryExercise
}
export interface DeleteExercisesAction {
  type: typeof DELETE_EXERCISE
  payload: { exerciseId: string }
}
export type FactoryActionsTypes =
  | UpdateExercisesAction
  | SetExercisesAction
  | AddExerciseAction
  | DeleteExercisesAction
