import React from 'react'
import * as Styled from '../personal-info.styles'
import { IconButton } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import { useTranslation } from 'react-i18next'
import Step5 from '../Worksheet/Steps/Step5'

export default function({
  classFrequency,
  editType,
  handleClickEdit,
  onChange,
}: {
  classFrequency: number[]
  editType: string
  handleClickEdit: (data: string) => void
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()

  return (
    <>
      <Styled.PersonalInfoItem>
        <Styled.PersonalInfoLabel>
          Frequency of classes
        </Styled.PersonalInfoLabel>
        <Styled.PersonalItemCard>
          {`${classFrequency[0]}-${classFrequency[1]}`} hours per week
        </Styled.PersonalItemCard>
        {!editType && <div className="hover-block" />}
        <IconButton
          className="edit-btn"
          size="small"
          onClick={() => handleClickEdit('classFrequency')}
        >
          {editType === 'classFrequency' ? <CheckMarkIcon /> : <EditIcon />}
        </IconButton>
        {editType === 'classFrequency' && (
          <Styled.EditContainer style={{ padding: '16px 0px 30px 0px' }}>
            <Styled.PersonalInfoLabel
              style={{
                width: 'auto',
                margin: '0px 20px 20px 20px',
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Frequency of classes
            </Styled.PersonalInfoLabel>
            <span
              style={{
                color: '#414752',
                fontSize: 14,
                fontWeight: 600,
                margin: '0px 20px 18px 20px',
              }}
            >
              Hours per week
            </span>

            <div style={{ marginLeft: -10 }}>
              <Step5 classFrequency={classFrequency} onChange={onChange} />
            </div>
          </Styled.EditContainer>
        )}
      </Styled.PersonalInfoItem>
    </>
  )
}
