import { IWord } from 'src/models';
import { ADD_NEW_WORD, DELETE_WORD, SET_WORDS, WordsActionsTypes, IS_LIST_LOADED } from './types';

export const setWords = (words: IWord[]): WordsActionsTypes => ({
  type: SET_WORDS,
  payload: words,
});

export const addNewWordAction = (word: IWord): WordsActionsTypes => ({
  type: ADD_NEW_WORD,
  payload: word,
});

export const deleteWordAction = (uid: string): WordsActionsTypes => ({
  type: DELETE_WORD,
  payload: uid,
});


export const setListLoaded = (): WordsActionsTypes => ({
  type: IS_LIST_LOADED,
});
