import React from 'react'
import * as Styled from './filter-teachers.styles'
import Step1Component from './Step1'
import Step2Component from './Step2'
import Step3Component from './Step3'
import { IAutoTeacherSelectionFilterData } from '..'
import { useTranslation } from 'react-i18next'

const FilterTeachers = ({
  filterData,
  onChange,
}: {
  filterData: IAutoTeacherSelectionFilterData
  onChange: (data: { name: string; value: any }) => void
}) => {
  const { step, withWho } = filterData
  const { t } = useTranslation()

  const handleNext = () => {
    onChange({
      name: 'step',
      value: withWho === 'multiple' && step === 2 ? step + 2 : step + 1,
    })
  }
  const handleBack = () => {
    onChange({ value: step - 1, name: 'step' })
  }

  return (
    <>
      <Styled.FilterTeachersContainer>
        {step === 1 && <Step1Component onChange={onChange} withWho={filterData.withWho} />}
        {step === 2 && (
          <Step2Component
            isFilter
            onChange={onChange}
            studentBusinessHours={filterData.studentBusinessHours}
          />
        )}
        {step === 3 && (
          <Step3Component
            onChange={onChange}
            gender={filterData.gender}
            country={filterData.country}
            langVariant={filterData.langVariant}
          />
        )}
      </Styled.FilterTeachersContainer>
      <Styled.FooterButtons>
        {step > 1 && (
          <Styled.Button bgWhite onClick={handleBack}>
            {t('Back')}
          </Styled.Button>
        )}
        {step === 2 ? (
          filterData.studentBusinessHours.filter(item => item.daysOfWeek.length > 0).length > 0 && (
            <Styled.Button onClick={handleNext}>{t('Continue')}</Styled.Button>
          )
        ) : (
          <Styled.Button onClick={handleNext}>{t('Continue')}</Styled.Button>
        )}
      </Styled.FooterButtons>
    </>
  )
}

export default FilterTeachers
