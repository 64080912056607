import React from 'react'
import Pencil from './pencil'
import { fabric } from 'fabric'
import DefaultTool from './default-tool'
import Select from './select'
import { Tools } from '.'

class SketchField extends React.Component {
  static defaultProps = {
    lineColor: 'black',
    lineWidth: 10,
    fillColor: 'transparent',
    backgroundColor: 'white',
    opacity: 1.0,
    tool: null,
  }

  state = {
    sketchObjects: null,
    history: [],
    defaultTool: false,
    action: false,
    imageWidth: null,
    imageHeight: null,
    addedtext: false,
  }
  _initTools = fabricCanvas => {
    this._tools = {}
    this._tools[Tools.Select] = new Select(fabricCanvas)
    this._tools[Tools.Pencil] = new Pencil(fabricCanvas)
    this._tools[Tools.DefaultTool] = new DefaultTool(fabricCanvas)
  }

  enableTouchScroll = () => {
    let canvas = this._fc
    if (canvas.allowTouchScrolling) return
    canvas.allowTouchScrolling = true
  }

  disableTouchScroll = () => {
    let canvas = this._fc
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false
    }
  }

  _onObjectAdded = e => {
    if (!this.state.action) {
      this.setState({ history: [] })
    }
    this.setState({ action: false })
  }

  setActiveLineColor = color => {
    let activeObject = this._fc.getActiveObject()

    if (activeObject) {
      activeObject.type === 'path'
        ? activeObject.set('stroke', color)
        : activeObject.set('fill', color)
      this._fc.renderAll()
    }
  }

  _onObjectModified = e => {
    this.onChange()
  }

  _onMouseDown = e => {
    this._selectedTool.doMouseDown(e)
  }
  _onMouseMove = e => {
    this._selectedTool.doMouseMove(e)
  }

  _onMouseOut = e => {
    this._selectedTool.doMouseOut(e)
  }

  _onMouseUp = e => {
    this._selectedTool.doMouseUp(e)
    this.onChange()
  }

  _resize = e => {
    if (e) e.preventDefault()
    const { imageHeight, imageWidth } = this.state
    const el = document.getElementById(`skecth-container-${this.props.fileID}`)
    const canvas = this._fc

    if (!el || !canvas) return

    const offsetWidth = el.offsetWidth

    const prevWidth = canvas.getWidth()
    const prevHeight = canvas.getHeight()

    const maxHeight = window.innerHeight * 0.75
    const minHeight = 300
    const maxWidth = offsetWidth
    const minWidth = 300

    let newWidth = this.props.width || imageWidth
    let newHeight = this.props.height || imageHeight

    if (canvas.backgroundImage) {
      const bi = canvas.backgroundImage
      const originalRatio = bi.height / bi.width

      if (newHeight > maxHeight) {
        newHeight = maxHeight
        newWidth = newHeight / originalRatio
      } else if (newHeight < minHeight) {
        newHeight = minHeight
        newWidth = newHeight / originalRatio
      }

      if (newWidth > maxWidth) {
        newWidth = maxWidth
        newHeight = newWidth * originalRatio
      } else if (newWidth < minWidth) {
        newWidth = minWidth
        newHeight = newWidth * originalRatio
      }

      newHeight = Math.min(Math.max(newHeight, minHeight), maxHeight)
      newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth)

      canvas.setHeight(newHeight)
      canvas.setWidth(newWidth)
      this.props.updateCanvasSize(newWidth, newHeight)

      bi.scaleToWidth(newWidth)
      bi.scaleToHeight(newHeight)
    } else {
      const defaultHeight = Math.min(450, maxHeight)
      canvas.setWidth(offsetWidth)
      canvas.setHeight(defaultHeight)
      this.props.updateCanvasSize(offsetWidth, defaultHeight)
    }

    const objects = canvas.getObjects()
    if (prevWidth && prevHeight) {
      const scaleX = newWidth / prevWidth
      const scaleY = newHeight / prevHeight

      objects.forEach(obj => {
        obj.scaleX *= scaleX
        obj.scaleY *= scaleY

        // Корректируем позицию
        obj.left = obj.left * scaleX
        obj.top = obj.top * scaleY

        obj.setCoords()
      })
    }

    canvas.renderAll()
    canvas.calcOffset()
  }

  undo = () => {
    if (this._fc._objects.length > 0) {
      let history = this.state.history
      history.push(this._fc._objects.pop())
      this.setState({ history })
      this._fc.renderAll()
      this.onChange()
    }
  }
  redo = () => {
    if (this.state.history.length > 0) {
      let history = this.state.history
      this._fc.add(history.pop())

      this.setState({ action: true, history })
      this.onChange()
    }
  }

  canUndo = () => {
    return this._fc._objects.length === 0
  }

  canRedo = () => {
    return this.state.history.length === 0
  }
  canClear = () => {
    return this._fc._objects.length === 0
  }

  toJSON = propertiesToInclude => this._fc.toJSON(propertiesToInclude)

  fromJSON = value => {
    if (!value) return
    const sketchData = JSON.parse(value)
    let canvas = this._fc
    let sketchObjects = sketchData.objects
    if (this.state.sketchObjects !== JSON.stringify(sketchObjects)) {
      canvas.remove(...canvas.getObjects())
      fabric.util.enlivenObjects(sketchObjects, function(enlivenedObjects) {
        enlivenedObjects.forEach(function(obj, index) {
          canvas.add(obj)
        })
        canvas.renderAll()
      })

      let el = document.getElementById(`skecth-container-${this.props.fileID}`)

      let offsetWidth = el?.offsetWidth
      let clientHeight = el?.clientHeight

      let wfactor = (offsetWidth / sketchData.canvasWidth).toFixed(2)
      let hfactor = (clientHeight / sketchData.canvasHeight).toFixed(2)

      let objects = canvas.getObjects()
      for (let i in objects) {
        let obj = objects[i]
        let scaleX = obj.scaleX
        let scaleY = obj.scaleY
        let left = obj.left
        let top = obj.top
        let tempScaleX = scaleX * wfactor
        let tempScaleY = scaleY * hfactor
        let tempLeft = left * wfactor
        let tempTop = top * hfactor
        obj.scaleX = tempScaleX
        obj.scaleY = tempScaleY
        obj.left = tempLeft
        obj.top = tempTop
        obj.setCoords()
      }

      canvas.renderAll()
      canvas.calcOffset()
      if (!this.state.defaultTool) {
        this._selectedTool = this._tools[this.props.tool]

        this._fc.defaultCursor = 'default'
        if (this._selectedTool) {
          this._selectedTool.configureCanvas(this.props)
        }
        this.setState({ defaultTool: true })
      }
    }
  }

  clear = () => {
    this._fc.remove(...this._fc.getObjects())
    this.setState({ history: [] })
    this.onChange()
  }

  addText = (text, position, options = {}) => {
    let activeObject = this._fc.getActiveObject()
    if (!this.state.addedText && !activeObject) {
      let canvas = this._fc
      let iText = new fabric.IText(text, options)
      let opts = {
        left: position.x,
        top: position.y,
      }
      Object.assign(options, opts)
      iText.set({
        left: options.left,
        top: options.top,
      })

      canvas.add(iText)

      canvas.setActiveObject(iText)
      iText.enterEditing()
      iText.setSelectionEnd(7)

      this.onChange()
      this.setState({ addedText: true })
    } else {
      this.setState({ addedText: false })
    }
    if (activeObject) {
      this.setState({ addedText: true })
    }
  }

  onChange = () => {
    const sketchObjects = JSON.stringify(this.toJSON().objects)
    this.setState({ sketchObjects }, () => {
      this.props.onChange()
    })
  }
  callEvent = (e, eventFunction) => {
    if (this._selectedTool) eventFunction(e)
  }

  getObjects = () => {
    return this._fc.getObjects()
  }

  setImageSize = (imageHeight, imageWidth) => {
    this.setState({ imageHeight, imageWidth })
  }

  componentDidMount = () => {
    let { tool, value, imageUrl } = this.props

    let canvas = (this._fc = new fabric.Canvas(this.props.canvas))
    this._initTools(canvas)

    let selectedTool = this._tools[tool]
    if (selectedTool) selectedTool.configureCanvas(this.props)
    this._selectedTool = selectedTool
    let el = document.getElementById(`skecth-container-${this.props.fileID}`)
    let { offsetWidth } = el
    if (imageUrl) {
      let canvas = this._fc
      fabric.Image.fromURL(imageUrl, img => {
        let ratio = img.height / img.width
        let height = ratio * offsetWidth
        this.setState({ imageWidth: img.width, imageHeight: img.height }, () => {
          canvas.setHeight(height)
          canvas.setWidth(offsetWidth)
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
          })

          this.disableTouchScroll()
          value && this.fromJSON(value)
          requestAnimationFrame(() => {
            this._resize()
          })
        })
      })
    } else {
      const defaultHeight = 450
      this.setState(
        {
          imageWidth: offsetWidth,
          imageHeight: defaultHeight,
        },
        () => {
          canvas.setHeight(defaultHeight)
          canvas.setWidth(offsetWidth)

          this.disableTouchScroll()
          if (value) {
            this.fromJSON(value)
            canvas.renderAll()
          }
          requestAnimationFrame(() => {
            this._resize()
            canvas.renderAll()
          })
        }
      )
    }

    window.addEventListener('resize', this._resize, false)

    canvas.on('object:added', e => this.callEvent(e, this._onObjectAdded))
    canvas.on('object:modified', e => this.callEvent(e, this._onObjectModified))

    canvas.on('mouse:down', e => this.callEvent(e, this._onMouseDown))
    canvas.on('mouse:move', e => this.callEvent(e, this._onMouseMove))
    canvas.on('mouse:up', e => this.callEvent(e, this._onMouseUp))
    canvas.on('mouse:out', e => this.callEvent(e, this._onMouseOut))

    canvas.on('text:event:changed', () => this.onChange())
  }
  componentWillUnmount = () => window.removeEventListener('resize', this._resize)

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.tool !== prevProps.tool) {
      this._selectedTool = this._tools[this.props.tool]

      this._fc.defaultCursor = 'default'
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props)
      }
    }
    if (this.props.lineColor !== prevProps.lineColor) {
      this._selectedTool.configureCanvas(this.props)
    }
    if (this.props.lineWidth !== prevProps.lineWidth) {
      this._selectedTool.configureCanvas(this.props)
    }
    if (this.props.value) {
      if (this.props.value !== prevProps.value) {
        this.fromJSON(this.props.value)
      }
    }
  }

  render() {
    return null
  }
}

export default SketchField
