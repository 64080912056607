import * as React from 'react'

const CardSkypeIcon = props => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 24C5.35286 24 0 18.6471 0 12C0 5.35294 5.35286 0 11.9998 0C18.6468 0 23.9996 5.35294 23.9996 12C23.9996 18.6471 18.6468 24 11.9998 24Z"
        fill="#C2CFE0"
      />
      <path
        d="M12.0677 7.22989C14.2441 7.40636 15.4794 8.34754 15.5971 9.22989C15.6559 9.81813 15.303 10.2299 14.6559 10.2299C13.6559 10.2299 13.5383 8.93578 11.8912 8.93578C11.1265 8.93578 10.4795 9.28872 10.4795 9.93578C10.4795 11.3475 15.8912 10.524 15.8912 13.7005C15.8912 15.524 14.4206 16.7005 12.2442 16.7005C10.3618 16.7005 8.4207 15.8181 8.4207 14.3475C8.4207 13.877 8.77364 13.4652 9.24422 13.4652C10.3618 13.4652 10.303 15.1711 12.1265 15.1711C13.3618 15.1711 13.7736 14.4652 13.7736 13.9946C13.7736 12.2887 8.36188 13.2887 8.36188 10.0534C8.36188 8.34754 9.83244 7.11225 12.0677 7.22989ZM5.3031 11.9946C5.3031 15.7005 8.30306 18.7005 12.0089 18.7005C12.4206 18.7005 12.8324 18.6417 13.2442 18.5828C13.8324 18.9358 14.5383 19.1122 15.303 19.1122C17.4206 19.1122 19.1852 17.4064 19.1852 15.2299C19.1852 14.4652 18.95 13.7593 18.597 13.1711C18.6558 12.8181 18.7147 12.4064 18.7147 11.9946C18.7147 8.28872 15.7147 5.34754 12.0089 5.34754C11.5971 5.34754 11.1854 5.34754 10.7736 5.40636C10.1854 5.05342 9.47951 4.87695 8.77364 4.87695C6.5972 4.87695 4.83252 6.64166 4.83252 8.75931C4.83252 9.52401 5.06781 10.2299 5.42075 10.8181C5.36192 11.2299 5.3031 11.5828 5.3031 11.9946Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

export default CardSkypeIcon
