import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from '@utils/helpers'
import PhoneInput from 'react-phone-input-2'
import './style.css'
const WhiteTextField = withStyles({
  root: {
    // '& .MuiInputBase-input': {
    //   color: '#fff', // Text color
    // },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#D3D8DD', // Semi-transparent underline
      borderBottomWidth: 1,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#4284FF', // Solid underline on hover
      borderBottomWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#4284FF', // Solid underline on focus
      borderBottomWidth: 1,
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: '#DD2E44',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#DD2E44',
    },
    // '& .MuiInput-formControl': {
    //   marginTop: 10,
    // },
  },
})(TextField)

const useStyles = makeStyles({
  textField: {
    margin: 0,
    width: '100%',
  },
  resize: {
    fontSize: 18,
    color: '#414752',
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  selectRoot: {
    display: 'flex',
  },
  label: {
    fontFamily: 'Open Sans',
    fontSize: 18,
    color: '#7E92AC',
    '&$focused': {
      color: '#4284FF',
    },
  },
  labelFontSize: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    color: '#7E92AC',
    top: 7,
    '&$focused': {
      color: '#4284FF',
    },
  },
  focused: {},
  notchedOutline: {},
  hoverIcon: {
    color: '#4284FF',
  },
  defaultIcon: {
    fill: '#7E92AC',
  },
  hideIcon: {
    fill: 'none',
  },
  email: {
    color: '#414752',
    opacity: 0.5,
  },
  emailVerified: {
    color: '#000',
  },
})

interface TextMaskCustomProps {
  onChange?: (val:any) => void,
  inputRef: (ref: HTMLInputElement | null) => void
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { onChange, ...other } = props

  return (
    <PhoneInput
      {...other}
      country={'ru'}
      onChange={value => {
        onChange({ target: { value } })
      }}
      copyNumbersOnly
      enableLongNumbers
      specialLabel={''}
      inputStyle={{
        border: 'none',
      }}
    />
  )
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy', {
  minYear: 1,
  maxYear: new Date().getFullYear(),
})
function BirthDayMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      guide={false}
      pipe={autoCorrectedDatePipe}
      keepCharPositions
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
    />
  )
}

export default function(props) {
  const [isFocused, setIsFocused] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const classes = useStyles(props)

  return (
    <WhiteTextField
      onFocus={() => setIsFocused(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      onBlur={() => setIsFocused(false)}
      {...props}
      InputProps={
        props.name === 'phone' || props.name === 'birthDate'
          ? {
              inputComponent:
                props.name === 'birthDate' ? (BirthDayMask as any) : (TextMaskCustom as any),
              classes: {
                input: classes.resize,
              },
              disableUnderline: (!!props.value && !isFocused && !isHover) || props.disabled,
            }
          : {
              classes: {
                input:
                  props.name !== 'email'
                    ? classes.resize
                    : props.isVerified
                    ? classes.emailVerified
                    : classes.email,
              },
              disableUnderline: (!!props.value && !isFocused && !isHover) || props.disabled,
              startAdornment: props.startAdornment,
              endAdornment: props.endAdornment,
            }
      }
      SelectProps={{
        classes: {
          root: classes.selectRoot,
          icon:
            !!props.value && !isFocused && !isHover
              ? classes.hideIcon
              : isFocused || isHover
              ? classes.hoverIcon
              : classes.defaultIcon,
        },
      }}
      InputLabelProps={{
        classes: {
          root: !!props.value || isFocused ? classes.labelFontSize : classes.label,
          focused: classes.focused,
        },
        shrink: props.shrink,
      }}
      className={classes.textField}
      margin="normal"
    />
  )
}
