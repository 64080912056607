import {
  CLEAR_COURSE_LESSON,
  CourseActionsTypes,
  SET_COURSE_LESSON,
  SET_IS_LOADING_COURSE_LESSON,
} from './types';

import { ILesson, ILessonHomework } from '@models';

export const setCourseLessonAction = (lesson: ILesson | ILessonHomework ): CourseActionsTypes => ({
  type: SET_COURSE_LESSON,
  payload: lesson,
});

export const setLoadingCourseLessonAction = (isLoading: boolean): CourseActionsTypes => ({
  type: SET_IS_LOADING_COURSE_LESSON,
  payload: isLoading,
});

export const clearCourseLessonAction = (): CourseActionsTypes => ({
  type: CLEAR_COURSE_LESSON,
});
