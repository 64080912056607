import React, { useCallback, useRef } from 'react'
import Select from 'react-select'

export default function({ ...props }) {
  const selectRef = useRef(null)
  const onMenuOpen = useCallback(() => {
    const option = selectRef.current?.select?.state?.selectValue?.[0]
    if (option) {
      setTimeout(() => {
        if (selectRef.current?.select) {
          const selectedIndex = selectRef.current.select.state.menuOptions.focusable.indexOf(
            option
          )
          if (selectedIndex >= 0) {
            selectRef.current.select.scrollToFocusedOptionOnUpdate = true
            selectRef.current.select.inputIsHiddenAfterUpdate = false
            selectRef.current.select.setState({
              focusedValue: null,
              focusedOption: option,
            })
          }
        }
      })
    }
  }, [])

  return <Select ref={selectRef} onMenuOpen={onMenuOpen} {...props} />
}
