import React from 'react'
export default function UndoIcon(props) {
  let opacity = {}
  opacity = props.disabled ? { opacity: 0.5 } : null
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.52432C3.37438 8.03395 3.86303 7.40552 4.17811 7.0597C5.65052 5.44364 7.32111 4.5 9.375 4.5C14.1672 4.5 16.5 7.80917 16.5 12H15C15 8.54789 13.2039 6 9.375 6C7.80663 6 6.50081 6.7376 5.28691 8.06993C4.9698 8.41797 4.36536 9.21183 3.95133 9.75H8.25V11.25H1.5V4.5H3V8.52432Z"
        fill="white"
      />
    </svg>
  )
}
