import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import IconButton from '@material-ui/core/IconButton'

import { SKETCHPAD_COLORS, CLASSROOM_BLOCK_TYPES } from '@utils/constants'

import { IClassroomBlock } from '@models/IClassroom'

import { AppDispatch, RootState } from '@store'
import { videoPlayerActions } from '@store/videoPlayer'
import { deleteFileFromClassroom, hiddenFile } from '@servise/classroomAPI'

import {
  DeleteIcon,
  EyeIcon,
  PencilIcon,
  SelectCursor,
  EllipseIcon,
  LineWidth12Icon,
  LineWidth5Icon,
  LineWidth8Icon,
  LineIcon,
  Font14Icon,
  Font24Icon,
  Font36Icon,
  TextIcon,
  ClearBoardIcon,
  RedoIcon,
  UndoIcon,
  PencilIconActive,
  EyeOffIcon,
} from '@components/Icons'

import { Tools } from '@components/SketchField'

import * as Styled from './classroom-files.styles'
import { classroomsRef } from '@configs/firebase'
import CrossShairsIcon from '@components/Icons/CrossShairs'
import { changeScrollToFile } from '@servise/syncAPI'
import { makeStyles, Tooltip } from '@material-ui/core'
import sync from '@assets/icons/refresh.svg'
import { Loader } from '..'

enum SketchActive {
  colors,
  lines,
  fonts,
}

type TypeSettings = {
  fileData: IClassroomBlock
  isPdf?: boolean
  lineColor?: string
  undo?: () => void
  redo?: () => void
  canUndo?: boolean
  canClear?: boolean
  clear?: () => void
  canRedo?: boolean
  isBoard?: boolean
  isExercise?: boolean
  setLineColor?: (color: string) => void
  addText?: (text: number) => void
  setLineWidth?: (width: number) => void
  onChangeTool?: (tool: string) => void
  tool?: string
  hideClassActions?: boolean
  setIsEdit?: () => void
  onRegenerate?: () => void
  isLoading?: boolean
}

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'transparent',
  },
}))

const SettingsBlock = (props: TypeSettings) => {
  const [active, setActive] = useState<SketchActive | null>(null)
  const [activeSketch, setActiveSketch] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const classes = useStyles()
  const states = useSelector((state: RootState) => ({
    selectedVideo: state.sync.reactPlayerState.selectedVideo,
    classroomData: state.classroom.classroomData,
    isStudent: state.profile.isStudent,
    scrollFileId: state.sync.scrollFileId,
  }))

  const onSetLineColor = color => {
    setActive(null)
    props.setLineColor(color)
  }

  const { onChangeTool } = props

  useEffect(() => {
    props.isBoard && setActiveSketch(true)
  }, [props.isBoard])

  const onSetLineWidth = width => {
    setActive(null)
    props.setLineWidth(width)
  }
  const onAddText = fontSize => {
    setActive(null)
    props.addText(fontSize)
  }

  const handleActiveSketch = () => {
    setActiveSketch(!activeSketch)
  }
  useEffect(() => {
    onChangeTool && onChangeTool(activeSketch ? Tools.Pencil : Tools.DefaultTool)
  }, [activeSketch, onChangeTool])

  const handleDelete = () => {
    deleteFileFromClassroom(states.classroomData.id, props.fileData)
      .then(async () => {
        if (props.fileData.id === states.selectedVideo) {
          dispatch(videoPlayerActions.updateUserVideoPlayerState())
        }
        if (states.classroomData.img === props.fileData.url) {
          await classroomsRef.doc(states.classroomData.id).update({ img: null })
        }
      })
      .then(() => {
        toast('Файл успешно удален', {
          autoClose: 2000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'message-success',
        })
      })
      .catch(() => {
        toast('Не удалось удалить файл! :( Попробуйте снова', {
          autoClose: 3000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'message-error',
        })
      })
  }
  const handleChangeScroolToFile = () => {
    if (props.fileData.id === states.scrollFileId) {
      changeScrollToFile(states.classroomData.id, null)
    }
    changeScrollToFile(states.classroomData.id, props.fileData.id)
  }

  return (
    <>
      <Styled.BlockSettingsContainer
        audioFile={props.fileData.type === CLASSROOM_BLOCK_TYPES.AUDIO}
        isExercise={props.isExercise}
      >
        {!states.isStudent && !props.hideClassActions && (
          <>
            <IconButton size={'small'} onClick={() => handleDelete()}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              size={'small'}
              onClick={() => {
                hiddenFile(props.fileData, states.classroomData.id)
                if (props.fileData.isHidden) {
                  changeScrollToFile(states.classroomData.id, props.fileData.id)
                } else {
                  changeScrollToFile(states.classroomData.id, null)
                }
              }}
            >
              {props.fileData.isHidden ? <EyeOffIcon style={{ fill: '#F43E3E' }} /> : <EyeIcon />}
            </IconButton>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                <div
                  style={{
                    background: 'rgba(65, 71, 82, 0.8)',
                    color: '#fff',
                    borderRadius: 8,
                    fontSize: 14,
                    lineHeight: '19px',
                    padding: '6px 15px',
                  }}
                >
                  Вернуть ученика <br />к данному файлу
                </div>
              }
              placement="bottom-start"
            >
              <IconButton size={'small'} onClick={handleChangeScroolToFile}>
                <CrossShairsIcon />
              </IconButton>
            </Tooltip>
            {[
              CLASSROOM_BLOCK_TYPES.VOCABULARY,
              CLASSROOM_BLOCK_TYPES.QUESTIONS_VOCABULARY,
              CLASSROOM_BLOCK_TYPES.INTERACTIVE_EXERCISES,
              CLASSROOM_BLOCK_TYPES.TEXT_GAME,
              CLASSROOM_BLOCK_TYPES.SUBTOPICS,
              CLASSROOM_BLOCK_TYPES.DIALOGUE_WITH_EXERCISE,
              CLASSROOM_BLOCK_TYPES.QUESTIONS,
            ].includes(props.fileData.type) && (
              <>
                {props.fileData.type !== CLASSROOM_BLOCK_TYPES.DIALOGUE_WITH_EXERCISE && (
                  <IconButton size={'small'} onClick={props.setIsEdit}>
                    <PencilIcon />
                  </IconButton>
                )}
                {props.isLoading ? (
                  <div style={{ width: 20, height: 20 }}>
                    <Loader name={'ball-clip-rotate'} />
                  </div>
                ) : (
                  <IconButton size={'small'} onClick={props.onRegenerate}>
                    <img src={sync} alt="sync" />
                  </IconButton>
                )}
              </>
            )}
          </>
        )}

        {props.lineColor && (
          <>
            <div style={{ marginTop: '120%' }}>
              <IconButton size={'small'} onClick={() => handleActiveSketch()}>
                {activeSketch ? <PencilIconActive /> : <PencilIcon />}
              </IconButton>
            </div>
            <Styled.SketchSettings active={activeSketch}>
              <IconButton size={'small'} onClick={() => props.onChangeTool(Tools.Select)}>
                <SelectCursor fill={props.tool === Tools.Select ? '#336A93' : '#FFFFFF'} />
              </IconButton>
              <Styled.SketchSettingsItemContainer>
                <IconButton
                  size={'small'}
                  onClick={() =>
                    setActive(SketchActive.colors === active ? null : SketchActive.colors)
                  }
                >
                  <EllipseIcon fill={props.lineColor} />
                </IconButton>
                <Styled.SketchModal active={active === SketchActive.colors} style={{ width: 266 }}>
                  {SKETCHPAD_COLORS.map((color, index) => (
                    <IconButton
                      key={`${color}_${index}`}
                      size={'small'}
                      onClick={() => onSetLineColor(color)}
                    >
                      <EllipseIcon fill={color} />
                    </IconButton>
                  ))}
                </Styled.SketchModal>
              </Styled.SketchSettingsItemContainer>

              <Styled.SketchSettingsItemContainer>
                <IconButton
                  size={'small'}
                  onClick={() =>
                    setActive(SketchActive.lines === active ? null : SketchActive.lines)
                  }
                >
                  <LineIcon />
                </IconButton>
                <Styled.SketchModal active={active === SketchActive.lines} style={{ width: 105 }}>
                  <IconButton size={'small'} onClick={() => onSetLineWidth(12)}>
                    <LineWidth12Icon />
                  </IconButton>
                  <IconButton size={'small'} onClick={() => onSetLineWidth(8)}>
                    <LineWidth8Icon />
                  </IconButton>
                  <IconButton size={'small'} onClick={() => onSetLineWidth(5)}>
                    <LineWidth5Icon />
                  </IconButton>
                </Styled.SketchModal>
              </Styled.SketchSettingsItemContainer>

              <Styled.SketchSettingsItemContainer>
                <IconButton
                  size={'small'}
                  onClick={() =>
                    setActive(SketchActive.fonts === active ? null : SketchActive.fonts)
                  }
                >
                  <TextIcon />
                </IconButton>
                <Styled.SketchModal
                  active={active === SketchActive.fonts}
                  style={{ height: 35, width: 140 }}
                >
                  <IconButton size={'small'} onClick={() => onAddText(36)}>
                    <Font36Icon />
                  </IconButton>
                  <IconButton size={'small'} onClick={() => onAddText(24)}>
                    <Font24Icon />
                  </IconButton>
                  <IconButton size={'small'} onClick={() => onAddText(14)}>
                    <Font14Icon />
                  </IconButton>
                </Styled.SketchModal>
              </Styled.SketchSettingsItemContainer>
              <IconButton disabled={props.canUndo} onClick={() => props.undo()} size={'small'}>
                <UndoIcon disabled={props.canUndo} />
              </IconButton>
              <IconButton disabled={props.canRedo} onClick={() => props.redo()} size={'small'}>
                <RedoIcon disabled={props.canRedo} />
              </IconButton>
              <IconButton disabled={props.canClear} onClick={() => props.clear()} size={'small'}>
                <ClearBoardIcon disabled={props.canClear} />
              </IconButton>
            </Styled.SketchSettings>
          </>
        )}
      </Styled.BlockSettingsContainer>
    </>
  )
}

export default SettingsBlock
