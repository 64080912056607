import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from 'rc-dropdown'
import ReactTooltip from 'react-tooltip'
import { useDrag, useDrop } from 'react-dnd'
import classNames from 'classnames'

import Menu from '@components/common/Menu'
import rand from '@utils/rand'
import Pattern1 from '@assets/images/Constructor/pattern1.jpg'
import Pattern2 from '@assets/images/Constructor/pattern2.jpg'
import Pattern3 from '@assets/images/Constructor/pattern3.jpg'
import MoreHorizontal from '@assets/images/Constructor/more-horizontal.svg'

import './actionCard.scss'
import { getCachedImage } from '@utils/uncachedImage'

const patterns = [Pattern1, Pattern2, Pattern3]

const DraggableActionCard = ({
  image = null,
  title = '',
  description = '',
  lessonsTitleText = '',
  lessonsCount,
  actions,
  onClick,
  order,
  moveLesson,
}) => {
  const handleClickDropdown = e => {
    e.stopPropagation()
  }

  const pattern = useMemo(() => patterns[rand(0, 2)], [])

  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: 'lessonCard',
    hover: item => {
      if (!ref.current) return
      if (item.order === order) return
      moveLesson(item.order, order)
      item.order = order
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'lessonCard', order },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })

  drag(drop(ref))
  const [imageUrl, setImageUrl] = useState(null)
  useEffect(() => {
    if (image) {
      getCachedImage(image).then(url => setImageUrl(url))
    }
  }, [image])

  return (
    <>
      <div
        ref={ref}
        className={classNames('card big action draggable-card-item', {
          'is-empty-place': isDragging,
        })}
        onClick={onClick}
      >
        <div
          className="card-image-container"
          style={{
            backgroundImage: `url(${imageUrl || pattern})`,
          }}
        />
        <div className="card-description-container">
          <span className="card-title">{title}</span>
          <span data-tip={description} className="card-description">
            {description}
          </span>
        </div>
        <div className="card-footer-container">
          {typeof lessonsCount === 'number' ? (
            <span className="lessons-title">
              {lessonsTitleText} <b className="lessons-count">{lessonsCount}</b>
            </span>
          ) : (
            <div className="left-actions" />
          )}
          <Dropdown trigger="click" overlay={<Menu actions={actions} />} animation="slide-up">
            <div className="dropdown-trigger-container" onClick={handleClickDropdown}>
              <img src={MoreHorizontal} alt="more-horizontal" />
            </div>
          </Dropdown>
        </div>
      </div>
      <ReactTooltip
        className="course-description-tooltip"
        arrowColor="transparent"
        place="right"
        multiline
      />
    </>
  )
}

export default DraggableActionCard
