import React, { useState } from 'react'
import * as Styled from './style.styles'
import Close from '../../assets/icons/greyClose.svg'
import toast from 'react-hot-toast'

export const MistakesHint = ({
  toastId,
  onCloseToast,
}: {
  toastId?: string
  onCloseToast?: () => {}
}) => {
  const [visibility, setVisibility] = useState(true)
  const handleClose = () => {
    if (onCloseToast) {
      onCloseToast()
    }
    toastId ? toast.dismiss(toastId) : setVisibility(false)
  }

  return visibility ? (
    <Styled.Container>
      <Styled.TitleBlock>
        <Styled.Title>A lot of mistakes</Styled.Title>
        <img src={Close} alt="closes" onClick={handleClose} />
      </Styled.TitleBlock>
      <Styled.Text>
        Pay attention to this homework, there are more than 15 mistakes made. The student seems to
        have a problem with this topic.
      </Styled.Text>
    </Styled.Container>
  ) : null
}
