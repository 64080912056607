import React from 'react'
function PencilIconActive() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#336A93" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9584 5.33301C15.3949 5.33301 15.8133 5.50729 16.1189 5.81529L18.1862 7.88256C18.494 8.19034 18.6669 8.60779 18.6669 9.04306C18.6669 9.47833 18.494 9.89578 18.1862 10.2036L10.6385 17.7488C10.1728 18.286 9.51276 18.616 8.75656 18.6679H5.3335V18.0012L5.33566 15.1912C5.39245 14.4882 5.71927 13.8346 6.21783 13.3953L13.7971 5.81609C14.1044 5.50689 14.5224 5.33301 14.9584 5.33301ZM8.7095 17.3362C9.06565 17.311 9.39717 17.1453 9.66337 16.8407L14.7046 11.7995L12.2017 9.2965L7.13091 14.3661C6.86081 14.6049 6.69372 14.9392 6.66685 15.2449V17.335L8.7095 17.3362ZM13.1446 8.3538L15.6474 10.8567L17.2434 9.26075C17.3011 9.20301 17.3335 9.1247 17.3335 9.04305C17.3335 8.9614 17.3011 8.88309 17.2434 8.82536L15.1743 6.75624C15.1172 6.6987 15.0395 6.66633 14.9584 6.66633C14.8774 6.66633 14.7997 6.6987 14.7426 6.75624L13.1446 8.3538Z"
        fill="white"
      />
    </svg>
  )
}

export default PencilIconActive
