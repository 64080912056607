import React, { useEffect, useMemo, useState } from 'react'
import Dropdown from 'rc-dropdown'
import ReactTooltip from 'react-tooltip'

import Card from '@components/common/Card'
import Menu from '@components/common/Menu'
import CheckIcon from '@components/Icons/check.svg'
import ModerationIcon from '@components/Icons/sendModeration.svg'
import divertedModeration from '@components/Icons/divertedModeration.svg'
import WarningIcon from '@components/Icons/warning.svg'

import rand from '@utils/rand'

import Pattern1 from '@assets/images/Constructor/pattern1.jpg'
import Pattern2 from '@assets/images/Constructor/pattern2.jpg'
import Pattern3 from '@assets/images/Constructor/pattern3.jpg'
import MoreHorizontal from '@assets/images/Constructor/more-horizontal.svg'
import { COURSES_STATUS } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import { getCachedImage } from '@utils/uncachedImage'

const patterns = [Pattern1, Pattern2, Pattern3]

const ActionCard = ({
  image = null,
  title = '',
  description = '',
  lessonsTitleText = '',
  lessonsCount,
  actions,
  onClick,
  status,
  role,
  isMini,
  publishedAt,
  updatedAt,
}) => {
  const { t } = useTranslation()
  const handleClickDropdown = e => {
    e.stopPropagation()
  }

  const pattern = useMemo(() => patterns[rand(0, 2)], [])
  const isPublished =
    publishedAt && (updatedAt ? +publishedAt.toDate() > +updatedAt.toDate() : true)
  const isChanged = publishedAt && +publishedAt.toDate() < +updatedAt?.toDate()

  const [imageUrl, setImageUrl] = useState(null)
  useEffect(() => {
    if (image) {
      getCachedImage(image).then(url => setImageUrl(url))
    }
  }, [image])

  return (
    <Card className="big action" onClick={onClick}>
      {isMini ? (
        <div className="status-block">
          {(status === COURSES_STATUS.PUBLISHED ||
            (status === COURSES_STATUS.CHANGE_DIVERTED && role !== 'MANAGER')) && (
            <div className="card-status published-status">
              <img src={CheckIcon} alt="опубликовано" />
              {t('couresStatus.Published')}
            </div>
          )}
          {status === COURSES_STATUS.CHANGED && (
            <div className="card-status changed-status">
              <img src={WarningIcon} alt="есть изменения" />
              {t('couresStatus.Changes')}
            </div>
          )}
          {status === COURSES_STATUS.MODERATION && (
            <div className="card-status changed-status">
              <img src={ModerationIcon} alt={COURSES_STATUS.MODERATION} />
              {t('couresStatus.Moderation')}
            </div>
          )}
          {(status === COURSES_STATUS.DIVERTED ||
            (status === COURSES_STATUS.CHANGE_DIVERTED && role === 'MANAGER')) && (
            <div className="card-status diverted-status">
              <img src={divertedModeration} alt={COURSES_STATUS.DIVERTED} />
              {t('couresStatus.Diverted')}
            </div>
          )}
          {status === COURSES_STATUS.CHANGE_DIVERTED && role !== 'MANAGER' && (
            <div className="card-status diverted-status">
              <img src={divertedModeration} alt="есть изменения" />
              {t('couresStatus.ChangesRejected')}
            </div>
          )}
        </div>
      ) : (
        <div className="status-block">
          {isPublished && (
            <div className="card-status published-status">
              <img src={CheckIcon} alt="опубликовано" />
              {t('couresStatus.Published')}
            </div>
          )}
          {isChanged && (
            <div className="card-status changed-status">
              <img src={WarningIcon} alt="есть изменения" />
              {t('couresStatus.Changes')}
            </div>
          )}
        </div>
      )}

      <div
        className="card-image-container"
        style={{
          backgroundImage: `url(${imageUrl || pattern})`,
        }}
      />
      <div className="card-description-container">
        <span className="card-title">{title}</span>
        <span data-tip={description} className="card-description">
          {description}
        </span>
      </div>
      <div className="card-footer-container">
        {typeof lessonsCount === 'number' ? (
          <span className="lessons-title">
            {lessonsTitleText} <b className="lessons-count">{lessonsCount}</b>
          </span>
        ) : (
          <div className="left-actions" />
        )}
        <Dropdown trigger="click" overlay={<Menu actions={actions} />} animation="slide-up">
          <div className="dropdown-trigger-container" onClick={handleClickDropdown}>
            <img src={MoreHorizontal} alt="more-horizontal" />
          </div>
        </Dropdown>
        <ReactTooltip
          className="course-description-tooltip"
          arrowColor="transparent"
          place="right"
          multiline
        />
      </div>
    </Card>
  )
}

export default ActionCard
