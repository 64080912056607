import styled from 'styled-components'
import CommentBg from '../../../images/commentBg.png'

export const CommentInput = styled.textarea`
  background: url(${CommentBg}) repeat-y;
  background-size: contain;
  background-attachment: local;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 20px;
  width: 370px;
  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};

  line-height: 31px;
  font-style: normal;
  font-weight: normal;
  resize: none;
`
export const AutocheckByAIComment = styled.div`
  background: url(${CommentBg}) repeat;
  background-size: contain;
  background-attachment: local;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 20px;
  width: 370px;
  height: 267px;
  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};
  line-height: 31px;
  font-style: normal;
  font-weight: normal;
  resize: none;
  overflow-y: auto;
`

export const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const AutocheckByAICommentContainer = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;

  & > :first-child {
    align-self: end;
    margin-bottom: 12px;
  }
`
export const AutocheckByAIHeading = styled.div`
  position: absolute;
  width: 112px;
  height: 20px;
  margin: 30px 35px 30px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Gray */

  color: #90a4bd;
`

export const HomeWork = styled.div`
  display: flex;
  margin-bottom: 20px;
`

export const HomeworkItem = styled.div<{ checked: boolean }>`
  width: 600px;
  position: relative;
  box-shadow: 0px 2px 10px
    ${props =>
      props.checked ? props.theme.colors.hwMainBoxShadow : props.theme.colors.hwActiveBoxShadow};
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  .closeDiv {
    &:hover {
      .closeBtn {
        opacity: 1;
      }
    }
  }
  &:hover {
    .closeBtn {
      display: block;
      opacity: 0.5;
    }
  }
`

export const AudioButton = styled.div<{ isChecked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  left: ${props => !props.isChecked && '12px'};
  position: ${props => !props.isChecked && 'absolute'};
  margin-top: ${props => props.isChecked && '20px'};
  width: ${props => (props.isChecked ? '100%' : '60%')};
  bottom: 18px;
  justify-content: ${props => props.isChecked && 'space-between'};

  .volume-icon {
    display: none;
  }
  /* .timer {
    position: absolute;
    right: 8px;
    top: 8px;
  } */
  canvas {
    width: 75%;
  }
  .delete-btn {
    svg {
      path {
        fill: #7e92ac;
      }

      width: 16;
      height: 17;
    }
  }

  span {
    font-size: 14px;
    line-height: 19px;
    color: #7e92ac;
    margin: 0px 10px;
  }
  input {
    display: none;
  }
`

export const ViewButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  text-decoration-line: underline;
  color: #7e92ac;
`

export const CompletedPages = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #7e92ac;
`

export const NumberOfMistakes = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`

export const Bottom = styled.div`
  margin-top: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 60px;
  text-align: center;
  color: #414752;
`
export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 27.24px;
  letter-spacing: 0.01em;
`
export const ModalDescription = styled.div`
  color: #7e92ac;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  letter-spacing: 0.01em;
`
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`
export const DeleteBtn = styled.button`
  width: 200px;
  height: 50px;
`
