import React from 'react'
function SearchIcon(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="15" cy="15" r="15" fill="#7E92AC" />
      <path
        d="M20.6704 22.084C21.0481 22.487 21.6809 22.5074 22.0838 22.1296C22.4868 21.7519 22.5072 21.1191 22.1294 20.7162L20.6704 22.084ZM17.4908 13.4486C17.4908 15.6358 15.8436 17.2971 13.9454 17.2971V19.2971C17.0678 19.2971 19.4908 16.6168 19.4908 13.4486H17.4908ZM13.9454 17.2971C12.0471 17.2971 10.3999 15.6358 10.3999 13.4486H8.3999C8.3999 16.6168 10.8229 19.2971 13.9454 19.2971V17.2971ZM10.3999 13.4486C10.3999 11.2613 12.0471 9.6001 13.9454 9.6001V7.6001C10.8229 7.6001 8.3999 10.2803 8.3999 13.4486H10.3999ZM13.9454 9.6001C15.8436 9.6001 17.4908 11.2613 17.4908 13.4486H19.4908C19.4908 10.2803 17.0678 7.6001 13.9454 7.6001V9.6001ZM22.1294 20.7162L17.8945 16.1989L16.4355 17.5668L20.6704 22.084L22.1294 20.7162Z"
        fill="#F3F5F9"
      />
    </svg>
  )
}

export default SearchIcon
