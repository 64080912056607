import React from 'react'
export default function PlayIconWithCircle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle
        className="loading"
        fill="white"
        stroke="none"
        strokeWidth="3"
        cx="12"
        cy="12"
        r="10.5"
      />
      <path
        d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM17.4234 13.125L9.17344 17.8594C8.43281 18.2719 7.5 17.7422 7.5 16.875V7.125C7.5 6.2625 8.42812 5.72813 9.17344 6.14062L17.4234 11.1562C18.1922 11.5875 18.1922 12.6984 17.4234 13.125Z"
        fill="#7E92AC"
      />
    </svg>
  )
}
