import {
  Div,
  PersonalRate as StyledRate,
} from '@components/Student/StudentCard/student-card.styles'
import styled from 'styled-components'
import { WorkSheetTitle } from '../worksheet.styles'

export const InterestCard = styled.div<{ active: boolean }>`
  width: 154px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => (props.active ? '#C2CFE0' : '#f3f5f9')};
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Text_dark */

  color: #414752;
  > img {
    margin-right: 10px;
  }
  padding-left: 16px;
  cursor: pointer;
`

export const InterestsContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  overflow-y: auto;
  margin: 0px 10px 0px 30px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #f3f5f9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #c2cfe0;
  }
`
export const ChoosedLabel = styled.span`
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #90a4bd;
  bottom: 46px;
  right: 30px;
`

export const RangeHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  margin-left: 30px;

  input {
    background: #ffffff;
    border: 1px solid #7e92ac;
    border-radius: 5px;
    width: 50px;
    height: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7e92ac;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Text_dark */

    color: #414752;
  }
`
export const Step4Container = styled.div`
  margin: 0px 30px;
`
export const Step4Input = styled.textarea`
  width: 100%;
  border-bottom: 1px solid #c2cfe0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  word-wrap: break-word;
  word-break: break-all;
  color: #414752;
  padding: 8px 0px;
`

export const CalendarContainer = styled.div`
  .rdrCalendarWrapper {
    margin: 0px 30px;
    border: 2px solid #c9c9c9;
    border-radius: 7px;
  }
`

export const CalendarDesc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin: 0px 30px 10px 30px;
`
export const PersonalInfo = styled(Div)`
  display: flex;
`
export const PersonalRate = styled(StyledRate)``
export const WorksheetStartTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
`
export const WorksheetStartDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #90a4bd;
  margin-top: 10px;
  margin-bottom: 20px;
`

export const WorksheetStartInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 46px 0px 46px;
`
export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px 0px 30px;

  .personal-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #1b3b77;
    margin-left: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
`

export const PrioritiesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0px 30px 0px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 4px;
  }
`
