import React from 'react';

export default function() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='9' fill='#4284FF' />
      <path
        d='M8 14.1253V16H9.87474L15.404 10.4708L13.5292 8.59603L8 14.1253ZM16.8538 9.02097C17.0487 8.826 17.0487 8.51104 16.8538 8.31607L15.6839 7.14623C15.489 6.95126 15.174 6.95126 14.979 7.14623L14.0642 8.0611L15.9389 9.93584L16.8538 9.02097Z'
        fill='#F3F5F9'
      />
    </svg>
  );
}
