import { IEvent } from '@models/ICalendar'
import { IProfile } from '@models/IProfile'
import { getStudentCalendarEvents } from '@servise/calendarAPI'
import { fetchStudentPayments, fetchTeacherProfileByUid } from '@servise/profileAPI'
import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'
import * as Styled from './student-card.styles'
import RightIcon from '@components/Student/Icons/RightIcon'
import LeftIcon from '@components/Student/Icons/LeftIcon'
import { IconButton } from '@material-ui/core'
import 'moment/locale/ru'
import { useSelector } from 'react-redux'
import { RootState } from '@store'
import { CALENDAR_EVENT_TYPES, ROLES, TZ_MSK } from '@utils/constants'
import { toast } from 'react-toastify'
import { calendarUtil } from '@utils/calendarUtil'

const Schedule = ({ studentId }: { studentId: string }) => {
  const [events, setEvents] = useState<IEvent[]>([])

  const [startEvent, setStartEvent] = useState(0)
  const profile = useSelector((state: RootState) => state.profile.profile as IProfile)
  const [balanceCount, setBalanceCount] = useState<number>(0)

  const getBalanceCount = useCallback(async () => {
    const payment = await fetchStudentPayments(studentId)
    let balanceCount = Object.values(payment).reduce((prev, next) => prev + Number(next.package), 0)

    setBalanceCount(balanceCount)
  }, [studentId])

  useEffect(() => {
    getBalanceCount()
  }, [getBalanceCount])
  useEffect(() => {
    const timeZone =
      profile.role === ROLES.MANAGER || ROLES.MANAGER_ASSISTANT || ROLES.METHODIST
        ? TZ_MSK
        : profile.timeZone

    getStudentCalendarEvents(studentId, timeZone).then(async newEvents => {
      let allEvents = calendarUtil.getAllEvents(newEvents, timeZone, false)

      allEvents = await Promise.all(
        allEvents.map(async event => {
          if (event.teacherName) {
            return event
          }

          const teacher = await fetchTeacherProfileByUid(event.teacherId)
          return { ...event, teacherName: `${teacher.name} ${teacher.lastName}` }
        })
      )
      setEvents(allEvents)
    })
  }, [studentId, profile])
  const slideRight = () => {
    setStartEvent(prev => prev + 3)
  }

  const slideLeft = () => {
    setStartEvent(prev => prev - 3)
  }

  let eventsJsx: any[] = []
  const copyTeacherName = (teacherName: string) => {
    navigator.clipboard.writeText(`${teacherName} `)
    toast('Text Copied in ClipBoard', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'message-success',
    })
  }

  for (let i = startEvent; i < events.length; i++) {
    const teacherName = events[i].teacherName
    eventsJsx.push(
      <Styled.ScheduleBlock>
        <Styled.Div style={{ display: 'flex', alignItems: 'center' }}>
          <span> {moment(events[i].rrule.dtstart).format('DD.MM')}</span>
          <Styled.WeekBlock>
            <Styled.WeekLabel>
              {moment(events[i].rrule.dtstart)
                .locale('en')
                .format('MMMM')}
            </Styled.WeekLabel>
            <span style={{ width: '180px' }}>
              {events[i].type === CALENDAR_EVENT_TYPES.TRIAL
                ? 'trial lesson'
                : events[i].type === CALENDAR_EVENT_TYPES.ONCE
                ? 'one-time lesson'
                : 'regular lesson'}
              |
              <Styled.TeacherName
                onClick={() => {
                  copyTeacherName(teacherName)
                }}
                className="teacher-name"
              >{teacherName}</Styled.TeacherName>
            </span>
          </Styled.WeekBlock>
        </Styled.Div>
        <Styled.ProgressValue>
          {moment(events[i].rrule.dtstart).format('HH:mm')}
        </Styled.ProgressValue>
      </Styled.ScheduleBlock>
    )
  }

  return (
    <Styled.ScheduleContainter>
      <Styled.StudentCardHeader
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span>Schedule</span>
      </Styled.StudentCardHeader>
      <Styled.Div className="schedule-content">
        <div style={{ display: 'flex' }}>
          <Styled.BalanceCount>Balance of lessons: </Styled.BalanceCount>
          <Styled.Balance> {balanceCount}</Styled.Balance>
        </div>
        <Styled.SliderButtons>
          <IconButton disabled={startEvent === 0} onClick={slideLeft}>
            <LeftIcon />
          </IconButton>
          <IconButton disabled={startEvent + 3 >= events.length - 1} onClick={slideRight}>
            <RightIcon />
          </IconButton>
        </Styled.SliderButtons>
        {events.length ? (
          <Styled.ScheduleList>{eventsJsx}</Styled.ScheduleList>
        ) : (
          <Styled.ToCenter>No classes</Styled.ToCenter>
        )}
      </Styled.Div>
    </Styled.ScheduleContainter>
  )
}

export default Schedule
