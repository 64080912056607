import { IState } from '@models/IIndividualPlan'

import {
  IndividualPlanActionTypes,
  REACHED_INTERMEDIATE_GOAL,
  REACHED_MAIN_GOAL,
  REGENERATE_INDIVIDUAL_PLAN,
  SET_PLAN,
  UPDATE_DAILY_TASK,
} from './types'

const initialState = {
  allTask: [],
  createdAt: null,
  currentLevel: null,
  plan: [],
  points: 0,
  lastVisit: null,
}

export const individualPlanReducer = (
  state: IState = initialState,
  action: IndividualPlanActionTypes
) => {
  switch (action.type) {
    case SET_PLAN:
      return { ...state, ...action.payload }
    case UPDATE_DAILY_TASK:
      const { day, taskId, points } = action.payload
      return {
        ...state,
        allTask: state.allTask.map(task =>
          task.id === taskId ? { ...task, isDone: !task.isDone } : task
        ),
        plan: state.plan.map(dayPlan =>
          dayPlan.day === day
            ? {
                ...dayPlan,
                tasks: dayPlan.tasks.map(task =>
                  task.id === taskId ? { ...task, isDone: !task.isDone } : task
                ),
              }
            : dayPlan
        ),
        points,
      }
    case REGENERATE_INDIVIDUAL_PLAN:
      return { ...state, plan: [...action.payload.plan], createdAt: action.payload.date }
    case REACHED_INTERMEDIATE_GOAL:
      return { ...state, reachedIntermediateGoal: true }
    case REACHED_MAIN_GOAL:
      return { ...state, reachedMainGoal: true }
    default:
      return state
  }
}
