import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { RadioGroup } from '@material-ui/core'
import { ATTITUDE_TO_HOMEWORK_OPTIONS } from '@utils/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function({
  attitudeToHomework,
  onChange,
}: {
  attitudeToHomework?: string
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  return (
    <RadioGroup
      aria-label="attitudeToHomework"
      name="attitudeToHomework"
      value={attitudeToHomework}
      onChange={e =>
        onChange({ name: 'attitudeToHomework', value: e.target.value })
      }
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30,
        marginRight: 30,
        gap: 16,
      }}
    >
      {ATTITUDE_TO_HOMEWORK_OPTIONS.map(option => {
        let img =
          option !== 'DoesntMatter'
            ? require(`../../../../Icons/${option}.svg`)
            : null
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CustomMaterialRadioButton
              name={option}
              label={t(`AttitudeToHomework.${option}`)}
              key={option}
            />
            {img && (
              <img style={{ marginLeft: 10 }} src={img.default} alt={option} />
            )}
          </div>
        )
      })}
    </RadioGroup>
  )
}
