import React from 'react'

import { FileIcon } from '@components/Icons'
import SettingsBlock from './Settings'

import * as Styled from './classroom-files.styles'
import { makeStyles, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UpArrow from '@images/UpArrow.svg'
import DownArrow from '@images/DownArrow.svg'
import { changeBlockPosition } from '@servise/classroomAPI'
import { AppDispatch } from '@store/index'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'transparent',
  },
}))
const DocsComponent = ({ fileData, classID, isStudent }) => {
  const classes = useStyles()
  const dispatch: AppDispatch = useDispatch()

  const { t } = useTranslation()
  const handleUpSide = () => {
    dispatch(changeBlockPosition(classID, fileData.id, true))
  }
  const handleDownSide = () => {
    dispatch(changeBlockPosition(classID, fileData.id, false))
  }

  return (
    <Styled.FileContainer id={fileData.id}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<TooltipText text={t('DownloadFile')} />}
        placement="left"
      >
        <a
          href={fileData.url}
          target="_blank"
          style={{ textDecoration: 'none', width: '100%' }}
          rel="noreferrer"
        >
          <Styled.DocsFile>
            <FileIcon />
            <Styled.FileText style={{ marginBottom: 0, marginLeft: 20 }}>
              {fileData.file_name}
            </Styled.FileText>
          </Styled.DocsFile>
        </a>
      </Tooltip>
      <SettingsBlock fileData={fileData} />
      {!isStudent ? (
        <Styled.UpDownBlock type={fileData.type}>
          <img src={UpArrow} alt="UpArrow" onClick={handleUpSide} />
          <img src={DownArrow} alt="DownArrow" onClick={handleDownSide} />
        </Styled.UpDownBlock>
      ) : null}
      {}
    </Styled.FileContainer>
  )
}

export default DocsComponent
export const TooltipText = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        padding: '0px 15px',

        fontSize: 14,
        lineHeight: 19,

        background: 'rgba(65, 71, 82, 0.8)',
        borderRadius: 8,
        color: '#fff',
      }}
    >
      {text}
    </div>
  )
}
