import React from 'react'


function EssayBlock({ onStateChange, id, preloadedState }) {
  const initialState = {
    heading: '',
    condition: '',
    listOfWords: [],
    minWords: 0,
    randomWord: []
  }
  let tempState = {...preloadedState, randomWord: []} || initialState

  const changeState = state => {
    onStateChange(id, {
      ...tempState,
      ...state,
    })
  }

  return (
    <div className='aaa'>
      <textarea
        className="block-essay"
        placeholder="Тема сочинения"
        onChange={e => {
          changeState({
            heading: e.target.value,
          })
        }}
        value={tempState.heading}
      />
      <textarea
        className="block-essay"
        placeholder="Дополнительные условия сочинения"
        onChange={e => {
          changeState({
            condition: e.target.value,
          })
        }}
        value={tempState.condition}
      />
      <textarea
        className="block-essay"
        placeholder="Перечислите через запятую слова, которые нужно употребить в сочинении + 2 случайных слова из словаря ученика добавятся автоматически"
        onChange={e => {
          changeState({
            listOfWords: e.target.value.split(','),
          })
        }}
        value={tempState.listOfWords}
      />
      <div className='block-essay'>
        <span className='essay-input-details'>
          Минимальное количество слов:
        </span>
        <input className='block-essay-input' placeholder='0' type={'number'} onChange={e => {
          changeState({
            minWords: e.target.value,
          })
        }}
          value={Number(tempState.minWords)} />
      </div>
    </div>
  )
}

export default EssayBlock