import {
  IClassroom,
  IClassroomBlock,
  IPublishedCourse,
  IProfile,
  ICurrentCourseInfo,
  IStudentProfile,
} from '@models'

export const SET_CLASSROOMS = 'SET_CLASSROOMS'
export const SET_CLASSROOM = 'SET_CLASSROOM'
export const ADD_FILE_TO_CLASSROOM = 'ADD_FILE_TO_CLASSROOM'
export const DELETE_FILE_FROM_CLASSROOM = 'DELETE_FILE_FROM_CLASSROOM'
export const CREATE_CLASSROOM = 'CREATE_CLASSROOM'
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM'
export const DELETE_CLASSROOM = 'DELETE_CLASSROOM'
export const SET_CLASSROOM_FILES = 'SET_CLASSROOM_FILES'
export const SET_STUDENTS = 'SET_STUDENTS'
export const SET_STUDENT = 'SET_STUDENT'
export const SET_PUBLISHED_COURSES = 'SET_PUBLISHED_COURSES'
export const SET_DISPLAYED_COURSE = 'SET_DISPLAYED_COURSE'
export const CLEAR_DISPLAYED_COURSE = 'CLEAR_DISPLAYED_COURSE'
export const SET_LOADING_STUDENT_PAGE = 'SET_LOADING_STUDENT_PAGE'
export const SET_STUDENT_CURRENT_COURSE = 'SET_STUDENT_CURRENT_COURSE'
export const SET_STUDENT_DATA_IN_CLASSROOOM = 'SET_STUDENT_DATA_IN_CLASSROOOM'
export const SET_STUDENT_DATA_CLASSROOMS = 'SET_STUDENT_DATA_CLASSROOMS'
export const REMOVE_STUDENT = 'REMOVE_STUDENT'

interface SetClassroomsAction {
  type: typeof SET_CLASSROOMS
  payload: IClassroom[]
}
interface SetStudentDataClassroomsAction {
  type: typeof SET_STUDENT_DATA_CLASSROOMS
  payload: { id: string; student: IStudentProfile }
}

interface SetClassroomFilesAction {
  type: typeof SET_CLASSROOM_FILES
  payload: IClassroomBlock[]
}

interface CreateClassroomAction {
  type: typeof CREATE_CLASSROOM
  payload: IClassroom
}
interface UpdateClassroomAction {
  type: typeof UPDATE_CLASSROOM
  payload: IClassroom
}

interface DeleteClassroomAction {
  type: typeof DELETE_CLASSROOM
  payload: string
}

interface SetClassroomAction {
  type: typeof SET_CLASSROOM
  payload: IClassroom
}

interface AddFileToClassroomAction {
  type: typeof ADD_FILE_TO_CLASSROOM
  payload: IClassroomBlock
}

interface DeleteFileFromClassroomAction {
  type: typeof DELETE_FILE_FROM_CLASSROOM
  payload: string
}

interface SetStudentsAction {
  type: typeof SET_STUDENTS
  payload: IStudentProfile[]
}
interface SetStudentDataInClassrrom {
  type: typeof SET_STUDENT_DATA_IN_CLASSROOOM
  payload: IStudentProfile
}

interface SetStudentAction {
  type: typeof SET_STUDENT
  payload: IProfile
}

interface SetPublishedCoursesAction {
  type: typeof SET_PUBLISHED_COURSES
  payload: IPublishedCourse[]
}

interface SetDisplayedCourse {
  type: typeof SET_DISPLAYED_COURSE
  payload: IPublishedCourse
}

interface ClearDisplayedCourse {
  type: typeof CLEAR_DISPLAYED_COURSE
}

interface SetLoadingStudentPage {
  type: typeof SET_LOADING_STUDENT_PAGE
  payload: boolean
}

interface SetStudentCurrentCourse {
  type: typeof SET_STUDENT_CURRENT_COURSE
  payload: ICurrentCourseInfo
}

interface RemoveStudent {
  type: typeof REMOVE_STUDENT
  payload: IStudentProfile['id']
}

export type ClassroomActionsTypes =
  | SetClassroomAction
  | SetClassroomsAction
  | CreateClassroomAction
  | UpdateClassroomAction
  | DeleteClassroomAction
  | SetClassroomFilesAction
  | AddFileToClassroomAction
  | DeleteFileFromClassroomAction
  | SetStudentsAction
  | SetStudentAction
  | SetPublishedCoursesAction
  | SetDisplayedCourse
  | ClearDisplayedCourse
  | SetLoadingStudentPage
  | SetStudentCurrentCourse
  | SetStudentDataInClassrrom
  | SetStudentDataClassroomsAction
  | RemoveStudent
