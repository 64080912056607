const GET_LESSONS = 'GET_LESSONS'
const GET_LESSONS_FULFILLED = 'GET_LESSONS_FULFILLED'
const GET_LESSONS_REJECTED = 'GET_LESSONS_REJECTED'

const CREATE_LESSON = 'CREATE_LESSON'
const CREATE_LESSON_FULFILLED = 'CREATE_LESSON_FULFILLED'
const CREATE_LESSON_REJECTED = 'CREATE_LESSON_REJECTED'

const DELETE_LESSON = 'DELETE_LESSON'
const DELETE_LESSON_FULFILLED = 'DELETE_LESSON_FULFILLED'
const DELETE_LESSON_REJECTED = 'DELETE_LESSON_REJECTED'

const DUPLICATE_LESSON = 'DUPLICATE_LESSON'
const DUPLICATE_LESSON_FULFILLED = 'DUPLICATE_LESSON_FULFILLED'
const DUPLICATE_LESSON_REJECTED = 'DUPLICATE_LESSON_REJECTED'

const EDIT_COURSE = 'EDIT_COURSE'
const EDIT_COURSE_FULFILLED = 'EDIT_COURSE_FULFILLED'
const EDIT_COURSE_REJECTED = 'EDIT_COURSE_REJECTED'

const RESET_LESSONS = 'RESET_LESSONS'

const EDIT_LESSON = 'EDIT_LESSON'
const EDIT_LESSON_FULFILLED = 'EDIT_LESSON_FULFILLED'
const EDIT_LESSON_REJECTED = 'EDIT_LESSON_REJECTED'
const UPDATE_LESSONS = 'UPDATE_LESSONS'

export {
  GET_LESSONS,
  GET_LESSONS_FULFILLED,
  GET_LESSONS_REJECTED,
  CREATE_LESSON,
  CREATE_LESSON_FULFILLED,
  CREATE_LESSON_REJECTED,
  DELETE_LESSON,
  DELETE_LESSON_FULFILLED,
  DELETE_LESSON_REJECTED,
  DUPLICATE_LESSON,
  DUPLICATE_LESSON_FULFILLED,
  DUPLICATE_LESSON_REJECTED,
  EDIT_COURSE,
  EDIT_COURSE_FULFILLED,
  EDIT_COURSE_REJECTED,
  RESET_LESSONS,
  EDIT_LESSON,
  EDIT_LESSON_FULFILLED,
  EDIT_LESSON_REJECTED,
  UPDATE_LESSONS,
}
