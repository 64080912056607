import * as React from 'react'

const LessonMenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 4H21V14C21 15.1046 20.1046 16 19 16H13V17.4338L17.5145 20.1425L16.4855 21.8575L13 19.7662V22H11V19.7662L7.5145 21.8575L6.4855 20.1425L11 17.4338V16H5C3.89543 16 3 15.1046 3 14V4H2V2H22V4ZM5 4V14H19V4H5ZM8 12H10V8H8V12ZM13 12H11V6H13V12ZM14 12H16V9H14V12Z"
      />
    </svg>
  )
}

export default LessonMenuIcon
