import React from 'react';

const RemoveRowIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.93161 2.93161C-0.977205 6.84043 -0.977205 13.1596 2.93161 17.0684C6.84043 20.9772 13.1596 20.9772 17.0684 17.0684C20.9772 13.1596 20.9772 6.84043 17.0684 2.93161C13.1596 -0.977205 6.84043 -0.977205 2.93161 2.93161ZM16.0788 10C16.0789 10.1309 16.0533 10.2606 16.0032 10.3816C15.9532 10.5025 15.8798 10.6125 15.7872 10.705C15.6946 10.7976 15.5847 10.871 15.4637 10.9211C15.3427 10.9711 15.2131 10.9968 15.0822 10.9966L10.9966 10.9966L10.9966 15.0822C10.9966 15.3465 10.8916 15.6 10.7047 15.7869C10.5178 15.9738 10.2643 16.0788 10 16.0788C9.73567 16.0788 9.48217 15.9738 9.29527 15.7869C9.20272 15.6944 9.12931 15.5845 9.07922 15.4636C9.02914 15.3427 9.00336 15.2131 9.00336 15.0822V10.9966H4.91783C4.6535 10.9966 4.4 10.8916 4.2131 10.7047C4.02619 10.5178 3.92119 10.2643 3.92119 10C3.92119 9.73567 4.02619 9.48217 4.2131 9.29527C4.4 9.10836 4.6535 9.00336 4.91783 9.00336H9.00336V4.91783C9.00336 4.78695 9.02914 4.65735 9.07922 4.53643C9.12931 4.41551 9.20272 4.30564 9.29527 4.2131C9.38781 4.12055 9.49768 4.04714 9.6186 3.99705C9.73952 3.94697 9.86912 3.92119 10 3.92119C10.1309 3.92119 10.2605 3.94697 10.3814 3.99705C10.5023 4.04714 10.6122 4.12055 10.7047 4.2131C10.7973 4.30564 10.8707 4.41551 10.9208 4.53643C10.9709 4.65735 10.9966 4.78695 10.9966 4.91783L10.9966 9.00336H15.0822C15.6194 9.00336 16.0717 9.45573 16.0788 10Z"
      fill="currentColor"
    />
    <path
      d="M9.00336 4.91783V9.00336H10.9966L10.9966 4.91783C10.9966 4.78695 10.9709 4.65735 10.9208 4.53643C10.8707 4.41551 10.7973 4.30564 10.7047 4.2131C10.6122 4.12055 10.5023 4.04714 10.3814 3.99705C10.2605 3.94697 10.1309 3.92119 10 3.92119C9.86912 3.92119 9.73952 3.94697 9.6186 3.99705C9.49768 4.04714 9.38781 4.12055 9.29527 4.2131C9.20272 4.30564 9.12931 4.41551 9.07922 4.53643C9.02914 4.65735 9.00336 4.78695 9.00336 4.91783Z"
      fill="currentColor"
    />
    <path
      d="M10.9966 15.0822L10.9966 10.9966H9.00336V15.0822C9.00336 15.2131 9.02914 15.3427 9.07922 15.4636C9.12931 15.5845 9.20272 15.6944 9.29527 15.7869C9.48217 15.9738 9.73567 16.0788 10 16.0788C10.2643 16.0788 10.5178 15.9738 10.7047 15.7869C10.8916 15.6 10.9966 15.3465 10.9966 15.0822Z"
      fill="currentColor"
    />
  </svg>
);

export { RemoveRowIcon };
