import React from 'react'

export default function CreditCardIcon(props) {
  if (props.isAdded) {
    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.81818 3.89648H21.1818C22.186 3.89648 23 4.79191 23 5.89648V17.8965C23 19.0011 22.186 19.8965 21.1818 19.8965H2.81818C1.81403 19.8965 1 19.0011 1 17.8965V5.89648C1 4.79191 1.81403 3.89648 2.81818 3.89648ZM3 10.8965V17.8965H21V10.8965H3ZM3 8.89648H21V5.89648H3V8.89648Z"
          fill="#90A4BD"
        />
      </svg>
    )
  }
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1818 3H2.81818C1.81403 3 1 3.89543 1 5V17C1 18.1046 1.81403 19 2.81818 19H14V17H3V10H21V13H23V5C23 3.89543 22.186 3 21.1818 3ZM21 8V5H3V8H21ZM17 19V17H19V15H21V17H23V19H21V21H19V19H17Z"
        fill="#90A4BD"
      />
    </svg>
  )
}
