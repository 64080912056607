import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import RSC from 'react-scrollbars-custom'
import Spinner from 'react-spinkit'
import { useIndexedDB } from 'react-indexed-db'
import { Document, Page } from 'react-pdf/dist/entry.webpack'
import 'react-pdf/dist/Page/AnnotationLayer.css'

import { database } from '@configs/firebase'
import { toFixedNumber, localType } from '@utils/helpers'

import { IClassroomBlock, ICursorState, IDocumentState, IGoogleDrive } from '@models'

import {
  GoogleDrive,
  cursorPositionChanged,
  documentChangePage,
  changeBlockPosition,
} from '@servise'

import {
  HideIcon,
  CursorIcon,
  PlayIcon,
  ReduceIcon,
  IncreaseIcon,
  DownloadIcon,
} from '@components/Icons'

import SettingsBlock from './Settings'
import { withTranslation } from 'react-i18next'
import { ScrollContiner } from '@pages/teacher/Classroom/classroom.styles'
import * as Styled from './classroom-files.styles'
import { formatBytes } from '@utils/helperst'
import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import { TooltipText } from './Docs'
import UpArrow from '@images/UpArrow.svg'
import DownArrow from '@images/DownArrow.svg'
import { connect } from 'react-redux'

type StateType = {
  scale: number
  numPages: number
  pageNumber: number
  file: any
  fileLoad: boolean
  width: number
  height: number
}

type PropsType = {
  fileData: IClassroomBlock
  googleDrive: IGoogleDrive | null
  cursorState: ICursorState
  documentState: IDocumentState
  classID: string
  isStudent: boolean
}
const countPages = 1

const styles = theme => ({
  tooltip: {
    background: 'transparent',
  },
})

class RenderPDF extends Component<PropsType, StateType> {
  pdfPage = React.createRef<Page>()
  constructor(props: PropsType) {
    super(props)

    this.state = {
      scale: 1.0,
      numPages: 0,
      pageNumber: 1,
      file: null,
      fileLoad: false,
      height: 841,
      width: 595,
    }
  }

  componentDidUpdate(prevProps: PropsType, prevState) {
    if (prevProps.documentState.pageNumbers !== this.props.documentState.pageNumbers) {
      this.setPageNumber()
    }
  }

  setPageNumber = () => {
    const { documentState, fileData } = this.props
    let pageNumbers = documentState.pageNumbers
    const pageNumber = pageNumbers[fileData.id] ? pageNumbers[fileData.id] : 1
    this.setState({ pageNumber: pageNumber })
  }
  checkPageNumber = () => {
    const { documentState, classID, fileData } = this.props
    const { pageNumber, id } = fileData
    let pageNumbers = documentState.pageNumbers

    if (!pageNumbers[id]) {
      let newPageNumbers = pageNumbers
      newPageNumbers[id] = pageNumber ? pageNumber : 1
      database
        .ref(`${classID}`)
        .child('documentState')
        .set({
          pageNumbers: newPageNumbers,
        })
    }
  }

  async componentDidMount() {
    try {
      this.checkPageNumber()
      this.setPageNumber()
      this.setState({ fileLoad: true })
      const { getByIndex, add } = useIndexedDB('files')
      const { googleDrive, fileData } = this.props

      getByIndex('name', fileData.url).then(async fileFromDB => {
        if (fileFromDB) {
          this.setState({ file: fileFromDB.blob, fileLoad: false })

          this.updateWindowDimensions()
        } else {
          if (localType(fileData.url)) {
            let responseDownloadFile = await GoogleDrive.downloadFile(fileData.url)
            if (responseDownloadFile.status !== 200) {
              if (googleDrive && googleDrive.refreshToken) {
                const refreshToken = await GoogleDrive.refreshToken(googleDrive.refreshToken)
                if (refreshToken) {
                  responseDownloadFile = await GoogleDrive.downloadFile(fileData.url)
                }
              }
            }

            responseDownloadFile
              .blob()
              .then(fileBlob => {
                add({ name: fileData.url, blob: fileBlob }).then(
                  event => {
                    this.setState({ file: fileBlob, fileLoad: false })
                    this.updateWindowDimensions()
                  },
                  error => {
                    this.setState({ fileLoad: false })
                  }
                )
              })
              .catch(error => {
                this.setState({ fileLoad: false })
              })
          } else {
            fetch(fileData.url)
              .then(res => {
                return res.blob()
              })
              .then(fileBlob => {
                add({ name: fileData.url, blob: fileBlob }).then(
                  event => {
                    this.setState({ file: fileBlob, fileLoad: false })
                    this.updateWindowDimensions()
                  },
                  error => {
                    this.setState({ fileLoad: false })
                  }
                )
              })
              .catch(() => {
                this.setState({ fileLoad: false })
              })
          }
        }
      })
    } catch (e) {
      this.setState({ fileLoad: false })
    }

    window.addEventListener('resize', this.updateWindowDimensions)
  }

  previousPage = () => {
    const data = {
      useInput: false,
      offset: -countPages,
      fileID: this.props.fileData.id,
      numPages: this.state.numPages,
      pageNumber: this.state.pageNumber,
      classID: this.props.classID,
      documentState: this.props.documentState,
    }
    documentChangePage(data)
  }

  nextPage = () => {
    const data = {
      useInput: false,
      offset: countPages,
      fileID: this.props.fileData.id,
      numPages: this.state.numPages,
      pageNumber: this.state.pageNumber,
      classID: this.props.classID,
      documentState: this.props.documentState,
    }

    documentChangePage(data)
  }

  onChangePage = e => {
    const pageNumber = e.target.value

    if (pageNumber <= this.state.numPages) {
      this.setState({ pageNumber: e.target.value })
    }
  }

  onPositionChanged = event => {
    const { height, width } = this.state
    const { fileData, classID } = this.props
    let currentTargetRect = event.currentTarget.getBoundingClientRect()
    const position = {
      x: event.pageX - currentTargetRect.left,
      y: event.pageY - currentTargetRect.top,
    }

    const data = {
      width,
      height,
      classID,
      fileID: fileData.id,
      position,
    }
    cursorPositionChanged(data)
  }

  keyPress = e => {
    const pageNumber = parseInt(this.state.pageNumber)
    if (e.key === 'Enter') {
      if (!pageNumber) {
        this.setState({ pageNumber })
        this.setPageNumber()
      }
      const data = {
        useInput: true,
        offset: pageNumber,
        fileID: this.props.fileData.id,
        numPages: this.state.numPages,
        pageNumber: this.state.pageNumber,
        classID: this.props.classID,
        documentState: this.props.documentState,
      }
      documentChangePage(data)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (this.pdfPage && this.pdfPage.ref) {
      const width = this.pdfPage.ref.firstElementChild.clientWidth
      const height = this.pdfPage.ref.firstElementChild.clientHeight
      this.setState({
        width,
        height,
      })
    }
  }

  onDocumentLoadSuccess = document => {
    const { numPages } = document
    this.setState({ numPages })
  }
  loadFile = blob => {
    this.setState({ file: blob, fileLoad: true })
  }

  ref = page => {
    this.pdfPage = page
  }

  setScaleDocument = scale => {
    this.setState(
      prevState => ({
        scale: toFixedNumber(prevState.scale + scale, 2),
      }),
      () => {
        this.updateWindowDimensions()
      }
    )
  }

  handleUpSide = () => {
    this.props.changeBlockPosition(this.props.classID, this.props.fileData.id, true)
  }
  handleDownSide = () => {
    this.props.changeBlockPosition(this.props.classID, this.props.fileData.id, false)
  }

  render() {
    const { cursorState, fileData, documentState, classes } = this.props
    const fileID = fileData.id

    const { height, width, file, fileLoad, scale, numPages } = this.state
    const pageNumber = documentState.pageNumbers[fileData.id]
      ? documentState.pageNumbers[fileData.id]
      : 1

    const formatBy = file ? formatBytes(file.size) : ''
    return (
      <Styled.FileContainer
        style={{ padding: '17px 0 17px 0' }}
        id={this.props.fileData.id}
        type={this.props.fileData.type}
      >
        <Styled.PDFContainer>
          <Styled.FileText
            style={{
              marginLeft: 35,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            {fileData.file_name}
            {this.props.isStudent && (
              <a href={this.props.fileData.url} target="_blank" rel="noreferrer">
                <Tooltip
                  title={<TooltipText text={`${this.props.t('Download')} .pdf, ${formatBy}`} />}
                  className="download-button"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton size={'small'}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </a>
            )}
          </Styled.FileText>
          <Styled.PDFDocument>
            <Styled.PDFPrevConainer onClick={() => (pageNumber > 1 ? this.previousPage() : 0)}>
              <IconButton style={{ margin: 'auto' }}>
                <PlayIcon style={{ transform: 'rotate(-180deg)' }} />
              </IconButton>
            </Styled.PDFPrevConainer>
            <Styled.DocumentContainer>
              <ScrollContiner>
                <RSC noDefaultStyles disableTracksWidthCompensation style={{ position: null }}>
                  {!fileLoad ? (
                    <Document
                      file={file}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                      error={this.props.t('TryDeleteFile')}
                    >
                      <Styled.SyncCursorContainer
                        onClick={this.onPositionChanged}
                        style={{ width: width, height: height }}
                      >
                        <Page ref={this.ref} pageNumber={pageNumber} scale={this.state.scale} />
                        {cursorState.fileID === fileID && !fileData.isHidden && (
                          <CursorIcon
                            style={{
                              position: 'absolute',
                              top: cursorState.position.y * height,
                              left: cursorState.position.x * width,
                            }}
                          />
                        )}
                      </Styled.SyncCursorContainer>

                      <Page className={'page-hide'} scale={this.state.scale} />
                      <Page
                        className={'page-hide'}
                        pageNumber={pageNumber}
                        scale={this.state.scale}
                      />
                      <Page
                        className={'page-hide'}
                        pageNumber={pageNumber + 1}
                        scale={this.state.scale}
                      />

                      <Page
                        className={'page-hide'}
                        pageNumber={pageNumber + 2}
                        scale={this.state.scale}
                      />
                    </Document>
                  ) : (
                    <Spinner name="line-spin-fade-loader" className="pdf-spin" fadeIn="none" />
                  )}
                </RSC>
              </ScrollContiner>

              <Styled.PdfPageNumberContainer hide={fileLoad}>
                <Styled.PdfActivePageInput
                  value={this.state.pageNumber}
                  onChange={this.onChangePage}
                  onKeyDown={this.keyPress}
                  min="1"
                  max={numPages}
                />
                <span>{`/${numPages}`}</span>
              </Styled.PdfPageNumberContainer>
              <Styled.PdfZoomButtons>
                <IconButton
                  size={'medium'}
                  disabled={scale === 3}
                  onClick={() => this.setScaleDocument(0.2)}
                >
                  <IncreaseIcon />
                </IconButton>
                <IconButton
                  size={'medium'}
                  disabled={scale === 0.4}
                  onClick={() => this.setScaleDocument(-0.2)}
                >
                  <ReduceIcon />
                </IconButton>
              </Styled.PdfZoomButtons>
            </Styled.DocumentContainer>
            <Styled.PDFPrevConainer
              style={{ width: 40 }}
              onClick={() => (this.state.numPages > pageNumber ? this.nextPage() : 0)}
            >
              <IconButton style={{ margin: 'auto' }}>
                <PlayIcon />
              </IconButton>
            </Styled.PDFPrevConainer>
          </Styled.PDFDocument>
        </Styled.PDFContainer>
        {fileData.isHidden && <HideIcon />}

        <SettingsBlock fileData={fileData} isPdf={true} />
        {!this.props.isStudent && (
          <Styled.UpDownBlock type={this.props.fileData.type} style={{ zIndex: '555' }}>
            <img src={UpArrow} alt="UpArrow" onClick={this.handleUpSide} />
            <img src={DownArrow} alt="DownArrow" onClick={this.handleDownSide} />
          </Styled.UpDownBlock>
        )}
      </Styled.FileContainer>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeBlockPosition: (classID: string, fileDataId: string, IsUpside: boolean) =>
      dispatch(changeBlockPosition(classID, fileDataId, IsUpside)),
  }
}

const WithStylesRenderPDF = withStyles(styles)(RenderPDF)
export default connect(null, mapDispatchToProps)(withTranslation()(WithStylesRenderPDF))
