import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'

import { ellipsify } from '@utils/helpers'
import { TZ_MSK } from '@utils/constants'
import * as Styled from './calendar-styles'
import moment from 'moment'
import { usersRef } from '@configs/firebase'
import { filterEvents } from '@servise/calendarAPI'
import { fetchStudentPayments } from '@servise/profileAPI'
import { useDispatch } from 'react-redux'
import { setCalendarEventsAction } from '@store/calendar/actions'

const regul = '#9ED6FF'
const razov = '#FFF7B3'
const prob = '#FF8282'
const reschedule = '#C2CFE0'
const booked = '#C1C1C1'
const newLesson = '#FF922D'

export default function Calendar(props) {
  const showedEvents = useRef([])
  const dispatch = useDispatch()
  const renderEventContent = useCallback(
    eventInfo => {
      const { event } = eventInfo

      const type = event.extendedProps.type
      const isConfirm = event.extendedProps.isConfirm

      let styles = {}
      if (event.extendedProps.reschedule) {
        styles.backgroundColor = reschedule
      }

      if (isConfirm) {
        styles.backgroundColor = newLesson
      } else if (type === 'trial') {
        styles.backgroundColor = prob
      } else if (type === 'booked') {
        styles.backgroundColor = booked
      } else if (event.extendedProps.balanceCount <= 0) {
        styles.backgroundColor = '#C1C1C1'
      } else if (type === 'regular') {
        styles.backgroundColor = regul
      } else if (type === 'once') {
        styles.backgroundColor = razov
      }

      const content =
        event.extendedProps.type === 'booked' ? (
          <div className="event-container" style={styles} id={event.extendedProps.eventID}>
            <span className="event-time-your-self"> Time for yourself</span>
          </div>
        ) : (
          <div className="event-container" style={styles} id={event.extendedProps.eventID}>
            <p className="event-title" style={{ color: isConfirm ? '#fff' : '' }}>
              {ellipsify(event.title, 15, '')}
            </p>
            <p className="event-description" style={{ color: isConfirm ? '#fff' : '' }}>
              {isConfirm ? 'new lesson' : event.extendedProps.type}
            </p>
          </div>
        )

      if (
        isConfirm &&
        !props.isManager &&
        !showedEvents.current.includes(event.extendedProps.eventID)
      ) {
        showedEvents.current.push(event.extendedProps.eventID)
      }
      return <>{content}</>
    },
    [showedEvents, props.isManager]
  )
  useEffect(() => {
    const confirmEvents = showedEvents.current
    const timeId = setTimeout(() => {
      for (const eventId of confirmEvents) {
        const eventEl = document.getElementById(eventId)
        if (eventEl) {
          eventEl.click()
        }
      }
    }, 1000)
    return () => {
      clearTimeout(timeId)
    }
  }, [showedEvents])

  useEffect(() => {
    const unsubscribe = usersRef
      .doc(props.teacherId)
      .collection('events')
      .onSnapshot(async snapShot => {
        let events = filterEvents(snapShot.docs, props.timeZone)
        events = await Promise.all(
          events.map(async event => {
            const payments = await fetchStudentPayments(event.studentId)
            let balanceCount = payments.reduce((prev, payment) => prev + payment.package, 0)
            return { ...event, balanceCount }
          })
        )
        dispatch(setCalendarEventsAction(events))
        return events
      })
    return () => {
      unsubscribe()
    }
  }, [props.teacherId, props.timeZone, dispatch])

  const bhUpdated = useMemo(() => {
    if (!props.bhUpdated) return ''
    return 'upd: ' + moment(props.bhUpdated.toDate()).format('HH:mm DD-MM-YY')
  }, [props.bhUpdated])

  return (
    <Styled.CalendarContainer
      isManager={props.isManager}
      style={{ '--fc-page-bg-color': '#f3f5f9', '--fc-border-color': '#C2CFE0' }}
    >
      <span className="last-update-time-user">{bhUpdated}</span>
      <FullCalendar
        initialView="timeGridWeek"
        locale="ru"
        droppable={false}
        headerToolbar={{
          left: '',
          center: '',
          right: 'month,agendaWeek,agendaDay,listMonth,listYear,listWeek,listDay',
        }}
        businessHours={props.businessHours}
        editable={false}
        eventClick={props.handleUpdateEventClick}
        plugins={[
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          rrulePlugin,
          momentTimezonePlugin,
        ]}
        ref={props.calendarComponentRef}
        weekends={true}
        firstDay={1}
        events={props.events}
        selectable={true}
        select={props.handleAddEventClick}
        slotMinTime={props.minTime}
        slotMaxTime={props.maxTime}
        timeZone={props.timeZone}
        allDaySlot={false}
        bootstrapFontAwesome={'Open Sans'}
        slotDuration={'0:30'}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitSeroMinute: true,
          meridiem: 'short',
        }}
        height={'auto'}
        eventClassNames={'calendar-event'}
        eventContent={renderEventContent}
        slotLabelContent={arg => {
          moment.tz.setDefault(props.timeZone)

          let timeEl = document.createElement('div')
          timeEl.classList.add('time-item')

          let currentTime = arg.text
          if (!props.isManager && props.timeZone !== TZ_MSK) {
            const timeInOriginalTimeZone = moment.tz(
              moment().format('YYYY-MM-DD') + ' ' + currentTime,
              'YYYY-MM-DD HH:mm',
              props.timeZone
            )
            const mskTime = timeInOriginalTimeZone.clone().tz(TZ_MSK)

            timeEl.innerHTML = `
            <span class="current-time">${currentTime}</span>
            <span class="msk-time">${mskTime.format('HH:mm')} мск</span>
          `
          } else {
            timeEl.innerHTML = `
            <span class="current-time">${currentTime}</span> `
          }

          return { domNodes: [timeEl] }
        }}
        dayHeaderContent={event => {
          let header = document.createElement('div')
          header.classList.add('event-column-header')
          moment.tz.setDefault(props.timeZone)
          moment.locale('ru')

          header.innerHTML = `<h5>${moment(event.date).format('DD')}${
            props.isManager ? ',' : ''
          }</h5> <p>${moment(event.date).format('dddd')}</p>`

          let arrayOfDomNodes = [header]
          return { domNodes: arrayOfDomNodes }
        }}
      />
    </Styled.CalendarContainer>
  )
}
