import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { firebaseAnalytics } from '@configs/firebase'
import { STUDENT_MODAL_NAME, TelegramShareLink, MobileAppLink } from '@utils/constants'
import { generatePromoCode } from '@utils/helperst'
import { updateProfilePromoCode } from '@servise/profileAPI'
import StudentButton from './Button'
import CloseModalImg from './Icons/close_modal.svg'
import PromoCodeStep1 from './Icons/PromoCodeStep1.svg'
import PromoCodeStep2 from './Icons/PromoCodeStep2.svg'
import PromoCodeStep3 from './Icons/PromoCodeStep3.svg'
import MobileAppModalIcon from './Icons/MobileAppModalIcon.svg'
import InviteFriendModalIcon from './Icons/InviteFriendModalIcon.svg'
import TelegramIcon from '@assets/icons/TelegramLogo.svg'
import { AppDispatch } from '@store'
import { theme } from '@styles/Theme'

import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { MainModal } from '@components'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #4d6084;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 334px;
  text-align: center;
  margin-top: 10px;
`

const ModalTitle = styled.div`
  font-family: Open Sans;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #414752;
  // display: flex;
  // justify-content: center;
  margin-top: 30px;
`

const SecondTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #4d6084;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 350px;
  text-align: center;
  margin-bottom: -15px;
`

export const ModalContentText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  width: 330px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #414752;
  margin-top: 30px;
`
export const ModalText = styled.div`
  font-family: Open Sans;
  width: 315px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #414752;
  margin-top: 20px;
`
export const TextSecondPart = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #414752;
`
export const TextThirdPart = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #414752;
`

const ModalHeader = styled.div`
  background: linear-gradient(104.67deg, #f0e7ff 0%, #dce8fd 48.96%, #ffcad3 100%);
  padding: 30px;
  border-radius: 20px 20px 0px 0px;
`
const ModalFooter = styled.div``

const PromoCodeText = styled.div`
  width: 274px;
  border: 1px solid #e1ecff;
  border-radius: 20px;
  margin: 25px auto;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
`

const CopyBtn = styled.div`
  background: linear-gradient(104.67deg, #f0e7ff 0%, #dce8fd 48.96%, #ffcad3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 20px;
  width: 50%;
  color: #566a8f;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  cursor: pointer;
`

const TelegramBtn = styled.a`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: linear-gradient(203.2deg, #7cd7ff 21.67%, #22b8f7 70%);
  border-radius: 20px;
  width: 217px;
  padding: 10px;
  cursor: pointer;
`
const MobileAppBtn = styled.a`
  margin: 25px auto 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%);
  border-radius: 20px;
  width: 135px;
  padding: 10px;
  cursor: pointer;
`

const InviteFriendBtn = styled.a`
  margin: 30px auto 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%);
  border-radius: 20px;
  width: 135px;
  padding: 10px;
  cursor: pointer;
`

const WorkHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d6084;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 28px;
  margin: 30px;
`
const StepText = styled.div`
  color: #7e92ac;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`
interface Props {
  modalName: STUDENT_MODAL_NAME | null
  onClose: () => void
  promoCode: string | null
  showPromoCodeModal: () => void
}

const StudentModal = ({ modalName, onClose, promoCode, showPromoCodeModal }: Props) => {
  const { t } = useTranslation()

  const [newPromoCode, setNewPromoCode] = useState<string | null>(null)
  const dispatch: AppDispatch = useDispatch()
  const DESCRIPTION = t('FreeTwoLessons')

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(DESCRIPTION + ' ' + newPromoCode).then(() => {
      firebaseAnalytics.logEvent('COPY_PROMO_CODE')
      toast(t('Toast.PromoCodeCopied'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-message-success',
      })
    })
  }

  useEffect(() => {
    if (promoCode) {
      setNewPromoCode(promoCode)
    } else {
      const generatedPromoCode = generatePromoCode(5)
      dispatch(updateProfilePromoCode(generatedPromoCode))
    }
  }, [dispatch, promoCode])

  const getChangeEventDateModalContent = () => {
    return (
      <>
        <Title>{t('ChangeEventDateModal.Title')}</Title>
        <ModalContentText>{t('ChangeEventDateModal.Desc')}</ModalContentText>
        <StudentButton
          onClick={() => {
            onClose()
          }}
          bgColor={theme.colors.yellow}
          text={t('Reschedule')}
          styles={{
            margin: '30px auto 0 auto',
          }}
        />
      </>
    )
  }

  const getPromoCodeModalContent = () => {
    return (

      <div
        style={{
          width: '512px',
          borderRadius: '20px',
          overflowY: 'hidden',
        }}
      >

        <ModalHeader>
          <Title> {t('GiveFriendLessons')}</Title>
          <PromoCodeText>
            <div
              style={{
                width: '50%',
                textAlign: 'center',
                color: '#414752',
                fontSize: '16px',
              }}
            >
              {newPromoCode}
            </div>
            <CopyBtn onClick={copyToClipBoard}>{t('Copy')}</CopyBtn>
          </PromoCodeText>
          <TelegramBtn
            href={TelegramShareLink(promoCode)}
            onClick={() => firebaseAnalytics.logEvent('SEND_PROMO_CODE_TG')}
            target="_blank"
          >
            {t('Telegram.SendText')}{' '}
            <img src={TelegramIcon} alt="telegram-icon" style={{ marginLeft: '20px' }} />
          </TelegramBtn>
        </ModalHeader>
        <ModalFooter style={{ padding: '30px', borderRadius: '20px' }}>
          <WorkHeading>{t('PromoCode.WorkTitle')}</WorkHeading>

          <div>
            <Step>
              <img src={PromoCodeStep1} alt="" />
              <StepText>{t('PromoCode.WorkStep1')}</StepText>
            </Step>
            <Step>
              <img src={PromoCodeStep2} alt="" />
              <StepText>{t('PromoCode.WorkStep2')}</StepText>
            </Step>
            <Step>
              <img src={PromoCodeStep3} alt="" />
              <StepText>{t('PromoCode.WorkStep3')}</StepText>
            </Step>
          </div>
        </ModalFooter>
      </div>
    )
  }

  const getMobileAppModalContent = () => {
    return (
      <>
        <img src={MobileAppModalIcon} alt="" style={{ marginBottom: '15px' }} />
        <Title>{t('MobileAppModal.FirstTitle')}</Title>
        <SecondTitle> {t('MobileAppModal.SecondTitle')}</SecondTitle>
        <ModalContentText>{t('MobileAppModal.TextFirstPart')}</ModalContentText>
        <TextSecondPart>{t('MobileAppModal.TextSecondPart')}</TextSecondPart>
        <TextThirdPart>{t('MobileAppModal.TextThirdPart')}</TextThirdPart>
        <MobileAppBtn
          href={MobileAppLink}
          target="_blank"
          onClick={() => {
            onClose()
          }}
        >
          {t('More')}
        </MobileAppBtn>
      </>
    )
  }

  const getInviteFriendModalContent = () => {
    return (
      <>
        <img src={InviteFriendModalIcon} alt="" />
        <ModalTitle>{t('InviteFriendsModal.Title')}</ModalTitle>
        <ModalText>{t('InviteFriendsModal.Text')}</ModalText>
        <InviteFriendBtn
          onClick={() => {
            onClose()
            showPromoCodeModal()
          }}
        >
          {t('Invite')}
        </InviteFriendBtn>
      </>
    )
  }

  const getModalWindow = () => {
    switch (modalName) {
      case STUDENT_MODAL_NAME.INVITE_FRIEND:
        return getInviteFriendModalContent()
      case STUDENT_MODAL_NAME.BONUS:
        return getPromoCodeModalContent()
      case STUDENT_MODAL_NAME.CHANGE_EVENT_DATE:
        return getChangeEventDateModalContent()
      case STUDENT_MODAL_NAME.MOBILE_CONTENT:
        return getMobileAppModalContent()

      default:
        return null
    }
  }

  return (
    // @ts-ignore
    <MainModal
      open={modalName}
      children={() => {
        return (
          <Container>
            <img
              src={CloseModalImg}
              alt=""
              style={{
                position: 'absolute',
                top: '20px',
                right: '25px',
                cursor: 'pointer',
              }}
              onClick={onClose}
            />
            {getModalWindow()}
          </Container>
        )
      }}
      handleClose={onClose}
    />
  )
}

export default StudentModal
