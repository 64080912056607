import styled from 'styled-components'
import { theme } from '@styles/Theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const TeacherProfileContainer = styled.div<{ isDeactivated: boolean }>`
  display: flex;
  margin-top: 15px;
  margin-left: 45px;
  opacity: ${props => props.isDeactivated && '50%'};
`
export const ProfileMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin-left: 60px;
`

export const EmailError = styled.div`
  color: red;
  font-size: 13px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ButtonContainer = styled.div`
  align-self: self-start;
  justify-content: space-between;
  display: flex;
  gap: 30px;
  margin-top: 10px;
  margin-right: 30px;
`
export const InputRows = styled.div`
  display: flex;
  gap: 70px;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const ProfileTitle = styled.h5`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #414752;
`

export const ProfileSettingsTitle = styled.h5`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #414752;
`

export const ProfileInfo = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #414752;
  margin: 30px 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export const KeyFeaturesDesc = styled.div`
  color: rgba(65, 71, 82, 0.5);
  font-size: 14px;
  font-family: Open Sans;
  letter-spacing: 0.14px;
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .avatar {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  input {
    display: none;
  }
  .upload-button {
  }
`

export const ProfileButton = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding-left: 15px;
  padding-right: 20px;
  justify-content: center;
  min-width: 160px;
  height: 40px;
  background: rgba(194, 207, 224, 0.3);
  color: #414752;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  svg {
    margin-right: 10px;
  }
`

export const AudioButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .delete-btn {
    svg {
      path {
        fill: #7e92ac;
      }

      width: 16;
      height: 17;
    }
  }

  span {
    font-size: 14px;
    line-height: 19px;
    color: #7e92ac;
  }
  input {
    display: none;
  }
`

export const ProfileSaveButton = styled.button<{ isSaved?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;

  height: 50px;
  border-radius: 50px;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-align: center;
  background: ${props => (props.isSaved ? '#4284ff' : '#7E92AC')};
  &:hover {
    background: #7e92ac;
  }
  svg {
    margin-right: 7px;
  }
`

export const AudioRecordModalContainer = styled.div`
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AudioRecordModalHeader = styled.h4`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #414752;
  margin-top: 40px;
  margin-bottom: 40px;
`

export const AudioRecordModalFooter = styled.div<{ isRecording: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: ${props => !props.isRecording && '48px'};
`

export const CancelButton = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #414752;
  margin-top: 20px;
`

export const AudioDescModal = styled.div<{ isBlock: boolean }>`
  ${props =>
    !props.isBlock &&
    `
  margin-left: 100px;
  margin-right: 100px;
  `}
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #7e92ac;
`
export const TeachersRoleLabel = styled.div`
  .css-ahj2mt-MuiTypography-root {
    font-weight: 400;
    font-size: 18px;
  }
`

export const TeachersRoleTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
`

export const ProfileCheckboxContainer = styled.div`
  display: flex;

  .notificationOption {
    margin-top: 12px;
    color: #414752;
    font-size: 16px;
    font-weight: 400;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 0px;
    }
  }

  .closeEnrolmentOfStudents {
    font-weight: 400;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 14px;
    }
  }

  .levelsOfStudents {
    font-weight: 400;
    cursor: pointer;
    margin-right: 24px;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 14px;
    }
  }

  .trialLessons {
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 6px;
    }
  }
`
export const customSelectStyles = (width: number, isWhiteBg?: boolean) => {
  return {
    container: (base: any, state: any) => ({
      ...base,
      outline: 'none',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#192A3E',
      fontSize: 18,
      marginLeft: 20,
    }),
    control: (base: any, state: any) => {
      return {
        ...base,
        borderRadius: state.menuIsOpen ? '20px 20px 0 0' : 20,
        width: width,
        height: 40,
        paddingLeft: 10,
        border: '1px solid #C2CFE0',
        boxShadow: state.isFocused ? null : null,
        backgroundColor: isWhiteBg ? '#fff' : theme.colors.bgDashboard,
        '&:hover': {
          border: '1px solid #C2CFE0',
        },
      }
    },
    input: (base: any, state: any) => ({
      ...base,
      outline: 'none',
    }),
    indicatorSeparator: () => {},
    dropdownIndicator: (defaultStyles, state) => ({
      ...defaultStyles,
      border: 'none',
      color: '#c2c2c2',
      '&:hover': {
        color: state.isFocused ? ' #c2c2c2' : ' #c2c2c2',
      },

      marginRight: 7,
    }),
    placeholder: () => ({
      fontSize: 14,
      color: 'rgba(25, 42, 62, 0.15)',
    }),

    option: (styles: any, { isFocused, isSelected }) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: isWhiteBg
        ? '#fff'
        : isFocused
        ? 'rgba(196, 196, 196, 0.19)'
        : theme.colors.bgDashboard,
      color: isSelected ? '#FF852D' : '#192A3E',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: 220,
      boxShadow: state.isFocused ? null : null,

      'overflow-x': 'hidden',

      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': 5,
        borderRadius: 10,
        background: '#C2CFE0',
      },
    }),
    singleValue: base => ({
      ...base,
      color: '#192a3e',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: width,
      backgroundColor: isWhiteBg ? '#fff' : theme.colors.bgDashboard,
      zIndex: '9999 !important',
      borderRadius: '0 0 20px 20px',
      fontSize: 14,
      boxShadow: state.isFocused ? null : null,

      border: '1px solid #C2CFE0',
      color: state.selectProps.menuColor,
      padding: 20,
      marginTop: 0,
    }),
  }
}
export const InterestsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
`

export const TooltipContainer = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;

  color: #ffffff;
  padding: 10px 12px;

  background: rgba(65, 71, 82, 0.8);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;
  max-width: 230px;
  width: 100%;
`
export const AudioDescription = styled.div`
  font-size: 14px;
  position: relative;
  top: -20px;
  padding: 0px 30px;
  color: #414752;
  line-height: 18px;
`
export const NotificationTitle = styled.div`
  height: 22px;
  font-size: 16px;
  color: #414752;
  margin-top: 30px;
`

export const VoiceRecordWidgetContainer = styled.div<{ glowing: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 254px;
  max-height: 143px;
  border-radius: 16px;
  box-shadow: ${props => props.glowing && '0px 2px 10px 0px rgba(255, 146, 45, 0.5)'};
  z-index: 1;
  background: inherit;
  gap: 15px;
  padding: 20px 0;
`

export const VoiceRecordWidgetStopButton = styled.button`
  color: rgba(255, 146, 45, 1);
  font-size: 14px;
  padding: 8px 26px;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box padding-box,
    linear-gradient(270.29deg, rgb(255, 146, 45) 18.37%, rgb(255, 184, 0) 99.74%) border-box
      border-box;
  border-radius: 20px;
`

export const VoiceRecordWidgetCancelButton = styled.button`
  color: rgba(144, 164, 189, 1);
  border-radius: 20px;
  font-size: 14px;
  padding: 8px 16px;

  & :hover {
    border: 2px solid rgba(144, 164, 189, 1);
  }
`

export const VoiceRecordWidgetTimer = styled.span`
  font-size: 16px;
  color: rgba(126, 146, 172, 1);
  font-weight: 600;
`

export const VoiceRecordWidgetFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0 35px;
`

export const PaymentPerLessonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const PaymentPerLessonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const PaymentPerLessonLabel = styled.span`
  color: #414752;
  font-size: 18px;
`

export const PaymentPerLessonCurrrencyLabel = styled.span`
  color: #7e92ac;
  font-size: 20px;
`
export const PaymentPerLessonInput = styled.input`
  width: 80px;
  color: #414752;
  font-size: 18px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #d3d8dd;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const PaymentPerLessonAddBtn = styled.button`
  color: #414752;
  font-size: 18px;
  background-color: #e1ecff;
  border-radius: 10px;
  color: #414752;
  padding: 8px 18px;
  width: fit-content;
`
