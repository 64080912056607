import { USER_LOGIN, USER_LOGOUT, AuthActionsTypes } from './types';
import { AUTH_STATUS } from '@utils/constants';

export default (state = AUTH_STATUS.UNKNOWN, action: AuthActionsTypes): AUTH_STATUS => {
  switch (action.type) {
    case USER_LOGIN:
      return AUTH_STATUS.ON;
    case USER_LOGOUT:
      return AUTH_STATUS.OUT;
    default:
      return state;
  }
};
