import styled from 'styled-components'

export const OfferModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 50px 80px 40px;
  font-family: 'Open Sans';
`

export const OfferModalHeader = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin-bottom: 64px;
  text-align: center;
`

export const OfferModalAgreeContainer = styled.div`
  font-size: 14px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Text_dark */

  color: #7e92ac;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    flex: none;
    width: 16px;
    height: 16px;
  }
  .agree-checkbox {
    cursor: pointer;
  }
  span {
    margin-left: 20px;
  }
`

export const OfferModalContent = styled.div`
  margin: 65px 0px 40px 0px;

  font-size: 14px;
  line-height: 20px;
  text-align: left;
  letter-spacing: 0.01em;
  border-radius: 10px;
  border: solid 1px #c2cfe0;

  color: #414752;
  height: 182px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 14px 16px;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.bgLightSide1};

    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }
`

export const OfferModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OfferModalCancel = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin-top: 10px;
  cursor: pointer;
`
