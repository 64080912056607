import { packagesRef } from '@configs/firebase'
import { DEFAULT_PACKAGES } from '@utils/constants'
import { IPackage } from '@models'

export const fetchStudentPackages = async (uid: string): Promise<any> => {
  const snap = await packagesRef.doc(uid).get()
  const packages = snap.exists ? snap.data().data : []
  const packagesEuro = snap.exists && snap.data().data_euro ? snap.data().data_euro : []
  const packagesUsd = snap.exists && snap.data().data_usd ? snap.data().data_usd : []
  const packagesKzt = snap.exists && snap.data().data_kzt ? snap.data().data_kzt : []
  const {
    defaultPackages,
    defaultEurPackages,
    defaultUsdPackages,
    defaultKztPackages,
  } = await fetchDefaultPackages()

  return {
    packages: defaultPackages.map(
      defaultPkg => packages.find(pkg => pkg.type === defaultPkg.type) || defaultPkg
    ),
    euroPackages: defaultEurPackages.map(
      defaultPkg => packagesEuro.find(pkg => pkg.type === defaultPkg.type) || defaultPkg
    ),
    usdPackages: defaultUsdPackages.map(
      defaultPkg => packagesUsd.find(pkg => pkg.type === defaultPkg.type) || defaultPkg
    ),
    kztPackages: defaultKztPackages.map(
      defaultPkg => packagesKzt.find(pkg => pkg.type === defaultPkg.type) || defaultPkg
    ),
  }
}

export const fetchDefaultPackages = async (): Promise<{
  defaultPackages: IPackage[]
  defaultEurPackages: IPackage[]
  defaultUsdPackages: IPackage[]
  defaultKztPackages: IPackage[]
}> => {
  const snap = await packagesRef.doc('default').get()

  return {
    defaultPackages: snap.exists ? snap.data().data : DEFAULT_PACKAGES,
    defaultEurPackages:
      snap.exists && snap.data().data_euro ? snap.data().data_euro : DEFAULT_PACKAGES,
    defaultUsdPackages:
      snap.exists && snap.data().data_usd ? snap.data().data_usd : DEFAULT_PACKAGES,
    defaultKztPackages:
      snap.exists && snap.data().data_kzt ? snap.data().data_kzt : DEFAULT_PACKAGES,
  }
}
