import {
  ICursorState,
  IDocumentState,
  INotebookState,
  VideoPlayerState,
  IUserState,
  ITranslatedWordState,
} from '@models'

export const SET_PLAYER_STATE = 'SET_PLAYER_STATE'
export const SET_CURSOR_STATE = 'SET_CURSOR_STATE'
export const SET_DOCUMENT_STATE = 'SET_DOCUMENT_STATE'
export const SET_DOCUMET_PAGES_STATE = 'SET_PDF_PAGES_STATE'
export const SET_NOTEBOOK_STATE = 'SET_NOTEBOOK_STATE'
export const SET_USER_STATE = 'SET_USER_STATE'
export const SET_SELECTED_VIDEO_STATE = 'SET_SELECTED_VIDEO_STATE'
export const SET_PREV_PLAYER_STATE_STATE = 'SET_PREV_PLAYER_STATE_STATE'
export const SET_SCROLL_TO_FILE_STATE = 'SET_SCROLL_TO_FILE_STATE'
export const SET_TRANSLATED_WORD_STATE = 'SET_TRANSLATED_WORD_STATE'
export const SET_EXERCISES_STATE = 'SET_EXERCISES_STATE'
interface SetPlayerStateAction {
  type: typeof SET_PLAYER_STATE
  payload: VideoPlayerState
}
interface SetExerciseStateAction {
  type: typeof SET_EXERCISES_STATE
  payload: any
}
interface SetSelectedVideoAction {
  type: typeof SET_SELECTED_VIDEO_STATE
  payload: string
}

interface SetPrevPlayerStateAction {
  type: typeof SET_PREV_PLAYER_STATE_STATE
  payload: string
}
interface SetCursorStateAction {
  type: typeof SET_CURSOR_STATE
  payload: ICursorState
}
interface SetDocumentStateAction {
  type: typeof SET_DOCUMENT_STATE
  payload: IDocumentState
}
interface SetUserState {
  type: typeof SET_USER_STATE
  payload: IUserState
}

interface SetNotebookStateAction {
  type: typeof SET_NOTEBOOK_STATE
  payload: INotebookState
}

interface SetScrollToFileAction {
  type: typeof SET_SCROLL_TO_FILE_STATE
  payload: string | null
}
interface SetTranslatedWordStateAction {
  type: typeof SET_TRANSLATED_WORD_STATE
  payload: ITranslatedWordState
}

export type SyncActionsTypes =
  | SetPlayerStateAction
  | SetDocumentStateAction
  | SetPrevPlayerStateAction
  | SetCursorStateAction
  | SetSelectedVideoAction
  | SetUserState
  | SetNotebookStateAction
  | SetScrollToFileAction
  | SetTranslatedWordStateAction
  | SetExerciseStateAction
