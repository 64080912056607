import React, { useState, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { NAVIGATOR } from '@utils/constants'
import { ILessonPageParams, ILessonStudentPageParams } from '@models'

import NavBar from './Nav'
import Header from './Header'

import * as Styled from './dashboard.styles'
import { useTranslation } from 'react-i18next'
import { BrowserView } from 'react-device-detect'

type PropsType = {
  children: any
  student?: boolean
}

const Dashboard = ({ children, student }: PropsType) => {
  const [activeLink, setActiveLink] = useState<string>('')
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [open, setOpen] = useState(() => {
    return localStorage.getItem('drawerOpen') === 'true' ? true : false
  })
  let location = useLocation()
  const teacherLessonPreviewPage = useRouteMatch<ILessonPageParams>(
    `${NAVIGATOR.teacher_classes.path}/course/:courseId/lesson/:lessonId/page/:pageId`
  )
  const teacherLessonPage = useRouteMatch<ILessonStudentPageParams>(
    `${NAVIGATOR.teacher_classes.path}/:studentId/course/:courseId/lesson/:lessonId/page/:pageId`
  )
  const isLessonPage = teacherLessonPreviewPage || teacherLessonPage
  const { t } = useTranslation()
  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }
    window.addEventListener('online', handleStatusChange)
    window.addEventListener('offline', handleStatusChange)

    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOnline])
  const handleClickDrawer = (isClose: boolean) => {
    if (isClose) {
      setOpen(false)
      localStorage.setItem('drawerOpen', String(false))
    } else {
      setOpen(prev => {
        localStorage.setItem('drawerOpen', String(!prev))
        return !prev
      })
    }
  }

  return (
    <>
      <Styled.DashboardContainer open={open} student={student} isTeacherLessonPage={!!isLessonPage}>
        {!student && (
          <>
            <NavBar open={open} onClickDrawer={handleClickDrawer} />
            {!isLessonPage && <Header open={open} />}
          </>
        )}

        <Styled.DashboardMain
          isChatPage={activeLink.includes(NAVIGATOR.teacher_chat.path)}
          isCalendarPage={activeLink === NAVIGATOR.teacher.path}
          isTeacherSearchPage={activeLink.includes(NAVIGATOR.manager_search_teacher.path)}
        >
          {children}
        </Styled.DashboardMain>
      </Styled.DashboardContainer>
      <BrowserView>
        <Styled.ConnectionError isOnline={isOnline}>{t('ConnectionFailed')}</Styled.ConnectionError>
      </BrowserView>
    </>
  )
}

export default Dashboard
