import { TranslateSpanish } from '@utils/constants'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import * as Styled from './spanish-symbol.styles'

function SpanishSymbolforDiv(props) {
  const { event, positionTop } = props
  const [curPosition, setCurPosition] = useState(0)
  const [isLastChar, setIsLastChar] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const debouncedCheckSymbol = debounce( () => {
    setIsVisible(true)
  }, 250)

  useEffect(() => {
    const lastChar = event?.textContent[document.getSelection().anchorOffset - 1]
    Object.keys(TranslateSpanish).includes(lastChar)
      ? debouncedCheckSymbol()
      : setIsVisible(false)
    setIsLastChar(lastChar)
    setCurPosition(document.getSelection().anchorOffset)
  }, [event?.textContent])

  const translateSymbol = char => {
    const discreption =
      event.childNodes.length > 1
        ? event.childNodes[0].textContent
        : event?.textContent
    let temp = discreption.split('')
    temp.splice(curPosition - 1, 1, `${char}`)
    temp = temp.join('')
    let select = document.getSelection()
    let range = document.createRange()
    select.removeAllRanges()
    if (event.childNodes.length > 1) {
      event.childNodes[0].textContent = temp
      range.setStart(event,select.focusOffset+1)
      range.setEnd(event,select.focusOffset+1)
      range.collapse(true)
      select.addRange(range)
    } else {
      event.textContent = temp
      range.setStart(event,select.focusOffset+1)
      range.setEnd(event,select.focusOffset+1)
      range.collapse(true)
      select.addRange(range)
    }
    setIsVisible(false)
  }

  return (
    <>
      {isVisible && TranslateSpanish[isLastChar] ? (
        isLastChar !== 'u' ? (
          <Styled.tootTip
            onClick={() => translateSymbol(TranslateSpanish[isLastChar])}
            style={{
              left: 0,
              top: positionTop ? positionTop : '102%',
            }}
          >
            {TranslateSpanish[isLastChar]}
          </Styled.tootTip>
        ) : (
          <>
            <Styled.tootTip
              onClick={() => translateSymbol(TranslateSpanish[isLastChar][0])}
              style={{
                left: 0,
                top: positionTop ? positionTop : '102%',
              }}
            >
              {TranslateSpanish[isLastChar][0]}
            </Styled.tootTip>
            <Styled.tootTip
              onClick={() => translateSymbol(TranslateSpanish[isLastChar][1])}
              style={{
                left: '45px',
                top: positionTop ? positionTop : '102%',
              }}
            >
              {TranslateSpanish[isLastChar][1]}
            </Styled.tootTip>
          </>
        )
      ) : (
        ''
      )}
    </>
  )
}

export default SpanishSymbolforDiv

