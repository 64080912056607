import React from 'react'
function PlayIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.14061 0.591716C1.38141 0.460249 1.67479 0.470757 1.90557 0.619117L12.4056 7.36912C12.6202 7.50712 12.75 7.7448 12.75 8C12.75 8.2552 12.6202 8.49288 12.4056 8.63089L1.90557 15.3809C1.67479 15.5292 1.38141 15.5398 1.14061 15.4083C0.899804 15.2768 0.75 15.0244 0.75 14.75V1.25C0.75 0.975645 0.899804 0.723184 1.14061 0.591716ZM2.25 2.62375V13.3763L10.6131 8L2.25 2.62375Z"
        fill={props.color}
      />
    </svg>
  )
}

export default PlayIcon
