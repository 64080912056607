import React from 'react'
function StudentIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4429 6.10729C17.8005 5.96424 18.1995 5.96424 18.5571 6.10729L33.5571 12.1073C34.1266 12.3351 34.5 12.8866 34.5 13.5V22.5C34.5 23.3284 33.8284 24 33 24C32.1716 24 31.5 23.3284 31.5 22.5V15.7155L18.5571 20.8927C18.1995 21.0358 17.8005 21.0358 17.4429 20.8927L2.44291 14.8927C1.87343 14.6649 1.5 14.1134 1.5 13.5C1.5 12.8866 1.87343 12.3351 2.44291 12.1073L17.4429 6.10729ZM28.9611 13.5L18 17.8844L7.03887 13.5L18 9.11555L28.9611 13.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 14.4001C9.82843 14.4001 10.5 15.0717 10.5 15.9001V24.0001C10.5 24.3377 10.8049 25.0895 12.3069 25.8405C13.7386 26.5564 15.7859 27.0001 18 27.0001C20.2141 27.0001 22.2614 26.5564 23.6931 25.8405C25.1951 25.0895 25.5 24.3377 25.5 24.0001V15.9001C25.5 15.0717 26.1716 14.4001 27 14.4001C27.8284 14.4001 28.5 15.0717 28.5 15.9001V24.0001C28.5 26.0495 26.9085 27.5869 25.0348 28.5238C23.0909 29.4957 20.5598 30.0001 18 30.0001C15.4402 30.0001 12.9091 29.4957 10.9652 28.5238C9.09151 27.5869 7.5 26.0495 7.5 24.0001V15.9001C7.5 15.0717 8.17157 14.4001 9 14.4001Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default StudentIcon
