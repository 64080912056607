import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '@components/Text'

export const HomeWorkDescriptionStyle = styled.div<{ isStart: boolean }>`
  background: ${props => (props.isStart ? '#FFFFFF' : '#F3F5F9')};
  border-radius: 20px;
  padding: 15px;
  margin-top: 20px;
`

interface Props {
  description: string
  isHomeWork: boolean
  isStart?: boolean
  highlightedWords?: Map<string, boolean>
}

const HomeWorkDescription = ({ description, isHomeWork, isStart, highlightedWords }: Props) => {
  const [highlighted, setHighlighted] = useState<Map<string, boolean>>(new Map())

  useEffect(() => {
    if (highlightedWords) {
      setHighlighted(new Map(highlightedWords))
    }
  }, [highlightedWords])

  const transformLine = (line: string) => {
    let expression = /(https?:\/\/[^\s]+)/gi
    let linkRegexp = new RegExp(expression)

    const transformLinks = () => {
      let newText = line
      let link = newText.match(linkRegexp)
      if (link) {
        newText = newText.replace(
          linkRegexp,
          `<a
            key=${'link' + link}
            href=${link}
            style= "white-space: pre-wrap; text-align: start; word-break: break-word" 
            target="_blank"
            rel="noopener noreferrer"
          >${link}</a>`
        )
      }
      return newText
    }

    let transformedPart = transformLinks()

    highlighted.forEach((value, key) => {
      if (value === true) {
        transformedPart = transformedPart.replace(
          new RegExp('\\b' + key.trim() + '\\b', 'g'),
          match => `<span style="text-decoration: line-through; opacity:0.5">${match}</span>`
        )
      }
    })

    return transformedPart
  }
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }
  const transformHomeWork = (line: string) => {
    let transformedPart = line

    const phrases = [
      'Используй следующие слова в сочинении:',
      'Use the following words in your essay:',
      'Usa las siguientes palabras en su ensayo:',
    ]

    const phraseRegex = new RegExp(phrases.join('|'), 'i')
    const phraseMatch = transformedPart.match(phraseRegex)

    if (phraseMatch) {
      const [beforePhrase, afterPhrase] = transformedPart.split(phraseMatch[0])

      let highlightedPart = afterPhrase

      const sortedHighlighted = new Map(
        [...highlighted.entries()].sort((a, b) => b[0].length - a[0].length)
      )

      sortedHighlighted.forEach((value, key) => {
        if (value === true) {
          const escapedKey = escapeRegExp(key as string)

          const regex = new RegExp(`(^|\\s|\\b)${escapedKey}($|\\s|\\b|[.,?!])`, 'giu')

          highlightedPart = highlightedPart.replace(
            regex,
            match => `<span style="text-decoration: line-through; opacity:0.5">${match}</span>`
          )
        }
      })

      transformedPart = `${beforePhrase}${phraseMatch[0]}${highlightedPart}`
    }

    return transformedPart
  }

  try {
    let notesTextByLines: any = description ? description.split('\n') : []
    if (isHomeWork) {
      return (
        <HomeWorkDescriptionStyle isStart={isStart}>
          <Text display="smallContent">
            {notesTextByLines.map((val, index) => (
              <div
                key={index}
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{ __html: `<div>${transformHomeWork(val)}</div>` }}
              />
            ))}
          </Text>
        </HomeWorkDescriptionStyle>
      )
    } else {
      return (
        <HomeWorkDescriptionStyle isStart={isStart}>
          <Text display="smallContent" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
            {notesTextByLines.map((val, index) => (
              <p
                key={index}
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{ __html: `<div>${transformLine(val)}</div>` }}
              />
            ))}
          </Text>
        </HomeWorkDescriptionStyle>
      )
    }
  } catch (e) {
    return <></>
  }
}

export default HomeWorkDescription
