import React from 'react'
import { useState } from 'react'

import * as Styled from './student-card.styles'
import { IProfile, IStudentProfile } from '@models/IProfile'
import CustomMaterialCheckbox from '@components/Checkbox/CustomMaterialCheckbox'
import { studentsRef } from '@configs/firebase'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import { ROLES } from '@utils/constants'

const Comments = (props: {
  studentData: IStudentProfile
  handleChange: (data: IStudentProfile) => void
  handleSave: (comments: string) => void
  changeComment: boolean
  setChangeComment: (changeComment: boolean) => void
}) => {
  const [comments, setComments] = useState(props.studentData.comments || '')
  const userRole = useSelector((state: RootState) => (state.profile.profile as IProfile)?.role)
  const handleChangeComments = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value)
    if (e.target.value !== props.studentData.comments) {
      props.setChangeComment(true)
    } else {
      props.setChangeComment(false)
    }
  }

  const onSave = () => {
    props.handleChange({ ...props.studentData, comments: comments })
    props.handleSave(comments)
  }

  const handleChangeResearchGroups = async (value: string) => {
    const researchGroups = props.studentData.researchGroups || []
    const newResearchGroups = researchGroups.includes(value)
      ? researchGroups.filter(interest => interest !== value)
      : researchGroups.concat(value)

    props.handleChange({ ...props.studentData, researchGroups: newResearchGroups })
    await studentsRef.doc(props.studentData.id).update({ researchGroups: newResearchGroups })
  }
  const isManagerAndTutor = [ROLES.MANAGER_ASSISTANT, ROLES.MANAGER].includes(userRole)
  return (
    <Styled.CommentsContainter>
      <Styled.StudentCardHeader child={isManagerAndTutor}>Comments</Styled.StudentCardHeader>
      {isManagerAndTutor && (
        <Styled.ResearchGroups>
          {['1', '2', '3'].map(val => {
            return (
              <CustomMaterialCheckbox
                name={val}
                style={{ padding: 0, paddingRight: 30 }}
                onChange={() => handleChangeResearchGroups(val)}
                checked={!!props.studentData.researchGroups?.includes(val)}
              />
            )
          })}
        </Styled.ResearchGroups>
      )}

      <Styled.Div className="comments-content" style={{ height: isManagerAndTutor ? 390 : 450 }}>
        <Styled.CommentsTextArea
          style={{ height: isManagerAndTutor ? 390 : 450 }}
          value={comments}
          onChange={handleChangeComments}
          placeholder="Describe about students and study process"
        />
        {props.changeComment && <Styled.SaveBtn onClick={onSave}>Save</Styled.SaveBtn>}
      </Styled.Div>
    </Styled.CommentsContainter>
  )
}

export default Comments
