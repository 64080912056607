import React from 'react'
import * as Styled from '../personal-info.styles'
import { IconButton, RadioGroup } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { MAIN_GOALS } from '@utils/constants'
import { useTranslation } from 'react-i18next'

export default function({
  onChange,
  goal,
  editType,
  handleClickEdit,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  goal: string
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <Styled.PersonalInfoItem>
        <Styled.PersonalInfoLabel>{t('Goal')}</Styled.PersonalInfoLabel>
        <Styled.PersonalItemCard>
          {goal === 'ToStudy'
            ? 'be able to learn spanish'
            : goal === 'ToTravel'
            ? 'speak the language for travel'
            : goal === 'GetAJob'
            ? 'get a job'
            : goal === 'MoveToSpanishSpeakingCountry'
            ? 'move to a Spanish speaking country'
            : goal === 'PassTheExam'
            ? 'pass the exam'
            : goal === 'EnjoyALearningLanguage'
            ? 'enjoy learning a language'
            : ''}
        </Styled.PersonalItemCard>
        {!editType && <div className="hover-block" />}
        <IconButton className="edit-btn" size="small" onClick={() => handleClickEdit('goal')}>
          {editType === 'goal' ? <CheckMarkIcon /> : <EditIcon />}
        </IconButton>
        {editType === 'goal' && (
          <Styled.EditContainer style={{ padding: '16px 20px' }}>
            <Styled.PersonalInfoLabel
              style={{
                width: 'auto',
                fontWeight: 700,
                fontSize: 16,
                marginBottom: 18,
              }}
            >
              {t('Goal')}
            </Styled.PersonalInfoLabel>
            <RadioGroup
              aria-label="goal"
              name="goal"
              value={goal}
              onChange={e => onChange({ name: 'goal', value: e.target.value })}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {MAIN_GOALS.map(goal => (
                <CustomMaterialRadioButton
                  name={goal}
                  label={
                    goal === 'ToStudy'
                      ? 'be able to learn spanish'
                      : goal === 'ToTravel'
                      ? 'speak the language for travel'
                      : goal === 'GetAJob'
                      ? 'get a job'
                      : goal === 'MoveToSpanishSpeakingCountry'
                      ? 'move to a Spanish speaking country'
                      : goal === 'PassTheExam'
                      ? 'pass the exam'
                      : goal === 'EnjoyALearningLanguage'
                      ? 'enjoy learning a language'
                      : ''
                  }
                  key={goal}
                />
              ))}
            </RadioGroup>
          </Styled.EditContainer>
        )}
      </Styled.PersonalInfoItem>
    </>
  )
}
