import React from 'react'
function PencilIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.328 4.5C15.8191 4.5 16.2898 4.69606 16.6336 5.04257L18.9593 7.36824C19.3055 7.7145 19.5 8.18413 19.5 8.67381C19.5 9.16349 19.3055 9.63312 18.9593 9.97938L10.4681 18.4678C9.94427 19.0721 9.20167 19.4434 8.35095 19.5017H4.5V18.7517L4.50244 15.5905C4.56633 14.7996 4.934 14.0642 5.49487 13.5701L14.0215 5.04347C14.3673 4.69562 14.8375 4.5 15.328 4.5ZM8.29802 18.0036C8.69869 17.9752 9.07165 17.7888 9.37113 17.4462L15.0425 11.7748L12.2267 8.95894L6.52211 14.6622C6.21825 14.9309 6.03027 15.3069 6.00004 15.6509V18.0022L8.29802 18.0036ZM13.2875 7.8984L16.1032 10.7142L17.8986 8.9187C17.9636 8.85374 18.0001 8.76565 18.0001 8.67379C18.0001 8.58193 17.9636 8.49384 17.8986 8.42888L15.5709 6.10112C15.5067 6.03639 15.4192 5.99998 15.328 5.99998C15.2369 5.99998 15.1494 6.03639 15.0852 6.10112L13.2875 7.8984Z"
        fill="#C2CFE0"
      />
    </svg>
  )
}

export default PencilIcon
