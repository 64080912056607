import Button from '@components/TeacherButton'
import React, { useState } from 'react'
import * as Styled from './offer-modal.styles'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@components'

const OfferModal = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
  const { t } = useTranslation()
  const [isAgreeOfferBuy, setIsAgreeOfferBuy] = useState(false)

  return (
    <Styled.OfferModalContainer>
      <Styled.OfferModalHeader>{t('OfferBuy.Title')}</Styled.OfferModalHeader>
      <Styled.OfferModalAgreeContainer>
        <Checkbox
          rect
          type="checkbox"
          tip={t('OfferBuy.AgreeCheckbox')}
          className="agree-checkbox"
          name="agreeCheckbox"
          checked={isAgreeOfferBuy}
          onChange={event => setIsAgreeOfferBuy(event.target.checked)}
        />
      </Styled.OfferModalAgreeContainer>
      <Styled.OfferModalContent>{t('OfferBuy.Rules')}</Styled.OfferModalContent>
      <Styled.OfferModalFooter>
        <Button
          whiteTheme
          bgWhite
          disabled={!isAgreeOfferBuy}
          children={t('Continue')}
          type="button"
          onClick={onConfirm}
        />
        <Styled.OfferModalCancel onClick={onCancel}>{t('Close')}</Styled.OfferModalCancel>
      </Styled.OfferModalFooter>
    </Styled.OfferModalContainer>
  )
}

export default OfferModal
