import React from 'react';
function ExpandLessIcon() {
  return (
    <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10 6L5.5 1L1 6' stroke='#90A4BD' />
    </svg>
  );
}

export default ExpandLessIcon;
