import React from 'react'
import * as Styled from './progressBar.styles'

interface Props {
  value: number
  lineColor?: string
  barColor?: string
}

const ProgressBar = ({ value, lineColor, barColor }: Props) => {
  return (
    <Styled.ProgressBarContainer>
      <Styled.Percent>{value}%</Styled.Percent>
      <Styled.ProgressBar
        variant="determinate"
        value={value}
        lineColor={lineColor}
        barColor={barColor}
      />
    </Styled.ProgressBarContainer>
  )
}

export default ProgressBar
