import * as React from 'react'

const VKIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM19.4656 23.6822C19.2881 23.8694 18.9416 23.9072 18.9416 23.9072H17.7941C17.7941 23.9072 15.2623 24.0565 13.032 21.7778C10.5998 19.2918 8.45217 14.3593 8.45217 14.3593C8.45217 14.3593 8.32821 14.0357 8.4625 13.8799C8.61369 13.7039 9.02594 13.6928 9.02594 13.6928L11.769 13.6753C11.769 13.6753 12.0272 13.7168 12.2122 13.8504C12.3653 13.961 12.4507 14.1666 12.4507 14.1666C12.4507 14.1666 12.894 15.2672 13.4809 16.2627C14.6275 18.2068 15.1609 18.6318 15.5497 18.4234C16.1169 18.1202 15.9469 15.6756 15.9469 15.6756C15.9469 15.6756 15.9572 14.7888 15.6614 14.3934C15.4323 14.0873 15.0003 13.9979 14.8097 13.973C14.6547 13.9527 14.9083 13.6006 15.237 13.443C15.7309 13.2061 16.6024 13.1923 17.6325 13.2024C18.4354 13.2107 18.6665 13.2596 18.9801 13.3342C19.7081 13.5066 19.6853 14.0592 19.6363 15.2519C19.6216 15.6085 19.6046 16.0225 19.6046 16.5006C19.6046 16.608 19.6014 16.7225 19.5981 16.8404C19.5811 17.4514 19.5616 18.151 19.9718 18.4114C20.1821 18.5442 20.6958 18.4308 21.9814 16.2886C22.5908 15.2727 23.0472 14.079 23.0472 14.079C23.0472 14.079 23.1477 13.8661 23.3027 13.7748C23.4614 13.6817 23.6755 13.7103 23.6755 13.7103L26.5622 13.6928C26.5622 13.6928 27.4299 13.5905 27.5698 13.9758C27.7172 14.3786 27.2458 15.3207 26.0664 16.8638C24.9475 18.3279 24.403 18.867 24.453 19.3437C24.4895 19.6921 24.8435 20.0072 25.5226 20.6256C26.9404 21.9177 27.3205 22.5975 27.4118 22.7608C27.4193 22.7744 27.4249 22.7844 27.4289 22.7909C28.0647 23.826 26.7237 23.9072 26.7237 23.9072L24.1591 23.9422C24.1591 23.9422 23.6088 24.0491 22.8838 23.5606C22.5041 23.3049 22.133 22.8872 21.7795 22.4895C21.2397 21.882 20.7411 21.3208 20.3155 21.4534C19.6008 21.6764 19.6224 23.1872 19.6224 23.1872C19.6224 23.1872 19.6281 23.5108 19.4656 23.6822Z"
        fill="#9ABDFC"
      />
    </svg>
  )
}

export default VKIcon
