import React, { useCallback } from 'react'

const Menu = ({ actions }) => {
  const mockActions = actions.map(({ id, name, onClick }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleClickItem = useCallback(e => {
      e.stopPropagation()
      document.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      )
      onClick()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (name && id && onClick) {
      return (
        <div key={id} className="dropdown-item" onClick={handleClickItem}>
          <span className="dropdown-item-name">{name}</span>
        </div>
      )
    }
    return null
  })

  return <div className="dropdown-menu">{mockActions}</div>
}

export default Menu
