import { firebaseTimestamp } from '@configs/firebase'
import { IStudentProfile } from './../../models/IProfile'
import {
  ProfileActionsTypes,
  SET_PROFILE,
  UPDATE_BUSINESS_HOURS,
  SET_PROFILE_FILES,
  SET_PROFILE_TEACHER,
  UPDATE_PROFILE_FILE,
  DELETE_PROFILE_FILE,
  SET_IS_STUDENT,
  SET_STUDENT_PROMO_CODE,
  SET_STUDENT_PAYMENT,
} from './types'
import { IProfileState } from '@models/IProfile'

const initialState: IProfileState = {
  profile: null,
  profileTeacher: null,
  files: [],
  allFilesLoading: false,
  isStudent: null,
}

export function profileReducers(state = initialState, action: ProfileActionsTypes): IProfileState {
  let files = [...state.files]
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload } as IStudentProfile,
      }

    case SET_PROFILE_TEACHER:
      return {
        ...state,
        profileTeacher: action.payload,
      }
    case UPDATE_BUSINESS_HOURS:
      return {
        ...state,
        profile: ({
          ...state.profile,
          businessHours: action.payload,
          bhUpdated: firebaseTimestamp.now(),
        } as unknown) as IStudentProfile,
      }
    case SET_STUDENT_PAYMENT:
      return {
        ...state,
        profile: { ...state.profile, payments: action.payload } as IStudentProfile,
      }
    case SET_PROFILE_FILES:
      return {
        ...state,
        files: action.payload,
      }
    case UPDATE_PROFILE_FILE:
      files.push(action.payload)
      return {
        ...state,
        files,
      }
    case DELETE_PROFILE_FILE:
      files = files.filter(item => item.url !== action.payload)
      return {
        ...state,
        files,
      }

    case SET_IS_STUDENT:
      return {
        ...state,
        isStudent: action.payload,
      }
    case SET_STUDENT_PROMO_CODE:
      return {
        ...state,
        profile: { ...state.profile, promoCode: action.payload } as IStudentProfile,
      }
    default:
      return state
  }
}
