import {
  FAILED_FETCHING,
  FailedFetchingHomeworks,
  SET_HOMEWORKS,
  UPDATE_HOMEWORK,
  SetHomeworksAction,
  UpdateHomeworkAction,
  START_FETCHING,
  StartFetchingHomeworks,
  STUDENT_COUNT_HOMEWORKS,
  StudentCountHomerWorks,
  SUCCESS_FETCHING,
  TEACHER_COUNT_HOMEWORKS,
  SuccessFetchingHomeworks,
  TeacherCountHomerWorks,
  SetMiniCourse,
  SET_MINICOURSE,
  SET_LESSON_FEEDBACK,
  SetLessonFeedback,
  RemoveHomework,
  REMOVE_HOMEWORK,
} from './types'
import { IHomework, ILessonHomework } from '@models/IHomework'

export const setHomeworksAction = (
  homeworks: (ILessonHomework | IHomework)[]
): SetHomeworksAction => ({
  type: SET_HOMEWORKS,
  payload: homeworks,
})

export const updateHomeworkAction = (
  homework: Partial<ILessonHomework | IHomework>
): UpdateHomeworkAction => ({
  type: UPDATE_HOMEWORK,
  payload: homework,
})

export const startFetchingHomeWorks = (): StartFetchingHomeworks => ({
  type: START_FETCHING,
})

export const failedFetchingHomeWorks = (error: string): FailedFetchingHomeworks => ({
  type: FAILED_FETCHING,
  payload: error,
})

export const successFetchingHomeWorks = (): SuccessFetchingHomeworks => ({
  type: SUCCESS_FETCHING,
})

export const setStudentCountHomeWorks = (payload: number): StudentCountHomerWorks => ({
  type: STUDENT_COUNT_HOMEWORKS,
  payload,
})

export const setTeacherCountHomeWorks = (payload: number): TeacherCountHomerWorks => ({
  type: TEACHER_COUNT_HOMEWORKS,
  payload,
})

export const setMiniCourses = (payload): SetMiniCourse => ({
  type: SET_MINICOURSE,
  payload,
})

export const setLessonFeedback = (payload): SetLessonFeedback => ({
  type: SET_LESSON_FEEDBACK,
  payload,
})

export const removeHomework = (homeworkId: string) => ({
  type: REMOVE_HOMEWORK,
  payload: { homeworkId },
})
