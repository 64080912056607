import React, { useState } from 'react'
import Dropdown from 'rc-dropdown'
import ArrowUp from '@assets/images/blocks/Editor/arrow-up.svg'
import ArrowDown from '@assets/images/blocks/Editor/arrow-down.svg'
import Variant1 from '@assets/images/blocks/Exercise/variant1.svg'
import Variant2 from '@assets/images/blocks/Exercise/variant2.svg'
import Variant3 from '@assets/images/blocks/Exercise/variant3.svg'
import Variant4 from '@assets/images/blocks/Exercise/variant4.svg'
import Info from '@assets/images/blocks/Exercise/info.svg'
import { Tooltip, withStyles } from '@material-ui/core'
import Plus from '@assets/images/blocks/plus'
import Cross from '@assets/images/blocks/cross'
import { TextField, makeStyles } from '@material-ui/core'
import Spinner from 'react-spinkit'
import LoaderChecked from '../../assets/icons/checked.svg'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(197, 207, 221, 1)',
    color: '#1B3B77',
    fontSize: 10,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
    fontWeight: 600,
  },
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  input: {
    '&::placeholder': {
      color: '#90A4BD',
    },
  },
}))

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },

    '& .MuiInput-underline:after': {
      borderBottom: '1px solid #C2CFE0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #C2CFE0',
      },
      color: '#414752',
      fontSize: 16,
      fontFamily: 'Open Sans',

      background: 'white',
      '&:hover fieldset': {
        border: '1px solid #C2CFE0',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #C2CFE0',
      },
    },
  },
})(TextField)

const ExerciseBlock = ({
  onStateChange,
  id,
  preloadedState,
  isFactory,
  additions,
  isEditAIExiercise,
}) => {
  const initialState = {
    variant: 0,
    condition: '',
    text: '',
    sentences: [{ id: Date.now(), text: '' }],
  }
  const classes = useStyles()

  const [markState, setMarkState] = useState(preloadedState?.mark || 0)
  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)
  const state =
    preloadedState || (additions?.withMarks ? { ...initialState, mark: 0 } : initialState)

  const changeState = state => {
    const { variant, condition, text, sentences, mark } = {
      ...preloadedState,
      ...state,
    }

    const updatedState = {
      variant,
      condition: condition || '',
      ...(variant === 4 ? { sentences } : { text }),
      mark: mark || null,
    }
    onStateChange(id, {
      ...updatedState,
    })
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }

  const htmlTooltip = (
    <HtmlTooltip
      placement="right"
      title={
        <>
          {state.variant === 1 ? (
            <p>
              Напишите текст. Заключите в квадратные скобки слова, которые нужно будет вставить в
              текст.
              <br />
              <br /> Me [llamo] Alejandro Makovski, soy de Moscú.
            </p>
          ) : null}
          {state.variant === 2 ? (
            <p>
              Запишите в квадратных скобках правильное слово/выражение.
              <br />
              <br /> Me [llamo] Alejandro Makovski, soy de Moscú.
            </p>
          ) : null}
          {state.variant === 3 ? (
            <p>
              Запишите в квадратных скобках сначала начальную форму слова, слэш, затем правильную
              форму слова.
              <br />
              <br /> Me [llamar/llamo] Alejandro Makovski, soy de Moscú.
            </p>
          ) : null}
          {state.variant === 4 ? (
            <p>
              Напишите предложение и разделите слова слэшем. Слова автоматически перемешаются.
              <br />
              <br /> Me/llamo/Alejandro/Makovski/soy/de/Moscú.
            </p>
          ) : null}
        </>
      }
    >
      <img src={Info} alt="" />
    </HtmlTooltip>
  )

  return (
    <div className="aaa" style={{ position: 'relative', width: '100%' }}>
      {!isFactory && (
        <Dropdown
          trigger="click"
          prefixCls="exercise-text-open rc-dropdown"
          overlay={
            <div className="exercise-dropdown">
              <div className="left-side">
                <div>
                  <span className="exercise-type-selector-text">Выберите тип упражнения</span>
                  <div
                    className="exercise-type-image-container"
                    onClick={() => {
                      changeState({
                        variant: 1,
                        text: '',
                      })
                    }}
                  >
                    <img
                      style={{
                        border: state.variant === 1 ? '1px solid #2C82FF' : '',
                        borderRadius: 8,
                      }}
                      src={Variant1}
                      alt=""
                    />
                    <span style={{ color: state.variant === 1 ? '#2C82FF' : '' }}>
                      Перенести слова\выражения в пропуски
                    </span>
                  </div>
                  <div
                    className="exercise-type-image-container"
                    onClick={() => {
                      changeState({
                        variant: 2,
                        text: '',
                      })
                    }}
                  >
                    <img
                      style={{
                        border: state.variant === 2 ? '1px solid #2C82FF' : '',
                        borderRadius: 8,
                      }}
                      src={Variant2}
                      alt=""
                    />
                    <span style={{ color: state.variant === 2 ? '#2C82FF' : '' }}>
                      Вставьте слово в пропуски
                    </span>
                  </div>
                  <div
                    className="exercise-type-image-container"
                    onClick={() => {
                      changeState({
                        variant: 3,
                        text: '',
                      })
                    }}
                  >
                    <img
                      style={{
                        border: state.variant === 3 ? '1px solid #2C82FF' : '',
                        borderRadius: 8,
                      }}
                      src={Variant3}
                      alt=""
                    />
                    <span style={{ color: state.variant === 3 ? '#2C82FF' : '' }}>
                      Поставьте слово в нужную форму
                    </span>
                  </div>
                  <div
                    className="exercise-type-image-container"
                    onClick={() => {
                      changeState({
                        variant: 4,
                        sentences: [{ id: Date.now(), text: '' }],
                      })
                    }}
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <img
                      style={{
                        border: state.variant === 4 ? '1px solid #2C82FF' : '',
                        borderRadius: 8,
                      }}
                      src={Variant4}
                      alt=""
                    />
                    <span style={{ color: state.variant === 4 ? '#2C82FF' : '' }}>
                      Составьте предложение из слов
                    </span>
                  </div>
                </div>
              </div>
              <img src={ArrowUp} alt="" />
            </div>
          }
        >
          <div className="exercise-type-selector">
            <span
              className="exercise-type-selector-text"
              style={{
                color: state.variant ? '#9ABDFC' : '',
                fontWeight: state.variant ? 600 : 400,
              }}
            >
              {(() => {
                if (state.variant === 1) {
                  return 'Перенести слова\\выражения в пропуски'
                } else if (state.variant === 2) {
                  return 'Вставьте слово в пропуски'
                } else if (state.variant === 3) {
                  return 'Поставьте слово в нужную форму'
                } else if (state.variant === 4) {
                  return 'Составьте предложение из слов'
                } else {
                  return 'Выберите тип упражнения'
                }
              })()}
            </span>
            <img src={ArrowDown} alt="" />
          </div>
        </Dropdown>
      )}
      {state.variant ? (
        <>
          {!isEditAIExiercise && (
            <div
              className={
                isFactory
                  ? 'exercise-input-container '
                  : 'exercise-input-container exercise-constructor'
              }
              style={{ marginTop: 30 }}
              key={id}
            >
              <img style={{ display: 'inherit' }} alt="" />
              <CssTextField
                type="text"
                InputProps={{
                  classes: { input: classes.input },
                }}
                style={{
                  width: isFactory ? '100%' : 'calc(100% - 25px)',
                  margin: 0,
                }}
                multiline
                margin="dense"
                variant="outlined"
                placeholder="Task title in Spanish"
                value={state.condition}
                onChange={e => {
                  changeState({
                    condition: e.target.value,
                  })
                }}
              />
            </div>
          )}

          {state.variant === 4 ? (
            state.sentences?.map(({ id, text }, index) => (
              <div
                className={
                  isFactory
                    ? 'exercise-input-container '
                    : 'exercise-input-container exercise-constructor'
                }
                key={id}
              >
                {htmlTooltip}

                <CssTextField
                  type="text"
                  style={{
                    width: isFactory ? '100%' : 'calc(100% - 65px)',
                    margin: 0,
                  }}
                  InputProps={{
                    classes: { input: classes.input },
                  }}
                  placeholder="Task text"
                  multiline
                  margin="dense"
                  variant="outlined"
                  value={text}
                  onChange={e => {
                    changeState({
                      sentences: state.sentences.map(s =>
                        s.id === id ? { id, text: e.target.value } : s
                      ),
                    })
                  }}
                />
                {index !== 0 && (
                  <img
                    onClick={() => {
                      changeState({
                        sentences: state.sentences.filter(s => s.id !== id),
                      })
                    }}
                    style={{ padding: '0 20px', cursor: 'pointer' }}
                    src={Cross}
                    alt="Remove exercise row"
                  />
                )}
              </div>
            ))
          ) : (
            <div
              className="exercise-input-container"
              style={{ position: 'relative', width: '100%' }}
            >
              {htmlTooltip}

              <CssTextField
                type="text"
                style={{
                  width: isFactory ? '100%' : 'calc(100% - 65px)',
                  margin: 0,
                }}
                placeholder="Текст упражнения"
                multiline
                InputProps={{
                  classes: { input: classes.input },
                }}
                margin="dense"
                variant="outlined"
                value={state.text}
                onChange={e => {
                  changeState({
                    text: e.target.value,
                  })
                }}
              />
              <div style={{ width: '20px' }}>
                {isLoading && (
                  <Spinner
                    name="line-spin-fade-loader"
                    color="#FF852D"
                    style={{
                      scale: '0.4',
                      top: '10px',
                      left: '-20px',
                      marginLeft: 'auto',
                    }}
                  />
                )}
                {showCheckmark && (
                  <img
                    style={{
                      position: 'absolute',
                      top: '23px',
                      right: '-18px',
                    }}
                    className="checkmark"
                    src={LoaderChecked}
                    alt=""
                  />
                )}
              </div>
            </div>
          )}
          {state.variant === 4 && (
            <div
              className="centered-icon-voc"
              onClick={() => {
                changeState({
                  sentences: [
                    ...state.sentences,
                    {
                      id: Date.now(),
                      text: '',
                    },
                  ],
                })
              }}
            >
              <img src={Plus} alt="Add words set" />
            </div>
          )}
        </>
      ) : null}
      <div
        className="marks-container"
        style={{ marginLeft: 'auto', paddingTop: 20, paddingRight: 5 }}
      >
        {state.variant && additions?.withMarks ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingRight: 60,
            }}
          >
            <span style={{ color: '#7E92AC', paddingRight: 16, fontWeight: 400, fontSize: 16 }}>
              Балл за каждый верный ответ:
            </span>
            <input
              style={{
                border: '1px solid #C2CFE0',
                padding: 10,
                textAlign: 'center',
                color: 'rgb(65, 71, 82)',
                width: 80,
                fontSize: 16,
              }}
              type="number"
              max="100"
              value={markState}
              onChange={event => {
                const value = parseInt(event.target.value)

                if (value > 100) {
                  setMarkState(100)
                  changeState({ mark: value })
                } else {
                  setMarkState(parseInt(value))
                  changeState({ mark: value })
                }
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ExerciseBlock
