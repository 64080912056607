import { correctionTextColor } from './constants'

const Diff = require('diff')

export const getDiff = (str1: string, str2: any, ref: React.RefObject<HTMLElement>) => {
  if (typeof str1 !== 'string' || typeof str2 !== 'string') {
    return
  }
  const diff = Diff.diffChars(str1, str2),
    fragment = document.createDocumentFragment()
  let span = null
  diff.forEach(part => {
    const color = part.added
      ? correctionTextColor.add
      : part.removed
      ? correctionTextColor.lineThrough
      : 'currentcolor'
    span = document.createElement('span')
    span.style.color = color
    span.style.textDecoration = part.removed && ''
    if (part.removed) {
      let innerSpan = document.createElement('span')
      innerSpan.style.color = correctionTextColor.remove
      innerSpan.appendChild(document.createTextNode(part.value))
      span.appendChild(innerSpan)
      fragment.appendChild(span)
    } else {
      span.appendChild(document.createTextNode(part.value))
      fragment.appendChild(span)
    }
  })
  ref.current.innerHTML = ''
  ref.current.appendChild(fragment)
}

export const getRandomWord = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random())

  return shuffled.slice(0, num)
}
