import React from 'react'
export default function ShoppingIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6H16V4.05882C16 2.93414 15.1178 2 14 2H4C2.88225 2 2 2.93414 2 4.05882V19.9412C2 21.0659 2.88225 22 4 22H14C15.1178 22 16 21.0659 16 19.9412V18H20C21.1046 18 22 17.1046 22 16V8C22 6.89543 21.1046 6 20 6ZM16 11H20V16H16V11ZM14 19.9412V4.05882C14 4.03888 13.9974 4.02504 13.9957 4.01582C13.9935 4.00431 13.9927 4 14 4H4C4.00732 4 4.00651 4.00431 4.00434 4.01582C4.00261 4.02504 4 4.03888 4 4.05882V19.9412C4 19.9611 4.00261 19.975 4.00434 19.9842C4.00651 19.9957 4.00732 20 4 20H14C13.9927 20 13.9935 19.9957 13.9957 19.9842C13.9974 19.975 14 19.9611 14 19.9412ZM5 17V19H7V17H5ZM8 19V17H10V19H8ZM11 17V19H13V17H11ZM5 16V14H7V16H5ZM8 14V16H10V14H8ZM11 16V14H13V16H11ZM13 13V5H5V13H13ZM7 7V11H11V7H7ZM20 8V9H16V8H20Z"
        fill="#90A4BD"
      />
    </svg>
  )
}
