import { LegacyRef, MouseEvent } from 'react'
import { EditorState, RawDraftContentState } from 'draft-js'

export type Nullable<T> = T | null

export type CSSProperties = 'box-sizing' | 'padding-left' | 'padding-right'

export interface CellContent<T> {
  rowContent: T
  bgColor?: string
  align?: AlignType
}

export interface IState<T> {
  colWidths: number[]
  table: {
    [rowIndex: string]: CellContent<T>[]
  }
  hideBorder: boolean
}

export interface StyleButtonProps {
  active: boolean
  disabled: boolean
  color?: string
  style: StyleType
  onToggle(style: StyleType): void
}

export interface BlockStyleControlsProps {
  editorState: CellContent<EditorState>
  onStateChange(state: CellContent<EditorState>): void
}

export interface InlineStyleControlsProps {
  editorState: CellContent<EditorState>
  onToggle(style: StyleType): void
}

export interface ColorPreviewProps {
  editorState: CellContent<EditorState>
}

export interface ColorControlsProps {
  editorState: CellContent<EditorState>
  onToggleColor(color: StyleType): void
}
export interface BackgroundColorControlsProps {
  editorState: CellContent<EditorState>
  onToggleBackground(color: string): (event: any) => void
}

export interface EditorActionsProps {
  editorState: CellContent<EditorState>
  onStateChange(state: CellContent<EditorState>): void
  isHiddenBorder: boolean
  toggleBorder(): void
  toggleAlignAll(alignType: AlignType): void
  tableAlignType: AlignType
}

export enum FontSizeBlockType {
  headerOne = 'header-one',
  headerTwo = 'header-two',
  headerThree = 'header-three',
}

export enum StyleType {
  bold = 'BOLD',
  italic = 'ITALIC',
  darkGrey = 'darkGrey',
  darkBlue = 'darkBlue',
  blue = 'blue',
  lightBlue = 'lightBlue',
  grey = 'grey',
  orange = 'orange',
  red = 'red',
}

export interface StyleShape {
  style: StyleType
  color?: string
}

export type ColorStyleMap = {
  [key in StyleType]?: {
    color: string
  }
}

export type AlignType = 'left' | 'center'

export interface TableBlockProps {
  onStateChange(id: number, state: IState<RawDraftContentState>): void
  id: number
  preloadedState: IState<RawDraftContentState>
  onClickDelete(e: MouseEvent<HTMLImageElement, any>): void
  onClickCopy(e: MouseEvent<HTMLImageElement, any>): void
  dragRef: LegacyRef<HTMLDivElement>
  preview: LegacyRef<HTMLDivElement>
  opacity: number
}
