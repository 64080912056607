export const videoUtils = {
  getAmountOfSecondsAtXPos: (clickEvent, videoDuration) => {
    const parentWidth = clickEvent.target.getBoundingClientRect().width;
    const relativeMousePosition = {
      relativeX:
        clickEvent.clientX - clickEvent.target.getBoundingClientRect().left,
      relativeY:
        clickEvent.clientY - clickEvent.target.getBoundingClientRect().top,
    };

    return videoUtils.pixelsToSeconds(
      relativeMousePosition.relativeX,
      parentWidth,
      videoDuration
    );
  },

  pixelsToSeconds: (xPos, elementWidth, videoDuration) => {
    return (xPos / elementWidth) * videoDuration;
  },
  secondsToPixels: (seconds, elementWidth, videoDuration) => {
    return (seconds / videoDuration) * elementWidth;
  },
};
