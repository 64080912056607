import React from 'react';

import classNames from 'classnames';

import './configInput.sass';

function ConfigInput({ inputClass, inputStyleType, ...props }) {
  const classListConfig = classNames({
    [inputClass]: Boolean(inputClass),
    'underline-input': inputStyleType === 'underline',
    'white-input': inputStyleType === 'white',
  });

  return <input className={classListConfig} {...props} />;
}

ConfigInput.defaultProps = {
  type: 'text',
  inputStyleType: 'underline',
};

export default ConfigInput;
