import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinkit';

import './button.sass';

function Button(props) {
  const { btnClass, loading, whiteTheme, children, customStyles, actionButton, ...rest } = props;

  const classNamesConfig = {
    'button--load': loading,
    [btnClass]: btnClass,
    'button--white': whiteTheme,
  };

  return actionButton ? (
    <button className={classNames('button', classNamesConfig)} style={customStyles} {...rest}>
      {loading ? <Spinner name='ball-spin-fade-loader' className='button__spin' fadeIn='none' /> : children}
    </button>
  ) : (
    <button style={customStyles} {...rest}>
      {loading ? <Spinner name='ball-spin-fade-loader' className='button__spin' fadeIn='none' /> : children}
    </button>
  );
}

Button.defaultProps = {
  loading: false,
  whiteTheme: false,
  children: 'default',
  customStyles: {},
  actionButton: true,
};

export default Button;
