import React, { FC, ChangeEvent, useCallback, useMemo } from 'react';
import Dropdown from 'rc-dropdown';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import classNames from 'classnames';

import {
  BorderedTableIcon,
  NotBorderedTableIcon,
  AlignLeftIcon,
  AlignCenterIcon,
  BoldTextIcon,
  ItalicTextIcon,
  TextColorIcon,
  BackgroundColorIcon,
  FontSizePlusIcon,
  FontSizeMinusIcon,
} from '@assets/icons';

import { INLINE_STYLES, backgroundColors, COLORS, colorStyleMap } from './constants';
import * as Styled from './TableBlock.styles';
import {
  StyleButtonProps,
  BlockStyleControlsProps,
  InlineStyleControlsProps,
  ColorPreviewProps,
  ColorControlsProps,
  BackgroundColorControlsProps,
  EditorActionsProps,
  FontSizeBlockType,
  StyleType,
  AlignType,
} from './types';

const BlockStyleControls: FC<BlockStyleControlsProps> = ({ editorState, onStateChange }) => {
  const blockType = useMemo<string>(() => {
    if (!editorState) return null;
    return editorState.rowContent
      .getCurrentContent()
      .getBlockForKey(editorState.rowContent.getSelection().getStartKey())
      .getType();
  }, [editorState]);

  const handleFontPlus = useCallback(
    (e: ChangeEvent<SVGElement>) => {
      e.preventDefault();
      if (!editorState || blockType === FontSizeBlockType.headerOne) return;
      let newBlockType: FontSizeBlockType = FontSizeBlockType.headerTwo;
      if (blockType === FontSizeBlockType.headerTwo) newBlockType = FontSizeBlockType.headerOne;
      if (blockType === FontSizeBlockType.headerThree) newBlockType = FontSizeBlockType.headerTwo;
      onStateChange({
        rowContent: RichUtils.toggleBlockType(editorState.rowContent, newBlockType),
        bgColor: editorState.bgColor,
        align: editorState.align,
      });
    },
    [blockType, editorState],
  );

  const handleFontMinus = useCallback(
    (e: ChangeEvent<SVGElement>) => {
      e.preventDefault();
      if (!editorState || !blockType || blockType === FontSizeBlockType.headerThree) return;
      let newBlockType: FontSizeBlockType;
      if (blockType === FontSizeBlockType.headerOne) newBlockType = FontSizeBlockType.headerTwo;
      if (blockType === FontSizeBlockType.headerTwo) newBlockType = FontSizeBlockType.headerThree;
      onStateChange({
        rowContent: RichUtils.toggleBlockType(editorState.rowContent, newBlockType),
        bgColor: editorState.bgColor,
        align: editorState.align,
      });
    },
    [blockType, editorState],
  );

  return (
    <>
      <FontSizePlusIcon
        className={classNames({ disabled: !editorState })}
        onMouseDown={handleFontPlus}
      />
      <FontSizeMinusIcon
        className={classNames({ disabled: !editorState })}
        onMouseDown={handleFontMinus}
      />
    </>
  );
};

const StyleButton: FC<StyleButtonProps> = ({ active, color, style, onToggle, disabled }) => {
  const handleMouseDown = useCallback(
    e => {
      e.preventDefault();
      onToggle(style);
    },
    [onToggle, style],
  );

  return (
    <>
      {style === StyleType.bold && (
        <BoldTextIcon className={classNames({ active, disabled })} onMouseDown={handleMouseDown} />
      )}
      {style === StyleType.italic && (
        <ItalicTextIcon
          className={classNames({ active, disabled })}
          onMouseDown={handleMouseDown}
        />
      )}
      {color && <Styled.ColorListItem providedColor={color} onMouseDown={handleMouseDown} />}
    </>
  );
};

const InlineStyleControls: FC<InlineStyleControlsProps> = ({ editorState, onToggle }) => (
  <>
    {INLINE_STYLES.map(({ style }) => (
      <StyleButton
        key={style}
        active={editorState ? editorState.rowContent.getCurrentInlineStyle().has(style) : false}
        onToggle={onToggle}
        style={style}
        disabled={!editorState}
      />
    ))}
  </>
);

const ColorPreview: FC<ColorPreviewProps> = ({ editorState }) => {
  const currentStyle = editorState
    ? editorState.rowContent.getCurrentInlineStyle()
    : new Set<StyleType>();
  const bgColor = useMemo<string>(
    () => COLORS.find(({ style, color }) => currentStyle.has(style))?.color || '#404753',
    [currentStyle],
  );

  return (
    <>
      <TextColorIcon
        onMouseDown={e => e.preventDefault()}
        className={classNames({
          active: editorState && !currentStyle.has(StyleType.darkGrey),
          disabled: !editorState,
        })}
      />
      {editorState && (
        <Styled.StylePreviewWrapper>
          <Styled.StylePreview providedColor={bgColor} />
        </Styled.StylePreviewWrapper>
      )}
    </>
  );
};

const ColorControls: FC<ColorControlsProps> = ({ editorState, onToggleColor }) => (
  <Dropdown
    trigger="hover"
    overlay={
      <Styled.DropdownOverlay hidden={!editorState}>
        {COLORS.map(({ color, style }) => (
          <StyleButton
            key={style}
            active={editorState ? editorState.rowContent.getCurrentInlineStyle().has(style) : false}
            onToggle={onToggleColor}
            style={style}
            color={color}
            disabled={!editorState}
          />
        ))}
      </Styled.DropdownOverlay>
    }
  >
    <Styled.DropdownContainer>
      <ColorPreview editorState={editorState} />
    </Styled.DropdownContainer>
  </Dropdown>
);

const BackgroundColorControls: FC<BackgroundColorControlsProps> = ({
  editorState,
  onToggleBackground,
}) => (
  <Dropdown
    trigger="hover"
    overlay={
      <Styled.DropdownOverlay hidden={!editorState}>
        {backgroundColors.map(c => (
          <Styled.StyledBackgroundListItem
            key={c}
            onMouseDown={onToggleBackground(c)}
            providedColor={c}
          />
        ))}
      </Styled.DropdownOverlay>
    }
  >
    <Styled.DropdownContainer>
      <BackgroundColorIcon
        onMouseDown={e => e.preventDefault()}
        className={classNames({
          active: editorState?.bgColor && editorState.bgColor !== '#fff',
          disabled: !editorState,
        })}
      />
      {editorState && (
        <Styled.StylePreviewWrapper>
          <Styled.StylePreview providedColor={editorState.bgColor} />
        </Styled.StylePreviewWrapper>
      )}
    </Styled.DropdownContainer>
  </Dropdown>
);

const EditorActions: FC<EditorActionsProps> = ({
  editorState,
  onStateChange,
  isHiddenBorder,
  toggleBorder,
  toggleAlignAll,
  tableAlignType,
}) => {
  const onToggleColor = useCallback(
    (toggledColor: string) => {
      if (!editorState) return;
      const selection = editorState.rowContent.getSelection();
      const nextContentState = Object.keys(colorStyleMap).reduce(
        (contentState, color) => Modifier.removeInlineStyle(contentState, selection, color),
        editorState.rowContent.getCurrentContent(),
      );
      let nextEditorState = EditorState.push(
        editorState.rowContent,
        nextContentState,
        'change-inline-style',
      );
      const currentStyle = editorState.rowContent.getCurrentInlineStyle();
      if (selection.isCollapsed()) {
        nextEditorState = currentStyle.reduce(
          (state, color) => RichUtils.toggleInlineStyle(state, color),
          nextEditorState,
        );
      }
      if (!currentStyle.has(toggledColor)) {
        nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
      }
      onStateChange({
        rowContent: nextEditorState,
        bgColor: editorState.bgColor,
        align: editorState.align,
      });
    },
    [editorState],
  );

  const onToggleBackground = useCallback(
    (bgColor: string) => e => {
      e.preventDefault();
      if (!editorState) return;
      onStateChange({
        rowContent: editorState.rowContent,
        align: editorState.align,
        bgColor,
      });
    },
    [editorState, onStateChange],
  );

  const onToggleInlineStyle = useCallback(
    (inlineStyle: StyleType) => {
      if (!editorState) return;
      onStateChange({
        rowContent: RichUtils.toggleInlineStyle(editorState.rowContent, inlineStyle),
        bgColor: editorState.bgColor,
        align: editorState.align,
      });
    },
    [onStateChange, editorState],
  );

  const handleAlign = useCallback(
    (align: AlignType) => e => {
      e.preventDefault();
      if (!editorState) return toggleAlignAll(align);
      onStateChange({
        rowContent: editorState.rowContent,
        bgColor: editorState.bgColor,
        align,
      });
    },
    [onStateChange, editorState, toggleAlignAll],
  );

  return (
    <Styled.EditorActionsWrapper>
      {isHiddenBorder ? (
        <NotBorderedTableIcon onClick={toggleBorder} />
      ) : (
        <BorderedTableIcon onClick={toggleBorder} />
      )}
      <AlignLeftIcon
        className={classNames({
          active: editorState
            ? editorState.align === 'left' || !editorState.align
            : tableAlignType === 'left',
        })}
        onMouseDown={handleAlign('left')}
      />
      <AlignCenterIcon
        className={classNames({
          active: editorState ? editorState?.align === 'center' : tableAlignType === 'center',
        })}
        onMouseDown={handleAlign('center')}
      />
      <InlineStyleControls editorState={editorState} onToggle={onToggleInlineStyle} />
      <ColorControls editorState={editorState} onToggleColor={onToggleColor} />
      <BackgroundColorControls editorState={editorState} onToggleBackground={onToggleBackground} />
      <BlockStyleControls editorState={editorState} onStateChange={onStateChange} />
    </Styled.EditorActionsWrapper>
  );
};

export { EditorActions };
