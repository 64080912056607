import styled from 'styled-components'

export const CalendarContainer = styled.div<{ isManager: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 0;
  flex-grow: 1;
  margin-top: 25px;
  position: relative;

  .fc-day-today h5 {
    color: #ff852d;
  }

  .fc-day-today p {
    color: #ff852d;
  }

  .fc-timegrid-axis-chunk > table,
  .fc .fc-timegrid-slots {
    position: relative;
    z-index: 3;
  }
  .fc-toolbar {
    display: none;
  }
  .fc .fc-non-business {
    background-color: #f3f5f9;
    z-index: 0;
  }
  .fc-timegrid-slot .fc-timegrid-slot-label {
    border: none;
    position: sticky;
    left: 0;
  }
  .fc-scrollgrid {
    border-left: none;
  }
  .fc-day .fc-timegrid-col-frame {
    background-color: #ffe3c9;
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }
  .fc .fc-timegrid-axis {
    border-width: 0px;
  }

  .fc .fc-timegrid-slot.fc-timegrid-slot-label {
    border-width: 0px;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0px;
    z-index: 999;
  }

  .fc-v-event {
    background-color: transparent;
    border: transparent;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0px;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    box-shadow: initial;
  }
  .calendar-event {
    z-index: 999;
    .event-container {
      font-family: Open Sans;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding-left: 10px;
      z-index: 999;
      @media (max-width: 767px) {
        padding-left: 2px;
      }
    }
    .event-time-your-self {
      font-weight: 400;
      font-size: 16px;
      color: #414752;
      align-self: flex-start;
    }

    .fc-event-main {
      width: 100%;
      height: 100%;
    }

    .event-title {
      font-style: bold;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #000000;
      text-align: left;
      white-space: normal;
      @media (max-width: 767px) {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .event-description {
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #000000;
      text-align: left;
      @media (max-width: 767px) {
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .fc-col-header-cell-cushion {
    height: ${props => (props.isManager ? '54px' : '105px')};
    background-color: transparent;
    font-weight: normal;
    font-style: normal;
    display: flex;
    color: #414752;
  }
  .fc-col-header-cell-cushion .event-column-header {
    display: flex;
    flex-direction: ${props => (props.isManager ? 'row' : 'column')};
    align-items: flex-start;
    margin-left: 24px;
    margin-top: ${props => (props.isManager ? '10px' : '5px')};
  }
  .fc-col-header-cell-cushion h5 {
    font-weight: ${props => (props.isManager ? 'normal' : 'bold')};
    font-size: ${props => (props.isManager ? '16px' : '36px')};
    letter-spacing: 0.01em;
    text-align: left;
    margin-right: 5px;
  }

  .fc-col-header-cell-cushion p {
    font-size: ${props => (props.isManager ? '16px' : '18px')};
    letter-spacing: 0.01em;
  }

  .time-item {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: flex-start;
    .current-time {
      font-size: 18px;
      font-weight: 500;
      color: #7e92ac;
    }
    .msk-time {
      font-size: 12px;
      font-weight: 400;
      color: #7e92ac;
    }
  }
  .last-update-time-user {
    font-size: 9px;
    color: #979797;
    float: right;
    margin-right: 18px;
    margin-bottom: 8px;
  }

  @media (max-width: 767px) {
    .fc-col-header-cell-cushion {
      height: 54px;
      display: block;
    }

    .fc-col-header-cell-cushion .event-column-header {
      display: flex;
      align-items: flex-start;
      margin-left: 0;
      margin-top: ${props => (props.isManager ? '5px' : '5px')};
    }

    .fc-col-header-cell-cushion h5 {
      font-size: ${props => (props.isManager ? '14px' : '16px')};
      margin: 0 auto;
    }

    .fc-col-header-cell-cushion p {
      font-size: 12px;
      margin: 0 auto;
      max-width: 100%;
      overflow-x: hidden;
    }

    .current-time {
      font-size: 14px !important;
    }
  }
`
