import React from 'react'
import * as Styled from './teacher-card.styles'
import { CancelButtonIcon } from '@components/Icons'
import { IconButton } from '@material-ui/core'
import { IProfile } from '@models'
import { ProfileAvatar } from '@pages/student/Profile/student-profile.styles'
import DefaultAvatar from '@images/default_avatar.png'
import { toast } from 'react-toastify'
import CardEmailIcon from '../Icons/CardEmailIcon'
import CardPhoneIcon from '../Icons/CardPhoneIcon'
import CardSkypeIcon from '../Icons/CardSkypeIcon'
import { CopyIcon } from '@components/Icons'
import WhatsAppIcon from '../Icons/WhatsApp'
import TelegramIcon from '../Icons/Telegram.'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import VectorAvatarIcon from '../Icons/VectorAvatar'
import LeftVectorIcon from '../Icons/LeftVector'
import VectorRightIcon from '../Icons/VectorRight'
import AudioPlayerWaveForm from './AudioPlayerWaveForm'
import { useTranslation } from 'react-i18next'
interface Props {
  handleClose: () => void
  teacherData: IProfile
}

const TeacherCard = ({ handleClose, teacherData }: Props) => {
  const { t } = useTranslation()
  const handleCopied = () => {
    toast(t('Toast.DataCopied'), {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-card-message',
    })
  }
  if (!teacherData) return null

  return (
    <Styled.TeacherCard>
      <button className="close-button" onClick={handleClose}>
        <CancelButtonIcon />
      </button>
      <VectorRightIcon className="right-icon" />
      <LeftVectorIcon className="left-icon" />
      <Styled.TeacherCardContainer>
        <Styled.LeftContainer>
          <ProfileAvatar
            src={teacherData.avatar || DefaultAvatar}
            alt=""
            style={{ width: 160, height: 160 }}
          />
          <VectorAvatarIcon className="avatar-icon" />
          <Styled.Name>{teacherData.name + ' ' + teacherData.lastName}</Styled.Name>
          <Styled.Address>{teacherData.cityAddress}</Styled.Address>

            {teacherData.phone && (
          <Styled.ContactBlock>
            <CardPhoneIcon /> <span className="label"> {teacherData.phone}</span>
              <CopyToClipboard text={teacherData.phone}>
                <IconButton className="copy" size="small" onClick={handleCopied}>
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
          </Styled.ContactBlock>
            )}

          {teacherData.skype && (
            <Styled.ContactBlock>
              <CardSkypeIcon /> <span className="label">{teacherData.skype}</span>
              <CopyToClipboard text={teacherData.skype}>
                <IconButton className="copy" size="small" onClick={handleCopied}>
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
            </Styled.ContactBlock>
          )}
          {teacherData.email && (  <Styled.ContactBlock>
            <CardEmailIcon /> <span className="label">{teacherData.email}</span>
          
              <CopyToClipboard text={teacherData.email}>
                <IconButton className="copy" size="small" onClick={handleCopied}>
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
          </Styled.ContactBlock>
            )}
        </Styled.LeftContainer>
        <Styled.RightContainer>
          <Styled.AboutMe>
            <div>{teacherData.aboutMe}</div>
          </Styled.AboutMe>
          {teacherData.audio && <AudioPlayerWaveForm audio={teacherData.audio} />}
        </Styled.RightContainer>
        <Styled.FooterContainer>
          <span>{t('TeacherCardText')}</span>
          <div>
            <a
              href={'https://wa.me/' + teacherData.phone}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon />
            </a>

            {teacherData.telegram && (
              <a
                href={'https://t.me/' + teacherData.telegram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon />{' '}
              </a>
            )}
          </div>
        </Styled.FooterContainer>
      </Styled.TeacherCardContainer>
    </Styled.TeacherCard>
  )
}

export default TeacherCard
