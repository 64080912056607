import styled from 'styled-components'
import { withStyles, Tooltip as MuiToolTip } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;

  /* Text_dark */
  color: #414752;
`

export const Desc = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin-top: 6px;
`

export const ProgressesContinaer = styled.div`
  position: relative;
  display: flex;
  align-items: end;
`

export const SlideContinaer = styled.div`
  display: flex;
  align-items: end;
  position: relative;
  height: 160px;
  overflow: hidden;
  flex-grow: 1;
`

export const ProgressesItemsContinaer = styled.div`
  position: relative;
  background: #e1ecff;
  border-radius: 20px;
  height: 60px;
  width: 100%;
`

export const Progresses = styled.div`
  margin: 4px 20px 0px 20px;
  display: -webkit-inline-box;
  transition: 0.5s;
  position: absolute;
`

export const ColorsItems = styled.div`
  display: -webkit-inline-box;

  background: linear-gradient(90deg, #9ad9fc 0%, #4284ff 75%);
  height: 12px;
  border-radius: 10px;
`

export const ProgressItem = styled.div<{ isCompleted: boolean }>`
  border-right: ${props =>
    props.isCompleted ? '1px solid  #1556CF' : '1px solid  #c4c4c4'};
  height: 12px;
  width: 30px;
  position: relative;
  background: ${props => (props.isCompleted ? 'none' : 'white')};
`

export const LessonNumber = styled.div`
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  width: 30px;
  bottom: -26px;
  text-align: center;
  /* Grey middle_ default text */

  color: #7e92ac;
`

export const LessonType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PassedLessonsLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7e92ac;
  margin-top: 10px;
  align-self: flex-end;
`

export const LessonLine = styled.div<{ isCompleted: boolean }>`
  width: 2px;
  background: ${props => (props.isCompleted ? '#9ABDFC' : '#c2cfe0')};
`

export const ToolTipTitle = styled.div`
  width: 158px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  background: rgba(65, 71, 82, 0.8);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;
`

export const FinishTooltipTitle = styled.div`
  max-width: 118px;
  padding: 8px 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  background: #c2cfe0;
  border-radius: 4px;
  color: #ffffff;
`

export const Ellipse = styled.div<{ isCompleted: boolean }>`
  width: 34px;
  height: 34px;
  background: ${props =>
    props.isCompleted ? '#9ABDFC' : 'rgba(194, 207, 224, 0.5)'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`

export const Tooltip = withStyles({
  tooltip: {
    background: 'transparent',
  },
})(MuiToolTip)
