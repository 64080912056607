import React from 'react'

export default function StarIcon(props) {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.7" cx="25" cy="25" r="25" fill="#414752" />
      <g clipPath="url(#clip0_5136_49474)">
        <path
          opacity="1"
          d="M25 12L27.9187 20.9828H37.3637L29.7225 26.5344L32.6412 35.5172L25 29.9656L17.3588 35.5172L20.2775 26.5344L12.6363 20.9828H22.0813L25 12Z"
          fill="#ffc800"
        />
      </g>
      <defs>
        <clipPath id="clip0_5136_49474">
          <rect width="26" height="26" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}
