import React, { useMemo } from 'react'
import CustomTextfield from '../../pages/teacher/TeacherProfile/CustomTextfield'
import { MenuItem } from '@material-ui/core'
import { TZ_MSK } from '../../utils/constants'
import { useTranslation } from 'react-i18next'
import { calendarUtil } from '@utils/calendarUtil'

type TimeZoneProps = {
  value: string
  setFn: (value: string) => void
}

const TimeZoneSelect: React.FC<TimeZoneProps> = ({ value, setFn }) => {
  const { t } = useTranslation()

  const timeZones = useMemo(() => {
    return calendarUtil.getAvailableTimezones()
  }, [])
  return (
    <CustomTextfield
      id="input-timezone"
      label={t('TimeZone')}
      select
      value={value || TZ_MSK}
      name="timeZone"
      onChange={event => setFn(event.target.value)}
    >
      {timeZones.map(option => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      })}
    </CustomTextfield>
  )
}

export default TimeZoneSelect
