import { ChatActionsTypes, SET_UNREAD_MESSAGES } from './types'
export interface IChatState {
  unreadMessages: string[]
}
const initialState: IChatState = {
  unreadMessages: [],
}

export const chatReducers = (
  state = initialState,
  action: ChatActionsTypes
): IChatState => {
  switch (action.type) {
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload,
      }

    default:
      return state
  }
}
