import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Styled from './miniCourse.styles'
import { ListItem, ListItemText } from '@material-ui/core'
import { LEVELS, INTERESTS, GOALS } from '@utils/constants'
import { getTeachers } from '@servise'
import { editCourse, updateLessons } from '@actions'
import { Button } from '@components'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CurrencyInput from './CurrencyInput'
const MiniCourseSettingsTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { courseId } = useParams()

  const states = useSelector(state => ({
    teachers: state.personal.teachers,
    lessonsList: state.course.lessonsList,
    currentCourse: state.course.currentCourse,
  }))

  useEffect(() => {
    dispatch(getTeachers())
  }, [dispatch])

  const [desc, setDesc] = useState(states.currentCourse.description || '')

  const [benefits, setBenefits] = useState(states.currentCourse?.benefits || '')
  const [benefitsAvailable, setBenefitsAvailable] = useState(
    !!states.currentCourse?.benefitsAvailable
  )

  const [sources, setSources] = useState(states.currentCourse?.sources || '')
  const [sourcesAvailable, setSourcesAvailable] = useState(!!states.currentCourse?.sourcesAvailable)

  const [level, setLevel] = useState(states.currentCourse?.level || '')

  const [duration, setDuration] = useState(
    states.currentCourse?.duration ? states.currentCourse?.duration + '(ч)' : ''
  )
  const [durationInputType, setDurationInputType] = useState('text')
  const [author, setAuthor] = useState(states.currentCourse?.author || '')

  const [price, setPrice] = useState(states.currentCourse?.price || '')

  const [interests, setInterests] = useState(states.currentCourse?.interests || [])

  const [goals, setGoals] = useState(states.currentCourse?.goals || [])

  const [lessons, setLessons] = useState(states.lessonsList || [])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setDesc(states.currentCourse.description || '')

    setBenefits(states.currentCourse?.benefits || '')
    setBenefitsAvailable(!!states.currentCourse?.benefitsAvailable)

    setSources(states.currentCourse?.sources || '')
    setSourcesAvailable(!!states.currentCourse?.sourcesAvailable)

    setLevel(states.currentCourse?.level || '')

    setDuration(states.currentCourse?.duration ? states.currentCourse?.duration + '(ч)' : '')

    setAuthor(states.currentCourse?.author || '')

    setPrice(states.currentCourse?.price || '')

    setInterests(states.currentCourse?.interests || [])

    setGoals(states.currentCourse?.goals || [])

    setLessons(states.lessonsList || [])
  }, [states.currentCourse, states.lessonsList])

  const onEnter = (e, setStateCallback) => {
    if (e.key !== 'Enter') return
    e.preventDefault()
    setStateCallback(
      state => `${state}
• `
    )
  }

  const onStartWriting = setStateCallback =>
    setStateCallback(state => (state.length === 0 ? '• ' : state))

  const handleToggleLesson = (e, id) => {
    setLessons(lessons =>
      lessons.map(lesson => {
        if (lesson.id === id)
          return {
            ...lesson,
            isFree: e.target.checked,
          }
        return lesson
      })
    )
  }

  const onToggleInterest = (e, name) => {
    if (e.target.checked) return setInterests(interests => [...interests, name])
    setInterests(interests => interests.filter(interest => interest !== name))
  }

  const onToggleGoal = (e, title) => {
    if (e.target.checked) return setGoals(goals => [...goals, title])
    setGoals(goals => goals.filter(goal => goal !== title))
  }

  const onSave = async () => {
    setIsLoading(true)

    await dispatch(
      editCourse({
        courseId,
        courseData: {
          description: desc,
          benefits,
          benefitsAvailable,
          sources,
          sourcesAvailable,
          level: level || LEVELS.A1,
          duration: Number(duration.split('(')[0]),
          author,
          price: Number(price),
          interests,
          goals,
        },
      })
    )

    await dispatch(updateLessons({ courseId, lessons }))
    setTimeout(() => {
      toast('Настройки успешно сохранились:)', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-create-course-success',
      })
      setIsLoading(false)
    }, 1000)
  }

  return (
    <Styled.PageContainer>
      <Styled.DescriptionContainer>
        <Styled.Description>
          <Styled.Title>{t('MiniCourseSettings.DescriptionTitle')}</Styled.Title>
          <Styled.Text>{t('MiniCourseSettings.DescriptionText')}</Styled.Text>
          <Styled.Input
            type="text"
            placeholder={t('MiniCourseSettings.DescriptionPlaceholder')}
            value={desc}
            onChange={e => setDesc(e.target.value)}
          />
        </Styled.Description>
      </Styled.DescriptionContainer>
      <Styled.BenefitsContainer>
        <Styled.CustomTooltip title={t('MiniCourseSettings.TooltipTitle')} placement="right-start">
          <div>
            <Styled.Checkbox
              checked={benefitsAvailable}
              onChange={e => setBenefitsAvailable(e.target.checked)}
            />
          </div>
        </Styled.CustomTooltip>
        <Styled.Benefits>
          <Styled.Title>{t('MiniCourseSettings.BenefitsTitle')}</Styled.Title>
          <Styled.Text>
            {t('MiniCourseSettings.BenefitsText')} <br />
            <span>{t('MiniCourseSettings.BenefitsTextBottom')}</span>
          </Styled.Text>
          <Styled.CustomTextareaAutosize
            aria-label="empty textarea"
            placeholder={`• ${t('MiniCourseSettings.BenefitsPlaceholder')}`}
            value={benefits}
            onChange={e => setBenefits(e.target.value)}
            onKeyDown={e => onEnter(e, setBenefits)}
            onClick={() => onStartWriting(setBenefits)}
          />
        </Styled.Benefits>
      </Styled.BenefitsContainer>
      <Styled.SourcesContainer>
        <Styled.CustomTooltip title={t('MiniCourseSettings.TooltipTitle')} placement="right-start">
          <div>
            <Styled.Checkbox
              checked={sourcesAvailable}
              onChange={e => setSourcesAvailable(e.target.checked)}
            />
          </div>
        </Styled.CustomTooltip>
        <Styled.Sources>
          <Styled.Title>{t('MiniCourseSettings.SourcesTitle')}</Styled.Title>
          <Styled.Text>{t('MiniCourseSettings.SourcesText')}</Styled.Text>
          <Styled.CustomTextareaAutosize
            aria-label="empty textarea"
            placeholder={`• ${t('MiniCourseSettings.SourcesPlaceholder')}`}
            value={sources}
            onChange={e => setSources(e.target.value)}
            onKeyDown={e => onEnter(e, setSources)}
            onClick={() => onStartWriting(setSources)}
          />
        </Styled.Sources>
      </Styled.SourcesContainer>
      <Styled.InfoContainer>
        <Styled.Title>{t('MiniCourseSettings.InfoTitle')}</Styled.Title>
        <Styled.CustomSelect
          displayEmpty
          value={level}
          onChange={e => setLevel(e.target.value)}
          renderValue={selected =>
            selected.length === 0 ? t('MiniCourseSettings.InfoLevelPlaceholder') : selected
          }
        >
          {Object.keys(LEVELS).map(level => (
            <Styled.CustomMenuItem key={level} value={level}>
              {level}
            </Styled.CustomMenuItem>
          ))}
        </Styled.CustomSelect>
        <Styled.Input
          type={durationInputType}
          min={0}
          onBlur={e => {
            setDurationInputType('text')
            setDuration(prev => (prev ? prev + '(ч)' : ''))
          }}
          onFocus={e => {
            setDurationInputType('number')
            setDuration(prev => {
              return String(prev).split('(')[0]
            })
          }}
          placeholder={t('MiniCourseSettings.InfoDurationPlaceholder') + ' (ч)'}
          value={duration}
          onChange={e => {
            setDuration(e.target.value.replaceAll('-', ''))
          }}
        />
        <Styled.CustomSelect
          displayEmpty
          value={author}
          onChange={e => setAuthor(e.target.value)}
          renderValue={selected => {
            if (selected.length === 0) return t('MiniCourseSettings.InfoAuthorPlaceholder')
            const teacher = states.teachers.find(teacher => teacher.id === selected)
            return `${teacher?.name} `
          }}
        >
          {states.teachers.map(teacher => (
            <Styled.CustomMenuItem key={teacher.id} value={teacher.id}>
              {`${teacher?.name} `}
            </Styled.CustomMenuItem>
          ))}
        </Styled.CustomSelect>

        <Styled.Input
          placeholder={t('MiniCourseSettings.InfoPricePlaceholder')}
          value={price}
          onChange={event => {
            setPrice(event.target.value)
          }}
          isError={!price}
          style={{
            marginBottom: 0,
          }}
          InputProps={{ inputComponent: CurrencyInput }}
        />
      </Styled.InfoContainer>
      <Styled.TagsContainer>
        <Styled.Title>{t('MiniCourseSettings.TagsTitle')}</Styled.Title>
        <Styled.InterestsGoalsContainer>
          <Styled.InterestsContainer>
            <p>{t('MiniCourseSettings.TagsSecondTitle1')}</p>
            <Styled.Tags>
              {INTERESTS.map(interest => (
                <Styled.Checkbox
                  key={interest.id}
                  checked={interests.includes(interest?.name)}
                  onChange={e => onToggleInterest(e, interest?.name)}
                  name={t(interest?.name)}
                />
              ))}
            </Styled.Tags>
          </Styled.InterestsContainer>
          <Styled.GoalsContainer>
            <p>{t('MiniCourseSettings.TagsSecondTitle2')}</p>
            <Styled.Tags style={{ flexDirection: 'column' }}>
              {GOALS.map(goal => (
                <Styled.Checkbox
                  key={goal.id}
                  checked={goals.includes(goal.title)}
                  onChange={e => onToggleGoal(e, goal.title)}
                  name={t(goal.title)}
                  style={{ width: '100%' }}
                />
              ))}
            </Styled.Tags>
          </Styled.GoalsContainer>
        </Styled.InterestsGoalsContainer>
      </Styled.TagsContainer>
      {lessons.length > 0 && (
        <Styled.LessonsContainer>
          <Styled.Title>{t('MiniCourseSettings.LessonsTitle')}</Styled.Title>
          <Styled.CustomList>
            {lessons.map(lesson => (
              <ListItem key={lesson.id}>
                <Styled.CustomListItemIcon>
                  <Styled.Checkbox
                    checked={!!lesson.isFree}
                    onChange={e => handleToggleLesson(e, lesson.id)}
                  />
                </Styled.CustomListItemIcon>
                <ListItemText primary={lesson?.name} />
              </ListItem>
            ))}
          </Styled.CustomList>
        </Styled.LessonsContainer>
      )}
      <Button
        customStyles={{
          backgroundColor: '#4284FF',
          padding: '13px 40px',
          borderRadius: 50,
          fontWeight: 700,
          fontSize: 18,
          margin: '50px 0 175px 42px',
        }}
        disabled={!price}
        loading={isLoading}
        onClick={onSave}
      >
        {t('MiniCourseSettings.SaveButton')}
      </Button>
    </Styled.PageContainer>
  )
}

export default MiniCourseSettingsTab
