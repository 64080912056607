import React from 'react'

export default function TypeMusicIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5984 18.5984C19.3016 17.8951 20.2554 17.5 21.25 17.5H26.25C26.9404 17.5 27.5 18.0597 27.5 18.75V21.25C27.5 22.2446 27.1049 23.1984 26.4017 23.9017C25.6984 24.6049 24.7446 25 23.75 25H21.25C20.2554 25 19.3016 24.6049 18.5984 23.9017C17.8951 23.1984 17.5 22.2446 17.5 21.25C17.5 20.2554 17.8951 19.3016 18.5984 18.5984ZM21.25 20C20.9185 20 20.6005 20.1317 20.3661 20.3661C20.1317 20.6005 20 20.9185 20 21.25C20 21.5815 20.1317 21.8995 20.3661 22.1339C20.6005 22.3683 20.9185 22.5 21.25 22.5H23.75C24.0815 22.5 24.3995 22.3683 24.6339 22.1339C24.8683 21.8995 25 21.5815 25 21.25V20H21.25ZM3.59835 21.0984C4.30161 20.3951 5.25544 20 6.25 20H11.25C11.9404 20 12.5 20.5597 12.5 21.25V23.75C12.5 24.7446 12.1049 25.6984 11.4016 26.4017C10.6984 27.1049 9.74456 27.5 8.75 27.5H6.25C5.25544 27.5 4.30161 27.1049 3.59835 26.4017C2.89509 25.6984 2.5 24.7446 2.5 23.75C2.5 22.7554 2.89509 21.8016 3.59835 21.0984ZM6.25 22.5C5.91848 22.5 5.60054 22.6317 5.36612 22.8661C5.1317 23.1005 5 23.4185 5 23.75C5 24.0815 5.1317 24.3995 5.36612 24.6339C5.60054 24.8683 5.91848 25 6.25 25H8.75C9.08152 25 9.39946 24.8683 9.63388 24.6339C9.8683 24.3995 10 24.0815 10 23.75V22.5H6.25Z"
        fill="#2B4659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.058 2.79623C27.3383 3.03374 27.5 3.38257 27.5 3.75001V18.75C27.5 19.4404 26.9404 20 26.25 20C25.5596 20 25 19.4404 25 18.75V5.22558L12.5 7.30892V21.25C12.5 21.9404 11.9404 22.5 11.25 22.5C10.5596 22.5 10 21.9404 10 21.25V6.25001C10 5.63896 10.4418 5.11747 11.0445 5.01702L26.0445 2.51702C26.4069 2.45661 26.7776 2.55873 27.058 2.79623Z"
        fill="#2B4659"
      />
    </svg>
  )
}
