import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'

import SingleTeacherInfo from './SingleTeacherInfo'
import { usersRef } from '@configs/firebase'
import { DecorStripe, Wrapper } from '.'
import { TeacherResultTitle } from '@pages/student/AutoTeacherSelection/ResultTeachers/result-teachers.styles'

import { AllTeachersBtn } from '@pages/student/AutoTeacherSelection/auto-teacher-selection.styles'
import returnBtn from '@assets/icons/returnBtn.svg'

export interface Params {
  teacherId: string
}

const DetailsPage = styled.div`
  width: 656px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DetailsTeacherInfo = () => {
  const [teacher, setTeacher] = useState(null)
  const { t } = useTranslation()
  const { teacherId } = useParams<Params>()
  const history = useHistory()

  useEffect(() => {
    usersRef
      .doc(teacherId)
      .get()
      .then(snapshot => {
        setTeacher({ ...snapshot.data(), id: teacherId })
      })
  }, [teacherId])

  if (teacher === null) {
    return null
  }

  const handleReturnClick = () => {
    history.push('/teachersInfo')
  }

  return (
    <Wrapper>
      <DecorStripe />
      <DetailsPage>
        <AllTeachersBtn
          onClick={handleReturnClick}
          style={{ position: 'absolute', left: 50, top: 38, color: '#7E92AC' }}
        >
          <img src={returnBtn} alt="return" style={{ marginRight: '6px' }} />
          {t('AutoTeacher.AllTeachers')}
        </AllTeachersBtn>
        <TeacherResultTitle style={{ padding: '60px 0 40px 0' }}>{teacher.name}</TeacherResultTitle>
        <SingleTeacherInfo teacher={teacher} />
      </DetailsPage>
    </Wrapper>
  )
}

export default DetailsTeacherInfo
