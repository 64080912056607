import React from 'react'

export default function MenuSearchIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M12.4001 22.6C12.2806 22.6 12.1611 22.6175 12.0471 22.6545C11.3991 22.865 10.7176 23 10.0001 23C9.28258 23 8.60108 22.865 7.95258 22.6545C7.83858 22.6175 7.71958 22.6 7.60008 22.6C4.49708 22.6 1.98358 25.124 2.00008 28.231C2.00708 29.544 3.08658 30.6 4.40008 30.6H15.6001C16.9136 30.6 17.9931 29.544 18.0001 28.231C18.0166 25.124 15.5031 22.6 12.4001 22.6ZM10.0001 21C12.6511 21 14.8001 18.851 14.8001 16.2C14.8001 13.549 12.6511 11.4 10.0001 11.4C7.34908 11.4 5.20008 13.549 5.20008 16.2C5.20008 18.851 7.34908 21 10.0001 21ZM31.6001 5H12.4001C11.0766 5 10.0001 6.1125 10.0001 7.4795V9.8C11.1711 9.8 12.2551 10.139 13.2001 10.69V8.2H30.8001V22.6H27.6001V19.4H21.2001V22.6H17.3881C18.3431 23.4345 19.0441 24.5365 19.3726 25.8H31.6001C32.9236 25.8 34.0001 24.6875 34.0001 23.3205V7.4795C34.0001 6.1125 32.9236 5 31.6001 5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="25.6" fill="white" transform="translate(2 5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
