import React, { useState, useEffect } from 'react'
import Bar from './Bar'
import { connect } from 'react-redux'
import { videoPlayerActions } from '@store/videoPlayer'
import { emitNewPlayerStateToServer } from '@servise'
import { toFixedNumber } from '@utils/helpers'
import { PLAYER_STATE } from '@utils/constants'
import './style.scss'

function Audio(props) {
  const {
    partyVideoPlayerState,
    videoPlayerIsMuted,
    videoDuration,
    videoProgress,
    setPlayerMutedState,
    classID,
    toHeader,
    playerIsHidden,
    fileUrl,
  } = props

  const [hidePlayer, setHidePlayer] = useState(true)
  const [prevPlayerState, setPrevPlayerState] = useState('')
  const { playing, paused, unstarted } = PLAYER_STATE
  const videoIsPlaying = partyVideoPlayerState.playerState === playing
  const setPlaying = () => {
    emitNewPlayerStateToServer(
      {
        playerState: !videoIsPlaying ? playing : paused,
        prevPlayerState: !videoIsPlaying ? playing : paused,
        timeInVideo: videoProgress,
      },
      classID
    )
  }
  const setMute = () => {
    setPlayerMutedState(!videoPlayerIsMuted)
  }
  const setClickedTime = time => {
    if (time >= 1) {
      emitNewPlayerStateToServer(
        {
          playerState: videoIsPlaying ? playing : paused,
          prevPlayerState: videoIsPlaying ? playing : paused,
          timeInVideo: toFixedNumber(time, 2),
        },
        classID
      )
    } else {
      emitNewPlayerStateToServer(
        {
          playerState: videoIsPlaying ? playing : paused,
          prevPlayerState: videoIsPlaying ? playing : paused,
          timeInVideo: 0,
        },
        classID
      )
    }
  }

  useEffect(() => {
    if (partyVideoPlayerState.playerState !== unstarted && prevPlayerState !== paused) {
      setHidePlayer(false)
    } else {
      setHidePlayer(true)
    }
    setPrevPlayerState('')
  }, [partyVideoPlayerState.playerState, paused, prevPlayerState, unstarted])

  const setHideAudio = () => {
    if (videoIsPlaying) {
      setPrevPlayerState(paused)
      emitNewPlayerStateToServer(
        {
          playerState: paused,
          timeInVideo: toFixedNumber(videoProgress, 2),
        },
        classID
      )
    }
    setHidePlayer(true)
  }

  if (!videoDuration || playerIsHidden) {
    return null
  }

  var style = {}

  if (toHeader) {
    style = { width: '390px', background: '#F3F5F9' }
  } else {
    style = { width: '100%', background: '#F3F5F9' }
  }

  if (hidePlayer && toHeader) {
    return null
  }
  return (
    <div className="player box" style={style}>
      <Bar
        mute={videoPlayerIsMuted}
        setMute={setMute}
        toHeader={toHeader}
        playing={videoIsPlaying}
        setPlaying={setPlaying}
        curTime={videoProgress}
        duration={videoDuration}
        onTimeUpdate={time => setClickedTime(time)}
        setHidePlayer={setHideAudio}
        fileUrl={fileUrl}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    partyVideoPlayerState: state.sync.reactPlayerState.videoPlayerState,
    selectedVideo: state.sync.reactPlayerState.selectedVideo,
    classID: state.classroom.classroomData.id,
    blocks: state.classroom.classroomData.blocks,
    videoPlayerIsMuted: state.videoPlayer.videoPlayerIsMuted,
    videoDuration: state.videoPlayer.videoDuration,
    videoProgress: state.videoPlayer.videoProgress,
    playerIsHidden: state.videoPlayer.playerIsHidden,
  }
}

const mapDispatchToProps = {
  setPlayerMutedState: videoPlayerActions.setPlayerMutedState,
}
export default connect(mapStateToProps, mapDispatchToProps)(Audio)
