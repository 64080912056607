import React, { useState } from 'react'
import * as Styled from '@components/RateDropdown/rate-dropdown.styles'
import { useSelector } from 'react-redux'
import { RootState } from '@store'
import { studentsRef, usersRef } from '@configs/firebase'
import { useTranslation } from 'react-i18next'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { ProfileAvatar } from '@pages/student/Profile/student-profile.styles'
import { IProfile, IStudentProfile } from '@models'
import DefaultAvatar from '@images/default_avatar.png'
import { ExpandMoreIcon, ExpandLessIcon } from '@components/Icons'
import { logger } from '@utils/ConsoleLogger'
import { toast } from 'react-toastify'
import { ROLES } from '@utils/constants'

interface Props {
  studentData: IStudentProfile
  handleChange: (data: IStudentProfile) => void
}

const TutorsDropDown = ({ studentData, handleChange }: Props) => {
  const userRole = useSelector((state: RootState) => (state.profile.profile as IProfile)?.role)
  const [teachers, setTeachers] = useState([])

  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    usersRef
      .where('role', '==', ROLES.MANAGER_ASSISTANT)
      .get()
      .then(teachersSnapshot => {
        let teachers: IProfile[] = teachersSnapshot.docs.map(user => {
          return {
            ...user.data(),
            id: user.id,
          } as IProfile
        })
        setTeachers(teachers)
      })
  }, [])

  const onHandleChange = (tutorData: IProfile) => {
    try {
      ;[ROLES.MANAGER_ASSISTANT, ROLES.MANAGER].includes(userRole) &&
        studentsRef
          .doc(studentData.id)
          .update({
            ...studentData,
            tutorName: tutorData.name + tutorData.lastName,
            tutorAvatar: tutorData.avatar,
            tutorId: tutorData.id,
          })
          .then(() => {
            handleChange({
              ...studentData,
              tutorName: tutorData.name + tutorData.lastName,
              tutorAvatar: tutorData.avatar,
              tutorId: tutorData.id,
            })
          })
    } catch (err) {
      logger.error(err)
      toast('Try to reload the page or check internet connection', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-card-message',
      })
    }

    setIsOpen(false)
  }

  const handleClickAway = () => {
    setIsOpen(false)
  }

  return (
    <Styled.CommentsContainter>
      <Styled.StudentCardHeader>Mentor</Styled.StudentCardHeader>
      <Styled.TutorContainter>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Styled.RateDropdownContainer>
            <Styled.TutorInfo onClick={() => setIsOpen(!isOpen)}>
              <Styled.ChosenTutor>
                <ProfileAvatar
                  src={studentData.tutorAvatar ? studentData.tutorAvatar : DefaultAvatar}
                  alt=""
                  style={{ width: 30, height: 30, margin: 0 }}
                />
                <Styled.TutorName>
                  {studentData.tutorName
                    ? `${studentData.tutorName.slice(0, 20)}...`
                    : "Don't choose"}
                </Styled.TutorName>
              </Styled.ChosenTutor>
              <Styled.ExpandIcon>
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Styled.ExpandIcon>
            </Styled.TutorInfo>
            {isOpen && (
              <Styled.Options id="rate-options" style={{ height: 200 }}>
                {teachers.map(data => (
                  <Styled.OptionItem key={data.id} onClick={() => onHandleChange(data)}>
                    {data.lastName || ''} {data.name || ''}
                  </Styled.OptionItem>
                ))}
              </Styled.Options>
            )}
          </Styled.RateDropdownContainer>
        </ClickAwayListener>
      </Styled.TutorContainter>
    </Styled.CommentsContainter>
  )
}

export default TutorsDropDown
