import React from 'react'

export default function LineWidth5Icon() {
  return (
    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51348 2.25236C4.06538 1.5523 2.23231 2.29681 1.84156 4.05992C1.72239 4.59764 1.21924 4.92996 0.717751 4.80218C0.216259 4.6744 -0.0936736 4.1349 0.0254984 3.59718C0.737766 0.38332 3.97224 -0.688244 6.28071 0.427754C7.6583 1.09373 8.16847 2.42341 8.51097 3.31605C8.54129 3.39509 8.5703 3.4707 8.59848 3.54212C8.79333 4.03593 8.94442 4.35659 9.13562 4.58109C9.29221 4.76495 9.50339 4.9163 9.90957 4.96775C10.9339 5.09752 11.452 4.79881 11.718 4.52016C12.0151 4.20899 12.1334 3.78126 12.1334 3.47308C12.1334 2.92039 12.5512 2.47234 13.0667 2.47234C13.5821 2.47234 14 2.92039 14 3.47308C14 4.23143 13.7342 5.2062 13.0225 5.95177C12.2798 6.72986 11.1646 7.14215 9.69056 6.95542C8.84752 6.84862 8.22199 6.47646 7.75723 5.93075C7.32709 5.42568 7.07222 4.81071 6.87938 4.32201C6.86352 4.28182 6.84794 4.24224 6.83259 4.20327C6.43278 3.18804 6.19382 2.58127 5.51348 2.25236Z"
        fill="white"
      />
    </svg>
  )
}
