import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { RadioGroup } from '@material-ui/core'
import { MAIN_GOALS } from '@utils/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step2 = ({
  goal,
  onChange,
}: {
  goal?: string
  onChange: (data: { name: string; value: any }) => void
}) => {
  const { t } = useTranslation()
  return (
    <RadioGroup
      aria-label="mainGoal"
      name="goal"
      value={goal}
      onChange={e => onChange({ name: 'goal', value: e.target.value })}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30,
        marginRight: 30,
      }}
    >
      {MAIN_GOALS.map(goal => (
        <CustomMaterialRadioButton
          name={goal}
          label={t(`Main.Goals.${goal}`)}
          key={goal}
        />
      ))}
    </RadioGroup>
  )
}
export default Step2
