import { FC } from 'react'
import { RawDraftContentState } from 'draft-js'
import { ICursorState } from './ISyncData'
import { ITestResultContent } from './ITest'
import { audioProps } from './IFactory'

export enum ILessonPageStateType {
  editor = 'editor',
  image = 'image',
  'action-editor' = 'action-editor',
  video = 'video',
  audio = 'audio',
  vocabulary = 'vocabulary',
  test = 'test',
  exercise = 'exercise',
  link = 'link',
  table = 'table',
  essay = 'essay',
  result = 'result',
}

export enum IBlockType {
  regular = 'regular',
  rule = 'rule',
}

export interface IVocabularyWord {
  id: number
  original: string
  translation: string
}

interface ITestAnswer {
  comment: boolean
  commentText: string
  id: number
  selected: boolean
  text: string
}

export interface IEditorContent {
  align: string
  bgColor: string
  color: string
  rowContent: RawDraftContentState
}

export interface IImageContent {
  description?: string
  height: number
  image: string
  isForStdnt: boolean
  isVisDesc: boolean
  originalHeight: number
  width: number
}

export interface IActionEditorContent {
  align: string
  bgColor: string
  blockType: IBlockType
  color: string
  rowContent: RawDraftContentState
  visibleForStudent: boolean
}

export interface IVideoContent {
  description: string
  isForStdnt: boolean
  isVisDesc: boolean
  submitted: boolean
  videoLink: string
}

export interface IAudioContent {
  audio: string
  description: string
  isForStdnt: boolean
  isVisDesc: boolean
}

export interface IVocabularyContent {
  words: IVocabularyWord[]
}

export interface ITestContent {
  isVarious: boolean
  question: string
  answers: ITestAnswer[]
}

export interface ISentenceConstructor {
  id: number
  text: string
}

export interface IExerciseContent {
  condition: string
  text?: string
  sentences?: ISentenceConstructor[]
  variant: 1 | 2 | 3 | 4
}

export interface ILinkContent {
  align: string
  link: string
  name: string
}

interface CellContent {
  rowContent: RawDraftContentState
  bgColor?: string
  align?: string
}

export interface ITableContent {
  hideBorder: boolean
  colWidths: number[]
  table: {
    [rowIndex: string]: CellContent[]
  }
}
export interface IEssayContent {
  condition: string
  listOfWords: string[]
  minWords: number
  heading: string
  randomWord: string[]
}

export type ILessonPageStateContent =
  | IEditorContent
  | IImageContent
  | IActionEditorContent
  | IVideoContent
  | IAudioContent
  | IVocabularyContent
  | ITestContent
  | IExerciseContent
  | ILinkContent
  | ITableContent
  | IEssayContent
  | ITestResultContent

export interface ILessonPageState {
  id: number
  type: ILessonPageStateType
  content: ILessonPageStateContent
}

export interface ILessonPage {
  id: number
  name: string
  pageState: ILessonPageState[]
  isHomework: boolean
  isWriting?: boolean
  writing?: string
  status?: string
}

export interface ILesson {
  [x: string]: any
  id: string
  name: string
  description: string
  status?: string
  teacherId?: string
  image: string
  timestamp: number
  pages: ILessonPage[]
  isAllowed?: boolean
  isCurrent?: boolean
  dbData?: ILessonRealTimeData
  courseId?: string
  isVisible?: boolean
}

export interface ITeacherCourseNotesUpdates {
  teacherNotes: string
  studentId: string
  courseId: string
}

export interface ILessonNotebookProps {
  text: string
  scrollPosition?: number
  studentId: string
  userId: string
}

export interface ILessonRealTimeDataNotebookState {
  text: string
  userTyping: string
  scrollPosition?: number
}

export interface IDragItem {
  text: string
  correctIndex: number
  droppedIndex?: number
}

export interface IAddedWordsState {
  [index: string]: boolean
}

export interface IAddedEssay {
  [index: string]: string
}

export interface ITestState {
  [index: string]: boolean | number
}

export interface IInsertWordState {
  [index: string]: {
    correct: string
    current: string
    try: number
    isValidated?: boolean
    showEye?: boolean
  }
}

export interface IMoveWordsInText {
  [index: string]: {
    text: string
    correctIndex: number
    droppedIndex?: number
  }
}

export interface IWordConstructorState {
  [index: string]: IDragItem
}

export interface IWordConstructorSentenceState {
  [index: string]: IWordConstructorState
}

export interface IActionEditorState {
  isDisplayed: boolean
}

export type IClearedState = { clear: boolean }

export type IExerciseState =
  | IAddedWordsState
  | ITestState
  | IMoveWordsInText
  | IInsertWordState
  | IWordConstructorSentenceState
  | IActionEditorState
  | IClearedState
  | IAddedEssay

export interface IRealPageState {
  [exerciseId: string]: IExerciseState
}

export interface IRealPageStates {
  [pageId: string]: IRealPageState
}

export enum ISyncPlayerState {
  'playing' = 'playing',
  'paused' = 'paused',
  'unstarted' = 'unstarted',
}

export interface IPlayerState {
  id: number
  duration: number
  curTime: number
  state: ISyncPlayerState
  page: string
}

export interface ILessonRealTimeData {
  scrollFileId?: string
  isStarted: boolean
  currentPage?: string
  isStudentOnline?: boolean
  notebookState?: ILessonRealTimeDataNotebookState
  cursorState?: ICursorState
  pageStates?: IRealPageStates
  playerState?: IPlayerState
  teacherName?: string
  teacherId?: string
}

export interface ISketchData {
  id: string
  sketchData: string
}

export interface ILessonState {
  lesson: ILesson
  isLoading: boolean
}

export enum LessonWidgetType {
  student = 'student',
  teacher = 'teacher',
}

export interface ILessonPageParams {
  courseId: string
  lessonId: string
  pageId: string
  classId?: string
  homeworkId?: string
  testId?: string
}

export interface ILessonStudentPageParams extends ILessonPageParams {
  studentId: string
}

export interface ILessonData {
  id: string
  start: string
}

export interface IPageStateComponentProps<T = ILessonPageStateContent, S = IExerciseState> {
  content: T
  id: number
  isCurrentLesson: boolean
  lesson?: ILesson
  cursorState?: ICursorState
  realTimeState: S
  currentPage: string
  widgetType: LessonWidgetType
  sketch?: ISketchData
  prevRealTimeData?: ILessonRealTimeData
  playerState: IPlayerState
  isMutedPlayer: boolean
  setIsMutedPlayer(isMuted: boolean): void
  currentTime: number
  isHomework?: boolean
  setCurrentTime(time: number): void
  handleSavePlayerState(playerState: Partial<IPlayerState>): void
  saveStudentHomework?(data: IRealPageState): void
  saveStudentTest?(data: IRealPageState): void
  isHomeWork: boolean
  isClearEssay?: boolean
  isResult?: boolean
  studentID?: string
  testID?: string
  teacherReview?: boolean
  teacherTest?: boolean
  testData?: any
  matches?: ITestResultContent['matches']
  mark?: ITestResultContent['mark'] //mark
  answersSound?: audioProps
}

export type IPageStateComponentsMap = {
  [key in ILessonPageStateType]: FC<IPageStateComponentProps>
}

export interface ILessonInlineStyles {
  bgColor: string
  align: string
}
