import { IProfile, IStudentProfile } from './IProfile'
import firebase from 'firebase/app'
import { CURRENCY_CODES } from '@utils/constants'
export interface ITeacherStatistics {
  name: string
  id: string
  lessonsPassed: number
  summ: number
  weeklyLoad: number
}

export interface IPersonalState {
  teachers: IProfile[]
  teachersAccounting: IProfile[]
  students: IStudentProfile[]
  potentialStudents: IStudentProfile[]
  loading: boolean
  managers: IProfile[]
  salesManagers: IProfile[]
  teachersStatistics: IProfile[]
}

export interface IPassedLesson {
  d: firebase.firestore.Timestamp
  n: string
  id?: string
  r: string | number
  cr: CURRENCY_CODES
  s: PassedLessonStatus
  ls?: string
  c?: string
  dp?: firebase.firestore.Timestamp
  cs?: string
  i?: number
}

export interface IRequisites {
  paymentMethod: string
  number: string
}
export enum PassedLessonStatus {
  done = 'done',
  active = 'active',
  checked = 'checked',
}
