import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { RadioGroup } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './steps.styles'

const PRIORITIES_OPTIONS = ['important', 'notImportant', 'neutral']

export default function({
  priorities,
  onChange,
}: {
  priorities: { [Name: string]: string }
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const newPriorities = priorities
    newPriorities[name] = e.target.value

    onChange({ name: 'priorities', value: JSON.parse(JSON.stringify(newPriorities)) })
  }
  return (
    <Styled.PrioritiesContainer>
      <Styled.WorksheetStartTitle>{t('Priorities.speaking')}</Styled.WorksheetStartTitle>
      <RadioGroup
        aria-label="prioritiesSpeaking"
        name="prioritiesSpeaking"
        value={priorities['speaking']}
        onChange={e => handleChange(e, 'speaking')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 14,
          marginBottom: 30,
          gap: 16,
        }}
      >
        {PRIORITIES_OPTIONS.map(option => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomMaterialRadioButton
                name={option}
                label={t(`Priorities.${option}`)}
                key={option}
              />
            </div>
          )
        })}
      </RadioGroup>
      <Styled.WorksheetStartTitle>{t('Priorities.listening')}</Styled.WorksheetStartTitle>
      <RadioGroup
        aria-label="prioritiesListening"
        name="prioritiesListening"
        value={priorities['listening']}
        onChange={e => handleChange(e, 'listening')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 14,
          marginBottom: 30,
          gap: 16,
        }}
      >
        {PRIORITIES_OPTIONS.map(option => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomMaterialRadioButton
                name={option}
                label={t(`Priorities.${option}`)}
                key={option}
              />
            </div>
          )
        })}
      </RadioGroup>
      <Styled.WorksheetStartTitle>{t('Priorities.reading')}</Styled.WorksheetStartTitle>
      <RadioGroup
        aria-label="prioritiesReading"
        name="prioritiesReading"
        value={priorities['reading']}
        onChange={e => handleChange(e, 'reading')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 14,
          marginBottom: 30,
          gap: 16,
        }}
      >
        {PRIORITIES_OPTIONS.map(option => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomMaterialRadioButton
                name={option}
                label={t(`Priorities.${option}`)}
                key={option}
              />
            </div>
          )
        })}
      </RadioGroup>
      <Styled.WorksheetStartTitle>{t('Priorities.writing')}</Styled.WorksheetStartTitle>
      <RadioGroup
        aria-label="prioritiesWriting"
        name="prioritiesWriting"
        value={priorities['writing']}
        onChange={e => handleChange(e, 'writing')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 14,
          marginBottom: 30,
          gap: 16,
        }}
      >
        {PRIORITIES_OPTIONS.map(option => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomMaterialRadioButton
                name={option}
                label={t(`Priorities.${option}`)}
                key={option}
              />
            </div>
          )
        })}
      </RadioGroup>
    </Styled.PrioritiesContainer>
  )
}
