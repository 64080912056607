import React from 'react'
export default function ChatIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7108 31.1126C12.9195 32.343 15.4132 33 18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 20.5868 3.65702 23.0805 4.88737 25.2892L4.35796 31.642L10.7108 31.1126ZM11.4126 28.0438L11.8275 28.2932C13.6764 29.4045 15.7927 30 18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 20.2073 6.59545 22.3236 7.70683 24.1725L7.95625 24.5874L7.64204 28.358L11.4126 28.0438ZM16.499 17.9978C16.499 18.8262 17.1708 19.4978 17.9995 19.4978C18.8282 19.4978 19.5 18.8262 19.5 17.9978C19.5 17.1694 18.8282 16.4978 17.9995 16.4978C17.1708 16.4978 16.499 17.1694 16.499 17.9978ZM23.9995 19.4978C23.1708 19.4978 22.499 18.8262 22.499 17.9978C22.499 17.1694 23.1708 16.4978 23.9995 16.4978C24.8282 16.4978 25.5 17.1694 25.5 17.9978C25.5 18.8262 24.8282 19.4978 23.9995 19.4978ZM10.499 17.9978C10.499 18.8262 11.1708 19.4978 11.9995 19.4978C12.8282 19.4978 13.5 18.8262 13.5 17.9978C13.5 17.1694 12.8282 16.4978 11.9995 16.4978C11.1708 16.4978 10.499 17.1694 10.499 17.9978Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
