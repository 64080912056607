import * as React from 'react'

const InstagramIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM14.0425 8.45771C15.0665 8.41111 15.3937 8.39971 18.0009 8.39971H17.9979C20.6059 8.39971 20.9319 8.41111 21.956 8.45771C22.978 8.50451 23.676 8.66631 24.288 8.90371C24.92 9.14872 25.454 9.47672 25.988 10.0107C26.522 10.5443 26.85 11.0799 27.096 11.7113C27.332 12.3217 27.494 13.0194 27.542 14.0414C27.588 15.0654 27.6 15.3926 27.6 17.9998C27.6 20.607 27.588 20.9334 27.542 21.9574C27.494 22.9791 27.332 23.6769 27.096 24.2875C26.85 24.9187 26.522 25.4543 25.988 25.9879C25.4546 26.5219 24.9198 26.8507 24.2886 27.0959C23.6778 27.3333 22.9794 27.4951 21.9574 27.5419C20.9333 27.5885 20.6071 27.5999 17.9997 27.5999C15.3927 27.5999 15.0657 27.5885 14.0417 27.5419C13.0199 27.4951 12.3221 27.3333 11.7112 27.0959C11.0802 26.8507 10.5446 26.5219 10.0112 25.9879C9.47742 25.4543 9.14942 24.9187 8.90402 24.2873C8.66681 23.6769 8.50501 22.9793 8.45801 21.9572C8.41161 20.9332 8.40001 20.607 8.40001 17.9998C8.40001 15.3926 8.41201 15.0652 8.45781 14.0412C8.50381 13.0196 8.66581 12.3217 8.90382 11.7111C9.14982 11.0799 9.47782 10.5443 10.0118 10.0107C10.5454 9.47692 11.081 9.14891 11.7124 8.90371C12.3229 8.66631 13.0205 8.50451 14.0425 8.45771Z"
        fill="#9ABDFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1397 10.1298C17.307 10.1296 17.4868 10.1297 17.681 10.1297L18.0009 10.1298C20.5642 10.1298 20.868 10.139 21.8802 10.185C22.8162 10.2278 23.3242 10.3842 23.6626 10.5156C24.1106 10.6896 24.43 10.8976 24.7658 11.2337C25.1018 11.5697 25.3098 11.8897 25.4842 12.3377C25.6156 12.6757 25.7722 13.1837 25.8148 14.1197C25.8608 15.1317 25.8708 15.4357 25.8708 17.9977C25.8708 20.5598 25.8608 20.8638 25.8148 21.8758C25.772 22.8118 25.6156 23.3198 25.4842 23.6578C25.3102 24.1058 25.1018 24.4248 24.7658 24.7606C24.4298 25.0966 24.1108 25.3046 23.6626 25.4786C23.3246 25.6106 22.8162 25.7666 21.8802 25.8094C20.8682 25.8554 20.5642 25.8654 18.0009 25.8654C15.4375 25.8654 15.1337 25.8554 14.1217 25.8094C13.1857 25.7662 12.6777 25.6098 12.3391 25.4784C11.8911 25.3044 11.5711 25.0964 11.2351 24.7604C10.8991 24.4244 10.6911 24.1052 10.5167 23.657C10.3853 23.319 10.2287 22.811 10.1861 21.875C10.1401 20.863 10.1309 20.559 10.1309 17.9953C10.1309 15.4317 10.1401 15.1293 10.1861 14.1173C10.2289 13.1813 10.3853 12.6733 10.5167 12.3349C10.6907 11.8869 10.8991 11.5669 11.2351 11.2309C11.5711 10.8948 11.8911 10.6868 12.3391 10.5124C12.6775 10.3804 13.1857 10.2244 14.1217 10.1814C15.0073 10.1414 15.3505 10.1294 17.1397 10.1274V10.1298ZM23.1254 11.7241C22.4894 11.7241 21.9734 12.2395 21.9734 12.8757C21.9734 13.5117 22.4894 14.0278 23.1254 14.0278C23.7614 14.0278 24.2774 13.5117 24.2774 12.8757C24.2774 12.2397 23.7614 11.7237 23.1254 11.7237V11.7241ZM13.0709 18.0003C13.0709 15.2776 15.2783 13.0702 18.0009 13.0702H18.0007C20.7233 13.0702 22.9302 15.2776 22.9302 18.0003C22.9302 20.7229 20.7235 22.9293 18.0009 22.9293C15.2783 22.9293 13.0709 20.7229 13.0709 18.0003Z"
        fill="#9ABDFC"
      />
      <path
        d="M18.0008 14.8003C19.768 14.8003 21.2008 16.2329 21.2008 18.0003C21.2008 19.7675 19.768 21.2004 18.0008 21.2004C16.2334 21.2004 14.8008 19.7675 14.8008 18.0003C14.8008 16.2329 16.2334 14.8003 18.0008 14.8003Z"
        fill="#9ABDFC"
      />
    </svg>
  )
}

export default InstagramIcon
