import Parser from 'rss-parser'
import { BlogLink, BlogLinkEnglishAndSpanish } from '@utils/constants'
import { LangEnum } from '@models/IProfile'
const parser = new Parser()
export const parseRssNews = async (lang: LangEnum) => {
  const link = lang === LangEnum.ru ? BlogLink : BlogLinkEnglishAndSpanish

  const response = await fetch(link)
  const text = await response.text()
  const feed = await parser.parseString(text)

  return feed.items
    .sort((a, b) => new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime())
    .slice(0, 3)
}
