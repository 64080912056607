const GET_TESTS = 'GET_TESTS'
const GET_TESTS_FULFILLED = 'GET_TESTS_FULFILLED'
const GET_TESTS_REJECTED = 'GET_TESTS_REJECTED'

const CREATE_TEST = 'CREATE_TEST'
const CREATE_TEST_FULFILLED = 'CREATE_TEST_FULFILLED'
const CREATE_TEST_REJECTED = 'CREATE_TEST_REJECTED'

const DELETE_TEST = 'DELETE_TEST'
const DELETE_TEST_FULFILLED = 'DELETE_TEST_FULFILLED'
const DELETE_TEST_REJECTED = 'DELETE_TEST_REJECTED'

const DUPLICATE_TEST = 'DUPLICATE_TEST'
const DUPLICATE_TEST_FULFILLED = 'DUPLICATE_TEST_FULFILLED'
const DUPLICATE_TEST_REJECTED = 'DUPLICATE_TEST_REJECTED'

const EDIT_TEST = 'EDIT_TEST'
const EDIT_TEST_FULFILLED = 'EDIT_TEST_FULFILLED'
const EDIT_TEST_REJECTED = 'EDIT_TEST_REJECTED'

const ASSIGN_TEST = 'ASSIGN_TEST'
const ASSIGN_TEST_FULFILLED = 'ASSIGN_TEST_FULFILLED'
const ASSIGN_TEST_REJECTED = 'ASSIGN_TEST_REJECTED'

const RESET_ALL_TESTS = 'RESET_ALL_TESTS'

export {
  GET_TESTS,
  GET_TESTS_FULFILLED,
  GET_TESTS_REJECTED,
  CREATE_TEST,
  CREATE_TEST_FULFILLED,
  CREATE_TEST_REJECTED,
  DELETE_TEST,
  DELETE_TEST_FULFILLED,
  DELETE_TEST_REJECTED,
  DUPLICATE_TEST,
  DUPLICATE_TEST_FULFILLED,
  DUPLICATE_TEST_REJECTED,
  RESET_ALL_TESTS,
  EDIT_TEST,
  EDIT_TEST_FULFILLED,
  EDIT_TEST_REJECTED,
  ASSIGN_TEST,
  ASSIGN_TEST_FULFILLED,
  ASSIGN_TEST_REJECTED,
}
