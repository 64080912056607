import React, { useState } from 'react'
import Spinner from 'react-spinkit'
import Cross from '@assets/images/blocks/cross.svg'
import Plus from '@assets/images/blocks/plus.svg'
import LoaderChecked from '../../assets/icons/checked.svg'

export const VocabularyBlock = ({ onStateChange, id, preloadedState }) => {
  const initialState = {
    words: [
      {
        id: 1,
        original: '',
        translation: '',
      },
    ],
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const state = preloadedState || initialState

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)

  const changeState = state => {
    onStateChange(id, {
      ...preloadedState,
      ...state,
    })
  }

  return (
    <div className="aaa">
      {state.words.map(({ id, original, translation }, index) => (
        <div key={id} className="vocabulary-block">
          <input
            type="text"
            className="vocabulary-area"
            placeholder="Введите слово или фразу"
            onChange={e => {
              changeState({
                words: state.words.map(word => {
                  if (word.id === id) {
                    return {
                      id,
                      translation,
                      original: e.target.value,
                    }
                  }
                  return word
                }),
              })
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                setShowCheckmark(true)
                setTimeout(() => {
                  setShowCheckmark(false)
                }, 2000)
              }, 3000)
            }}
            value={original}
            style={{
              borderBottom: index === state.words.length - 1 ? '1px solid #C2CFE0' : '',
            }}
          />
          <input
            type="text"
            className="vocabulary-area"
            placeholder="а здесь - перевод"
            // onChange={handleChangeDescription}
            value={translation}
            onChange={e => {
              changeState({
                words: state.words.map(word => {
                  if (word.id === id) {
                    return {
                      id,
                      original,
                      translation: e.target.value,
                    }
                  }
                  return word
                }),
              })
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                setShowCheckmark(true)
                setTimeout(() => {
                  setShowCheckmark(false)
                }, 2000)
              }, 3000)
            }}
            style={{
              borderBottom: index === state.words.length - 1 ? '1px solid #C2CFE0' : '',
            }}
          />
          <img
            onClick={() => {
              changeState({
                words: state.words.filter(item => item.id !== id),
              })
              setLoading(true)
              setTimeout(() => {
                setLoading(false)
                setShowCheckmark(true)
                setTimeout(() => {
                  setShowCheckmark(false)
                }, 2000)
              }, 3000)
            }}
            style={{ padding: '0 20px', cursor: 'pointer' }}
            src={Cross}
            alt=""
          />
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div
          className="centered-icon-voc"
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          onClick={() => {
            changeState({
              words: [
                ...state.words,
                {
                  id: state.words.length ? state.words[state.words.length - 1].id + 1 : 0,
                  original: '',
                  transition: '',
                },
              ],
            })
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
              setShowCheckmark(true)
              setTimeout(() => {
                setShowCheckmark(false)
              }, 2000)
            }, 3000)
          }}
        >
          <img src={Plus} alt="" style={{ display: 'flex', justifyContent: 'center' }} />
        </div>
        <div style={{ width: '20px', position: 'relative' }}>
          {isLoading && (
            <Spinner
              name="line-spin-fade-loader"
              color="#FF852D"
              style={{
                scale: '0.4',
                top: '10px',
                left: '-5px',
              }}
            />
          )}
          {showCheckmark && (
            <img
              style={{
                position: 'absolute',
                right: '7px',
                top: '4px',
              }}
              className="checkmark"
              src={LoaderChecked}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  )
}
