import React from 'react'
import * as Styled from './package.styles'
import { Course } from '@components'
import { ICourse } from '@models'
interface Props {
  type: string
  courses: ICourse[]
  promoCode: string
  currency: string
}

const Package = ({ type, courses, promoCode, currency }: Props) => {
  const oldLessonPrice = courses[0].price / courses[0].lessons

  return (
    <Styled.Package>
      <Styled.CoursesContainer>
        {courses.map(course => (
          <Course
            key={course.lessons}
            lessons={course.lessons}
            price={course.price}
            type={type}
            currency={currency}
            promoCode={promoCode}
            oldLessonPrice={oldLessonPrice}
          />
        ))}
      </Styled.CoursesContainer>
    </Styled.Package>
  )
}

export default Package
