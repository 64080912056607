import { IDay, IState } from '@models/IIndividualPlan'

export const SET_PLAN = 'SET_PLAN'
export const UPDATE_DAILY_TASK = 'UPDATE_DAILY_TASK'
export const REGENERATE_INDIVIDUAL_PLAN = 'REGENERATE_INDIVIDUAL_PLAN'
export const REACHED_INTERMEDIATE_GOAL = 'REACHED_INTERMEDIATE_GOAL'
export const REACHED_MAIN_GOAL = 'REACHED_MAIN_GOAL'

export interface SetIndividualPlanAction {
  type: typeof SET_PLAN
  payload: IState
}

export interface UpdateDailyTaskAction {
  type: typeof UPDATE_DAILY_TASK
  payload: {
    day: number
    taskId: string
    points: number
  }
}

export interface RegenerateIndividualPlanAction {
  type: typeof REGENERATE_INDIVIDUAL_PLAN
  payload: {
    plan: IDay[]
    date: any
  }
}

export interface ReachedIntermediateGoalAction {
  type: typeof REACHED_INTERMEDIATE_GOAL
}

export interface ReachedMainGoalAction {
  type: typeof REACHED_MAIN_GOAL
}

export type IndividualPlanActionTypes =
  | SetIndividualPlanAction
  | UpdateDailyTaskAction
  | RegenerateIndividualPlanAction
  | ReachedIntermediateGoalAction
  | ReachedMainGoalAction
