import axios from 'axios'
import { FIREBASE_CONFIG } from './constants'
import { storageRef } from '@configs/firebase'
import queryString from 'query-string'
const cacheName = 'imagesCache'

export const getCachedImage = async (url: string) => {
  const isFromGoogle = url.includes('https://drive.google.com/uc?id')
  const isFromFirebase = url.includes('firebasestorage.googleapis.com')
  if (!isFromGoogle && !isFromFirebase) return url
  let imageCachedUrl = ''
  try {
    if ('caches' in window) {
      const cacheStorage = await caches.open(cacheName)
      const cachedResponse = await cacheStorage.match(url)

      if (cachedResponse) {
        const blobImage = await cachedResponse.blob()
        imageCachedUrl = URL.createObjectURL(blobImage)
      } else {
        let googleStorageUrl: string | null = null
        const values = queryString.parse(url)
        const fileID = values['https://drive.google.com/uc?id'] as string

        if (isFromGoogle) {
          try {
            googleStorageUrl = await storageRef
              .ref('COURSES')
              .child(`google_drive`)
              .child('images')
              .child(fileID)
              .getDownloadURL()
          } catch (e) {}
        }

        const apiUrl = isFromGoogle
          ? googleStorageUrl
            ? googleStorageUrl
            : FIREBASE_CONFIG.proxyImageFnLink + '?url=' + encodeURIComponent(url)
          : url

        const response = await axios.get(apiUrl, {
          responseType: 'blob',
        })

        let init = { status: 200, statusText: 'ok' }

        if (response.status === 200 && response?.data instanceof Blob) {
          if (isFromGoogle) {
            await storageRef.ref(`COURSES/google_drive/images/${fileID}`).put(response.data)
          }
          const imageResponse = new Response(response.data, init)
          await cacheStorage.put(url, imageResponse)
          imageCachedUrl = URL.createObjectURL(response.data as any)
        } else {
          imageCachedUrl = url
        }
      }

      return imageCachedUrl
    } else {
      return url
    }
  } catch (e) {
    return url
  }
}
