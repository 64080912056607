import React from 'react'
import Input from 'input-material-ui'
import styled from 'styled-components'
import { CustomMaterialCheckbox } from '@components'

const Checkbox = styled(CustomMaterialCheckbox)`
  margin-top: 35px;
  & .MuiButtonBase-root {
    margin-right: 17px;
  }
  & .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(25, 42, 62, 1);
    letter-spacing: 0.01em;
  }
  & svg {
    width: 20px;
    height: 20px;
  }
  & rect {
    stroke: #c2cfe0;
  }
`

const ModalInputs = ({
  preloadedName,
  preloadedDescription,
  isMini,
  preloadedTime,
  onChangeField,
  isDescriptionBlockHidden,
  localization: { inputPlaceholderName, inputPlaceholderDescription, checkboxMiniTitle, timePlaceholderTitle },
}) => {
  return (
    <div className="modal-inputs-container">
      <Input
        className="name-input"
        type="text"
        value={preloadedName}
        label={inputPlaceholderName}
        onChange={onChangeField.bind({
          target: 'name-field',
        })}
      />
      {
        !isDescriptionBlockHidden ? (
          <Input
            className="description-input"
            type="text"
            value={preloadedDescription}
            label={inputPlaceholderDescription}
            onChange={onChangeField.bind({
              target: 'description-field',
            })}
          />
        ) : null
      }
      {checkboxMiniTitle && (
        <Checkbox
          checked={isMini}
          onChange={onChangeField.bind({
            target: 'mini-checkbox',
          })}
          name={checkboxMiniTitle}
        />
      )}
      {timePlaceholderTitle && (
        <Input
          className="description-input"
          type="text"
          value={preloadedTime}
          onChange={onChangeField.bind({
            target: 'time-field',
          })}
          label={timePlaceholderTitle}
        />
      )}
    </div>
  )
}

export default ModalInputs
