import styled from 'styled-components'

export const PlaybackRateContainter = styled.div`
  display: flex;
  align-items: left;
  width: 50px;
  height: 0px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
`

export const RateDropdownContainer = styled.div`
  position: relative;
`

export const ChosenOption = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
  font-size: 24px;
  line-height: 33px;
  color: #414752;
  padding: 11px 20px;
  cursor: pointer;
  letter-spacing: 0.01em;
`

export const ChosenPlaybackRate = styled.div`
  margin-left: -10px;
  display: flex;
  align-items: center;
`
export const PlaybackRateName = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 10px;
  white-space: nowrap;
  cursor: none;
`

export const PlaybackRateInfo = styled.div`
  width: 15px;
  position: relative;
  display: flex;
  align-items: center;
  jutify-content: space-between;
  font-size: 16px;
  line-height: 10px;
  scale: 0.8;
  // background-color: #7e92ac;
  // border-radius: 10px;
  // color: #f3f5f9;
  padding: 15px 10px;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.01em;
`

export const Options = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 45px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 10px;
  z-index: 9999;
  right: -25px;
  overflow: auto;
`

export const PlaybackRateItem = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  &:hover {
    color: #ff852d;
  }
`
