import React from "react";

export default function MoreHorizontalIcon(props) {
  return (
    <svg
      width="40"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 20C16.6667 18.159 18.1591 16.6667 20.0001 16.6667C21.841 16.6667 23.3334 18.159 23.3334 20C23.3334 21.8409 21.841 23.3333 20.0001 23.3333C18.1591 23.3333 16.6667 21.8409 16.6667 20Z"
        fill="#336A93"
      />
      <path
        d="M28.3333 20C28.3333 18.159 29.8256 16.6667 31.6666 16.6667C33.5075 16.6667 34.9999 18.159 34.9999 20C34.9999 21.8409 33.5075 23.3333 31.6666 23.3333C29.8256 23.3333 28.3333 21.8409 28.3333 20Z"
        fill="#336A93"
      />
      <path
        d="M5 20C5 18.159 6.49238 16.6667 8.33333 16.6667C10.1743 16.6667 11.6667 18.159 11.6667 20C11.6667 21.8409 10.1743 23.3333 8.33333 23.3333C6.49238 23.3333 5 21.8409 5 20Z"
        fill="#336A93"
      />
    </svg>
  );
}
