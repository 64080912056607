import styled from 'styled-components'

export const Container = styled.form`
  padding: 20px;
  width: 600px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ebeff2;
  padding-top: 20px;
`

export const Center = styled.div`
  margin: 30px 0;
`

export const Input = styled.textarea`
  width: 100%;
  border: 1px solid #90a4bd;
  border-radius: 20px;
  min-height: 46px;
  background: #f3f5f9;
  margin: 15px 0;
  padding: 15px;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  resize: none;
  color: ${props => props.theme.textColors.mainContent};
`
export const EssayCheckBoxWrapper = styled.div<{
  toolTipLeft?: string
  toolTipTop?: string
}>`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 0px;

  img {
    margin-left: 11px;
    cursor: pointer;

    &:hover {
      + .menu-text {
        display: block;
      }
    }
  }

  .menu-text {
    display: none;
    position: absolute;
    background: rgba(194, 207, 224, 0.9);
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    color: #414752;
    padding: 10px 15px;
    z-index: 10;
    left: ${props => (props.toolTipLeft ? `${props.toolTipLeft}px` : '135px')};
    top: ${props => (props.toolTipTop ? `${props.toolTipTop}px` : '-60px')};
    max-width: 225px;
    box-sizing: border-box;
  }
`
