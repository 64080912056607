import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import * as Styled from '../personal-info.styles'
import { IconButton, RadioGroup } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { GRADIES, GRADIES_PERCENT, LEVELS } from '@utils/constants'
import CustomDropDown from '@components/Dropdown'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import firebase from 'firebase/app'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: '#C2CFE0',
    opacity: 0.3,
  },
  top: {
    color: '#9ABDFC',
    position: 'absolute',
    left: 0,
    transform: 'rotate(90deg)',
  },
  circle: {
    strokeLinecap: 'round',
  },
}))
const levelOptions = Object.keys(LEVELS).map(key => ({ id: key, title: key }))
export default function({
  level,
  levelLastUpdate,
  levelGrade,
  editType,
  handleClickEdit,
  onChange,
}: {
  level: string
  levelGrade: string
  levelLastUpdate: firebase.firestore.Timestamp
  editType?: string
  handleClickEdit: (data: string) => void
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  return (
    <Styled.PersonalInfoItem>
      <>
        <CircularProgressWithLabel label={level} value={(100 / 5) * GRADIES_PERCENT[levelGrade]} />
        <Styled.LevelInfo>
          <Styled.Div className="level-info-container">
            <span className="level-title">Level</span>
            {levelLastUpdate && (
              <span className="level-time">
                Updated on{' '}
                {moment(levelLastUpdate?.seconds ? levelLastUpdate.toDate() : levelLastUpdate)
                  .locale('en')
                  .format('DD-MM-YYYY')}
              </span>
            )}
          </Styled.Div>
          <span className="level-grade">
            {levelGrade === 'one'
              ? 'has just started studying'
              : levelGrade === 'two'
              ? 'knows something, but not much'
              : levelGrade === 'three'
              ? 'has knowledge, but also has meaningful gaps'
              : levelGrade === 'four'
              ? 'has enough knowledge, but there is still work to be done'
              : levelGrade === 'five'
              ? 'good knowledge, time to learn the next level'
              : ''}
          </span>
        </Styled.LevelInfo>
        {!editType && <div className="hover-block" />}
      </>
      {editType === 'level' && (
        <Styled.EditContainer>
          <CustomDropDown
            options={levelOptions}
            chosenOption={{ id: level, title: `${level} Level` }}
            setOption={val => onChange({ name: 'level', value: val.id })}
          />
          <RadioGroup
            aria-label="levelGrade"
            name="levelGrade"
            value={levelGrade}
            onChange={e => onChange({ name: 'levelGrade', value: e.target.value })}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 20,
              marginRight: 20,
              margin: '0px 20px 30px 20px',
            }}
          >
            {Object.keys(GRADIES).map(key => (
              <CustomMaterialRadioButton
                name={GRADIES[key]}
                label={
                  GRADIES[key] === 'one'
                    ? 'has just started studying'
                    : GRADIES[key] === 'two'
                    ? 'knows something, but not much'
                    : GRADIES[key] === 'three'
                    ? 'has knowledge, but also has meaningful gaps'
                    : GRADIES[key] === 'four'
                    ? 'has enough knowledge, but there is still work to be done'
                    : GRADIES[key] === 'five'
                    ? 'good knowledge, time to learn the next level'
                    : ''
                }
                key={key}
              />
            ))}
          </RadioGroup>
        </Styled.EditContainer>
      )}

      <IconButton className="edit-btn" size="small" onClick={() => handleClickEdit('level')}>
        {editType === 'level' ? <CheckMarkIcon /> : <EditIcon />}
      </IconButton>
    </Styled.PersonalInfoItem>
  )
}

function CircularProgressWithLabel(props) {
  const classes = useStyles()
  return (
    <Styled.LevelProgressContainer>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={56}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={56}
        {...props}
      />
      <Styled.LevelProgeessCircle>
        <Styled.LevelProgressTitle>{props.label}</Styled.LevelProgressTitle>
      </Styled.LevelProgeessCircle>
    </Styled.LevelProgressContainer>
  )
}
