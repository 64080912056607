import React from 'react'
function SupportIconLogin(props) {
  return (
    <svg
      width={props.width ?? '100'}
      height={props.height ?? '94'}
      viewBox="0 0 100 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_bd)">
        <path
          d="M20 34C20 17.4315 33.4315 4 50 4C66.5685 4 80 17.4315 80 34C80 50.5685 66.5685 64 50 64C33.4315 64 20 50.5685 20 34Z"
          fill="#9ABDFC"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.5 23.5C44.3579 23.5 41 26.8579 41 31V32.5V43H36.5C34.8431 43 33.5 41.6569 33.5 40V34C33.5 32.3431 34.8431 31 36.5 31L38 31C38 25.201 42.701 20.5 48.5 20.5H51.5C57.299 20.5 62 25.201 62 31H63.5C65.1569 31 66.5 32.3431 66.5 34V40C66.5 41.6569 65.1569 43 63.5 43H61.811C61.1449 45.5878 58.7958 47.5 56 47.5H54.0987C53.5799 48.3967 52.6104 49 51.5 49C49.8431 49 48.5 47.6569 48.5 46C48.5 44.3431 49.8431 43 51.5 43C52.6104 43 53.5799 43.6033 54.0987 44.5H56C57.6569 44.5 59 43.1569 59 41.5V32.5V31C59 26.8579 55.6421 23.5 51.5 23.5H48.5ZM38 34H36.5L36.5 40H38V34ZM48.5 34.375C48.5 35.4105 47.6605 36.25 46.625 36.25C45.5895 36.25 44.75 35.4105 44.75 34.375C44.75 33.3395 45.5895 32.5 46.625 32.5C47.6605 32.5 48.5 33.3395 48.5 34.375ZM53.375 36.25C54.4105 36.25 55.25 35.4105 55.25 34.375C55.25 33.3395 54.4105 32.5 53.375 32.5C52.3395 32.5 51.5 33.3395 51.5 34.375C51.5 35.4105 52.3395 36.25 53.375 36.25ZM62 34H63.5V40H62V34Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_bd"
          x="0"
          y="-14"
          width="100"
          height="114"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="9" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.164706 0 0 0 0 0.545098 0 0 0 0 0.94902 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="effect1_backgroundBlur" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SupportIconLogin
