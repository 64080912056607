import React from "react";
function CallIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#0971BD" />
      <path
        d="M17.34 14.2533C16.52 14.2533 15.7267 14.12 14.9867 13.88C14.7533 13.8 14.4933 13.86 14.3133 14.04L13.2667 15.3533C11.38 14.4533 9.61333 12.7533 8.67333 10.8L9.97333 9.69333C10.1533 9.50667 10.2067 9.24667 10.1333 9.01333C9.88667 8.27333 9.76 7.48 9.76 6.66C9.76 6.3 9.46 6 9.1 6H6.79333C6.43333 6 6 6.16 6 6.66C6 12.8533 11.1533 18 17.34 18C17.8133 18 18 17.58 18 17.2133V14.9133C18 14.5533 17.7 14.2533 17.34 14.2533Z"
        fill="white"
      />
    </svg>
  );
}

export default CallIcon;
