import React from 'react'
import styled from 'styled-components'

import Chip from '@components/Chip'

const HomeWorkCommentContainerWrapper = styled.div`
  border-radius: 20px;
  background-color: #dbe2ed;
  padding: 15px;
`

const HomeWorkCommentContainer = styled.div`
  background-color: #dbe2ed;
  background-image: -webkit-linear-gradient(right, transparent, transparent 1px, #dbe2ed 1px),
    -webkit-linear-gradient(top, transparent, transparent 29px, #90a4bd 0);
  background-size: 3px 1px, 94% 30px;
  background-attachment: local;
  box-sizing: border-box;
  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};
  line-height: 30px;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
  overflow-y: auto;
`

const Container = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: min-content;
  flex-grow: 1;
`

const TeacherChipWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`

const StudentViewHomeWorkComment = ({
  comment,
  teacherName,
  teacherAvatar,
  handleClick
}: {
  comment: string
  teacherName?: string
  teacherAvatar?: string
  handleClick ?: (e:any)=>void
}) => (
  <Container>
    {teacherName && (
      <TeacherChipWrapper>
        <Chip name={teacherName} img={teacherAvatar} color="rgba(194, 207, 224, 0.5)"  onClick={handleClick}/>
      </TeacherChipWrapper>
    )}
    <HomeWorkCommentContainerWrapper>
      <HomeWorkCommentContainer>{comment}</HomeWorkCommentContainer>
    </HomeWorkCommentContainerWrapper>
  </Container>
)

export { StudentViewHomeWorkComment }
