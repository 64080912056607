import * as React from 'react'

const UncheckedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="14" height="14" rx="3" stroke="#D5D5D5" strokeWidth="2" />
    </svg>
  )
}

export default UncheckedIcon
