import { profileReducers } from './profile/reducers'
import { AnyAction, combineReducers } from 'redux'
import { calendarReducers } from './calendar/reducers'
import { syncReducers } from './synchronization/reducers'
import { personalReducers } from './personal/reducers'
import { classroomReducers } from './classroom/reducers'
import { homeworkReducer } from './homework/reducer'
import { lessonPreviewReducer } from './lessonPreview/reducers'
import authReducers from './auth/reducers'
import coursesReducer from '../redux/reducers/coursesReducer'
import courseReducer from '../redux/reducers/courseReducer'
import lessonReducer from '../redux/reducers/lessonReducer'
import testsReducer from '../redux/reducers/testsReducer'
import { ThunkDispatch } from 'redux-thunk'
import { videoPlayerReducer } from './videoPlayer/reducer'
import { wordsReducer } from './words/reducers'
import { chatReducers } from './chat/reducers'
import { factoryReducer } from './factory/reducer'
import { TestReducer } from './tests/reducer'
import { individualPlanReducer } from './individualPlan/reducer'

export const rootReducer = combineReducers({
  authStatus: authReducers,
  profile: profileReducers,
  calendar: calendarReducers,
  sync: syncReducers,
  personal: personalReducers,
  classroom: classroomReducers,
  homework: homeworkReducer,
  videoPlayer: videoPlayerReducer,
  courses: coursesReducer,
  course: courseReducer,
  lessonPreview: lessonPreviewReducer,
  lesson: lessonReducer,
  words: wordsReducer,
  chat: chatReducers,
  factory: factoryReducer,
  tests: testsReducer,
  test: TestReducer,
  individualPlan: individualPlanReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>
