import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/remote-config'

import ReduxSagaFirebase from 'redux-saga-firebase'
import { API_PATHS, FIREBASE_CONFIG } from '../utils/constants.ts'

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG)
const sagaFirebase = new ReduxSagaFirebase(firebaseApp).firestore
const authRef = firebase.auth()
const firestoreRef = firebase.firestore()
const storageRef = firebase.storage()
const database = firebase.database()
const remoteConfig = firebase.remoteConfig()
const functionsRef = firebase.functions()
const firebaseAnalytics = firebase.analytics()
const firebaseTimestamp = firebase.firestore.Timestamp
// *** collection refs ***
const classroomsRef = firestoreRef.collection(API_PATHS.classrooms)
const promotionsRef = firestoreRef.collection(API_PATHS.promotions)
const usersRef = firestoreRef.collection(API_PATHS.users)
const libraryRef = firestoreRef.collection(API_PATHS.library)
const studentsRef = firestoreRef.collection(API_PATHS.students)
const homeworkRef = firestoreRef.collection(API_PATHS.homeworks)
const managersRef = firestoreRef.collection(API_PATHS.managers)
const salesManagersRef = firestoreRef.collection(API_PATHS.salesManagers)
const paymentsRef = firestoreRef.collection(API_PATHS.payments)
const publishedCoursesRef = firestoreRef.collection(API_PATHS.publishedCourses)
const activeCoursesRef = firestoreRef.collection(API_PATHS.activeCourses)
const feedbackRef = firestoreRef.collection(API_PATHS.feedback)
const fbgoalRef = firestoreRef.collection(API_PATHS.fbgoal)
const testsRef = firestoreRef.collection(API_PATHS.tests)

const requisitesRef = firestoreRef.collection(API_PATHS.requisites)

const courseRef = firestoreRef.collection(API_PATHS.courses)

const promoCodesRef = firestoreRef.collection(API_PATHS.promoCodes)
const packagesRef = firestoreRef.collection(API_PATHS.packages)
const timestampRef = firestoreRef.Timestamp
const configRef = firestoreRef.collection(API_PATHS.config)
const chatsRef = firestoreRef.collection(API_PATHS.chats)
const exercisesRef = firestoreRef.collection(API_PATHS.exercises)
const cardsRef = firestoreRef.collection(API_PATHS.cards)
const individualPlansRef = firestoreRef.collection(API_PATHS.individualPlans)

export {
  authRef,
  firestoreRef,
  classroomsRef,
  functionsRef,
  usersRef,
  storageRef,
  database,
  libraryRef,
  studentsRef,
  homeworkRef,
  managersRef,
  salesManagersRef,
  promotionsRef,
  paymentsRef,
  promoCodesRef,
  packagesRef,
  requisitesRef,
  feedbackRef,
  firebaseAnalytics,
  firebaseTimestamp,
  courseRef,
  sagaFirebase,
  publishedCoursesRef,
  activeCoursesRef,
  configRef,
  chatsRef,
  timestampRef,
  cardsRef,
  exercisesRef,
  fbgoalRef,
  testsRef,
  remoteConfig,
  individualPlansRef,
}
