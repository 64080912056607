import { IHomework, ILessonHomework } from '@models/IHomework'

export const SET_HOMEWORKS = 'SET_HOMEWORKS'
export const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK'
export const START_FETCHING = 'START_FETCHING'
export const FAILED_FETCHING = 'FAILED_FETCHING'
export const SUCCESS_FETCHING = 'SUCCESS_FETCHING'
export const STUDENT_COUNT_HOMEWORKS = 'STUDENT_COUNT_HOMEWORKS'
export const STUDENT_FINISHED_COUNT_HOMEWORKS = 'STUDENT_COUNT_HOMEWORKS'
export const TEACHER_COUNT_HOMEWORKS = 'TEACHER_COUNT_HOMEWORKS'
export const SET_MINICOURSE = 'SET_MINICOURSE'
export const SET_LESSON_FEEDBACK = 'SET_LESSON_FEEDBACK'
export const REMOVE_HOMEWORK = 'REMOVE_HOMEWORK'

export interface SetHomeworksAction {
  type: typeof SET_HOMEWORKS
  payload: (ILessonHomework | IHomework)[]
}

export interface UpdateHomeworkAction {
  type: typeof UPDATE_HOMEWORK
  payload: Partial<ILessonHomework | IHomework>
}

export interface StartFetchingHomeworks {
  type: typeof START_FETCHING
}

export interface FailedFetchingHomeworks {
  type: typeof FAILED_FETCHING
  payload: string
}

export interface SuccessFetchingHomeworks {
  type: typeof SUCCESS_FETCHING
}

export interface StudentCountHomerWorks {
  type: typeof STUDENT_COUNT_HOMEWORKS
  payload: number
}

export interface TeacherCountHomerWorks {
  type: typeof TEACHER_COUNT_HOMEWORKS
  payload: number
}

export interface SetMiniCourse {
  type: typeof SET_MINICOURSE
  payload: any
}

export interface SetLessonFeedback {
  type: typeof SET_LESSON_FEEDBACK
  payload: any
}

export interface RemoveHomework {
  type: typeof REMOVE_HOMEWORK
  payload: any
}

export type HomeworkActionTypes =
  | SetHomeworksAction
  | UpdateHomeworkAction
  | StartFetchingHomeworks
  | FailedFetchingHomeworks
  | SuccessFetchingHomeworks
  | StudentCountHomerWorks
  | TeacherCountHomerWorks
  | SetMiniCourse
  | SetLessonFeedback
  | RemoveHomework
