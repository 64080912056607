import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { CALENDAR_EVENT_TYPES } from '@utils/constants'

import { AppDispatch, RootState } from '@store'
import { updateEvent, deleteEvent } from '@servise/calendarAPI'

import { IEvent, IProfile } from '@models'

import { TeacherButton as Button, Checkbox } from '@components'
import { CancelModalContainer } from './styles'
import { sendCalendarNotify, sendMessageTrialEvent } from '@servise/webhookAPI'
import { calendarUtil } from '@utils/calendarUtil'

type PropsType = {
  cancelHandler: () => void
  teacherId: string
  argEvent: any
  timeZone: string
}

export default function(props: PropsType) {
  const [currentLesson, setCurrentLesson] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [calendarEvent, setCalendarEvent] = useState<IEvent>({})
  const events = useSelector((state: RootState) => state.calendar.events)
  const students = useSelector((state: RootState) => state.classroom.students)
  const dispatch: AppDispatch = useDispatch()
  const profile = useSelector((state: RootState) => state.profile.profile as IProfile)
  const profileTeacher = useSelector((state: RootState) => {
    return state.personal.teachers.find(teacher => teacher.id === props.teacherId)
  })

  moment.tz.setDefault(props.timeZone)

  useEffect(() => {
    let newEvent: IEvent = {}
    newEvent = events.find(item => item.eventID === props.argEvent.event.extendedProps.eventID)

    newEvent = { ...newEvent, teacherId: props.teacherId }
    setCalendarEvent(newEvent)
  }, [events, props.argEvent.event.extendedProps.eventID, props.teacherId, students])

  const handleCancelLesson = () => {
    setIsLoading(true)
    const studentData = students.find(item => {
      if (item.id === calendarEvent.studentId) {
        return { ...item, name: `${item.name} ${item.lastName}` }
      }
    })
    const teacherName =
      profileTeacher && profileTeacher.id
        ? profileTeacher.name
        : `${profile.name} ${profile.lastName}`

    const teacherData = profileTeacher && profileTeacher.id ? profileTeacher : profile

    const eventMsk = calendarUtil.convertToMSK(calendarEvent.rrule.dtstart)

    if (calendarEvent.type === CALENDAR_EVENT_TYPES.TRIAL) {
      sendMessageTrialEvent({
        name: `${studentData.name} ${studentData.lastName}`,
        teacher: teacherName,
        email: studentData.email,
        date: moment(eventMsk).format('DD.MM.YYYY'),
        time: moment(eventMsk).format('HH:mm'),
        type: 'Cancel',
        locale: studentData.lang,
        teacher_chat_id: teacherData?.notifications?.cid,
        student_chat_id: studentData?.notifications?.cid,
        telegram: teacherData?.telegram,
      })
    } else {
      sendCalendarNotify({
        studentEmail: studentData.email,
        teacherEmail: teacherData.email,
        studentId: calendarEvent.studentId,
        teacherId: calendarEvent.teacherId,
        studentName: studentData.name,
        teacherName: teacherName,
        date: moment(props.argEvent.event.start).format('DD.MM.YYYY'),
        time: moment(props.argEvent.event.start).format('HH:mm'),
        type: 'Cancel',
        p: calendarEvent.type === CALENDAR_EVENT_TYPES.ONCE ? 'once' : 'regular',
        locale: studentData.lang,
        teacher_chat_id: teacherData?.notifications?.cid,
        student_chat_id: studentData?.notifications?.cid,
        telegram: teacherData?.telegram,
      })
    }
    if (currentLesson && calendarEvent.rrule?.count > 1) {
      const date = props.argEvent.event.start

      dispatch(
        updateEvent(
          {
            ...calendarEvent,
          },
          null,
          date
        )
      ).then(() => {
        setIsLoading(false)
        props.cancelHandler()
      })
    } else {
      const diffWeeks = moment(moment(props.argEvent.event.start)).diff(
        moment(calendarEvent.rrule.dtstart),
        'weeks'
      )

      if (diffWeeks > 0) {
        dispatch(
          updateEvent(
            {
              ...calendarEvent,
              rrule: { ...calendarEvent.rrule, count: diffWeeks },
            },
            null,
            null
          )
        )
          .then(() => {
            setIsLoading(false)
            props.cancelHandler()
          })
          .catch(() => {})
      } else {
        dispatch(deleteEvent({ event: calendarEvent }))
          .then(() => {
            setIsLoading(false)
            props.cancelHandler()
          })
          .catch(() => {})
      }
    }
  }

  return (
    <CancelModalContainer>
      <header>Отменить урок</header>
      <main>
        <Checkbox
          type="radio"
          name="currentLesson"
          tip="только этот урок"
          className="checkbox"
          checked={currentLesson}
          onChange={() => {
            setCurrentLesson(true)
          }}
        />
        <Checkbox
          type="radio"
          name="currentLesson"
          tip="этот и все последующие уроки"
          className="checkbox"
          checked={!currentLesson}
          onChange={() => {
            setCurrentLesson(false)
          }}
        />
      </main>

      <footer>
        <Button
          children="Отменить"
          disabled={calendarEvent.studentId === undefined}
          loading={isLoading}
          bgWhite
          whiteTheme
          onClick={handleCancelLesson}
        />

        <button
          className="mt-20 bold-text"
          children="Передумать"
          type="button"
          onClick={props.cancelHandler}
        />
      </footer>
    </CancelModalContainer>
  )
}
