import React from 'react'

export default function VolumeIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57489 3.07403C8.83474 3.19892 9 3.4617 9 3.75001V14.25C9 14.5383 8.83474 14.8011 8.57489 14.926C8.31504 15.0509 8.00661 15.0158 7.78148 14.8357L4.23691 12H1.5C1.08579 12 0.75 11.6642 0.75 11.25V6.75001C0.75 6.33579 1.08579 6.00001 1.5 6.00001H4.23691L7.78148 3.16436C8.00661 2.98425 8.31504 2.94914 8.57489 3.07403ZM7.5 5.31048L4.96852 7.33566C4.83554 7.44205 4.6703 7.50001 4.5 7.50001H2.25V10.5H4.5C4.6703 10.5 4.83554 10.558 4.96852 10.6644L7.5 12.6895V5.31048Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7722 3.1671C14.0652 2.87425 14.5401 2.87432 14.8329 3.16726C16.3795 4.71436 17.2484 6.81241 17.2484 9.00001C17.2484 11.1876 16.3795 13.2857 14.8329 14.8328C14.5401 15.1257 14.0652 15.1258 13.7722 14.8329C13.4793 14.5401 13.4792 14.0652 13.7721 13.7723C15.0375 12.5064 15.7484 10.7899 15.7484 9.00001C15.7484 7.21015 15.0375 5.49357 13.7721 4.22776C13.4792 3.93482 13.4793 3.45995 13.7722 3.1671ZM11.1247 5.8146C11.4177 5.52175 11.8926 5.52182 12.1854 5.81476C13.029 6.65863 13.5029 7.80302 13.5029 8.99626C13.5029 10.1895 13.029 11.3339 12.1854 12.1778C11.8926 12.4707 11.4177 12.4708 11.1247 12.1779C10.8318 11.8851 10.8317 11.4102 11.1246 11.1173C11.687 10.5547 12.0029 9.79175 12.0029 8.99626C12.0029 8.20077 11.687 7.43784 11.1246 6.87526C10.8317 6.58232 10.8318 6.10745 11.1247 5.8146Z"
        fill={props.color}
      />
    </svg>
  )
}
