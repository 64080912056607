import styled from 'styled-components'

export const ContactsMain = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`

export const ContactsTitle = styled.h5`
  font-weight: 700;
  font-size: 42px;
  margin-bottom: 90px;
`

export const ContactItem = styled.p<{ contact?: boolean }>`
  font-weight: ${props => (props.contact ? 700 : 300)};
  font-size: 18px;
`
