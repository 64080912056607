import * as React from 'react'

const CheckedIcon = ({ color = '#1556CF' }: { color?: string }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill={color} />
      <path
        d="M6.5002 9.85761L4.52141 7.87883L4.4002 7.75761L4.27898 7.87883L3.57898 8.57883L3.45776 8.70005L3.57898 8.82127L6.37898 11.6213L6.5002 11.7425L6.62141 11.6213L12.6214 5.62127L12.7426 5.50005L12.6214 5.37883L11.9214 4.67883L11.8002 4.55761L11.679 4.67883L6.5002 9.85761Z"
        fill="white"
        stroke="white"
        strokeWidth="0.342857"
      />
    </svg>
  )
}

export default CheckedIcon
