import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { RadioGroup } from '@material-ui/core'
import { LEVELS } from '@utils/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step1 = ({
  level,
  onChange,
}: {
  level?: string
  onChange: (data: { name: string; value: any }) => void
}) => {
  const { t } = useTranslation()
  return (
    <RadioGroup
      aria-label="level"
      name="level"
      value={level}
      onChange={e => onChange({ name: 'level', value: e.target.value })}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30,
        marginRight: 30,
      }}
    >
      {Object.keys(LEVELS).map(key => (
        <CustomMaterialRadioButton
          name={LEVELS[key]}
          label={`${LEVELS[key]} - ${t('LEVELS.' + LEVELS[key])}`}
          key={key}
        />
      ))}
    </RadioGroup>
  )
}

export default Step1
