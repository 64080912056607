import React from 'react'

export default function LogoutIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      {' '}
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 6C7.10217 6 6.72064 6.15804 6.43934 6.43934C6.15804 6.72064 6 7.10217 6 7.5V28.5C6 28.8978 6.15804 29.2794 6.43934 29.5607C6.72064 29.842 7.10218 30 7.5 30H13.5C14.3284 30 15 30.6716 15 31.5C15 32.3284 14.3284 33 13.5 33H7.5C6.30653 33 5.16193 32.5259 4.31802 31.682C3.47411 30.8381 3 29.6935 3 28.5V7.5C3 6.30653 3.47411 5.16193 4.31802 4.31802C5.16193 3.47411 6.30653 3 7.5 3H13.5C14.3284 3 15 3.67157 15 4.5C15 5.32843 14.3284 6 13.5 6H7.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9393 9.43934C23.5251 8.85355 24.4749 8.85355 25.0607 9.43934L32.5607 16.9393C33.1464 17.5251 33.1464 18.4749 32.5607 19.0607L25.0607 26.5607C24.4749 27.1464 23.5251 27.1464 22.9393 26.5607C22.3536 25.9749 22.3536 25.0251 22.9393 24.4393L29.3787 18L22.9393 11.5607C22.3536 10.9749 22.3536 10.0251 22.9393 9.43934Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18C12 17.1716 12.6716 16.5 13.5 16.5H31.5C32.3284 16.5 33 17.1716 33 18C33 18.8284 32.3284 19.5 31.5 19.5H13.5C12.6716 19.5 12 18.8284 12 18Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
