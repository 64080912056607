import firebase from 'firebase/app'
import { put, select, takeEvery, call, all } from 'redux-saga/effects'

import {
  GET_COURSES,
  GET_COURSES_FULFILLED,
  GET_COURSES_REJECTED,
  CREATE_COURSE,
  CREATE_COURSE_FULFILLED,
  CREATE_COURSE_REJECTED,
  DELETE_COURSE,
  DELETE_COURSE_FULFILLED,
  DELETE_COURSE_REJECTED,
  DUPLICATE_COURSE,
  DUPLICATE_COURSE_FULFILLED,
  DUPLICATE_COURSE_REJECTED,
  GET_LESSONS_LENGTH,
  GET_LESSONS_LENGTH_FULFILLED,
  GET_LESSONS_LENGTH_REJECTED,
  PUBLISH_COURSE,
  PUBLISH_COURSE_FULFILLED,
  PUBLISH_COURSE_REJECTED,
  TRANSFORM_TO_MINI_COURSE,
  TRANSFORM_TO_MINI_COURSE_FULFILLED,
  TRANSFORM_TO_MINI_COURSE_REJECTED,
  MODERATION,
  MODERATION_FULFILLED,
  MODERATION_REJECTED,
  DIVERTED,
  DIVERTED_FULFILLED,
  DIVERTED_REJECTED,
  REMOVE_PUBLISH,
  REMOVE_PUBLISH_FULFILLED,
  REMOVE_PUBLISH_REJECTED,
} from '@types/courses'

import { sagaFirebase as firestore, firestoreRef, database } from '@configs/firebase'
import { COURSES_STATUS } from '@utils/constants'
import { logger } from '@utils/ConsoleLogger'

function* getCourses() {
  try {
    const coursesSnapshot = yield call(firestore.getCollection, 'courses')
    const courses = coursesSnapshot.docs.map(course => ({
      id: course.id,
      ...course.data(),
    }))
    yield put({
      type: GET_COURSES_FULFILLED,
      payload: courses.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return 1
        }
        if (a.timestamp > b.timestamp) {
          return -1
        }
        return 0
      }),
    })
  } catch (error) {
    yield put({
      type: GET_COURSES_REJECTED,
      payload: error,
    })
  }
}

function* createCourse({
  payload: {
    image = null,
    description = '',
    name = '',
    timestamp,
    isMini = false,
    createdBy = '',
    status = '',
  },
}) {
  try {
    const { id: courseId } = yield call(firestore.addDocument, 'courses', {
      image,
      description,
      name,
      timestamp,
      isMini,
      createdBy,
      status,
    })
    yield call(getCourses)
    yield put({
      type: CREATE_COURSE_FULFILLED,
      payload: {
        id: courseId,
        image,
        description,
        name,
        isMini,
        createdBy,
      },
    })
  } catch (error) {
    yield put({
      type: CREATE_COURSE_REJECTED,
      payload: error,
    })
  }
}

function* transformToMiniCourse({ payload: { id } }) {
  try {
    yield call(firestore.updateDocument, `courses/${id}`, {
      isMini: true,
    })
    yield put({
      type: TRANSFORM_TO_MINI_COURSE_FULFILLED,
      payload: { id },
    })
  } catch (error) {
    yield put({
      type: TRANSFORM_TO_MINI_COURSE_REJECTED,
      payload: error,
    })
  }
}

function* deleteCourse({ payload: { id } }) {
  try {
    // TODO: split this behaviour into smaller batches or move to the server
    const batch = firestoreRef.batch()
    const courseSnapshot = yield call(firestore.getDocument, `publishedCourses/${id}`)
    const relatedStudents = courseSnapshot.data()?.students
    if (relatedStudents && relatedStudents.length) {
      for (let studentId of relatedStudents) {
        const studentSnap = yield call(firestore.getDocument, `students/${studentId}`)
        const student = studentSnap.data()
        if (student?.currentCourseId === id) {
          batch.update(firestoreRef.collection('students').doc(studentId), {
            currentCourseId: null,
          })
        }
        const courseRef = firestoreRef
          .collection('students')
          .doc(studentId)
          .collection('courses')
          .doc(id)
        batch.delete(courseRef)
        const lessonsSnap = yield call(
          firestore.getCollection,
          `students/${studentId}/courses/${id}/lessons`
        )
        for (let lessonDoc of lessonsSnap.docs) {
          batch.delete(courseRef.collection('lessons').doc(lessonDoc.id))
          const sketchSnaps = yield call(
            firestore.getCollection,
            `students/${studentId}/courses/${id}/lessons/${lessonDoc.id}/sketches`
          )
          sketchSnaps.docs.forEach(({ id: sketchId }) =>
            batch.delete(courseRef.collection(`lessons/${lessonDoc.id}/sketches`).doc(sketchId))
          )
        }
        yield database.ref(`courses/${studentId}/${id}`).remove()
      }
    }
    batch.delete(firestoreRef.collection('courses').doc(id))
    batch.delete(firestoreRef.collection('publishedCourses').doc(id))
    batch.delete(firestoreRef.collection('activeCourses').doc(id))
    yield batch.commit()
    const lessonsSnapshot = yield call(firestore.getCollection, `courses/${id}/lessons`)
    yield all(
      lessonsSnapshot.docs.map(({ id: lessonId }) =>
        call(firestore.deleteDocument, `courses/${id}/lessons/${lessonId}`)
      )
    )
    const pubLessonsSnapshot = yield call(firestore.getCollection, `publishedCourses/${id}/lessons`)
    yield all(
      pubLessonsSnapshot.docs.map(({ id: lessonId }) =>
        call(firestore.deleteDocument, `publishedCourses/${id}/lessons/${lessonId}`)
      )
    )
    const activePubLessonsSnapshot = yield call(
      firestore.getCollection,
      `activeCourses/${id}/lessons`
    )
    yield all(
      activePubLessonsSnapshot.docs.map(({ id: lessonId }) =>
        call(firestore.deleteDocument, `activeCourses/${id}/lessons/${lessonId}`)
      )
    )
    yield call(getCourses)
    yield put({
      type: DELETE_COURSE_FULFILLED,
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: DELETE_COURSE_REJECTED,
      payload: error,
    })
  }
}

function* duplicateCourse({ payload: { id = 0 } }) {
  try {
    const course = yield call(firestore.getDocument, `courses/${id}`)

    const { id: newCourseId } = yield call(firestore.addDocument, 'courses', {
      ...course.data(),status:''
    })
    const lessonsSnapshot = yield call(firestore.getCollection, `courses/${id}/lessons`)
    const lessons = lessonsSnapshot.docs.map(lesson => ({
      id: lesson.id,
      ...lesson.data(),
    }))
    const batch = firestoreRef.batch()
    for (let l of lessons) {
      batch.set(firestoreRef.collection(`courses/${newCourseId}/lessons`).doc(l.id), l)
    }
    yield batch.commit()
    yield call(getCourses)
    yield put({
      type: DUPLICATE_COURSE_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: DUPLICATE_COURSE_REJECTED,
      payload: error,
    })
  }
}

function* getLessonsLength({ payload: { id = 0 } }) {
  try {
    const lessonsSnapshot = yield call(firestore.getCollection, `courses/${id}/lessons`)
    const lessonsLengthArr = yield select(store => store.courses.lessonsLengthArr)
    yield put({
      type: GET_LESSONS_LENGTH_FULFILLED,
      payload: lessonsLengthArr.length
        ? [
            ...lessonsLengthArr,
            {
              id,
              length: lessonsSnapshot.docs.length,
            },
          ]
        : [
            {
              id,
              length: lessonsSnapshot.docs.length,
            },
          ],
    })
  } catch (error) {
    yield put({
      type: GET_LESSONS_LENGTH_REJECTED,
      payload: error,
    })
  }
}

function* publishCourse({ payload: { id } }) {
  try {
    const courseSnapshot = yield call(firestore.getDocument, `courses/${id}`)
    const course = courseSnapshot.data()
    const lessonsSnapshot = yield call(firestore.getCollection, `courses/${id}/lessons`)
    const lessons = lessonsSnapshot.docs.map(lesson => ({
      id: lesson.id,
      ...lesson.data(),
    }))
    const publishedCourse = {
      ...course,
      status: COURSES_STATUS.PUBLISHED,
      publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }

    const batch = firestoreRef.batch()
    batch[course.publishedAt ? 'update' : 'set'](
      firestoreRef.collection('publishedCourses').doc(id),
      {
        ...publishedCourse,
        lessons: lessons.map(({ description, id, image, name, order, isFree }) => ({
          description,
          id,
          image,
          name,
          order,
          isFree: isFree || false,
        })),
      }
    )
    batch.set(firestoreRef.collection('activeCourses').doc(id), publishedCourse)
    const publishedLessonsSnap = yield call(firestore.getCollection, `activeCourses/${id}/lessons`)
    publishedLessonsSnap.docs.forEach(l => batch.delete(l.ref))
    lessonsSnapshot.docs.forEach(l => {
      batch.set(firestoreRef.collection(`activeCourses/${id}/lessons`).doc(l.id), {
        id: l.id,
        ...l.data(),
      })
    })
    batch.update(firestoreRef.collection('courses').doc(id), {
      status: COURSES_STATUS.PUBLISHED,
      publishedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    yield batch.commit()

    const updatedCourse = yield call(firestore.getDocument, `courses/${id}`)
    yield put({
      type: PUBLISH_COURSE_FULFILLED,
      payload: {
        ...updatedCourse.data(),
        status: COURSES_STATUS.PUBLISHED,
        id: updatedCourse.id,
      },
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: PUBLISH_COURSE_REJECTED,
      payload: error,
    })
  }
}
function* moderation({ payload: { id } }) {
  try {
    const batch = firestoreRef.batch()

    batch.update(firestoreRef.collection('courses').doc(id), {
      status: COURSES_STATUS.MODERATION,
    })

    yield batch.commit()
    const updatedCourse = yield call(firestore.getDocument, `courses/${id}`)
    yield put({
      type: MODERATION_FULFILLED,
      payload: {
        ...updatedCourse.data(),
        status: COURSES_STATUS.MODERATION,
        id: updatedCourse.id,
      },
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: MODERATION_REJECTED,
      payload: error,
    })
  }
}
function* divertedCourse({ payload: { id } }) {
  try {
    const updatedCourse = yield call(firestore.getDocument, `courses/${id}`)
    const batch = firestoreRef.batch()
    batch.update(firestoreRef.collection('courses').doc(id), {
      status:
        updatedCourse.data().status === COURSES_STATUS.CHANGED && updatedCourse.data().publishedAt
          ? COURSES_STATUS.CHANGE_DIVERTED
          : COURSES_STATUS.DIVERTED,
    })
    yield batch.commit()
    yield put({
      type: DIVERTED_FULFILLED,
      payload: {
        ...updatedCourse.data(),
        status:
          updatedCourse.data().status === COURSES_STATUS.CHANGED && updatedCourse.data().publishedAt
            ? COURSES_STATUS.CHANGE_DIVERTED
            : COURSES_STATUS.DIVERTED,
        id: updatedCourse.id,
      },
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: DIVERTED_REJECTED,
      payload: error,
    })
  }
}
function* removePublication({ payload: { id } }) {
  try {
    const batch = firestoreRef.batch()
    batch.update(firestoreRef.collection('courses').doc(id), {
      status:"",
    })
    batch.update(firestoreRef.collection('publishedCourses').doc(id), {
      status:"",
    })
    batch.update(firestoreRef.collection('activeCourses').doc(id),{
      status:"",
    })
    yield batch.commit()
    const updatedCourse = yield call(firestore.getDocument, `courses/${id}`)
    yield put({
      type: REMOVE_PUBLISH_FULFILLED,
      payload: {
        ...updatedCourse.data(),
        status: '',
        id: updatedCourse.id,
      },
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: REMOVE_PUBLISH_REJECTED,
      payload: error,
    })
  }
}

function* watchGetCourses() {
  yield takeEvery(GET_COURSES, getCourses)
}

function* watchCreateCourses() {
  yield takeEvery(CREATE_COURSE, createCourse)
}

function* watchDeleteCourse() {
  yield takeEvery(DELETE_COURSE, deleteCourse)
}

function* watchDuplicateCourse() {
  yield takeEvery(DUPLICATE_COURSE, duplicateCourse)
}

function* watchLessonsLength() {
  yield takeEvery(GET_LESSONS_LENGTH, getLessonsLength)
}

function* watchPublishCourse() {
  yield takeEvery(PUBLISH_COURSE, publishCourse)
}

function* watchTransformToMiniCourse() {
  yield takeEvery(TRANSFORM_TO_MINI_COURSE, transformToMiniCourse)
}
function* watchModeration() {
  yield takeEvery(MODERATION, moderation)
}
function* watchDiverted() {
  yield takeEvery(DIVERTED, divertedCourse)
}
function* watchRemovePublish() {
  yield takeEvery(REMOVE_PUBLISH, removePublication)
}

export default [
  watchGetCourses(),
  watchCreateCourses(),
  watchDeleteCourse(),
  watchDuplicateCourse(),
  watchLessonsLength(),
  watchPublishCourse(),
  watchTransformToMiniCourse(),
  watchModeration(),
  watchDiverted(),
  watchRemovePublish(),
]
