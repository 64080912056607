import styled, { css } from 'styled-components';
import { UnderLineInput } from '@styles/elements';

export const LibraryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 0 0 15px;
`;
export const ClearButton = styled.button`
margin: 5px 0px 0px 90px;
position: relative;
`;

export const SearchContainer = styled.div`
  width: 360px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #c2cfe0;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;

  &:focus-within {
    svg {
      fill: ${(props) => props.theme.colors.orange};
    }
  }
`;
export const SearchInput = styled(UnderLineInput)`
  padding: 0 0 0 5px;
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #414752;
  ::placeholder {
    color: #90a4bd;
    opacity: 0.5;
    font-size: 15px;
  }
`;

export const SearchInputIcon = styled.div<{ active?: boolean }>`
    height: 30px;
    background: #ffffff;
    // border: 1px solid #c2cfe0;
    box-sizing: border-box;
    border-radius: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  svg {
    fill: ${(props) => (props.active ? props.theme.colors.orange : props.theme.colors.bgGray)};
  }
`;

export const TableContainer = styled.div``;

export const Table = styled.table``;
export const TableBody = styled.tbody`
  display: block;
  font-size: 24px;
  color: ${(props) => props.theme.colors.textDark};
  height: 63vh;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: Open Sans;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.bgLightSide1};
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }
`;
export const TableHead = styled.thead`
  width: calc(100% - 5px);
  color: ${(props) => props.theme.colors.bgGray};
`;

export const TableRow = styled.tr<{ header?: boolean }>`
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: ${(props) => (props.header ? `2px solid ${props.theme.colors.bgLightSide}` : 'none')};

  ${(props) =>
    !props.header &&
    css`
      :hover {
        background-color: ${(props) => props.theme.colors.bgLightSide1};
      }
    `}
`;
export const TableCell = styled.td<{ left?: boolean }>`
  height: 50px;
  text-align: ${(props) => (props.left ? 'left' : 'center')};
`;
export const TableItemContent = styled.div`
  width: 40px;
  height: 40px;
`;
export const TableItemIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const TableSortLable = styled.button<{ active?: boolean; order?: string; left?: boolean }>`
  display: flex;
  font-weight: bold;
  font-size: 18px;
  margin: ${(props) => (props.left ? '0' : 'auto')};
  > svg {
    fill: ${(props) => (props.active ? props.theme.colors.bgGray : 'none')};
    transform: ${(props) => (props.order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  :hover {
    svg {
      fill: ${(props) => props.theme.colors.bgGray};
    }
  }
`;
