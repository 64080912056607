import React from 'react'
function StudentPossibleIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4429 6.10729C17.8005 5.96424 18.1995 5.96424 18.5571 6.10729L33.5571 12.1073C34.1266 12.3351 34.5 12.8866 34.5 13.5V22.5C34.5 22.7739 34.4266 23.0307 34.2984 23.2517C33.4592 22.716 32.5134 22.3326 31.5 22.1404V15.7155L28.5 16.9155V22.1404C27.4046 22.3482 26.3882 22.7793 25.5 23.3847V18.1156L18.5571 20.8927C18.1995 21.0358 17.8005 21.0358 17.4429 20.8927L10.5 18.1156V23.9999C10.5 24.3375 10.8049 25.0893 12.3069 25.8402C13.7386 26.5561 15.7859 26.9999 18 26.9999C19.8706 26.9999 21.622 26.6832 22.9839 26.1532C22.4217 27.1764 22.0768 28.3359 22.0114 29.5695C20.7401 29.8545 19.3742 29.9999 18 29.9999C15.4402 29.9999 12.9091 29.4955 10.9652 28.5235C9.09151 27.5867 7.5 26.0493 7.5 23.9999V16.9156L2.44291 14.8927C1.87343 14.6649 1.5 14.1134 1.5 13.5C1.5 12.8866 1.87343 12.3351 2.44291 12.1073L17.4429 6.10729ZM26.4079 14.5213C26.4313 14.5112 26.4549 14.5018 26.4788 14.4929L28.9611 13.5L18 9.11555L7.03887 13.5L9.52116 14.4929C9.5451 14.5018 9.56874 14.5112 9.59208 14.5213L18 17.8844L26.4079 14.5213Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 36C33.3137 36 36 33.3137 36 30C36 26.6863 33.3137 24 30 24C26.6863 24 24 26.6863 24 30C24 33.3137 26.6863 36 30 36ZM29.3365 30.2204C29.2197 30.5215 29.1614 30.9337 29.1614 31.457H30.6794C30.6865 31.1846 30.7236 30.9606 30.7909 30.7849C30.8581 30.6057 30.9784 30.4319 31.1518 30.2634L31.7622 29.6882C32.0205 29.4337 32.2081 29.1846 32.3248 28.9409C32.4416 28.6971 32.5 28.4265 32.5 28.129C32.5 27.448 32.2824 26.9229 31.8471 26.5538C31.4154 26.1846 30.805 26 30.0159 26C29.2233 26 28.6076 26.2007 28.1688 26.6022C27.73 27 27.5071 27.5573 27.5 28.2742H29.2941C29.3011 28.0054 29.3701 27.7921 29.5011 27.6344C29.632 27.4767 29.8036 27.3978 30.0159 27.3978C30.4759 27.3978 30.7059 27.6685 30.7059 28.2097C30.7059 28.4319 30.6369 28.6362 30.4989 28.8226C30.3645 29.0054 30.1663 29.2079 29.9045 29.4301C29.6426 29.6523 29.4533 29.9158 29.3365 30.2204ZM29.2251 32.4409C29.0375 32.6093 28.9437 32.8262 28.9437 33.0914C28.9437 33.3566 29.0375 33.5753 29.2251 33.7473C29.4161 33.9158 29.6532 34 29.9363 34C30.2194 34 30.4547 33.9158 30.6423 33.7473C30.8333 33.5753 30.9289 33.3566 30.9289 33.0914C30.9289 32.8262 30.8333 32.6093 30.6423 32.4409C30.4547 32.2688 30.2194 32.1828 29.9363 32.1828C29.6532 32.1828 29.4161 32.2688 29.2251 32.4409Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default StudentPossibleIcon
