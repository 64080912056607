import { HomeworkStatuses } from '@models/IHomework'
import styled from 'styled-components'

export const FlexWrapper = styled.div`
  display: flex;
`

export const Container = styled.div<{ isNew: boolean; status: string }>`
  width: 600px;

  background: ${props => (props.isNew ? '#FFF2E7' : '#FFFFFF')};
  box-shadow: 0 2px 10px
    ${props => (props.isNew ? 'rgba(255, 146, 45, 0.5)' : 'rgba(0, 0, 0, 0.08)')};
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
`

export const LessonInformation = styled.div`
  flex: 1;
`

export const LessonName = styled.h2`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #414752;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 15px;
`

export const PagesProgressHint = styled.span`
  color: #90a4bd;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
`

export const PagesCount = styled.span`
  color: #414752;
  font-size: 14px;
  font-weight: 600;
`

export const ActionButton = styled.button`
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background: #4284ff;
  border-radius: 20px;
  height: 40px;
  min-width: 120px;
`

export const WillNotButton = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  height: 40px;
  border: 1px solid #c2cfe0;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin-right: 10px;
`

export const ArchiveButton = styled.button`
  font-size: 14px;
  color: #90a4bd;
  text-decoration: underline;
`

export const DoneLabel = styled.div`
  background: #27ae60;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 4px 10px;
  margin-bottom: 5px;
  user-select: none;
`
