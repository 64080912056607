import React, { useState, useCallback, useMemo, useEffect, memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editCourse, getLessons, resetLessons } from '@actions'
import Navbar from '@components/Navbar'
import Tabs from '@components/common/Tabs'
import CourseTab from './courseTab'
import MiniCourseSettingsTab from './miniCourseSettingsTab'
import rand from '@utils/rand'
import EditBig from '@assets/images/Constructor/edit-big.svg'
import Pattern1 from '@assets/images/Constructor/pattern1.jpg'
import Pattern2 from '@assets/images/Constructor/pattern2.jpg'
import Pattern3 from '@assets/images/Constructor/pattern3.jpg'
import EditIcon from '@assets/images/blocks/Exercise/edit.svg'
import PlusIcon from '@assets/images/Constructor/plus-progress.svg'
import { authRef, storageRef } from '@configs/firebase'
import { getManagerProfile, getProfile } from '../../servise/profileAPI'
import { InitialProgress, NAVIGATOR } from '@utils/constants'
import NavBar from '@components/Dashboard/Nav'
import { debounce, isEqual } from 'lodash'
import { MainModal } from '@components'
import EditCourseProgress from './EditCourseProgress'
import AutosizeInput from 'react-input-autosize'
import { getCachedImage } from '@utils/uncachedImage'

const patterns = [Pattern1, Pattern2, Pattern3]

const Course = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const history = useHistory()
  const { courseId } = useParams()
  const dispatch = useDispatch()
  const editCourseRef = useRef(null)
  const nameCourseRef = useRef(null)

  const [openEditProgress, setOpenEditProgress] = useState(false)
  const [progress, setProgress] = useState(null)
  const handleSetOpenEditProgress = data => {
    setProgress(data)
    setOpenEditProgress(prev => !prev)
  }
  const [imageUrl, setImageUrl] = useState(null)
  const states = useSelector(state => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile,
    currentCourse: state.course.currentCourse,
  }))

  useEffect(() => {
    if (states.authStatus === 'logged on') {
      const uid = authRef.currentUser.uid
      dispatch(getManagerProfile(uid))
        .then(() => {})
        .catch(e => {
          if (e && e.code === 'auth/manager-not-found') {
            dispatch(getProfile(uid))
              .then(() => {})
              .catch(e => {
                history.push(NAVIGATOR.forbidden.path)
              })
          }
        })
    }
  }, [dispatch, history, states.authStatus])

  useEffect(() => {
    dispatch(getLessons({ courseId }))

    return () => {
      setCurrentCourse(null)
      dispatch(resetLessons())
    }
  }, [courseId, dispatch])

  const profile = useSelector(store => store.profile.profile)

  const [currentCourse, setCurrentCourse] = useState(null)

  useEffect(() => {
    const progress = states.currentCourse?.progress
      ? states.currentCourse?.progress
      : InitialProgress

    setCurrentCourse({ ...states.currentCourse, progress })
  }, [states.currentCourse, courseId])

  const [isElVisible, setElVisibility] = useState(null)

  const pattern = useMemo(() => rand(0, 2), [])

  const handleChangeCourseData = useCallback(e => {
    const { name, value } = e.target
    setCurrentCourse(prev => ({
      ...prev,
      [name]: value,
    }))
  }, [])

  const delayedQuery = useCallback(
    debounce(() => {
      if (!currentCourse) return
      if (!isEqual(currentCourse, states.currentCourse)) {
        dispatch(
          editCourse({
            courseId,
            courseData: {
              name: currentCourse.name,
              description: currentCourse.description,
              image: currentCourse.image,
              progress: currentCourse.progress,
            },
          })
        )
      }
    }, 3000),
    [currentCourse?.name, currentCourse?.description, currentCourse?.image, currentCourse?.progress]
  )

  const handleSaveProgress = data => {
    setCurrentCourse(prev => {
      const progress = prev.progress.map(p => (p.type === data.type ? { ...data } : { ...p }))
      return { ...prev, progress }
    })

    setOpenEditProgress(false)
    setProgress(null)
  }

  useEffect(() => {
    delayedQuery()

    return () => delayedQuery.cancel()
  }, [delayedQuery])

  const handleChooseFile = e => {
    let uploadTask = null
    if (e.target.files.length) {
      const file = e.target.files[0]

      uploadTask = storageRef.ref(`image/${file.name}`).put(file)
      uploadTask.on(
        'state_changed',
        () => {},
        () => {},

        () => {
          storageRef
            .ref('image')
            .child(file.name)
            .getDownloadURL()
            .then(image => {
              dispatch(
                editCourse({
                  courseId,
                  courseData: { image },
                })
              )
              setCurrentCourse({
                ...currentCourse,
                image,
              })
            })
        }
      )
    }
  }
  useEffect(() => {
    if (currentCourse?.image) {
      getCachedImage(currentCourse?.image).then(url => setImageUrl(url))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCourse?.image])
  const renderProgressModal = () => {
    return (
      <EditCourseProgress
        progress={progress}
        onClose={handleSetOpenEditProgress}
        onSave={handleSaveProgress}
      />
    )
  }

  return (
    <>
      {profile && (
        <>
          <NavBar />
          <div style={{ marginLeft: 70 }}>
            <Navbar title={t('LessonsRoute.Title')} goBack={() => push('/courses')} />
            {currentCourse && (
              <>
                <div className="course-block-container">
                  <div className="course-edit-container">
                    <div
                      onMouseOver={() => {
                        setElVisibility(true)
                      }}
                      onMouseLeave={() => {
                        setElVisibility(false)
                      }}
                      className="course-image-container"
                    >
                      {isElVisible ? (
                        <div
                          onClick={() => {
                            editCourseRef.current && editCourseRef.current.click()
                          }}
                          className="hover-current-course"
                        >
                          <img src={EditBig} alt="" />
                        </div>
                      ) : null}
                      {!isElVisible ? (
                        <img className="edit-image-course-icon" src={EditIcon} alt="" />
                      ) : null}
                      <input
                        onChange={handleChooseFile}
                        accept="image/*"
                        ref={editCourseRef}
                        type="file"
                        className="edit-course-image"
                      />
                      <img src={imageUrl || patterns[pattern]} alt="" />
                    </div>
                    <div className="ccontenr">
                      <div className="course-description-container">
                        <div className="course-name">
                          <span className="course-name-label">Название курса</span>
                          <div className="course-input-container" style={{ marginBottom: 25 }}>
                            <AutosizeInput
                              ref={nameCourseRef}
                              type="text"
                              name="name"
                              className="course-name"
                              value={currentCourse.name || ''}
                              onChange={handleChangeCourseData}
                            />
                            <img src={EditIcon} alt="" />
                          </div>
                        </div>
                      </div>
                      {!currentCourse.isMini && (
                        <div className="course-description">
                          <span className="course-description-label">Описание курса</span>
                          <div className="course-input-container">
                            <textarea
                              rows={2}
                              className="course-description"
                              name="description"
                              value={currentCourse.description}
                              onChange={handleChangeCourseData}
                            />
                            <img src={EditIcon} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {states.currentCourse && !states.currentCourse?.isMini ? (
                    <div className="course-progress-container">
                      {currentCourse.progress.map((progress, index) => (
                        <div className="progress-container" key={progress.type + index}>
                          <div className="progress-name">{progress.type}</div>

                          <div className="progress-number-lessons">
                            {progress.lessons.map(l => l.order).join(',')}
                          </div>
                          <img
                            style={{ cursor: 'pointer' }}
                            src={PlusIcon}
                            alt=""
                            onClick={() => handleSetOpenEditProgress(progress)}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <Tabs
                  tabs={[
                    {
                      index: 0,
                      component: <CourseTab />,
                      name: t('LessonsRoute.Tab1'),
                    },
                    {
                      index: 1,
                      component: <MiniCourseSettingsTab />,
                      name: t('LessonsRoute.Tab2'),
                    },
                  ].filter(course => course.index !== 1 || currentCourse?.isMini)}
                />
              </>
            )}
          </div>
        </>
      )}

      <MainModal
        open={openEditProgress}
        handleClose={handleSetOpenEditProgress}
        children={renderProgressModal}
        max
      />
    </>
  )
}

export default memo(Course)
