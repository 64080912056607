import styled from 'styled-components'

export const DropdownContainer = styled.div`
  position: relative;
  width: fit-content;
`

export const ChosenOption = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
  padding: 11px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  > img {
    margin-left: 22px;
  }
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  text-align: left;
  background: #ffffff;
  border: 1px solid #c2cfe0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 20px;
  z-index: 9999;
  min-width: 108px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #414752;
  gap: 14px;
`

export const OptionItem = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: 700;
    font-size: 14px;
    color: #4d6084;
  }
`
