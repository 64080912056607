/* eslint-disable react-hooks/exhaustive-deps */
import { IBusinessHours, IProfile, IStudentProfile } from '@models/IProfile'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DefaultAvatar from '@components/Icons/DefaultAvatar.svg'
import * as Styled from './result-teachers.styles'
import AudioPlayerWaveForm from '@components/Student/TeacherCard/AudioPlayerWaveForm'
import { useTranslation } from 'react-i18next'
import { CALENDAR_EVENT_TYPES, INTERESTS, TZ_MSK, WEEKS } from '@utils/constants'
import { IAutoTeacherSelectionFilterData } from '..'
import { IEvent } from '@models/ICalendar'
import moment from 'moment'
import { authRef } from '@configs/firebase'
import { Tooltip } from '@pages/student/student.styles'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import Dropdown from 'rc-dropdown'
import { getTeacherCalendarEvents } from '@servise/calendarAPI'
import { getWeekDay } from '@utils/helpers'
import { getExperienceDescription } from '@utils/countExpYearsHelper'
import { calendarUtil } from '@utils/calendarUtil'

export function getAvailableBusinessHours(
  businessHours: IBusinessHours[],
  teacherBusinessHours: IBusinessHours[],
  teacherTimeZone: string,
  studentTimeZone: string
) {
  let businessHoursTwo = teacherBusinessHours
  if (teacherTimeZone !== studentTimeZone) {
    let tz = parseInt(
      moment
        .tz(studentTimeZone)
        .format('Z')
        .substr(0, 3)
    )

    businessHoursTwo = teacherBusinessHours.map((item, i) => {
      let daysOfWeek = item.daysOfWeek

      let time = i - (3 - tz)

      if (time < 0) {
        time = 24 + time

        daysOfWeek = item.daysOfWeek.map(weekDay => {
          return getWeekDay(false, weekDay)
        })
      } else if (time >= 24) {
        time = time - 24
        daysOfWeek = item.daysOfWeek.map(weekDay => {
          return getWeekDay(true, weekDay)
        })
      }

      return {
        startTime: ('0' + time).slice(-2) + ':' + '00',
        endTime: ('0' + (time + 1)).slice(-2) + ':' + '00',
        daysOfWeek,
      }
    })
  }

  return businessHours
    .map(object1 => {
      const find = businessHoursTwo.find(
        object2 =>
          object1.startTime === object2.startTime &&
          isDaysOfWeek(object1.daysOfWeek, object2.daysOfWeek).length > 0
      )
      if (find) {
        return {
          ...object1,
          daysOfWeek: isDaysOfWeek(object1.daysOfWeek, find.daysOfWeek),
        }
      }

      return { ...object1, daysOfWeek: [] }
    })
    .filter(item => item.daysOfWeek.length > 0)
}

export function isDaysOfWeek(array1: number[], array2: number[]) {
  return array1.filter(item1 => array2.includes(item1 === 7 ? 0 : item1))
}

const TeachersInfoCard = ({
  teacher,
  onChange,
  filterData,
  editDate,
  onClose,
}: {
  teacher: IProfile
  onChange: (data: { name: string; value: any }) => void
  filterData: IAutoTeacherSelectionFilterData
  editDate?: string
  onClose?: () => void
}) => {
  const { t, i18n } = useTranslation()
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const [events, setEvents] = useState<IEvent[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSelectEvent = (dtstart: string, count: number = 50) => {
    if (
      !isMultiple &&
      filterData.selectedEvents.length > 0 &&
      filterData.selectedEvents.filter(event => event.teacher.id === teacher.id).length === 0
    ) {
      return
    }
    if (renderTooltipTime(dtstart) && isMultiple) return

    if (editDate) {
      const findIndex = filterData.selectedEvents.findIndex(
        event => event.event.rrule.dtstart === editDate
      )
      filterData.selectedEvents.splice(findIndex, 1)
    }
    const findIndex = filterData.selectedEvents.findIndex(
      event => event.event.rrule.dtstart === dtstart
    )

    if (findIndex === -1) {
      const byweekday = [
        moment(dtstart)
          .locale('en')
          .format('dd'),
      ]
      const newEvent: { teacher: IProfile; event: IEvent } = {
        teacher,
        event: {
          teacherId: teacher.id,
          studentId: authRef.currentUser.uid,
          isConfirm: true,
          type: count === 50 ? CALENDAR_EVENT_TYPES.REGULAR : CALENDAR_EVENT_TYPES.ONCE,
          title: `${profileData.name} ${profileData?.lastName ? profileData.lastName : ''}`,
          teacherName: `${teacher.name} ${teacher?.lastName}`,
          rrule: {
            freq: 2,
            byweekday,
            dtstart,
            count,
          },
        },
      }
      onChange({ name: 'selectedEvents', value: [...filterData.selectedEvents, newEvent] })
    } else {
      filterData.selectedEvents.splice(findIndex, 1)
      onChange({ name: 'selectedEvents', value: filterData.selectedEvents })
    }
    if (onClose) {
      onClose()
    }
  }

  const defaultDate = useMemo(() => {
    return moment().add(2, 'days')
  }, [])

  const expDesc = useMemo(() => {
    return getExperienceDescription(teacher.teachingExperience, i18n.language, t)
  }, [i18n.language, teacher.teachingExperience, t])

  const selectedEvent = (days: number, startTime: string) => {
    if (editDate) return false
    return !!filterData.selectedEvents.find(
      event =>
        moment(event.event.rrule.dtstart).format() ===
          moment(
            moment(defaultDate)
              .add(days, 'days')
              .format('YYYY-MM-DD') +
              ' ' +
              startTime
          ).format() && event.teacher.id === teacher.id
    )
  }
  const isMultiple = useMemo(() => {
    return filterData.withWho === 'multiple'
  }, [filterData.withWho])

  const renderTooltipTime = useCallback(
    (dtstart: string): React.JSX.Element => {
      const findEvent = filterData.selectedEvents.find(
        event => event.event.rrule.dtstart === dtstart
      )

      if (
        !isMultiple &&
        filterData.selectedEvents.length > 0 &&
        filterData.selectedEvents.filter(event => event.teacher.id === teacher.id).length === 0
      ) {
        return (
          <Styled.TooltipContainer>
            {t('AutoTeacher.TooltipLessonTakenRemoveLesson', {
              teacher: `${filterData.selectedEvents[0].teacher.name} ${filterData.selectedEvents[0].teacher.lastName}`,
            })}
          </Styled.TooltipContainer>
        )
      }

      if (filterData.selectedEvents.length === profileData.balanceCount) {
        return (
          <Styled.TooltipContainer>
            {t('AutoTeacher.TooltipLessonTakenMaxOfBalance', {
              balanceCount: profileData.balanceCount,
            })}
          </Styled.TooltipContainer>
        )
      }
      if (!findEvent || findEvent.teacher.id === teacher.id) return null
      return (
        <Styled.TooltipContainer>
          {t('AutoTeacher.TooltipLessonTakenAnotherTeacher')}
        </Styled.TooltipContainer>
      )

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [filterData.withWho, profileData, filterData.selectedEvents, teacher.id, isMultiple]
  )
  useEffect(() => {
    async function getData() {
      setIsLoading(true)
      const events = await getTeacherCalendarEvents(teacher?.id, profileData.timeZone)
      let allEvents = calendarUtil.getAllEvents(events, profileData.timeZone, false)

      setEvents(allEvents)
      setIsLoading(false)
    }
    getData()
  }, [teacher?.id])
  const studentBusinessHours = useMemo(() => {
    if (!filterData.isSkip) return filterData.studentBusinessHours
    return filterData.studentBusinessHours.map(item => ({
      ...item,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    }))
  }, [filterData.isSkip])

  const businessHours = useMemo(() => {
    let filteredBusinessHours = getAvailableBusinessHours(
      studentBusinessHours,
      teacher.businessHours,
      teacher.timeZone,
      profileData.timeZone
    )

    filteredBusinessHours = filteredBusinessHours.map(item => {
      const daysOfWeek = item.daysOfWeek.filter(day => {
        const diffDays = moment(defaultDate)
          .days(day)
          .diff(defaultDate, 'days')

        const dtstart = moment(
          moment(defaultDate)
            .day(diffDays < 0 ? 7 + day : day)
            .format('YYYY-MM-DD') +
            ' ' +
            item.startTime
        ).format()

        return (
          events.filter(event => {
            const diff = moment(dtstart).diff(moment(event.rrule.dtstart).format(), 'minutes')

            return diff > -60 && diff < 60
          }).length === 0
        )
      })

      return { ...item, daysOfWeek }
    })
    return filteredBusinessHours.filter(item => item.daysOfWeek.length > 0)
  }, [studentBusinessHours, teacher.businessHours, events, defaultDate, profileData])

  if (businessHours.length === 0 || isLoading) return null

  return (
    <Styled.TeacherItemContainer edit={!!editDate}>
      <Styled.TeacherInfoContainer>
        <Styled.TeacherBasicInfo>
          <Styled.TeacherAvatar
            style={{
              backgroundImage: `url(${teacher.avatar ? teacher.avatar : DefaultAvatar})`,
            }}
          />
          <Styled.FullNameLabel>{`${teacher.name}`}</Styled.FullNameLabel>
          <Styled.CountryLabel>{teacher.cityAddress}</Styled.CountryLabel>
        </Styled.TeacherBasicInfo>

        <Styled.AdditionalInfoContainer>
          {teacher.keyFeatures?.length > 0 && expDesc && (
            <Styled.AdditionalInfoItem>
              <Styled.Subtitle>{t('SuperAbilities')}</Styled.Subtitle>
              <Styled.SuperAbilities>
                {teacher.keyFeatures?.map(
                  (val, index) =>
                    val && (
                      <li key={'key-features-index'}>
                        {val.charAt(0).toUpperCase() + val.slice(1)}
                      </li>
                    )
                )}

                <li>{expDesc} </li>
              </Styled.SuperAbilities>
            </Styled.AdditionalInfoItem>
          )}

          {teacher.aboutMe?.length > 0 && (
            <>
              <Styled.Subtitle>{t('AboutMe')}</Styled.Subtitle>
              <Styled.AboutMe>
                {teacher.aboutMe.charAt(0).toUpperCase() + teacher.aboutMe.slice(1)}
              </Styled.AboutMe>
            </>
          )}

          {teacher.audio && (
            <AudioPlayerWaveForm
              audio={teacher.audio}
              isProfile
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
                width: 376,
                marginRight: 20,
              }}
            />
          )}

          {teacher.interests?.length > 0 && (
            <>
              <Styled.Subtitle>{t('Interests')}</Styled.Subtitle>
              <Styled.InterestsContainer>
                {INTERESTS.map(i => {
                  if (!teacher?.interests?.includes(i.id)) return null
                  return (
                    <Styled.InteresItem
                      active={!!profileData?.interests?.includes(i.id)}
                      key={i.id}
                    >
                      {t(i.name)}
                    </Styled.InteresItem>
                  )
                })}
              </Styled.InterestsContainer>
            </>
          )}
        </Styled.AdditionalInfoContainer>
      </Styled.TeacherInfoContainer>
      <Styled.TeacherTimeContainer>
        <Styled.WeekContainer>
          {WEEKS.map((week, index) => {
            const weekDay = moment(defaultDate)
              .add(index, 'days')
              .weekday()

            return (
              businessHours.filter(
                hours =>
                  hours.daysOfWeek.includes(weekDay + 1) &&
                  studentBusinessHours.find(
                    item =>
                      item.startTime === hours.startTime && item.daysOfWeek.includes(weekDay + 1)
                  )
              ).length > 0 && (
                <Styled.DayItem key={week.value}>
                  <Styled.DayItemLabel>
                    {moment(defaultDate)
                      .add(index, 'days')
                      .format('dddd, DD.MM.YYYY')}
                  </Styled.DayItemLabel>

                  <Styled.TimeItems>
                    {businessHours.map(hours => {
                      const dtstart = moment(
                        moment(defaultDate)
                          .add(index, 'days')
                          .format('YYYY-MM-DD') +
                          ' ' +
                          hours.startTime
                      ).format()
                      if (!hours.daysOfWeek.includes(weekDay + 1)) return null
                      return !selectedEvent(index, hours.startTime) &&
                        !isMultiple &&
                        !renderTooltipTime(dtstart) ? (
                        <Dropdown
                          animation="slide-up"
                          overlayStyle={{ top: -10 }}
                          overlay={() => (
                            <Styled.ChooseCalendarEventTypeContainer>
                              <Styled.ChooseCalendarEventTypeItem
                                onClick={() => handleSelectEvent(dtstart, 1)}
                              >
                                {t('OnceLesson')}
                              </Styled.ChooseCalendarEventTypeItem>
                              <Styled.ChooseCalendarEventTypeItem
                                onClick={() => handleSelectEvent(dtstart)}
                              >
                                {t('RegularLesson')}
                              </Styled.ChooseCalendarEventTypeItem>
                            </Styled.ChooseCalendarEventTypeContainer>
                          )}
                        >
                          <Styled.TimeItem
                            key={hours.startTime}
                            selected={selectedEvent(index, hours.startTime)}
                            taken={
                              !!filterData.selectedEvents.find(
                                event =>
                                  moment(event.event.rrule.dtstart).format() === dtstart &&
                                  event.teacher.id !== teacher.id
                              )
                            }
                          >
                            {hours.startTime}
                          </Styled.TimeItem>
                        </Dropdown>
                      ) : (
                        <Tooltip title={renderTooltipTime(dtstart)}>
                          <Styled.TimeItem
                            key={hours.startTime}
                            selected={selectedEvent(index, hours.startTime)}
                            taken={
                              !!filterData.selectedEvents.find(
                                event =>
                                  moment(event.event.rrule.dtstart).format() === dtstart &&
                                  event.teacher.id !== teacher.id
                              )
                            }
                            onClick={() => handleSelectEvent(dtstart)}
                          >
                            {hours.startTime}
                          </Styled.TimeItem>
                        </Tooltip>
                      )
                    })}
                  </Styled.TimeItems>
                </Styled.DayItem>
              )
            )
          })}
        </Styled.WeekContainer>
      </Styled.TeacherTimeContainer>
    </Styled.TeacherItemContainer>
  )
}

export default TeachersInfoCard
