import React from 'react';

const AddRowIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.92771 2.93161C0.0188893 6.84043 0.0188893 13.1596 3.92771 17.0684C7.83652 20.9772 14.1557 20.9772 18.0645 17.0684C21.9733 13.1596 21.9733 6.84043 18.0645 2.93161C14.1557 -0.977204 7.83652 -0.977204 3.92771 2.93161ZM17.0749 10C17.075 10.1309 17.0494 10.2606 16.9993 10.3816C16.9493 10.5025 16.8759 10.6125 16.7833 10.705C16.6907 10.7976 16.5808 10.871 16.4598 10.9211C16.3388 10.9711 16.2092 10.9968 16.0783 10.9966L11.9927 10.9966L11.9927 15.0822C11.9927 15.3465 11.8877 15.6 11.7008 15.7869C11.5139 15.9738 11.2604 16.0788 10.9961 16.0788C10.7318 16.0788 10.4783 15.9738 10.2914 15.7869C10.1988 15.6944 10.1254 15.5845 10.0753 15.4636C10.0252 15.3427 9.99945 15.2131 9.99945 15.0822V10.9966H5.91392C5.6496 10.9966 5.3961 10.8916 5.20919 10.7047C5.02228 10.5178 4.91728 10.2643 4.91728 10C4.91728 9.73567 5.02228 9.48217 5.20919 9.29527C5.3961 9.10836 5.6496 9.00336 5.91392 9.00336H9.99945V4.91783C9.99945 4.78695 10.0252 4.65735 10.0753 4.53643C10.1254 4.41551 10.1988 4.30564 10.2914 4.2131C10.3839 4.12055 10.4938 4.04714 10.6147 3.99705C10.7356 3.94697 10.8652 3.92119 10.9961 3.92119C11.127 3.92119 11.2566 3.94697 11.3775 3.99705C11.4984 4.04714 11.6083 4.12055 11.7008 4.2131C11.7934 4.30564 11.8668 4.41551 11.9169 4.53643C11.967 4.65735 11.9927 4.78695 11.9927 4.91783L11.9927 9.00336H16.0783C16.6155 9.00336 17.0678 9.45573 17.0749 10Z"
      fill="currentColor"
    />
  </svg>
);

export { AddRowIcon };
