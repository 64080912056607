import { IProfile, IStudentProfile } from '@models/IProfile'
import React, { useMemo, useState } from 'react'
import * as Styled from './result-teachers.styles'
import TeachersInfoCard from './TeacherInfoCard'
import { GENDERS, IAutoTeacherSelectionFilterData } from '..'
import Dropdown from './Dropdown'
import { CustomMaterialCheckbox } from '@components/index'
import AutoTeacherBig from '@images/auto_teacher_big.svg'
import { useTranslation } from 'react-i18next'
import { Tooltip, TooltipContainer } from '@pages/student/student.styles'
import Step2Component from '../FilterTeachers/Step2'
import { sendTeacherSelection } from '@servise/webhookAPI'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { APP_CONFIG } from '@utils/constants'
import { fetchTeacherProfileByUid } from '@servise/profileAPI'
import { calendarUtil } from '@utils/calendarUtil'
const ResultTeachers = ({
  filterData,
  onChange,
  teachers,
}: {
  filterData: IAutoTeacherSelectionFilterData
  onChange: (data: { name: string; value: any }) => void
  teachers: IProfile[]
}) => {
  const [isChangeTime, setIsChangeTime] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const handleTeacherSelection = async () => {
    try {
      let profile: IProfile | undefined = undefined
      if (profileData.tutorId) {
        profile = await fetchTeacherProfileByUid(profileData.tutorId)
      }

      await sendTeacherSelection({
        name: `${profileData.name} ${profileData.lastName}`,
        phone: profileData.phone,
        email: profileData.email,
        locale: profileData.lang,
        telegram: profile?.telegram,
        isStudent: profileData.student,
      })
      toast(t('Toast.AutoTeacherSelection'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-teacher-selection-success',
      })
      history.push('/student')
    } catch (e) {}
  }

  const timeZone = useMemo(() => {
    const timeZones = calendarUtil.getAvailableTimezones()
    return timeZones.find(timeZone => timeZone.value === profileData.timeZone)
  }, [profileData.timeZone])

  return (
    <Styled.TeacherResultContainer>
      <Tooltip
        placement="bottom"
        title={<TooltipContainer>{t('AutoTeacher.TimezoneTooltip')}</TooltipContainer>}
      >
        <Styled.TimzoneLabel>{timeZone?.label}</Styled.TimzoneLabel>
      </Tooltip>

      <Styled.TeacherResultTitle>{t('AutoTeacher.TeachersResultTitle')}</Styled.TeacherResultTitle>
      <Styled.SearchContainer>
        <Tooltip
          title={
            <Styled.TooltipContainer>{t('MultipleTeacherBtnTooltip')}</Styled.TooltipContainer>
          }
        >
          <div style={{ justifySelf: 'flex-start', width: 360 }}>
            <CustomMaterialCheckbox
              name={t('MultipleTeacherLabel')}
              onChange={e => {
                onChange({ name: 'withWho', value: e.target.checked ? 'multiple' : 'one' })
              }}
              checked={filterData.withWho === 'multiple'}
            />
          </div>
        </Tooltip>
        <Dropdown
          title={t('Gender')}
          selected={filterData.gender}
          items={GENDERS}
          onChange={value => onChange({ name: 'gender', value })}
          width={106}
        />

        {/* <Dropdown
          title={t('AutoTeacher.Step3.CountryTitle')}
          selected={filterData.country}
          items={COUNTRIES}
          onChange={value => onChange({ name: 'country', value })}
          width={166}
        /> */}

        <Dropdown
          title={t('AutoTeacher.Accent')}
          selected={filterData.langVariant}
          items={[...APP_CONFIG.typeOfLangs?.items, { label: 'LangVariant.ANY', value: 'ANY' }]}
          onChange={value => onChange({ name: 'langVariant', value })}
          width={166}
        />
        <Styled.ChangeTimeButton onClick={() => setIsChangeTime(true)}>
          {t('AutoTeacher.ChangeTime')}
        </Styled.ChangeTimeButton>
      </Styled.SearchContainer>
      {isChangeTime ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Step2Component
            onChange={onChange}
            studentBusinessHours={filterData.studentBusinessHours}
          />
          <Styled.Button onClick={() => setIsChangeTime(false)}>{t('Continue')}</Styled.Button>
        </div>
      ) : (
        <Styled.TeachersList>
          {teachers.map(teacher => (
            <TeachersInfoCard
              key={teacher.id}
              teacher={teacher}
              onChange={onChange}
              filterData={filterData}
            />
          ))}
          <Styled.Banner>
            <img src={AutoTeacherBig} alt="" />
            <Styled.BannerContent>
              <Styled.BannerDesc>{t('AutoTeacherBannerText')}</Styled.BannerDesc>
              <Styled.BannerButton onClick={handleTeacherSelection}>
                {t('AutoTeacherBannerBtnText')}
              </Styled.BannerButton>
            </Styled.BannerContent>
          </Styled.Banner>
        </Styled.TeachersList>
      )}
    </Styled.TeacherResultContainer>
  )
}

export default ResultTeachers
