import React from "react";

export default function EllipseIcon(props) {
  return (
    <svg
      width="14"
      height="14"
      {...props}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" />
    </svg>
  );
}
