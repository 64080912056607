import React from "react";

export default function Font36Icon() {
  return (
    <svg
      width="44"
      height="26"
      viewBox="0 0 44 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8585 25.6505L17.0059 19.5699H7.69023L5.83758 25.6505H0L9.01854 0H15.6426L24.6961 25.6505H18.8585ZM15.7125 15.0269C13.9997 9.51702 13.0326 6.40099 12.8112 5.67876C12.6015 4.95654 12.45 4.38575 12.3568 3.9664C11.9723 5.45744 10.8712 9.14427 9.0535 15.0269H15.7125Z"
        fill="white"
      />
      <path
        d="M40.2772 25.6505L39.246 22.9946H39.1062C38.209 24.1246 37.2827 24.9108 36.3272 25.3535C35.3834 25.7845 34.1483 26 32.622 26C30.746 26 29.2662 25.4642 28.1826 24.3925C27.1106 23.3208 26.5746 21.7948 26.5746 19.8145C26.5746 17.741 27.2971 16.2151 28.7419 15.2366C30.1984 14.2464 32.3889 13.6989 35.3135 13.5941L38.7042 13.4892V12.6331C38.7042 10.6528 37.6905 9.66263 35.6631 9.66263C34.1017 9.66263 32.2666 10.1344 30.1576 11.078L28.3923 7.47849C30.6411 6.30197 33.1346 5.71371 35.8728 5.71371C38.4945 5.71371 40.5044 6.2845 41.9027 7.42608C43.3009 8.56765 44 10.3033 44 12.6331V25.6505H40.2772ZM38.7042 16.5995L36.6418 16.6694C35.0922 16.716 33.9386 16.9955 33.1812 17.5081C32.4239 18.0206 32.0452 18.8011 32.0452 19.8495C32.0452 21.3522 32.9074 22.1035 34.6319 22.1035C35.867 22.1035 36.8516 21.7482 37.5856 21.0376C38.3314 20.3271 38.7042 19.3835 38.7042 18.207V16.5995Z"
        fill="white"
      />
    </svg>
  );
}
