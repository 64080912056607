import { createReducer } from '@reduxjs/toolkit'

import {
  GET_COURSES_FULFILLED,
  GET_COURSES_REJECTED,
  CREATE_COURSE_REJECTED,
  DUPLICATE_COURSE_REJECTED,
  GET_LESSONS_LENGTH_FULFILLED,
  RESET_COURSES_LIST,
  RESET_LESSONS_LENGTH_ARR,
  GET_ALL_LESSONS_FULFILLED,
  GET_ALL_LESSONS_REJECTED,
  PUBLISH_COURSE_FULFILLED,
  MODERATION_REJECTED,
  MODERATION_FULFILLED,
  DIVERTED_FULFILLED,
  DIVERTED_REJECTED,
  REMOVE_PUBLISH_FULFILLED,
  REMOVE_PUBLISH_REJECTED,
  PUBLISH_COURSE_REJECTED,
  TRANSFORM_TO_MINI_COURSE_FULFILLED,
  TRANSFORM_TO_MINI_COURSE_REJECTED,
} from '@types/courses'

const coursesReducer = createReducer(
  {
    coursesList: [],
    lessonsLengthArr: [],
    allLessons: [],
    error: null,
  },
  {
    [GET_COURSES_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: payload,
      error: null,
    }),
    [GET_COURSES_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [CREATE_COURSE_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DUPLICATE_COURSE_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [GET_LESSONS_LENGTH_FULFILLED]: (state, { payload }) => ({
      ...state,
      lessonsLengthArr: payload,
    }),
    [RESET_COURSES_LIST]: (state, { payload }) => ({
      ...state,
      coursesList: [],
    }),
    [RESET_LESSONS_LENGTH_ARR]: (state, { payload }) => ({
      ...state,
      lessonsLengthArr: [],
    }),
    [GET_ALL_LESSONS_FULFILLED]: (state, { payload }) => ({
      ...state,
      allLessons: payload,
    }),
    [GET_ALL_LESSONS_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [PUBLISH_COURSE_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: state.coursesList.map(c => (c.id === payload.id ? payload : c)),
    }),
    [PUBLISH_COURSE_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [MODERATION_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: state.coursesList.map(c => (c.id === payload.id ? payload : c)),
    }),
    [MODERATION_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DIVERTED_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: state.coursesList.map(c => (c.id === payload.id ? payload : c)),
    }),
    [DIVERTED_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [REMOVE_PUBLISH_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: state.coursesList.map(c => (c.id === payload.id ? payload : c)),
    }),
    [REMOVE_PUBLISH_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [TRANSFORM_TO_MINI_COURSE_FULFILLED]: (state, { payload }) => ({
      ...state,
      coursesList: state.coursesList.map(c => (c.id === payload.id ? { ...c, isMini: true } : c)),
    }),
    [TRANSFORM_TO_MINI_COURSE_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  }
)

export default coursesReducer
