import styled from 'styled-components'
import { CSSProperties } from 'react'

export const ModalContainer = styled.div`
  width: 810px;
  max-width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.theme.colors.textDark};
  header {
    margin: 30px;
    text-align: center;
  }

  main {
    padding: 20px 20px;
    border-top: ${props => props.theme.border.gray};
    .table-checkbox {
      svg {
        margin: auto;
      }
      input[type='checkbox']:checked + svg {
        fill: #558b2f;
      }
    }
    .table-checkbox-all {
      svg {
        fill: #ff852d;
        margin: auto;
      }
    }

    tbody {
      display: block;
      height: 300px;
      overflow: auto;
      font-weight: normal;

      letter-spacing: 0.01em;

      /* black */

      color: ${props => props.theme.colors.textDark};

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.bgLightSide1};

        border-radius: 2px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.bgLightSide};
        border-radius: 2px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${props => props.theme.colors.bgLightSide};
        border-radius: 2px;
      }
      @media (max-width: 767px) {
      }
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    .col1 {
      width: 140px;
      font-size: 18px;

      @media (max-width: 767px) {
        width: 80px;
        font-size: 12px;
      }
    }
    .col2 {
      width: 80px;
      @media (max-width: 767px) {
        width: unset;
        font-size: 12px;
      }
    }
    tbody td {
      text-align: center;
      line-height: 38px;
    }

    thead th {
      text-align: center;
      height: 38px;
      color: #414752;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.18px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    thead {
      width: calc(100% - 5px);
    }

    tbody tr:nth-child(odd) {
      background: RGBA(243, 245, 249, 0.5);
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    header {
      margin: 15px;
    }
    main {
      padding: 10px;
      width: 100%;
      overflow: auto;
      box-sizing: border-box;
      position: relative;
    }
    .bussines-hours {
    }
  }
`

export const SelectField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelectLabel = styled.span`
  color: #7e92ac;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 7px;
`
export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 22px 30px 35px 30px;

  .search-btn {
    height: 50px;
    width: 200px;
    padding: 0;
  }
`
export const TrialLessonsCheckboxContainer = styled.div`
  display: flex;

  .trialLessons {
    padding: 15px 0 0 10px;
    font-weight: 400;
    color: #7e92ac;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 3px;
    }
  }
`

export const customSelectStyles = (width: number) => ({
  container: (base: CSSProperties, state: any): CSSProperties => {
    return {
      ...base,
      outline: 'none',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#90A4BD',
      fontSize: 14,
    }
  },
  control: (base: any, state: any): CSSProperties => ({
    ...base,
    borderRadius: state.menuIsOpen ? '20px 20px 0 0' : 20,
    width: width,
    height: 36,
    paddingLeft: 10,

    border: '1px solid #C2CFE0',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      border: '1px solid #C2CFE0',
    },
  }),
  input: (base: any, state: any): CSSProperties => ({
    ...base,
    outline: 'none',
  }),
  indicatorSeparator: (): CSSProperties => ({}),
  dropdownIndicator: (defaultStyles, state): CSSProperties => ({
    ...defaultStyles,
    border: 'none',
    color: '#7E92AC',
    '&:hover': {
      color: state.isFocused ? ' #c2c2c2' : ' #c2c2c2',
    },

    marginRight: 7,
  }),
  placeholder: (): CSSProperties => ({
    fontSize: 14,
    color: '#7E92AC',
  }),

  option: (styles: any, { isFocused, isSelected }): CSSProperties => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.19)' : 'white',
    color: '#192A3E',
  }),
  menuList: (provided: any, state: any): CSSProperties => ({
    ...provided,
    maxHeight: 300,
    boxShadow: state.isFocused ? null : null,

    'overflow-x': 'hidden',

    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      '-webkit-border-radius': 5,
      borderRadius: 10,
      background: '#C2CFE0',
    },
  }),
  singleValue: (base, state): CSSProperties => ({
    ...base,
    color: state.selectProps.value.value !== 0 ? '#414752' : '#90A4BD',
    fontWeight: state.selectProps.value.value !== 0 ? 600 : 500,
  }),
  menu: (provided: any, state: any): CSSProperties => ({
    ...provided,
    width: width,
    // backgroundColor: theme.colors.bgDashboard,
    borderRadius: '0 0 20px 20px',
    fontSize: 14,
    boxShadow: state.isFocused ? null : null,

    border: '1px solid #C2CFE0',
    color: state.selectProps.menuColor,
    padding: 20,
    marginTop: 0,
  }),
})
