import {
  SET_CURSOR_STATE,
  SET_DOCUMENT_STATE,
  SyncActionsTypes,
  SET_NOTEBOOK_STATE,
  SET_PLAYER_STATE,
  SET_SELECTED_VIDEO_STATE,
  SET_PREV_PLAYER_STATE_STATE,
  SET_USER_STATE,
  SET_SCROLL_TO_FILE_STATE,
  SET_TRANSLATED_WORD_STATE,
  SET_EXERCISES_STATE,
} from './types'
import {
  ICursorState,
  IDocumentState,
  INotebookState,
  VideoPlayerState,
  IUserState,
  ITranslatedWordState,
} from '@models'

export const setPlayerStateAction = (
  playerState: VideoPlayerState
): SyncActionsTypes => {
  return {
    type: SET_PLAYER_STATE,
    payload: playerState,
  }
}

export const setSetScrollToFileAction = (
  fileId: string | null
): SyncActionsTypes => {
  return {
    type: SET_SCROLL_TO_FILE_STATE,
    payload: fileId,
  }
}

export const setSelectedVideoStateAction = (
  selectedVideo: string
): SyncActionsTypes => {
  return {
    type: SET_SELECTED_VIDEO_STATE,
    payload: selectedVideo,
  }
}

export const setPrevPlayerStateAction = (
  prevPlayerState: string
): SyncActionsTypes => {
  return {
    type: SET_PREV_PLAYER_STATE_STATE,
    payload: prevPlayerState,
  }
}

export const setCursorStateAction = (
  cursorState: ICursorState
): SyncActionsTypes => {
  return {
    type: SET_CURSOR_STATE,
    payload: cursorState,
  }
}
export const setExerciseStateAction = (
  exerciseState: any
): SyncActionsTypes => {
  return {
    type: SET_EXERCISES_STATE,
    payload: exerciseState,
  }
}
export const setDocumentStateAction = (
  documentState: IDocumentState
): SyncActionsTypes => {
  return {
    type: SET_DOCUMENT_STATE,
    payload: documentState,
  }
}

export const setUserState = (userState: IUserState): SyncActionsTypes => {
  return {
    type: SET_USER_STATE,
    payload: userState,
  }
}

export const setNotebookStateAction = (
  data: INotebookState
): SyncActionsTypes => {
  return {
    type: SET_NOTEBOOK_STATE,
    payload: data,
  }
}

export const setTranslatedWordStateAction = (
  data: ITranslatedWordState
): SyncActionsTypes => {
  return {
    type: SET_TRANSLATED_WORD_STATE,
    payload: data,
  }
}
