import styled, { css } from 'styled-components'

export const RescheduleLessonContainer = styled.div`
  position: relative;
  .rdrDays {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .rdrMonthAndYearWrapper {
    padding: 0px;
    height: 27px;
    margin-bottom: 29px;
  }
  .rdrWeekDay {
    width: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;

    /* Text_dark */

    color: #414752;
  }

  .rdrMonthAndYearPickers select {
    font-size: 20px;
    font-weight: 400;
    color: #414752;
  }
  .rdrWeekDays {
    gap: 12px;
    margin-bottom: 24px;

    height: 22px;
  }

  .rdrMonth {
    width: 354px;
    padding: 0px;
  }
  .rdrDay {
    box-sizing: border-box;
    position: relative;
    width: 40px;
    height: 40px;
    background: #e1ecff;
    border-radius: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;

    /* Color_menu_dashb */
    color: #4d6084;
    :hover {
      color: #f3f5f9;
      background: #7e92ac;
      border: none;
      .rdrDayNumber span {
        color: #f3f5f9;
      }
    }
    :first-child {
    }
  }
  .rdrDayHovered {
    color: #f3f5f9;
    background: #7e92ac;
  }
  .rdrDayEndPreview {
    display: none;
  }
  .rdrDayStartPreview {
    display: none;
  }
  .rdrSelected {
    background: #566a8f;
    color: #f3f5f9;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    left: 0px;
    top: 0px;
  }
  .rdrDayDisabled {
    background: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;
    .rdrDayNumber span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.01em;

      /* Text_dark */

      color: #414752;
    }
    /* Text_dark */
    :hover {
      background: none;
      .rdrDayNumber span {
        color: #414752;
      }
    }
  }

  .rdrDayNumber span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;

    /* Headline dark */
    color: #4d6084;
  }
  .rdrDayToday {
    border: 1px solid #4d6084;

    border-radius: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.01em;

    /* Text_dark */

    color: #414752;
    :first-child {
    }
  }
  .rdrDayToday .rdrDayNumber span:after {
    display: contents;
  }
`

export const RescheduleLessonHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  text-align: center;
  letter-spacing: 0.01em;
  color: #414752;
  margin: 20px 30px 0px 30px;
`
export const SelectedDateTimeLabel = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
`
export const SelectedDateTimeZoneLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
`

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #c2cfe0;
  margin-top: 20px;
  margin-bottom: 30px;
`

export const Button = styled.button<{ cancelBtn?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #7e92ac;
  padding: 6px 12px;
  ${({ cancelBtn }) =>
    cancelBtn &&
    css`
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;

      /* Text_dark */

      color: #414752;
    `};
  margin-top: 10px;
`

export const CancelButton = styled(Button)<{ disabled: boolean }>`
  border: 2px solid #c2cfe0;
  border-radius: 12px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`

export const ConfirmButton = styled(Button)`
  color: #ff922d;
  border: 1px solid #ff922d;
  border-radius: 25px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 30px 0px 30px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
`
export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 13px;
  height: 408px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.bgLightSide1};

    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }
`
export const HoursItem = styled.div`
  width: 190px;
  height: 40px;
  background: #e1ecff;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  justify-content: center;
  color: #4d6084;
  cursor: pointer;
  :hover {
    color: #f3f5f9;
    background: #7e92ac;
  }
  min-height: 40px;
`
export const SelectedItem = styled.div`
  display: flex;
  gap: 5px;
`
export const SelectedHours = styled(HoursItem)`
  color: #f3f5f9;
  background: #4d6084;
  width: 90px;
  :hover {
    color: #f3f5f9;
    background: #4d6084;
  }
`

export const CheckTimeButton = styled.button`
  width: 95px;
  height: 40px;

  border: 2px solid #4284ff;
  border-radius: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Back_blue_1 */
  justify-content: center;
  color: #1556cf;
  :disabled {
    border: 2px solid #c2cfe0;
    color: #c2cfe0;
  }
`

export const CancelLessonText = styled.div`
  width: 350px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin-right: 75px;
  margin-left: 75px;
  span {
    color: #414752;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.18px;
  }
`
