import {
  CLEAR_COURSE_LESSON,
  CourseActionsTypes,
  SET_COURSE_LESSON,
  SET_IS_LOADING_COURSE_LESSON,
} from './types'
import { ILessonState } from '@models/ICourse'

const initialState: ILessonState = {
  lesson: null,
  isLoading: true,
}

export const lessonPreviewReducer = (
  state = initialState,
  action: CourseActionsTypes
): ILessonState => {
  switch (action.type) {
    case SET_IS_LOADING_COURSE_LESSON:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_COURSE_LESSON:
      return {
        ...state,
        lesson: action.payload,
      }
    case CLEAR_COURSE_LESSON:
      return {
        ...state,
        lesson: null,
        isLoading: true,
      }
    default:
      return state
  }
}
