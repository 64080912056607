import styled from 'styled-components';

export const BalanceHistory = styled.table`
  display: block;
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
  padding: 30px 40px;
  th,
  td {
    flex-basis: 85%;
    flex-grow: 1;
    display: block;
    text-align: left;
    padding-right: 50px;
  }
`;

export const BalanceHead = styled.thead`
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  overflow-y: scroll;
  color: rgba(144, 164, 189, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
`;

export const BalanceBody = styled.tbody`
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  max-height: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: rgba(65, 71, 82, 1);
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(144, 164, 189, 0.5);
  }
`;

export const BalanceColumn = styled.tr<{ isGreen?: boolean }>`
  width: 100%;
  display: flex;

  td {
    padding-top: 30px;
  }

  > td:nth-child(-n + 3) {
    color: ${(props) => props.isGreen && 'rgba(33, 150, 83, 1)'};
  }

  > td:nth-last-child(-n + 2) {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    flex-basis: 35%;
  }

  > th:nth-last-child(-n + 2) {
    text-align: center;
    flex-basis: 35%;
  }

  > td:nth-child(2),
  th:nth-child(2) {
    flex-basis: 190%;
  }
`

export const DateColumn = styled.span<{ isGreen?: boolean }>`
  color: ${props => (props.isGreen && 'rgba(33, 150, 83, 1)') || 'gray'};
  font-weight: bold;
`
export const TimeColumn = styled.span<{ isGreen?: boolean }>`
  color: ${props => (props.isGreen && 'rgba(33, 150, 83, 1)') || 'gray'};
  margin-left: 10px;
  font-size: 16px;
`
