import React from 'react';

function AudioPlayIcon({ playing }) {
  if (playing) {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='12.0003' cy='12' r='12' fill='#4284FF' />
        <path
          d='M9.77779 8C10.1314 8 10.4706 8.14048 10.7206 8.39052C10.9706 8.64057 11.1111 8.97971 11.1111 9.33333V14.6667C11.1111 15.0203 10.9706 15.3594 10.7206 15.6095C10.4706 15.8595 10.1314 16 9.77779 16C9.42417 16 9.08503 15.8595 8.83498 15.6095C8.58493 15.3594 8.44446 15.0203 8.44446 14.6667V9.33333C8.44446 8.97971 8.58493 8.64057 8.83498 8.39052C9.08503 8.14048 9.42417 8 9.77779 8ZM14.2222 8C14.5759 8 14.915 8.14048 15.165 8.39052C15.4151 8.64057 15.5556 8.97971 15.5556 9.33333V14.6667C15.5556 15.0203 15.4151 15.3594 15.165 15.6095C14.915 15.8595 14.5759 16 14.2222 16C13.8686 16 13.5295 15.8595 13.2794 15.6095C13.0294 15.3594 12.8889 15.0203 12.8889 14.6667V9.33333C12.8889 8.97971 13.0294 8.64057 13.2794 8.39052C13.5295 8.14048 13.8686 8 14.2222 8Z'
          fill='white'
        />
      </svg>
    );
  }
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='#4284FF' />
      <path
        d='M16.2018 11.0714L10.6472 7.26875C10.3907 7.09301 10.1322 7 9.91739 7C9.50204 7 9.24512 7.33334 9.24512 7.89131V16.11C9.24512 16.6673 9.50172 17 9.91609 17C10.1312 17 10.3856 16.9069 10.6427 16.7307L16.1999 12.9281C16.5573 12.6832 16.7552 12.3535 16.7552 11.9996C16.7553 11.6458 16.5596 11.3163 16.2018 11.0714Z'
        fill='#F3F5F9'
      />
    </svg>
  );
}

export default AudioPlayIcon;
