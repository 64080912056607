import React, { useEffect, useState, memo, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Ripples from 'react-ripples'
import isEqual from 'lodash/isEqual'
import DragCard from '@components/common/DragCard'
import Constructor from '@components/common/Constructor'
import PagesController from '@components/common/PagesController'
import { getTests, editTest, resetTests } from '@actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loader from '@components/Loader'

import test from '@assets/icons/test.svg'
import more from '@assets/images/blocks/more.svg'
import Navbar from '@components/Navbar'
import { useHistory } from 'react-router-dom'
import { authRef } from '@configs/firebase'
import { getManagerProfile, getProfile } from '../../servise/profileAPI'
import { setTeacherTestReview } from '@store/tests/actions'
import { debounce } from 'lodash'
import update from 'immutability-helper'
import { logger } from '@utils/ConsoleLogger'
import exercise from '@assets/icons/exercise.svg'
import result from '@assets/icons/result.svg'

function arePagesEqual(pages1, pages2) {
  return isEqual(pages1, pages2)
}

const Test = () => {
  const { push } = useHistory()
  const history = useHistory()
  const actions = [
    {
      type: 'test',
      name: 'Тест',
      icon: test,
    },
    {
      type: 'exercise',
      name: 'Упражнение',
      icon: exercise,
    },
    {
      type: 'result',
      name: 'Итог теста',
      icon: result,
    },
  ]

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    setPending(true)
  }, [dispatch, params])

  const states = useSelector(state => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile,
  }))

  useEffect(() => {
    if (states.authStatus === 'logged on') {
      const uid = authRef.currentUser.uid
      dispatch(getManagerProfile(uid))
        .then(() => {})
        .catch(e => {
          if (e && e.code === 'auth/manager-not-found') {
            dispatch(getProfile(uid))
              .then(() => {})
              .catch(e => {
                logger.error('Error: ', e)
              })
          }
        })
    }
  }, [dispatch, history, states.authStatus])

  const { testsList } = useSelector(store => store.tests)
  const { isReviewed } = useSelector(state => state.test)
  const currentTest = testsList.find(test => test.id === params.testId)
  const [pages, setPages] = useState([])
  const [previousPages, setPreviousPages] = useState([])
  const [currentPage, setCurrentPage] = useState(null)
  const [pending, setPending] = useState(false)
  const [openRectangle, setOpenRectangle] = useState(false)

  useEffect(() => {
    dispatch(getTests())
  }, [dispatch])

  useEffect(() => {
    if (currentTest) {
      setPages(currentTest.pages)
      setPreviousPages(currentTest.pages)
      setCurrentPage(currentTest.pages[currentTest.pages.length - 1].id)
      setPending(false)
    }
  }, [currentTest])

  useEffect(
    () => () => {
      dispatch(resetTests())
    },
    [dispatch]
  )

  const delayedQuery = useCallback(
    debounce(() => {
      if (pages && !arePagesEqual(pages, previousPages)) {
        dispatch(editTest({ testId: params.testId, testData: { pages } }))
        setPreviousPages(pages)
      }
    }, 3000),
    [pages, previousPages]
  )

  useEffect(() => {
    delayedQuery()

    return delayedQuery.cancel
  }, [pages, delayedQuery])

  const moveCard = useCallback(
    (dragIndex, hoverIndex, id) => {
      const dragCard = pages[dragIndex]
      setPages(
        update(pages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
    },
    [pages]
  )

  useEffect(() => {
    const handler = e => {
      if (e.target.closest('.rectangle-button')) return
      setOpenRectangle(false)
    }
    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [setOpenRectangle])

  const toggleOptions = useCallback(() => {
    setOpenRectangle(v => !v)
  }, [setOpenRectangle])

  const handleDuplicatePage = useCallback(() => {
    setOpenRectangle(false)
    setPages(prevPages => {
      const id = Math.max(...prevPages.map(({ id }) => id)) + 1
      setCurrentPage(id)
      return [...prevPages, { ...prevPages.find(({ id }) => id === currentPage), id }]
    })
  }, [setOpenRectangle, currentPage, setCurrentPage, setPages])

  const handleRemovePage = useCallback(() => {
    setOpenRectangle(false)
    setPages(prevPages => {
      if (prevPages.length === 1) return prevPages
      const currentPageIndex = prevPages.indexOf(prevPages.find(({ id }) => id === currentPage))
      const newCurrentPage = prevPages[currentPageIndex - 1] || prevPages[currentPageIndex + 1]
      setCurrentPage(newCurrentPage.id)
      return prevPages.filter(({ id }) => id !== currentPage)
    })
  }, [setOpenRectangle, currentPage, setCurrentPage, setPages])

  return (
    <DndProvider backend={HTML5Backend}>
      {!pending ? (
        <>
          <div>
            <Navbar
              title={currentTest?.name}
              value={pages.find(({ id }) => id === currentPage)?.name}
              middleText
              onChangeText={e => {
                setPages(
                  pages.map(page => {
                    if (page.id !== currentPage) {
                      return page
                    } else {
                      return {
                        ...page,
                        name: e.target.value,
                      }
                    }
                  })
                )
              }}
              goBack={() => {
                isReviewed ? push('/teacher/classes') : push('/tests')
                dispatch(setTeacherTestReview(false))
              }}
            />
            <div style={{ padding: '15px 0' }} id="lesson">
              <aside id="actions">
                {actions.map(({ type, name, icon }) => (
                  <DragCard key={type} type={type} name={name} icon={icon} />
                ))}
              </aside>
              {pages.length ? (
                <Constructor
                  additions={{ withMarks: true }}
                  pageIndex={currentPage}
                  pageState={pages.find(({ id }) => id === currentPage)?.pageState}
                  onStateChange={(pageState, pageIndex) => {
                    setPages(prevState =>
                      prevState.map((page, index) => {
                        if (page.id !== currentPage) {
                          return page
                        } else {
                          return {
                            ...page,
                            name: page.name,
                            id: page.id,
                            pageState,
                          }
                        }
                      })
                    )
                  }}
                />
              ) : null}
              <aside id="additional-actions">
                <div className="rectangle-button" onClick={toggleOptions}>
                  <img src={more} alt="More" />
                  {openRectangle && (
                    <div className="rectangle-content">
                      <ul>
                        <Ripples during={1200}>
                          <li onClick={handleDuplicatePage}>Дублировать страницу</li>
                        </Ripples>
                        <Ripples during={1200}>
                          <li onClick={handleRemovePage}>Удалить страницу</li>
                        </Ripples>
                      </ul>
                    </div>
                  )}
                </div>
              </aside>
            </div>
            <PagesController
              pages={pages}
              currentPage={currentPage}
              moveCard={moveCard}
              setCurrentPage={id => {
                setCurrentPage(id)
              }}
              onCreatePage={() => {
                setPages(prevState => [
                  ...prevState,
                  {
                    id:
                      Math.max.apply(
                        Math,
                        prevState.map(({ id }) => id)
                      ) + 1,
                    name: 'Название страницы',
                    pageState: [],
                    isHomework: false,
                  },
                ])
                setCurrentPage(pages.length)
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </DndProvider>
  )
}

export default memo(Test)
