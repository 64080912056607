import React from 'react'


export default function MusicIcon() {
	return (
		<svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
			<path d='m12.399 12.399c0.4688-0.4688 1.1047-0.7322 1.7678-0.7322h3.3333c0.4602 0 0.8333 0.3731 0.8333 0.8333v1.6667c0 0.663-0.2634 1.2989-0.7322 1.7677-0.4688 0.4689-1.1047 0.7323-1.7678 0.7323h-1.6666c-0.6631 0-1.299-0.2634-1.7678-0.7323-0.4689-0.4688-0.7322-1.1047-0.7322-1.7677 0-0.6631 0.2633-1.299 0.7322-1.7678zm1.7678 0.9344c-0.2211 0-0.433 0.0878-0.5893 0.2441s-0.2441 0.3683-0.2441 0.5893 0.0878 0.4329 0.2441 0.5892 0.3682 0.2441 0.5893 0.2441h1.6666c0.221 0 0.433-0.0878 0.5893-0.2441s0.2441-0.3682 0.2441-0.5892v-0.8334h-2.5zm-11.768 0.7323c0.46884-0.4689 1.1047-0.7323 1.7678-0.7323h3.3333c0.46024 0 0.83333 0.3731 0.83333 0.8334v1.6666c0 0.6631-0.26339 1.299-0.73223 1.7678s-1.1047 0.7322-1.7678 0.7322h-1.6667c-0.66305 0-1.2989-0.2634-1.7678-0.7322s-0.73223-1.1047-0.73223-1.7678c0-0.663 0.26339-1.2989 0.73223-1.7677zm1.7678 0.9344c-0.22102 0-0.43298 0.0878-0.58926 0.2441s-0.24408 0.3682-0.24408 0.5892 0.0878 0.433 0.24408 0.5893 0.36824 0.2441 0.58926 0.2441h1.6667c0.22102 0 0.43298-0.0878 0.58926-0.2441s0.24408-0.3683 0.24408-0.5893v-0.8333h-2.5z' />
			<path d='m18.039 1.8642c0.1869 0.15833 0.2947 0.39089 0.2947 0.63585v10c0 0.4602-0.3731 0.8333-0.8333 0.8333s-0.8333-0.3731-0.8333-0.8333v-9.0163l-8.3334 1.3889v9.2941c0 0.4602-0.37309 0.8333-0.83333 0.8333s-0.83333-0.3731-0.83333-0.8333v-10c0-0.40736 0.29451-0.75502 0.69633-0.82199l10-1.6667c0.2416-0.04027 0.4887 0.02781 0.6756 0.18615z' />
		</svg>
	)
}