import React from 'react'
import { IconButton, RadioGroup } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { ATTITUDE_TO_HOMEWORK_OPTIONS } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import * as Styled from '../personal-info.styles'

export default function({
  attitudeToHomework,
  editType,
  handleClickEdit,
  onChange,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  attitudeToHomework: string
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()

  const img =
    attitudeToHomework !== 'DoesntMatter'
      ? require(`../../../Icons/${attitudeToHomework}.svg`)
      : null
  return (
    <>
      <Styled.PersonalInfoItem>
        <Styled.PersonalInfoLabel>Homework</Styled.PersonalInfoLabel>
        <Styled.PersonalItemCard style={{ display: 'flex', alignItems: 'center' }}>
          {attitudeToHomework === 'GreatIDo'
            ? "great, i'll do it"
            : attitudeToHomework === 'IDontLike'
            ? "ugh i don't like doing it"
            : attitudeToHomework === 'DoesntMatter'
            ? "doesn't matter"
            : ''}
          {img && <img style={{ marginLeft: 10 }} src={img.default} alt={attitudeToHomework} />}
        </Styled.PersonalItemCard>
        {!editType && <div className="hover-block" />}
        <IconButton
          className="edit-btn"
          size="small"
          onClick={() => handleClickEdit('attitudeToHomework')}
        >
          {editType === 'attitudeToHomework' ? <CheckMarkIcon /> : <EditIcon />}
        </IconButton>
        {editType === 'attitudeToHomework' && (
          <Styled.EditContainer style={{ padding: '16px 20px' }}>
            <Styled.PersonalInfoLabel
              style={{
                width: 'auto',
                fontWeight: 700,
                fontSize: 16,
                marginBottom: 18,
              }}
            >
              Homework
            </Styled.PersonalInfoLabel>

            <RadioGroup
              aria-label="attitudeToHomework"
              name="attitudeToHomework"
              value={attitudeToHomework}
              onChange={e => onChange({ name: 'attitudeToHomework', value: e.target.value })}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              {ATTITUDE_TO_HOMEWORK_OPTIONS.map(option => {
                let img = option !== 'DoesntMatter' ? require(`../../../Icons/${option}.svg`) : null
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomMaterialRadioButton
                      name={option}
                      label={
                        option === 'GreatIDo'
                          ? "great, i'll do it"
                          : option === 'IDontLike'
                          ? "ugh i don't like doing it"
                          : option === 'DoesntMatter'
                          ? "doesn't matter"
                          : ''
                      }
                      key={option}
                    />
                    {img && <img style={{ marginLeft: 10 }} src={img.default} alt={option} />}
                  </div>
                )
              })}
            </RadioGroup>
          </Styled.EditContainer>
        )}
      </Styled.PersonalInfoItem>
    </>
  )
}
