import styled from 'styled-components'

export const CommentsContainter = styled.div`
  width: 300px;
  .comments-content {
    width: 260px;
    height: 475px;
    background: #ffffff;
    border-radius: 20px;
    padding: 20px;
    position: relative;
  }
`
export const TutorContainter = styled.div`
  display: flex;
  align-items: center;
  width: 260px;
  height: 0px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
`
export const StudentCardHeader = styled.p<{ child?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1b3b77;
  margin-bottom: ${props => (props.child ? '10px' : '15px')};
`

export const RateDropdownContainer = styled.div`
  position: relative;
`

export const ChosenOption = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  font-size: 24px;
  line-height: 33px;
  color: #414752;
  padding: 11px 20px;
  cursor: pointer;
  letter-spacing: 0.01em;
  > img {
    margin-left: 22px;
  }
`

export const ChosenTutor = styled.div`
  margin-left: -10px;
  display: flex;
  align-items: center;
`
export const TutorName = styled.div`
  display: flex;
  margin: 0 10px;
  white-space: nowrap;
`
export const ExpandIcon = styled.div`
  margin-right: -10px;
  position: absolute;
  right: 0;
`
export const TutorInfo = styled.div`
  width: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 10px;
  color: #414752;
  padding: 11px 5px 11px 0;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.01em;
  > img {
    margin-right: 10px;
  }
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 20px;
  z-index: 9999;
  left: 0px;
  top: 10px;
  overflow: auto;
  div:first-child {
    margin-top: 0px;
  }
`

export const OptionItem = styled.div`
  cursor: pointer;

  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;

  /* Text_dark */
  margin-top: 20px;

  color: #414752;
  &:hover {
    color: #ff852d;
  }
`
