import React from 'react'

export default function LineWidth12Icon() {
  return (
    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90367 7.1706C7.35483 7.38407 7.06633 7.72393 6.95869 8.14094C6.54432 9.74615 4.89583 10.7144 3.27668 10.3036C1.65752 9.89283 0.680844 8.25853 1.09521 6.65332C1.75787 4.08622 3.62404 2.38957 5.69316 1.58479C7.71399 0.798788 10.0707 0.79152 12.1284 1.64559C14.7237 2.7228 15.7052 4.95776 16.1721 6.02109C16.214 6.11649 16.2517 6.20246 16.2861 6.27731C16.4121 6.55128 16.4983 6.72351 16.5753 6.85353C16.6033 6.90096 16.6258 6.93514 16.6426 6.95892C16.6654 6.9631 16.6976 6.968 16.7412 6.97274C17.3003 7.03355 17.6287 6.98118 17.79 6.937C17.8707 6.91487 17.9174 6.8928 17.9396 6.88082C17.9431 6.87889 17.9463 6.87709 17.9491 6.87545C17.9999 5.26267 19.3346 3.97071 20.9738 3.97071C22.6451 3.97071 24 5.31393 24 6.97087C24 8.35582 23.4428 10.0401 22.0345 11.3068C20.551 12.6411 18.484 13.1986 16.0812 12.9373C14.5582 12.7716 13.3149 12.1722 12.3628 11.2124C11.5172 10.3599 11.0495 9.35493 10.7785 8.76521C10.1133 7.31801 10.0441 7.28546 9.79107 7.18042C9.22534 6.94561 8.50079 6.93835 7.90367 7.1706Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
