import React from 'react'
import styled from 'styled-components'

import Chip from '@components/Chip'
import CommentBg from '@images/commentBg.png'

export const HomeWorkCommentContainer = styled.div<{ commentHeightFull: boolean }>`
  background: url(${CommentBg}) repeat-y;
  background-size: contain;
  background-attachment: local;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 5px 15px;

  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};

  line-height: 35px;
  font-style: normal;
  font-weight: normal;
  max-width: 70%;
  // width: 100%;
  ${props => props.commentHeightFull && `height: 100%`};
  word-break: break-word;
`

const Container = styled.div`
  margin-left: 20px;
  width: 40%;
`

const HomeWorkComment = ({
  comment,
  teacherName,
  teacherAvatar,
  commentHeightFull = true,
}: {
  comment: string
  teacherName?: string
  teacherAvatar?: string
  commentHeightFull?: boolean
}) => {
  return (
    <Container>
      {teacherName && (
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-end' }}>
          <Chip name={teacherName} img={teacherAvatar} color="rgba(194, 207, 224, 0.5)" />
        </div>
      )}
      <HomeWorkCommentContainer commentHeightFull={commentHeightFull}>
        {comment}
      </HomeWorkCommentContainer>
    </Container>
  )
}

export default HomeWorkComment
