import React from 'react'
import * as Styled from './teacher-homework-card.styles'
import { TeacherButton } from '@components/index'

interface IDeleteHomeworkModal {
  handleClose: () => void
  handleDeleteHomework: () => void
}

const DeleteHomeworkModal = ({ handleClose, handleDeleteHomework }: IDeleteHomeworkModal) => {
  return (
    <Styled.ModalWrapper>
      <Styled.ModalTitle> Do you want delete this homework?</Styled.ModalTitle>
      <Styled.ModalDescription>
        You can delete this homework for yourself and your student.
      </Styled.ModalDescription>
      <Styled.ButtonGroup>
        <TeacherButton
          whiteTheme
          children={'Delete'}
          onClick={handleDeleteHomework}
          bgWhite
          btnClass="save-changes-btn"
        />
        <Styled.DeleteBtn onClick={handleClose}>Cancel</Styled.DeleteBtn>
      </Styled.ButtonGroup>
    </Styled.ModalWrapper>
  )
}

export default DeleteHomeworkModal
