import React from 'react'

export default function FileIcon(props) {
  return (
    <svg viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#336A93"
        d="M0.90901 1.40901C1.33097 0.987053 1.90326 0.75 2.5 0.75H7.75C7.94891 0.75 8.13968 0.829018 8.28033 0.96967L13.5303 6.21967C13.671 6.36032 13.75 6.55109 13.75 6.75V15C13.75 15.5967 13.5129 16.169 13.091 16.591C12.669 17.0129 12.0967 17.25 11.5 17.25H2.5C1.90326 17.25 1.33097 17.0129 0.90901 16.591C0.487053 16.169 0.25 15.5967 0.25 15V3C0.25 2.40326 0.487053 1.83097 0.90901 1.40901ZM2.5 2.25C2.30109 2.25 2.11032 2.32902 1.96967 2.46967C1.82902 2.61032 1.75 2.80109 1.75 3V15C1.75 15.1989 1.82902 15.3897 1.96967 15.5303C2.11032 15.671 2.30109 15.75 2.5 15.75H11.5C11.6989 15.75 11.8897 15.671 12.0303 15.5303C12.171 15.3897 12.25 15.1989 12.25 15V7.06066L7.43934 2.25H2.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#336A93"
        d="M7.75 0.75C8.16421 0.75 8.5 1.08579 8.5 1.5V6H13C13.4142 6 13.75 6.33579 13.75 6.75C13.75 7.16421 13.4142 7.5 13 7.5H7.75C7.33579 7.5 7 7.16421 7 6.75V1.5C7 1.08579 7.33579 0.75 7.75 0.75Z"
      />
    </svg>
  )
}
