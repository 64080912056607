import * as React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import { CSSProperties } from 'react'
import { createElement as h } from 'react'
type DisplayStyle =
  | 'xxl'
  | 'xl'
  | 'large'
  | 'prelarge'
  | 'primary'
  | 'largeDesc'
  | 'secondary'
  | 'medium'
  | 'desc'
  | 'label'
  | 'nav'
  | 'small'
  | 'title'
  | 'describe'
  | 'headline'
  | 'link'
  | 'pageHeader'
  | 'classHeader'
  | 'classTeacher'
  | 'subheadline'
  | 'infoTitle'
  | 'infoLink'
  | 'content'
  | 'addHomeworkContent'
  | 'smallContent'
  | 'hw'
  | 'noteDate'
  | 'additionalMediumText'
  | 'supperLarge'
  | 'studentButton'
  | 'statValue'
  | 'dayNum'
  | 'dayName'
  | 'calendarTime'
  | 'default'
  | 'infoContent'
  | 'infoDesc'
  | 'testHeader'

const textStyles = ({
  theme,
  light,
  dark,
  center = false,
  bold,
  thin,
  capitalize,
  pointer,
  color,
  underline,
  fontSize,
}: {
  theme: DefaultTheme
  light?: boolean
  dark?: boolean
  center?: boolean
  bold?: boolean
  semibold?: boolean
  thin?: boolean
  capitalize?: boolean
  pointer?: boolean
  color?: string
  underline?: boolean
  fontSize?: 'small' | 'medium' | 'large'
}) => {
  const common = `
        text-decoration: none;
        line-height: 25px;
        font-family: ${theme.defaultFont};
        white-space: pre-wrap;
        text-align: ${center ? 'center' : 'inherit'};
        ${light ? `color: ${theme.textColors.light}` : ''};
        ${dark ? `color: ${theme.textColors.dark}` : ''};
        ${color ? `color: ${color}` : ''};
        ${thin ? `font-weight: 400` : ''};
        ${bold ? `font-weight: bold` : ''};
        ${capitalize ? `text-transform: capitalize` : ''};
        ${pointer ? `cursor: pointer` : ''};
        ${underline && `text-decoration: underline`};
        ${fontSize && `font-size: ${theme.fontSizes[fontSize]}`};
  `

  return {
    default: `
      ${common}
    `,

    large: `
          ${common}
          
          font-size: ${theme.fontSizes.large};
          font-weight: 600;
        `,

    pageHeader: `
          ${common}
          
          font-size: ${theme.fontSizes.large};
          font-weight: 700;
          color: ${theme.textColors.pageHeader};
        `,

    classHeader: `
          ${common}
          
          text-align: left;
          font-size: ${theme.fontSizes.large};
          color: ${theme.textColors.additionalText};
          font-weight: bold;
        `,
    testHeader: `
          ${common}
          margin-top: 10px;
          text-align: center;
          font-size: ${theme.fontSizes.large};
          color: ${theme.textColors.mainContent};
          font-weight: bold;
          height: 75px;
        `,

    classTeacher: `
          ${common}
          
          font-size: 16px;
          line-height: 22px;
          color: ${theme.textColors.mainContent};
        `,

    infoTitle: `
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #336A93;
          
          ${common}
        `,

    infoContent: `
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    
    color: #414752;
   
        
        ${common}
        a {
          text-decoration: underline;
        }
      `,
    infoDesc: `
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #414752;
          
          ${common}
        `,

    content: `
          font-size: 16px;
          font-weight: 600;
          color: ${theme.textColors.mainContent};
          
          ${common};
          
          line-height: 22px;
        `,

    statValue: `
          font-size: ${theme.fontSizes.large};
          font-weight: 700;
          color: ${theme.textColors.mainContent};
          
          ${common};
          
          line-height: 25px;
        `,

    infoLink: `
            color: ${theme.textColors.menu};
            font-size: 14px;
            
            ${common};
        
            line-height: 19px;
            text-decoration: underline;
        `,

    addHomeworkContent: `
      color: ${theme.textColors.mainContent};
      font-size: ${theme.fontSizes.medium};
      
      ${common}
      
      line-height: 19px;
      font-style: normal;
      font-weight: 600;
    `,

    studentButton: `
      display: flex;
      align-items: center;
      
      color: ${theme.textColors.mainContent};
      font-size: ${theme.fontSizes.medium};
      
      ${common}
      
      line-height: 19px;
      font-style: normal;
      font-weight: 600;
      
      > img {
        margin-left: 10px;
      }
    `,

    smallContent: `
      color: ${theme.textColors.mainContent};
      font-size: ${theme.fontSizes.small};
      
      ${common}
      
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
    `,

    hw: `
      color: ${theme.textColors.mainContent};
      font-size: ${theme.fontSizes.small};
      
      ${common}
      
      line-height: 30px;
      font-style: normal;
      font-weight: normal;
      line-height: 30px;
      text-decoration-line: underline;
      text-decoration-style: dotted;
      text-decoration-color: #E2AD8C;
    `,

    noteDate: `
      color: ${theme.textColors.menu};
      font-size: ${theme.fontSizes.small};
      
      ${common}
      
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
    `,

    dayNum: `
      color: ${theme.colors.greyMiddle};
      font-size: 24px;
      
      ${common}
      
      line-height: 33px;
      font-style: normal;
      font-weight: bold;
    `,

    dayName: `
      color: ${theme.colors.greyMiddle};
      font-size: ${theme.fontSizes.medium};
      
      ${common}
      
      line-height: 19px;
      font-style: normal;
      font-weight: normal;
      margin-top: 3px;
    `,

    additionalMediumText: `
      color: ${theme.textColors.additionalText};
      font-size: ${theme.fontSizes.medium};
      
      ${common}
      
      line-height: 19px;
      font-style: normal;
      font-weight: 600;
    `,

    supperLarge: `
      ${common}
      
      font-weight: bold;
      font-size: 48px;
      line-height: 65px;
      display: flex;
      align-items: baseline;
      justify-content: center;
    `,

    calendarTime: `
      ${common}
      
      font-size: 16px;
      color: ${theme.textColors.mainContent};
      font-weight: bold;
      line-height: 22px;
    `,
  }
}

export interface TextProps {
  children: React.ReactNode
  display: DisplayStyle
  center?: boolean
  dark?: boolean
  light?: boolean
  bold?: boolean
  thin?: boolean
  capitalize?: boolean
  pointer?: boolean
  className?: string
  color?: string
  style?: CSSProperties
  underline?: boolean
  fontSize?: 'small' | 'medium' | 'large'
}

const TextComponent = styled.div<{ display?: any }>(props => textStyles(props)[props.display])
const ALinkComponent = styled.a<{ display?: any }>(props => textStyles(props)[props.display])

const Text = ({ children, type = 'div', display, ...rest }: TextProps & { type?: string }) => {
  if (type === 'a') {
    return (
      <ALinkComponent {...rest} display={display}>
        {children}
      </ALinkComponent>
    )
  }
  return (
    <TextComponent {...rest} display={display}>
      {children}
    </TextComponent>
  )
}
const LinkComponent = styled(Link)<{ display?: any }>(props => textStyles(props)[props.display])

const CustomLink = ({ to, children, display, ...rest }: TextProps & { to: string }) => {
  return (
    <LinkComponent to={to} {...rest} display={display}>
      {children}
    </LinkComponent>
  )
}

export { CustomLink, Text }
