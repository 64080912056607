import React from 'react'

export default function CheckRectIcon({ isChecked, ...rest }) {
  if (isChecked) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 21.3333C24 22.806 22.806 24 21.3333 24H2.66667C1.194 24 0 22.806 0 21.3333V2.66667C0 1.194 1.194 0 2.66667 0H21.3333C22.806 0 24 1.194 24 2.66667V21.3333Z"
          fill="#226699"
        />
        <path
          d="M19.52 4.24127C18.7494 3.7406 17.7174 3.95993 17.2147 4.73193L9.95735 15.9179L6.60468 12.8179C5.92868 12.1926 4.87402 12.2346 4.24935 12.9099C3.62468 13.5859 3.66602 14.6406 4.34202 15.2653L9.14802 19.7093C9.46802 20.0059 9.87468 20.1526 10.2787 20.1526C10.7274 20.1526 11.3014 19.9646 11.678 19.3933C11.8994 19.0559 20.0114 6.5466 20.0114 6.5466C20.512 5.77393 20.292 4.74193 19.52 4.24127Z"
          fill="#ffffff"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="14" height="14" rx="3" stroke="#D5D5D5" strokeWidth="2" />
      </svg>
    )
  }
}

CheckRectIcon.defaultProps = {
  isChecked: false,
}
