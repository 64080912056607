import React, { useMemo } from 'react'
import * as Styled from './promoCode.styles'
import Gift from '@images/gift.svg'
import Check from '@images/round_check.svg'
import Loader from '@images/promoCodeLoader.gif'
import Cancel from '@images/round_cancel.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  promoCode: string
  allAccess?: boolean
  discount?: number
  isMiniCourse?: boolean
  freeMinicourses?: string
  discountedMinicourses?: string
  handleChangePromoCode: (e: React.ChangeEvent<HTMLInputElement>) => void
  suspensePromoCode: string
  showPromoCodeModal: () => void
}

const PromoCode = ({
  promoCode,
  allAccess,
  discount,
  freeMinicourses,
  discountedMinicourses,
  handleChangePromoCode,
  suspensePromoCode,
  showPromoCodeModal,
  isMiniCourse,
}: Props) => {
  const { t } = useTranslation()

  let suspenseComponent = useMemo(() => {
    if (suspensePromoCode === 'pending') {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Loader src={Loader} /> {t('PromoCode.Checking')}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    } else if (suspensePromoCode === 'success' && !isMiniCourse) {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} /> {t('PromoCode.Success')}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    } else if (suspensePromoCode === 'success' && allAccess) {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessAll', {
              discount: discount,
            })}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    } else if (suspensePromoCode === 'success' && freeMinicourses?.length > 0) {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessFree', {
              freeMinicourses: freeMinicourses,
            })}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    } else if (suspensePromoCode === 'success' && discountedMinicourses?.length > 0) {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            <Styled.Check src={Check} />
            {t('PromoCode.SuccessCertain', {
              disCourses: discountedMinicourses,
              discount: discount,
            })}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    } else if (suspensePromoCode === 'fail') {
      return (
        <Styled.Container>
          <Styled.PromoCodeStatus status={suspensePromoCode}>
            {t('PromoCode.Fail')}
          </Styled.PromoCodeStatus>
        </Styled.Container>
      )
    }
  }, [freeMinicourses, suspensePromoCode, discount, discountedMinicourses, isMiniCourse])

  return (
    <Styled.PromoCodeContainer>
      <Styled.Gift src={Gift} />
      <Styled.PromoCode
        value={promoCode}
        onChange={handleChangePromoCode}
        type="search"
        placeholder={t('PromoCode.Enter')}
        CancelImg={Cancel}
      />
      {promoCode.length === 0 && !isMiniCourse ? (
        <Styled.Container>
          <Styled.PromoText>{t('PromoCode.PromoText')}</Styled.PromoText>
          <Styled.PromoButton onClick={showPromoCodeModal}>{t('PromoCode.Get')}</Styled.PromoButton>
        </Styled.Container>
      ) : (
        promoCode.length !== 0 && suspenseComponent
      )}
    </Styled.PromoCodeContainer>
  )
}

export default PromoCode
