import * as React from 'react'

const AppleIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0ZM16.7531 12.1592C17.2849 12.3702 17.7592 12.5585 18.1429 12.5585C18.4799 12.5585 18.9195 12.3857 19.4396 12.1814C20.2721 11.8542 21.3109 11.4459 22.4649 11.5358C23.1832 11.5845 25.2407 11.8037 26.58 13.7638C26.4704 13.8247 24.1207 15.1883 24.145 18.0371C24.1815 21.4461 27.1278 22.5783 27.1643 22.5905C27.14 22.6757 26.6895 24.2097 25.606 25.7924C24.6685 27.1682 23.6945 28.5317 22.1605 28.5561C21.4197 28.574 20.9251 28.3603 20.4106 28.138C19.8767 27.9073 19.3214 27.6673 18.4472 27.6673C17.5183 27.6673 16.9339 27.9169 16.3709 28.1573C15.8862 28.3643 15.4174 28.5645 14.7583 28.5926C13.273 28.6413 12.1529 27.1073 11.2033 25.7437C9.26751 22.9436 7.79437 17.8545 9.77885 14.4091C10.765 12.6924 12.5182 11.6089 14.4296 11.5845C15.253 11.5638 16.0528 11.8812 16.7531 12.1592ZM22.4771 6.09375C22.6232 7.40862 22.0997 8.73566 21.3083 9.68529C20.5169 10.6349 19.2264 11.3776 17.9724 11.2802C17.802 9.98966 18.4351 8.63826 19.1656 7.79821C19.9813 6.84858 21.3448 6.14245 22.4771 6.09375Z"
        fill="#9ABDFC"
      />
    </svg>
  )
}

export default AppleIcon
