import React, { useState, useEffect, useCallback } from 'react'
import * as Styled from './balanceHistory.styles'
import { fetchStudentPayments } from '@servise'
import { authRef } from '@configs/firebase'
import { IPayment } from '@models'
import { useTranslation } from 'react-i18next'
import { CURRRENCY_SYMBOL, PACKAGES_DESC } from '@utils/constants'
import { capitalize } from '@utils/helperst'
import moment from 'moment'

const BalanceHistory = () => {
  const [payments, setPayments] = useState<Array<IPayment>>([])
  const { t } = useTranslation()
  useEffect(() => {
    fetchStudentPayments(authRef.currentUser.uid).then(res =>
      setPayments(
        res
          .reverse()
          .map(item => {
            balance += item.package
            return { ...item, balance }
          })
          .reverse()
      )
    )
  }, [])

  const makeDesc = useCallback((payment: IPayment): string => {
    if (payment.amount > 0) {
      let currency = payment.currency === undefined ? 'RUB' : payment.currency.toUpperCase()
      return `${t('Manager.Description.Topup')} + ${payment.amount.toLocaleString()} ${
        CURRRENCY_SYMBOL[currency]
      }  ${payment.type ? t(PACKAGES_DESC[payment.type].title) : ''}`
    }

    if (payment.package < 0)
      return `${capitalize(t('Class1'))}${
        payment.n ? `, ${t('Teacher').toLowerCase()}: ${payment.n}` : ''
      }`

    if (payment.amount === 0 && payment.package === 0) return t('Triallesson')

    return t('Bonus')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let balance = 0

  return (
    <Styled.BalanceHistory>
      <Styled.BalanceHead>
        <Styled.BalanceColumn>
          <th>{t('Date')}</th>
          <th>{t('Status')}</th>
          <th>{t('TheChange')}</th>
          <th>{t('Balance')}</th>
        </Styled.BalanceColumn>
      </Styled.BalanceHead>
      <Styled.BalanceBody>
        {payments.map(payment => {
          let date
          let time
          try {
            date = moment(payment.date?.seconds ? payment.date.toDate() : payment.date).format(
              'DD/MM/YYYY'
            )
            time = moment(payment.date?.seconds ? payment.date.toDate() : payment.date).format(
              'HH:mm'
            )
          } catch (e) {
            date = new Date(payment.date * 1000).toLocaleString()
          }
          return (
            <Styled.BalanceColumn key={`${date}${payment.balance}`} isGreen={payment.amount > 0}>
              <td>
                <Styled.DateColumn isGreen={payment.amount > 0}>{date}</Styled.DateColumn>
                <Styled.TimeColumn>{time}</Styled.TimeColumn>
              </td>
              <td>{payment.desc ? t(payment.desc) : makeDesc(payment)}</td>
              <td>{payment.package > 0 ? `+${payment.package}` : payment.package}</td>
              <td>{payment.balance}</td>
            </Styled.BalanceColumn>
          )
        })}
      </Styled.BalanceBody>
    </Styled.BalanceHistory>
  )
}

export default BalanceHistory
