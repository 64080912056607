import {
  SET_PLAYER_STATE,
  SET_SELECTED_VIDEO_STATE,
  SyncActionsTypes,
  SET_CURSOR_STATE,
  SET_DOCUMENT_STATE,
  SET_NOTEBOOK_STATE,
  SET_PREV_PLAYER_STATE_STATE,
  SET_USER_STATE,
  SET_SCROLL_TO_FILE_STATE,
  SET_TRANSLATED_WORD_STATE,
  SET_EXERCISES_STATE,
} from './types'
import { ISyncDataState } from '@models'

const initialState: ISyncDataState = {
  reactPlayerState: {
    videoPlayerState: {
      playerState: 'unstarted',
      timeInVideo: 0,
    },
    prevPlayerState: 'unstarted',
    selectedVideo: null,
  },
  cursorState: {
    fileID: '',
    position: {
      x: 0,
      y: 0,
    },
  },
  documentState: {
    pageNumbers: {},
  },
  notebookState: null,
  translatedWordState: null,
  userState: {
    studentIsOnline: false,
    teacherIsOnline: false,

    currentLesson: {
      courseId: '',
      lessonId: '',
    },
    teacher: {
      fullName: 'Teacher',
      avatar: '',
      id: '',
    },
    isTurnVideoScreen: false,
  },
  scrollFileId: null,
  exerciseState: {},
  audioPlayerState: undefined,
}

export const syncReducers = (state = initialState, action: SyncActionsTypes): ISyncDataState => {
  var reactPlayerState = { ...state.reactPlayerState }
  switch (action.type) {
    case SET_PLAYER_STATE:
      reactPlayerState.videoPlayerState = action.payload
      return {
        ...state,
        reactPlayerState,
      }
    case SET_SELECTED_VIDEO_STATE:
      reactPlayerState.selectedVideo = action.payload
      return {
        ...state,
        reactPlayerState,
      }

    case SET_PREV_PLAYER_STATE_STATE:
      reactPlayerState.prevPlayerState = action.payload
      return {
        ...state,
        reactPlayerState,
      }

    case SET_CURSOR_STATE:
      return {
        ...state,
        cursorState: action.payload,
      }
    case SET_DOCUMENT_STATE:
      return {
        ...state,
        documentState: { pageNumbers: action.payload },
      }
    case SET_USER_STATE:
      return {
        ...state,
        userState: action.payload,
      }

    case SET_NOTEBOOK_STATE:
      return {
        ...state,
        notebookState: action.payload,
      }
    case SET_SCROLL_TO_FILE_STATE:
      return {
        ...state,
        scrollFileId: action.payload,
      }

    case SET_TRANSLATED_WORD_STATE:
      return {
        ...state,
        translatedWordState: action.payload,
      }

    case SET_EXERCISES_STATE:
      return {
        ...state,
        exerciseState: action.payload,
      }
    default:
      return state
  }
}
