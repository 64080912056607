import React from 'react'
import styled from 'styled-components'
import { FormControlLabel, Radio } from '@material-ui/core'
import RadioCheckedIcon from '@components/Student/Icons/RadioCheckedIcon'
import RadioUnCheckedIcon from '@components/Student/Icons/RadioUnCheckedIcon'

export const CustomControlLabel = styled(FormControlLabel)<{ margin: number }>`
  margin-left: 0 !important;
  margin-right: 0 !important;
  &:nth-child(2n) {
    padding: ${props => `${props.margin}px 0`};
  }
  & .MuiButtonBase-root {
    padding: 0;
    margin-right: 10px;
  }
  & .MuiFormControlLabel-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #414752;
  }
`
const CustomMaterialRadioButton = ({
  name,
  label,
  margin = 10,
}: {
  name: string
  label?: string
  margin?: number
}) => {
  return (
    <CustomControlLabel
      margin={margin}
      value={name}
      control={
        <Radio color="primary" checkedIcon={<RadioCheckedIcon />} icon={<RadioUnCheckedIcon />} />
      }
      label={label || name}
    />
  )
}

export default CustomMaterialRadioButton
