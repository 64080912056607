import firebase from 'firebase/app'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import {
  GET_LESSONS,
  GET_LESSONS_FULFILLED,
  GET_LESSONS_REJECTED,
  CREATE_LESSON,
  CREATE_LESSON_FULFILLED,
  CREATE_LESSON_REJECTED,
  DELETE_LESSON,
  DELETE_LESSON_FULFILLED,
  DELETE_LESSON_REJECTED,
  DUPLICATE_LESSON,
  DUPLICATE_LESSON_FULFILLED,
  DUPLICATE_LESSON_REJECTED,
  EDIT_COURSE,
  EDIT_COURSE_FULFILLED,
  EDIT_COURSE_REJECTED,
  EDIT_LESSON,
  EDIT_LESSON_FULFILLED,
  EDIT_LESSON_REJECTED,
  UPDATE_LESSONS,
} from '@types/course'

import { sagaFirebase as firestore, firestoreRef } from '@configs/firebase'
import { COURSES_STATUS } from '@utils/constants'

function* getLessons({ payload: { courseId } }) {
  try {
    const courseSnapshot = yield call(firestore.getDocument, `courses/${courseId}`)
    const lessonsSnapshot = yield call(
      firestore.getCollection,
      firestoreRef.collection(`courses/${courseId}/lessons`).orderBy('order')
    )
    const lessons = lessonsSnapshot.docs.map(lesson => ({
      id: lesson.id,
      ...lesson.data(),
    }))

    yield put({
      type: GET_LESSONS_FULFILLED,
      payload: {
        lessons,
        currentCourse: courseSnapshot.data(),
      },
    })
  } catch (error) {
    yield put({
      type: GET_LESSONS_REJECTED,
      payload: error,
    })
  }
}

function* createLesson({ payload: { courseId, lesson, timestamp } }) {
  try {
    const lessonsList = yield select(store => store.course.lessonsList)
    const batch = firestoreRef.batch()
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    batch.set(firestoreRef.collection(`courses/${courseId}/lessons`).doc(), {
      pages: [
        {
          id: 0,
          name: 'Название страницы',
          pageState: [],
        },
      ],
      timestamp,
      order: lessonsList.length + 1,
      ...lesson,
    })
    batch.update(firestoreRef.doc(`courses/${courseId}`), {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status:
        updatedCourse.data().status === COURSES_STATUS.MODERATION
          ? COURSES_STATUS.MODERATION
          : COURSES_STATUS.CHANGED,
    })
    yield batch.commit()
    yield call(getLessons, { payload: { courseId } })
    yield put({
      type: CREATE_LESSON_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: CREATE_LESSON_REJECTED,
      payload: error,
    })
  }
}

function* deleteLesson({ payload: { courseId, lessonId } }) {
  try {
    const batch = firestoreRef.batch()
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    batch.delete(firestoreRef.collection(`courses/${courseId}/lessons`).doc(lessonId))
    batch.update(firestoreRef.doc(`courses/${courseId}`), {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status:
        updatedCourse.data().status === COURSES_STATUS.MODERATION
          ? COURSES_STATUS.MODERATION
          : COURSES_STATUS.CHANGED,
    })
    yield batch.commit()
    yield call(getLessons, { payload: { courseId } })
    yield put({
      type: DELETE_LESSON_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: DELETE_LESSON_REJECTED,
      payload: error,
    })
  }
}

function* duplicateLesson({ payload: { courseId, lessonId } }) {
  try {
    const batch = firestoreRef.batch()
    const lessonSnapshot = yield call(
      firestore.getDocument,
      `courses/${courseId}/lessons/${lessonId}`
    )
    const newLessonRef = firestoreRef.collection(`courses/${courseId}/lessons`).doc()
    batch.set(newLessonRef, {
      ...lessonSnapshot.data(),
      id: newLessonRef.id,
      order: 0,
    })
    const lessonsList = yield select(store => store.course.lessonsList)
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    lessonsList.forEach(({ id, order }) => {
      batch.update(firestoreRef.doc(`courses/${courseId}/lessons/${id}`), {
        order: order + 1,
      })
    })
    batch.update(firestoreRef.doc(`courses/${courseId}`), {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status:
        updatedCourse.data().status === COURSES_STATUS.MODERATION
          ? COURSES_STATUS.MODERATION
          : COURSES_STATUS.CHANGED,
    })
    yield batch.commit()

    yield call(getLessons, { payload: { courseId } })
    yield put({
      type: DUPLICATE_LESSON_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: DUPLICATE_LESSON_REJECTED,
      payload: error,
    })
  }
}

function* editCourse({ payload: { courseId, courseData } }) {
  try {
    const courseSnapshot = yield call(firestore.getDocument, `courses/${courseId}`)
    const currentCourse = courseSnapshot.data()
    const wasUpdated =
      currentCourse.name !== courseData.name ||
      currentCourse.description !== courseData.description ||
      currentCourse.image !== courseData.image ||
      currentCourse.progress !== courseData.progress
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    const updateData = {
      ...currentCourse,
      ...courseData,
      updatedAt: wasUpdated
        ? firebase.firestore.FieldValue.serverTimestamp()
        : currentCourse.updatedAt,
      status:
        updatedCourse.data().status === COURSES_STATUS.MODERATION
          ? COURSES_STATUS.MODERATION
          : COURSES_STATUS.CHANGED,
    }
    yield call(firestore.setDocument, `courses/${courseId}`, updateData)
    yield put({
      type: EDIT_COURSE_FULFILLED,
      payload: { currentCourse: updateData },
    })
  } catch (error) {
    yield put({
      type: EDIT_COURSE_REJECTED,
      payload: error,
    })
  }
}

function* editLesson({ payload: { courseId, lessonId, lesson } }) {
  try {
    const lessonSnapshot = yield call(
      firestore.getDocument,
      `courses/${courseId}/lessons/${lessonId}`
    )
    const currentLesson = lessonSnapshot.data()
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    const batch = firestoreRef.batch()
    const wasUpdated =
      currentLesson.name !== lesson.name ||
      currentLesson.description !== lesson.description ||
      currentLesson.image !== lesson.image
    batch.update(firestoreRef.collection(`courses/${courseId}/lessons`).doc(lessonId), {
      ...currentLesson,
      ...lesson,
    })
    if (wasUpdated) {
      batch.update(firestoreRef.doc(`courses/${courseId}`), {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        status:
          updatedCourse.data().status === COURSES_STATUS.MODERATION
            ? COURSES_STATUS.MODERATION
            : COURSES_STATUS.CHANGED,
      })
    }
    yield batch.commit()
    yield call(getLessons, { payload: { courseId } })
    yield put({
      type: EDIT_LESSON_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: EDIT_LESSON_REJECTED,
      payload: error,
    })
  }
}

function* updateLessons({ payload: { lessons, courseId } }) {
  try {
    const batch = firestoreRef.batch()
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    lessons.forEach(({ id, order, isFree }) => {
      batch.update(firestoreRef.doc(`courses/${courseId}/lessons/${id}`), {
        order,
        isFree: !!isFree,
      })
    })
    batch.update(firestoreRef.doc(`courses/${courseId}`), {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status:
        updatedCourse.data().status === COURSES_STATUS.MODERATION
          ? COURSES_STATUS.MODERATION
          : COURSES_STATUS.CHANGED,
    })
    yield batch.commit()
  } catch (error) {
    yield put({
      type: GET_LESSONS_REJECTED,
      payload: error,
    })
  }
}

function* watchGetLessons() {
  yield takeEvery(GET_LESSONS, getLessons)
}

function* watchCreateLesson() {
  yield takeEvery(CREATE_LESSON, createLesson)
}

function* watchDeleteLesson() {
  yield takeEvery(DELETE_LESSON, deleteLesson)
}

function* watchDuplicateLesson() {
  yield takeEvery(DUPLICATE_LESSON, duplicateLesson)
}

function* watchEditCourse() {
  yield takeEvery(EDIT_COURSE, editCourse)
}

function* watchEditLesson() {
  yield takeEvery(EDIT_LESSON, editLesson)
}

function* watchUpdateLessons() {
  yield takeEvery(UPDATE_LESSONS, updateLessons)
}

export default [
  watchGetLessons(),
  watchCreateLesson(),
  watchDeleteLesson(),
  watchDuplicateLesson(),
  watchEditCourse(),
  watchEditLesson(),
  watchUpdateLessons(),
]
