import React, { useEffect, useState, memo, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Dropdown from 'rc-dropdown'
import Ripples from 'react-ripples'

import DragCard from '@components/common/DragCard'
import Constructor from '@components/common/Constructor'
import PagesController from '@components/common/PagesController'
import { getConstructor, createConstructor, resetConstructor } from '@actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Loader from '@components/Loader'

import atextIcon from '@assets/icons/atext.svg'
import globe from '@assets/icons/globe.svg'
import image from '@assets/icons/image.svg'
import link from '@assets/icons/link.svg'
import music from '@assets/icons/music.svg'
import text from '@assets/icons/text.svg'
import video from '@assets/icons/video.svg'
import test from '@assets/icons/test.svg'
import exercise from '@assets/icons/exercise.svg'
import table from '@assets/icons/table.svg'
import essay from '@assets/icons/essay.svg'

import lessonIcon from '@assets/images/blocks/lesson.svg'
import home from '@assets/images/blocks/home.svg'
import homeActive from '@assets/images/blocks/home-active.svg'
import more from '@assets/images/blocks/more.svg'

import Navbar from '@components/Navbar'
import { useHistory } from 'react-router-dom'

import { authRef } from '@configs/firebase'
import { getManagerProfile, getProfile } from '../../servise/profileAPI'

import { debounce } from 'lodash'

import update from 'immutability-helper'
import MenuConstructor from '@components/common/MenuConstructor'
import { logger } from '@utils/ConsoleLogger'

const Lesson = () => {
  const { push } = useHistory()
  const history = useHistory()

  const actions = [
    {
      type: 'editor',
      name: 'Заголовок',
      icon: atextIcon,
    },
    {
      type: 'action-editor',
      name: 'Текст',
      icon: text,
    },
    {
      type: 'link',
      name: 'Ссылка',
      icon: link,
    },
    {
      type: 'image',
      name: 'Картинка',
      icon: image,
    },
    {
      type: 'video',
      name: 'Видео',
      icon: video,
    },
    {
      type: 'audio',
      name: 'Аудио',
      icon: music,
    },
    {
      type: 'exercise',
      name: 'Упражнение',
      icon: exercise,
    },
    {
      type: 'test',
      name: 'Тест',
      icon: test,
    },
    {
      type: 'vocabulary',
      name: 'Словарь',
      icon: globe,
    },
    {
      type: 'table',
      name: 'Таблица',
      icon: table,
    },
    {
      type: 'essay',
      name: 'Сочинение',
      icon: essay,
    },
  ]

  const BASE_URL = window.location.origin

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    setPending(true)
    dispatch(getConstructor(params))
  }, [dispatch, params])

  const states = useSelector(state => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile,
  }))

  useEffect(() => {
    if (states.authStatus === 'logged on') {
      const uid = authRef.currentUser.uid
      dispatch(getManagerProfile(uid))
        .then(() => {})
        .catch(e => {
          if (e && e.code === 'auth/manager-not-found') {
            dispatch(getProfile(uid))
              .then(() => {})
              .catch(e => {
                logger.error('Error: ', e)
              })
          }
        })
    }
  }, [dispatch, history, states.authStatus])

  const { currentCourse, lesson } = useSelector(store => store.lesson)

  const [pages, setPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [pending, setPending] = useState(false)
  const [openRectangle, setOpenRectangle] = useState(false)

  useEffect(() => {
    if (lesson) {
      setPages(lesson.pages)
      setCurrentPage(lesson.pages[lesson.pages.length - 1].id)
      setPending(false)
    }
  }, [lesson, currentCourse])

  useEffect(
    () => () => {
      dispatch(resetConstructor())
    },
    [dispatch]
  )

  const delayedQuery = useCallback(
    debounce(() => {
      if (pages) {
        dispatch(createConstructor({ ...params, constructor: pages }))
      }
    }, 3000),
    [pages, lesson]
  )

  useEffect(() => {
    delayedQuery()

    return delayedQuery.cancel
  }, [pages, delayedQuery])

  const moveCard = useCallback(
    (dragIndex, hoverIndex, id) => {
      const dragCard = pages[dragIndex]
      setPages(
        update(pages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
    },
    [pages]
  )

  const handleChangeHomeWork = useCallback(() => {
    setPages(allPages =>
      allPages.map(p => {
        if (p.id === currentPage) {
          const isEssay = p.pageState.find(item => item.type === 'essay')
          if (isEssay) {
            return { ...p, isHomework: true }
          } else return { ...p, isHomework: !p.isHomework }
        } else {
          return p
        }
      })
    )
  }, [currentPage, setPages])

  useEffect(() => {
    const handler = e => {
      if (e.target.closest('.rectangle-button')) return
      setOpenRectangle(false)
    }
    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [setOpenRectangle])

  const toggleOptions = useCallback(() => {
    setOpenRectangle(v => !v)
  }, [setOpenRectangle])

  const handleDuplicatePage = useCallback(() => {
    setOpenRectangle(false)
    setPages(prevPages => {
      const id = Math.max(...prevPages.map(({ id }) => id)) + 1
      setCurrentPage(id)
      return [...prevPages, { ...prevPages.find(({ id }) => id === currentPage), id }]
    })
  }, [setOpenRectangle, currentPage, setCurrentPage, setPages])

  const handleRemovePage = useCallback(() => {
    setOpenRectangle(false)
    setPages(prevPages => {
      if (prevPages.length === 1) return prevPages
      const currentPageIndex = prevPages.indexOf(prevPages.find(({ id }) => id === currentPage))
      const newCurrentPage = prevPages[currentPageIndex - 1] || prevPages[currentPageIndex + 1]
      setCurrentPage(newCurrentPage.id)
      return prevPages.filter(({ id }) => id !== currentPage)
    })
  }, [setOpenRectangle, currentPage, setCurrentPage, setPages])
  return (
    <DndProvider backend={HTML5Backend}>
      {pages && !pending ? (
        <>
          <div>
            <Navbar
              title={lesson?.name}
              value={pages.find(({ id }) => id === currentPage).name}
              middleText
              onChangeText={e => {
                setPages(
                  pages.map(page => {
                    if (page.id !== currentPage) {
                      return page
                    } else {
                      return {
                        ...page,
                        name: e.target.value,
                      }
                    }
                  })
                )
              }}
              goBack={() => push(`/course/${params.courseId}`)}
            />
            <div style={{ padding: '15px 0' }} id="lesson">
              <aside id="actions">
                {actions.map(({ type, name, icon }) => (
                  <DragCard key={type} type={type} name={name} icon={icon} />
                ))}
              </aside>
              <Constructor
                pageIndex={currentPage}
                pageState={pages.find(({ id }) => id === currentPage).pageState}
                onStateChange={(pageState, pageIndex) => {
                  setPages(prevState =>
                    prevState.map((page, index) => {
                      if (page.id !== currentPage) {
                        return page
                      } else {
                        return {
                          ...page,
                          name: page.name,
                          id: page.id,
                          pageState,
                        }
                      }
                    })
                  )
                }}
              />
              <aside id="additional-actions">
                <Dropdown
                  trigger="click"
                  animation="slide-up"
                  overlay={
                    <MenuConstructor
                      actions={[
                        {
                          id: 'view-as-student',
                          name: 'Как у ученика',
                          onClick: () => {
                            window.open(
                              `${BASE_URL}/student/course/${params.courseId}/lesson/${params.lessonId}/page/${currentPage}`
                            )
                          },
                        },
                        {
                          id: 'view-as-teacher',
                          name: 'Как у учителя',
                          onClick: () => {
                            window.open(
                              `${BASE_URL}/teacher/course/${params.courseId}/lesson/${params.lessonId}/page/${currentPage}`
                            )
                          },
                        },
                      ]}
                    />
                  }
                >
                  <div className="action-item">
                    <img
                      src={lessonIcon}
                      style={{
                        marginBottom: 20,
                        marginTop: 17,
                        cursor: 'pointer',
                      }}
                      alt="Посмотреть урок"
                    />
                    <div className="menu-text">
                      <span>Посмотреть урок</span>
                    </div>
                  </div>
                </Dropdown>
                <div className="action-item">
                  <img
                    src={pages.find(p => p.id === currentPage)?.isHomework ? homeActive : home}
                    style={{ marginBottom: 20, cursor: 'pointer' }}
                    alt="Homework"
                    onClick={handleChangeHomeWork}
                  />
                  <div className="menu-text">
                    <span>Сделать страницу домашкой</span>
                  </div>
                </div>
                <div className="rectangle-button" onClick={toggleOptions}>
                  <img src={more} alt="More" />
                  {openRectangle && (
                    <div className="rectangle-content">
                      <ul>
                        <Ripples during={1200}>
                          <li onClick={handleDuplicatePage}>Дублировать страницу</li>
                        </Ripples>
                        <Ripples during={1200}>
                          <li onClick={handleRemovePage}>Удалить страницу</li>
                        </Ripples>
                      </ul>
                    </div>
                  )}
                </div>
              </aside>
            </div>
            <PagesController
              pages={pages}
              currentPage={currentPage}
              moveCard={moveCard}
              setCurrentPage={id => {
                setCurrentPage(id)
              }}
              onCreatePage={() => {
                setPages(prevState => [
                  ...prevState,
                  {
                    id:
                      Math.max.apply(
                        Math,
                        prevState.map(({ id }) => id)
                      ) + 1,
                    name: 'Название страницы',
                    pageState: [],
                    isHomework: false,
                  },
                ])
                setCurrentPage(pages.length)
              }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </DndProvider>
  )
}

export default memo(Lesson)
