import React from 'react';

const AlignCenterIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3346 5.83329V4.16663H1.66797V5.83329H18.3346ZM15.8346 7.49996V9.16663H4.16797V7.49996H15.8346ZM18.3346 12.5V10.8333H1.66797V12.5H18.3346ZM15.8346 15.8333V14.1666H4.16797V15.8333H15.8346Z"
      fill="currentColor"
    />
  </svg>
);

export { AlignCenterIcon };
