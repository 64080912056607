import styled from 'styled-components'

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
  padding: 20px;
  line-height: 19px;
`

export const Phone = styled.img``

export const HelpText = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: rgba(27, 59, 119, 1);
  flex-grow: 1;
  margin-left: 1vw;
`
export const HelpButton = styled.button`
  color: rgba(21, 86, 207, 1);
  font-weight: inherit;
  text-decoration: underline;
`

export const HelpIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > * {
    margin-left: 1vw;
  }
`

export const WhatsApp = styled.img`
  cursor: pointer;
`

export const Telegram = styled.img`
  cursor: pointer;
`
