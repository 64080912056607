import styled from 'styled-components'
import { Text } from '@components/Text'

export const Container = styled.div<{ color: string | null }>`
  display: flex;
  align-items: center;
  background: ${props => props.color || '#ffe3c9'};
  border-radius: 40px;
  padding: 3px;
  align-self: flex-start;
  width: fit-content;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 12px !important;
    align-self: center !important;
  }
`

export const Img = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
`

export const Name = styled(Text)`
  margin: 0 13px 0 4px;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
`

export const IsOnline = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #27ae60;
  position: absolute;
  right: -2px;
  bottom: -2px;
`
