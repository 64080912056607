import {
  PersonalActionsTypes,
  SET_TEACHERS,
  DELETE_TEACHER_ACTION,
  SET_TEACHER_STUDENTS,
  SET_TEACHERS_ACCOUNTING,
  SET_RATE_TEACHER,
  SET_PASSED_LESSONS,
  SET_REQUISITES_TEACHER,
  SET_MANAGER_STUDENTS,
  SET_MANAGER_POSSIBLE_STUDENTS,
  DELETE_MANAGER_POSSIBLE_STUDENT,
  SET_STUDENT_PAYMENTS,
  SET_RATE_STUDENT,
  SET_STUDENT_DATA,
  SET_POSSIBLE_STUDENT_DATA,
  SET_LOADING,
  SET_MANAGERS,
  SET_SALES_MANAGERS,
  SET_TEACHER_STATUS,
  CHANGE_MANAGER_POTENTIAL_STUDENT,
  DELETE_SALES_MANAGER_ACTION,
  SET_TEACHERS_STATISTICS,
} from './types'
import { IPersonalState } from '@models/IPersonal'

const initialState: IPersonalState = {
  teachers: [],
  teachersAccounting: [],
  students: [],
  potentialStudents: [],
  loading: false,
  managers: [],
  salesManagers: [],
  teachersStatistics: [],
}

export const personalReducers = (
  state = initialState,
  action: PersonalActionsTypes
): IPersonalState => {
  let teachers = [...state.teachers]
  let teachersAccounting = [...state.teachersAccounting]
  let students = [...state.students]
  let potentialStudents = [...state.potentialStudents]
  let managers = [...state.managers]
  let salesManagers = [...state.salesManagers]
  switch (action.type) {
    case SET_TEACHERS:
      return {
        ...state,
        teachers: action.payload,
      }
    case SET_TEACHERS_ACCOUNTING:
      return {
        ...state,
        teachersAccounting: action.payload,
        loading: false,
      }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }

    case DELETE_TEACHER_ACTION: {
      teachers = teachers.filter(item => item.id !== action.payload)
      return {
        ...state,
        teachers,
      }
    }
    case SET_TEACHER_STUDENTS: {
      teachers = teachers.map(item =>
        item.id === action.payload.id ? { ...item, students: action.payload.students } : item
      )
      return {
        ...state,
        teachers,
      }
    }
    case SET_TEACHER_STATUS:
      return {
        ...state,
        teachers: action.payload,
      }
    case SET_RATE_TEACHER: {
      teachersAccounting = teachersAccounting.map(item =>
        item.id === action.payload.teacherId ? { ...item, rate: action.payload.rate } : item
      )
      return {
        ...state,
        teachersAccounting,
      }
    }
    case SET_REQUISITES_TEACHER: {
      teachersAccounting = teachersAccounting.map(item =>
        item.id === action.payload.teacherId
          ? { ...item, requisites: action.payload.requisites }
          : item
      )
      return {
        ...state,
        teachersAccounting,
      }
    }

    case SET_PASSED_LESSONS: {
      const findIndex = teachersAccounting.findIndex(item => item.id === action.payload.teacherId)
      const teacherData = teachersAccounting[findIndex]
      const filteredPassedLessons = action.payload.studentId
        ? teacherData.passedLessons.filter(item => item.id !== action.payload.studentId)
        : []

      teachersAccounting[findIndex].passedLessons = [
        ...filteredPassedLessons,
        ...action.payload.passedLessons,
      ]

      return {
        ...state,
        teachersAccounting,
      }
    }

    case SET_MANAGER_STUDENTS: {
      return {
        ...state,
        students: action.payload,
      }
    }
    case SET_MANAGER_POSSIBLE_STUDENTS: {
      return {
        ...state,
        potentialStudents: action.payload,
      }
    }
    case DELETE_MANAGER_POSSIBLE_STUDENT: {
      let leftStudents = potentialStudents.filter(e => e.id !== action.payload)
      return {
        ...state,
        potentialStudents: leftStudents,
      }
    }
    case CHANGE_MANAGER_POTENTIAL_STUDENT: {
      let leftStudents = potentialStudents.filter(e => e.id !== action.payload)
      return {
        ...state,
        potentialStudents: leftStudents,
        students: [],
      }
    }
    case SET_STUDENT_PAYMENTS: {
      let balanceCount = 0
      let passedLessons = 0
      for (let payment of action.payload.payments) {
        balanceCount = balanceCount + Number(payment.package)
        if (payment.package < 0) {
          passedLessons += Math.abs(payment.package)
        }
      }

      students = students.map(item =>
        item.id === action.payload.studentId
          ? {
              ...item,
              payments: action.payload.payments,
              balanceCount,
              passedLessons,
            }
          : item
      )

      return {
        ...state,
        students,
      }
    }

    case SET_RATE_STUDENT: {
      students = students.map(item =>
        item.id === action.payload.studentId ? { ...item, rate: action.payload.rate } : item
      )
      return {
        ...state,
        students,
      }
    }
    case SET_STUDENT_DATA: {
      students = students.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      )
      return {
        ...state,
        students,
      }
    }
    case SET_POSSIBLE_STUDENT_DATA: {
      potentialStudents = potentialStudents.map(item =>
        item.id === action.payload.id ? { ...action.payload } : item
      )
      return {
        ...state,
        potentialStudents,
      }
    }
    case SET_MANAGERS: {
      return {
        ...state,
        managers: action.payload,
      }
    }
    case SET_SALES_MANAGERS: {
      return {
        ...state,
        salesManagers: action.payload,
      }
    }

    case DELETE_SALES_MANAGER_ACTION: {
      salesManagers = salesManagers.filter(item => item.id !== action.payload)
      return {
        ...state,
        salesManagers,
      }
    }
    case SET_TEACHERS_STATISTICS: {
      return {
        ...state,
        teachersStatistics: action.payload,
      }
    }
    default:
      return state
  }
}
