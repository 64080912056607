import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const InputField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-right: 15px;
  }
`;

export const CustomInputField = styled(TextField)<{ editActive: boolean }>`
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;

  > label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
  }
  & .MuiInput-underline:before {
    border-bottom: ${(props) => (props.editActive ? '1px solid #d3d8dd' : 'none')};
    > input {
      color: ${(props) => (props.disabled ? '#90A4BD' : '#414752')};
    }
  }
  & .MuiInput-underline {
    > input {
      color: ${(props) => (props.disabled ? '#90A4BD' : '#414752')};
      font-weight: bold;
      font-family: Open Sans;
    }
  }
`;
