import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { HomeworkStatuses } from '@models/IHomework'
import Pencil from '@components/Student/Icons/pencil.svg'
import Speech from '@components/Student/Icons/speechIcon.svg'

import Chip from '@components/Chip'
import HomeWorkStatus from './Status'
import { MainModal } from '@components'
import { IStudentProfile } from '@models/IProfile'
import StudentCard from '@components/Student/StudentCard'
import { AppDispatch, RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'
import { setStudent } from '@store/classroom/actions'
import Spinner from 'react-spinkit'
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ChipContainer = styled.span`
  display: flex;
  align-items: center;
  height: min-content;
`

export const ClassName = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 0.01em;

  color: ${props => props.theme.textColors.mainContent};
  max-width: ;
`

interface Props {
  className: string
  studentName?: string
  studentAvatar?: string | React.ReactNode | null
  isStudent?: boolean
  status?: HomeworkStatuses | null
  showStatus?: boolean
  showPencil?: boolean
  showSpeech?: boolean
  student?: IStudentProfile | null
  isGettingAI?: boolean
}

const TopContent = ({
  className,
  studentName = '',
  student,
  status = null,
  isStudent = false,
  showStatus = false,
  studentAvatar = null,
  showPencil = false,
  showSpeech = false,
  isGettingAI,
}: Props) => {
  const [studentData, setStudentData] = useState(null)
  const dispatch: AppDispatch = useDispatch()
  const students = useSelector((state: RootState) => state.classroom.students)
  const openModal = () => {
    const currentStud = students.find(item => item.id === student.id)
    setStudentData(currentStud)
  }
  const handleStudent = (data: IStudentProfile) => {
    setStudentData({ ...data })
  }
  const handleCloseStudent = async () => {
    await dispatch(setStudent(studentData))
    setStudentData(null)
  }
  const customStyles = {
    zoom: 0.3,
    color: 'orange',
    alignSelf: 'center',
    marginRight: '40px',
  }

  return (
    <Top>
      <ClassName>{className}</ClassName>
      <ChipContainer>
        {isGettingAI && isStudent && status == HomeworkStatuses.NOT_READY && showPencil && (
          <>
            <Spinner
              name="line-spin-fade-loader"
              color="#FF922D"
              className="spin"
              style={customStyles}
            />
            <HomeWorkStatus status={'Homework.UnderReview'} />
          </>
        )}
        {!isStudent && (
          <Chip name={studentName} img={studentAvatar} color="#F3F5F9" onClick={openModal} />
        )}

        {isStudent && showStatus && status && <HomeWorkStatus status={status} />}
        {showPencil && !isGettingAI && (
          <img src={Pencil} style={{ marginRight: '10px' }} alt="pencil" />
        )}
        {showSpeech && <img src={Speech} style={{ marginRight: '10px' }} alt="speech" />}
      </ChipContainer>
      <MainModal
        open={!!studentData}
        children={() => (
          <StudentCard
            studentData={studentData}
            handleClose={handleCloseStudent}
            handleChange={handleStudent}
          />
        )}
        handleClose={handleCloseStudent}
        maxWidth={'1130px'}
      />
    </Top>
  )
}

export default TopContent
