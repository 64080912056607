import { AuthActionsTypes, USER_LOGIN, USER_LOGOUT } from "./types"

export const userLoginAction=(): AuthActionsTypes=>{
    return{
        type: USER_LOGIN,
    }
  
}

export const userLogoutAction=(): AuthActionsTypes=>{
    return{
        type: USER_LOGOUT,
    }
  
}