import { ILesson, ILessonHomework } from '@models';

export const SET_COURSE_LESSON = 'SET_COURSE_LESSON';
export const CLEAR_COURSE_LESSON = 'CLEAR_COURSE_LESSON';
export const SET_IS_LOADING_COURSE_LESSON = 'SET_IS_LOADING_COURSE_LESSON';

interface SetCourseLessonAction {
  type: typeof SET_COURSE_LESSON;
  payload: ILesson | ILessonHomework;
}

interface ClearCourseLessonAction {
  type: typeof CLEAR_COURSE_LESSON;
}

interface SetIsLoadingCourseLessonAction {
  type: typeof SET_IS_LOADING_COURSE_LESSON;
  payload: boolean;
}

export type CourseActionsTypes =
  | SetCourseLessonAction
  | SetIsLoadingCourseLessonAction
  | ClearCourseLessonAction;
