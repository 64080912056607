import React from 'react'

import CurrencyInputMat from 'react-currency-input-field'
const CurrencyInput = ({ onChange, inputRef, ...inputProps }) => {
  return (
    <CurrencyInputMat
      id="validationCustom04"
      {...inputProps}
      intlConfig={{
        locale: 'de-DE',
        currency: 'EUR',
      }}
      allowNegativeValue={false}
      decimalsLimit={6}
      step={1}
      onValueChange={value => {
        onChange({ target: { value } })
      }}
    />
  )
}

export default CurrencyInput
