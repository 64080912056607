import React, { useCallback, useEffect, useState } from 'react'
import Modal, { defaultStyles, setAppElement } from 'react-modal'

import ModalHeader from '@components/common/Modal/modalHeader'
import ModalMedia from '@components/common/Modal/modalMedia'
import ModalInputs from '@components/common/Modal/modalInputs'
import ModalActions from '@components/common/Modal/modalActions'
import { useSelector } from 'react-redux'

const customStyles = {
  content: {
    border: 0,
    background: 'transparent',
    height: '100%',
  },
}

defaultStyles.overlay.bgColor = 'rgba(48, 72, 89, 0.73)'
defaultStyles.overlay.zIndex = 1071
setAppElement('#app')

const CreationModal = ({
  visibility,
  onSubmit,
  onClose,
  preloadedState,
  isOnlyActions,
  isMediaBlockHidden,
  isDescriptionBlockHidden,
  localization: {
    title,
    mediaTitle,
    inputFileTitle,
    inputFileSize,
    inputPlaceholderName,
    inputPlaceholderDescription,
    checkboxMiniTitle,
    actionCreate,
    actionCancelCreation,
    timePlaceholderTitle,
  },
}) => {
  const initialState = {
    image: null,
    name: '',
    description: '',
    time: '',
    createdBy: '',
    status: '',
  }

  if (!timePlaceholderTitle) {
    delete initialState.time
  }

  const profile = useSelector(state => state.profile.profile)

  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (preloadedState) {
      setState(preloadedState)
    }
  }, [preloadedState])

  const handleChooseImage = useCallback(
    image => {
      setState(prevState => ({
        ...prevState,
        image,
      }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const handleChooseField = useCallback(
    function(value) {
      switch (this.target) {
        case 'name-field':
          setState({
            ...state,
            name: value,
          })
          break
        case 'description-field':
          setState({
            ...state,
            description: value,
          })
          break
        case 'mini-checkbox':
          setState({ ...state, isMini: value.target.checked })
          break
        case 'time-field':
          const regex = /^[0-9\b]+$/

          if (value === '' || regex.test(parseInt(value))) {
            if (value.startsWith('00')) {
              value = value.replace(/^0+/, '0')
            }
            if (parseInt(value) > 720) {
              value = 720
            }
            setState({ ...state, time: parseInt(value) || value })
          }
          break
        default:
          return
      }
    },
    [state]
  )

  const handleCloseModal = useCallback(() => {
    onClose(false)
    setState(initialState) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitModal = useCallback(() => {
    onSubmit({ ...state, CreatedBy: profile.id })
    setState(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Modal isOpen={visibility} style={customStyles}>
      <div className="modal-container">
        <div className="modal">
          <ModalHeader
            localization={{
              title,
            }}
          />
          {!isMediaBlockHidden ? (
            <ModalMedia
              preloadedImage={state.image}
              onChooseImage={handleChooseImage}
              localization={{
                mediaTitle,
                inputFileTitle,
                inputFileSize,
              }}
            />
          ) : null}
          <ModalInputs
            preloadedName={state.name}
            preloadedDescription={state.description}
            preloadedTime={state.time}
            isMini={!!state.isMini}
            onChangeField={handleChooseField}
            isDescriptionBlockHidden={isDescriptionBlockHidden}
            localization={{
              inputPlaceholderName,
              inputPlaceholderDescription,
              checkboxMiniTitle,
              timePlaceholderTitle,
            }}
          />
          <ModalActions
            isDisabled={!state.name || (!state.description && !state.time)}
            description={!isDescriptionBlockHidden && !state.time ? state.description : ' '}
            isDescriptionBlockHidden={isDescriptionBlockHidden}
            onSubmit={handleSubmitModal}
            onClose={handleCloseModal}
            localization={{
              actionCreate,
              actionCancelCreation,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreationModal
