import React, { useRef } from 'react'
import { useEffect } from 'react'
import MicroModal from 'react-micro-modal'

import '../Modal//modal.scss'

function ZoomImage(props) {
  const { ...rest } = props

  let startY
  let startX
  let scrollLeft
  let scrollTop
  let isDown
  const modelRef = useRef()
  const handelMouseDown = (event: any) => {
    isDown = true
    startY = event.pageY - modelRef.current.offsetTop
    startX = event.pageX - modelRef.current.offsetLeft
    scrollLeft = modelRef.current.scrollLeft
    scrollTop = modelRef.current.scrollTop
    modelRef.current.style.cursor = 'grabbing'
  }

  const handelMouseUp = (event: any) => {
    isDown = false
    modelRef.current.style.cursor = 'grab'
  }
  const handelMouseLeave = (event: any) => {
    isDown = false
    modelRef.current.style.cursor = 'grab'
  }
  const handelMouseMove = (event: any) => {
    if (isDown) {
      event.preventDefault()
      //Move vertcally
      const y = event.pageY - modelRef.current.offsetTop
      const walkY = y - startY
      modelRef.current.scrollTop = scrollTop - walkY

      //Move Horizontally
      const x = event.pageX - modelRef.current.offsetLeft
      const walkX = x - startX
      modelRef.current.scrollLeft = scrollLeft - walkX
      modelRef.current.style.cursor = 'grabbing'
    }
  }

  return (
    props.open && (
      <div
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: '#00000087',
          zIndex: '10000',
          top: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'auto',
          display: 'flex',
        }}
        onMouseDown={handelMouseDown}
        onMouseUp={handelMouseUp}
        onMouseMove={handelMouseMove}
        onMouseLeave={handelMouseLeave}
        ref={modelRef}
      >
        {props.children}
      </div>
    )
  )
}

ZoomImage.defaultProps = {
  open: false,
  closeOnOverlayClick: true,
  closeOnEscapePress: true,
  max: false,
}

export default ZoomImage
