import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { ILessonHomework, HomeworkStatuses } from '@models'

import * as Styled from './homework-lesson-card.styles'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { removeCheckedHomeWork } from '@servise/homeworkAPI'
import { authRef } from '@configs/firebase'
import { getPageCountInfo } from '@pages/student/StudentHomework/helpers'

interface HomeWorkLessonCardProps {
  homeWork: ILessonHomework
}

const HomeWorkLessonCard: FC<HomeWorkLessonCardProps> = ({ homeWork }) => {
  const { passedCount, totalCount } = useMemo(() => getPageCountInfo(homeWork.answers.progress), [
    homeWork,
  ])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { teacherId, studentId, id } = homeWork
  const history = useHistory()

  const removeHomework = useCallback(() => {
    setLoading(true)

    dispatch(removeCheckedHomeWork(authRef.currentUser.uid, teacherId, id, true)).then(() =>
      setLoading(false)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherId, studentId, id])

  const goToHomeLessonPage = useCallback(() => {
    history.push(`/homework/${homeWork.id}/page/${homeWork.pages[0].id}`)
  }, [history, homeWork])

  return (
    <Styled.FlexWrapper>
      <Styled.Container
        isNew={homeWork.status === HomeworkStatuses.NOT_READY && !passedCount}
        status={homeWork.status}
      >
        <Styled.LessonInformation>
          <Styled.LessonName>{homeWork.name}</Styled.LessonName>
          <div>
            <Styled.PagesProgressHint>{t('HomeWork.PagesCompleted')}:</Styled.PagesProgressHint>
            <Styled.PagesCount>
              {passedCount}/{totalCount}
            </Styled.PagesCount>
          </div>
        </Styled.LessonInformation>
        {!passedCount && (
          <>
            <Styled.WillNotButton onClick={removeHomework} disabled={loading}>
              {t('Homework.WillNotDo')}
            </Styled.WillNotButton>

            <Styled.ActionButton onClick={goToHomeLessonPage}>
              {t('HomeWork.Proceed')}
            </Styled.ActionButton>
          </>
        )}

        {passedCount > 0 && passedCount !== totalCount && (
          <Styled.ActionButton onClick={goToHomeLessonPage}>
            {t('HomeWork.Continue')}
          </Styled.ActionButton>
        )}
        {totalCount > 0 && passedCount === totalCount && (
          <div>
            <Styled.DoneLabel>{t('HomeWork.Done')}</Styled.DoneLabel>
            <Styled.ArchiveButton
              onClick={() => {
                goToHomeLessonPage()
              }}
            >
              {t('HomeWork.Look')}
            </Styled.ArchiveButton>
          </div>
        )}
      </Styled.Container>
    </Styled.FlexWrapper>
  )
}

export { HomeWorkLessonCard }
