import { CalendarActionsTypes, SET_CALENDAR_EVENTS, ADD_EVENT, UPDATE_EVENT, DELETE_EVENT } from './types';
import { IEvent } from '@models/ICalendar';

export const setCalendarEventsAction = (events: IEvent[]): CalendarActionsTypes => {
  return {
    type: SET_CALENDAR_EVENTS,
    payload: events,
  };
};

export const addEventAction = (event: IEvent): CalendarActionsTypes => {
  return {
    type: ADD_EVENT,
    payload: event,
  };
};

export const updateEventAction = (event: IEvent): CalendarActionsTypes => {
  return {
    type: UPDATE_EVENT,
    payload: event,
  };
};

export const deleteEventAction = (eventID: string): CalendarActionsTypes => {
  return {
    type: DELETE_EVENT,
    payload: eventID,
  };
};
