import React from 'react'
function StatisticsIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <rect x="2" y="18" width="4" height="12" rx="1.5" fill="white" />
        <rect x="9" y="7" width="4" height="23" rx="1.5" fill="white" />
        <rect x="16" y="13" width="4" height="17" rx="1.5" fill="white" />
        <rect x="23" y="4" width="4" height="26" rx="1.5" fill="white" />{' '}
      </g>
    </svg>
  )
}

export default StatisticsIcon
