import { IWord } from '@models/IWord'

export const SET_WORDS = 'SET_WORDS'
export const ADD_NEW_WORD = 'ADD_NEW_WORD'
export const DELETE_WORD = 'DELETE_WORD'
export const IS_LIST_LOADED = 'IS_LIST_LOADED'

export interface IWordsState {
  words: IWord[]
  isListLoaded: boolean
  isLoading: boolean
}

interface ISetWords {
  type: typeof SET_WORDS
  payload: IWord[]
}

interface IAddNewWord {
  type: typeof ADD_NEW_WORD
  payload: IWord
}

interface IDeleteWord {
  type: typeof DELETE_WORD
  payload: string
}

interface IListLoaded {
  type: typeof IS_LIST_LOADED
}

export type WordsActionsTypes = ISetWords | IAddNewWord | IDeleteWord | IListLoaded
