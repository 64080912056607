import { INTERESTS } from '@utils/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './steps.styles'

export default function({
  interests,
  onChange,
}: {
  interests?: string[]
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()

  const handleChangeInterests = (value: string) => {
    const newInterests = interests.includes(value)
      ? interests.filter(interest => interest !== value)
      : interests.concat(value)
    onChange({ name: 'interests', value: newInterests })
  }
  return (
    <Styled.InterestsContainer>
      {INTERESTS.map(interest => {
        let img = require(`../../../../Icons/${interest.id}.svg`)
        const active = interests.includes(interest.id)
        return (
          <Styled.InterestCard
            key={interest.id}
            active={active}
            onClick={() => handleChangeInterests(interest.id)}
          >
            <img src={img.default} alt={interest.name} style={{ width: 40, height: 40 }} />
            {t(interest.name)}
          </Styled.InterestCard>
        )
      })}

      <Styled.ChoosedLabel>
        {t('Выбрано')}: {interests.length} {interests.length <= 3 && '/3'}
      </Styled.ChoosedLabel>
    </Styled.InterestsContainer>
  )
}
