import React, {
  FC,
  Fragment,
  ChangeEvent,
  KeyboardEvent,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useRouteMatch } from 'react-router'
import AutosizeInput from 'react-input-autosize'

import {
  LessonWidgetType,
  IInsertWordState,
  ILessonStudentPageParams,
  IPageStateComponentProps,
  IExerciseContent,
  IRealPageStates,
} from '@models'
import { database } from '@configs/firebase'
import eyeButton from '@images/eyeButton.svg'

import * as Styled from './exercise.styles'

import { makeStyles, Tooltip } from '@material-ui/core'

import EnterIcon from '@assets/icons/EnterIcon'
import CounterOneIcon from '@assets/icons/CounterOneIcon'
import CounterTwoIcon from '@assets/icons/CounterTwoIcon'
import CounterThreeIcon from '@assets/icons/CounterThreeIcon'
import { useSelector } from 'react-redux'
import { RootState } from '@store'
import CounterZeroIcon from '@assets/icons/CounterZeroIcon'
import SpanishSymbol from '@components/SpanishSymbols/SpanishSymbol'
import { useTranslation } from 'react-i18next'
import { audioProps } from '@models/IFactory'
interface InsertWordExerciseProps
  extends Omit<IPageStateComponentProps<IExerciseContent, IInsertWordState>, 'content'> {
  text: string
  insertCorrectForm?: boolean
  widgetType: LessonWidgetType
  pagestate?: IRealPageStates
  audios: audioProps
}

export const areEqualsInputs = (
  current: string,
  correctChunk: string,
  insertCorrectForm: boolean
): boolean => {
  const chunks = correctChunk.split('/')
  const correct = insertCorrectForm && chunks.length > 1 ? chunks[1] : correctChunk
  let removedPunctuationCorrect = correct.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~¡¿]/g, '')

  let replacedCurrent = current.toLocaleLowerCase().trim()
  let replacedCorrect = correct.toLocaleLowerCase().trim()
  let replacedRemovedPunctuationCorrect = removedPunctuationCorrect.toLocaleLowerCase().trim()

  return (
    replacedCurrent === replacedCorrect || replacedCurrent === replacedRemovedPunctuationCorrect
  )
}
const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'transparent',
    marginTop: '-73px',
    marginLeft: '-100px',
  },
  helpTooltip: {
    background: 'transparent',
    marginTop: '-80px',
    marginLeft: '-35px',
  },
}))

const InsertWordExercise: FC<InsertWordExerciseProps> = ({
  text,
  insertCorrectForm,
  widgetType,
  realTimeState,
  currentPage,
  id,
  isCurrentLesson,
  pagestate,
  saveStudentHomework,
  audios,
}) => {
  const chunksRef = useRef<string[]>(text.split(/\[(.+?)\]/g))
  const { current: chunks } = chunksRef
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    chunksRef.current = text.split(/\[(.+?)\]/g)
  }, [text])
  const { t } = useTranslation()
  const classes = useStyles()
  const [values, setValues] = useState<IInsertWordState>(null)
  const [isChangedIndex, setIsChangedIndex] = useState<number>(-1)

  const initialValues = useMemo<IInsertWordState>(() => {
    const data: IInsertWordState = {}
    for (let i = 1; i < chunks.length; i += 2) {
      if (insertCorrectForm) {
        const [_, correct] = chunks[i].split('/')
        data[`i-${i}`] = {
          correct: correct ? correct : '',
          current: '',
          try: 0,
        }
      } else {
        data[`i-${i}`] = { correct: chunks[i], current: '', try: 0 }
      }
    }

    return data
  }, [chunks, insertCorrectForm])

  const { lessonId, courseId, studentId } = useRouteMatch<ILessonStudentPageParams>().params
  const [inputEvent, setInputEvent] = useState()
  useEffect(() => {
    const currentValue = pagestate?.[`pageId-${currentPage}`]?.[`exerciseId-${id}`]

    if (currentValue) {
      setValues(currentValue as IInsertWordState)
    } else {
      setValues(initialValues)
    }
  }, [currentPage, id, initialValues, pagestate])
  const saveRealTimeState = useCallback(
    (updatedState: IInsertWordState) => {
      database.ref(`courses/${studentId}/${courseId}/${lessonId}/pageId-${currentPage}`).update({
        [`exerciseId-${id}`]: updatedState,
      })
    },
    [currentPage, studentId, courseId, lessonId, id]
  )

  useEffect(() => {
    if (!realTimeState || realTimeState.clear) return setValues(initialValues)
    setValues(realTimeState)
  }, [realTimeState, setValues, initialValues])

  const states = useSelector((state: RootState) => ({
    isStudent: state.profile.isStudent,
  }))
  const handleChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      event.target.type && setInputEvent(event.target)
      const data = values[`i-${index}`]
      const updatedState: IInsertWordState = {
        ...values,
        [`i-${index}`]: {
          ...data,
          current: value,
          isValidated: false,
        },
      }

      setIsChangedIndex(!!value?.trim() ? index : -1)
      setValues(updatedState)
      if (isCurrentLesson && studentId) saveRealTimeState(updatedState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, isCurrentLesson, studentId, saveRealTimeState, saveStudentHomework, id]
  )

  const validateField = (current, correct, classList) => {
    if (!current) {
      classList.remove('input-word-success')
      classList.remove('input-word-error')
      return false
    }
    if (current && areEqualsInputs(current, correct, insertCorrectForm)) {
      classList.remove('input-word-error')
      classList.add('input-word-success')
      return true
    }
    if (current && !areEqualsInputs(current, correct, insertCorrectForm)) {
      classList.remove('input-word-success')
      classList.add('input-word-error')
      return false
    }
  }

  const handleBlur = useCallback(
    (index: number) => ({ target: { value, classList } }: ChangeEvent<HTMLInputElement>) => {
      setValues(state => {
        const data = state[`i-${index}`]
        if (data) validateField(value, data.correct, classList)
        return state
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleEyeBtn = (index: number) => {
    const data = values[`i-${index}`]
    const updatedState = {
      ...values,
      [`i-${index}`]: {
        ...data,
        current: data.correct,
        showEye: false,
      },
    }
    setValues(updatedState)
    if (isCurrentLesson && studentId) saveRealTimeState(updatedState)
    if (saveStudentHomework) saveStudentHomework({ [`exerciseId-${id}`]: updatedState })
  }

  useEffect(() => {
    if (isValid === true && isPlaying) {
      audios.rightAnswerSound.currentTime = 0
      audios.rightAnswerSound.play()
    }
    if (isValid === false && isPlaying) {
      audios.wrongAnswerSound.currentTime = 0
      audios.wrongAnswerSound.play()
    }
    setIsPlaying(false)
  }, [isPlaying, isValid])

  const handleKeyPress = useCallback(
    (index: number) => ({
      key,
      currentTarget: { classList, name },
    }: KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter') {
        if (isChangedIndex !== index) return
        setIsChangedIndex(-1)
        const data = values[`i-${index}`]
        if (data && key === 'Enter') {
          const isValid = validateField(data.current, data.correct, classList)
          setIsPlaying(true)
          setIsValid(isValid)
        }
        const updatedState: IInsertWordState = {
          ...values,
          [`i-${index}`]: {
            ...data,
            isValidated: true,
            try:
              classList.value.length < 25
                ? data.current.length > 0 && classList.value !== 'input-word-success'
                  ? data.try >= 0
                    ? data.try + 1
                    : 1
                  : data.try
                : data.current.length > 0 && classList.value.split(' ')[3] !== 'input-word-success'
                ? data.try >= 0
                  ? data.try + 1
                  : 1
                : data.try,
          },
        }
        setValues(updatedState)
        if (isCurrentLesson && studentId) saveRealTimeState(updatedState)
        if (saveStudentHomework) {
          saveStudentHomework({ [`exerciseId-${id}`]: updatedState })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values,
      setValues,
      isCurrentLesson,
      studentId,
      saveRealTimeState,
      saveStudentHomework,
      id,
      isChangedIndex,
    ]
  )

  const renderInputField = useMemo(
    () => ({ chunk, i }) => {
      const { correct, current, try: number, showEye = true, isValidated } =
        values?.[`i-${i}`] ?? {}
      const isCorrect =
        current && isValidated && areEqualsInputs(current, correct, insertCorrectForm)
      const isNotCorrent =
        current && isValidated && !areEqualsInputs(current, correct, insertCorrectForm)
      return (
        <>
          <Styled.InputFieldWrapper>
            <Styled.InputWrapper
              style={{
                borderColor: isCorrect
                  ? '#bee2c2'
                  : !states.isStudent && isNotCorrent
                  ? '#fcd8d8'
                  : '#90a4bd',
                backgroundColor: isCorrect ? '#bee2c2' : isNotCorrent ? '#fcd8d8' : 'transparent',
              }}
            >
              <div style={{ position: 'relative' }}>
                <AutosizeInput
                  inputStyle={{
                    fontSize: 14,
                    height: 34,
                    minWidth: 44,
                    background: 'inherit',
                  }}
                  name={`field-${i}`}
                  injectStyles={false}
                  onChange={handleChange(i)}
                  onBlur={handleBlur(i)}
                  onKeyPress={handleKeyPress(i)}
                  value={current || ''}
                  placeholder={insertCorrectForm ? chunk.split('/')[0] : ''}
                  disabled={widgetType === LessonWidgetType.teacher || isCorrect}
                />
                {isChangedIndex === i && (
                  <SpanishSymbol
                    event={inputEvent}
                    isExercise
                    onchangeSymbol={value =>
                      handleChange(i)({ target: { value, classList: null } })
                    }
                  />
                )}
              </div>

              {states.isStudent && isChangedIndex === i && !isCorrect && (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={
                    <div
                      style={{
                        background: '#414752',
                        color: '#fff',
                        borderRadius: 8,
                        fontSize: 13,

                        lineHeight: '19px',
                        padding: '2px 6px',
                        gap: '10px',
                      }}
                    >
                      Нажмите на Enter для проверки
                    </div>
                  }
                  placement="bottom-start"
                >
                  <button
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={e => {
                      const click = handleKeyPress(i)
                      click({ ...e, key: 'Enter' })
                    }}
                  >
                    <EnterIcon />
                  </button>
                </Tooltip>
              )}
            </Styled.InputWrapper>

            {number > 0 && (
              <>
                {number === 0 && !isValidated && (
                  <div style={{ display: 'inline-flex' }}>
                    <button style={{ marginLeft: 12 }}>
                      <CounterZeroIcon />
                    </button>
                  </div>
                )}
                {number === 1 && (
                  <div style={{ display: 'inline-flex' }}>
                    <Tooltip
                      classes={{ tooltip: classes.helpTooltip }}
                      title={
                        <div
                          style={{
                            background: '#414752',
                            color: '#fff',
                            borderRadius: 8,
                            display:
                              isValidated && !areEqualsInputs(current, correct, insertCorrectForm)
                                ? 'block'
                                : 'none',
                            fontSize: 13,
                            lineHeight: '19px',
                            padding: '2px 6px',
                            gap: '10px',
                          }}
                        >
                          {t('2AttemptsHintOpen')}
                        </div>
                      }
                      placement="bottom-start"
                    >
                      <button style={{ marginLeft: 12 }}>
                        <CounterOneIcon />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {number === 2 && (
                  <div style={{ display: 'inline-flex' }}>
                    <Tooltip
                      classes={{ tooltip: classes.helpTooltip }}
                      title={
                        <div
                          style={{
                            background: '#414752',
                            color: '#fff',
                            borderRadius: 8,
                            display:
                              isValidated && !areEqualsInputs(current, correct, insertCorrectForm)
                                ? 'block'
                                : 'none',
                            fontSize: 13,
                            lineHeight: '19px',
                            padding: '2px 6px',
                            gap: '10px',
                          }}
                        >
                          {t('1AttemptsHintOpen')}
                        </div>
                      }
                      placement="bottom-start"
                    >
                      <button style={{ marginLeft: 12 }}>
                        <CounterTwoIcon />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {number >= 3 && (
                  <div style={{ display: 'inline-flex' }}>
                    <button style={{ marginLeft: 12 }}>
                      <CounterThreeIcon />
                    </button>
                  </div>
                )}
                {number > 2 && showEye && (
                  <div style={{ display: 'inline-flex' }} onClick={() => handleEyeBtn(i)}>
                    {states.isStudent && (
                      <Tooltip
                        classes={{ tooltip: classes.helpTooltip }}
                        title={
                          <div
                            style={{
                              background: '#414752',
                              color: '#fff',
                              display:
                                isValidated && !areEqualsInputs(current, correct, insertCorrectForm)
                                  ? 'block'
                                  : 'none',
                              borderRadius: 8,
                              fontSize: 13,
                              lineHeight: '19px',
                              padding: '2px 6px',
                              gap: '10px',
                            }}
                          >
                            Показать ответ
                          </div>
                        }
                        placement="bottom-start"
                      >
                        <button>
                          <Styled.EyeButton src={eyeButton} />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                )}
              </>
            )}
          </Styled.InputFieldWrapper>
          {widgetType === LessonWidgetType.teacher && (
            <Styled.TeacherHint>
              {insertCorrectForm ? chunk.split('/')[1] : chunk}
            </Styled.TeacherHint>
          )}
        </>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, isChangedIndex]
  )

  return (
    <>
      <Styled.InsertWordWrapper id={`exercise-${id}`}>
        {chunks.map((chunk, i) => {
          const splitChunk = chunk.split(/[\r\n]+/g)

          return splitChunk.map((t, j) => {
            return (
              <Fragment key={`InsertWordExercise_splitChunk_${id}_${i}_${j}`}>
                {i % 2 === 0 ? (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t,
                      }}
                    />
                    {splitChunk.length > 1 && j !== splitChunk.length - 1 && (
                      <>
                        <br />
                        {chunk.includes(t + '\n\n') ? <br /> : ''}
                      </>
                    )}
                  </>
                ) : (
                  renderInputField({ chunk, i })
                )}
              </Fragment>
            )
          })
        })}
      </Styled.InsertWordWrapper>
    </>
  )
}

export { InsertWordExercise }
