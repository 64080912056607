import { createReducer } from '@reduxjs/toolkit'

import {
  GET_TESTS_FULFILLED,
  GET_TESTS_REJECTED,
  CREATE_TEST_FULFILLED,
  CREATE_TEST_REJECTED,
  EDIT_TEST_REJECTED,
  DELETE_TEST_REJECTED,
  DUPLICATE_TEST_REJECTED,
  RESET_ALL_TESTS,
} from '@types/tests'

const testsReducer = createReducer(
  {
    testsList: [],
    error: null,
  },
  {
    [GET_TESTS_FULFILLED]: (state, { payload: { tests } }) => {
      return ({
        ...state,
        testsList: [...tests],
        error: null,
      })
    },
    [GET_TESTS_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [CREATE_TEST_FULFILLED]: (state, { payload: { tests } }) => ({
      ...state,
      testsList: [...tests],
      error: null,
    }),
    [CREATE_TEST_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [EDIT_TEST_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DELETE_TEST_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DUPLICATE_TEST_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [RESET_ALL_TESTS]: (state, { payload }) => ({
      ...state,
      testsList: [],
      error: null,
    }),
  }
)

export default testsReducer
