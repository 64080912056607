import React from 'react';

export default function CalendarMenuIcon(props) {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.5'>
        <path
          d='M10.5 16.5H13.5V19.5H10.5V16.5ZM10.5 22.5H13.5V25.5H10.5V22.5ZM16.5 16.5H19.5V19.5H16.5V16.5ZM16.5 22.5H19.5V25.5H16.5V22.5ZM22.5 16.5H25.5V19.5H22.5V16.5ZM22.5 22.5H25.5V25.5H22.5V22.5Z'
          fill='white'
        />
        <path
          d='M7.5 33H28.5C30.1545 33 31.5 31.6545 31.5 30V9C31.5 7.3455 30.1545 6 28.5 6H25.5V3H22.5V6H13.5V3H10.5V6H7.5C5.8455 6 4.5 7.3455 4.5 9V30C4.5 31.6545 5.8455 33 7.5 33ZM28.5 12L28.5015 30H7.5V12H28.5Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
