import React from 'react'
import * as Styled from './package.tag.style'

interface Props {
    title: string
    index: number
    selected: number
    widthProp: number
    setSelected: (index: number)=>void;
}

const divCss = (widthProp: number, align: string, bottomBorder: string) => {
    return {
        width: `${widthProp}%`,
        fontSize: '16px',
        paddingBottom: '13px',
        'text-align': align,
        'border-bottom': bottomBorder
    }
}

const PackageTag = ({ title, index, selected, setSelected, widthProp }: Props) => {
    return (
        <div 
            onClick={() => setSelected(index)} 
            style={divCss(widthProp,
                        (index === 2) ? 'left' : 'center',
                        (index === selected) ? '4px solid #1556CF' : '2px solid #C2CFE0')}
        >
            {
                index === selected ?
                    <Styled.ActiveTag> { title } </Styled.ActiveTag> :
                    <Styled.NormalTag> { title } </Styled.NormalTag>
            }
        </div>
    )
}

export default PackageTag
