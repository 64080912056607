import styled from 'styled-components'

export const WorkSheetContainer = styled.div<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;
  width: 410px;
  height: 540px;

  border-radius: 20px;

  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
  background: #ffffff;
`

export const WorkSheetTitle = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #1b3b77;

  margin: 0px 30px 20px 30px;
`

export const WorkSheetTitlePages = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #90a4bd;
  margin: 0px 30px 6px 30px;
`
export const PrevNextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 12px 30px 0px 30px;
`
export const Button = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: #4284ff;
  border-radius: 20px;
  padding: 10px 16px;
`
export const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
`
