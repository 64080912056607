import { Dispatch } from 'redux'
import moment from 'moment'
import { usersRef, studentsRef, firebaseTimestamp } from '@configs/firebase'
import { CALENDAR_EVENT_TYPES, TZ_MSK } from '@utils/constants'

import { IEvent, IMessage, MessageType } from '@models'

import { CalendarActionsTypes } from '@store/calendar/types'
import { addEventAction, deleteEventAction, setCalendarEventsAction } from '@store/calendar/actions'
import { getWeeklyLoadEvents } from '@utils/helpers'

import { chatAPI } from './chatAPI'
import firebase from 'firebase/app'
import { calendarUtil } from '@utils/calendarUtil'
import { fetchStudentPayments } from './profileAPI'

export const convertEventsToTimezone = (event, timeZone) => {
  let timeZoneUser = parseInt(
    moment
      .tz(timeZone)
      .format('Z')
      .substr(0, 3)
  )

  let timeZoneEvent = parseInt(
    moment
      .tz(event.timeZone)
      .format('Z')
      .substr(0, 3)
  )

  let time: any = parseFloat(
    event.rrule.dtstart.substring(11, 13) + '.' + event.rrule.dtstart.substring(14, 16)
  )
  let byweekDay = event.rrule.byweekday
  let date = event.rrule.dtstart.substring(0, 10)
  let tz = time - (timeZoneEvent - timeZoneUser)

  if (tz < 0) {
    time = parseFloat(24 + tz).toFixed(2)
    byweekDay = byweekDay.map(weekDay => {
      return moment(date)
        .add('days', -1)
        .locale('en')
        .format('dd')
    })
    date = moment(date)
      .add('days', -1)
      .format('YYYY-MM-DD')
  } else if (tz >= 24) {
    time = parseFloat(tz - 24).toFixed(2)
    byweekDay = byweekDay.map(weekDay => {
      return moment(date)
        .add('days', 1)
        .locale('en')
        .format('dd')
    })
    date = moment(date)
      .add('days', 1)
      .format('YYYY-MM-DD')
  } else {
    time = parseFloat(tz).toFixed(2)
  }

  time = time.toString()
  let index = time.indexOf('.')
  let hours = time
  let minute = '00'

  if (index !== -1) {
    minute = time.substring(index + 1, time.length)
    hours = time.substring(0, index)
  }

  if (minute.length === 1) {
    minute = minute + '0'
  }
  time = ('0' + hours).slice(-2) + ':' + ('0' + minute).slice(-2)
  moment.tz.setDefault(timeZone)

  return {
    ...event,
    rrule: {
      ...event.rrule,
      byweekday: byweekDay,
      dtstart: moment(date + ' ' + time).format(),
    },
  }
}

export const filterEvents = (
  eventDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[],
  timeZone: string = TZ_MSK
) => {
  let events = eventDocs
    .filter(doc => doc.data().rrule)
    .map(doc => {
      let event = doc.data() as IEvent
      const type =
        event.type === 'регул.'
          ? CALENDAR_EVENT_TYPES.REGULAR
          : event.type === 'разов.'
          ? CALENDAR_EVENT_TYPES.ONCE
          : event.type === 'пробный'
          ? CALENDAR_EVENT_TYPES.TRIAL
          : event.type

      if (event?.timeZone && typeof event.rrule.dtstart === 'string') {
        event = convertEventsToTimezone(event, timeZone)
        moment.tz.setDefault('UTC')
        event.rrule.dtstart = moment(event.rrule.dtstart).format()
      }
      moment.tz.setDefault('UTC')
      if (typeof event.rrule.dtstart === 'number') {
        event.rrule.dtstart = moment(event.rrule.dtstart).format()
      }

      if (typeof event.rrule.until === 'number') {
        event.rrule.until = moment(event.rrule.until).format()
      }

      if (event.exdate) {
        event.exdate = event.exdate.map(date => moment(date).format())
      }

      return {
        ...event,
        eventID: doc.id,
        type,
      }
    }) as IEvent[]

  events = events.filter(item => item.rrule)
  return events
}

export const getCalendarEvents = (userID: string, timeZone: string) => {
  return async (dispatch: Dispatch<CalendarActionsTypes>) => {
    dispatch(setCalendarEventsAction([]))

    usersRef
      .doc(userID)
      .collection('events')
      .get()
      .then(async snapShot => {
        let events = filterEvents(snapShot.docs, timeZone)

        events = await Promise.all(
          events.map(async event => {
            const payments = await fetchStudentPayments(event.studentId)

            let balanceCount = payments.reduce((prev, payment) => prev + payment.package, 0)

            return { ...event, balanceCount }
          })
        )

        dispatch(setCalendarEventsAction(events))
        return events
      })
  }
}

export const getTeacherCalendarEvents = async (userId: string, timeZone: string) => {
  const snapshot = await usersRef
    .doc(userId)
    .collection('events')
    .get()

  const events = filterEvents(snapshot.docs, timeZone)

  return events
}
export const getStudentCalendarEvents = async (studentId: string, timeZone: string) => {
  const snapshot = await studentsRef
    .doc(studentId)
    .collection('events')
    .get()

  const events = filterEvents(snapshot.docs, timeZone)

  return events
}

export const getStudentCalendarEventsRealtime = (studentId: string, timeZone: string) => {
  return async (dispatch: Dispatch<CalendarActionsTypes>) => {
    dispatch(setCalendarEventsAction([]))
    studentsRef
      .doc(studentId)
      .collection('events')
      .onSnapshot(snapshot => {
        const events = filterEvents(snapshot.docs, timeZone)
        dispatch(setCalendarEventsAction(events))
        return events
      })
  }
}

export const addEvent = (event: IEvent) => {
  return async (dispatch: Dispatch<CalendarActionsTypes>) => {
    try {
      moment.tz.setDefault('UTC')
      const dtstart = calendarUtil.convertToUTC(event.rrule.dtstart)

      let byweekday = [
        moment(dtstart.format().substring(0, 10))
          .locale('en')
          .format('dd'),
      ]

      let dbEvent: IEvent = {
        ...event,
        rrule: { ...event.rrule, dtstart: dtstart.valueOf(), byweekday },
      }
      if (event.rrule.count > 1) {
        dbEvent.rrule.until = calendarUtil
          .convertToUTC(
            moment(event.rrule.dtstart)
              .add(7 * (event.rrule.count - 1), 'days')
              .format()
          )
          .valueOf()
      }

      const eventInfo = await usersRef
        .doc(event.teacherId)
        .collection('events')
        .add(dbEvent)

      if (event.studentId && event.type !== CALENDAR_EVENT_TYPES.BOOKED) {
        await studentsRef
          .doc(event.studentId)
          .collection('events')
          .doc(eventInfo.id)
          .set(dbEvent)
      }
      dispatch(addEventAction({ ...event, eventID: eventInfo.id }))
    } catch (err) {
      console.error('addEvent', err)
    }
  }
}

export const updateEvent = (
  event: IEvent,
  prevStudentID: string | null,
  cancelEvent: string | null
) => {
  return async (dispatch: Dispatch<CalendarActionsTypes>) => {
    try {
      moment.tz.setDefault('UTC')
      const dtstart = calendarUtil.convertToUTC(event.rrule.dtstart)

      const byweekday = [
        moment(dtstart.format().substring(0, 10))
          .locale('en')
          .format('dd'),
      ]
      let currentEvent = {
        ...event,
        rrule: { ...event.rrule, byweekday, dtstart: dtstart.valueOf() },
      }

      if (cancelEvent) {
        const cancelEventDate = calendarUtil.convertToUTC(cancelEvent)

        const exdate = (currentEvent.exdate || []).map(datetime =>
          calendarUtil.convertToUTC(datetime).valueOf()
        )
        currentEvent.exdate = [...exdate, cancelEventDate.valueOf()]
        // currentEvent.rrule.count = currentEvent.rrule.count - 1
        const data: { id: string; message: IMessage } = {
          id: `${event.studentId}${event.teacherId}`,
          message: {
            id: event.teacherId,
            m: '',
            plannedEvent: calendarUtil.convertToMSK(cancelEventDate.format()).format(),
            date: firebaseTimestamp.now().toMillis(),
            type: MessageType.schedule,
            eventType: event.type,
          },
        }
        await chatAPI.sendMessage(data)
        await chatAPI.addUnreadMessage({
          userId: event.teacherId,
          isTeacher: false,
          addUserId: event.studentId,
        })

        await chatAPI.addUnreadMessage({
          userId: event.studentId,
          isTeacher: true,
          addUserId: event.teacherId,
        })
      }
      let dbEvent = { ...currentEvent }
      delete dbEvent?.eventID
      delete dbEvent?.balanceCount
      if (currentEvent.rrule.count > 1) {
        currentEvent.rrule.until = calendarUtil
          .convertToUTC(
            moment(currentEvent.rrule.dtstart)
              .add(7 * (currentEvent.rrule.count - 1), 'days')
              .format()
          )
          .valueOf()
      }

      await usersRef
        .doc(currentEvent.teacherId)
        .collection('events')
        .doc(currentEvent.eventID)
        .update(dbEvent)

      if (prevStudentID && prevStudentID !== currentEvent.studentId) {
        await studentsRef
          .doc(prevStudentID)
          .collection('events')
          .doc(currentEvent.eventID)
          .delete()
      }

      await studentsRef
        .doc(currentEvent.studentId)
        .collection('events')
        .doc(currentEvent.eventID)
        .set(dbEvent)
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

export const deleteEvent = ({
  event,
  isTeacher = true,
  isSendChat = true,
}: {
  event: IEvent
  isTeacher?: boolean
  isSendChat?: boolean
}) => {
  return async (dispatch: Dispatch<CalendarActionsTypes>) => {
    await usersRef
      .doc(event.teacherId)
      .collection('events')
      .doc(event.eventID)
      .delete()
    if (event.studentId) {
      await studentsRef
        .doc(event.studentId)
        .collection('events')
        .doc(event.eventID)
        .delete()
    }

    if (isSendChat && event.studentId) {
      const data: { id: string; message: IMessage } = {
        id: `${event.studentId}${event.teacherId}`,
        message: {
          id: isTeacher ? event.teacherId : event.studentId,
          m: '',
          plannedEvent: calendarUtil.convertToMSK(event.rrule.dtstart).format(),
          date: firebaseTimestamp.now().toMillis(),
          type: MessageType.schedule,
          eventType: event.type,
        },
      }
      await chatAPI.sendMessage(data)
      await chatAPI.addUnreadMessage({
        userId: event.teacherId,
        isTeacher: false,
        addUserId: event.studentId,
      })

      await chatAPI.addUnreadMessage({
        userId: event.studentId,
        isTeacher: true,
        addUserId: event.teacherId,
      })
    }

    dispatch(deleteEventAction(event.eventID))
  }
}

export const updateWeeklyLoad = (events: IEvent[], teacherId: string) => {
  const weeklyLoad = getWeeklyLoadEvents(events)
  usersRef.doc(teacherId).update({ weeklyLoad })
}

export const checkUpdateCalendar = async (bhUpdated?: firebase.firestore.Timestamp) => {
  if (!bhUpdated) {
    return true
  }
  const diff = moment().diff(moment(bhUpdated?.toDate()), 'days')
  return diff > 14
}
