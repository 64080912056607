const GET_COURSES = 'GET_COURSES'
const GET_COURSES_FULFILLED = 'GET_COURSES_FULFILLED'
const GET_COURSES_REJECTED = 'GET_COURSES_REJECTED'

const CREATE_COURSE = 'CREATE_COURSE'
const CREATE_COURSE_FULFILLED = 'CREATE_COURSE_FULFILLED'
const CREATE_COURSE_REJECTED = 'CREATE_COURSE_REJECTED'

const DELETE_COURSE = 'DELETE_COURSE'
const DELETE_COURSE_FULFILLED = 'DELETE_COURSE_FULFILLED'
const DELETE_COURSE_REJECTED = 'DELETE_COURSE_REJECTED'
const PUBLISH_COURSE = 'PUBLISH_COURSE'
const MODERATION = 'MODERATION'
const MODERATION_FULFILLED = 'MODERATION_FULFILLED'
const MODERATION_REJECTED = 'MODERATION_REJECTED'
const PUBLISH_COURSE_FULFILLED = 'PUBLISH_COURSE_FULFILLED'
const PUBLISH_COURSE_REJECTED = 'PUBLISH_COURSE_REJECTED'
const DIVERTED = 'DIVERTED'
const DIVERTED_FULFILLED = 'DIVERTED_FULFILLED'
const DIVERTED_REJECTED = 'DIVERTED_REJECTED'
const REMOVE_PUBLISH = 'REMOVE_PUBLISH'
const REMOVE_PUBLISH_FULFILLED = 'REMOVE_PUBLISH_FULFILLED'
const REMOVE_PUBLISH_REJECTED = 'REMOVE_PUBLISH_REJECTED'

const DUPLICATE_COURSE = 'DUPLICATE_COURSE'
const DUPLICATE_COURSE_FULFILLED = 'DUPLICATE_COURSE_FULFILLED'
const DUPLICATE_COURSE_REJECTED = 'DUPLICATE_COURSE_REJECTED'

const TRANSFORM_TO_MINI_COURSE = 'TRANSFORM_TO_MINI_COURSE'
const TRANSFORM_TO_MINI_COURSE_FULFILLED = 'TRANSFORM_TO_MINI_COURSE_FULFILLED'
const TRANSFORM_TO_MINI_COURSE_REJECTED = 'TRANSFORM_TO_MINI_COURSE_REJECTED'

const GET_LESSONS_LENGTH = 'GET_LESSONS_LENGTH'
const GET_LESSONS_LENGTH_FULFILLED = 'GET_LESSONS_LENGTH_FULFILLED'
const GET_LESSONS_LENGTH_REJECTED = 'GET_LESSONS_LENGTH_REJECTED'

const RESET_COURSES_LIST = 'RESET_COURSES_LIST'
const RESET_LESSONS_LENGTH_ARR = 'RESET_LESSONS_LENGTH_ARR'

const GET_ALL_LESSONS = 'GET_ALL_LESSONS'
const GET_ALL_LESSONS_FULFILLED = 'GET_ALL_LESSONS_FULFILLED'
const GET_ALL_LESSONS_REJECTED = 'GET_ALL_LESSONS_REJECTED'

const RESET_ALL_LESSONS = 'RESET_ALL_LESSONS'

export {
  GET_COURSES,
  GET_COURSES_FULFILLED,
  GET_COURSES_REJECTED,
  CREATE_COURSE,
  CREATE_COURSE_FULFILLED,
  CREATE_COURSE_REJECTED,
  DELETE_COURSE,
  DELETE_COURSE_FULFILLED,
  DELETE_COURSE_REJECTED,
  DUPLICATE_COURSE,
  DUPLICATE_COURSE_FULFILLED,
  DUPLICATE_COURSE_REJECTED,
  GET_LESSONS_LENGTH,
  GET_LESSONS_LENGTH_FULFILLED,
  GET_LESSONS_LENGTH_REJECTED,
  RESET_COURSES_LIST,
  RESET_LESSONS_LENGTH_ARR,
  GET_ALL_LESSONS,
  GET_ALL_LESSONS_FULFILLED,
  GET_ALL_LESSONS_REJECTED,
  RESET_ALL_LESSONS,
  PUBLISH_COURSE,
  MODERATION,
  MODERATION_FULFILLED,
  MODERATION_REJECTED,
  DIVERTED,
  DIVERTED_FULFILLED,
  DIVERTED_REJECTED,
  REMOVE_PUBLISH,
  REMOVE_PUBLISH_FULFILLED,
  REMOVE_PUBLISH_REJECTED,
  PUBLISH_COURSE_FULFILLED,
  PUBLISH_COURSE_REJECTED,
  TRANSFORM_TO_MINI_COURSE,
  TRANSFORM_TO_MINI_COURSE_FULFILLED,
  TRANSFORM_TO_MINI_COURSE_REJECTED,
}
