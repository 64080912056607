import * as React from 'react'

const MiniCoursesMenuIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.5247 17.028L19.8407 6.08201C19.8195 5.94525 19.7651 5.81571 19.6824 5.70481L17.1564 2.33683C16.9973 2.12476 16.7478 2 16.4828 2H13.1148C12.8334 1.99996 12.5705 2.14055 12.4143 2.37468L10.7303 4.90067C10.5419 5.18342 10.5419 5.55171 10.7303 5.83446L12.4143 8.36046C12.5703 8.5949 12.8332 8.73584 13.1148 8.736H15.512L15.096 11.6485C14.0644 10.8068 12.7613 10.37 11.4308 10.42C10.0841 10.3563 8.74919 10.6964 7.59717 11.3967C6.78803 9.00968 5.55112 6.21004 2.16881 6.21004V7.89405C4.50788 7.89405 5.33305 9.87108 6.24241 12.664C6.29948 13.0024 6.45029 13.318 6.67771 13.575C7.06131 13.8726 7.61351 13.8029 7.91107 13.4193C7.93013 13.3947 7.94789 13.3691 7.96427 13.3426C7.99289 13.2921 8.70356 12.1041 11.4308 12.1041C14.1151 12.1041 14.8164 13.9826 14.8426 14.0541C15.0035 14.4939 15.4905 14.7199 15.9302 14.5589C16.2152 14.4546 16.4228 14.2059 16.4743 13.9068L17.3163 8.01281C17.375 7.54448 17.0429 7.11727 16.5746 7.05858C16.5441 7.05475 16.5135 7.05258 16.4828 7.05207H13.5652L12.4412 5.36806L13.5635 3.68405H16.0617L18.2088 6.54683L19.7901 16.8259C18.4522 17.9037 12.7611 21.7828 2.33718 19.6988L2.0004 21.3491C4.06283 21.7721 6.16205 21.9902 8.26739 22C16.9156 22 21.0767 17.9584 21.2847 17.7504C21.4752 17.5616 21.5643 17.2933 21.5247 17.028Z" />
    </svg>
  )
}

export default MiniCoursesMenuIcon
