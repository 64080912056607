import React from 'react'

const StudentIconDark = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5358 5.0894C14.8338 4.9702 15.1662 4.9702 15.4642 5.0894L27.9642 10.0894C28.4388 10.2792 28.75 10.7389 28.75 11.25V18.75C28.75 19.4404 28.1904 20 27.5 20C26.8096 20 26.25 19.4404 26.25 18.75V13.0963L15.4642 17.4106C15.1662 17.5298 14.8338 17.5298 14.5358 17.4106L2.03576 12.4106C1.56119 12.2208 1.25 11.7611 1.25 11.25C1.25 10.7389 1.56119 10.2792 2.03576 10.0894L14.5358 5.0894ZM24.1343 11.25L15 14.9037L5.86573 11.25L15 7.59629L24.1343 11.25Z"
        fill="#7E92AC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 12C8.19036 12 8.75 12.5596 8.75 13.25V20C8.75 20.2813 9.00409 20.9078 10.2557 21.5336C11.4489 22.1302 13.1549 22.5 15 22.5C16.8451 22.5 18.5511 22.1302 19.7443 21.5336C20.9959 20.9078 21.25 20.2813 21.25 20V13.25C21.25 12.5596 21.8096 12 22.5 12C23.1904 12 23.75 12.5596 23.75 13.25V20C23.75 21.7078 22.4237 22.989 20.8623 23.7697C19.2424 24.5796 17.1332 25 15 25C12.8668 25 10.7576 24.5796 9.13768 23.7697C7.57626 22.989 6.25 21.7078 6.25 20V13.25C6.25 12.5596 6.80964 12 7.5 12Z"
        fill="#7E92AC"
      />
    </svg>
  )
}

export default StudentIconDark
