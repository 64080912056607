import { LOGIN_CODES, REGISTER_CODES } from './constants.ts'
import moment from 'moment'

import 'moment/locale/ru'
import { createBrowserHistory } from 'history'
import queryString from 'query-string'
export const history = createBrowserHistory()

/**
 *
 * @param code {number} code of error
 * @returns {string} text error
 */
export function getErrorMsgFromCode(code) {
  switch (code) {
    case 'data_not_correct':
    case LOGIN_CODES.other.not_found:
    case LOGIN_CODES.password.wrong:
      return 'Неверный логин или пароль.'

    case 'field_must_be_filled':
      return 'Все поля должны быть заполнены!'

    case 'passwords_dont_match':
      return 'Пароли не совпадают!'

    // auth --> common
    case LOGIN_CODES.email.invalid:
    case REGISTER_CODES.email.invalid:
      return 'Неверный формат email!'

    // auth --> register
    case REGISTER_CODES.email.already_in_use:
      return 'Такой email уже занят.'

    case REGISTER_CODES.other.not_allowed:
      return 'У вас нет прав доступа в базу данных.'

    case REGISTER_CODES.password.weak_password:
      return 'Пароль недостаточно силен!'

    // auth --> login
    case LOGIN_CODES.email.disabled:
      return 'Пользователь отключен.'

    default:
      return 'Неизвестная ошибка, попробуйте позже.'
  }
}

export const toHHMMSS = amountOfSeconds => {
  const sec_num = parseInt(amountOfSeconds, 10)
  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return hours + ':' + minutes + ':' + seconds
}

export const localType = url => {
  const values = queryString.parse(url)
  return values.export || values.token
}

export const secondsToPixels = (seconds, elementWidth, videoDuration) => {
  return (seconds / videoDuration) * elementWidth
}
/**
 *
 * @param loading {boolean} status of fetching data
 * @returns {object | null}
 */
export function getStylesUtilFetchData(loading) {
  return loading ? { opacity: 0.3 } : null
}

export const toFixedNumber = (number, decimals) => {
  const pow = Math.pow(10, decimals)
  return +(Math.round(number * pow) / pow)
}

export const compare = (a, b) => {
  const bandA = a.datetime ? a.datetime : a
  const bandB = b.datetime ? b.datetime : b

  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}

export const compareHours = (a, b) => {
  const bandA = a.startTime
  const bandB = b.startTime

  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}

let weekRange = {
  min: moment()
    .startOf('week')
    .toDate(),

  max: moment()
    .endOf('week')
    .toDate(),
}

export const getWeeklyLoadEvents = events => {
  let allEvents = []

  events.forEach(item => {
    let event = []
    if (
      moment(item.rrule.dtstart).toDate() >= weekRange.min &&
      moment(item.rrule.dtstart).toDate() <= weekRange.max
    ) {
      event.push(moment(item.rrule.dtstart).format())
    }

    for (let i = 1; i < item.rrule.count; i++) {
      if (
        moment(event[event.length - 1])
          .add(7, 'days')
          .toDate() >= weekRange.min &&
        moment(event[event.length - 1])
          .add(7, 'days')
          .toDate() <= weekRange.max
      ) {
        event.push(
          moment(event[event.length - 1])
            .add(7, 'days')
            .format()
        )
      }
    }

    event.forEach(dtstart => {
      allEvents.push({
        dtstart,
        ...item,
      })
    })
  })
  return allEvents.length
}

export const getDaysOfWeek = day => {
  switch (day.toLowerCase()) {
    case 'mo':
    case 'tu':
      return 1
    case 'we':
      return 2
    case 'th':
      return 3
    case 'fr':
      return 4
    case 'sa':
      return 5
    case 'su':
      return 6
    default:
      return 0
  }
}
export const getWeekDay = (increment, day) => {
  if (increment) {
    if (day === 6) {
      return 0
    } else {
      return (day % 6) + 1
    }
  } else {
    if (day === 0) {
      return 6
    } else {
      return (day % 7) - 1
    }
  }
}

export function eventTimes() {
  var quarterHours = ['00', '15', '30', '45']

  var times = []
  for (var i = 0; i < 23; i++) {
    for (var j = 0; j < 4; j++) {
      times.push({
        value: ('0' + i).slice(-2) + ':' + quarterHours[j],
        label:
          ('0' + i).slice(-2) +
          ':' +
          quarterHours[j] +
          '-' +
          ('0' + (i + 1)).slice(-2) +
          ':' +
          quarterHours[j],
      })
    }
    if (i === 22) {
      times.push({
        value: '23:00',
        label: '23:00-00:00',
      })
    }
  }

  return times
}

export const getMonthName = mday => {
  let monthName = new Date(mday).toLocaleString('ru', { month: 'long' })
  if (monthName === 'март' || monthName === 'август') {
    monthName = monthName + 'а'
  } else {
    let lastchar = monthName.substr(monthName.length - 1)
    if (lastchar === 'ь' || lastchar === 'й') {
      monthName = monthName.substr(0, monthName.length - 1) + 'я'
    } else {
      monthName = monthName + 'я'
    }
  }
  return monthName
}

export function ellipsify(str, length, end) {
  if (str.length > length) {
    return str.substring(0, length) + end
  } else {
    return str
  }
}

export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS']
export default function createAutoCorrectedDatePipe(
  dateFormat = 'mm dd yyyy',
  { minYear = 1, maxYear = 9999 } = {}
) {
  const dateFormatArray = dateFormat
    .split(/[^dmyHMS]+/)
    .sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b))
  return function(conformedValue) {
    const indexesOfPipedChars = []
    const maxValue = {
      dd: 31,
      mm: 12,
      yy: 99,
      yyyy: maxYear,
      HH: 23,
      MM: 59,
      SS: 59,
    }
    const minValue = { dd: 1, mm: 1, yy: 0, yyyy: minYear, HH: 0, MM: 0, SS: 0 }
    const conformedValueArr = conformedValue.split('')

    // Check first digit
    dateFormatArray.forEach(format => {
      const position = dateFormat.indexOf(format)
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10)

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position]
        conformedValueArr[position] = 0
        indexesOfPipedChars.push(position)
      }
    })

    // Check for invalid date
    let month = 0
    const isInvalid = dateFormatArray.some(format => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)
      if (format === 'mm') {
        month = value || 0
      }
      const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format]
      if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
        const scopedMaxValue = parseInt(
          maxValue[format].toString().substring(0, textValue.length),
          10
        )
        const scopedMinValue = parseInt(
          minValue[format].toString().substring(0, textValue.length),
          10
        )
        return value < scopedMinValue || value > scopedMaxValue
      }
      return value > maxValueForFormat || (textValue.length === length && value < minValue[format])
    })

    if (isInvalid) {
      return false
    }

    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars,
    }
  }
}

export const getBrowserUniqueId = () => {
  var navigator_info = window.navigator
  var screen_info = window.screen
  var uid = navigator_info.mimeTypes.length
  uid += navigator_info.userAgent.replace(/\D+/g, '')
  uid += navigator_info.plugins.length
  uid += screen_info.height || ''
  uid += screen_info.width || ''
  uid += screen_info.pixelDepth || ''
  return uid
}
