import React, { useCallback, useState, useEffect, memo } from 'react'
import { useDrop } from 'react-dnd'
import update from 'immutability-helper'
import Block from '@components/Block'
import { authRef } from '@configs/firebase'
import EmptyConstructor from '@assets/images/Constructor/empty-constructor.png'
import { getManagerProfile, getProfile } from '@servise/profileAPI'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { logger } from '@utils/ConsoleLogger'
import { AUTH_STATUS } from '@utils/constants'

const Constructor = ({ pageIndex, onStateChange, pageState, additions }) => {
  const [blocks, setBlocks] = useState([])
  const [alternateIds, setAlternateIds] = useState({})
  const history = useHistory()
  const dispatch = useDispatch(data => data)
  const handleAlternateIds = blocks => {
    let setter = {}
    blocks.forEach(val => {
      if (!setter[val.id]) {
        setter[val.id] = `${val.id}-${Math.random()}`
      }
    })
    setAlternateIds(setter)
  }
  const states = useSelector(state => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile,
  }))
  useEffect(() => {
    if (states.authStatus === AUTH_STATUS.ON) {
      const uid = authRef.currentUser.uid
      dispatch(getManagerProfile(uid))
        .then(profile => {
          if (!profile.googleDrive || _.isEmpty(profile.googleDrive)) {
            return
          }
        })
        .catch(e => {
          if (e && e.code === 'auth/manager-not-found') {
            dispatch(getProfile(uid))
              .then(profile => {
                if (!profile.googleDrive || _.isEmpty(profile.googleDrive)) {
                  return
                }
              })
              .catch(e => {
                logger.error('Error', e)
              })
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, states.authStatus])
  useEffect(() => {
    setBlocks(pageState)
    handleAlternateIds(pageState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex])
  const handleDrop = useCallback(
    name => {
      setBlocks([
        ...blocks,
        {
          id: blocks.length
            ? Math.max.apply(
                Math,
                blocks.map(({ id }) => id)
              ) + 1
            : 0,
          type: name,
          content: null,
          additions,
        },
      ])
      handleAlternateIds([
        ...blocks,
        {
          id: blocks.length
            ? Math.max.apply(
                Math,
                blocks.map(({ id }) => id)
              ) + 1
            : 0,
          type: name,
          content: null,
        },
      ])
    },
    [blocks, additions]
  )

  const [, drop] = useDrop({
    accept: [
      'image',
      'audio',
      'video',
      'editor',
      'action-editor',
      'link',
      'vocabulary',
      'test',
      'exercise',
      'table',
      'essay',
      'result',
    ],
    drop({ type }) {
      handleDrop(type)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType(),
    }),
  })

  const handleCopyBlock = (type, content) => {
    setBlocks([
      ...blocks,
      {
        id:
          Math.max.apply(
            Math,
            blocks.map(({ id }) => id)
          ) + 1,
        type,
        content,
      },
    ])
    handleAlternateIds([
      ...blocks,
      {
        id:
          Math.max.apply(
            Math,
            blocks.map(({ id }) => id)
          ) + 1,
        type,
        content,
      },
    ])
  }

  useEffect(() => {
    onStateChange(blocks, pageIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocks, pageIndex])

  const handleChangeStateBlock = (id, state) => {
    let newBlocks = blocks.map(block => {
      if (id !== block.id) {
        return block
      } else {
        return {
          id,
          type: block.type,
          content: state,
        }
      }
    })
    setBlocks(newBlocks)
  }

  const handleDeleteBlock = id => {
    setBlocks(blocks.filter(({ id: blockId }) => blockId !== id))
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = blocks[dragIndex]
      setBlocks(
        update(blocks, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
    },
    [blocks]
  )

  return (
    <div ref={drop} id="constructor">
      {!blocks.length ? (
        <div className="empty-constructor-container">
          <img width="200" src={EmptyConstructor} alt="" />
          <span className="empty-constructor-message">С заботой об учениках и образовании</span>
        </div>
      ) : (
        blocks.map(({ id, type, content }, i) => {
          return (
            <Block
              additions={additions}
              moveCard={moveCard}
              index={i}
              key={`${pageIndex}-${alternateIds[id]}`}
              id={id}
              actions={{
                onCopy: handleCopyBlock.bind({}, type, content),
                onDelete: handleDeleteBlock.bind({}, id),
              }}
              preloadedState={content}
              onStateChange={handleChangeStateBlock}
              type={type}
            />
          )
        })
      )}
    </div>
  )
}

export default memo(Constructor)
