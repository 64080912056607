import React from 'react'

import DefaultTeacherImg from '@images/people-default.svg'

import * as Styled from './chip.styles'

export interface Props {
  img?: string | React.ReactNode
  name: string
  color?: string | null
  chipStyle?: any
  onClick?: (e?: any) => void
  isOnline?: boolean
  isHeader?: boolean
}

const Chip = ({
  img = null,
  name,
  color = null,
  chipStyle = {},
  onClick,
  isOnline = false,
  isHeader = false,
}: Props) => {
  return (
    <Styled.Container color={color} style={chipStyle} onClick={onClick}>
      <Styled.ImageWrapper>
        <Styled.Img src={img || DefaultTeacherImg} />
        {isOnline && <Styled.IsOnline />}
      </Styled.ImageWrapper>
      <Styled.Name display="content" thin style={isHeader ? chipStyle : {}}>
        {name}
      </Styled.Name>
    </Styled.Container>
  )
}

export default Chip
