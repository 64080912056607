import React from 'react'

export default function(props) {
  if (!props.isActive) {
    return (
      <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 11C11.1362 11 9.57008 9.72523 9.12604 8H2V6H9.12604C9.57008 4.27477 11.1362 3 13 3C15.2092 3 17 4.79086 17 7C17 9.20914 15.2092 11 13 11ZM19 6H22V8H19V6ZM8.00002 21C6.13618 21 4.57008 19.7252 4.12604 18H2V16H4.12604C4.57008 14.2748 6.13618 13 8.00002 13C10.2092 13 12 14.7909 12 17C12 19.2091 10.2092 21 8.00002 21ZM14 18H22V16H14V18ZM9.99999 17C9.99999 18.1046 9.10456 19 7.99999 19C6.89542 19 5.99999 18.1046 5.99999 17C5.99999 15.8954 6.89542 15 7.99999 15C9.10456 15 9.99999 15.8954 9.99999 17ZM15 7C15 8.10457 14.1046 9 13 9C11.8954 9 11 8.10457 11 7C11 5.89543 11.8954 5 13 5C14.1046 5 15 5.89543 15 7Z"
          fill="#4284FF"
        />
      </svg>
    )
  }
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12602 8C7.57006 9.72523 9.13616 11 11 11C12.8638 11 14.4299 9.72523 14.874 8H22V6H14.874C14.4299 4.27477 12.8638 3 11 3C9.13616 3 7.57006 4.27477 7.12602 6H2V8H7.12602ZM2 16V18H11.083C11.0284 17.6748 11 17.3407 11 17C11 16.6593 11.0284 16.3252 11.083 16H2ZM11 9C12.1046 9 13 8.10457 13 7C13 5.89543 12.1046 5 11 5C9.89543 5 9 5.89543 9 7C9 8.10457 9.89543 9 11 9Z"
        fill="#4284FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4966 19.1157L15.7692 17.2262C15.3645 16.7835 14.7083 16.7835 14.3036 17.2262C13.8988 17.6689 13.8988 18.3867 14.3036 18.8294L16.8969 21.666C17.304 22.1113 17.9642 22.1113 18.3713 21.666L22.6964 16.9352C23.1012 16.4925 23.1012 15.7747 22.6964 15.332C22.2917 14.8893 21.6355 14.8893 21.2308 15.332L17.7716 19.1157C17.6956 19.1987 17.5726 19.1987 17.4966 19.1157Z"
        fill="#4284FF"
      />
    </svg>
  )
}
