import React from 'react'

export default function LineWidth8Icon() {
  return (
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40287 5.29047C4.97334 5.47349 4.74757 5.76487 4.66332 6.12241C4.33904 7.49866 3.04891 8.32882 1.78175 7.97662C0.514581 7.62441 -0.249774 6.22322 0.074511 4.84697C0.593118 2.64602 2.0536 1.19137 3.6729 0.501381C5.25443 -0.172512 7.09879 -0.178744 8.7092 0.553511C10.7403 1.47707 11.5084 3.39325 11.8738 4.30491C11.9066 4.3867 11.9361 4.46041 11.9631 4.52459C12.0616 4.75948 12.1291 4.90715 12.1893 5.01862C12.2113 5.05929 12.2289 5.08859 12.242 5.10898C12.2599 5.11256 12.2851 5.11676 12.3192 5.12083C12.7568 5.17296 13.0138 5.12807 13.14 5.09018C13.2032 5.07121 13.2397 5.05229 13.257 5.04202C13.2598 5.04036 13.2623 5.03882 13.2645 5.03742C13.3043 3.65467 14.3489 2.54699 15.6317 2.54699C16.9397 2.54699 18 3.69862 18 5.11922C18 6.30663 17.5639 7.75071 16.4618 8.83673C15.3008 9.9807 13.6831 10.4587 11.8026 10.2346C10.6108 10.0926 9.63774 9.57867 8.89265 8.75579C8.23083 8.02488 7.86483 7.16324 7.65272 6.65763C7.13218 5.41685 7.07802 5.38894 6.87997 5.29889C6.43722 5.09757 5.87018 5.09134 5.40287 5.29047Z"
        fill="white"
      />
    </svg>
  )
}
