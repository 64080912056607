import firebase from 'firebase/app'
import { IProfile, IStudentProfile } from './IProfile'
import { CLASSROOM_BLOCK_TYPES } from '@utils/constants'
import { IFactoryExercise } from './IFactory'
import { IVocabularyWord } from './ICourse'

export enum CourseProgressType {
  native = 'native',
  music = 'music',
  probation = 'probation',
  grammar = 'grammar',
}

export interface IClassroomBlock {
  created_at?: Date
  file_name?: string
  load_type?: string
  type?: CLASSROOM_BLOCK_TYPES
  url?: string
  id?: string
  isHidden?: boolean
  pageNumber?: number
  position?: number
  isLocked?: boolean
  exercise?: IFactoryExercise
  content?: string
  words?: IVocabularyWord[]
}

export interface IClassroomBlockActionType {
  message: string
  type: string
  title: string
  icon?: any
  name?: string
}

export interface IClassroom {
  name: string
  description: string
  isAIClass?: boolean
  notebookText?: string
  notes?: string
  uid?: string
  student_uid?: string
  id?: string
  blocks?: IClassroomBlock[]
  userTyping?: string
  teacher?: IProfile
  img?: string
  student?: IStudentProfile
  d?: firebase.firestore.Timestamp
}

export interface IStudent {
  name?: string
  avatar?: string
}

export interface IPublishedCourseLesson {
  id: string
  name: string
  description: string
  image: string
  order: number
}

export interface IPublishedCourse {
  author: string
  benefits: string
  benefitsAvailable: boolean
  description: string
  duration: number
  goals: string[]
  id: string
  image: string
  interests: string[]
  isMini: boolean
  lessons: any
  level: string
  name: string
  price: number
  priceEur: number
  priceUsd: number
  publishedAt: any
  sources: string
  sourcesAvailable: boolean
  students: string[]
  timestamp: number
  updatedAt: number
  isBought?: boolean
  progress?: ICourseLessonProgress[] | number
  isPassedCourse?: boolean
  mini?: boolean
}

export interface IClassroomState {
  classrooms: IClassroom[]
  classroomData: IClassroom
  students: IStudentProfile[]
  publishedCourses: IPublishedCourse[]
  displayedCourse: IPublishedCourse
  currentCourse?: ICurrentCourseInfo
  loadingStudentPage: boolean
  studentData: IStudentProfile | null
}

export interface IStudentCurrentCourse {
  currentLessonId: string
  lessonIds: string[]
  showLessonIds: string[]
}

export interface IPublishedCourseLessonInfo extends IPublishedCourseLesson {
  isCompleted: boolean
  isCurrent: boolean
  isFree?: boolean
  isVisible?: boolean
  progress?: ICourseLessonProgress
}
export interface ICourseLessonProgress {
  icon: React.ReactElement
  type: string
  subject: string
}
export interface ICourseProgress {
  lessons: { order: string; subject: string }[]
  type: CourseProgressType
}

export interface ICurrentCourseInfo {
  id: string
  name: string
  progress: ICourseProgress[]
  lessons: IPublishedCourseLessonInfo[]
}

export enum CustomClientState {
  CONNECTED,
  CONNECTING,
  DISCONNECTED,
  LOGGED_IN,
  LOGGING_IN,
  RECONNECTING,
}

export enum VoxiMessageCommandType {
  RECORD_STARTED = 'record_started',
  STATISTICS = 'statistics',
  REAL_TIME = 'realTime',
}

export interface VoxiMessage {
  type: VoxiMessageCommandType
  text: string
}
