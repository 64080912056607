import React from 'react';

const ItalicTextIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3338 3.33333L9.37088 16.6667H12.4987V18.3333H4.58203V16.6667H7.66355L10.6265 3.33333H7.91536V1.66666H15.832V3.33333H12.3338Z"
      fill="currentColor"
    />
  </svg>
);

export { ItalicTextIcon };
