import { IEvent } from '@models/ICalendar'

export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS'
export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'

interface SetCalendarEvents {
  type: typeof SET_CALENDAR_EVENTS
  payload: IEvent[]
}

interface AddEvent {
  type: typeof ADD_EVENT
  payload: IEvent
}

interface UpdateEvent {
  type: typeof UPDATE_EVENT
  payload: IEvent
}

interface DeleteEvent {
  type: typeof DELETE_EVENT
  payload: String
}

export type CalendarActionsTypes = SetCalendarEvents | AddEvent | UpdateEvent | DeleteEvent
