import { Dispatch } from 'redux'
import firebase from 'firebase/app'

import { CourseActionsTypes } from '@store/lessonPreview/types'
import { setCourseLessonAction, setLoadingCourseLessonAction } from '@store/lessonPreview/actions'
import {
  activeCoursesRef,
  studentsRef,
  firestoreRef,
  publishedCoursesRef,
  database,
  courseRef,
} from '@configs/firebase'
import { IEssayContent, ILessonHomework } from '../models'

const previewPagePaths: string[] = [
  '/teacher/course/:courseId/lesson/:lessonId/page/:pageId',
  '/student/course/:courseId/lesson/:lessonId/page/:pageId',
]

export const getCourseLesson = ({
  courseId,
  lessonId,
  studentId,
  preventLoading,
  path,
}: {
  courseId: string
  lessonId: string
  studentId?: string
  preventLoading?: boolean
  path: string
}) => {
  return async (dispatch: Dispatch<CourseActionsTypes>) => {
    if (!preventLoading) dispatch(setLoadingCourseLessonAction(true))
    try {
      let currentLessonId, lessonIds

      if (studentId) {
        const courseSnap = await studentsRef.doc(`${studentId}/courses/${courseId}`).get()
        const data = courseSnap.data() || {}
        currentLessonId = data?.currentLessonId
        lessonIds =( data.lessonIds||[]).concat(data.showLessonIds||[])
      }

      if (studentId) {
        const lessonSnap = await studentsRef
          .doc(`${studentId}/courses/${courseId}/lessons/${lessonId}`)
          .get()
        if (lessonSnap.exists) {
          const data = lessonSnap.data() as Omit<ILessonHomework, 'id'>
          dispatch(
            setCourseLessonAction({
              ...data,
              id: lessonId,
              isAllowed: lessonIds?.includes(lessonId),
              isCurrent: currentLessonId === lessonId,
            })
          )
          return
        }
      }
      const getCourseRef = previewPagePaths.includes(path) ? courseRef : activeCoursesRef
      const lesson = await getCourseRef
        .doc(courseId)
        .collection('lessons')
        .doc(lessonId)
        .get()
      const data = (await lesson.data()) as Omit<ILessonHomework, 'id'>

      const homework = await studentsRef
        .doc(studentId)
        .collection('homeworks')
        .doc(lessonId)
        .get()

      if (homework.exists) {
        const res = await Promise.all(
          data?.pages?.map(async currentPage => {
            const currenPageState = await Promise.all(
              currentPage?.pageState.map(async state => {
                if (state.type === 'essay') {
                  const content = state?.content as IEssayContent

                  const randomWord =
                    (await homework
                      .data()
                      .pages.find(page => page.id === currentPage.id)
                      ?.pageState?.find(state => state.type === 'essay')?.content?.randomWord) || []

                  return {
                    ...state,
                    content: {
                      ...content,
                      randomWord: [...randomWord],
                    },
                  }
                } else return state
              })
            )
            return {
              ...currentPage,
              pageState: [...currenPageState],
            }
          }) ?? []
        )
        dispatch(
          setCourseLessonAction({
            ...data,
            pages: [...res],
            id: lessonId,
            isAllowed: lessonIds?.includes(lessonId),
          })
        )
      } else {
        dispatch(
          setCourseLessonAction({
            ...data,
            id: lessonId,
            isAllowed: lessonIds?.includes(lessonId),
          })
        )
      }
    } finally {
      dispatch(setLoadingCourseLessonAction(false))
    }
  }
}

export const assignCourseForStudent = async ({ studentId, courseId }): Promise<void> => {
  const batch = firestoreRef.batch()
  batch.update(studentsRef.doc(studentId), { currentCourseId: courseId })
  const courseSnap = await studentsRef
    .doc(studentId)
    .collection('courses')
    .doc(courseId)
    .get()
  // @ts-ignore
  batch[courseSnap.exists ? 'update' : 'set'](
    studentsRef
      .doc(studentId)
      .collection('courses')
      .doc(courseId),
    {}
  )
  batch.update(publishedCoursesRef.doc(courseId), {
    students: firebase.firestore.FieldValue.arrayUnion(studentId),
  })

  await batch.commit()
}
