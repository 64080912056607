import React from 'react'
function SupportIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 7.5C12.3579 7.5 9 10.8579 9 15V16.5V27H4.5C2.84315 27 1.5 25.6569 1.5 24V18C1.5 16.3431 2.84315 15 4.5 15L6 15C6 9.20101 10.701 4.5 16.5 4.5H19.5C25.299 4.5 30 9.20101 30 15H31.5C33.1569 15 34.5 16.3431 34.5 18V24C34.5 25.6569 33.1569 27 31.5 27H29.811C29.1449 29.5878 26.7958 31.5 24 31.5H22.0987C21.5799 32.3967 20.6104 33 19.5 33C17.8431 33 16.5 31.6569 16.5 30C16.5 28.3431 17.8431 27 19.5 27C20.6104 27 21.5799 27.6033 22.0987 28.5H24C25.6569 28.5 27 27.1569 27 25.5V16.5V15C27 10.8579 23.6421 7.5 19.5 7.5H16.5ZM6 18H4.5L4.5 24H6V18ZM16.5 18.375C16.5 19.4105 15.6605 20.25 14.625 20.25C13.5895 20.25 12.75 19.4105 12.75 18.375C12.75 17.3395 13.5895 16.5 14.625 16.5C15.6605 16.5 16.5 17.3395 16.5 18.375ZM21.375 20.25C22.4105 20.25 23.25 19.4105 23.25 18.375C23.25 17.3395 22.4105 16.5 21.375 16.5C20.3395 16.5 19.5 17.3395 19.5 18.375C19.5 19.4105 20.3395 20.25 21.375 20.25ZM30 18H31.5V24H30V18Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default SupportIcon
