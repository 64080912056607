import firebase from 'firebase/app'
import { ILesson, IRealPageStates } from './ICourse'

export interface IHomework {
  id?: string
  className: string
  description: string
  isWriting: boolean
  isAudio?: boolean
  answers?: any
  isLessonHomework?: boolean
  isHomework?: boolean
  status: string
  writing?: string
  audioURL?: string
  studentId: string
  teacherId: string
  createdAt: firebase.firestore.Timestamp
  checkAt: firebase.firestore.Timestamp
  comment?: string
  studentAvatar?: string
  studentName?: string
  teacherAvatar?: string
  teacherName?: string
  isNew: boolean
  teacherWriting?: string
  homeworkId?: string
  suggestionByAI?: string
}

export type IHomeworkProgress = {
  [key: string]: boolean
}

export interface ILessonHomeworkAnswers {
  progress: IHomeworkProgress
  pageStates?: IRealPageStates
}

export interface ILessonHomework extends Pick<ILesson, 'id' | 'name' | 'pages' | 'courseId'> {
  isHomework: boolean
  answers: ILessonHomeworkAnswers
  status: HomeworkStatuses
  createdAt: firebase.firestore.Timestamp
  checkAt: firebase.firestore.Timestamp
  teacherId: string
  studentId: string
  audioURL?: string
  isToastClosed?: boolean
  isLessonHomework?: boolean
}

export enum HomeworkStatuses {
  CHECKED = 'Проверено',
  CHECKED_AI = 'Проверено ИИ',
  SENT = 'Отправлено',
  NOT_READY = 'Не выполнено',
  DONE = 'Выполнено',
  IN_PROGRESS = 'В процессе',
}

export enum EngHomeworkStatuses {
  CHECKED = 'Checked',
  SENT = 'Sent',
  NOT_READY = 'NotPerformed',
  CHECKED_AI = 'CheckedAI',
  UNDER_VIEW = 'Homework.UnderReview',
  DONE = 'HomeWork.Done',

  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In progress',
}

export enum MiniCourseLessonStatus {
  PASSED = 'PASSED',
  NOT_PASSED = 'NOT_PASSED',
}

export interface IState {
  homeworks: IHomework[]
  error: string
  loading: boolean
  studentCount: number
  studentFinishedCount: number
  teacherCount: number
  miniCourse: any
  lessonFeedback: boolean
}

export enum AiAssistantRoles {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}
