import React, { useMemo } from 'react'
import styled from 'styled-components'

import { HomeworkStatuses, EngHomeworkStatuses } from '@models/IHomework'
import { useTranslation } from 'react-i18next'

const Container = styled.div<{ status: EngHomeworkStatuses }>`
  background: ${props => {
    switch (props.status) {
      case EngHomeworkStatuses.NOT_READY:
      case EngHomeworkStatuses.NOT_STARTED:
        return props.theme.colors.hmNotReadyStatus
      case EngHomeworkStatuses.CHECKED:
      case EngHomeworkStatuses.DONE:
        return props.theme.colors.hmCheckedStatus
      case EngHomeworkStatuses.SENT:
      case EngHomeworkStatuses.UNDER_VIEW:
        return props.theme.colors.hmSentStatus
      case EngHomeworkStatuses.CHECKED_AI:
        return props.theme.colors.hmCheckAIStatus

      case EngHomeworkStatuses.IN_PROGRESS:
        return props.theme.colors.hmInProgress

      default:
        return props.theme.colors.hmNotReadyStatus
    }
  }};
  border-radius: 40px;
  font-family: ${props => props.theme.defaultFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  padding: 8px 10px;
  color: ${props => props.theme.colors.hmStatusText};
  width: max-content;
`

interface Props {
  status: HomeworkStatuses
}

const HomeWorkStatus = ({ status }: Props) => {
  const displayStatus = useMemo(() => {
    switch (status) {
      case HomeworkStatuses.NOT_READY:
        return EngHomeworkStatuses.NOT_READY
      case HomeworkStatuses.DONE:
        return EngHomeworkStatuses.DONE
      case HomeworkStatuses.CHECKED:
        return EngHomeworkStatuses.CHECKED
      case HomeworkStatuses.CHECKED_AI:
        return EngHomeworkStatuses.CHECKED_AI
      case HomeworkStatuses.SENT:
        return EngHomeworkStatuses.SENT

      case HomeworkStatuses.IN_PROGRESS:
        return EngHomeworkStatuses.IN_PROGRESS

      default:
        return status
    }
  }, [status])
  const { t } = useTranslation()
  return <Container status={displayStatus}>{t(displayStatus)}</Container>
}

export default HomeWorkStatus
