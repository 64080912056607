import React, { useState } from 'react'
import * as Styled from '../personal-info.styles'
import { IconButton } from '@material-ui/core'

export default function({ children, header, hover }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Styled.AccordionContainer hover={hover}>
      <Styled.AccordionHeader>
        <Styled.PersonalInfoLabel style={{ width: 'auto' }}>
          {header}
        </Styled.PersonalInfoLabel>
        <IconButton size="small" onClick={() => setIsOpen(prev => !prev)}>
          {isOpen ? (
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM4.5 2V1H3.5V2H4.5Z"
                fill="#1B3B77"
              />
            </svg>
          ) : (
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L7.53553 1.17157C7.7308 0.976311 7.7308 0.659728 7.53553 0.464466C7.34027 0.269204 7.02369 0.269204 6.82843 0.464466L4 3.29289L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.64645 4.35355ZM3.5 3V4H4.5V3H3.5Z"
                fill="#1B3B77"
              />
            </svg>
          )}
        </IconButton>
      </Styled.AccordionHeader>
      {isOpen && <Styled.AccordionContent>{children}</Styled.AccordionContent>}
    </Styled.AccordionContainer>
  )
}
