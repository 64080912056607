import React from 'react'
import * as Styled from '../personal-info.styles'
import { IconButton } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import { INTERESTS } from '@utils/constants'
import CustomMaterialCheckbox from '@components/Checkbox/CustomMaterialCheckbox'
import { useTranslation } from 'react-i18next'

export default function({
  interests,
  editType,
  handleClickEdit,
  onChange,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  interests?: string[]
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  const handleChangeInterests = (value: string) => {
    const newInterests = interests.includes(value)
      ? interests.filter(interest => interest !== value)
      : interests.concat(value)
    onChange({ name: 'interests', value: newInterests })
  }
  return (
    <>
      <Styled.PersonalInfoItem
        style={{
          flexDirection: 'column',
          marginBottom: 40,
          alignItems: 'flex-start',
        }}
      >
        <Styled.PersonalInfoLabel>Interests</Styled.PersonalInfoLabel>
        <Styled.InterestsContianer>
          {interests.map(key => {
            try {
              const findInterest = INTERESTS.find(item => item.id === key)
              let img = require(`../../../Icons/${findInterest?.id}.svg`)
              return (
                <Styled.InterestCard active={false} key={findInterest?.id}>
                  <img
                    src={img.default}
                    alt={findInterest?.name}
                    style={{ width: 30, height: 30 }}
                  />
                  {findInterest?.name.split('.')[1] || t(findInterest?.name)}
                </Styled.InterestCard>
              )
            } catch (e) {
              return null
            }
          })}
        </Styled.InterestsContianer>
        {!editType && <div className="hover-block" />}
        {editType !== 'interests' && (
          <IconButton
            className="edit-btn"
            size="small"
            onClick={() => handleClickEdit('interests')}
          >
            <EditIcon />
          </IconButton>
        )}
      </Styled.PersonalInfoItem>
      {editType === 'interests' && (
        <Styled.EditMainContainer>
          <Styled.Div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <Styled.PersonalInfoLabel
              style={{
                width: 'auto',
                fontWeight: 700,
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Interests
            </Styled.PersonalInfoLabel>
            <IconButton
              size="small"
              className="edit-btn"
              style={{ display: 'block', top: 10, right: 10 }}
              onClick={() => handleClickEdit('interests')}
            >
              <CheckMarkIcon />
            </IconButton>
          </Styled.Div>

          <Styled.Div className="edit-interests-container">
            {INTERESTS.map((i, index) => {
              try {
                const findIndex = interests && interests.findIndex(key => key === i.id)
                return (
                  <CustomMaterialCheckbox
                    name={i.name.split('.')[1]}
                    style={{ padding: 0 }}
                    onChange={e => handleChangeInterests(i.id)}
                    checked={findIndex !== -1}
                  />
                )
              } catch (e) {
                return null
              }
            })}
          </Styled.Div>
        </Styled.EditMainContainer>
      )}
    </>
  )
}
