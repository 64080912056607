import styled, { css } from 'styled-components'

import {
  ScrollContiner,
  SaveIcon,
  UpDownBlockFactory,
} from '@pages/teacher/Classroom/classroom.styles'
import { CLASSROOM_BLOCK_TYPES } from '@utils/constants'

export const AddFileModalContainer = styled.div`
  width: 500px;
`

export const StartLessonContiner = styled.div`
  width: 500px;
  padding: 40px 0;
  .checkbox {
    margin: 0 0 0 35px;
    align-items: flex-start;
    font-size: 18px;
    line-height: 25px;
    color: #414752;
    svg {
      margin-right: 20px;
    }
  }
`

export const StartLessonTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.textDark};
  text-align: center;
  margin-bottom: 35px;
`

export const AddFileModalMain = styled.div`
  border-top: ${props => props.theme.border.gray};
  border-bottom: ${props => props.theme.border.gray};
  padding: 40px 30px;
  > * {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`

export const PdfInfoIcon = styled(SaveIcon)`
  background-color: white;
  margin-left: 15px;
`
export const AddFileModalTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.textDark};
  margin: 20px 0px 20px 30px;
`
export const AddFileModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const AddFileModalField = styled.div`
  display: flex;
  flex-direction: column;
  .mt-input {
    margin: 20px 0 0 40px;
  }

  .checkbox {
    color: ${props => props.theme.colors.textDark};
  }
`

export const AddFileModalCheckbox = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px 30px 0px 30px;
  .checkbox {
    color: ${props => props.theme.colors.textDark};
  }
`

export const TableImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const ScrollContinerTable = styled(ScrollContiner)`
  height: 180px;
  width: 400px;

  margin: 0px 30px 0px 40px;
  font-size: 20px;
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
    .item_image {
      width: 60px;
      height: 40px;
    }
  }
`

export const CancelButton = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: ${props => props.theme.colors.textDark};
  width: 170px;
`
export const AddFileModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
  > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

export const BlockSettingsContainer = styled.div<{
  audioFile?: boolean
  isExercise?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 10px;
  top: ${props => (props.isExercise ? '4.7rem' : props.audioFile ? '0.5rem' : '0.7rem')};
  > * {
    &:not(:last-child) {
      margin-bottom: ${props => (props.audioFile ? '5px' : '15px')};
    }
  }
`
export const PdfZoomButtons = styled.div<{ hide?: boolean }>`
  display: none;
  position: absolute;
  align-items: center;
  right: 2.2rem;
  top: 7rem;
  position: absolute;
  width: 50;
  flex-direction: column;
`
export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 918px;

  .page-hide {
    display: none;
  }
  .pdf-spin {
    margin: 100px auto 0;
    width: 0;
    height: 0;
    transform: scale(0.8);

    div {
      color: #ff852d;
    }
  }
`
export const SketchContainer = styled.div<{
  board?: boolean
  hide?: boolean
  scale?: boolean
}>`
  position: relative;
  margin-right: 10px;
  width: 100%;
  border: ${props => (props.board ? '1px solid #9c9c9c' : 'none')};
  box-sizing: border-box;
  ${props =>
    props.hide &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  > canvas {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > img {
    width: 100%;
    height: auto;
  }
  .canvas-container {
    width: 100%;
    margin-top: 5px;
    margin: auto;
    object-fit: cover;
    height: 100%;
  }
`
export const SketchZoomImageContainer = styled.div<{
  board?: boolean
  hide?: boolean
  scale?: number
}>`
  transform: scale(${props => props.scale});
  overflow: visible;
  border-radius: 0;
  position: relative;
  width: 100%;
  cursor: grab;
  border: ${props => (props.board ? '1px solid #9c9c9c' : 'none')};
  box-sizing: border-box;
  transform-origin: center;
  ${props =>
    props.hide &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  > canvas {
    width: 100%;
  }
  > img {
    width: 100%;
    height: auto;
  }
  .canvas-container {
    width: 100%;
    margin-top: 5px;
    margin: auto;
    height: 100%;
  }
`
export const UpDownBlock = styled.div<{ type: string }>`
  display: none;
  position: absolute;
  right: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? '14px' : '55px')};
  bottom: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? '50px' : null)};
  top: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? null : '20px')};
  img {
    cursor: pointer;
  }
`
export const LockIcon = styled.div<{ isStudent: boolean }>`
  display: none;
  position: absolute;
  right: 18px;
  bottom: ${props => (props.isStudent ? '25px' : '100px')};
  img {
    cursor: pointer;
  }
`
export const SearchIcon = styled.div<{ isStudent: boolean }>`
  display: inline;
  position: absolute;
  right: 16px;
  z-index: 1;
  padding: 10px;
  border-radius: 80%;
  font-size: x-small;
  bottom: 16px;
  img {
    cursor: pointer;
  }
`
export const PlusIcon = styled.div<{ isStudent: boolean; scale: number }>`
  display: inline;
  position: fixed;
  right: 40px;
  z-index: 1;
  border-radius: 80%;
  background-color: #d3d3d399;
  bottom: 70px;
  opacity: 1;
  img {
    cursor: pointer;
  }
  height: 30px;
  font-size: medium;
  &:hover {
    opacity: 1;
  }
`
export const CloseIcon = styled.div<{ isStudent: boolean }>`
  display: inline;
  position: fixed;
  background: rgba(0, 0, 0, 0.53);
  right: 40px;
  z-index: 1;
  border-radius: 80%;
  color: white;
  top: 40px;
  img {
    cursor: pointer;
  }
  height: 25px;
  font-size: medium;
`
export const MinusIcon = styled.div<{ isStudent: boolean; scale: number }>`
  display: inline;
  position: fixed;
  right: 40px;
  z-index: 1;
  border-radius: 50%;
  background-color: #d3d3d399;
  bottom: 40px;
  opacity: ${props => (props.scale >= 0.5 ? 0.8 : 0.3)};
  img {
    cursor: pointer;
  }
  height: 24px;
  font-size: medium;
  &:hover {
    opacity: ${props => (props.scale >= 0.5 ? 1 : 0.3)};
  }
  &:active {
    filter: ${props => props.scale >= 0.5 && 'none'};
    opacity: ${props => props.scale >= 0.5 && '1'};
  }
`

export const FileContainer = styled.div<{
  isCoursePage?: boolean
  type?: string
  isPreview?: boolean
  isManager?: boolean
  isEdit?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 35px 17px 35px;
  margin: ${({ isCoursePage, isPreview, isManager }) =>
    !isManager && isCoursePage && isPreview ? '0 -175px' : isCoursePage ? '0 -35px' : '0'};
  position: relative;
  min-height: 200px;
  max-width: 100%;
  flex-direction: column;

  &:hover {
    .download-button {
      display: block;
    }
  }

  .download-button {
    display: none;
    position: absolute;
    right: 10px;
  }

  &:hover ${BlockSettingsContainer} {
    display: ${({ isPreview }) => !isPreview && 'flex'};
  }
  &:hover ${UpDownBlock} {
    display: flex;
    flex-direction: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? 'column' : '')};
    gap: 15px;
    margin-right: 5px;
  }
  &:hover ${UpDownBlockFactory} {
    display: flex;
    flex-direction: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? 'column' : '')};
    gap: 15px;
  }
  &:hover ${LockIcon} {
    display: block;
  }
  &:hover ${PdfZoomButtons} {
    display: flex;
  }
  &:hover ${DocumentContainer} {
    background: white;
  }
  &:hover ${SketchContainer} {
    background: white;
  }

  &:hover {
    background: RGBA(243, 245, 249, 0.5);
  }

  ${({ isEdit }) => isEdit && ` background: #F3F5F9;`}
`

export const DocsFile = styled.div`
  position: relative;
  border-radius: 20px 20px;
  display: flex;
  background-color: rgb(243, 245, 249);
  padding: 10px 20px;
  margin-bottom: 10px;
  align-items: center;
  align-content: center;

  svg {
    height: 16px;
    width: 18px;
    fill: '#336A93';
  }
`

export const SketchSettings = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background-color: #c2cfe0;
  border-radius: 12px;
  width: 24px;
  justify-content: space-around;
  padding: 5px 0;
  z-index: 555;
  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const SketchModal = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  position: absolute;
  right: 35px;
  top: 3px;
  height: 24px;
  background-color: #c2cfe0;
  border-radius: 12px;
`

export const SketchSettingsItemContainer = styled.div`
  position: relative;
`

export const PdfPageNumberContainer = styled.div<{ hide?: boolean }>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  position: absolute;
  align-items: center;
  right: 3rem;
  top: 3rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #414752;
`

export const PdfActivePageInput = styled.input`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 40px;
  font-weight: 600;
  text-align: center;
  border: 2px solid #d0d0d0;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    display: none;
    margin: auto;
  }
`

export const MediaContainer = styled.div`
  position: relative;
  width: 100%;
`

export const PDFDocument = styled.div`
  display: flex;
`

export const PDFContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`

export const PDFPrevConainer = styled.div`
  display: flex;
  width: 35px;
  background: #c2cfe0;
  opacity: 0.5;
  cursor: pointer;
  padding: auto;
`

export const FileText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #414752;
  text-align: left;
  margin-bottom: 20px;
`

export const SyncCursorContainer = styled.div`
  margin: auto;
  position: relative;
  cursor: pointer;
`

export const EditAITooltipContainer = styled.div`
  border-radius: 10px;
  right: 65px;
  top: -10px;
  position: absolute;
  background-color: rgba(65, 71, 82, 1);

  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 20px 16px;
  white-space: nowrap;
  z-index: 999;
  .header {
    color: #fff;
    font-size: 18px;
    font-style: normal;

    font-weight: 500;
    line-height: 140%;
  }
  .btn {
    border-radius: 10px;
    background: #e1ecff;
    color: #1556cf;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 10px 16px;
    width: fit-content;
  }
  ::after {
    content: ' ';
    position: absolute;
    top: 28px;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    margin-left: -5px;
    border-width: 14px;
    border-style: solid;
    border-color: rgba(65, 71, 82, 1) transparent transparent rgba(65, 71, 82, 1);
    border-radius: 5px;
  }
`
