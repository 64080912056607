import styled from 'styled-components';

export const TeacherCard = styled.div`
  width: 685px;
  height: 536px;
  background: #f3f5f9;
  border-radius: 16px;
  position: relative;
  display: flex;
  .left-icon {
    position: absolute;
    left: 20.22px;
    top: 189.21px;
  }
  .right-icon {
    position: absolute;
    right: 25.88px;
    top: 369px;
  }
  .close-button {
    position: absolute;
    right: 13px;
    top: 13px;
  }
  .edit-button {
    position: absolute;

    right: 7px;
    top: 7px;
  }
  .block-main {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const TeacherCardContainer = styled.div`
  width: 100%;
  margin: 40px 50px 30px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  width: 250px;
  position: relative;
  .avatar-icon {
    position: absolute;
    right: 65px;
    top: 0;
  }
`;
export const RightContainer = styled.div`
  width: 300px;
`;
export const LeftVector = styled.div`
  /* Vector 16 */

  position: absolute;
  width: 16.91px;
  height: 14.78px;
  left: 20.22px;
  top: 189.21px;

  background: #9ed1ff;
  opacity: 0.4;
  border-radius: 0.5px;
  transform: rotate(-77.96deg);
`;
export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #7586a4;
  padding-left: 20px;
  padding-right: 20px;
  div {
    display: flex;
    justify-content: space-between;
    width: 79px;
  }
`;
export const AboutMe = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 275px;
  height: 280px;
  background: #ffffff;
  border-radius: 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #414752;
  padding: 20px 5px 20px 20px;
  div {
    overflow: auto;
    height: 280px;
    padding-right: 10px;
    ::-webkit-scrollbar {
      width: 5px;
      height: 100%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c2cfe0;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #f3f5f9;
      border-radius: 2px;
    }
    scrollbar-width: thin;
    scrollbar-color: #c2cfe0 #f3f5f9;
  }
`;
export const Name = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: #1b3b77;
  margin-top: 20px;
`;

export const Address = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #414752;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const ContactBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .copy {
    display: none;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    .copy {
      display: block;
    }
  }
  .label {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #414752;
  }
`;

export const Player = styled.div`
  position: relative;
  display: flex;
  justify-content: between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  height: 44px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.01em;

  color: #7e92ac;
`;
