import React from 'react';

const HomeWorkIcon = () => {
  return (
    <svg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.5'>
        <path
          d='M1.15745 0C0.519693 0 0 0.519693 0 1.15745V17.3617C0 18.6279 1.04865 19.6766 2.31489 19.6766H13.3465C13.6535 19.6766 13.9479 19.5546 14.1649 19.3376C14.382 19.1205 14.5039 18.8261 14.5039 18.5191C14.5039 18.2121 14.382 17.9177 14.1649 17.7007C13.9479 17.4836 13.6535 17.3617 13.3465 17.3617H2.31489V5.78723H27.7787V17.3617H23.7276C23.4207 17.3617 23.1263 17.4836 22.9092 17.7007C22.6921 17.9177 22.5702 18.2121 22.5702 18.5191C22.5702 18.8261 22.6921 19.1205 22.9092 19.3376C23.1263 19.5546 23.4207 19.6766 23.7276 19.6766H27.7787C29.0449 19.6766 30.0936 18.6279 30.0936 17.3617V1.15745C30.0936 0.519693 29.5739 0 28.9361 0H1.15745ZM24.1258 12.1532C24.0394 12.1944 23.9647 12.2565 23.9082 12.3337L23.2577 12.9854C22.0378 13.1439 19.8953 14.5039 19.3513 15.0468L13.2018 21.1963L15.9149 23.9082L22.0644 17.7598C22.5193 17.3038 23.9776 14.9843 24.0899 13.7447L24.7034 13.1301C24.7889 13.043 24.8447 12.9312 24.8629 12.8106C24.8811 12.6899 24.8607 12.5666 24.8046 12.4583C24.7485 12.3499 24.6596 12.2621 24.5505 12.2073C24.4415 12.1525 24.318 12.1335 24.1975 12.1532C24.1737 12.1517 24.1497 12.1517 24.1258 12.1532ZM12.1173 22.2449L7.66807 26.693C7.1125 27.2486 6.84976 28.7695 7.59516 29.5149C8.33939 30.2602 9.70055 30.0855 10.3811 29.4061L14.8303 24.958L12.1173 22.2449ZM17.2899 23.3653C17.0809 23.3969 16.8889 23.499 16.7459 23.6547L12.0814 28.2845C11.9797 28.3658 11.8964 28.4677 11.8367 28.5834C11.7771 28.6991 11.7425 28.8261 11.7353 28.9561C11.7281 29.0861 11.7483 29.2162 11.7948 29.3378C11.8412 29.4595 11.9128 29.5699 12.0048 29.662C12.0968 29.7541 12.2072 29.8258 12.3288 29.8723C12.4504 29.9189 12.5804 29.9393 12.7104 29.9321C12.8404 29.925 12.9675 29.8906 13.0833 29.831C13.199 29.7715 13.301 29.6882 13.3824 29.5866L18.0492 24.9568C18.1919 24.8223 18.2883 24.646 18.3243 24.4532C18.3604 24.2605 18.3343 24.0612 18.2499 23.8842C18.1655 23.7072 18.027 23.5616 17.8545 23.4683C17.6819 23.3751 17.4843 23.339 17.2899 23.3653Z'
          fill='white'
        />
        <path d='M19.5 8.5H4.74592V10.5H19.5V8.5Z' fill='white' />
        <path d='M12.7459 13H4.74592V15H12.7459V13Z' fill='white' />
      </g>
    </svg>
  );
};

export default HomeWorkIcon;
