import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { LangEnum } from '@models'
import { getTranslationResources } from './translation'
let defaultLang = localStorage.getItem('lang') || LangEnum.en

// if (defaultLang !== LangEnum.en && defaultLang !== LangEnum.ru && defaultLang !== LangEnum.es) {
//   defaultLang = LangEnum.ru
// }

const resources = getTranslationResources()

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
    },
    resources,
    // react: {
    //   wait: false,
    //   transSupportBasicHtmlNodes: true,
    //   useSuspense: false,
    // },
    keySeparator: false,
    nsSeparator: false,
    ns: ['translation'],
    defaultNS: 'translation',
    lng: defaultLang,
  })

export default i18n
