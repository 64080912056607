import styled from 'styled-components'
import { PageContent } from '../student.styles'

export const StudentProfilePageContainer = styled(PageContent)`
  display: flex;
`

export const ProfileLeftSide = styled.div`
  width: 70%;
`
export const SubText = styled.span`
  color: #7e92ac;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  margin: 16px 0 6px;
`
export const Select = styled.div`
  margin: 16px 0 40px;
  width: 250px;
  color: '#414752';
`
export const ScheduleHeader = styled.div`
  width: 128px;
  height: 28px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;
`
export const TimeZoneBlock = styled.div`
  width: 250px;
  display: block;
  margin-bottom: 30px;
`
export const GetCalendarFeedContainer = styled.div`
  position: inherit;
  display: flex;
  cursor: pointer;
  margin-bottom: 40px;
`
export const ICalendarTextLabel = styled.p`
  position: inherit;
  width: 489px;
  height: 48px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
`
export const GetCalendarFeed = styled.div`
  /* Grey middle_ default text */
  margin-top: 16px;
  text-align: center;
  justify-content: center;
  color: #7e92ac;
  /* Back_Grey_1 */

  float: right;
  border: 1px solid #c2cfe0;
  border-radius: 40px;
  box-sizing: border-box;
  width: 164px;
  height: 42px;

  /* Get iCalendar feed */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  position: initial;
  float: left;
  letter-spacing: 0.01em;
`

export const ProfileRightSide = styled.div`
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ProfileBlock = styled.div`
  display: flex;
  margin: 16px 0;
`

export const ProfileAvatar = styled.img`
  margin-top: 20px;
  margin-left: 20px;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
`

export const ProfileAvatarHover = styled.img`
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: scale-down;
  position: absolute;
  top: 0;
  left: 0;
`

export const ProfileAvatarBackgroundHover = styled.div`
  width: 115px;
  height: 115px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #192a3e;
  opacity: 0.4;
`

export const UploadedAvatarSuccessIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: -8px;
`
export const EmailError = styled.div`
  color: red;
  font-size: 13px;
`
export const MainProfileFieldsContainer = styled.div`
  > div:nth-child(2n) {
    margin: 30px 0;
  }
  width: 220px;
  img {
    margin-right: 20px;
  }
`

export const ChooseButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.active ? '#90a4bd' : '#90a4bd6b')};
  border-radius: 4px;
  width: 83px;
  height: 40px;
  cursor: pointer;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;

  &:nth-child(2n) {
    margin: 0 20px;
  }
`

export const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InterestsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const LevelHeader = styled.div`
  margin: 220px 30px 15px;
`

export const InterestCard = styled(ChooseButton)`
  &:nth-child(2n) {
    margin: 10px 10px 0 0;
  }
  > img {
    margin-right: 15px;
  }
  font-size: 14px;
  width: fit-content;
  padding: 0 10px;
  margin: 0;
  margin-right: 10px;
  margin-top: 10px;
`

export const StudentProfileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  font-size: 18px;
  background: none;
  text-decoration: inherit;
  font-size: 18px;
  border: 3px solid ${props => props.theme.colors.button};
  color: ${props => props.theme.colors.button};
  &:hover {
    border-color: ${props => props.theme.colors.buttonHover};
    color: ${props => props.theme.colors.buttonHover};
  }
  align-self: flex-end;
  bottom: 5px;
  margin-bottom: 30px;
  margin-top: auto;
`

export const StudentLogoutButton = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #90a4bd;
  span {
    margin-left: 28px;
  }
`

export const LevelDescriptionBlock = styled.div`
  width: 320px;
  height: 378px;
  position: absolute;
  top: 560px;
  background: #ffffff;
  border-radius: 20px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.08));
  padding: 30px 10px 20px 20px;
  box-sizing: border-box;
  margin-top: 30px;
  @media (max-width: 1439px) {
    width: 285px;
  }
`

export const LevelDescriptionTitle = styled.div``

export const LevelDescriptionContent = styled.div`
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 260px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  ::-webkit-scrollbar-track-piece:vertical {
    background: #f3f5f9;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 95px;
    background-color: #c2cfe0;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`
