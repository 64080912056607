import React from 'react'

export default function PlusMath(props) {
  return (
    <svg
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill={props.zoom ? '#C2CFE0' : '#E1ECFF'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C8 14.4477 8.6268 14 9.4 14H20.6C21.3732 14 22 14.4477 22 15C22 15.5523 21.3732 16 20.6 16H9.4C8.6268 16 8 15.5523 8 15Z"
        fill="#336A93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 22C14.4477 22 14 21.3732 14 20.6V9.4C14 8.6268 14.4477 8 15 8C15.5523 8 16 8.6268 16 9.4V20.6C16 21.3732 15.5523 22 15 22Z"
        fill="#336A93"
      />
    </svg>
  )
}
