import { IPassedLesson, IPayment, IProfile, IRequisites, IStudentProfile } from '@models'
import { STUDENT_RATES, TEACHER_RATES } from '@utils/constants'

export const SET_TEACHERS = 'GET_TEACHERS'
export const DELETE_TEACHER_ACTION = 'DELETE_TEACHER_ACTION'

export const SET_TEACHERS_LOADING = 'GET_TEACHERS_LOADING'
export const SET_TEACHER_STUDENTS = 'SET_TEACHER_STUDENTS '
export const SET_TEACHERS_ACCOUNTING = 'SET_TEACHERS_ACCOUNTING'
export const SET_LOADING = 'SET_LOADING'
export const SET_RATE_TEACHER = 'SET_RATE_TEACHER'
export const SET_RATE_STUDENT = 'SET_RATE_STUDENT'
export const SET_PASSED_LESSONS = 'SET_PASSED_LESSONS'
export const SET_REQUISITES_TEACHER = 'SET_REQUISITES_TEACHER'
export const SET_MANAGER_STUDENTS = 'SET_MANAGER_STUDENTS'
export const SET_MANAGER_POSSIBLE_STUDENTS = 'SET_MANAGER_POSSIBLE_STUDENTS'
export const DELETE_MANAGER_POSSIBLE_STUDENT = 'DELETE_MANAGER_POSSIBLE_STUDENT'
export const SET_STUDENT_PAYMENTS = 'SET_STUDENT_PAYMENTS'
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA'
export const SET_POSSIBLE_STUDENT_DATA = 'SET_POSSIBLE_STUDENT_DATA'
export const SET_MANAGERS = 'SET_MANAGERS'
export const SET_SALES_MANAGERS = 'SET_SALES_MANAGERS'
export const SET_TEACHER_STATUS = 'SET_TEACHER_STATUS'
export const CHANGE_MANAGER_POTENTIAL_STUDENT = 'CHANGE_MANAGER_POTENTIAL_STUDENT'
export const DELETE_SALES_MANAGER_ACTION = 'DELETE_SALES_MANAGER_ACTION'
export const SET_TEACHERS_STATISTICS = 'SET_TEACHERS_STATISTICS'

interface SetTeacherStatusAction {
  type: typeof SET_TEACHER_STATUS
  payload: IProfile[]
}
interface SetTeachersStudentsAction {
  type: typeof SET_TEACHER_STUDENTS
  payload: { students: string[]; id: string }
}

interface SetTeachersAction {
  type: typeof SET_TEACHERS
  payload: IProfile[]
}
interface SetTeachersStatisticsAction {
  type: typeof SET_TEACHERS_STATISTICS
  payload: IProfile[]
}

interface SetIsLoading {
  type: typeof SET_LOADING
  payload: boolean
}

interface DeleteTeacherAction {
  type: typeof DELETE_TEACHER_ACTION
  payload: string
}

interface SetTeachersAccountingAction {
  type: typeof SET_TEACHERS_ACCOUNTING
  payload: IProfile[]
}

interface SetRateTeacherAction {
  type: typeof SET_RATE_TEACHER
  payload: { teacherId: string; rate: TEACHER_RATES }
}
interface SetPassedLessonsAction {
  type: typeof SET_PASSED_LESSONS
  payload: {
    teacherId: string
    studentId?: string
    passedLessons: IPassedLesson[]
  }
}

interface SetRequisitesTeacherAction {
  type: typeof SET_REQUISITES_TEACHER
  payload: { teacherId: string; requisites: IRequisites }
}
interface SetManagerStudentsAction {
  type: typeof SET_MANAGER_STUDENTS
  payload: IStudentProfile[]
}

interface SetManagerPotentialStudentsAction {
  type: typeof SET_MANAGER_POSSIBLE_STUDENTS
  payload: IStudentProfile[]
}
interface DeleteManagerPotentialStudentAction {
  type: typeof DELETE_MANAGER_POSSIBLE_STUDENT
  payload: string
}

interface ChangeManagerPotentialStudent {
  type: typeof CHANGE_MANAGER_POTENTIAL_STUDENT
  payload: string
}

interface SetStudentDataAction {
  type: typeof SET_STUDENT_DATA
  payload: IStudentProfile
}

interface SetPotentialStudentDataAction {
  type: typeof SET_POSSIBLE_STUDENT_DATA
  payload: IStudentProfile
}
interface SetStudentPaymentsAction {
  type: typeof SET_STUDENT_PAYMENTS
  payload: { studentId: string; payments: IPayment[] }
}

interface SetRateStudentAction {
  type: typeof SET_RATE_STUDENT
  payload: { studentId: string; rate: STUDENT_RATES }
}

interface SetManagersAction {
  type: typeof SET_MANAGERS
  payload: IProfile[]
}

interface SetSalesManagersAction {
  type: typeof SET_SALES_MANAGERS
  payload: IProfile[]
}
interface DeleteSalesManagerAction {
  type: typeof DELETE_SALES_MANAGER_ACTION
  payload: string
}

export type PersonalActionsTypes =
  | SetTeachersAction
  | DeleteTeacherAction
  | SetTeachersStudentsAction
  | SetTeachersAccountingAction
  | SetRateTeacherAction
  | SetPassedLessonsAction
  | SetStudentPaymentsAction
  | SetRequisitesTeacherAction
  | SetManagerStudentsAction
  | SetManagerPotentialStudentsAction
  | DeleteManagerPotentialStudentAction
  | SetRateStudentAction
  | SetStudentDataAction
  | SetPotentialStudentDataAction
  | SetIsLoading
  | SetManagersAction
  | SetSalesManagersAction
  | SetTeacherStatusAction
  | ChangeManagerPotentialStudent
  | DeleteSalesManagerAction
  | SetTeachersStatisticsAction
