import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import ImageBlock from '@components/Block/imageBlock'
import AudioBlock from '@components/Block/audioBlock'
import VideoBlock from '@components/Block/VideoBlock'
import EditorBlock from '@components/Block/EditorBlock'
import DoubleEditorBlock from '@components/Block/doubleEditorBlock'
import LinkBlock from '@components/Block/linkBlock'
import { VocabularyBlock } from '@components/Block/vocabularyBlock'
import { TestBlock } from '@components/Block/testBlock'
import ExerciseBlock from '@components/Block/exerciseBlock'
import { TableBlock } from '@components/Block/Table'
import ResultBlock from '@components/Block/resultBlock'

import Move from '@assets/images/blocks/move.svg'
import Copy from '@assets/images/blocks/copy.svg'
import Delete from '@assets/images/blocks/delete.svg'
import EssayBlock from './essayBlock'

const Block = ({
  actions: { onCopy, onDelete },
  type,
  onStateChange,
  id,
  preloadedState,
  moveCard,
  index,
  additions,
}) => {
  const types = {
    IMAGE: 'image',
    AUDIO: 'audio',
    VIDEO: 'video',
    LINK: 'link',
    VOCABULARY: 'vocabulary',
    RESULT: 'result',
    TEST: 'test',
    EXERCISE: 'exercise',
    EDITOR: 'editor',
    DOUBLE_EDITOR: 'action-editor',
    TABLE: 'table',
    ESSAY: 'essay',
  }
  const actionsRef = useRef(null)
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'block',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'block', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const renderContent = () => {
    switch (type) {
      case types.IMAGE:
        return (
          <ImageBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.AUDIO:
        return (
          <AudioBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.VIDEO:
        return (
          <VideoBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.LINK:
        return (
          <LinkBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            actionsRef={actionsRef}
            additions={additions}
          />
        )
      case types.VOCABULARY:
        return (
          <VocabularyBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.RESULT:
        return (
          <ResultBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.TEST:
        return (
          <TestBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.EXERCISE:
        return (
          <ExerciseBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      case types.ESSAY:
        return (
          <EssayBlock
            id={id}
            onStateChange={onStateChange}
            preloadedState={preloadedState}
            additions={additions}
          />
        )
      default:
        return null
    }
  }
  return (
    <React.Fragment>
      {(() => {
        if (type === types.EDITOR) {
          return (
            <EditorBlock
              opacity={opacity}
              dragRef={ref}
              preview={preview}
              onClickCopy={onCopy}
              onClickDelete={onDelete}
              id={id}
              onStateChange={onStateChange}
              preloadedState={preloadedState}
            />
          )
        } else if (type === types.DOUBLE_EDITOR) {
          return (
            <DoubleEditorBlock
              opacity={opacity}
              dragRef={ref}
              preview={preview}
              onClickCopy={onCopy}
              onClickDelete={onDelete}
              id={id}
              onStateChange={onStateChange}
              preloadedState={preloadedState}
            />
          )
        } else if (type === types.TABLE) {
          return (
            <TableBlock
              opacity={opacity}
              dragRef={ref}
              preview={preview}
              onClickCopy={onCopy}
              onClickDelete={onDelete}
              id={id}
              onStateChange={onStateChange}
              preloadedState={preloadedState}
            />
          )
        } else {
          return (
            <>
              <div style={{ opacity }} ref={preview} className="block">
                <div className="actions-container">
                  <div ref={ref} className="left-side-actions">
                    <img src={Move} alt="move" />
                  </div>
                  <div className="right-side-actions">
                    <div ref={actionsRef} className="sub-actions" />
                    <img src={Copy} onClick={onCopy} alt="Copy" />
                    <img src={Delete} onClick={onDelete} alt="Delete" />
                  </div>
                </div>
                <div className="content-container">{renderContent()}</div>
              </div>
            </>
          )
        }
      })()}
    </React.Fragment>
  )
}

export default Block
