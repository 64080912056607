import React from 'react';
import { Link } from 'react-router-dom';

import { TeacherButton as Button, Chip } from '@components';
import CheckboxIcon from '@components/Icons/CheckboxIcon';

import * as Styled from './classroom-files.styles';
import { NAVIGATOR } from '@utils/constants';

type PropsType = {
  cancelHandler: () => void;
  student: { name?: string; avatar?: string } | null;
  startLesson: () => void;
};

const StartLesson = (props: PropsType) => {
  if (props.student) {
    return (
      <Styled.StartLessonContiner>
        <Styled.StartLessonTitle>Начать урок?</Styled.StartLessonTitle>
        <div className={'checkbox'}>
          {' '}
          <CheckboxIcon />
          <p>Ученик получит ссылку на занятие</p>
        </div>

        <div style={{ margin: '15px 0px 30px 80px' }}>
          <Chip img={props.student.avatar} name={props.student.name} color={'#E5E5E5'} />
        </div>
        <div className={'checkbox'}>
          {' '}
          <CheckboxIcon />
          <p>Включится таймер с обратным отсчетом до конца занятия</p>
        </div>

        <Styled.AddFileModalFooter style={{ marginBottom: 0 }}>
          <Button whiteTheme bgWhite children='Начать' type='button' onClick={props.startLesson} />

          <Styled.CancelButton onClick={props.cancelHandler}> Отмена</Styled.CancelButton>
        </Styled.AddFileModalFooter>
      </Styled.StartLessonContiner>
    );
  }

  return (
    <Styled.StartLessonContiner>
      <Styled.StartLessonTitle>Вы не можете начать урок</Styled.StartLessonTitle>
      <div style={{ margin: '0px 35px 10px 35px' }}>
        <p>Сейчас в календаре у вас нет занятия. Добавьте урок в календарь или попросите менеджера</p>
      </div>

      <Styled.AddFileModalFooter style={{ marginBottom: 0 }}>
        <Link target='_blank' to={NAVIGATOR.teacher_calendar.path}>
          <Button whiteTheme bgWhite children='Открыть календарь' type='button' onClick={props.cancelHandler} />
        </Link>
        <Styled.CancelButton onClick={props.startLesson}>Начать все-равно</Styled.CancelButton>
        <Styled.CancelButton onClick={props.cancelHandler}> Окей</Styled.CancelButton>
      </Styled.AddFileModalFooter>
    </Styled.StartLessonContiner>
  );
};

export default StartLesson;
