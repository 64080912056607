import { put, takeEvery, call, all } from 'redux-saga/effects'

import {
  GET_TESTS,
  GET_TESTS_FULFILLED,
  GET_TESTS_REJECTED,
  CREATE_TEST,
  CREATE_TEST_FULFILLED,
  CREATE_TEST_REJECTED,
  DELETE_TEST,
  DELETE_TEST_FULFILLED,
  DELETE_TEST_REJECTED,
  DUPLICATE_TEST,
  DUPLICATE_TEST_FULFILLED,
  DUPLICATE_TEST_REJECTED,
  EDIT_TEST,
  EDIT_TEST_FULFILLED,
  EDIT_TEST_REJECTED,
  ASSIGN_TEST,
  ASSIGN_TEST_FULFILLED,
  ASSIGN_TEST_REJECTED,
} from '@types/tests'

import { sagaFirebase as firestore } from '@configs/firebase'
import { logger } from '@utils/ConsoleLogger'

function* getTests() {
  try {
    const testsSnapshot = yield call(firestore.getCollection, 'tests')
    const tests = testsSnapshot.docs.map(test => ({
      id: test.id,
      ...test.data(),
    }))
    yield put({
      type: GET_TESTS_FULFILLED,
      payload: {
        tests: tests.sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return 1
          }
          if (a.timestamp > b.timestamp) {
            return -1
          }
          return 0
        }),
      },
    })
  } catch (error) {
    yield put({
      type: GET_TESTS_REJECTED,
      payload: error,
    })
  }
}

function* createTest({
  payload: {
    description = '',
    name = '',
    time = '',
    timestamp,
    createdBy = '',
    status = '',
    color,
  },
}) {
  try {
    const { id: testId } = yield call(firestore.addDocument, 'tests', {
      description,
      name,
      time,
      timestamp,
      createdBy,
      status,
      pages: [
        {
          id: 0,
          name: 'Название страницы',
          pageState: [],
        },
      ],
      order: 1,
      color,
    })

    yield call(firestore.setDocument, `tests/${testId}`, {
      id: testId,
      description,
      name,
      time,
      timestamp,
      createdBy,
      status,
      pages: [
        {
          id: 0,
          name: 'Название страницы',
          pageState: [],
        },
      ],
      order: 1,
      color,
    })

    yield call(getTests)

    yield put({
      type: CREATE_TEST_FULFILLED,
      payload: {
        id: testId,
        description,
        name,
        time,
        createdBy,
      },
    })
  } catch (error) {
    yield put({
      type: CREATE_TEST_REJECTED,
      payload: error,
    })
  }
}

function* deleteTest({ payload: { id } }) {
  try {
    const testsSnapshot = yield call(firestore.getCollection, 'tests')
    yield all(
      testsSnapshot.docs.map(
        ({ id: testId }) => testId === id && call(firestore.deleteDocument, `tests/${testId}`)
      )
    )
    yield call(getTests)
    yield put({
      type: DELETE_TEST_FULFILLED,
    })
  } catch (error) {
    logger.error(error)
    yield put({
      type: DELETE_TEST_REJECTED,
      payload: error,
    })
  }
}

function* duplicateTest({ payload: { id = 0 } }) {
  try {
    const test = yield call(firestore.getDocument, `tests/${id}`)
    const { name, description, pages, time, order } = test.data()

    const newTest = yield call(firestore.addDocument, 'tests', {
      name,
      description,
      pages,
      time,
      order,
    })

    yield call(firestore.setDocument, `tests/${newTest.id}`, {
      id: newTest.id,
      name,
      description,
      pages,
      time,
      order,
    })

    yield call(getTests)

    yield put({
      type: DUPLICATE_TEST_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: DUPLICATE_TEST_REJECTED,
      payload: error,
    })
  }
}

function* editTest({ payload: { testId, testData } }) {
  try {
    const test = yield call(firestore.getDocument, `tests/${testId}`)

    yield call(firestore.setDocument, `tests/${testId}`, { ...test.data(), ...testData })

    yield call(getTests)

    yield put({
      type: EDIT_TEST_FULFILLED,
    })
  } catch (error) {
    yield put({
      type: EDIT_TEST_REJECTED,
      payload: error,
    })
  }
}

function* assignTestToStudent({ payload: { testId, studentId } }) {
  try {
    const student = yield call(firestore.getDocument, `students/${studentId}`)

    if (!student.data().assignedTests) {
      yield call(firestore.setDocument, `students/${studentId}`, {
        ...student.data(),
        assignedTests: [{ id: testId, isPassed: false }],
      })

      yield put({
        type: ASSIGN_TEST_FULFILLED,
      })
    }

    if (
      student.data().assignedTests &&
      !student.data().assignedTests.find(({ id }) => id === testId)
    ) {
      yield call(firestore.setDocument, `students/${studentId}`, {
        ...student.data(),
        assignedTests: [...student.data().assignedTests, { id: testId, isPassed: false }],
      })

      yield put({
        type: ASSIGN_TEST_FULFILLED,
      })
    }
  } catch (error) {
    yield put({
      type: ASSIGN_TEST_REJECTED,
      payload: error,
    })
  }
}

function* watchGetTests() {
  yield takeEvery(GET_TESTS, getTests)
}

function* watchCreateTest() {
  yield takeEvery(CREATE_TEST, createTest)
}

function* watchDeleteTest() {
  yield takeEvery(DELETE_TEST, deleteTest)
}

function* watchDuplicateTest() {
  yield takeEvery(DUPLICATE_TEST, duplicateTest)
}

function* watchEditTest() {
  yield takeEvery(EDIT_TEST, editTest)
}

function* watchAssignTest() {
  yield takeEvery(ASSIGN_TEST, assignTestToStudent)
}

export default [
  watchGetTests(),
  watchCreateTest(),
  watchDeleteTest(),
  watchDuplicateTest(),
  watchEditTest(),
  watchAssignTest(),
]
