let version = ''

try {
  const list = document.scripts
  for (let i = 0; i < list.length; i += 1) {
    const sc = document.scripts.item(i)
    let s = sc.src.match(/-time([0-9]+)/)
    if (s) {
      const [, second] = s
      s = second
      const date = new Date()
      date.setTime(s)
      version = `updated: ${date.toLocaleString()}`
      break
    }
  }
} catch (e) {}

const defaultFooter = () => version
export default defaultFooter
