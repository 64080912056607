import React from 'react'

const FactoryIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M10 15L9.29289 14.2929C9.10536 14.4804 9 14.7348 9 15H10ZM21.25 3.75L21.9571 3.04289C21.5666 2.65237 20.9334 2.65237 20.5429 3.04289L21.25 3.75ZM26.25 8.75L26.9571 9.45711C27.3476 9.06658 27.3476 8.43342 26.9571 8.04289L26.25 8.75ZM15 20V21C15.2652 21 15.5196 20.8946 15.7071 20.7071L15 20ZM10 20H9C9 20.5523 9.44772 21 10 21V20ZM25 25V26C25.2652 26 25.5196 25.8946 25.7071 25.7071C25.8946 25.5196 26 25.2652 26 25H25ZM5 25H4C4 25.5523 4.44772 26 5 26L5 25ZM5 5L5 4C4.44772 4 4 4.44771 4 5L5 5ZM15 6C15.5523 6 16 5.55228 16 5C16 4.44771 15.5523 4 15 4V6ZM26 15C26 14.4477 25.5523 14 25 14C24.4477 14 24 14.4477 24 15H26ZM10.7071 15.7071L21.9571 4.45711L20.5429 3.04289L9.29289 14.2929L10.7071 15.7071ZM20.5429 4.45711L25.5429 9.45711L26.9571 8.04289L21.9571 3.04289L20.5429 4.45711ZM25.5429 8.04289L14.2929 19.2929L15.7071 20.7071L26.9571 9.45711L25.5429 8.04289ZM15 19H10V21H15V19ZM11 20V15H9V20H11ZM16.7929 8.20711L21.7929 13.2071L23.2071 11.7929L18.2071 6.79289L16.7929 8.20711ZM25 24L5 24L5 26L25 26V24ZM6 25L6 5L4 5L4 25H6ZM5 6L15 6V4L5 4L5 6ZM24 15V25H26V15H24Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default FactoryIcon
