import { Dispatch } from 'redux'

import { database as db } from '@configs/firebase'
import { PLAYER_STATE, TZ_MSK } from '@utils/constants'
import { generalUtils } from '@utils'

import { SyncActionsTypes } from '@store/synchronization/types'
import {
  setPlayerStateAction,
  setCursorStateAction,
  setDocumentStateAction,
  setNotebookStateAction,
  setSelectedVideoStateAction,
  setPrevPlayerStateAction,
  setSetScrollToFileAction,
  setTranslatedWordStateAction,
  setExerciseStateAction,
} from '@store/synchronization/actions'
import moment from 'moment'

import { IReactPlayerState } from '@models/ISyncData'

export const listenerSyncState = (classID: string, detach: boolean = false) => {
  const documentStateRef = db.ref(`${classID}/documentState`)
  const cursorStateRef = db.ref(`${classID}/cursorState`)
  const notebookRef = db.ref(`${classID}/notebookState`)
  const translatedWordRef = db.ref(`${classID}/twState`)
  // const studentRef = db.ref(`${classID}/user`)
  const playerStateRef = db.ref(`${classID}/reactPlayerState/videoPlayerState`)
  const selectedVideoRef = db.ref(`${classID}/reactPlayerState/selectedVideo`)
  const prevPlayerRef = db.ref(`${classID}/reactPlayerState/prevPlayerState`)
  const scrollToFileRef = db.ref(`${classID}/scrollToFile`)

  const exercisesRef = db.ref(`exerciseStates/${classID}`)
  return async (dispatch: Dispatch<SyncActionsTypes>) => {
    let documentListener = documentStateRef.on('value', snapshot => {
      if (snapshot.val() && snapshot.val().pageNumbers) {
        dispatch(setDocumentStateAction(snapshot.val().pageNumbers))
      }
    })

    let cursorListener = cursorStateRef.on('value', snapshot => {
      if (snapshot.val()) {
        dispatch(setCursorStateAction(snapshot.val()))
      }
    })
    let exercisesListener = exercisesRef.on('value', snapshot => {
      if (snapshot.val()) {
        dispatch(setExerciseStateAction(snapshot.val()))
      } else {
        dispatch(setExerciseStateAction({}))
      }
    })
    let notebookListener = notebookRef.on('value', async snapshot => {
      if (snapshot.val()) {
        dispatch(setNotebookStateAction(snapshot.val()))
      } else {
        dispatch(
          setNotebookStateAction({
            text: '',
            userTyping: '',
          })
        )
      }
    })
    let translatedWordListener = translatedWordRef.on(
      'value',
      async snapshot => {
        let snapshotVal = snapshot.val()
        if (snapshotVal) {
          dispatch(setTranslatedWordStateAction(snapshotVal))
        } else {
          dispatch(
            setTranslatedWordStateAction({
              w: '',
              r: '',
              stl: '',
            })
          )
        }
      }
    )
    // let studentListener = studentRef.on('value', snapshot => {
    //   if (snapshot.val()) {
    //     dispatch(setUserState(snapshot.val()))
    //   }
    // })

    let playerListener = playerStateRef.on('value', snapshot => {
      if (snapshot.val()) {
        dispatch(setPlayerStateAction(snapshot.val()))
      } else {
        dispatch(
          setPlayerStateAction({ playerState: 'unstarted', timeInVideo: 0 })
        )
      }
    })

    let selectVideoListener = selectedVideoRef.on('value', snapshot => {
      if (snapshot.val()) {
        dispatch(setSelectedVideoStateAction(snapshot.val()))
      } else {
        dispatch(setSelectedVideoStateAction(null))
      }
    })

    let scrollToFileListener = scrollToFileRef.on('value', snapshot => {
      if (snapshot.val() && snapshot.val().fileId) {
        dispatch(setSetScrollToFileAction(snapshot.val().fileId))
      } else {
        dispatch(setSetScrollToFileAction(null))
      }
    })

    let prevPlayerListener = prevPlayerRef.on('value', snapshot => {
      if (snapshot.val()) {
        dispatch(setPrevPlayerStateAction(snapshot.val()))
      } else {
        dispatch(setPrevPlayerStateAction(PLAYER_STATE.unstarted))
      }
    })
    if (detach) {
      playerStateRef.off('value', playerListener)
      prevPlayerRef.off('value', prevPlayerListener)
      // studentRef.off('value', studentListener)
      translatedWordRef.off('value', translatedWordListener)
      notebookRef.off('value', notebookListener)
      cursorStateRef.off('value', cursorListener)
      documentStateRef.off('value', documentListener)
      selectedVideoRef.off('value', selectVideoListener)
      scrollToFileRef.off('value', scrollToFileListener)
      exercisesRef.off('value', exercisesListener)
    }
  }
}

export const onStartLessonSendToServer = (
  studentId: string,
  classId: string,
  end: boolean
) => {
  if (end) {
    db.ref(`isStartLesson/${studentId}`).update({ start: null, classId })
  } else {
    db.ref(`isStartLesson/${studentId}`).update({
      start: moment.tz(TZ_MSK).format(),
      classId,
    })
  }
}

export const changeScrollToFile = (classId: string, fileId: string) => {
  db.ref(`${classId}/scrollToFile`).update({ fileId })
}

export const emitNewPlayerStateToServer = (newPlayerState, classID) => {
  const timeInVideo =
    newPlayerState.timeInVideo <= 1 ? 0 : newPlayerState.timeInVideo
  const videoPlayerState = {
    playerState: newPlayerState.playerState,
    timeInVideo: generalUtils.toFixedNumber(timeInVideo, 2),
  }
  var reactPlayerState = { videoPlayerState } as IReactPlayerState
  if (newPlayerState.selectedVideo) {
    reactPlayerState.selectedVideo = newPlayerState.selectedVideo
  }

  if (newPlayerState.prevPlayerState) {
    reactPlayerState.prevPlayerState = newPlayerState.prevPlayerState
  }

  db.ref(`${classID}`)
    .child('reactPlayerState')
    .update(reactPlayerState)
}
