import { TranslateSpanish } from '@utils/constants'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import * as Styled from './spanish-symbol.styles'

function SpanishSymbol(props) {
  const { event, onchangeSymbol, isExercise } = props
  const [curPosition, setCurPosition] = useState(0)
  const [isLastChar, setIsLastChar] = useState('')
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [isVisible, setIsVisible] = useState(false)

  function createCopy(textArea) {
    var copy = document.createElement('div')
    copy.textContent = textArea.value
    var style = getComputedStyle(textArea)
    ;[
      'fontFamily',
      'fontSize',
      'fontWeight',
      'wordWrap',
      'whiteSpace',
      'borderLeftWidth',
      'borderTopWidth',
      'borderRightWidth',
      'borderBottomWidth',
    ].forEach(function(key) {
      copy.style[key] = style[key]
    })
    copy.style.overflow = 'auto'
    copy.style.width = textArea.offsetWidth + 'px'
    copy.style.height = textArea.offsetHeight + 'px'
    copy.style.position = 'absolute'
    copy.style.left = textArea.offsetLeft + 'px'
    copy.style.top = textArea.offsetTop + 'px'
    document.body.appendChild(copy)
    return copy
  }

  const debouncedCheckSymbol = debounce(() => {
    setIsVisible(true)
  }, 250)

  useEffect(() => {
    const lastChar = event?.value[event?.selectionStart - 1]
    Object.keys(TranslateSpanish).includes(lastChar) ? debouncedCheckSymbol() : setIsVisible(false)
    setIsLastChar(lastChar)
    setCurPosition(event?.selectionStart)
    if (event && lastChar) {
      var start = event.selectionStart
      var end = event.selectionEnd
      var copy = createCopy(event)
      var range = document.createRange()
      range?.setStart(copy.firstChild, start)
      range?.setEnd(copy.firstChild, end)
      var selection = document.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      var rect = range.getBoundingClientRect()
      document.body.removeChild(copy)
      event.selectionStart = start
      event.selectionEnd = end
      event.focus()
      const x = rect.left - event.scrollLeft
      const y = rect.top - event.scrollTop
      const width =
        event.clientWidth -
        parseInt(window.getComputedStyle(event, null).getPropertyValue('padding-left')) * 2

      setPosition({
        left: x > width ? x - width : x,
        top: y - y / 20,
      })
    }
  }, [event?.value])

  const translateSymbol = char => {
    const discreption = event?.value
    let temp = discreption.split('')
    temp.splice(curPosition - 1, 1, `${char}`)
    temp = temp.join('')
    event.value = temp
    setIsVisible(false)
    event.selectionStart = curPosition
    event.selectionEnd = curPosition
    event.focus()
    onchangeSymbol(temp)
  }

  return (
    <>
      {isVisible && TranslateSpanish[isLastChar] ? (
        isLastChar !== 'u' ? (
          <Styled.tootTip
            onClick={() => translateSymbol(TranslateSpanish[isLastChar])}
            style={{
              right: isExercise ? 0 : 15,
              top: `${position.top - (isExercise ? 56 : 40)}px`,
            }}
          >
            {TranslateSpanish[isLastChar]}
          </Styled.tootTip>
        ) : (
          <>
            <Styled.tootTip
              onClick={() => translateSymbol(TranslateSpanish[isLastChar][0])}
              style={{
                right: isExercise ? 45 : 60,
                top: `${position.top - (isExercise ? 56 : 40)}px`,
              }}
            >
              {TranslateSpanish[isLastChar][0]}
            </Styled.tootTip>
            <Styled.tootTip
              onClick={() => translateSymbol(TranslateSpanish[isLastChar][1])}
              style={{
                right: isExercise ? 0 : 15,
                top: `${position.top - (isExercise ? 56 : 40)}px`,
              }}
            >
              {TranslateSpanish[isLastChar][1]}
            </Styled.tootTip>
          </>
        )
      ) : (
        ''
      )}
    </>
  )
}

export default SpanishSymbol
