import { TeacherButton as Button } from '@components'
import React, { useEffect, useState } from 'react'
import * as Styled from './teacher-profile.styles'
import MicRecorder from 'mic-recorder-to-mp3'
import AudioPlayerWaveForm from '@components/Student/TeacherCard/AudioPlayerWaveForm'
import { IconButton } from '@material-ui/core'
import { DeleteIcon } from '@components/Icons'
import { logger } from '@utils/ConsoleLogger'
const Mp3Recorder = new MicRecorder({ bitRate: 128 })

export const AudioRecordModal = ({ handleClose, handleSave, title, description = '' }) => {
  const [blobURL, setBlobURL] = useState(null)
  const [blobData, setBlobData] = useState(null)
  const [duration, setDuration] = useState(0)

  const [isRecording, setIsRecording] = useState(false)

  const [isBlocked, setIsBlocked] = useState(false)

  useEffect(() => {
    let intervalId = null
    if (isRecording) {
      intervalId = setInterval(() => {
        setDuration(prev => prev + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
      setDuration(0)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [isRecording])

  const start = () => {
    if (isBlocked) {
      logger.debug('Permission Denied')
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch(e => console.error(e))
    }
  }
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        setIsBlocked(false)
      })
      .catch(err => {
        setIsBlocked(true)
      })
  }, [])

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setBlobData(blob)
        setIsRecording(false)
        setBlobURL(URL.createObjectURL(blob))
      })
      .catch(e => logger.debug(e))
  }

  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${returnedSeconds}`
  }

  return (
    <Styled.AudioRecordModalContainer>
      <Styled.AudioRecordModalHeader>
        {(isBlocked ? 'Доступ к микрофону' : title) || 'Аудио презентация'}
      </Styled.AudioRecordModalHeader>

      {blobURL ? (
        <>
          <Styled.AudioDescription>{description}</Styled.AudioDescription>
          <Styled.AudioButtons>
            <AudioPlayerWaveForm
              audio={blobURL}
              isProfile
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
                width: 376,
                marginRight: 20,
              }}
            />
            <IconButton
              className="delete-btn"
              onClick={() => {
                setIsRecording(false)
                setBlobURL(null)
              }}
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
                width: 44,
                height: 44,
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Styled.AudioButtons>
        </>
      ) : (
        <>
          {isRecording && <Styled.AudioDescription>{description}</Styled.AudioDescription>}
          <Styled.AudioDescModal
            style={{ marginBottom: isRecording ? 30 : 20 }}
            isBlock={isBlocked}
          >
            {isRecording
              ? calculateTime(duration)
              : isBlocked
              ? 'Чтобы мы смогли записать ваш голос, разрешите доступ к микрофону в вашем бразуере.'
              : 'Проверьте микрофон и нажмите на кнопку “Начать запись”'}
          </Styled.AudioDescModal>
        </>
      )}

      <Styled.AudioRecordModalFooter isRecording={isRecording}>
        {!isBlocked && (
          <Button
            whiteTheme={!isBlocked}
            bgWhite={!isBlocked}
            children={isRecording ? 'Стоп' : blobURL ? 'Сохранить' : 'Начать запись'}
            type="button"
            onClick={() => (isRecording ? stop() : blobURL ? handleSave(blobData) : start())}
          />
        )}
        <Styled.CancelButton onClick={handleClose}>Отмена</Styled.CancelButton>
      </Styled.AudioRecordModalFooter>
    </Styled.AudioRecordModalContainer>
  )
}
