import React from "react";

export default function ClearBoardIcon(props) {
  let opacity = {};
  opacity = props.disabled ? { opacity: 0.5 } : null;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...opacity}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5584 8.05958C15.9809 7.72822 15.9809 7.08207 15.5584 6.75072L9.95773 2.39337C9.36121 1.92947 8.52443 1.92947 7.91963 2.39337L6.79287 3.27975L13.3214 9.80748L15.5584 8.05958ZM15.5584 10.9507L15.5501 10.9424C15.4057 10.8297 15.2279 10.7684 15.0447 10.7684C14.8616 10.7684 14.6837 10.8297 14.5393 10.9424L14.4979 10.9755L15.6578 12.1353C15.7326 12.0526 15.7896 11.9555 15.8253 11.8499C15.861 11.7443 15.8746 11.6325 15.8653 11.5214C15.856 11.4103 15.8239 11.3023 15.7711 11.2041C15.7183 11.106 15.6459 11.0197 15.5584 10.9507ZM16.7597 15.5897L2.41012 1.24191C2.33342 1.16521 2.24236 1.10438 2.14214 1.06287C2.04192 1.02136 1.9345 1 1.82603 1C1.71755 1 1.61014 1.02136 1.50992 1.06287C1.4097 1.10438 1.31864 1.16521 1.24194 1.24191C1.16523 1.3186 1.10439 1.40965 1.06288 1.50986C1.02137 1.61006 1 1.71746 1 1.82592C1 1.93439 1.02137 2.04179 1.06288 2.14199C1.10439 2.2422 1.16523 2.33325 1.24194 2.40994L4.15825 5.32588L2.31899 6.75072C1.89645 7.08207 1.89645 7.72822 2.31899 8.05958L7.91963 12.4169C8.51615 12.8808 9.35293 12.8808 9.95773 12.4169L10.6785 11.8536L11.855 13.0299L9.43578 14.9104C9.13752 15.1423 8.71499 15.1423 8.41673 14.9104L3.32147 10.9507C3.17713 10.8379 2.99923 10.7767 2.81608 10.7767C2.63293 10.7767 2.45504 10.8379 2.3107 10.9507C2.21202 11.0279 2.13221 11.1266 2.07732 11.2393C2.02244 11.352 1.99392 11.4756 1.99392 11.601C1.99392 11.7263 2.02244 11.8499 2.07732 11.9626C2.13221 12.0753 2.21202 12.174 2.3107 12.2512L7.91134 16.6086C8.50786 17.0725 9.34464 17.0725 9.94945 16.6086L13.0315 14.2145L15.5749 16.7577C15.8981 17.0808 16.42 17.0808 16.7431 16.7577C17.0828 16.4346 17.0828 15.9127 16.7597 15.5897Z"
        fill="white"
      />
    </svg>
  );
}
