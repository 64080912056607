import * as React from 'react'

import * as Styled from './currency-selector.style'
import { getCurrencyTitle, CURRENCIES, APP_CONFIG } from '@utils/constants'

import DownArrow from '@assets/images/dropdown.png'
import { LangEnum } from '@models/IProfile'

interface Props {
  chosenOption: CURRENCIES
  setOption: (currency: CURRENCIES) => void
  isTeacher?: boolean
}

const CurrencySelector = ({ chosenOption, setOption, isTeacher }: Props) => {
  const [open, setOpen] = React.useState(false)

  const handleOptionClick = (currency: CURRENCIES) => {
    setOption(currency)
    setOpen(!open)
  }

  const getTeacherCurrencies = () => Object.keys(CURRENCIES).filter(key => key !== CURRENCIES.KZT)

  const getNonTeacherCurrencies = () =>
    Object.keys(CURRENCIES)
      .filter(key => key !== CURRENCIES.USD)
      .filter(key => (APP_CONFIG.lang === LangEnum.es ? true : key !== CURRENCIES.RUB))

  const currencies = React.useMemo(() => {
    return isTeacher ? getTeacherCurrencies() : getNonTeacherCurrencies()
  }, [isTeacher])

  return (
    <Styled.CurrencySwitchContainer>
      <Styled.ChosenOption onClick={() => setOpen(!open)}>
        {getCurrencyTitle(chosenOption)}
        <img src={DownArrow} alt="" />
      </Styled.ChosenOption>
      {open && (
        <Styled.Options>
          {currencies.map((key: string) => {
            const title = getCurrencyTitle(CURRENCIES[key])
            if (title) {
              return (
                <Styled.OptionItem key={key} onClick={() => handleOptionClick(CURRENCIES[key])}>
                  {title}
                </Styled.OptionItem>
              )
            }
          })}
        </Styled.Options>
      )}
    </Styled.CurrencySwitchContainer>
  )
}

export default CurrencySelector
