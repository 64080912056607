import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import RSC from 'react-scrollbars-custom'

import { withStyles, Tooltip as MuiToolTip, IconButton as MuiIconButton } from '@material-ui/core'

import { classroomsRef, storageRef, usersRef } from '@configs/firebase'
import {
  PROJECT_FILES_TYPES,
  ERROR_LOAD_FILE,
  FILE_LOAD_TYPES,
  CLASSROOM_BLOCK_TYPES,
  ERROR_TYPE_FILE,
} from '@utils/constants'
import { useTranslation } from 'react-i18next'

import { ILibraryFile, IClassroomBlock } from '@models'

import { RootState, AppDispatch } from '@store'
import { updateProfileFiles, getProfileFiles, addFileToClassroom } from '@servise'

import { TableItemContent, TableItemIcon } from '@pages/teacher/Library/library.styles'
import { Checkbox, ConfigInput, SelectFile, TeacherButton as Button } from '@components'

import Flurry from '@components/Icons/flurry.png'
import Pdf from '@components/Icons/pdf.png'
import TypeMusicIcon from '@components/Icons/TypeMusic'
import ToolTipInfo from '@components/Icons/tool_tip_info.svg'

import * as Styled from './classroom-files.styles'

export const Tooltip = withStyles({
  tooltip: {
    background: 'transparent',
  },
})(MuiToolTip)

const PdfInfoTooltipText = () => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        padding: '10px',
        fontWeight: 600,
        zIndex: 99999,
        color: '#414752',
        fontSize: '12px',
        lineHeight: '16px',
        fontFamily: 'Open Sans',
        borderRadius: 8,
        backgroundColor: 'RGBA(144,164,189,0.5)',
      }}
    >
      <div>{t('ToolTip.WeRecommendUploadPdf')}</div>
      <div
        style={{
          marginTop: '5px',
        }}
      >
        {t('ToolTip.OtherFormatsWillDisplay')}
      </div>
    </div>
  )
}

type PropsType = {
  actionType: string
  userID: string
  googleDrive: IGoogleDrive
  file: any
  classID: string
  cancelHandler: () => void
}
const AddFileModal = (props: PropsType) => {
  const fileTypeConfig = PROJECT_FILES_TYPES[props.actionType || 'image']
  let uploadTask = null
  const [sending, setSending] = useState(false)
  const [file, setFile] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState<IClassroomBlock[]>([])
  const [loadType, setLoadType] = useState(
    props.actionType === CLASSROOM_BLOCK_TYPES.VIDEO ? FILE_LOAD_TYPES.LINK : FILE_LOAD_TYPES.LOCAL
  )
  const { t } = useTranslation()
  const [titleImage, setTitleImage] = useState(false)
  const [libFiles, setLibFiles] = useState([])
  const states = useSelector((state: RootState) => ({
    libFiles: state.profile.files,
  }))

  const { classID, userID } = props
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    if (fileTypeConfig.type)
      setLibFiles(states.libFiles.filter(item => item.type === fileTypeConfig.type))
  }, [fileTypeConfig.type, states.libFiles])

  const addSelectedFiles = async () => {
    let fileData: IClassroomBlock = { type: fileTypeConfig.type }

    try {
      for (let file of selectedFiles) {
        fileData.url = file.url
        fileData.file_name = file.file_name
        fileData.created_at = new Date()
        fileData.isLocked = false

        await dispatch(addFileToClassroom(fileData, classID))
      }

      if (titleImage && selectedFiles[0]) {
        await classroomsRef.doc(classID).update({ img: selectedFiles[0].url })
      }
      setSending(false)
      toast(t(fileTypeConfig.message, { type: t(fileTypeConfig.name) }), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-success',
      })
      cancelHandler()
    } catch (error) {
      setSending(false)
      toast(t(ERROR_LOAD_FILE), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-error',
      })
    }
  }

  useEffect(() => {
    dispatch(getProfileFiles(userID))
  }, [dispatch, userID])
  const handleSetLink = e => {
    setFile(e.target.value)
  }

  const addFile = (e: React.ChangeEvent<HTMLInputElement>, fileData: IClassroomBlock) => {
    const checked = e.target.checked

    if (checked) {
      setSelectedFiles([...selectedFiles, { ...fileData }])
    } else {
      setSelectedFiles([...selectedFiles.filter(item => item.url !== fileData.url)])
    }
  }

  const cancelHandler = () => {
    if (uploadTask) {
      uploadTask.cancel()
    }

    props.cancelHandler()
  }
  const setSelectedFile = file => {
    setFile(file ? file : null)
  }

  const handleUploadFile = async () => {
    try {
      const withLib = loadType === FILE_LOAD_TYPES.LIB

      var fileData = {
        created_at: new Date(),
        url: null,
        type: fileTypeConfig.type,
        file_name: null,
      }
      setSending(true)

      if (withLib) {
        addSelectedFiles()
      } else {
        if (file) {
          if (loadType === FILE_LOAD_TYPES.LINK) {
            fileData.file_name = file
            fileData.url = file
            saveFile(fileData)
          } else {
            const file_type = file.type.split('/')[0]
            if (file_type === fileTypeConfig.type || fileTypeConfig.type === 'docs') {
              uploadFileWithStorage()
            } else {
              setSending(false)
              toast(t('InvalidFileFormat', { type: t(fileTypeConfig.name) }), {
                autoClose: 3000,
                position: 'bottom-center',
                closeButton: false,
                hideProgressBar: true,
                className: 'message-error',
              })
            }
          }
        }
      }
    } catch (error) {
      setSending(false)
      toast(t(ERROR_LOAD_FILE), {
        autoClose: 3000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-error',
      })
    }
  }

  const uploadFileWithStorage = () => {
    var fileData = {
      created_at: new Date(),
      url: null,
      type: fileTypeConfig.type,
      file_name: null,
    }

    uploadTask = storageRef.ref(fileTypeConfig.type + '/' + file.name).put(file)
    uploadTask.on(
      'state_changed',
      snapshot => {
        setSending(true)
      },
      error => {
        setSending(false)
      },

      () => {
        storageRef
          .ref(fileTypeConfig.type)
          .child(file.name)
          .getDownloadURL()
          .then(url => {
            fileData.url = url
            fileData.file_name = file.name
            saveFile(fileTypeConfig.type === 'image' ? { ...fileData, isLocked: false } : fileData)
          })
      }
    )
  }

  const saveFile = async (fileData: IClassroomBlock | ILibraryFile) => {
    const classrooFile = fileData as IClassroomBlock
    if (titleImage) {
      await classroomsRef.doc(classID).update({ img: fileData.url })
    }
    dispatch(updateProfileFiles(fileData, userID))
      .then(() => {
        dispatch(addFileToClassroom(classrooFile, classID))
          .then(() => {
            setSending(false)
            cancelHandler()
            toast(t(fileTypeConfig.message, { type: fileTypeConfig.name }), {
              autoClose: 2000,
              position: 'bottom-center',
              closeButton: false,
              hideProgressBar: true,
              className: 'message-success',
            })
          })
          .catch(error => {
            setSending(false)
            logger.error('error', error)
            toast(t(ERROR_LOAD_FILE), {
              autoClose: 3000,
              position: 'bottom-center',
              closeButton: false,
              hideProgressBar: true,
              className: 'message-error',
            })
          })
      })
      .catch(error => {
        setSending(false)
        logger.error('error', error)
        toast(t(ERROR_LOAD_FILE), {
          autoClose: 3000,
          position: 'bottom-center',
          closeButton: false,
          hideProgressBar: true,
          className: 'message-error',
        })
      })
  }

  const checkedFile = (url: string) => {
    const hasFile =
      selectedFiles.filter(currentValue => {
        return currentValue.url === url
      }).length > 0
    return hasFile
  }

  const setTypeDocument = file => {
    switch (fileTypeConfig.type) {
      case CLASSROOM_BLOCK_TYPES.DOCS:
        return (
          <TableItemContent>
            <TableItemIcon src={Pdf} />
          </TableItemContent>
        )
      case CLASSROOM_BLOCK_TYPES.AUDIO:
        return <TypeMusicIcon />

      case CLASSROOM_BLOCK_TYPES.VIDEO:
        return (
          <TableItemContent>
            <TableItemIcon src={Flurry} />
          </TableItemContent>
        )

      case CLASSROOM_BLOCK_TYPES.IMAGE:
        return (
          <TableItemContent style={{ height: 41, width: 60 }}>
            <TableItemIcon src={file.url} />
          </TableItemContent>
        )

      default:
        return null
    }
  }

  return (
    <Styled.AddFileModalContainer>
      <Styled.AddFileModalTitle>
        {t(fileTypeConfig.title)}
        {fileTypeConfig.type === CLASSROOM_BLOCK_TYPES.DOCS && (
          <Tooltip title={<PdfInfoTooltipText />} placement="bottom-start">
            <Styled.PdfInfoIcon src={ToolTipInfo} />
          </Tooltip>
        )}
      </Styled.AddFileModalTitle>

      <Styled.AddFileModalMain>
        <Styled.AddFileModalField>
          <Checkbox
            type="radio"
            name="load_type"
            tip={t('Classroom.AddLink')}
            checked={loadType === FILE_LOAD_TYPES.LINK}
            onChange={() => setLoadType(FILE_LOAD_TYPES.LINK)}
          />
          {loadType !== FILE_LOAD_TYPES.LIB && (
            <ConfigInput
              inputStyleType="white"
              inputClass="mt-input"
              type="url"
              required
              onChange={handleSetLink}
              onKeyPress={e => {
                if (e.key === 'Enter' && file) {
                  handleUploadFile()
                }
              }}
              disabled={loadType !== FILE_LOAD_TYPES.LINK}
            />
          )}
        </Styled.AddFileModalField>

        <Styled.AddFileModalField>
          <Checkbox
            type="radio"
            name="load_type"
            tip={t('Classroom.UploadFromComputer')}
            checked={loadType === FILE_LOAD_TYPES.LOCAL}
            onChange={() => setLoadType(FILE_LOAD_TYPES.LOCAL)}
          />
          {loadType !== FILE_LOAD_TYPES.LIB && (
            <SelectFile
              containerClass="mt-input select-file"
              accept={fileTypeConfig.type + '/*'}
              fileTypeConfig={fileTypeConfig}
              file={props.file}
              selectedFile={setSelectedFile}
              disabled={loadType !== FILE_LOAD_TYPES.LOCAL}
            />
          )}
        </Styled.AddFileModalField>
        {libFiles.length > 0 && (
          <Styled.AddFileModalField>
            <Checkbox
              type="radio"
              name="load_type"
              tip={t('Classroom.SelectFromLibrary')}
              checked={loadType === FILE_LOAD_TYPES.LIB}
              onChange={() => setLoadType(FILE_LOAD_TYPES.LIB)}
            />

            {loadType === FILE_LOAD_TYPES.LIB && (
              <Styled.ScrollContinerTable>
                <RSC
                  noDefaultStyles
                  noScrollX={true}
                  disableTracksWidthCompensation
                  style={{ position: '' }}
                >
                  <table>
                    {libFiles.map((row, index) => {
                      return (
                        <tr>
                          <td>
                            <Checkbox
                              type="checkbox"
                              name={row.name}
                              checked={checkedFile(row.url) ? true : false}
                              onChange={e => {
                                addFile(e, row)
                              }}
                            />
                          </td>

                          <td>{setTypeDocument(row)}</td>

                          <td>{row.file_name}</td>
                        </tr>
                      )
                    })}
                  </table>
                </RSC>
              </Styled.ScrollContinerTable>
            )}
          </Styled.AddFileModalField>
        )}
      </Styled.AddFileModalMain>
      {fileTypeConfig.type === CLASSROOM_BLOCK_TYPES.IMAGE && (
        <Styled.AddFileModalCheckbox>
          <Checkbox
            type="checkbox"
            name="title_image"
            tip={t('Classroom.MakeACaptionForTheLesson')}
            checked={titleImage}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTitleImage(e.target.checked)
            }}
          />
        </Styled.AddFileModalCheckbox>
      )}
      <Styled.AddFileModalFooter>
        <Button
          whiteTheme
          bgWhite
          children={t('Add')}
          type="button"
          loading={sending}
          disabled={!file && selectedFiles.length === 0}
          onClick={handleUploadFile}
        />
        <Styled.CancelButton onClick={() => cancelHandler()}>{t('Cancel')}</Styled.CancelButton>
      </Styled.AddFileModalFooter>
    </Styled.AddFileModalContainer>
  )
}

export default AddFileModal
