import React from 'react'

function PlayIcon(props) {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14061 1.59172C4.38141 1.46025 4.67479 1.47076 4.90557 1.61912L15.4056 8.36912C15.6202 8.50712 15.75 8.7448 15.75 9C15.75 9.2552 15.6202 9.49288 15.4056 9.63089L4.90557 16.3809C4.67479 16.5292 4.38141 16.5398 4.14061 16.4083C3.8998 16.2768 3.75 16.0244 3.75 15.75V2.25C3.75 1.97565 3.8998 1.72318 4.14061 1.59172ZM5.25 3.62375V14.3763L13.6131 9L5.25 3.62375Z"
        fill="white"
      />
    </svg>
  )
}

export default PlayIcon
