import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { debounce, isEqual } from 'lodash'

import Card from '@components/common/Card'
import CreationModal from '@components/CreationModal'
import EditingModal from '@components/CreationModal'
import DraggableActionCard from '@components/DraggableActionCard'
import DeletingModal from '@components/WarningModal'

import { createLesson, deleteLesson, duplicateLesson, editLesson, updateLessons } from '@actions'

import FeatherIcon from '@assets/images/Constructor/feather.svg'

const LessonTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { courseId } = useParams()

  const [visibilityCreationModal, setVisibilityCreationModal] = useState(false)
  const [visibilityDeletingModal, setVisibilityDeletingModal] = useState(false)
  const [visibilityEditingModal, setVisibilityEditingModal] = useState(false)
  const [lessonState, setLessonState] = useState(null)
  const [lessonId, setLessonId] = useState(null)

  const lessonsList = useSelector(store => store.course.lessonsList)

  const [lessons, setLessons] = useState([])

  useEffect(() => {
    if (lessonsList?.length) setLessons(lessonsList)
  }, [lessonsList])

  const delayedQuery = useCallback(
    debounce(() => {
      if (lessons.length && !isEqual(lessons, lessonsList)) {
        dispatch(updateLessons({ lessons, courseId }))
      }
    }, 3000),
    [lessons, lessonsList]
  )

  useEffect(() => {
    delayedQuery()
    return () => delayedQuery.cancel()
  }, [lessons, delayedQuery])

  const handleOpenCreationModal = useCallback(() => {
    setVisibilityCreationModal(true)
  }, [])

  const handleOpenDeletingModal = useCallback(id => {
    setVisibilityDeletingModal(true)
    setLessonId(id)
  }, [])

  const handleCreateLesson = useCallback(
    state => {
      setVisibilityCreationModal(false)
      dispatch(
        createLesson({
          courseId,
          lesson: state,
          timestamp: new Date().getTime(),
        })
      )
    },
    [courseId, dispatch]
  )

  const handleDeleteLesson = useCallback(() => {
    setVisibilityDeletingModal(false)
    dispatch(deleteLesson({ courseId, lessonId }))
  }, [courseId, dispatch, lessonId])

  const handleDuplicateLesson = useCallback(
    id => {
      dispatch(duplicateLesson({ courseId, lessonId: id }))
    },
    [courseId, dispatch]
  )

  const handleRedirect = useCallback(
    id => {
      history.push(`lesson/${courseId}/${id}`)
    },
    [courseId, history]
  )

  const handleOpenEditingModal = useCallback((id, image, name, description) => {
    setVisibilityEditingModal(true)
    setLessonState({ id, name, description, image })
    setLessonId(id)
  }, [])

  const handleEditLesson = useCallback(
    state => {
      setVisibilityEditingModal(false)
      dispatch(editLesson({ courseId, lessonId, lesson: state }))
    },
    [courseId, dispatch, lessonId]
  )

  const handleMoveLesson = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedLesson = lessons[dragIndex]
      const updated = update(lessons, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedLesson],
        ],
      }).map((l, index) => ({ ...l, order: index }))
      setLessons(updated)
    },
    [lessons, setLessons]
  )

  const mockLessonsList = useMemo(
    () =>
      lessons.map(({ id, image, name, description }, index) => (
        <DraggableActionCard
          key={id}
          title={name}
          description={description}
          image={image}
          lessonsTitleText={t('ActionCard.LessonsTitle')}
          actions={[
            {
              id: 'duplicate-action',
              name: t('ActionCard.DuplicateLessonTitle'),
              onClick: handleDuplicateLesson.bind({}, id),
            },
            {
              id: 'delete-action',
              name: t('ActionCard.DeleteLessonTitle'),
              onClick: handleOpenDeletingModal.bind({}, id),
            },
            {
              id: 'edit-action',
              name: 'Редактировать описание',
              onClick: handleOpenEditingModal.bind({}, id, image, name, description),
            },
          ]}
          onClick={handleRedirect.bind({}, id)}
          order={index}
          moveLesson={handleMoveLesson}
        />
      )),
    [
      handleDuplicateLesson,
      handleMoveLesson,
      handleOpenDeletingModal,
      handleOpenEditingModal,
      handleRedirect,
      lessons,
      t,
    ]
  )

  return (
    <div className="coursesTab cards-container">
      <Card className="big" onClick={handleOpenCreationModal}>
        <div className="creation-block-container">
          <img src={FeatherIcon} alt="feather" draggable={false} />
          <span>{t('LessonsRoute.CreateNewLessonTitle')}</span>
        </div>
      </Card>
      <DndProvider backend={HTML5Backend}>{mockLessonsList}</DndProvider>
      <CreationModal
        visibility={visibilityCreationModal}
        onSubmit={handleCreateLesson}
        onClose={setVisibilityCreationModal}
        localization={{
          title: t('LessonModal.Title'),
          mediaTitle: t('LessonModal.MediaTitle'),
          inputFileTitle: t('LessonModal.InputFileTitle'),
          inputFileSize: t('LessonModal.InputFileSize'),
          inputPlaceholderName: t('LessonModal.InputPlaceholderName'),
          inputPlaceholderDescription: t('LessonModal.InputPlaceholderDescription'),
          actionCreate: t('LessonModal.ActionCreate'),
          actionCancelCreation: t('LessonModal.ActionCancelCreation'),
        }}
      />
      <EditingModal
        visibility={visibilityEditingModal}
        onSubmit={handleEditLesson}
        onClose={setVisibilityEditingModal}
        preloadedState={lessonState}
        localization={{
          title: 'Редактировать описание урока',
          mediaTitle: t('LessonModal.MediaTitle'),
          inputFileTitle: t('LessonModal.InputFileTitle'),
          inputFileSize: t('LessonModal.InputFileSize'),
          inputPlaceholderName: t('LessonModal.InputPlaceholderName'),
          inputPlaceholderDescription: t('LessonModal.InputPlaceholderDescription'),
          actionCreate: 'Сохранить',
          actionCancelCreation: t('LessonModal.ActionCancelCreation'),
        }}
      />
      <DeletingModal
        isOnlyActions
        visibility={visibilityDeletingModal}
        onSubmit={handleDeleteLesson}
        onClose={setVisibilityDeletingModal}
        localization={{
          title: t('LessonWarningModalDeleteAction.Title'),
          description: t('LessonWarningModalDeleteAction.Description'),
          actionCreate: t('LessonWarningModalDeleteAction.ActionDelete'),
          actionCancelCreation: t('LessonWarningModalDeleteAction.ActionCancelDelete'),
        }}
      />
    </div>
  )
}

export default LessonTab
