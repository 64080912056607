import { logger } from '@utils/ConsoleLogger'
import { GOOGLE_AUTH } from '@utils/constants.ts'
import queryString from 'query-string'

const { clientID, clientSecret, tokenUrl } = GOOGLE_AUTH

class GoogleDriveClass {
  refreshToken = async refreshToken => {
    try {
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },

        body: `client_id=${clientID}&client_secret=${clientSecret}&refresh_token=${refreshToken}&grant_type=refresh_token`,
      })
      if (response.status === 200) {
        const responseToken = await response.json()
        localStorage.setItem('accessToken', responseToken.access_token)

        return true
      } else {
        return false
      }
    } catch (error) {
      logger.error(error)
      return false
    }
  }

  downloadFile = async fileUrl => {
    try {
      const accessToken = localStorage.getItem('accessToken')

      const values = queryString.parse(fileUrl)
      const fileID = values['https://drive.google.com/uc?id']
      let response
      if (fileID) {
        response = await fetch(
          'https://www.googleapis.com/drive/v3/files/' + fileID + '?alt=media',
          {
            method: 'GET',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
          }
        )
      } else {
        response = await fetch(fileUrl)
      }

      return response
    } catch (error) {
      logger.error(error)
    }
  }
}

export const GoogleDrive = new GoogleDriveClass()
