import React, { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { authRef } from '@configs/firebase'
import { useHistory } from 'react-router'
import Tabs from '@components/common/Tabs'
import CoursesTab from './coursesTab'
import TestsTab from './testsTab'
import MiniCoursesTab from './miniCoursesTab'
import { getManagerProfile, getProfile } from '../../servise/profileAPI'
import { useSelector } from 'react-redux'
import { NAVIGATOR } from '@utils/constants'
import { getCourses, getLessonsLength, resetCoursesList, resetLessonsLengthArr, getTests, resetTests } from "@actions";
import Dashboard from '@components/Dashboard'

const Courses = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const states = useSelector(state => ({
    authStatus: state.authStatus,
    profileData: state.profile.profile,
  }))

  const coursesList = useSelector(store => store.courses.coursesList)
  const profile = useSelector(store => store.profile.profile)

  useEffect(() => {
    if (states.authStatus === 'logged on') {
      const uid = authRef.currentUser.uid
      dispatch(getManagerProfile(uid))
        .then(() => {})
        .catch(e => {
          if (e && e.code === 'auth/manager-not-found') {
            dispatch(getProfile(uid))
              .then(() => {})
              .catch(e => {
                history.push(NAVIGATOR.forbidden.path)
              })
          }
        })
    }
  }, [dispatch, history, states.authStatus])

  useEffect(() => {
    if (profile) {
      if (coursesList.length) {
        coursesList.forEach(({ id }) => {
          dispatch(getLessonsLength({ id }))
        })
      } else {
        dispatch(getCourses())
      }
    }

    dispatch(getTests)
  }, [coursesList, dispatch, profile])

  useEffect(
    () => () => {
      dispatch(resetCoursesList())
      dispatch(resetLessonsLengthArr())
      dispatch(resetTests()) // delete it ?
    },
    [dispatch]
  )

  return (
    <>
      {profile && (
        <>
          <Dashboard>
            <Tabs
              tabs={
                ['MANAGER', 'METHODIST'].includes(profile.role)
                  ? [
                    {
                      index: 0,
                      component: <CoursesTab />,
                      name: t('CoursesRoute.Tab1'),
                    },
                    {
                      index: 1,
                      component: <MiniCoursesTab />,
                      name: t('CoursesRoute.Tab2'),
                    },
                    {
                      index: 2,
                      component: <TestsTab />,
                      name: t('CoursesRoute.Tab3'),
                    },
                  ]
                  : [
                    {
                      index: 0,
                      component: <MiniCoursesTab />,
                      name: t('CoursesRoute.Tab2'),
                      onClick: () => history.push('/courses'),
                    },
                    {
                      index: 1,
                      component: <TestsTab />,
                      name: t('CoursesRoute.Tab3'),
                      onClick: () => history.push('/tests'),
                    },
                  ]
              }
              activeTab={history.location.pathname === '/tests' && 'last'}
            />
          </Dashboard>
        </>
      )}
    </>
  )
}

export default memo(Courses)
