import React from 'react'

import Button from '@components/common/Button'
import { TeacherButton as StyledButton } from '@components'

const ModalActions = ({
  onSubmit,
  onClose,
  isDisabled,
  localization: { actionCreate, actionCancelCreation },
  isNewClass = false,
}) => (
  <div
    className="modal-actions-container"
    style={{ padding: isNewClass ? '20px 0px 30px' : '20px 0px 40px' }}
  >
    <div className="gradient-action-btn" style={{ marginBottom: 12 }}>
      <StyledButton
        disabled={isDisabled}
        whiteTheme
        btnClass={'search-btn'}
        children={actionCreate}
        onClick={onSubmit}
      />
    </div>

    <Button className="transparent" onClick={onClose}>
      {actionCancelCreation}
    </Button>
  </div>
)

export default ModalActions
