import React from "react";

export default function Font24Icon() {
  return (
    <svg
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4295 16.7715L11.2084 12.7957H5.06856L3.8475 16.7715H0L5.94404 0H10.3099L16.277 16.7715H12.4295ZM10.356 9.82527C9.22708 6.22267 8.58967 4.18526 8.44376 3.71304C8.30553 3.24082 8.20569 2.86761 8.14426 2.59341C7.89083 3.56832 7.1651 5.97894 5.96708 9.82527H10.356Z"
        fill="white"
      />
      <path
        d="M26.5464 16.7715L25.8667 15.0349H25.7746C25.1832 15.7737 24.5727 16.2879 23.943 16.5773C23.3209 16.8591 22.5069 17 21.5008 17C20.2644 17 19.2891 16.6496 18.5749 15.9489C17.8684 15.2482 17.5151 14.2504 17.5151 12.9556C17.5151 11.5999 17.9912 10.6021 18.9435 9.96237C19.9035 9.31496 21.3472 8.95699 23.2748 8.88844L25.5096 8.81989V8.26008C25.5096 6.96528 24.8415 6.31788 23.5052 6.31788C22.4761 6.31788 21.2666 6.62634 19.8766 7.24328L18.7131 4.88978C20.1953 4.12052 21.8387 3.73589 23.6435 3.73589C25.3714 3.73589 26.6961 4.1091 27.6177 4.85551C28.5392 5.60193 29 6.73678 29 8.26008V16.7715H26.5464ZM25.5096 10.8535L24.1503 10.8992C23.1289 10.9297 22.3686 11.1125 21.8695 11.4476C21.3703 11.7827 21.1207 12.293 21.1207 12.9785C21.1207 13.961 21.689 14.4523 22.8256 14.4523C23.6396 14.4523 24.2885 14.22 24.7724 13.7554C25.2639 13.2908 25.5096 12.6738 25.5096 11.9046V10.8535Z"
        fill="white"
      />
    </svg>
  );
}
