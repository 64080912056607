import React from 'react'
import * as Styled from './filter-teachers.styles'
import { CancelButtonIcon } from '@components/Icons'
import infoIcon from '@assets/icons/infoCircle.svg'
import { useTranslation } from 'react-i18next'

interface ICourseHintModal {
  handleClose: () => void
}

const CourseHintModal = ({ handleClose }: ICourseHintModal) => {
  const { t } = useTranslation()

  return (
    <Styled.CourseHintWrapper>
      <Styled.CourseHintHeader>
        <Styled.CourseHintInfoImg src={infoIcon} alt="closeBtn" />
        <Styled.CourseHintInfo>{t('TrainingForBeginners')}</Styled.CourseHintInfo>
        <Styled.CloseBtn onClick={handleClose}>
          <CancelButtonIcon />
        </Styled.CloseBtn>
      </Styled.CourseHintHeader>
      <Styled.Content>
        <Styled.Item>
          <Styled.ItemTitle>{t('SpeakingTraining')}</Styled.ItemTitle>
          <Styled.ItemParagraph>{t('SpeakingTraining.Desc1')}</Styled.ItemParagraph>
          <Styled.ItemParagraph>{t('SpeakingTraining.Desc2')}</Styled.ItemParagraph>
          <Styled.ItemParagraph>{t('SpeakingTraining.Desc3')}</Styled.ItemParagraph>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemTitle>{t('InteractiveCourse')}</Styled.ItemTitle>
          <Styled.ItemParagraph>{t('CourseA1.Desc')}</Styled.ItemParagraph>
        </Styled.Item>
        <Styled.HelpText>{t('HelpViaTutor')} &#128578;</Styled.HelpText>
      </Styled.Content>
    </Styled.CourseHintWrapper>
  )
}

export default CourseHintModal
