import React, { useState } from 'react'
import * as Styled from './filter-teachers.styles'
import CustomMaterialRadioButton from '@components/Checkbox/CustomMaterialRadioButton'
import { RadioGroup } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import IconInfo from '@components/Student/Icons/IconInfo'
import MainModal from '@components/Modal'
import CourseHintModal from './CourseHintModal'

const Step1Component = ({
  withWho,
  onChange,
}: {
  withWho: string
  onChange: (data: { name: any; value: string }) => void
}) => {
  const [isShowHint, setIsShowHint] = useState(false)

  const handleClose = () => {
    setIsShowHint(false)
  }

  const { t } = useTranslation()
  return (
    <>
      <Styled.Title>{t('AutoTeacher.Step1.Title')}</Styled.Title>
      <Styled.Desc>{t('AutoTeacher.Step1.Desc')}</Styled.Desc>
      <Styled.Desc>
        {t('AutoTeacher.Step1.Newbee')}
        <Styled.NewbeeButton
          onClick={() => {
            setIsShowHint(true)
          }}
        >
          {t('GetMoreDetails')}
          <IconInfo />
        </Styled.NewbeeButton>
      </Styled.Desc>
      <Styled.SecondTitle>{t('AutoTeacher.Step1.Title2')}</Styled.SecondTitle>

      <RadioGroup
        aria-label="withWho"
        name="withWho"
        value={withWho}
        onChange={e => onChange({ name: 'withWho', value: e.target.value })}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Styled.RadionButtonItem>
          <CustomMaterialRadioButton label={t('AutoTeacher.Step1.OptionTitle')} name={'one'} />
          <Styled.Ul>
            <li>{t('AutoTeacher.Step1.Item1')}</li>
            <li>{t('AutoTeacher.Step1.Item2')} </li>
            <li>{t('AutoTeacher.Step1.Item3')} </li>
          </Styled.Ul>
        </Styled.RadionButtonItem>

        <Styled.RadionButtonItem>
          <CustomMaterialRadioButton
            label={t('AutoTeacher.Step1.OptionTitle2')}
            name={'multiple'}
          />
          <Styled.Ul>
            <li>{t('AutoTeacher.Step1.Item4')}</li>
            <li>{t('AutoTeacher.Step1.Item5')}</li>
            <li> {t('AutoTeacher.Step1.Item6')} </li>
            <li> {t('AutoTeacher.Step1.Item7')} </li>
            <li> {t('AutoTeacher.Step1.Item8')}</li>
          </Styled.Ul>
        </Styled.RadionButtonItem>
      </RadioGroup>
      <MainModal
        open={isShowHint}
        children={() => <CourseHintModal handleClose={handleClose} />}
        handleClose={() => {
          setIsShowHint(false)
        }}
      />
    </>
  )
}

export default Step1Component
