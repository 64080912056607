import React, { useState } from 'react'
import classNames from 'classnames'
import './formInput.sass'
import PhoneInput from 'react-phone-input-2'

function FormInput(props) {
  const {
    name,
    labelClass,
    tipClass,
    inputClass,
    tip,
    type,
    error,
    errorTip,
    labelStyle,
    inputDataFieldStyle,
    onChange,
    ...rest
  } = props
  const isPasswordInput = type === 'password'
  let [passwordIsVisible, toggleVisiblePassword] = useState(false)
  let inputType = passwordIsVisible ? 'text' : type

  const classNamesConfig = {
    label: classNames('input-data', {
      [labelClass]: labelClass,
    }),
    tip: classNames('input-data__tip', {
      [tipClass]: tipClass,
    }),
    input: classNames('input-data__input', {
      'input-data__input--password': isPasswordInput,
      [inputClass]: inputClass,
      'input-data__input--filled': Boolean(rest.value.length) && !error,
      'input-data__input--error': error,
    }),
    action: classNames('input-data__action', {
      'input-data__action--visible': !passwordIsVisible,
      'input-data__action--hidden': passwordIsVisible,
    }),
  }

  return (
    <label className={classNamesConfig.label} style={labelStyle}>
      {tip && <span className={classNamesConfig.tip}>{tip}</span>}

      <span className="input-data__field" style={inputDataFieldStyle}>
        {name === 'phone' ? (
          <PhoneInput
            className={classNamesConfig.input}
            type={inputType}
            name={name}
            {...rest}
            country={'ru'}
            onChange={value => {
              onChange({ target: { value } })
            }}
            enableLongNumbers
            specialLabel={''}
            inputStyle={{
              border: 'none',
            }}
          />
        ) : (
          <input
            className={classNamesConfig.input}
            autoComplete={isPasswordInput ? 'new-password' : undefined}
            type={inputType}
            {...rest}
            name={name}
            onChange={onChange}
          />
        )}

        {isPasswordInput && rest.value.length > 0 && (
          <button
            type="button"
            className={classNamesConfig.action}
            onClick={() => toggleVisiblePassword(!passwordIsVisible)}
          />
        )}

        <p className="input-data__err-tip">{errorTip}</p>
      </span>
    </label>
  )
}

FormInput.defaultProps = {
  type: 'text',
  error: false,
  errorTip: '',
  value: '',
}

export default FormInput
