import styled from 'styled-components'

export const Package = styled.div``

export const PackageTitle = styled.p`
  color: rgba(65, 71, 82, 1);
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Info = styled.img`
  margin-left: 0.4vw;
`

export const Desc = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
`

export const CoursesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
