import React from 'react'
import RichEditorExample from '@components/TextEditor'

const EditorBlock = ({
  onStateChange,
  id,
  preloadedState,
  onClickDelete,
  onClickCopy,
  dragRef,
  preview,
  opacity,
}) => {
  return (
    <div style={{ opacity }} ref={preview}>
      <RichEditorExample
        dragRef={dragRef}
        onClickCopy={onClickCopy}
        onClickDelete={onClickDelete}
        onStateChange={onStateChange}
        id={id}
        preloadedState={preloadedState}
      />
    </div>
  )
}

export default EditorBlock
