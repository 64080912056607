import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import Button from '@components/common/Button'
import Image from '@assets/images/blocks/Image/Image.svg'
import Cross from '@assets/images/blocks/cross.svg'
import Resize from '@assets/images/resize.svg'
import Eye from '@assets/images/blocks/eye.svg'
import EyeOff from '@assets/images/blocks/eye-off.svg'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { Tooltip, withStyles } from '@material-ui/core'
import { storageRef } from '@configs/firebase'
import { useRouteMatch } from 'react-router'
import { getCachedImage } from '@utils/uncachedImage'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(197, 207, 221, 1)',
    color: '#414752',
    fontSize: 10,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
    fontWeight: 'bold',
  },
}))(Tooltip)

const ImageBlock = ({ onStateChange, id, preloadedState }) => {
  const ref = useRef(null)
  const imageRef = useRef(null)
  const resizerRef = useRef(null)
  const initialState = {
    isVisDesc: false,
    isForStdnt: true,
    description: '',
    image: null,
    height: null,
    originalHeight: null,
    width: null,
  }

  const { courseId } = useRouteMatch().params
  const [imageUrl, setImageUrl] = useState('')
  const [, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item) {
      ;(async () => {
        let uploadTask = null
        if (item.files.length) {
          const file = item.files[0]
          const fileName = (Math.random() + 2).toString(36).substring(2) + '-' + file.name
          uploadTask = storageRef.ref(`COURSES/${courseId}/images/${fileName}`).put(file)
          uploadTask.on(
            'state_changed',
            snapshot => {},
            error => {},
            () => {
              storageRef
                .ref('COURSES')
                .child(`${courseId}`)
                .child('images')
                .child(fileName)
                .getDownloadURL()
                .then(image => {
                  changeState({
                    image: image,
                  })
                })
            }
          )
        }
      })()
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const state = preloadedState || initialState
  const [loaded, setLoaded] = useState(false)

  const changeState = state => {
    onStateChange(id, {
      ...preloadedState,
      ...state,
    })
  }

  const handleDescription = e => {
    e.stopPropagation()
    changeState({
      isVisDesc: true,
    })
  }

  const handleCloseDescription = e => {
    e.stopPropagation()
    changeState({
      isVisDesc: false,
    })
  }

  const handleShowForStudent = e => {
    e.stopPropagation()
    changeState({
      isForStdnt: !state.isForStdnt,
    })
  }

  const handleChangeDescription = e => {
    e.stopPropagation()
    changeState({
      description: e.target.value,
    })
  }

  useLayoutEffect(() => {
    if (imageRef.current && loaded) {
      changeState({
        height: imageRef.current.getBoundingClientRect().height,
        width: imageRef.current.getBoundingClientRect().width,
        originalHeight: state.originalHeight || imageRef.current.getBoundingClientRect().height,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef.current, loaded])

  const handleChooseFile = e => {
    let uploadTask = null
    if (e.target.files.length) {
      const file = e.target.files[0]
      const fileName = (Math.random() + 2).toString(36).substring(2) + '-' + file.name
      uploadTask = storageRef.ref(`COURSES/${courseId}/images/${fileName}`).put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {},
        () => {
          storageRef
            .ref('COURSES')
            .child(`${courseId}`)
            .child('images')
            .child(fileName)
            .getDownloadURL()
            .then(image => {
              changeState({
                image: image,
              })
            })
        }
      )
    }
  }

  let startY, startHeight

  const initDrag = e => {
    if (resizerRef.current) {
      startY = e.clientY
      startHeight = parseInt(document.defaultView.getComputedStyle(imageRef.current).height, 10)
      document.documentElement.addEventListener('mousemove', doDrag, false)
      document.documentElement.addEventListener('mouseup', stopDrag, false)
    }
  }

  function doDrag(e) {
    if (imageRef.current) {
      if (
        startHeight + e.clientY - startY >= 100 &&
        state.originalHeight >= startHeight + e.clientY - startY
      ) {
        changeState({
          height: startHeight + e.clientY - startY,
          width: imageRef.current.getBoundingClientRect().width,
        })
        imageRef.current.style.height = startHeight + e.clientY - startY + 'px'
      }
    }
  }

  function stopDrag() {
    document.documentElement.removeEventListener('mousemove', doDrag, false)
    document.documentElement.removeEventListener('mouseup', stopDrag, false)
  }

  useEffect(() => {
    if (state.image) {
      getCachedImage(state.image).then(url => setImageUrl(url))
    }
  }, [state.image])

  return (
    <div
      ref={drop}
      className="aaa"
      onClick={() => {
        !state.image && ref.current.click()
      }}
    >
      {!state.image ? (
        <>
          <img src={Image} className="block-icon" alt="" />
          <span className="block-title">Добавить изображение</span>
          <span className="block-description">
            Кликните на выделенную область или перетащите файл
          </span>
          {state.isVisDesc ? (
            <div className="block-description-area-container">
              <textarea
                className="block-description-area"
                placeholder="Описание изображения"
                onChange={handleChangeDescription}
                onClick={e => {
                  e.stopPropagation()
                }}
                value={state.description}
              />
              <div className="block-actions-area">
                <img
                  onClick={handleCloseDescription}
                  src={Cross}
                  alt=""
                  style={{ marginBottom: 22 }}
                />
                {state.isForStdnt ? (
                  <HtmlTooltip title="Текст виден ученику" placement="left">
                    <img onClick={handleShowForStudent} src={Eye} alt="" />
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip title="Текст не виден ученику" placement="left">
                    <img onClick={handleShowForStudent} src={EyeOff} alt="" />
                  </HtmlTooltip>
                )}
              </div>
            </div>
          ) : null}
          <input
            style={{ display: 'none' }}
            ref={ref}
            onChange={handleChooseFile}
            type="file"
            accept="image/*"
          />
        </>
      ) : (
        <>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              width: '100%',
              padding: '30px',
            }}
          >
            <div>
              <img
                ref={imageRef}
                onLoad={() => setLoaded(true)}
                style={{
                  height: state.height,
                  borderRadius: 16,
                  maxWidth: '100%',
                }}
                src={imageUrl}
                alt=""
              />
              <img
                onDragStart={e => e.preventDefault()}
                style={{
                  position: 'absolute',
                  bottom: 10,
                  left: 'calc(50% - 20px)',
                }}
                src={Resize}
                alt=""
                onMouseDown={initDrag}
                ref={resizerRef}
              />
              <img
                onDragStart={e => e.preventDefault()}
                style={{
                  position: 'absolute',
                  top: 10,
                  left: 'calc(50% - 20px)',
                  visibility: 'hidden',
                }}
                src={Resize}
                alt=""
              />
            </div>
            <img
              onClick={() => {
                changeState({
                  image: null,
                  originalHeight: null,
                  height: null,
                })
                setLoaded(false)
              }}
              src={Cross}
              alt=""
              style={{ marginLeft: 10 }}
            />
          </div>
        </>
      )}
      {!state.isVisDesc ? (
        <Button className="block-action" onClick={handleDescription}>
          Добавить описание
        </Button>
      ) : null}
      {state.isVisDesc && state.image ? (
        <div className="block-description-area-container">
          <textarea
            className="block-description-area"
            placeholder="Описание изображения"
            onChange={handleChangeDescription}
            value={state.description}
          />
          <div className="block-actions-area">
            <img onClick={handleCloseDescription} src={Cross} alt="" style={{ marginBottom: 22 }} />
            {state.isForStdnt ? (
              <HtmlTooltip title="Текст виден ученику" placement="left">
                <img onClick={handleShowForStudent} src={Eye} alt="" />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip title="Текст не виден ученику" placement="left">
                <img onClick={handleShowForStudent} src={EyeOff} alt="" />
              </HtmlTooltip>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ImageBlock
