import React from 'react'

export default function CopyIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10.8965C10.4477 10.8965 10 11.3442 10 11.8965V20.8965C10 21.4488 10.4477 21.8965 11 21.8965H20C20.5523 21.8965 21 21.4488 21 20.8965V11.8965C21 11.3442 20.5523 10.8965 20 10.8965H11ZM8 11.8965C8 10.2396 9.34315 8.89648 11 8.89648H20C21.6569 8.89648 23 10.2396 23 11.8965V20.8965C23 22.5533 21.6569 23.8965 20 23.8965H11C9.34315 23.8965 8 22.5533 8 20.8965V11.8965Z"
        fill="#C2CFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3.89648C3.73478 3.89648 3.48043 4.00184 3.29289 4.18938C3.10536 4.37691 3 4.63127 3 4.89648V13.8965C3 14.1617 3.10536 14.4161 3.29289 14.6036C3.48043 14.7911 3.73478 14.8965 4 14.8965H5C5.55228 14.8965 6 15.3442 6 15.8965C6 16.4488 5.55228 16.8965 5 16.8965H4C3.20435 16.8965 2.44129 16.5804 1.87868 16.0178C1.31607 15.4552 1 14.6921 1 13.8965V4.89648C1 4.10084 1.31607 3.33777 1.87868 2.77516C2.44129 2.21255 3.20435 1.89648 4 1.89648H13C13.7956 1.89648 14.5587 2.21255 15.1213 2.77516C15.6839 3.33777 16 4.10083 16 4.89648V5.89648C16 6.44877 15.5523 6.89648 15 6.89648C14.4477 6.89648 14 6.44877 14 5.89648V4.89648C14 4.63127 13.8946 4.37691 13.7071 4.18938C13.5196 4.00184 13.2652 3.89648 13 3.89648H4Z"
        fill="#C2CFE0"
      />
    </svg>
  )
}
