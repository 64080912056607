import { APP_CONFIG } from '@utils/constants'
import escuelaData from './data/escuela'
import portlangData from './data/portlang'

export const getTranslationResources = () => {
  const en = {}
  const es = {}
  const ru = {}
  const translationData = APP_CONFIG.lang === 'pt' ? portlangData : escuelaData
  translationData.forEach(item => {
    ru[item.KEY] = item.Ru
    es[item.KEY] = item.Es
    en[item.KEY] = item.Eng
  })

  return {
    ru: { translation: ru },
    en: { translation: en },
    es: { translation: es },
  }
}
