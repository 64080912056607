import {
  WordsActionsTypes,
  IWordsState,
  ADD_NEW_WORD,
  SET_WORDS,
  DELETE_WORD,
  IS_LIST_LOADED,
} from './types'

const initialState: IWordsState = {
  words: [],
  isListLoaded: true,
  isLoading: true,
}

export const wordsReducer = (state = initialState, action: WordsActionsTypes): IWordsState => {
  switch (action.type) {
    case ADD_NEW_WORD:
      return { ...state, words: [action.payload, ...state.words] }
    case SET_WORDS:
      return { ...state, words: action.payload, isLoading: false }
    case DELETE_WORD:
      return {
        ...state,
        words: state.words.filter(w => w.uid !== action.payload),
      }
    case IS_LIST_LOADED:
      return {
        ...state,
        isListLoaded: false,
      }
    default:
      return state
  }
}
