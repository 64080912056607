import React from "react";

export default function EmojiIcon(props) {
  return (
    <svg
      {...props}
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0C7.611 0 0 7.611 0 17H0.029V34C0.029 35.1 0.929 36 2.029 36H32C33.1 36 34 35.1 34 34V17C34 7.611 26.389 0 17 0Z"
        fill="#AAB8C2"
      />
      <path
        d="M17 31C24.732 31 31 24.732 31 17C31 9.26801 24.732 3 17 3C9.26801 3 3 9.26801 3 17C3 24.732 9.26801 31 17 31Z"
        fill="white"
      />
      <path
        d="M17 4C24.18 4 30 9.82 30 17C30 24.18 24.18 30 17 30C9.82 30 4 24.18 4 17C4 9.82 9.82 4 17 4ZM17 2C8.729 2 2 8.729 2 17C2 25.271 8.729 32 17 32C25.271 32 32 25.271 32 17C32 8.729 25.271 2 17 2Z"
        fill="#66757F"
      />
      <path
        d="M18 6C18 5.448 17.552 5 17 5C16.448 5 16 5.448 16 6V7C16 7.552 16.448 8 17 8C17.552 8 18 7.552 18 7V6ZM18 27C18 26.448 17.552 26 17 26C16.448 26 16 26.448 16 27V28C16 28.552 16.448 29 17 29C17.552 29 18 28.552 18 28V27ZM7 16H6C5.448 16 5 16.448 5 17C5 17.552 5.448 18 6 18H7C7.552 18 8 17.552 8 17C8 16.448 7.552 16 7 16ZM28 16H27C26.448 16 26 16.448 26 17C26 17.552 26.448 18 27 18H28C28.552 18 29 17.552 29 17C29 16.448 28.552 16 28 16ZM25.707 24.293L25 23.586C24.609 23.195 23.976 23.195 23.586 23.586C23.195 23.977 23.195 24.61 23.586 25L24.293 25.707C24.684 26.098 25.317 26.098 25.707 25.707C26.098 25.317 26.098 24.683 25.707 24.293ZM9 23.586L8.293 24.293C7.902 24.684 7.902 25.317 8.293 25.707C8.684 26.098 9.317 26.098 9.707 25.707L10.414 25C10.805 24.609 10.805 23.976 10.414 23.586C10.024 23.195 9.391 23.195 9 23.586ZM8.293 9.707L9 10.414C9.391 10.805 10.024 10.805 10.414 10.414C10.804 10.023 10.805 9.39 10.414 9L9.707 8.293C9.316 7.902 8.683 7.902 8.293 8.293C7.903 8.684 7.902 9.317 8.293 9.707ZM25 10.414L25.707 9.707C26.098 9.316 26.098 8.683 25.707 8.293C25.316 7.902 24.683 7.902 24.293 8.293L23.586 9C23.195 9.391 23.195 10.024 23.586 10.414C23.976 10.805 24.609 10.805 25 10.414Z"
        fill="#292F33"
      />
      <path
        d="M7.91503 13.8393C7.82003 13.6013 7.81603 13.3273 7.92603 13.0743C8.14403 12.5673 8.73203 12.3333 9.24003 12.5513L17.394 16.0613C17.901 16.2793 18.135 16.8673 17.917 17.3753C17.699 17.8823 17.111 18.1163 16.603 17.8983L8.44903 14.3883C8.19503 14.2783 8.01003 14.0773 7.91503 13.8393Z"
        fill="#DD2E44"
      />
    </svg>
  );
}
