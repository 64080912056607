import React from 'react'

export default function LibraryMenuIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M21 16H9V19H21V16Z" fill="white" />
        <path d="M15 20.5H9V23.5H15V20.5Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6C5.60217 6 5.22064 6.15804 4.93934 6.43934C4.65804 6.72064 4.5 7.10217 4.5 7.5V28.5C4.5 28.8978 4.65804 29.2794 4.93934 29.5607C5.22064 29.842 5.60218 30 6 30H30C30.3978 30 30.7794 29.842 31.0607 29.5607C31.342 29.2794 31.5 28.8978 31.5 28.5V12C31.5 11.6022 31.342 11.2206 31.0607 10.9393C30.7794 10.658 30.3978 10.5 30 10.5H16.5C15.9985 10.5 15.5301 10.2493 15.2519 9.83205L12.6972 6H6ZM2.81802 4.31802C3.66193 3.47411 4.80653 3 6 3H13.5C14.0015 3 14.4699 3.25065 14.7481 3.66795L17.3028 7.5H30C31.1935 7.5 32.3381 7.97411 33.182 8.81802C34.0259 9.66193 34.5 10.8065 34.5 12V28.5C34.5 29.6935 34.0259 30.8381 33.182 31.682C32.3381 32.5259 31.1935 33 30 33H6C4.80653 33 3.66193 32.5259 2.81802 31.682C1.97411 30.8381 1.5 29.6935 1.5 28.5V7.5C1.5 6.30653 1.97411 5.16193 2.81802 4.31802Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
