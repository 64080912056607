import styled from 'styled-components'

export const RoleSwitchContainer = styled.div`
  width: fit-content;

  position: relative;
`

export const ChosenOption = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 11px 20px;
  cursor: pointer;
  display: flex;
  > img {
    margin-left: 30px;
  }
`

export const Options = styled.div`
  background: #ffffff;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 199px;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
`

export const OptionItem = styled.div`
  cursor: pointer;
  &:hover {
    color: #ff852d;
    font-weight: bold;
  }
`
