class FabricCanvasTool {
  constructor(canvas) {
    this._canvas = canvas;
  }

  configureCanvas(props) {}

  doMouseUp(event) {}

  doMouseDown(event) {}

  doMouseMove(event) {}

  doMouseOut(event) {}
}

export default FabricCanvasTool;
