import React from 'react';

const AlignLeftIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3346 5.83341V4.16675H1.66797V5.83341H18.3346ZM13.3346 7.50008V9.16675H1.66797V7.50008H13.3346ZM18.3346 12.5001V10.8334H1.66797V12.5001H18.3346ZM13.3346 15.8334V14.1667H1.66797V15.8334H13.3346Z"
      fill="currentColor"
    />
  </svg>
);

export { AlignLeftIcon };
