import React from 'react'

export function EyeOffIcon(props) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M10 17.5C3.75 17.5 0.25 10.6667 0.0833333 10.3333C-1.24176e-08 10.0833 -1.24176e-08 9.83333 0.0833333 9.58333C1.16667 7.58333 2.66667 5.83333 4.58333 4.41667C4.91667 4.08333 5.41667 4.16667 5.75 4.58333C6 4.91667 6 5.5 5.58333 5.75C4 6.91667 2.75 8.33333 1.75 10C2.5 11.3333 5.5 15.8333 10 15.8333C11.5833 15.8333 13.1667 15.25 14.4167 14.25C14.75 14 15.3333 14.0833 15.5833 14.4167C15.8333 14.75 15.75 15.3333 15.4167 15.5833C13.9167 16.8333 12 17.5 10 17.5ZM17.3333 13.5C17.1667 13.5 16.9167 13.4167 16.8333 13.3333C16.5 13 16.4167 12.5 16.75 12.1667C17.3333 11.5 17.8333 10.8333 18.25 10.0833C17.5 8.75 14.5 4.25 10 4.25C9.5 4.25 8.91667 4.33333 8.41667 4.41667C8 4.41667 7.58333 4.16667 7.41667 3.75C7.25 3.33333 7.58333 2.83333 8 2.75C8.66667 2.58333 9.33333 2.5 10 2.5C16.25 2.5 19.75 9.33333 19.9167 9.66667C20 9.91667 20 10.1667 19.9167 10.4167C19.4167 11.4167 18.75 12.3333 18 13.25C17.8333 13.4167 17.5833 13.5 17.3333 13.5ZM9.91667 13.4167C9.08333 13.4167 8.25 13.0833 7.66667 12.5C7 11.9167 6.66667 11.0833 6.58333 10.1667C6.58333 9.25 6.83333 8.41667 7.5 7.75C7.58333 7.66667 7.58333 7.66667 7.66667 7.58333C8 7.25 8.5 7.25 8.83333 7.58333C9.16667 7.91667 9.16667 8.41667 8.83333 8.75L8.75 8.83333C8.41667 9.16667 8.25 9.58333 8.33333 10C8.33333 10.4167 8.5 10.8333 8.83333 11.1667C9.5 11.8333 10.5833 11.75 11.1667 11.0833C11.5 10.75 12 10.75 12.3333 11.0833C12.6667 11.4167 12.6667 11.9167 12.3333 12.25C11.75 13 10.8333 13.4167 9.91667 13.4167Z" />
        <path d="M19.1667 20C18.9167 20 18.75 19.9167 18.5833 19.75L0.25 1.41667C-0.0833333 1.08333 -0.0833333 0.583333 0.25 0.25C0.583333 -0.0833333 1.08333 -0.0833333 1.41667 0.25L19.75 18.5833C20.0833 18.9167 20.0833 19.4167 19.75 19.75C19.5833 19.9167 19.4167 20 19.1667 20Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const HideIcon = () => (
  <EyeOffIcon
    style={{
      fill: 'rgba(244, 62, 62, 0.2',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '125px',
      height: '121px',
    }}
  />
)
