import React, { useState, useEffect, useCallback } from 'react'
import Spinner from 'react-spinkit'
import Center from '@assets/images/blocks/Link/centered-icon.svg'
import Left from '@assets/images/blocks/Link/left-icon.svg'
import LeftActive from '@assets/images/blocks/Link/left-icon-active.svg'
import LoaderChecked from '../../assets/icons/checked.svg'

const LinkBlock = ({ onStateChange, id, preloadedState, actionsRef }) => {
  const initialState = {
    align: 'center',
    link: '',
    name: '',
  }

  const [isLoading, setLoading] = useState(false)
  const [showCheckmark, setShowCheckmark] = useState(false)

  let state = preloadedState || initialState
  const changeState = state => {
    onStateChange(id, {
      ...preloadedState,
      ...state,
    })
    document.querySelector(`.link-center-icon-${id}`).removeEventListener('click', handleCenter)
    document.querySelector(`.link-left-icon-${id}`).removeEventListener('click', handleLeft)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setShowCheckmark(true)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 2000)
    }, 3000)
  }
  const handleCenter = useCallback(() => {
    changeState({
      align: 'center',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLeft = useCallback(() => {
    changeState({
      align: 'left',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const center = document.createElement('div')
    center.innerHTML = `
<img class="link-left-icon-${id}" style="margin-right: 20px" src=${
      state.align === 'left' ? LeftActive : Left
    } />
<img class="link-center-icon-${id}" src=${state.align === 'center' ? Center : Center} />
`
    center.style.marginRight = '60px'
    if (!actionsRef.current.children.length) {
      actionsRef.current.append(center)
    }
    document.querySelector(`.link-left-icon-${id}`).addEventListener('click', handleLeft)
    document.querySelector(`.link-center-icon-${id}`).addEventListener('click', handleCenter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsRef.current, preloadedState])

  return (
    <div className="aaa">
      <textarea
        className="block-description-area-link"
        placeholder="Вставьте ссылку"
        onChange={e => {
          changeState({
            link: e.target.value,
          })
        }}
        style={{
          textDecoration: state.link ? 'underline' : 'none',
        }}
        value={state.link}
      />
      <textarea
        className="block-description-area-link"
        placeholder="Название ссылки"
        onChange={e => {
          changeState({
            name: e.target.value,
          })
        }}
        style={{
          textDecoration: state.name ? 'underline' : 'none',
          textAlign: state.align,
        }}
        value={state.name}
      />
      <div className="containerImg">
        {isLoading && (
          <Spinner
            name="line-spin-fade-loader"
            color="#FF852D"
            style={{
              scale: '0.4',
              top: '10px',
              marginLeft: 'auto',
            }}
          />
        )}
        {showCheckmark && <img className="checkmark" src={LoaderChecked} alt="" />}
      </div>
    </div>
  )
}

export default LinkBlock
