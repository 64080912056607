import React from 'react'
import * as Styled from './contacts.styles'

function Contacts() {
  return (
    <Styled.ContactsMain>
      <Styled.ContactsTitle>Наши контакты:</Styled.ContactsTitle>
      <Styled.ContactItem contact>Email: сontact@escuela.pro</Styled.ContactItem>
      <Styled.ContactItem contact style={{ marginBottom: 30 }}>
        tel:+7 771 269 47 83
      </Styled.ContactItem>
      <Styled.ContactItem>© "Escuela" 2024.</Styled.ContactItem>
      {/* <Styled.ContactItem>ИП Дрик Юлия Сергеевна</Styled.ContactItem>
      <Styled.ContactItem>ОГРНИП 320246800113251</Styled.ContactItem>
      <Styled.ContactItem>117519, 77-г.Москва, ш. Варшавское, 133</Styled.ContactItem>
      <Styled.ContactItem>ИНН 241105039061</Styled.ContactItem> */}

      <Styled.ContactItem>ТОО «Языковые технологии»</Styled.ContactItem>
      <Styled.ContactItem>БИН 231040018745</Styled.ContactItem>
      <Styled.ContactItem>
        Казахстан, город Алматы, Алмалинский район, улица Ауэзова, здание 14а, почтовый индекс
        050009
      </Styled.ContactItem>
      <Styled.ContactItem>Все права защищены.</Styled.ContactItem>
    </Styled.ContactsMain>
  )
}

export default Contacts
