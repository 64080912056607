import FabricCanvasTool from "./fabrictool";

class DefaultTool extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;

    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    canvas.discardActiveObject();
    canvas.defaultCursor = "pointer";
    canvas.renderAll();
  }
}

export default DefaultTool;
