import { IFactoryState } from '@models/IFactory'
import {
  ADD_EXERCISE,
  DELETE_EXERCISE,
  FactoryActionsTypes,
  SET_EXERCISES,
  UPDATE_EXERCISE,
} from './types'

const initialState: IFactoryState = {
  exercises: [],
}

export const factoryReducer = (
  state: IFactoryState = initialState,
  action: FactoryActionsTypes
) => {
  switch (action.type) {
    case SET_EXERCISES:
      return { ...state, exercises: action.payload }
    case ADD_EXERCISE:
      return { ...state, exercises: [...state.exercises, action.payload] }
    case UPDATE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.map(exercise =>
          exercise.id === action.payload.id
            ? { ...exercise, ...action.payload }
            : exercise
        ),
      }
    case DELETE_EXERCISE:
      return {
        ...state,
        exercises: state.exercises.filter(
          exercise => exercise.id !== action.payload.exerciseId
        ),
      }
    default:
      return state
  }
}
