import React, { useEffect, useState, ReactElement } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { NAVIGATOR, ROLES } from '@utils/constants'

import { ellipsify } from '@utils/helpers'

import { IProfile, IStudentProfile } from '@models/IProfile'

import { RootState } from '@store'
import { fetchStudentPayments, fetchStudentProfileByUid } from '@servise/profileAPI'

import ArrowLeftIcon from '@components/Icons/arrowLeft.svg'

import * as Styled from './dashboard.styles'

import StudentCard from '@components/Student/StudentCard'
import MainModal from '@components/Modal'
import { setStudent, setStudentDataInClassroomAction } from '@store/classroom/actions'
import Chip from '@components/Chip'
import sync from '@assets/icons/refresh.svg'

import 'moment/locale/ru'
import { getCalendarEvents } from '@servise/calendarAPI'
import { useTranslation } from 'react-i18next'
import { getTeachersAccounting } from '@servise/personalAPI'
import { SetIsLoading } from '@store/personal/actions'
import { calendarUtil } from '@utils/calendarUtil'

export default ({ open }) => {
  const { push } = useHistory()
  const teacherCoursePage = useRouteMatch<{ courseId: string }>(
    `${NAVIGATOR.teacher_courses.path}/:courseId`
  )
  const teacherProfilePage = useRouteMatch<{ userId: string }>(
    `${NAVIGATOR.manager_search_teacher.path}/:userId`
  )
  const userProfilePage = useRouteMatch<{ userId: string }>(
    `${NAVIGATOR.manager_search_user.path}/:userId`
  )
  let location = useLocation()
  const states = useSelector((state: RootState) => ({
    profileData: state.profile.profile,
    studentIsOnline: state.sync.userState.studentIsOnline,
    events: state.calendar.events,
    studentData: state.classroom.studentData,
    students: state.classroom.students,
    studentUid: state.classroom.classroomData.student_uid,
    className: state.classroom.classroomData.name,
  }))

  const dispatch = useDispatch()
  const [nextEvent, setNextEvent] = useState('')
  const [balanceCount, setBalanceCount] = useState('')
  const [openStudentCard, setOpenStudentCard] = useState(false)
  const [comments, setComments] = useState('')
  const [studentData, setStudentData] = useState(null)
  const { t } = useTranslation()
  const [activeLink, setActiveLink] = useState<string>('')

  moment.locale('ru')
  const getCurrentSectionName = (): {
    currentName: string | ReactElement
    inClass: boolean
  } => {
    if (teacherCoursePage) {
      return {
        currentName: (
          <Styled.GoBackContainer
            onClick={() => push(`${NAVIGATOR.teacher_classes.path}?allCourses`)}
          >
            <img src={ArrowLeftIcon} alt="Вернуться в курсы" />
            <Styled.GoBackTitle>Вернуться в курсы</Styled.GoBackTitle>
          </Styled.GoBackContainer>
        ),
        inClass: false,
      }
    }

    if (teacherProfilePage) {
      return {
        currentName: (
          <Styled.GoBackContainer onClick={() => push(`${NAVIGATOR.manager_search_teacher.path}`)}>
            <img src={ArrowLeftIcon} alt="Вернуться к поиску учителя" />
            <Styled.GoBackTitle>Вернуться к поиску учителя</Styled.GoBackTitle>
          </Styled.GoBackContainer>
        ),
        inClass: false,
      }
    }

    if (userProfilePage) {
      return {
        currentName: (
          <Styled.GoBackContainer onClick={() => push(`${NAVIGATOR.manager_search_user.path}`)}>
            <img src={ArrowLeftIcon} alt="Вернуться к поиску пользователей" />
            <Styled.GoBackTitle>Вернуться к поиску пользователей</Styled.GoBackTitle>
          </Styled.GoBackContainer>
        ),
        inClass: false,
      }
    }

    const currentPath = location.pathname

    for (const key in NAVIGATOR) {
      const { path, name } = NAVIGATOR[key]

      if (NAVIGATOR.hasOwnProperty(key) && path === currentPath) {
        return { currentName: name, inClass: false }
      }
    }

    if (
      NAVIGATOR.teacher_profile.path === currentPath.substring(0, 16) ||
      NAVIGATOR.manager_profile.path === currentPath.substring(0, 16)
    ) {
      return { currentName: NAVIGATOR.teacher_profile.name, inClass: false }
    }
    return { currentName: className, inClass: true }
  }
  const handleSyncBtn = () => {
    dispatch(SetIsLoading(true))
    dispatch(getTeachersAccounting())
  }
  const { studentIsOnline, className } = states
  const profileData = states.profileData as IProfile

  const { currentName, inClass } = getCurrentSectionName()

  const handleChangeStudentData = (data: IStudentProfile) => {
    setStudentData(data)
  }

  if (profileData.role === ROLES.MANAGER) {
    return (
      <Styled.Header open={open}>
        <Styled.HeaderMenuItem children={currentName} />
        {currentName === 'Бухгалтерия' && (
          <Styled.SyncBtn onClick={() => handleSyncBtn()}>
            <img src={sync} alt="SyncBtn" />
            <span>{t('UpdateCalender')}</span>
          </Styled.SyncBtn>
        )}
        <Styled.HeaderMenuContainer></Styled.HeaderMenuContainer>
      </Styled.Header>
    )
  }

  useEffect(() => {
    const nearestEvent = calendarUtil.getNearestEvent(states.events, profileData.timeZone)

    setStudentData(states.students.find(item => item.id === nearestEvent?.studentId))
    if (nearestEvent) {
      fetchStudentProfileByUid(nearestEvent.studentId).then(async student => {
        const payments = await fetchStudentPayments(nearestEvent.studentId)
        let balanceCount = 0

        for (let payment of payments) {
          balanceCount = balanceCount + payment.package
        }

        setComments(student.comments)
        setNextEvent(
          `Следующее занятие в 
            
            ${moment(nearestEvent.rrule.dtstart).format('HH:mm ddd')}
              `
        )
        if (balanceCount === 0 || balanceCount === 1) {
          setBalanceCount(`${balanceCount}`)
        } else {
          setBalanceCount('')
        }
      })
    } else {
      setNextEvent('У вас еще нет занятий :(')
    }
  }, [profileData.timeZone, states.events, inClass, states.students])

  const handleUpdateComments = () => {
    if (inClass) {
      dispatch(setStudentDataInClassroomAction(studentData))
    }
    dispatch(setStudent(studentData))
    setOpenStudentCard(false)
  }

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])

  return (
    <Styled.Header
      open={open}
      id={states.profileData.id}
      isTeacherSearchPage={activeLink.includes(NAVIGATOR.manager_search_teacher.path)}
    >
      <Styled.HeaderMenuItem children={<span>{ellipsify(currentName, 30, '...')}</span>} />
      {activeLink === NAVIGATOR.teacher_calendar.path && (
        <Styled.SyncBtn
          onClick={() => dispatch(getCalendarEvents(profileData.id, states.profileData.timeZone))}
        >
          <img src={sync} alt="SyncBtn" />
          <span>{t('UpdateCalender')}</span>
        </Styled.SyncBtn>
      )}

      <Styled.HeaderMenuContainer>
        <Styled.HeaderMenuLink
          target={'_blank'}
          children={
            <div
              className="linkItem"
              style={
                inClass
                  ? { cursor: 'pointer', display: 'flex', alignItems: 'center' }
                  : { display: 'flex', alignItems: 'center' }
              }
              onClick={() => studentData && setOpenStudentCard(true)}
            >
              {!inClass && (
                <span
                  style={{ marginRight: 10, fontSize: 16, fontWeight: 600 }}
                  className="headerSearchText"
                >
                  {nextEvent}
                </span>
              )}
              {!inClass && balanceCount && (
                <span
                  style={{ marginRight: 10, fontSize: 16, fontWeight: 600 }}
                  className="headerSearchText"
                >
                  | Balance:{' '}
                  <span style={balanceCount === '0' ? { color: '#FF922D' } : {}}>
                    {balanceCount} lesson
                  </span>
                </span>
              )}

              {studentData && studentData.email && (
                <Chip
                  chipStyle={{
                    fontWeight: 'bold',
                    fontSize: 16,
                    color: 'rgba(65, 71, 82, 0.7)',
                    maxWidth: 220,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  color={'#F3F5F9'}
                  isHeader
                  isOnline={inClass && studentIsOnline}
                  img={studentData && studentData.avatar}
                  name={studentData && `${studentData.name || ''} ${studentData.lastName || ''}`}
                  onClick={() => setOpenStudentCard(true)}
                />
              )}
            </div>
          }
        />
      </Styled.HeaderMenuContainer>

      <MainModal
        open={openStudentCard}
        children={() => (
          <StudentCard
            studentData={studentData}
            handleClose={handleUpdateComments}
            handleChange={handleChangeStudentData}
          />
        )}
        handleClose={handleUpdateComments}
        maxWidth={'1130px'}
      />
    </Styled.Header>
  )
}
