import * as React from 'react'
import { useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import { components } from 'react-select'
import * as Styled from './CustomComponents.styles'

import DefaultTeacherImg from '@images/people-default.svg'

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5.5 6L10 1" stroke="#C2CFE0" />
      </svg>
    </components.DropdownIndicator>
  )
}

export const CustomOption = props => {
  const { data, innerRef, innerProps, selectProps } = props

  const [imageSrc, setImageSrc] = useState(null)
  const { getByIndex, add } = useIndexedDB('files')

  async function getImage() {
    const fileFromDB = await getByIndex('name', data.id)
    let imageUrl

    if (fileFromDB) {
      imageUrl = URL.createObjectURL(fileFromDB.blob)
    } else {
      const res = await fetch(data.avatar)
      const fileBlob = await res.blob()
      add({ name: data.id, blob: fileBlob })

      imageUrl = URL.createObjectURL(fileBlob)
    }

    setImageSrc(imageUrl)
  }

  useEffect(() => {
    if (data.avatar) {
      getImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
    >
      <img
        src={imageSrc || DefaultTeacherImg}
        alt={data.name}
        style={{
          marginRight: '10px',
          width: 22,
          height: 22,
          borderRadius: '50%',
        }}
      />
      <span>
        {data.name || ''} {data.lastName || ''}
      </span>
      {selectProps && <span style={{ color: '#90A4BD', marginLeft: 10 }}>{data.email || ''}</span>}
    </div>
  )
}

export const CustomSelectLabel = props => {
  const [imageSrc, setImageSrc] = useState(null)

  const { getByIndex, add } = useIndexedDB('files')

  useEffect(() => {
    async function getImage() {
      const fileFromDB = await getByIndex('name', props.id)

      let imageUrl
      if (fileFromDB) {
        imageUrl = URL.createObjectURL(fileFromDB.blob)
      } else {
        const res = await fetch(props.avatar)
        const fileBlob = await res.blob()
        add({ name: props.id, blob: fileBlob })

        imageUrl = URL.createObjectURL(fileBlob)
      }

      setImageSrc(imageUrl)
    }
    if (props.avatar) {
      getImage()
    } else {
      setImageSrc(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.avatar])

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
      <div
        style={{
          background: '#F3F5F9',
          borderRadius: 40,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '3px 10px 3px 10px',
          color: '#414752',
          fontSize: 16,
        }}
      >
        <img
          onClick={() => {
            if (props.handleOpenStudentCard) {
              props.handleOpenStudentCard()
            }
          }}
          src={imageSrc || DefaultTeacherImg}
          alt={props.name}
          style={{ marginRight: '10px', width: '24px', height: '24px', borderRadius: '50%' }}
        />
        <Styled.ChosenStudent
          onClick={() => {
            if (props.handleOpenStudentCard) {
              props.handleOpenStudentCard()
            }
          }}
        >
          {props.name} {props.lastName || ''}
        </Styled.ChosenStudent>
      </div>
      <span style={{ color: '#90A4BD', marginLeft: 10 }}>{props.email || ''}</span>
    </div>
  )
}
