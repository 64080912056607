import React from 'react'
import Spinner from 'react-spinkit'
import styled, { css } from 'styled-components'

export const StyledButton = styled.button<{
  bgWhite?: boolean
  whiteTheme?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  font-size: 18px;
  background: none;
  text-decoration: inherit;
  font-size: 18px;
  font-weight: 600;

  background: linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%);
  border-radius: 25px;
  color: ${props => props.theme.colors.white};
  border-radius: 30px;
  &:not([disabled]):hover {
    color: ${props => props.theme.colors.white};
    background: #ffb800;
  }

  .spin {
    margin: 8px auto 0;
    width: 0;
    height: 0;
    transform: scale(0.5);

    div {
      color: ${props => props.theme.colors.white};
    }
  }

  ${props =>
    props.whiteTheme &&
    css`
      border: 3px solid transparent;

      background: ${!props.bgWhite
        ? 'linear-gradient(#f3f5f9, #f3f5f9) padding-box, linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%) border-box'
        : 'linear-gradient(white, white) padding-box, linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%) border-box'};
      border-radius: 25px;
      color: ${props => props.theme.colors.button};
      &:not([disabled]):hover {
        border-color: ${props => props.theme.colors.buttonHover};
        color: ${props => props.theme.colors.buttonHover};
        background: ${!props.bgWhite
          ? 'linear-gradient(#f3f5f9, #f3f5f9) padding-box, linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%) border-box'
          : 'linear-gradient(white, white) padding-box, linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%) border-box'};
      }
      .spin {
        div {
          color: ${props => props.theme.colors.button};
        }
      }
    `}

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
`

function Button(props) {
  const {
    btnClass,
    loading,
    bgWhite,
    disabled,
    whiteTheme,
    type,
    children,
    customStyles,
    actionButton,
    ...rest
  } = props
  return actionButton ? (
    <StyledButton
      {...rest}
      bgWhite={bgWhite}
      disabled={disabled}
      whiteTheme={whiteTheme}
      className={btnClass}
      type={type}
    >
      {loading ? <Spinner name="line-spin-fade-loader" className="spin" fadeIn="none" /> : children}
    </StyledButton>
  ) : (
    <button style={customStyles} {...rest}>
      {loading ? (
        <Spinner name="ball-spin-fade-loader" className="button__spin" fadeIn="none" />
      ) : (
        children
      )}
    </button>
  )
}

Button.defaultProps = {
  loading: false,
  whiteTheme: false,
  children: 'default',
  customStyles: {},
  actionButton: true,
  disabled: false,
  btnClass: '',
  type: 'button',
  bgWhite: false,
}

export default Button
