import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { HomeworkStatuses } from '@models/IHomework'

import { getChatGptSuggestion, removeCheckedHomeWork, saveHomeWork } from '@servise/homeworkAPI'

import { AppDispatch } from '@store'
import { Button, Text } from '@components'
import StudentButton from '@components/Student/Button'
import TopContent from './../TopContent'
import HomeWorkDescription from './../HomeWorkDescription'
import { StudentViewHomeWorkComment } from '../StudentViewHomeWorkComment'
import firebase from 'firebase/app'
import Check from '@components/Student/Icons/check.svg'
import { theme } from '@styles/Theme'
import * as Styled from './homework-card.styles'
import { useTranslation } from 'react-i18next'
import SpanishSymbolforDiv from '@components/SpanishSymbols/SpanishSymbolforDiv'
import { formatDateToTemplate } from '@utils/helperst'
import closeIcon from '@assets/icons/round-cancel.svg'
import { AudioRecordModal } from '@pages/teacher/TeacherProfile/AudioRecordModal'
import { MainModal } from '@components'
import AudioPlayerWaveForm from '@components/Student/TeacherCard/AudioPlayerWaveForm'
import { IconButton } from '@material-ui/core'
import { DeleteIcon } from '@components/Icons'
import { firebaseTimestamp, storageRef } from '@configs/firebase'
import CheckedAIAvater from '../../../assets/images/CheckedAIAvater.png'
import { logger } from '@utils/ConsoleLogger'
import HomeworkTextPreview from './HomeworkTextPreview'
import { fetchTeacherProfileByUid } from '@servise/index'
import TeacherCard from '@components/Student/TeacherCard'
import { Tooltip, TooltipContainer } from '@pages/student/student.styles'

interface Props {
  uid: string
  className: string
  isWriting: boolean
  isAudio: boolean
  description: string
  isNew: boolean
  status: HomeworkStatuses
  writing: string
  audioURL?: string
  teacherWriting: string
  comment: string
  teacherName: string
  teacherAvatar: string
  teacherId: string
  studentId?: string
  homeworkId?: string
  isHomeWork?: boolean
  date: firebase.firestore.Timestamp
  suggestionByAI?: string
}

const buttonStyles = {
  minWidth: '118px',
  minHeight: '40px',
  fontSize: '14px',
  lineHeight: 'inherit',
}

const doneButtonStyles = {
  ...buttonStyles,
  padding: '8px 22px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#4284FF',
}

const cancelButtonStyles = {
  ...buttonStyles,
  marginRight: '30px',
  padding: 0,
}

const getSaveButtonStyles = innerText => ({
  ...buttonStyles,
  padding: 0,
  backgroundColor: innerText ? '#4284FF' : 'rgba(66, 132, 255, .5)',
})

const checkButtonStyles = {
  ...buttonStyles,
  padding: '8px 20px',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 16,
}

const HomeWorkCard = ({
  uid,
  className,
  isWriting,
  isAudio,
  description,
  isNew,
  status,
  writing,
  audioURL,
  teacherWriting,
  comment,
  teacherAvatar,
  teacherName,
  teacherId,
  studentId,
  homeworkId,
  isHomeWork,
  date,
  suggestionByAI,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const writingInputRef = useRef<HTMLDivElement>()
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [startWriting, setStartWriting] = useState<boolean>(false)
  const [startAudio, setStartAudio] = useState<boolean>(false)
  const [newWriting, setNewWriting] = useState<string>(writing)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isRemove, setIsRemove] = useState<boolean>(false)
  const [isQuickCheck, setIsQuikCheck] = useState<boolean>(true)
  const [isAIgetting, setIsAIgetting] = useState<boolean>(false)
  const [audioRecord, setAudioRecord] = useState(null)
  const [isOpenRecord, setIsOpenRecord] = useState(false)
  const [url, setUrl] = useState(null)
  const [event, setEvent] = useState()
  const [openTeacherCard, setOpenTeacherCard] = useState(false)
  const [teacherProfile, setTeacherProfile] = useState(null)

  useEffect(() => {
    if (!writingInputRef.current || !writing) return
    writingInputRef.current.innerText = writing
  }, [writing, writingInputRef])

  useEffect(() => {
    async function getURL() {
      const audio = await storageRef
        .ref('audio')
        .child(audioURL)
        .getDownloadURL()
      setUrl(audio)
    }
    if (audioURL) {
      getURL()
    }
  }, [audioURL])

  const notFinished = useMemo(
    () =>
      writing && (status === HomeworkStatuses.NOT_READY || status === HomeworkStatuses.IN_PROGRESS),
    [writing, status]
  )

  const handleSave = useCallback(() => {
    setLoading(true)
    dispatch(
      saveHomeWork(
        {
          writing: writingInputRef.current?.innerText ?? '',
          status,
          teacherId,
          homeworkId,
        },
        uid
      )
    ).then(() => {
      setStartWriting(false)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writingInputRef, status, teacherId, uid])

  const handleCancel = useCallback(() => {
    const newText = writing || ''
    setNewWriting(newText)
    writingInputRef.current.innerText = newText
  }, [writing, writingInputRef])

  const handleFinish = useCallback(() => {
    setLoading(true)
    const writedText = writingInputRef.current?.innerText

    if (!isQuickCheck || !isWriting) {
      dispatch(
        saveHomeWork(
          {
            writing: writedText ?? '',
            status: !isWriting
              ? HomeworkStatuses.DONE.toString()
              : HomeworkStatuses.SENT.toString(),
            teacherId,
            homeworkId,
            suggestionByAI: '',
          },
          uid
        )
      )
      setLoading(false)
    } else {
      setIsAIgetting(true)
      getChatGptSuggestion(writedText)
        .then(textWithExplanation => {
          dispatch(
            saveHomeWork(
              {
                writing: writedText ?? '',
                status:
                  isWriting && textWithExplanation?.data
                    ? HomeworkStatuses.CHECKED_AI.toString()
                    : HomeworkStatuses.SENT.toString(),
                teacherId,
                homeworkId,
                suggestionByAI: textWithExplanation?.data || '',
              },
              uid
            )
          )
          setIsAIgetting(false)
          setLoading(false)
        })

        .catch(error => {
          logger.error(error)
          setIsAIgetting(false)
          setLoading(false)
        })
    }

    // })
  }, [isQuickCheck, dispatch, teacherId, homeworkId, uid, isWriting])

  const handleStartWriting = useCallback(() => {
    setStartWriting(true)
  }, [])

  const handleStartAudio = useCallback(() => {
    setStartAudio(true)
    setIsOpenRecord(true)
  }, [])

  const handleFieldFocus = useCallback(
    (value: boolean) => () => {
      setIsFocused(value)
    },
    []
  )

  const handleFieldChange = useCallback((e: ChangeEvent<HTMLDivElement>) => {
    setNewWriting(e.currentTarget.innerText)

    setEvent(document.getSelection().anchorNode.parentNode as any)
  }, [])

  const showStartButton = useMemo(
    () => status === HomeworkStatuses.NOT_READY && !writing && !startWriting,
    [status, writing, startWriting]
  )
  const showStartRecord = useMemo(() => status === HomeworkStatuses.NOT_READY && !startAudio, [
    status,
    startAudio,
  ])

  const showInputField = useMemo(() => startWriting || (notFinished && !startWriting), [
    startWriting,
    notFinished,
  ])

  const showFooter = useMemo(
    () => startWriting || notFinished || (!isWriting && status === HomeworkStatuses.NOT_READY),
    [startWriting, notFinished, isWriting, status]
  )
  const showAudioFooter = useMemo(
    () => audioRecord || (!isAudio && status === HomeworkStatuses.NOT_READY),
    [status, isAudio, audioRecord]
  )

  const showFooterDraftState = useMemo(
    () =>
      isFocused ||
      (startWriting && !writing) ||
      (writingInputRef.current && writingInputRef.current?.innerText !== writing),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, startWriting, writing, writingInputRef, newWriting]
  )

  const showTextPreview = useMemo(() => !notFinished && isWriting && writing, [
    notFinished,
    isWriting,
    writing,
  ])

  const placeholder = useMemo(
    () => (isFocused ? t('HomeWork.StartWritingHere') : writing || t('HomeWork.StartWritingHere')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, writing]
  )

  const showPencil = useMemo(
    () =>
      isWriting &&
      status !== HomeworkStatuses.SENT &&
      status !== HomeworkStatuses.CHECKED &&
      status !== HomeworkStatuses.CHECKED_AI,
    [isWriting, status]
  )

  const showSpeech = useMemo(
    () =>
      isAudio &&
      status !== HomeworkStatuses.SENT &&
      status !== HomeworkStatuses.CHECKED &&
      status !== HomeworkStatuses.CHECKED_AI,
    [isAudio, status]
  )

  const removeHomework = useCallback(
    async (isMade = false) => {
      setLoading(true)
      setIsRemove(false)
      await dispatch(removeCheckedHomeWork(studentId, teacherId, uid, false, isMade)).then(() =>
        setLoading(false)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teacherId, studentId, uid]
  )

  const handleAudioSave = async audioId => {
    await storageRef.ref('audio/' + audioId).put(audioRecord)
  }
  const handleOnAudioRecordChange = audioData => {
    if (audioData) {
      setAudioRecord(audioData)
    }
    setIsOpenRecord(false)
  }
  const handleOnAudioRecordSave = async () => {
    setLoading(true)
    const audioId = '' + firebaseTimestamp.now()
    await handleAudioSave(audioId)

    dispatch(
      saveHomeWork(
        {
          audioURL: audioId,
          status: isAudio ? HomeworkStatuses.SENT.toString() : HomeworkStatuses.DONE.toString(),
          teacherId,
          homeworkId,
        },
        uid
      )
    ).then(() => {
      setLoading(false)
    })
  }

  const memorizedAudio = useMemo(() => {
    if (audioRecord || url) {
      return (
        <>
          <Styled.AudioButton isSend={status !== HomeworkStatuses.NOT_READY}>
            <AudioPlayerWaveForm
              id={'recorded_audio' + uid}
              audio={url ?? URL.createObjectURL(audioRecord)}
              isProfile
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
                marginRight: 10,
              }}
            />
            {(status === HomeworkStatuses.NOT_READY || status === HomeworkStatuses.IN_PROGRESS) && (
              <IconButton
                className="delete-btn"
                onClick={() => {
                  setAudioRecord(null)
                  setStartAudio(false)
                }}
                style={{
                  background: 'rgba(194, 207, 224, 0.15)',
                  width: 44,
                  height: 44,
                }}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Styled.AudioButton>
        </>
      )
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRecord, audioURL, url])
  useEffect(() => {
    async function fetchData() {
      const teacher = await fetchTeacherProfileByUid(teacherId)
      setTeacherProfile(teacher)
    }
    fetchData()
  }, [teacherId])
  const suggestionByAIData = useMemo(() => {
    if (typeof suggestionByAI !== 'string') return {}
    let replaced =
      suggestionByAI.split('[').length > 1 ? suggestionByAI.split('[') : suggestionByAI.split('\n')
    replaced = replaced?.filter(item => !!item.trim())
    let comment = ''
    let checkedText = ''
    if (replaced && replaced.length > 1) {
      comment = replaced
        .slice(1)
        .join()
        .replace(']', '')
      checkedText = replaced
        .slice(0, 1)
        .join()
        .replace(']', '')
    }
    return { comment, checkedText }
  }, [suggestionByAI])

  const [highlightedWords, setHighlightedWords] = useState(new Map())
  const createWordRegex = useCallback(word => {
    return new RegExp(`(^|\\s|[.,!?;])${word.toLowerCase().trim()}($|\\s|[.,!?;])`, 'iu')
  }, [])

  const checkWordInclusion = useCallback(
    (word, text) => {
      const regex = createWordRegex(word)
      return text && regex.test(text.toLowerCase().trim())
    },
    [createWordRegex]
  )
  useEffect(() => {
    const extractSpanishWords = text => {
      if (!text) return
      const textWithoutURLs = (text || '').replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')

      const spanishWords = textWithoutURLs.match(/[A-Za-zÀ-ÿúéíóúü]+(?:\s+[A-Za-zÀ-ÿúéíóúü]+)*/g)
      return spanishWords
    }

    if (!isHomeWork) {
      const words: string[] = extractSpanishWords(description)
      const wordsMap = new Map()
      words?.forEach(word => {
        const isWordIncluded = checkWordInclusion(word, newWriting)
        wordsMap.set(word, isWordIncluded)
      })
      setHighlightedWords(wordsMap)
    } else {
      const regex = description.includes('Используй следующие слова в сочинении:')
        ? /<b>Используй следующие слова в сочинении:<\/b><\/br>([^<]*)<\/br>/
        : description.includes('Use the following words in your essay:')
        ? /<b>Use the following words in your essay:<\/b><\/br>([^<]*)<\/br>/
        : /<b>Usa las siguientes palabras en su ensayo:<\/b><\/br>([^<]*)<\/br>/
      const match = description.match(regex)

      if (match && match[1]) {
        const spanishWords = match[1]
          .split(', ')
          .map(word => word.trim())
          .filter(word => word)
        const wordsMap = new Map()
        spanishWords?.forEach(word => {
          const isWordIncluded = checkWordInclusion(word, newWriting)
          wordsMap.set(word, isWordIncluded)
        })
        setHighlightedWords(wordsMap)
      }
    }
  }, [writingInputRef.current?.innerText])

  return (
    <Styled.FlexWrapper>
      <Styled.HomeWorkCardContainer isNew={showStartButton} isStart={showStartButton}>
        {(status === HomeworkStatuses.CHECKED || status === HomeworkStatuses.DONE) && (
          <Styled.CloseBtn className="closeDiv">
            <Styled.CancelBtn
              src={closeIcon}
              className="closeBtn"
              onClick={() => setIsRemove(true)}
            />
          </Styled.CloseBtn>
        )}
        <TopContent
          className={className}
          isStudent
          status={status}
          showStatus={status !== HomeworkStatuses.NOT_READY}
          showPencil={showPencil}
          showSpeech={showSpeech}
          isGettingAI={isAIgetting}
        />
        {date && (
          <Text display="addHomeworkContent" style={{ marginTop: '5px' }}>
            {formatDateToTemplate(date.toDate())}
          </Text>
        )}
        <HomeWorkDescription
          highlightedWords={highlightedWords}
          description={description}
          isHomeWork={isHomeWork}
          isStart={
            status === HomeworkStatuses.NOT_READY.toString() && !startWriting && showStartButton
          }
        />

        {isWriting ? (
          <>
            {showStartButton && (
              <Styled.Actions>
                <Styled.Button onClick={removeHomework} disabled={loading}>
                  {t('Homework.WillNotDo')}
                </Styled.Button>

                <Tooltip
                  placement="bottom"
                  title={<TooltipContainer>{t('Homework.MadeEsewhereTooltip')}</TooltipContainer>}
                >
                  <Styled.Button onClick={() => removeHomework(true)} disabled={loading}>
                    {t('Homework.MadeEsewhere')}
                  </Styled.Button>
                </Tooltip>
                <StudentButton
                  onClick={handleStartWriting}
                  bgColor={theme.colors.hmStartStatus}
                  text={t('HomeWork.Proceed')}
                />
              </Styled.Actions>
            )}

            {showInputField && (
              <Styled.WritingInputWrapper>
                <Styled.WritingInput
                  ref={writingInputRef}
                  onInput={handleFieldChange}
                  onFocus={handleFieldFocus(true)}
                  onBlur={handleFieldFocus(false)}
                  placeholder={placeholder}
                  contentEditable
                />
                <SpanishSymbolforDiv event={event} />
              </Styled.WritingInputWrapper>
            )}

            {showFooter && (
              <Styled.Footer>
                <>
                  {showFooterDraftState ? (
                    <>
                      {writingInputRef.current?.innerText && (
                        <Button
                          actionButton={false}
                          disabled={loading}
                          children={writing ? t('DiscardChanges') : t('Clear')}
                          customStyles={cancelButtonStyles}
                          onClick={handleCancel}
                        />
                      )}
                      <Button
                        loading={loading}
                        children={t('Save')}
                        disabled={!writingInputRef.current?.innerText}
                        customStyles={getSaveButtonStyles(writingInputRef.current?.innerText)}
                        onClick={handleSave}
                      />
                    </>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      {!loading && (
                        <Tooltip
                          placement="bottom"
                          title={
                            <TooltipContainer>{t('Homework.QuickCheckTooltip')}</TooltipContainer>
                          }
                        >
                          <span>
                            <Styled.Checkbox
                              name={t('Homework.QuickCheck')}
                              onChange={e => setIsQuikCheck(e.target.checked)}
                              checked={isQuickCheck}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {!isAIgetting && (
                        <Button
                          loading={loading}
                          customStyles={checkButtonStyles}
                          onClick={handleFinish}
                        >
                          <span>{t('HomeWork.ForChecking')}</span>
                          <img style={{ marginLeft: '10px' }} src={Check} alt="check" />
                        </Button>
                      )}
                    </div>
                  )}
                </>
              </Styled.Footer>
            )}
            {showTextPreview && (
              <>
                <HomeworkTextPreview
                  hideBlock
                  writing={writing}
                  header={t('Homework.OriginalEssay')}
                  isOriginalText={!!suggestionByAI || !!teacherWriting}
                />
                {(suggestionByAI || teacherWriting) && (
                  <>
                    <HomeworkTextPreview
                      hideBlock
                      writing={teacherWriting || suggestionByAIData?.checkedText}
                      header={t('Homework.CheckedEssay')}
                    />
                    <HomeworkTextPreview
                      writing={writing}
                      checkedText={suggestionByAIData.checkedText}
                      teacherWriting={teacherWriting}
                      header={t('Homework.CheckedEssayWithNotes')}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : isAudio ? (
          <>
            {showStartRecord && (
              <Styled.Actions>
                <StudentButton
                  onClick={handleStartAudio}
                  bgColor={theme.colors.hmStartStatus}
                  text={t('HomeWork.Proceed')}
                />
              </Styled.Actions>
            )}
            <MainModal
              open={isOpenRecord}
              children={() => {
                return (
                  <AudioRecordModal
                    title={'Аудио запись'}
                    description={description}
                    handleClose={() => {
                      setStartAudio(false)
                      setIsOpenRecord(false)
                    }}
                    handleSave={handleOnAudioRecordChange}
                  />
                )
              }}
              handleClose={() => {
                setStartAudio(false)
                setIsOpenRecord(false)
              }}
            />
            {memorizedAudio}

            {showAudioFooter && (
              <Styled.Footer>
                <>
                  {audioRecord && (
                    <Button
                      loading={loading}
                      customStyles={checkButtonStyles}
                      onClick={handleOnAudioRecordSave}
                    >
                      <span>{t('HomeWork.ForChecking')}</span>
                      <img style={{ marginLeft: '10px' }} src={Check} alt="check" />
                    </Button>
                  )}
                </>
              </Styled.Footer>
            )}
          </>
        ) : (
          (status === HomeworkStatuses.NOT_READY || status === HomeworkStatuses.IN_PROGRESS) && (
            <Styled.Footer>
              <Styled.FooterButtonWrapper>
                <Button loading={loading} customStyles={doneButtonStyles} onClick={handleFinish}>
                  <span>{t('HomeWork.Done')}</span>
                  <img style={{ marginLeft: '10px' }} src={Check} alt="check" />
                </Button>
                <div className="menu-text">{t('HomeWork.ClickWhenYouCompleteTheTask')}</div>
              </Styled.FooterButtonWrapper>
            </Styled.Footer>
          )
        )}
      </Styled.HomeWorkCardContainer>
      {comment && status === HomeworkStatuses.CHECKED && (
        <StudentViewHomeWorkComment
          comment={comment}
          teacherName={teacherName}
          teacherAvatar={teacherAvatar}
          handleClick={() => setOpenTeacherCard(true)}
        />
      )}
      {suggestionByAI && !comment && status === HomeworkStatuses.CHECKED_AI && (
        <StudentViewHomeWorkComment
          comment={suggestionByAIData?.comment}
          teacherName={t('HomeWork.ArtificialIntelligence')}
          teacherAvatar={CheckedAIAvater}
        />
      )}
      {isRemove && (
        <Styled.ClosePopup
          onClick={() => {
            setIsRemove(false)
          }}
        >
          <div className="mainDiv" onClick={e => e.stopPropagation()}>
            <h3>{t('Homework.DeleteTitle')}</h3>
            <div style={{ margin: '40px 0px' }}>{t('Homework.DeleteDesc')}</div>
            <div>
              <div className="popupBtn" onClick={removeHomework}>
                {t('Delete')}
              </div>
              <div
                style={{ cursor: 'pointer', marginTop: '20px' }}
                onClick={() => setIsRemove(false)}
              >
                {t('Cancel')}
              </div>
            </div>
          </div>
        </Styled.ClosePopup>
      )}
      <MainModal
        open={openTeacherCard}
        children={() => (
          <TeacherCard handleClose={() => setOpenTeacherCard(false)} teacherData={teacherProfile} />
        )}
        handleClose={() => setOpenTeacherCard(false)}
        maxWidth={'685px'}
      />
    </Styled.FlexWrapper>
  )
}

export default HomeWorkCard
