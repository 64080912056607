import * as React from 'react'

const TelegramIcon = props => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#419FD9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5569 23.9234C20.6752 24.4126 21.0946 23.3875 21.0946 23.3875L24.0535 8.52289C24.0302 7.52104 22.6789 8.12681 22.6789 8.12681L6.1135 14.6272C6.1135 14.6272 5.32134 14.9067 5.39124 15.396C5.46114 15.8853 6.0902 16.1183 6.0902 16.1183L10.2607 17.5162C10.2607 17.5162 11.5188 21.6401 11.7751 22.4322C12.0081 23.2011 12.2178 23.2244 12.2178 23.2244C12.4508 23.3176 12.6604 23.1545 12.6604 23.1545L15.3631 20.7081L19.5569 23.9234ZM20.2791 11.1556C20.2791 11.1556 20.8616 10.8061 20.8383 11.1556C20.8383 11.1556 20.9315 11.2022 20.6286 11.5283C20.349 11.8079 13.7555 17.7258 12.8701 18.518C12.8002 18.5646 12.7536 18.6345 12.7536 18.7276L12.4973 20.9177C12.4507 21.1507 12.1479 21.174 12.078 20.9643L10.9829 17.3763C10.9363 17.2365 10.9829 17.0734 11.1227 16.9802L20.2791 11.1556Z"
        fill="white"
      />
    </svg>
  )
}

export default TelegramIcon
