import React from 'react';

export default function FeatherIcon(props) {
  return (
    <svg {...props} viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'>
      <path d='m41.768 18.232c0.9763 0.9763 0.9763 2.5593 0 3.5356l-35 35c-0.97631 0.9763-2.5592 0.9763-3.5355 0s-0.97631-2.5593 0-3.5356l35-35c0.9763-0.9763 2.5593-0.9763 3.5356 0z' />
      <path d='m27.607 7.6072c3.2835-3.2834 7.7368-5.1281 12.38-5.1281s9.0968 1.8446 12.38 5.1281c3.2834 3.2835 5.128 7.7368 5.128 12.38 0 4.6426-1.8439 9.0951-5.1261 12.378l-0.0019 0.0019-16.847 16.897c-0.4691 0.4705-1.1061 0.7349-1.7704 0.7349h-21.25c-1.3807 0-2.5-1.1193-2.5-2.5v-21.25c0-0.663 0.2634-1.2989 0.7322-1.7678l16.875-16.875zm12.38-0.12807c-3.3174 0-6.499 1.3178-8.8447 3.6636l-16.143 16.143v17.714h17.712l16.117-16.165 0.0026-0.0027c2.3458-2.3457 3.6636-5.5273 3.6636-8.8447s-1.3178-6.499-3.6636-8.8447c-2.3457-2.3458-5.5273-3.6636-8.8447-3.6636z' />
      <path d='m20 37.5c0-1.3807 1.1193-2.5 2.5-2.5h20c1.3807 0 2.5 1.1193 2.5 2.5s-1.1193 2.5-2.5 2.5h-20c-1.3807 0-2.5-1.1193-2.5-2.5z' />
    </svg>
  );
}
