import React from 'react'
function PauseIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 3C3.75 2.58579 4.08579 2.25 4.5 2.25H7.5C7.91421 2.25 8.25 2.58579 8.25 3V15C8.25 15.4142 7.91421 15.75 7.5 15.75H4.5C4.08579 15.75 3.75 15.4142 3.75 15V3ZM5.25 3.75V14.25H6.75V3.75H5.25Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 3C9.75 2.58579 10.0858 2.25 10.5 2.25H13.5C13.9142 2.25 14.25 2.58579 14.25 3V15C14.25 15.4142 13.9142 15.75 13.5 15.75H10.5C10.0858 15.75 9.75 15.4142 9.75 15V3ZM11.25 3.75V14.25H12.75V3.75H11.25Z"
        fill={props.color}
      />
    </svg>
  )
}

export default PauseIcon
