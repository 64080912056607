import React from 'react'

export default function RectCheckboxIcon({ checked, rect = true }) {
  if (!checked) {
    if (!rect)
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="10" fill="#7E92AC" />
          <circle cx="10" cy="10" r="8" fill="white" />
        </svg>
      )

    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="2" y="2" width="20" height="20" rx="1" stroke="#C2CFE0" strokeWidth="2" />
      </svg>
    )
  } else {
    if (!rect) {
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="10" fill="#336A93" />
          <circle cx="10" cy="10" r="8" fill="white" />
          <circle cx="10" cy="10" r="5" fill="#336A93" />
        </svg>
      )
    }
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6517_51404)">
          <path
            d="M16 1C17.6 1 17.5 3 16 3H4C3.5 3 3 3.5 3 4V20C3 20.5 3.5 21 4 21H20C20.5305 21 21 20.5 21 20V11C21 9.4 23 9.5 23 11V20C23 22.4 21 23 20 23H4C1.6 23 1 21 1 20V3.5C1 1.5 3 1 4 1H16Z"
            fill="#FF922D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.6872 3.31665C23.104 3.73916 23.1043 4.42452 22.688 4.84744L12.0213 15.6826C11.8213 15.8857 11.5499 15.9999 11.2669 16C10.9839 16.0001 10.7125 15.886 10.5124 15.683L7.31242 12.4357C6.89586 12.013 6.89586 11.3276 7.31242 10.9049C7.72898 10.4822 8.40435 10.4822 8.82091 10.9049L11.2663 13.3864L21.1787 3.31742C21.5951 2.89449 22.2704 2.89415 22.6872 3.31665Z"
            fill="#FF922D"
          />
        </g>
        <defs>
          <clipPath id="clip0_6517_51404">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

RectCheckboxIcon.defaultProps = {
  checked: false,
}
