import React from 'react';

const BoldTextIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5781 9.47844C14.2467 10.1037 15.4344 11.7131 15.4344 13.6C15.4344 16.0301 13.4644 18 11.0344 18H4.23438V2H9.43437C11.8644 2 13.8344 3.96995 13.8344 6.4C13.8344 7.59841 13.3553 8.68492 12.5781 9.47844ZM9.43386 9.19996C10.9803 9.19996 12.2339 7.94636 12.2339 6.39996C12.2339 4.85356 10.9803 3.59996 9.43386 3.59996H5.83386V9.19996H9.43386ZM5.83386 16.4V10.8H11.0339C12.5803 10.8 13.8339 12.0536 13.8339 13.6C13.8339 15.1464 12.5803 16.4 11.0339 16.4H5.83386Z"
      fill="currentColor"
    />
    <path
      d="M12.5781 9.47844L12.2209 9.12859L11.6836 9.67719L12.4027 9.94664L12.5781 9.47844ZM4.23438 18H3.73438V18.5H4.23438V18ZM4.23438 2V1.5H3.73438V2H4.23438ZM5.83386 3.59996V3.09996H5.33386V3.59996H5.83386ZM5.83386 9.19996H5.33386V9.69996H5.83386V9.19996ZM5.83386 10.8V10.3H5.33386V10.8H5.83386ZM5.83386 16.4H5.33386V16.9H5.83386V16.4ZM15.9344 13.6C15.9344 11.4979 14.611 9.70624 12.7536 9.01023L12.4027 9.94664C13.8824 10.5011 14.9344 11.9283 14.9344 13.6H15.9344ZM11.0344 18.5C13.7406 18.5 15.9344 16.3062 15.9344 13.6H14.9344C14.9344 15.7539 13.1883 17.5 11.0344 17.5V18.5ZM4.23438 18.5H11.0344V17.5H4.23438V18.5ZM3.73438 2V18H4.73438V2H3.73438ZM9.43437 1.5H4.23438V2.5H9.43437V1.5ZM14.3344 6.4C14.3344 3.6938 12.1406 1.5 9.43437 1.5V2.5C11.5883 2.5 13.3344 4.24609 13.3344 6.4H14.3344ZM12.9354 9.82828C13.8003 8.9451 14.3344 7.73442 14.3344 6.4H13.3344C13.3344 7.4624 12.9102 8.42474 12.2209 9.12859L12.9354 9.82828ZM11.7339 6.39996C11.7339 7.67022 10.7041 8.69996 9.43386 8.69996V9.69996C11.2564 9.69996 12.7339 8.2225 12.7339 6.39996H11.7339ZM9.43386 4.09996C10.7041 4.09996 11.7339 5.1297 11.7339 6.39996H12.7339C12.7339 4.57742 11.2564 3.09996 9.43386 3.09996V4.09996ZM5.83386 4.09996H9.43386V3.09996H5.83386V4.09996ZM6.33386 9.19996V3.59996H5.33386V9.19996H6.33386ZM9.43386 8.69996H5.83386V9.69996H9.43386V8.69996ZM5.33386 10.8V16.4H6.33386V10.8H5.33386ZM11.0339 10.3H5.83386V11.3H11.0339V10.3ZM14.3339 13.6C14.3339 11.7775 12.8564 10.3 11.0339 10.3V11.3C12.3041 11.3 13.3339 12.3298 13.3339 13.6H14.3339ZM11.0339 16.9C12.8564 16.9 14.3339 15.4226 14.3339 13.6H13.3339C13.3339 14.8703 12.3041 15.9 11.0339 15.9V16.9ZM5.83386 16.9H11.0339V15.9H5.83386V16.9Z"
      fill="currentColor"
    />
  </svg>
);

export { BoldTextIcon };
