import React from "react";

export default function ImageIcon() {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.1667 3.3333c-0.46024 0-0.83334 0.37309-0.83334 0.83333v11.667c0 0.4603 0.3731 0.8334 0.83334 0.8334h11.667c0.4603 0 0.8334-0.3731 0.8334-0.8334v-11.667c0-0.46024-0.3731-0.83333-0.8334-0.83333h-11.667zm-2.5 0.83333c0-1.3807 1.1193-2.5 2.5-2.5h11.667c1.3807 0 2.5 1.1193 2.5 2.5v11.667c0 1.3808-1.1193 2.5-2.5 2.5h-11.667c-1.3807 0-2.5-1.1192-2.5-2.5v-11.667z" />
      <path d="m7.0833 6.6667c-0.23012 0-0.41666 0.18655-0.41666 0.41667s0.18654 0.41667 0.41666 0.41667 0.41667-0.18655 0.41667-0.41667-0.18655-0.41667-0.41667-0.41667zm-2.0833 0.41667c0-1.1506 0.93274-2.0833 2.0833-2.0833 1.1506 0 2.0833 0.93274 2.0833 2.0833s-0.93274 2.0833-2.0833 2.0833c-1.1506 0-2.0833-0.93274-2.0833-2.0833z" />
      <path d="m12.744 7.7441c0.3254-0.32543 0.853-0.32543 1.1785 0l4.1666 4.1667c0.3255 0.3254 0.3255 0.853 0 1.1785-0.3254 0.3254-0.853 0.3254-1.1785 0l-3.5774-3.5774-8.5774 8.5774c-0.32543 0.3254-0.85307 0.3254-1.1785 0-0.32544-0.3255-0.32544-0.8531 0-1.1786l9.1667-9.1666z" />
    </svg>
  );
}
