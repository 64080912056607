import * as React from 'react';

const CardPhoneIcon = (props) => {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='#C2CFE0' />
      <path
        d='M17.3399 14.2533C16.5199 14.2533 15.7265 14.12 14.9865 13.88C14.7532 13.8 14.4932 13.86 14.3132 14.04L13.2665 15.3533C11.3799 14.4533 9.61321 12.7533 8.67321 10.8L9.97321 9.69333C10.1532 9.50667 10.2065 9.24667 10.1332 9.01333C9.88654 8.27333 9.75988 7.48 9.75988 6.66C9.75988 6.3 9.45988 6 9.09988 6H6.79321C6.43321 6 5.99988 6.16 5.99988 6.66C5.99988 12.8533 11.1532 18 17.3399 18C17.8132 18 17.9999 17.58 17.9999 17.2133V14.9133C17.9999 14.5533 17.6999 14.2533 17.3399 14.2533Z'
        fill='white'
      />
    </svg>
  );
};

export default CardPhoneIcon;
