import React from 'react'
import { useSelector } from 'react-redux'
import * as Styled from './help.styles'
import Phone from '@images/phone.svg'
import WhatsApp from '@images/whatsapp.svg'
import Telegram from '@images/telegram.svg'
import { openSupport } from '@utils/helperst'
import { IStudentProfile } from '@models/IProfile'
import { HelpWhatsAppLink, HelpTelegramLink, ManagerPhone } from '@utils/constants'
import { useTranslation } from 'react-i18next'

import { RootState } from '@store'
import { makeStyles, Tooltip } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  tooltip: {
    background: 'transparent',
  },
}))

const Help = () => {
  const states = useSelector((state: RootState) => ({
    profile: state.profile.profile as IStudentProfile,
  }))
  const { t } = useTranslation()

  const Open = (link: string) => window.open(link, '_blank')
  const classes = useStyles()

  return (
    <Styled.HelpContainer>
      <Styled.Phone src={Phone} />
      <Styled.HelpText>
        {t('NeedhelpCallUs')}{' '}
        <a href={`tel:${ManagerPhone}`}>
          <b>{ManagerPhone}</b>
        </a>
        <br />
        {t('Or')}{' '}
        <Styled.HelpButton onClick={e => openSupport(e, states.profile)}>
          {t('AskManagerContactYou')}
        </Styled.HelpButton>
      </Styled.HelpText>
      <Styled.HelpIcons>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <div
              style={{
                backgroundColor: '#414752',
                color: '#fff',
                fontSize: 14,
                fontFamily: 'Open Sans',
                padding: '8px 12px',
                borderRadius: 8,
                marginLeft: '-10px',
              }}
            >
              {t('Links.WhatsappToolTip')}
            </div>
          }
          placement="right"
        >
          <Styled.WhatsApp onClick={() => Open(HelpWhatsAppLink)} src={WhatsApp} />
        </Tooltip>

        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <div
              style={{
                backgroundColor: '#414752',
                color: '#fff',
                fontSize: 14,
                fontFamily: 'Open Sans',
                padding: '8px 12px',
                borderRadius: 8,
                marginLeft: '-10px',
              }}
            >
              {t('Links.TelegramToolTip')}
            </div>
          }
          placement="right"
        >
          <Styled.Telegram onClick={() => Open(HelpTelegramLink)} src={Telegram} />
        </Tooltip>
      </Styled.HelpIcons>
    </Styled.HelpContainer>
  )
}

export default Help