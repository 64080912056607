import React from 'react'

const MenuConstructor = ({ actions }) => {
  const mockActions = actions.map(({ id, name, onClick }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleClickItem = () => {
      document.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      )
      onClick()
    }

    return (
      <div key={id} className="dropdown-item" onClick={handleClickItem}>
        <span className="dropdown-item-name">{name}</span>
      </div>
    )
  })

  return <div className="dropdown-menu">{mockActions}</div>
}

export default MenuConstructor
