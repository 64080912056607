import React from 'react'
function EmailIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#0971BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40039 8.58763C6.40039 8.58763 11.2302 11.3265 12.1676 11.3265C13.1502 11.3265 17.9276 8.63995 17.9276 8.63995L17.9353 8.15995C17.9353 7.63003 17.5052 7.19995 16.9738 7.19995H7.36135C6.83047 7.19995 6.40039 7.63003 6.40039 8.15995V8.58763ZM17.9276 10.0799C17.9276 10.0799 13.1953 12.6465 12.1676 12.6465C11.2302 12.6465 6.40759 10.0799 6.40807 10.0799L6.40039 15.8399C6.40039 16.3699 6.83095 16.7999 7.36135 16.7999H16.9738C17.5052 16.7999 17.9353 16.3699 17.9353 15.8399L17.9276 10.0799Z"
        fill="white"
      />
    </svg>
  )
}

export default EmailIcon
