import React, { useEffect, useState } from 'react'

import { IStudentProfile, LangEnum } from '@models'
import * as Styled from './student-card.styles'
import { withStyles, Tooltip as MuiToolTip, IconButton } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CopyIcon } from '@components/Icons'
import { toast } from 'react-toastify'
import EditIcon from '../Icons/EditIcon'
import CheckMarkIcon from '../Icons/CheckMark'
import { studentsRef } from '@configs/firebase'
import SMSIcon from '@components/Icons/SMS'
import { sendSMSToStudent } from '@servise/webhookAPI'
import { APP_CONFIG } from '@utils/constants'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CustomTextfield from '../../../pages/teacher/TeacherProfile/CustomTextfield'
import { skypeRegex, telegramRegex } from '@utils/helperst'
interface Props {
  studentData: IStudentProfile
  handleChange: (data: IStudentProfile) => void
  showContacts?: boolean
  isManager?: boolean
}

export const Tooltip = withStyles({
  tooltip: {
    position: 'absolute',
    borderRadius: '8px',
    padding: '7px 15px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    left: '35px',
    top: '-10px',
  },
})(MuiToolTip)

const ValidateSchema = Yup.object().shape({
  skype: Yup.string()
    .max(50, 'Too Long!')
    .matches(skypeRegex, 'Skype username must not contain Cyrillic or leading/trailing spaces'),
  telegram: Yup.string().matches(telegramRegex, 'Invalid telegram format!'),
})

const Contacts = ({ studentData, handleChange, showContacts, isManager }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const smsSentTimeout = 5 * 60 * 1000

  const formik = useFormik<{ telegram: string; phone: string; skype: string }>({
    initialValues: {
      phone: '',
      skype: '',
      telegram: '',
    },
    validationSchema: ValidateSchema,
    onSubmit: values => {
      if (!isManager) {
        setIsEditing(false)
        return
      }
      studentsRef
        .doc(studentData.id)
        .update({ ...values })
        .then(() => {
          handleChange({ ...studentData, ...values })
        })
        .finally(() => {
          setIsEditing(false)
        })
    },
  })
  useEffect(() => {
    formik.setValues({
      skype: studentData.skype,
      phone: studentData.phone,
      telegram: studentData.telegram,
    })
  }, [studentData])

  const handleCopied = () => {
    toast('Data copied', {
      autoClose: 2000,
      position: 'bottom-center',
      closeButton: false,
      hideProgressBar: true,
      className: 'student-card-message',
    })
  }

  useEffect(() => {
    // Check if a previous SMS was sent
    const smsSentTimestamp = localStorage.getItem('smsSentTimestamp')

    if (smsSentTimestamp) {
      const currentTime = Date.now()
      const timeDifference = currentTime - parseInt(smsSentTimestamp, 10)
      const remainingTime = smsSentTimeout - timeDifference // 5 minutes in milliseconds

      // If the remaining time is still within the 5-minute, hide the button
      if (remainingTime > 0) {
        setIsShow(false)

        // Set a timeout to make the button visible again after the remaining time
        setTimeout(() => {
          setIsShow(true)
        }, remainingTime)
      }
    }
  }, [smsSentTimeout])

  const handleSendSMS = () => {
    const SMSText =
      !studentData.lang || studentData.lang === LangEnum.ru
        ? `У вас начался урок в ${APP_CONFIG.appName}. Сообщите преподавателю, если опаздываете.`
        : studentData.lang === LangEnum.en
        ? `Your lesson has started at ${APP_CONFIG.appName}. Tell your teacher if you are late.`
        : `Tu lección ha comenzado en la ${APP_CONFIG.appName}. Dile a tu maestro si llegas tarde.`
    if (formik.values.phone) {
      sendSMSToStudent({
        phNo: formik.values.phone
          .split(' ')
          .join('')
          .trim(),
        text: SMSText,
        locale: studentData.lang,
      })
      localStorage.setItem('smsSentTimestamp', String(Date.now()))
      setIsShow(false)

      // Set a timeout to make the button visible again after 5 minutes
      setTimeout(() => {
        setIsShow(true)
      }, smsSentTimeout)
    } else {
      toast('Phone number not found', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-card-message',
      })
    }
  }

  return (
    <Styled.ContactsContainter>
      <Styled.StudentCardHeader>Contacts</Styled.StudentCardHeader>
      {showContacts ? (
        <Styled.Contacts editing={isEditing} manager={isManager}>
          {isEditing ? (
            <IconButton
              className="edit-button"
              style={{ zIndex: 99 }}
              size="small"
              onClick={formik.handleSubmit}
            >
              <CheckMarkIcon />
            </IconButton>
          ) : (
            <IconButton
              className="edit-button show"
              size="small"
              onClick={() => setIsEditing(true)}
            >
              <EditIcon />
            </IconButton>
          )}

          <Styled.ContactBlock>
            <div className="phone">
              <Styled.PhoneIcon />
              {isEditing ? (
                <CustomTextfield
                  style={{ fontSize: 14 }}
                  id="input-phone-number"
                  shrink
                  name="phone"
                  onChange={e => {
                    formik.setFieldValue('phone', e.target.value)
                  }}
                  value={formik.values.phone}
                />
              ) : (
                <Styled.Label>{formik.values.phone}</Styled.Label>
              )}

              {formik.values.phone && (
                <CopyToClipboard text={formik.values.phone}>
                  <IconButton className="copy" size="small" onClick={handleCopied}>
                    <CopyIcon />
                  </IconButton>
                </CopyToClipboard>
              )}
              {isShow && !isEditing && (
                <div>
                  <Tooltip
                    title={
                      <div style={{ lineHeight: '19px', width: '280px' }}>
                        <span style={{ fontWeight: 700 }}>Send SMS:</span>
                        <div>
                          Your lesson has started at {APP_CONFIG.appName}. Tell your teacher if you
                          are late.
                        </div>
                      </div>
                    }
                    placement="bottom-start"
                  >
                    <Styled.SMSIconContainer onClick={handleSendSMS}>
                      <SMSIcon />
                    </Styled.SMSIconContainer>
                  </Tooltip>
                </div>
              )}
            </div>
          </Styled.ContactBlock>
          <Styled.ContactBlock>
            <Styled.SkypeIcon />
            {isEditing ? (
              <CustomTextfield
                style={{ fontSize: 14, marginLeft: 15 }}
                name="skype"
                onChange={event => {
                  formik.setFieldValue('skype', event.target.value.trim())
                }}
                value={formik.values.skype?.trim()}
                error={formik.errors.skype && formik.touched.skype}
                helperText={formik.errors.skype && formik.touched.skype && formik.errors.skype}
              />
            ) : (
              <Styled.Label>{formik.values.skype}</Styled.Label>
            )}

            {formik.values.skype && (
              <CopyToClipboard text={formik.values.skype}>
                <IconButton className="copy" size="small" onClick={handleCopied}>
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
            )}
          </Styled.ContactBlock>
          <Styled.ContactBlock>
            <Styled.EmailIcon />
            <Styled.Label>{studentData.email}</Styled.Label>
            {studentData.email && (
              <div
                style={{
                  display: 'flex',
                  width: '80px',
                  justifyContent: 'space-between',
                }}
              >
                <CopyToClipboard text={studentData.email}>
                  <IconButton className="copy" size="small" onClick={handleCopied}>
                    <CopyIcon />
                  </IconButton>
                </CopyToClipboard>
                <CopyToClipboard text={studentData.id}>
                  <IconButton className="copy" size="small" onClick={handleCopied}>
                    <span style={{ fontSize: '12px' }}>Copy ID</span>
                  </IconButton>
                </CopyToClipboard>
              </div>
            )}
          </Styled.ContactBlock>
          <Styled.ContactBlock>
            <Styled.TelegramIcon />
            {isEditing ? (
              <CustomTextfield
                style={{ fontSize: 14, marginLeft: 15 }}
                name="telegram"
                onChange={event => {
                  formik.setFieldValue('telegram', event.target.value.trim())
                }}
                value={formik.values.telegram?.trim()}
                error={formik.errors.telegram && formik.touched.telegram}
                helperText={
                  formik.errors.telegram && formik.touched.telegram && formik.errors.telegram
                }
              />
            ) : (
              <Styled.Label>{formik.values.telegram}</Styled.Label>
            )}

            {formik.values.telegram && (
              <CopyToClipboard text={formik.values.telegram}>
                <IconButton className="copy" size="small" onClick={handleCopied}>
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
            )}
          </Styled.ContactBlock>
        </Styled.Contacts>
      ) : (
        <Styled.Contacts
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#C2CFE0',
            fontSize: '14px',
          }}
        >
          No contacts
        </Styled.Contacts>
      )}
    </Styled.ContactsContainter>
  )
}

export default Contacts
