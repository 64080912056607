import React from 'react'

const EnrolmentClosed = ({ isActive, onClick }) => (
  <svg
    width="80"
    height="40"
    viewBox="0 0 80 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="80" height="40" rx="20" fill="#7e92ac" opacity={isActive ? '1' : '0.5'} />
    <path
      d="M58.3 24.3C58.2075 24.3927 58.0976 24.4663 57.9766 24.5164C57.8557 24.5666 57.726 24.5924 57.595 24.5924C57.464 24.5924 57.3344 24.5666 57.2134 24.5164C57.0924 24.4663 56.9825 24.3927 56.89 24.3L54 21.41L51.11 24.3C50.923 24.487 50.6694 24.592 50.405 24.592C50.1406 24.592 49.887 24.487 49.7 24.3C49.513 24.113 49.408 23.8594 49.408 23.595C49.408 23.4641 49.4338 23.3344 49.4839 23.2135C49.534 23.0925 49.6074 22.9826 49.7 22.89L52.59 20L49.7 17.11C49.513 16.923 49.408 16.6694 49.408 16.405C49.408 16.1406 49.513 15.887 49.7 15.7C49.887 15.513 50.1406 15.408 50.405 15.408C50.6694 15.408 50.923 15.513 51.11 15.7L54 18.59L56.89 15.7C56.9826 15.6074 57.0925 15.534 57.2135 15.4839C57.3344 15.4338 57.4641 15.408 57.595 15.408C57.7259 15.408 57.8556 15.4338 57.9765 15.4839C58.0975 15.534 58.2074 15.6074 58.3 15.7C58.3926 15.7926 58.466 15.9025 58.5161 16.0235C58.5662 16.1444 58.592 16.2741 58.592 16.405C58.592 16.5359 58.5662 16.6656 58.5161 16.7865C58.466 16.9075 58.3926 17.0174 58.3 17.11L55.41 20L58.3 22.89C58.68 23.27 58.68 23.91 58.3 24.3Z"
      fill="#F3F5F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8285 12.0742C32.0758 11.9753 32.3516 11.9753 32.5989 12.0742L42.9707 16.2229C43.3645 16.3804 43.6227 16.7618 43.6227 17.1859V23.409C43.6227 23.9818 43.1583 24.4462 42.5855 24.4462C42.0127 24.4462 41.5483 23.9818 41.5483 23.409V18.7179L32.5989 22.2976C32.3516 22.3966 32.0758 22.3966 31.8285 22.2976L21.4567 18.1489C21.0629 17.9914 20.8047 17.61 20.8047 17.1859C20.8047 16.7618 21.0629 16.3804 21.4567 16.2229L31.8285 12.0742ZM39.7928 17.1859L32.2137 20.2176L24.6346 17.1859L32.2137 14.1543L39.7928 17.1859Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9923 17.8105C26.5651 17.8105 27.0294 18.2749 27.0294 18.8477V24.4485C27.0294 24.6819 27.2403 25.2018 28.2788 25.721C29.2688 26.216 30.6844 26.5229 32.2154 26.5229C33.7463 26.5229 35.1619 26.216 36.1519 25.721C37.1904 25.2018 37.4013 24.6819 37.4013 24.4485V18.8477C37.4013 18.2749 37.8656 17.8105 38.4385 17.8105C39.0113 17.8105 39.4756 18.2749 39.4756 18.8477V24.4485C39.4756 25.8656 38.3752 26.9286 37.0796 27.5764C35.7355 28.2484 33.9853 28.5972 32.2154 28.5972C30.4454 28.5972 28.6952 28.2484 27.3511 27.5764C26.0555 26.9286 24.9551 25.8656 24.9551 24.4485V18.8477C24.9551 18.2749 25.4194 17.8105 25.9923 17.8105Z"
      fill="white"
    />
  </svg>
)
export { EnrolmentClosed }
