import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { getDiff } from '@utils/homeWorkHelpers'
import { HomeworkStatuses } from '@models/IHomework'

export const HomeWorkResult = styled.div<{ originalText?: boolean }>`
  background-color: ${props => (props.originalText ? '#F3F5F9' : '#fff0e3')};
  background-image: ${props =>
    props.originalText
      ? '-webkit-linear-gradient(right, transparent, transparent 1px, #F3F5F9 1px), -webkit-linear-gradient(top, transparent, transparent 29px, #7E92AC 0)'
      : '-webkit-linear-gradient(right, transparent, transparent 1px, #fff0e3 1px), -webkit-linear-gradient(top, transparent, transparent 29px, #e2ad8c 0)'};
  background-size: 3px 1px, 94% 30px;
  background-attachment: local;
  box-sizing: border-box;
  width: 100%;

  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};

  line-height: 30px;
  font-style: normal;
  font-weight: normal;
  outline: none;
  min-height: 65px;
  white-space: pre-wrap;

  &:empty::before {
    content: attr(placeholder);
    color: #414752;
  }
`

export const HomeWorkResultWrapper = styled.div<{ originalText?: boolean }>`
 background-color: ${props => (props.originalText ? '#F3F5F9' : '#fff0e3')};
  ${props => !props.originalText && `border: 1px solid #d9bba0;`}
  border-radius: ${props => (props.originalText ? '0px 0px 20px 20px' : '20px')};
  padding: 15px;
 
  border-radius: 20px;
  margin-top: 20px;
  position: relative;
`

const HomeWorkWriting = ({
  status,
  writing,
  onClick = () => {},
  isEditable,
  teacherWriting,
  setAddTeacherWriting,
  suggestionByAI,
  setSaved,
}: {
  writing: string
  onClick?: () => void
  isEditable: boolean
  teacherWriting: string
  setAddTeacherWriting: (addText: string) => void
  suggestionByAI: string
  status?: string
  setSaved: (addText: boolean) => void
}) => {
  const writingInputRef = useRef<HTMLDivElement>()

  const handleFieldChange = (e: ChangeEvent<HTMLDivElement>) => {
    setAddTeacherWriting(e.currentTarget.innerText)
    setSaved(false)
  }
  useEffect(() => {
    if (!writingInputRef.current || !writing) return
    writingInputRef.current.innerText = writing
  }, [writing, writingInputRef])
  const suggestionByAIData = useMemo(() => {
    if (typeof suggestionByAI !== 'string') return {}
    let replaced =
      suggestionByAI.split('[').length > 1 ? suggestionByAI.split('[') : suggestionByAI.split('\n')
    replaced = replaced?.filter(item => !!item.trim())
    let comment = ''
    let checkedText = ''
    if (replaced && replaced.length > 1) {
      comment = replaced
        .slice(1)
        .join()
        .replace(']', '')
      checkedText = replaced
        .slice(0, 1)
        .join()
        .replace(']', '')
    }
    return { comment, checkedText }
  }, [suggestionByAI])
  useEffect(() => {
    if ((teacherWriting || suggestionByAI) && writingInputRef)
      getDiff(
        writing,
        teacherWriting ||
          suggestionByAIData?.checkedText?.replace(/(\r\n|\n|\r)/gm, '') ||
          suggestionByAIData?.checkedText,

        writingInputRef
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, suggestionByAIData?.checkedText])

  return (
    <>
      {HomeworkStatuses.CHECKED_AI === status && suggestionByAI && (
        <HomeWorkResultWrapper originalText>
          <HomeWorkResult originalText>{writing}</HomeWorkResult>
        </HomeWorkResultWrapper>
      )}
      <HomeWorkResultWrapper>
        {isEditable ? (
          <HomeWorkResult
            ref={writingInputRef}
            suppressContentEditableWarning={true}
            onClick={onClick}
            contentEditable={isEditable}
            onInput={handleFieldChange}
          >
            {teacherWriting || writing}
          </HomeWorkResult>
        ) : (
          <HomeWorkResult ref={writingInputRef}>{teacherWriting || writing}</HomeWorkResult>
        )}
      </HomeWorkResultWrapper>
    </>
  )
}

export default HomeWorkWriting
