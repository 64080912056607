import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import moment from 'moment'
import * as Styled from './steps.styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { firebaseTimestamp } from '@configs/firebase'
import firebase from 'firebase/app'

export default function({
  deadline,
  onChange,
}: {
  deadline?: firebase.firestore.Timestamp
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  return (
    <Styled.CalendarContainer>
      <Styled.CalendarDesc>
        {t('StudentWorkSheetStep3Desc')}
      </Styled.CalendarDesc>
      <Calendar
        date={deadline?.seconds ? deadline.toDate() : null}
        onChange={date =>
          onChange({
            name: 'deadline',
            value: firebaseTimestamp.fromDate(date),
          })
        }
      />
    </Styled.CalendarContainer>
  )
}
