import React, { useMemo, useState } from 'react'
import * as Styled from './filter-teachers.styles'
import { WEEKS } from '@utils/constants'
import { IBusinessHours, IProfile, IStudentProfile } from '@models/IProfile'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { sendTeacherSelection } from '@servise/webhookAPI'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { fetchTeacherProfileByUid } from '@servise/profileAPI'
import { calendarUtil } from '@utils/calendarUtil'
const Step2Component = ({
  studentBusinessHours,
  onChange,
  isFilter,
}: {
  studentBusinessHours: IBusinessHours[]
  onChange: (data: { name: string; value: IBusinessHours[] }) => void
  isFilter?: boolean
}) => {
  const history = useHistory()
  const [isChekedAllTimes, setIsCheckedAllTimes] = useState()
  const { t } = useTranslation()
  const handleChange = (weekDay: number, time: string) => {
    const newSelectedHours = studentBusinessHours.map(hours => {
      if (hours.startTime === time) {
        if (hours.daysOfWeek.includes(weekDay)) {
          return { ...hours, daysOfWeek: hours.daysOfWeek.filter(week => week !== weekDay) }
        } else {
          return { ...hours, daysOfWeek: [...hours.daysOfWeek, weekDay] }
        }
      }
      return hours
    })

    onChange({ name: 'studentBusinessHours', value: newSelectedHours })
  }

  const defaultDate = useMemo(() => {
    return moment()
      .add(2, 'days')
      .format()
  }, [])
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const handleTeacherSelection = async () => {
    try {
      let profile: IProfile | undefined = undefined
      if (profileData.tutorId) {
        profile = await fetchTeacherProfileByUid(profileData.tutorId)
      }

      await sendTeacherSelection({
        name: `${profileData.name} ${profileData.lastName}`,
        phone: profileData.phone,
        email: profileData.email,
        locale: profileData.lang,
        telegram: profile?.telegram,
        isStudent: profileData.student,
      })

      toast(t('Toast.AutoTeacherSelection'), {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'student-teacher-selection-success',
      })
      history.push('/student')
    } catch (e) {}
  }

  const timeZone = useMemo(() => {
    const timeZones = calendarUtil.getAvailableTimezones()
    return timeZones.find(timeZone => timeZone.value === profileData.timeZone)
  }, [profileData.timeZone])

  const handleCheckAllTimes = () => {
    setIsCheckedAllTimes(prev => {
      let newSelectedHours = studentBusinessHours.map(hours => {
        return { ...hours, daysOfWeek: [] }
      })

      if (!prev) {
        newSelectedHours = studentBusinessHours.map(hours => {
          return { ...hours, daysOfWeek: [1, 2, 3, 4, 5, 6, 7] }
        })
      }
      onChange({ name: 'studentBusinessHours', value: newSelectedHours })
      return !prev
    })
  }

  return (
    <>
      {isFilter && (
        <>
          <Styled.TitleHeader>
            <Styled.Title>{t('AutoTeacher.Step2.Title')}</Styled.Title>
            <Styled.TimeZoneLabel>{timeZone?.label}</Styled.TimeZoneLabel>
          </Styled.TitleHeader>
          <Styled.Desc>{t('AutoTeacher.Step2.Desc')}</Styled.Desc>

          <Styled.ChooseLessonDesc2>
            <div style={{ display: 'flex' }}>
              {t('AutoTeacher.Step2.Desc2')},{' '}
              <span onClick={handleTeacherSelection}> {t('AutoTeacher.Step2.Desc2.1')}</span>
            </div>
            <p>{t('AutoTeacher.Step2.Desc3')}</p>
          </Styled.ChooseLessonDesc2>
        </>
      )}
      <Styled.WeekContainer>
        {WEEKS.map((week, index) => {
          const weekday =
            moment(defaultDate)
              .add(index, 'days')
              .weekday() + 1

          return (
            <Styled.DayItem key={week.value}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                {' '}
                <Styled.DayItemLabel>
                  {moment(defaultDate)
                    .add(index, 'days')
                    .format('dddd')}
                </Styled.DayItemLabel>
                {index === 0 && (
                  <Styled.ChooseAllBtn onClick={handleCheckAllTimes} selected={isChekedAllTimes}>
                    {t('AutoTeacher.ChooseAll')}
                  </Styled.ChooseAllBtn>
                )}
              </div>

              <Styled.TimeItems>
                {studentBusinessHours.map(hours => (
                  <Styled.TimeItem
                    key={hours.startTime}
                    selected={hours.daysOfWeek.includes(weekday)}
                    onClick={() => handleChange(weekday, hours.startTime)}
                  >
                    {hours.startTime}
                  </Styled.TimeItem>
                ))}
              </Styled.TimeItems>
            </Styled.DayItem>
          )
        })}
      </Styled.WeekContainer>
    </>
  )
}

export default Step2Component
