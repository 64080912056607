import React, { Component } from 'react'
import classNames from 'classnames'

import './selectFIle.sass'
import * as PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class SelectFile extends Component {
  constructor(props) {
    super(props)

    this.selectBtnRef = React.createRef()
    this.labelErrorBtnRef = React.createRef()
    this.labelBtnRef = React.createRef()

    this.linkConvertPdf = React.createRef()
  }

  reduceFileNaming = event => {
    this.setFileNaming(event.target.files[0])
  }

  setFileNaming = file => {
    let fileSize = file.size / 1048576
    const name = file.name.split(/\\|\//).pop()
    const truncated = name.length > 20 ? name.substr(name.length - 20) : name
    if (fileSize < this.props.fileTypeConfig.size) {
      this.props.selectedFile(file)
      this.labelBtnRef.current.style.display = 'block'
      this.labelBtnRef.current.innerHTML = truncated
      this.labelErrorBtnRef.current.style.display = 'none'
      var ext = file.name.split('.').pop()

      if (ext === 'doc' || ext === 'docx') {
        this.linkConvertPdf.current.style.display = 'block'
      } else {
        this.linkConvertPdf.current.style.display = 'none'
      }
    } else {
      this.props.selectedFile(null)
      this.labelErrorBtnRef.current.style.display = 'block'
      this.labelErrorBtnRef.current.innerHTML = this.props.t('FileIsTooHeavy')
      this.labelBtnRef.current.style.display = 'none'
    }
  }

  selectFileHandler = () => {
    this.selectBtnRef.current.click()
  }

  componentDidMount() {
    if (this.props.file) {
      this.setFileNaming(this.props.file)
    }
    this.selectBtnRef.current.addEventListener('change', this.reduceFileNaming)
  }

  componentWillUnmount() {
    this.selectBtnRef.current.removeEventListener(
      'change',
      this.reduceFileNaming
    )
  }

  render() {
    const { containerClass, t, ...rest } = this.props
    const containerClassList = classNames('input-container', {
      [containerClass]: Boolean(containerClass),
    })

    return (
      <div className={containerClassList}>
        <input
          type="file"
          className="input-container__input"
          ref={this.selectBtnRef}
          {...rest}
        />

        <button
          className="input-container__btn"
          type="button"
          onClick={this.selectFileHandler}
        >
          <span> {t('SelectFile')} </span>

          <span className={'input-container__sub-button-text'}>
            {t('NoMore') + ' ' + this.props.fileTypeConfig.size + ' ' + t('MB')}
          </span>
        </button>
        <div>
          <span
            className="input-container__info"
            ref={this.labelBtnRef}
            children={t('NoFileSelected')}
          />
          <span
            className="input-container__error"
            ref={this.labelErrorBtnRef}
          />
          <a
            className="input-container__info"
            ref={this.linkConvertPdf}
            style={{
              textAlign: 'left',
              marginTop: 0,
              display: 'none',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              fontSize: 15,
              textDecorationLine: 'underline',

              color: '#1556CF',
            }}
            href={'https://www.ilovepdf.com/ru/word_to_pdf'}
          >
            {t('ConvertYourFile')}
            <br />
            {t('InPdfFormat')}{' '}
          </a>
        </div>
      </div>
    )
  }
}

SelectFile.propTypes = { containerClass: PropTypes.any }
export default withTranslation()(SelectFile)
