import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { Text } from '../Text'

export const Container = styled.button<{ bgColor: string }>`
  background: ${props => props.bgColor};
  border-radius: 20px;
  padding: 11px 20px;
  &:hover {
    opacity: 0.8;
  }
`

interface Props {
  onClick: () => void
  text: string
  bgColor: string
  img?: string
  styles?: CSSProperties
  textColor?: string
  href?: string
  target?: string
}

const StudentButton = ({ onClick, text, bgColor, img, styles, textColor, target, href }: Props) => {
  return (
    <Container onClick={onClick} bgColor={bgColor} style={styles}>
      <Text display="studentButton" color={textColor ? textColor : '#fff'}>
        {text}
        {img && <img src={img} alt="" />}
      </Text>
    </Container>
  )
}

export default StudentButton
