import { HomeworkStatuses, IState } from '@models/IHomework'
import {
  FAILED_FETCHING,
  HomeworkActionTypes,
  SET_HOMEWORKS,
  START_FETCHING,
  STUDENT_COUNT_HOMEWORKS,
  SUCCESS_FETCHING,
  TEACHER_COUNT_HOMEWORKS,
  UPDATE_HOMEWORK,
  SET_MINICOURSE,
  SET_LESSON_FEEDBACK,
  REMOVE_HOMEWORK,
} from './types'

const initialState: IState = {
  homeworks: [],
  error: '',
  loading: false,
  studentCount: 0,
  studentFinishedCount: 0,
  teacherCount: 0,
  miniCourse: {},
  lessonFeedback: false,
}

export const homeworkReducer = (state: IState = initialState, action: HomeworkActionTypes) => {
  switch (action.type) {
    case STUDENT_COUNT_HOMEWORKS:
      return { ...state, studentCount: action.payload }
    case TEACHER_COUNT_HOMEWORKS:
      return { ...state, teacherCount: action.payload }

    case START_FETCHING:
      return { ...state, loading: true }
    case SET_HOMEWORKS:
      const studentFinishedCount = action.payload.reduce((prev, curr) => {
        if (
          [HomeworkStatuses.DONE.toString(), HomeworkStatuses.CHECKED.toString()].includes(
            curr.status
          )
        ) {
          return (prev += 1)
        }
        return prev
      }, 0)

      return {
        ...state,
        studentFinishedCount,
        homeworks: action.payload,
        error: '',
        loading: false,
      }
    case SUCCESS_FETCHING:
      return { ...state, loading: false, error: '' }
    case FAILED_FETCHING:
      return { ...state, loading: false, error: action.payload }
    case UPDATE_HOMEWORK:
      return {
        ...state,
        homeworks: [
          ...state.homeworks.map(w =>
            w.id === action.payload.id ? { ...w, ...action.payload } : w
          ),
        ],
      }

    case REMOVE_HOMEWORK:
      return {
        ...state,
        homeworks: [
          ...state.homeworks.filter(homework => homework.id !== action.payload.homeworkId),
        ],
      }

    case SET_MINICOURSE:
      return {
        ...state,
        miniCourse: action.payload,
      }
    case SET_LESSON_FEEDBACK:
      return {
        ...state,
        lessonFeedback: action.payload,
      }
    default:
      return state
  }
}
