import { IStudentProfile } from './../../models/IProfile'
import {
  ClassroomActionsTypes,
  SET_CLASSROOMS,
  SET_CLASSROOM,
  CREATE_CLASSROOM,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
  SET_CLASSROOM_FILES,
  SET_STUDENTS,
  SET_STUDENT,
  SET_PUBLISHED_COURSES,
  SET_DISPLAYED_COURSE,
  CLEAR_DISPLAYED_COURSE,
  SET_LOADING_STUDENT_PAGE,
  SET_STUDENT_CURRENT_COURSE,
  SET_STUDENT_DATA_IN_CLASSROOOM,
  SET_STUDENT_DATA_CLASSROOMS,
  REMOVE_STUDENT,
} from './types'
import { IClassroomState } from '@models/IClassroom'

const initialState: IClassroomState = {
  classrooms: [],
  currentCourse: null,
  loadingStudentPage: true,
  classroomData: {
    blocks: null,
    name: '',
    description: '',
  },
  studentData: null,
  students: [],
  publishedCourses: [],
  displayedCourse: null,
}
export const classroomReducers = (
  state = initialState,
  action: ClassroomActionsTypes
): IClassroomState => {
  let classrooms = [...state.classrooms]

  switch (action.type) {
    case SET_CLASSROOMS:
      return {
        ...state,
        classrooms: [...action.payload],
      }
    case SET_CLASSROOM:
      return {
        ...state,
        classroomData: action.payload,
      }

    case SET_CLASSROOM_FILES:
      return {
        ...state,
        classroomData: { ...state.classroomData, blocks: action.payload },
      }
    case CREATE_CLASSROOM:
      classrooms.push({ ...action.payload })
      return {
        ...state,
        classrooms,
      }
    case UPDATE_CLASSROOM:
      classrooms = classrooms.map((classroom, i) => {
        if (classroom.id === action.payload.id) {
          return { ...action.payload }
        } else {
          return classroom
        }
      })
      return {
        ...state,
        classroomData: {
          ...state.classroomData,
          name: action.payload.name,
          description: action.payload.description,
        },
        classrooms,
      }
    case DELETE_CLASSROOM:
      classrooms = classrooms.filter(item => item.id !== action.payload)
      return {
        ...state,
        classrooms,
      }
    case SET_STUDENTS:
      return {
        ...state,
        students: action.payload,
      }
    case SET_STUDENT:
      return {
        ...state,
        students: state.students.map(
          s => (s.id === action.payload.id ? action.payload : s) as IStudentProfile
        ),
      }
    case SET_PUBLISHED_COURSES:
      return {
        ...state,
        publishedCourses: action.payload,
      }
    case SET_DISPLAYED_COURSE:
      return {
        ...state,
        displayedCourse: action.payload,
      }
    case CLEAR_DISPLAYED_COURSE:
      return {
        ...state,
        displayedCourse: null,
      }
    case SET_LOADING_STUDENT_PAGE:
      return {
        ...state,
        loadingStudentPage: action.payload,
      }
    case SET_STUDENT_CURRENT_COURSE:
      return {
        ...state,
        currentCourse: action.payload,
      }

    case SET_STUDENT_DATA_IN_CLASSROOOM:
      return {
        ...state,
        studentData: action.payload,
      }

    case SET_STUDENT_DATA_CLASSROOMS:
      classrooms = classrooms.map((classroom, i) => {
        if (classroom.id === action.payload.id) {
          return { ...classroom, student: action.payload.student }
        }
        return classroom
      })
      return {
        ...state,
        classrooms,
      }
    case REMOVE_STUDENT:
      let students = state.students.filter(e => e.id !== action.payload)
      return {
        ...state,
        students,
      }
    default:
      return state
  }
}
