import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { videoUtils, generalUtils } from '@utils';
import { toFixedNumber } from '@utils/helpers';
import { PLAYER_STATE } from '@utils/constants';

import { emitNewPlayerStateToServer } from '@servise/syncAPI';

import './VideoPlayerControls.css';
const { paused, playing } = PLAYER_STATE;
export default class VideoPlayerControls extends Component {
  static propTypes = {
    partyVideoPlayerState: PropTypes.object.isRequired,

    partyId: PropTypes.string.isRequired,
    videoPlayerIsMuted: PropTypes.bool.isRequired,
    videoPlayerIsMaximized: PropTypes.bool.isRequired,
    videoProgress: PropTypes.number.isRequired,
    videoDuration: PropTypes.number,
    handleMuteBtnPressed: PropTypes.func.isRequired,
    handleMaximizeBtnPressed: PropTypes.func.isRequired,
  };

  setClickedTime = (time) => {
    const { classID, partyVideoPlayerState } = this.props;
    const videoIsPlaying = partyVideoPlayerState.playerState === PLAYER_STATE;

    if (time >= 1) {
      emitNewPlayerStateToServer(
        {
          playerState: videoIsPlaying ? playing : paused,
          prevPlayerState: videoIsPlaying ? playing : paused,
          timeInVideo: toFixedNumber(time, 2),
        },
        classID
      );
    } else {
      emitNewPlayerStateToServer(
        {
          playerState: videoIsPlaying ? playing : paused,
          prevPlayerState: videoIsPlaying ? playing : paused,
          timeInVideo: 0,
        },
        classID
      );
    }
  };
  calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector('.progress-bar');
    const barStart = bar.getBoundingClientRect().left + window.scrollX;

    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = this.props.videoDuration / barWidth;

    return timePerPixel * clickPositionInBar;
  };

  handleTimeDrag = (e) => {
    this.setClickedTime(this.calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      this.setClickedTime(this.calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  render() {
    const {
      partyVideoPlayerState,

      classID,
      videoPlayerIsMuted,
      videoPlayerIsMaximized,
      videoProgress,
      videoDuration,
      handleMuteBtnPressed,
      handleMaximizeBtnPressed,
    } = this.props;

    if (!videoDuration) {
      return false;
    }
    const videoIsPlaying = partyVideoPlayerState.playerState === playing;
    const videoIsMuted = videoPlayerIsMuted;
    const videoIsMaximized = videoPlayerIsMaximized;
    const progressBarWidth = this.progressBar ? this.progressBar.offsetWidth : null;
    const progressInSeconds = videoProgress;
    const progressInPixels = videoUtils.secondsToPixels(progressInSeconds, progressBarWidth, videoDuration);

    const formattedProgressString = generalUtils.toHHMMSS(progressInSeconds);

    const playBtnClassNames = classNames('player-btn btn-left fa', {
      'fa-pause': videoIsPlaying,
      'fa-play': !videoIsPlaying,
    });
    const muteBtnClassNames = classNames('player-btn btn-left fa', {
      'fa-volume-off': videoIsMuted,
      'fa-volume-up': !videoIsMuted,
    });
    const maximizeBtnClassNames = classNames('player-btn btn-right fa', {
      'fa-minus-square-o': videoIsMaximized,
      'fa-arrows-alt': !videoIsMaximized,
    });

    return (
      <div
        className='player-controls-overlay'
        onClick={() =>
          emitNewPlayerStateToServer(
            {
              playerState: videoIsPlaying ? paused : playing,
              prevPlayerState: videoIsPlaying ? paused : playing,
              timeInVideo: progressInSeconds,
            },
            classID
          )
        }
      >
        <div className='control-bar bottom' onClick={(event) => event.stopPropagation()}>
          <div
            className='progress-bar'
            ref={(e) => {
              this.progressBar = e;
            }}
            onMouseDown={(e) => this.handleTimeDrag(e)}
          >
            <div className='background-bar'></div>
            <div className='progress-indicator' style={{ left: progressInPixels }}></div>
          </div>

          <div className='control-buttons'>
            <span
              className={playBtnClassNames}
              onClick={() =>
                emitNewPlayerStateToServer(
                  {
                    playerState: videoIsPlaying ? paused : playing,
                    prevPlayerState: videoIsPlaying ? paused : playing,
                    timeInVideo: progressInSeconds,
                  },
                  classID
                )
              }
            />
            <span className={muteBtnClassNames} onClick={handleMuteBtnPressed} />
            <span className='current-time'>{formattedProgressString}</span>

            <span className={maximizeBtnClassNames} onClick={handleMaximizeBtnPressed} />
          </div>
        </div>
      </div>
    );
  }
}
