import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import { formatDateToTemplate } from '@utils/helperst'

import { IStudentProfile } from '@models/IProfile'

import { fetchStudentProfileByUid, saveHomeWork } from '@servise'

import { RootState } from '@store'
import { failedFetchingHomeWorks } from '@store/homework/actions'

import { CustomMaterialCheckbox, Button, Text } from '@components'
import TopContent from '../TopContent'

import { ErrorText } from '@styles/elements'
import * as Styled from './add-homework-form.styles'
import infoIcon from '@assets/icons/info.svg'
import RadioCheckedIcon from '@components/Student/Icons/RadioCheckedIcon'
import RadioUnCheckedIcon from '@components/Student/Icons/RadioUnCheckedIcon'

interface Props {
  cancelHandler: () => void
}

const AddHomeworkForm = ({ cancelHandler }: Props) => {
  const classRoomData = useSelector((state: RootState) => state.classroom.classroomData)
  const loading = useSelector((state: RootState) => state.homework.loading)
  const error = useSelector((state: RootState) => state.homework.error)

  const [student, setStudent] = useState<IStudentProfile>(null)
  const [title, setTitle] = useState('Задание:')

  const textarea = document.querySelector('#hwDescription')

  const dispatch = useDispatch()
  const currentRef = useRef(null)

  useEffect(() => {
    fetchStudentProfileByUid(classRoomData.student_uid).then(student => setStudent(student))
  }, [classRoomData.student_uid, dispatch])

  useEffect(() => {
    function autosize() {
      const el = this
      setTimeout(function() {
        el.style.cssText = 'height:auto;'
        el.style.cssText = 'height:' + el.scrollHeight + 'px'
      }, 0)
    }

    if (textarea) {
      const textarea = document.querySelector('#hwDescription')

      textarea.addEventListener('keydown', autosize)

      return () => {
        textarea.removeEventListener('keydown', autosize)
      }
    }
  }, [textarea])

  const formik = useFormik({
    initialValues: {
      description: '',
      isWriting: false,
      isAudio: false,
    },
    validateOnChange: false,
    validate: values => {
      if (!values.description) {
        dispatch(failedFetchingHomeWorks('Введите описание задания'))
      }
    },
    onSubmit: values => {
      if (!error) {
        dispatch(saveHomeWork(values)).then(() => cancelHandler())
      }
    },
  })

  const isDisabled = useMemo<boolean>(() => {
    const { description, isAudio, isWriting } = formik.values
    if (description === '' || (!isAudio && !isWriting)) {
      return true
    }
    return false
  }, [formik])

  const handleChange = (eventOrPath: string | React.ChangeEvent<any>) => {
    dispatch(failedFetchingHomeWorks(''))
    formik.handleChange(eventOrPath)
  }
  const handleChangeCheckbox = e => {
    dispatch(failedFetchingHomeWorks(''))
    if (e.target.name === 'Сочинение') {
      formik.setFieldValue('isWriting', !formik.values.isWriting)
      formik.setFieldValue('isAudio', false)
    } else if (e.target.name === 'На произношение') {
      formik.setFieldValue('isWriting', false)
      formik.setFieldValue('isAudio', !formik.values.isAudio)
    }
  }

  useEffect(() => {
    if (formik.values.isWriting) {
      setTitle('Задание на сочинение:')
    } else if (formik.values.isAudio) {
      setTitle('Задание на произношение:')
    } else {
      setTitle('Задание:')
    }
  }, [formik.values.isWriting, formik.values.isAudio])

  return (
    <Styled.Container>
      <TopContent
        className={classRoomData.name}
        studentName={student ? student.name + ' ' + student.lastName : null}
        studentAvatar={student ? student.avatar : null}
        student={student}
      />
      <Text display="addHomeworkContent">{formatDateToTemplate()}</Text>

      <Styled.Center>
        <Text display="addHomeworkContent">{title}</Text>
        <Styled.Input
          id="hwDescription"
          name="description"
          rows="1"
          onChange={handleChange}
          ref={currentRef}
          value={formik.values.description}
          placeholder="Начните писать здесь..."
        />

        <Styled.EssayCheckBoxWrapper>
          <CustomMaterialCheckbox
            name="Сочинение"
            onChange={e => handleChangeCheckbox(e)}
            checked={formik.values.isWriting}
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioUnCheckedIcon />}
          />
          <img src={infoIcon} alt="Essay information" />
          <div className="menu-text">
            Сочинение с дальнейшей проверкой. Когда ученик выполнит задание, вам придёт уведомление
          </div>
        </Styled.EssayCheckBoxWrapper>
        <Styled.EssayCheckBoxWrapper toolTipLeft={'170'} toolTipTop={'-50'}>
          <CustomMaterialCheckbox
            name="На произношение"
            onChange={e => handleChangeCheckbox(e)}
            checked={formik.values.isAudio}
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioUnCheckedIcon />}
          />

          <img src={infoIcon} alt="Audio information" />
          <div className="menu-text">Ученик запишет свою речь  и отправит вам на проверку</div>
        </Styled.EssayCheckBoxWrapper>
      </Styled.Center>

      <Styled.Footer>
        {Boolean(error) && <ErrorText>{error}</ErrorText>}
        <Button
          whiteTheme
          loading={loading}
          disabled={isDisabled}
          children="Отправить"
          customStyles={{
            width: '200px',
            pointerEvents: 'unset',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            backgroundColor: isDisabled && '#fff',
            color: isDisabled && '#c4c4c4',
            border: isDisabled && '3px solid #c4c4c4',
          }}
          onClick={formik.handleSubmit}
        />
        <button
          style={{ marginTop: 20, width: '200px' }}
          children="Отмена"
          type="button"
          onClick={cancelHandler}
        />
      </Styled.Footer>
    </Styled.Container>
  )
}

export default AddHomeworkForm
