import CustomMaterialCheckbox from '@components/Checkbox/CustomMaterialCheckbox'
import styled from 'styled-components'

export const FlexWrapper = styled.div`
  display: flex;
`

export const HomeWorkCardContainer = styled.div<{ isNew: boolean; isStart: boolean }>`
  width: 600px;

  background: ${props => (props.isStart ? '#FFF2E7' : '#FFFFFF')};
  /* card new */

  box-shadow: 0px 2px 10px
    ${props => (props.isNew ? 'rgba(255, 146, 45, 0.5)' : 'rgba(0, 0, 0, 0.08)')};
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  .closeDiv {
    &:hover {
      .closeBtn {
        opacity: 1;
      }
    }
  }
  &:hover {
    .closeBtn {
      display: block;
      opacity: 0.5;
    }
  }
`

export const CloseBtn = styled.div`
  position: absolute;
  right: 0px;
  top: -5px;
`
export const CancelBtn = styled.img`
  display: none;
  cursor: pointer;
`

export const Button = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  height: 40px;
  border: 1px solid #c2cfe0;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Grey middle_ default text */

  color: #7e92ac;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  button {
    height: 40px;
    padding: 0 15px;
  }
`

export const Checkbox = styled(CustomMaterialCheckbox)`
  & .MuiButtonBase-root {
    margin-right: 10px;
  }
  & .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: rgba(25, 42, 62, 1);
    letter-spacing: 0.01em;
  }
  & svg {
    width: 20px;
    height: 20px;
  }
  & rect {
    fill: #ff922d;
    stroke: #ff922d;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const FooterButtonWrapper = styled.div`
  position: relative;

  &:hover {
    .menu-text {
      display: block;
    }
  }

  .menu-text {
    display: none;
    position: absolute;
    background: rgba(65, 71, 82, 0.8);
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    padding: 10px 15px;
    z-index: 10;
    top: 0;
    left: 135px;
    white-space: nowrap;
    box-sizing: border-box;
  }
`

export const WritingInputWrapper = styled.div<{ originalText?: boolean }>`
  background-color: ${props => (props.originalText ? '#F3F5F9' : '#fff0e3')};
  padding: 15px;
  ${props => !props.originalText && `border: 1px solid #d9bba0;`}
  border-radius: 20px;
  margin-top: 20px;
  position: relative;
`

export const WritingInputHeader = styled.div<{ hide?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: #4d6084;
  cursor: pointer;
  svg {
    margin-left: 10px;
    ${props => props.hide && `transform: rotate(270deg)`}
  }
`

export const WritingInput = styled.div<{ originalText?: boolean }>`
  background-color: ${props => (props.originalText ? '#F3F5F9' : '#fff0e3')};
  background-image: ${props =>
    props.originalText
      ? '-webkit-linear-gradient(right, transparent, transparent 1px, #F3F5F9 1px), -webkit-linear-gradient(top, transparent, transparent 29px, #7E92AC 0)'
      : '-webkit-linear-gradient(right, transparent, transparent 1px, #fff0e3 1px), -webkit-linear-gradient(top, transparent, transparent 29px, #e2ad8c 0)'};

  background-size: 3px 1px, 94% 30px;
  background-attachment: local;
  box-sizing: border-box;
  width: 100%;

  color: ${props => props.theme.textColors.mainContent};
  font-size: ${props => props.theme.fontSizes.medium};

  line-height: 30px;
  font-style: normal;
  font-weight: normal;
  outline: none;
  min-height: 65px;
  white-space: pre-wrap;

  &:empty::before {
    content: attr(placeholder);
    color: #414752;
  }
`
export const ClosePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  // z-index: 0
  z-index: 111;
  .mainDiv {
    box-sizing: border-box;
    border-radius: 16px;
    background: white;
    padding: 40px 60px;
    text-align: center;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .popupBtn {
      border-radius: 50px;
      width: 200px;
      height: 50px;
      margin: 10px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 3px solid transparent;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(270.29deg, #ff922d 18.37%, #ffb800 99.74%) border-box;
      border-radius: 25px;
      color: #ff922d;
      font-weight: 600;
      font-size: 18px;
      &:hover {
        color: #ffb800;
        border: 3px solid #ffb800;
      }
    }
  }
`
export const AudioButton = styled.div<{ isSend: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  left: ${props => !props.isSend && '12px'};
  position: ${props => !props.isSend && 'absolute'};
  margin-top: ${props => props.isSend && '20px'};
  width: 70%;
  bottom: 18px;

  .volume-icon {
    display: none;
  }
  /* .timer {
    position: absolute;
    right: 8px;
    top: 8px;
  } */
  canvas {
    width: 75%;
  }
  .delete-btn {
    svg {
      path {
        fill: #7e92ac;
      }

      width: 16;
      height: 17;
    }
  }

  span {
    font-size: 14px;
    line-height: 19px;
    color: #7e92ac;
    margin: 0px 10px;
  }
  input {
    display: none;
  }
`
