import React from 'react';

const ConstructorIconActive = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 17.4102V27.0002H18.59L16.59 25.0002H11V19.4102L9 17.4102Z" fill="white"/>
      <path d="M34.87 32.29L32 29.38V32H4.00002V27.85H6.00002V26.25H4.00002V19.6H6.00002V18H4.00002V11.6H6.00002V9.99996H4.00002V4.40996L19.94 20.26V17.44L3.71002 1.28996C3.57015 1.14893 3.39145 1.05275 3.1967 1.01368C3.00195 0.974607 2.79998 0.994419 2.61654 1.07059C2.43309 1.14675 2.27649 1.27582 2.16668 1.44134C2.05688 1.60686 1.99886 1.80133 2.00002 1.99996V33C2.00002 33.2652 2.10537 33.5195 2.29291 33.7071C2.48045 33.8946 2.7348 34 3.00002 34H34.16C34.3586 34.0011 34.5531 33.9431 34.7186 33.8333C34.8841 33.7235 35.0132 33.5669 35.0894 33.3834C35.1656 33.2 35.1854 32.998 35.1463 32.8033C35.1072 32.6085 35.011 32.4298 34.87 32.29Z" fill="white"/>
      <path d="M24 29.9999H28C28.5304 29.9999 29.0391 29.7892 29.4142 29.4141C29.7893 29.0391 30 28.5304 30 27.9999V8.69992L27.7 4.46992C27.5221 4.16174 27.2652 3.9066 26.9559 3.73081C26.6465 3.55503 26.2958 3.46497 25.94 3.46992C25.5761 3.47281 25.22 3.5749 24.9098 3.76521C24.5997 3.95552 24.3474 4.22683 24.18 4.54992L22 8.71992V27.9999C22 28.5304 22.2107 29.0391 22.5858 29.4141C22.9609 29.7892 23.4696 29.9999 24 29.9999ZM24 9.19992L25.94 5.42992L28 9.20992V23.9999H24V9.19992ZM24 25.6299H28V28.0699H24V25.6299Z" fill="white"/>
    </svg>
  );
};

export default ConstructorIconActive;
