import React from 'react'
export default function CounterZeroIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26" fill="none">
      <circle cx="3" cy="3" r="3" fill="#C2CFE0" />
      <circle cx="3" cy="13" r="3" fill="#C2CFE0" />
      <circle cx="3" cy="23" r="3" fill="#C2CFE0" />
    </svg>
  )
}
