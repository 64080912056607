import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Button from '@components/common/Button'

import Image from '@assets/images/blocks/Video/youtube.svg'
import Cross from '@assets/images/blocks/cross.svg'
import Eye from '@assets/images/blocks/eye.svg'
import EyeOff from '@assets/images/blocks/eye-off.svg'
import ReactPlayer from 'react-player'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(197, 207, 221, 1)',
    color: '#414752',
    fontSize: 10,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
  },
}))(Tooltip)

const VideoBlock = ({ onStateChange, id, preloadedState }) => {
  const ref = useRef(null)
  const initialState = {
    isVisDesc: false,
    isForStdnt: true,
    videoLink: '',
    description: '',
    submitted: false,
  }

  const [state, setState] = useState(preloadedState || initialState)

  useEffect(() => {
    onStateChange(id, state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('keydown', ({ key }) => {
        if (key === 'Enter') {
          setState(prevState => {
            return {
              ...prevState,
              submitted: true,
            }
          })
        }
      })
    }
  }, [ref])

  const handleDescription = e => {
    e.stopPropagation()
    setState({
      ...state,
      isVisDesc: true,
    })
  }

  const handleCloseDescription = e => {
    e.stopPropagation()
    setState({
      ...state,
      isVisDesc: false,
    })
  }

  const handleShowForStudent = e => {
    e.stopPropagation()
    setState({
      ...state,
      isForStdnt: !state.isForStdnt,
    })
  }

  const handleChangeDescription = e => {
    e.stopPropagation()
    setState({
      ...state,
      description: e.target.value,
    })
  }

  const handleChangeVideoLink = e => {
    setState({
      ...state,
      videoLink: e.target.value,
    })
  }

  return (
    <div className="aaa">
      {!state.submitted ? (
        <>
          <img src={Image} className="block-icon" alt="" />
          <span className="block-title">Добавить видео</span>
          <input
            onChange={handleChangeVideoLink}
            className="block-description-input"
            type="text"
            placeholder="Вставьте ссылку на видео Youtube, Vimeo"
            ref={ref}
            style={{
              marginBottom: 10,
            }}
            value={state.videoLink}
          />
          {state.isVisDesc ? (
            <div className="block-description-area-container">
              <textarea
                className="block-description-area"
                placeholder="Описание видео"
                onChange={handleChangeDescription}
                value={state.description}
              />
              <div className="block-actions-area">
                <img
                  onClick={handleCloseDescription}
                  src={Cross}
                  alt=""
                  style={{ marginBottom: 22 }}
                />
                {state.isForStdnt ? (
                  <HtmlTooltip title="Текст виден ученику" placement="left">
                    <img onClick={handleShowForStudent} src={Eye} alt="" />
                  </HtmlTooltip>
                ) : (
                  <HtmlTooltip
                    className="block-tooltip"
                    title="Текст не виден ученику"
                    placement="left"
                  >
                    <img onClick={handleShowForStudent} src={EyeOff} alt="" />
                  </HtmlTooltip>
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 30,
          }}
        >
          <ReactPlayer url={state.videoLink} controls />
          <img
            onClick={() => {
              setState({
                ...state,
                submitted: false,
                videoLink: '',
              })
            }}
            src={Cross}
            alt=""
            style={{ marginLeft: 10 }}
          />
        </div>
      )}
      {state.isVisDesc && state.submitted ? (
        <div style={{ marginTop: 10 }} className="block-description-area-container">
          <textarea
            className="block-description-area"
            placeholder="Описание видео"
            onChange={handleChangeDescription}
            value={state.description}
          />
          <div className="block-actions-area">
            <img onClick={handleCloseDescription} src={Cross} alt="" style={{ marginBottom: 22 }} />
            {state.isForStdnt ? (
              <HtmlTooltip title="Текст виден ученику" placement="left">
                <img onClick={handleShowForStudent} src={Eye} alt="" />
              </HtmlTooltip>
            ) : (
              <HtmlTooltip
                className="block-tooltip"
                title="Текст не виден ученику"
                placement="left"
              >
                <img onClick={handleShowForStudent} src={EyeOff} alt="" />
              </HtmlTooltip>
            )}
          </div>
        </div>
      ) : null}
      {!state.isVisDesc ? (
        <Button className="block-action" onClick={handleDescription}>
          Добавить описание
        </Button>
      ) : null}
    </div>
  )
}

export default VideoBlock
