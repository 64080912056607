/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import moment from 'moment'

import { IProfile, IStudentProfile } from '@models/IProfile'
import DefaultAvatar from '@components/Icons/DefaultAvatar.svg'
import * as Styled from '../student/AutoTeacherSelection/ResultTeachers/result-teachers.styles'
import AudioPlayerWaveForm from '@components/Student/TeacherCard/AudioPlayerWaveForm'
import { BUSINESS_HOURS, INTERESTS, TZ_MSK, WEEKS } from '@utils/constants'
import { IEvent } from '@models/ICalendar'
import { getTeacherCalendarEvents } from '@servise/calendarAPI'
import { getAvailableBusinessHours } from '@pages/student/AutoTeacherSelection/ResultTeachers/TeacherInfoCard'
import heart from '@assets/icons/heart.svg'
import { Params } from './DetailsTeacherInfo'
import { getExperienceDescription } from '@utils/countExpYearsHelper'
import { calendarUtil } from '@utils/calendarUtil'
import { RootState } from '@store/index'
import { useSelector } from 'react-redux'

const TeacherItemContainer = styled(Styled.TeacherItemContainer)`
  max-height: 700px;
  width: 991px;
`

const TeacherTimeContainer = styled(Styled.TeacherTimeContainer)`
  max-height: 100%;
  overflow-y: auto;
`
const TimeItem = styled(Styled.TimeItem)`
  cursor: default;
  :hover {
    color: inherit;
  }
`

const OpenButton = styled.button`
  margin-top: 10px;
  font-size: 14px;
  line-height: 150%;
  width: 97px;
  font-weight: 700;
  color: #fff;
  background: #4284ff;
  border-radius: 10px;
  padding: 8px 16px;
`

const SingleTeacherInfo = ({ teacher }: { teacher: IProfile }) => {
  const { t, i18n } = useTranslation()
  const [events, setEvents] = useState<IEvent[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const params = useParams<Params>()
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const defaultDate = useMemo(() => {
    return moment().add(2, 'days')
  }, [])

  const expDesc = useMemo(() => {
    return getExperienceDescription(teacher.teachingExperience, i18n.language, t)
  }, [i18n.language, teacher.teachingExperience, t])

  useEffect(() => {
    async function getData() {
      setIsLoading(true)
      const events = await getTeacherCalendarEvents(teacher?.id, TZ_MSK)

      let allEvents = calendarUtil.getAllEvents(events, TZ_MSK, false)

      setEvents(allEvents)
      setIsLoading(false)
    }
    getData()
  }, [teacher?.id])

  const studentBusinessHours = useMemo(() => {
    return BUSINESS_HOURS.map(item => ({
      ...item,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    }))
  }, [])

  const businessHours = useMemo(() => {
    let filteredBusinessHours = getAvailableBusinessHours(
      studentBusinessHours,
      teacher.businessHours,
      teacher.timeZone,
      TZ_MSK
    )

    filteredBusinessHours = filteredBusinessHours.map(item => {
      const daysOfWeek = item.daysOfWeek.filter(day => {
        const diffDays = moment(defaultDate)
          .days(day)
          .diff(defaultDate, 'days')

        const dtstart = moment(
          moment(defaultDate)
            .day(diffDays < 0 ? 7 + day : day)
            .format('YYYY-MM-DD') +
            ' ' +
            item.startTime
        ).format('YYYY-MM-DD HH:mm')

        return (
          events.filter(event => {
            const diff = moment(dtstart).diff(
              moment(event.rrule.dtstart).format('YYYY-MM-DD HH:mm'),
              'minutes'
            )

            return diff > -60 && diff < 60
          }).length === 0
        )
      })

      return { ...item, daysOfWeek }
    })
    return filteredBusinessHours.filter(item => item.daysOfWeek.length > 0)
  }, [studentBusinessHours, teacher.businessHours, events, defaultDate])

  if (businessHours.length === 0 || isLoading) return null

  const handleOpenButton = () => {
    history.push(`/teachersInfo/${teacher.id}`, { teacher: { ...teacher } })
  }

  return (
    <TeacherItemContainer
      edit={params.teacherId && true}
      style={params.teacherId && { flexDirection: 'column' }}
    >
      <Styled.TeacherInfoContainer>
        <Styled.TeacherBasicInfo>
          <Styled.TeacherAvatar
            style={{
              backgroundImage: `url(${teacher.avatar ? teacher.avatar : DefaultAvatar})`,
            }}
          />
          <Styled.FullNameLabel>{`${teacher.name}`}</Styled.FullNameLabel>
          <Styled.CountryLabel>{teacher.cityAddress}</Styled.CountryLabel>
          {!params.teacherId && <OpenButton onClick={handleOpenButton}>{t('Open')}</OpenButton>}
        </Styled.TeacherBasicInfo>
        <Styled.AdditionalInfoContainer style={params.teacherId && { flex: 1 }}>
          {teacher.keyFeatures?.length > 0 && (
            <Styled.AdditionalInfoItem>
              <Styled.Subtitle>{t('SuperAbilities')}</Styled.Subtitle>
              <Styled.SuperAbilities>
                {teacher.keyFeatures?.map(
                  (val, index) =>
                    val && (
                      <li key={val} style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                        <img src={heart} alt="heart" />
                        {val.charAt(0).toUpperCase() + val.slice(1)}
                      </li>
                    )
                )}
                <li style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                  <img src={heart} alt="heart" />
                  {expDesc}
                </li>
              </Styled.SuperAbilities>
            </Styled.AdditionalInfoItem>
          )}

          {teacher.aboutMe?.length > 0 && (
            <>
              <Styled.Subtitle>{t('AboutMe')}</Styled.Subtitle>
              <Styled.AboutMe>
                {teacher.aboutMe.charAt(0).toUpperCase() + teacher.aboutMe.slice(1)}
              </Styled.AboutMe>
            </>
          )}

          {teacher.audio && (
            <AudioPlayerWaveForm
              audio={teacher.audio}
              isProfile
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
                width: 376,
                marginRight: 20,
              }}
            />
          )}

          {teacher.interests?.length > 0 && (
            <>
              <Styled.Subtitle>{t('Interests')}</Styled.Subtitle>
              <Styled.InterestsContainer>
                {INTERESTS.map(i => {
                  if (!teacher?.interests?.includes(i.id)) return null
                  return <Styled.InteresItem key={i.id}>{t(i.name)}</Styled.InteresItem>
                })}
              </Styled.InterestsContainer>
            </>
          )}
        </Styled.AdditionalInfoContainer>
      </Styled.TeacherInfoContainer>
      {params.teacherId && (
        <Styled.TeacherResultTitle
          style={{ padding: '40px 0 20px 0', marginLeft: 'auto', marginRight: 'auto' }}
        >
          {t('Schedule')}
        </Styled.TeacherResultTitle>
      )}
      <TeacherTimeContainer
        style={
          params.teacherId && { flexGrow: 1, width: '100%', border: 'none', overflow: 'inherit' }
        }
      >
        <Styled.WeekContainer>
          {WEEKS.map((week, index) => {
            const weekDay = moment(defaultDate)
              .add(index, 'days')
              .weekday()

            return (
              businessHours.filter(
                hours =>
                  hours.daysOfWeek.includes(weekDay + 1) &&
                  studentBusinessHours.find(
                    item =>
                      item.startTime === hours.startTime && item.daysOfWeek.includes(weekDay + 1)
                  )
              ).length > 0 && (
                <Styled.DayItem key={week.value}>
                  <Styled.DayItemLabel>
                    {moment(defaultDate)
                      .add(index, 'days')
                      .format('dddd, DD.MM.YYYY')}
                  </Styled.DayItemLabel>

                  <Styled.TimeItems>
                    {businessHours.map(hours => {
                      if (!hours.daysOfWeek.includes(weekDay + 1)) {
                        return null
                      }
                      return <TimeItem key={hours.startTime}>{hours.startTime}</TimeItem>
                    })}
                  </Styled.TimeItems>
                </Styled.DayItem>
              )
            )
          })}
        </Styled.WeekContainer>
      </TeacherTimeContainer>
    </TeacherItemContainer>
  )
}

export default SingleTeacherInfo
