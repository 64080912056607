import React from 'react';

import { ColorStyleMap, StyleShape, StyleType } from './types';

export const INLINE_STYLES: StyleShape[] = [
  { style: StyleType.bold },
  { style: StyleType.italic },
];

export const COLORS: StyleShape[] = [
  { style: StyleType.darkGrey, color: '#404753' },
  { style: StyleType.darkBlue, color: '#113A7B' },
  { style: StyleType.blue, color: '#0053D7' },
  { style: StyleType.lightBlue, color: '#2C82FF' },
  { style: StyleType.grey, color: '#8CA4C0' },
  { style: StyleType.orange, color: '#FF8C00' },
  { style: StyleType.red, color: '#DC0000' },
];

export const colorStyleMap: ColorStyleMap = {
  darkGrey: { color: '#404753' },
  darkBlue: { color: '#113A7B' },
  blue: { color: '#0053D7' },
  lightBlue: { color: '#2C82FF' },
  grey: { color: '#8CA4C0' },
  orange: { color: '#FF8C00' },
  red: { color: '#DC0000' },
};

export const backgroundColors: string[] = [
  '#fff',
  '#F3F5F9',
  '#BFCFE2',
  '#CCE1FF',
  '#B5D3FF',
  '#FFE2C5',
  '#FFD70B',
];
