import { getDiff } from '@utils/homeWorkHelpers'
import React, { useEffect, useRef, useState } from 'react'
import * as Styled from './homework-card.styles'

const HomeworkTextPreview = ({
  header,
  writing,
  isOriginalText,
  teacherWriting,
  checkedText,
  hideBlock,
}: {
  writing: string
  header: string
  isOriginalText?: boolean
  checkedText?: string
  teacherWriting?: string
  hideBlock?: boolean
}) => {
  const [hide, setHide] = useState(() => hideBlock)
  const completedWritingRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if ((teacherWriting || checkedText) && completedWritingRef.current)
      getDiff(
        writing,
        teacherWriting ||
          (!teacherWriting && checkedText.replace(/(\r\n|\n|\r)/gm, '')) ||
          (!teacherWriting && checkedText),
        completedWritingRef
      )
  }, [teacherWriting, checkedText])

  return (
    <Styled.WritingInputWrapper originalText={isOriginalText}>
      <Styled.WritingInputHeader hide={hide} onClick={() => setHide(prev => !prev)}>
        {header}
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L11.3033 1.75736C11.5962 1.46447 11.5962 0.989592 11.3033 0.696699C11.0104 0.403806 10.5355 0.403806 10.2426 0.696699L6 4.93934L1.75736 0.696699C1.46447 0.403806 0.989592 0.403806 0.696699 0.696699C0.403806 0.989593 0.403806 1.46447 0.696699 1.75736L5.46967 6.53033ZM5.25 5L5.25 6L6.75 6L6.75 5L5.25 5Z"
            fill="#4D6084"
          />
        </svg>
      </Styled.WritingInputHeader>
      {!hide && (
        <Styled.WritingInput
          originalText={isOriginalText}
          style={{ marginTop: 10 }}
          ref={completedWritingRef}
        >
          {writing}
        </Styled.WritingInput>
      )}
    </Styled.WritingInputWrapper>
  )
}
export default HomeworkTextPreview
