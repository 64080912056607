import { createReducer } from '@reduxjs/toolkit'

import {
  GET_LESSONS_FULFILLED,
  GET_LESSONS_REJECTED,
  CREATE_LESSON_REJECTED,
  DELETE_LESSON_REJECTED,
  DUPLICATE_LESSON_REJECTED,
  RESET_LESSONS,
  UPDATE_LESSONS,
  EDIT_COURSE_FULFILLED,
} from '@types/course'

const courseReducer = createReducer(
  {
    lessonsList: [],
    currentCourse: null,
    error: null,
  },
  {
    [GET_LESSONS_FULFILLED]: (
      state,
      { payload: { currentCourse, lessons } }
    ) => ({
      ...state,
      lessonsList: lessons,
      currentCourse,
      error: null,
    }),
    [GET_LESSONS_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [CREATE_LESSON_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DELETE_LESSON_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [DUPLICATE_LESSON_REJECTED]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [RESET_LESSONS]: state => ({
      ...state,
      lessonsList: [],
      currentCourse: null,
    }),
    [UPDATE_LESSONS]: (state, { payload: { lessons } }) => ({
      ...state,
      lessonsList: lessons,
    }),
    [EDIT_COURSE_FULFILLED]: (state, { payload: { currentCourse } }) => ({
      ...state,
      currentCourse,
    }),
  }
)

export default courseReducer
