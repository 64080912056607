import {
  ADD_FILE_TO_CLASSROOM,
  ClassroomActionsTypes,
  CLEAR_DISPLAYED_COURSE,
  CREATE_CLASSROOM,
  DELETE_CLASSROOM,
  DELETE_FILE_FROM_CLASSROOM,
  SET_CLASSROOM,
  SET_CLASSROOM_FILES,
  SET_CLASSROOMS,
  SET_DISPLAYED_COURSE,
  SET_LOADING_STUDENT_PAGE,
  SET_PUBLISHED_COURSES,
  SET_STUDENT,
  SET_STUDENT_CURRENT_COURSE,
  SET_STUDENTS,
  UPDATE_CLASSROOM,
  SET_STUDENT_DATA_IN_CLASSROOOM,
  SET_STUDENT_DATA_CLASSROOMS,
  REMOVE_STUDENT,
} from './types'
import { IClassroom, IClassroomBlock, IPublishedCourse } from '@models/IClassroom'
import { IProfile, IStudentProfile } from '@models/IProfile'
import { ICurrentCourseInfo } from '../../models'

export const setClassroomsAction = (classrooms: IClassroom[]): ClassroomActionsTypes => {
  return {
    type: SET_CLASSROOMS,
    payload: classrooms,
  }
}

export const setClassroomAction = (classroomData: IClassroom): ClassroomActionsTypes => {
  return {
    type: SET_CLASSROOM,
    payload: classroomData,
  }
}

export const setClassroomFilesAction = (files: IClassroomBlock[]): ClassroomActionsTypes => {
  return {
    type: SET_CLASSROOM_FILES,
    payload: files,
  }
}

export const createClassroomAction = (classroomData: IClassroom): ClassroomActionsTypes => {
  return {
    type: CREATE_CLASSROOM,
    payload: classroomData,
  }
}
export const updateClassroomAction = (classroomData: IClassroom): ClassroomActionsTypes => {
  return {
    type: UPDATE_CLASSROOM,
    payload: classroomData,
  }
}

export const deleteClassroomAction = (classID: string): ClassroomActionsTypes => {
  return {
    type: DELETE_CLASSROOM,
    payload: classID,
  }
}

export const addFileToClassroomAction = (fileData: IClassroomBlock): ClassroomActionsTypes => {
  return {
    type: ADD_FILE_TO_CLASSROOM,
    payload: fileData,
  }
}

export const deleteFileFromClassroomAction = (fileID: string): ClassroomActionsTypes => {
  return {
    type: DELETE_FILE_FROM_CLASSROOM,
    payload: fileID,
  }
}

export const setStudents = (students: IStudentProfile[]): ClassroomActionsTypes => ({
  type: SET_STUDENTS,
  payload: students,
})
export const setStudentDataInClassroomAction = (data: IStudentProfile): ClassroomActionsTypes => {
  return {
    type: SET_STUDENT_DATA_IN_CLASSROOOM,
    payload: data,
  }
}

export const setStudentDataClassroomsAction = (data: {
  id: string
  student: IStudentProfile
}): ClassroomActionsTypes => {
  return {
    type: SET_STUDENT_DATA_CLASSROOMS,
    payload: data,
  }
}

export const setStudent = (student: IProfile): ClassroomActionsTypes => ({
  type: SET_STUDENT,
  payload: student,
})

export const setPublishedCoursesAction = (
  publishedCourses: IPublishedCourse[]
): ClassroomActionsTypes => ({
  type: SET_PUBLISHED_COURSES,
  payload: publishedCourses,
})

export const setDisplayedCourseAction = (
  publishedCourse: IPublishedCourse
): ClassroomActionsTypes => ({
  type: SET_DISPLAYED_COURSE,
  payload: publishedCourse,
})

export const clearDisplayedCourseAction = (): ClassroomActionsTypes => ({
  type: CLEAR_DISPLAYED_COURSE,
})

export const setLoadingStudentPage = (isLoadingStudentPage: boolean): ClassroomActionsTypes => ({
  type: SET_LOADING_STUDENT_PAGE,
  payload: isLoadingStudentPage,
})

export const setStudentCurrentCourse = (courseInfo: ICurrentCourseInfo): ClassroomActionsTypes => ({
  type: SET_STUDENT_CURRENT_COURSE,
  payload: courseInfo,
})

export const removeStudent = (id: IStudentProfile['id']): ClassroomActionsTypes => ({
  type: REMOVE_STUDENT,
  payload: id,
})
