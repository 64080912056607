import React from 'react'
import Spinner from 'react-spinkit'

import './loader.sass'

function Loader(props) {
  return (
    <div className="loader">
      <Spinner
        name={props.name || 'folding-cube'}
        color="orange"
        fadeIn="none"
      />
    </div>
  )
}

export default Loader
