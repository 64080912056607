import React, {useRef} from 'react';
import {useDrag, useDrop} from "react-dnd";

import homeActive from '@assets/images/blocks/is-homework.svg';

const MyComponent = ({ id, index, currentPage, setCurrentPage, name, moveCard, isHomework }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'page',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex, item.id);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'page', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div onClick={() => setCurrentPage(id)} className="page-block"
         ref={ref}
         style={{
           background: id === currentPage ? '#fff' : '',
           opacity
         }}>
      {
        name ? (
          <span className="first">
                  {name}
                </span>
        ) : (
          <span className="first">
                  <br/>
                </span>
        )
      }
      {isHomework && <img className="homework-page-icon" src={homeActive} alt="Homework page" />}
    </div>
  );
};

export default MyComponent;
