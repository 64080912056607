import styled, { css } from 'styled-components'
export const TeacherResultContainer = styled.div<{ isTeacherInfo: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1015px;

  ${props =>
    !props.isTeacherInfo &&
    css`
      position: absolute;
    `}
`
export const TeacherResultTitle = styled.h2`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;
`
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 30px;
  gap: 20px;
  width: 100%;
`
export const TeachersList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;

  height: calc(100vh - 220px) !important;
  padding-right: 15px;
  margin-top: 30px;
  ::-webkit-scrollbar {
    width: 7px;
    padding-bottom: 30px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }
`
export const Button = styled.button<{ bgWhite?: boolean }>`
  padding: 10px 14px;
  ${props => (props.bgWhite ? `border: 3px solid #E1ECFF;` : `background: #E1ECFF;`)}
  border-radius: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Active_platform */

  color: #336a93;
  margin-top: 20px;
`
export const TeacherItemContainer = styled.div<{ edit?: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  max-height: 700px;

  border: ${props => (props.edit ? 'none' : '1px solid #9abdfc')};
  border-radius: 25px;
  margin-top: ${props => (props.edit ? '0px' : '30px')};
  :first-child {
    margin-top: 0px;
  }
`
export const TeacherInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
`

export const TeacherTimeContainer = styled.div`
  box-sizing: border-box;
  width: 272px;
  min-width: 272px;
  padding: 30px 20px 30px 30px;

  border-left: 1px solid #9abdfc;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;

    padding-bottom: 30px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }
`

export const TeacherBasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TeacherAvatar = styled.div`
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 15px;
`

export const FullNameLabel = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* Headline dark */

  color: #4d6084;
`

export const CountryLabel = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Headline dark */

  color: #4d6084;

  opacity: 0.5;
  margin-top: 6px;
  display: inline-block;
  width: 160px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 76px;
`

export const AdditionalInfoItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const Subtitle = styled.h6`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Headline dark */

  color: #4d6084;
  margin-bottom: 10px;
`

export const SuperAbilities = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  gap: 10px;
  li::marker {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='24' width='24'><path d='M1.88767.03191l712.3337l12.11247.03191c12.68076.44254135.64318134.80969c133.0740311.64321.666999.969541.66699c9.165811.666998.395011.99817.826692.58747l73.44477l6.173312.58747c5.604991.99814.834181.666994.030461.66699c2.356781.6669913.0740314.80969c15.643181.319286.442541.88767.03191z' fill='#c2cfe0' stroke='#c2cfe0' strokeWidth='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  }
`

export const AboutMe = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin-bottom: 20px;
  max-height: max-content;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;

    padding-bottom: 30px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }
`

export const InterestsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  max-height: max-content;
  height: fit-content;

  ::-webkit-scrollbar {
    width: 4px;
    padding-bottom: 30px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 10px;
  }
  ::first-letter {
    text-transform: capitalize;
  }
`

export const InteresItem = styled.div<{ active?: boolean }>`
  ${props => props.active && `border: 2px solid rgba(154, 189, 252, 0.5);`}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: rgba(225, 236, 255, 0.5);

  border-radius: 10px;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Active_platform */

  color: #336a93;
`

export const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
  box-sizing: border-box;
  width: fit-content;
  background: #ffffff;

  /* Back_Grey_1 */

  border: 1px solid #9abdfc;
  border-radius: 0px 0px 16px 16px;
  gap: 6px;
  border-top: none;
  margin-top: -5px;
`

export const DropdownItem = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding: 4px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  /* Text_dark */

  color: #414752;
  word-break: break-word;

  :hover {
    background: #e1ecff;
  }
`

export const DropdownLabel = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #9abdfc;
  border-radius: ${props => (props.open ? ' 16px 16px  0px 0px' : '20px')};
  box-sizing: border-box;
  background: #ffffff;
  padding: 6px 15px;
  width: fit-content;
  margin-top: 10px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    /* Text_dark */

    color: #414752;
  }
  ${props => props.open && ` border-bottom: none`};
`

export const DropdownContainer = styled.div``

export const DropDownTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Grey middle_ default text */

  color: #7e92ac;
`

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DayItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const DayItemLabel = styled.h5`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Headline dark */

  color: #4d6084;

  margin-bottom: 20px;
`

export const TimeItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`
export const TimeItem = styled.div<{ selected?: boolean; taken?: boolean }>`
  width: 64px;
  height: 32px;
  max-width: 64px;
  max-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #ffffff;
  /* Blue_Light_1 */

  border: 1px solid #9abdfc;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  box-sizing: border-box;
  /* Text_dark */

  color: #414752;
  background: ${props => (props.selected ? '#e1ecff' : 'white')};

  :hover {
    color: #4284ff;
    ${props =>
      props.taken &&
      `color: #7E92AC;
       background: rgba(194, 207, 224, 0.3);
  `};
  }
`

export const ChangeTimeButton = styled.button`
  border: 1px solid #9abdfc;
  border-radius: 40px;
  padding: 6px 20px;
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Active_platform */

  color: #336a93;
`

export const Banner = styled.div`
  background: linear-gradient(90deg, #9ad9fc 0%, #4284ff 75%);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  width: 720px;
  img {
    margin-left: 40px;
    align-self: flex-end;
  }
  margin-top: 30px;
`

export const BannerDesc = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color_menu_dashb */

  color: #f3f5f9;
`
export const BannerButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  background: #ff922d;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color_menu_dashb */
  color: #f3f5f9;
  width: fit-content;
  margin-top: 20px;
  overflow: hidden;
  &:hover {
    border-color: #ffb800;
    background: #ffb800;
  }
  &:active {
    animation: ripple 1s;
  }

  @keyframes ripple {
    from {
      background: linear-gradient(-90deg, #ff922d 0%, #ff922d 100%);
    }

    to {
      background: linear-gradient(-90deg, #ff922d 0%, #ffb800 100%);
    }
  }
`

export const BannerContent = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color_menu_dashb */

  color: #f3f5f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 30px 30px 20px;
`

export const TooltipContainer = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;

  color: #ffffff;
  padding: 10px 12px;

  background: rgba(65, 71, 82, 0.8);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;
  max-width: 430px;
  width: 100%;
`

export const ChooseCalendarEventTypeContainer = styled.div`
  position: absolute;
  width: 182px;

  flex-shrink: 0;
  color: #414752;
  font-size: 14px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  p::first-letter {
    text-transform: capitalize;
  }
`

export const ChooseCalendarEventTypeItem = styled.p`
  border-radius: 4px;
  display: inline-flex;
  padding: 14px 20px;
  align-items: center;
  gap: 10px;
  :hover {
    background: #e1ecff;
  }

  cursor: pointer;
`

export const TimzoneLabel = styled.span`
  font-size: 14px;
  position: absolute;
  color: #7e92ac;
  left: 0px;
  top: -10px;
  cursor: pointer;
`
