import styled from 'styled-components'

import { colors } from '@styles/global'
import { theme } from '@styles/Theme'
import CustomMaterialCheckbox from '@components/Checkbox/CustomMaterialCheckbox'

export const LessonnBoockedCheckbox = styled(CustomMaterialCheckbox)`
  margin-top: 10px;

  & .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    letter-spacing: 0.01em;

    /* Text_dark */

    color: #414752;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`

export const ModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: auto;

  font-weight: bold;
  font-size: 20px;
  color: ${props => props.theme.colors.textDark};

  header {
    margin: 50px 30px 30px 30px;
  }

  main {
    padding: 30px;
    border-top: ${props => props.theme.border.gray};
    border-bottom: ${props => props.theme.border.gray};

    .event-modal-checkbox {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      margin-top: 15px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 27px;
        margin-top: 0;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0px 20px 0px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    width: 100%;
  }
`

export const HeaderRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  input[type='text']::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${props => props.theme.colors.bgGray};
  }

  @media (max-width: 767px) {
    gap: 10px;
    flex-flow: row;
  }
`

export const Input = styled.input`
  border: ${props => props.theme.border.gray};
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  padding: 10px 30px;
  width: 332px;
  color: ${props => props.theme.colors.textDark};
  &:focus {
    border-color: ${colors.underLineCaret};
  }
  @media (max-width: 767px) {
    font-size: 14px;
    width: 100%;
    padding: 7px 14px;
  }
`

export const CancelModalContainer = styled.form`
  main,
  footer {
    padding: 30px 20px;
  }

  header {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-left: 30px;
    margin-top: 35px;
    color: ${props => props.theme.colors.textDark};
  }
  main {
    padding: 30px 30px;
    border-bottom: ${props => props.theme.border.gray};
    color: ${props => props.theme.colors.textDark};
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
  }

  .checkbox {
    margin-left: 0px;
    font-size: 20px;
    margin-bottom: 15px;
  }
`
export const CommentTextArea = styled.textarea`
  width: 412px;
  height: auto;
  background: #f3f5f9;

  font-size: 16px;
  margin: 15px 0px 15px 15px;
  color: #414752;
  resize: none;
  padding-right: 10px;

  ::placeholder {
    font-size: 16px;
    color: #7e92ac;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`
export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 468px;
  height: 225px;
  padding: 10px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
`

export const DialogTitle = styled.div`
  color: #414752;
  font-size: 20px;
  font-weight: 700;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  width: 348px;
`
export const DialogButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  border: 3px solid var(--Button-active-orange, #ff922d);
`

export const DialogButtonText = styled.p`
  color: #ff922d;
  text-align: center;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
`

export const customSelectStyles = (width: number) => {
  return {
    container: (base: any, state: any) => ({
      ...base,

      width: width,

      outline: 'none',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: '#192A3E',
      fontSize: 20,
      '@media (max-width: 767px)': {
        width: '150px',
        height: 34,
        fontSize: 14,
      },
    }),
    control: (base: any, state: any) => {
      return {
        ...base,

        borderRadius: state.menuIsOpen ? '25px 25px 0 0' : 25,
        width: width,
        height: 50,
        paddingLeft: 10,
        color: '#192a3e',
        border: state.isFocused ? '1px solid #C2CFE0' : '1px solid #C2CFE0',
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
          border: state.isFocused ? '1px solid #C2CFE0' : '1px solid #C2CFE0',
        },
        '@media (max-width: 767px)': {
          width: '150px',
          height: 34,
        },
      }
    },
    input: (base: any, state: any) => ({
      ...base,
      outline: 'none',
      color: '#192a3e',
      width: '100%',
      textAlign: 'center',
    }),
    singleValue: base => ({
      ...base,
      color: '#192a3e',
      width: '100%',
      textAlign: 'center',
    }),
    indicatorSeparator: () => {},
    dropdownIndicator: (defaultStyles, state) => ({
      ...defaultStyles,
      border: 'none',
      color: '#c2c2c2',
      '&:hover': {
        color: state.isFocused ? ' #c2c2c2' : ' #c2c2c2',
      },

      marginRight: 7,
    }),
    placeholder: () => ({
      fontSize: 18,
      color: '#90A4BD',
    }),

    option: (styles: any, { isFocused, isSelected }) => ({
      ...styles,
      cursor: 'pointer',
      '&:hover': {
        background: '#C2CFE0',
        opacity: '0.25',
      },
      color: isSelected ? '#FF852D' : '#192A3E',

      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.19)' : 'white',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: 300,
      textAlign: 'center',

      borderRadius: '0 0 20px 20px',

      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        background: '#F3F3F3',
        borderRadius: 2,

        marginBottom: 10,
      },
      '&::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': 5,
        borderRadius: 10,
        background: '#C2CFE0',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: width,
      padding: 20,

      borderRadius: '0 0 25px 25px',
      fontSize: 18,
      fontWeight: 'normal',

      border: '1px solid #C2CFE0',
      color: state.selectProps.menuColor,
      marginTop: 0,
      '@media (max-width: 767px)': {
        padding: 10,
        width: 150,
        fontSize: 14,
      },
    }),
  }
}

export const customSelectStylesCount = {
  container: (base: any, state: any) => ({
    ...base,
    width: 108,
    outline: 'none',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#90A4BD',
    fontSize: 20,
    '@media (max-width: 767px)': {
      height: 34,
      fontSize: 14,
      marginLeft: 0,
    },
  }),
  control: (base: any, state: any) => {
    return {
      ...base,

      borderRadius: state.menuIsOpen ? '25px 25px 0 0' : 40,
      width: 108,
      height: 50,
      paddingLeft: 10,
      color: '#90A4BD',
      border: state.isFocused ? '1px solid #C2CFE0' : '1px solid #C2CFE0',
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? '1px solid #C2CFE0' : '1px solid #C2CFE0',
      },
      '@media (max-width: 767px)': {
        height: 34,
      },
    }
  },
  input: (base: any, state: any) => ({
    ...base,
    outline: 'none',
    color: '#90A4BD',
    width: '100%',
    textAlign: 'center',
  }),
  singleValue: base => ({
    ...base,
    color: '#90A4BD',
    width: '100%',
    textAlign: 'center',
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: (defaultStyles, state) => ({
    ...defaultStyles,
    border: 'none',

    marginRight: 7,
  }),
  placeholder: () => ({
    fontSize: 18,
    color: '#90A4BD',
  }),

  option: (styles: any, { isFocused, isSelected }) => ({
    ...styles,
    cursor: 'pointer',
    padding: 'auto',
    height: 30,
    '&:hover': {
      background: '#C2CFE0',
      opacity: '0.25',
    },
    color: isSelected ? '#FF852D' : '#192A3E',

    backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.19)' : 'white',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: 140,
    textAlign: 'center',

    borderRadius: '0 0 20px 20px',

    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      background: 'RGBA(194, 207, 224, 0.5)',
      borderRadius: 2,
      marginBottom: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      '-webkit-border-radius': 5,
      borderRadius: 10,
      background: '#C2CFE0',
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    width: 108,
    padding: 5,
    boxShadow: state.isFocused ? null : null,

    borderRadius: '0 0 25px 25px',
    fontSize: 20,
    fontWeight: 'normal',

    border: '1px solid #C2CFE0',
    color: '#414752',
    marginTop: 0,
    '@media (max-width: 767px)': {
      fontSize: 14,
    },
  }),
}

export const customSelectStylesStudent = (width: number) => {
  return {
    container: (base: any, state: any) => ({
      ...base,
      outline: 'none',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#192A3E',
      fontSize: 18,
      marginLeft: 20,
      '@media (max-width: 767px)': {
        width: '100%',
        height: 34,
        fontSize: 14,
        marginLeft: 0,
      },
    }),
    control: (base: any, state: any) => {
      return {
        ...base,
        borderRadius: state.menuIsOpen ? '25px 25px 0 0' : 25,
        width: width,
        height: 50,
        paddingLeft: 10,
        border: '1px solid #C2CFE0',
        boxShadow: state.isFocused ? null : null,
        backgroundColor: theme.colors.white,
        '&:hover': {
          border: '1px solid #C2CFE0',
        },
        '@media (max-width: 767px)': {
          width: '100%',
          height: 34,
        },
      }
    },
    input: (base: any, state: any) => ({
      ...base,
      outline: 'none',
    }),
    indicatorSeparator: () => {},
    dropdownIndicator: (defaultStyles, state) => ({
      ...defaultStyles,
      border: 'none',
      color: '#c2c2c2',
      '&:hover': {
        color: state.isFocused ? ' #c2c2c2' : ' #c2c2c2',
      },

      marginRight: 7,
    }),
    placeholder: () => ({
      fontSize: 14,
      color: '#90A4BD',
    }),

    option: (styles: any, { isFocused, isSelected }) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: isFocused ? 'rgba(196, 196, 196, 0.19)' : theme.colors.white,
      color: isSelected ? '#FF852D' : '#192A3E',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: 300,
      boxShadow: state.isFocused ? null : null,

      'overflow-x': 'hidden',

      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        background: 'rgba(194, 207, 224, 0.5)',
      },
      '&::-webkit-scrollbar-thumb': {
        '-webkit-border-radius': 5,
        borderRadius: 10,
        background: '#C2CFE0',
      },
    }),
    singleValue: base => ({
      ...base,
      color: '#414752',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: width,
      backgroundColor: theme.colors.white,

      borderRadius: '0 0 25px 25px',
      fontSize: 14,
      boxShadow: state.isFocused ? null : null,

      border: '1px solid #C2CFE0',
      color: state.selectProps.menuColor,
      padding: 20,
      marginTop: 0,
      '@media (max-width: 767px)': {
        padding: 10,
        width: '100%',
        fontSize: 14,
      },
    }),
  }
}
