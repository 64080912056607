import React, { useMemo, useState } from 'react'
import * as Styled from './selected-events.styles'

import moment from 'moment'
import DefaultAvatar from '@components/Icons/DefaultAvatar.svg'
import { IAutoTeacherSelectionFilterData } from '..'
import { useTranslation } from 'react-i18next'
import MainModal from '@components/Modal'
import { TeacherButton } from '@components/index'
import { CALENDAR_EVENT_TYPES, timeZones } from '@utils/constants'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { IProfile, IStudentProfile } from '@models/IProfile'
import TeachersInfoCard from '../ResultTeachers/TeacherInfoCard'
import CloseIconWithCircle from '@components/Icons/closeIconWithCircle'
import { IEvent } from '@models/ICalendar'
import { calendarUtil } from '@utils/calendarUtil'

export default function({
  filterData,
  onChange,
  onSave,
  isNative,
}: {
  filterData: IAutoTeacherSelectionFilterData
  onChange: (data: { name: string; value: any }) => void
  onSave: () => void
  isNative: boolean
}) {
  const { selectedEvents } = filterData
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<{ teacher: IProfile; event: IEvent } | null>(
    null
  )
  const profileData = useSelector((state: RootState) => state.profile.profile as IStudentProfile)
  const handleDeleteEvent = () => {
    selectedEvents.splice(deleteIndex, 1)
    onChange({
      name: 'selectedEvents',
      value: selectedEvents,
    })
    setDeleteIndex(null)
  }
  const { t } = useTranslation()
  const isMultiple = useMemo(() => {
    return filterData.withWho === 'multiple'
  }, [filterData.withWho])

  const isLessonWithNative = useMemo(() => {
    if (isNative) return
    return (
      selectedEvents.filter(event => {
        return event.teacher.isNative
      }).length === 0
    )
  }, [selectedEvents, isNative])
  const timeZone = useMemo(() => {
    const timeZones = calendarUtil.getAvailableTimezones()
    return timeZones.find(timeZone => timeZone.value === profileData.timeZone)
  }, [profileData.timeZone])
  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>{t('Schedule')}</Styled.Title>
        <Styled.TimeZoneLabel>{timeZone.label}</Styled.TimeZoneLabel>
      </Styled.Header>
      <Styled.MainContainer>
        <Styled.SelectedEventItems>
          {selectedEvents.map((item, index) => (
            <Styled.SelectedEventItem key={item.event.rrule.dtstart}>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                <Styled.EventTeacherInfo>
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url(${
                        item.teacher.avatar ? item.teacher.avatar : DefaultAvatar
                      })`,
                    }}
                  />
                  <div>
                    <Styled.TeacherNameLabel>{`${item.teacher.name} ${item.teacher.lastName}`}</Styled.TeacherNameLabel>
                    <Styled.EventTypeLabel>
                      {item.event.type === CALENDAR_EVENT_TYPES.REGULAR
                        ? t('RegularLesson')
                        : t('OnceLesson')}
                    </Styled.EventTypeLabel>
                  </div>
                </Styled.EventTeacherInfo>
                <Styled.EventItem>
                  <span style={{ textTransform: 'lowercase' }}>
                    {moment(item.event.rrule.dtstart).format('dd')}
                  </span>
                  <span style={{ fontWeight: 700 }}>
                    {moment(item.event.rrule.dtstart).format('HH:mm')}
                  </span>
                  <button className="edit-btn" onClick={() => setSelectedEvent(item)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
                      <path
                        d="M6 15.1765L5.46967 14.6461L5.25 14.8658V15.1765H6ZM15.1765 6L15.7068 5.46967C15.4139 5.17678 14.939 5.17678 14.6461 5.46967L15.1765 6ZM18 8.82353L18.5303 9.35386C18.8232 9.06097 18.8232 8.58609 18.5303 8.2932L18 8.82353ZM8.82353 18V18.75H9.13419L9.35386 18.5303L8.82353 18ZM6 18H5.25C5.25 18.4142 5.58579 18.75 6 18.75V18ZM6.53033 15.7068L15.7068 6.53033L14.6461 5.46967L5.46967 14.6461L6.53033 15.7068ZM14.6461 6.53033L17.4697 9.35386L18.5303 8.2932L15.7068 5.46967L14.6461 6.53033ZM17.4697 8.2932L8.2932 17.4697L9.35386 18.5303L18.5303 9.35386L17.4697 8.2932ZM8.82353 17.25H6V18.75H8.82353V17.25ZM6.75 18V15.1765H5.25V18H6.75ZM12.5285 8.64798L15.352 11.4715L16.4127 10.4108L13.5892 7.58732L12.5285 8.64798Z"
                        fill="#7E92AC"
                      />
                      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#C2CFE0" />
                    </svg>
                  </button>
                </Styled.EventItem>
              </div>

              <button className="delete-btn " onClick={() => setDeleteIndex(index)}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
                  <path
                    d="M16.0003 8V16C16.0003 16.7364 15.4034 17.3333 14.667 17.3333H9.33366C8.59728 17.3333 8.00033 16.7364 8.00033 16V8M14.0003 8V7.33333C14.0003 6.59695 13.4034 6 12.667 6H11.3337C10.5973 6 10.0003 6.59695 10.0003 7.33333V8M6.66699 8H17.3337M10.667 10.6667V14.6667M13.3337 10.6667V14.6667"
                    stroke="#7E92AC"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#C2CFE0" />
                </svg>
              </button>
            </Styled.SelectedEventItem>
          ))}
          {isMultiple && (
            <>
              {selectedEvents.length === 1 && (
                <Styled.NextLessonItem>{t('NextLesson')}</Styled.NextLessonItem>
              )}
              {selectedEvents.length <= 2 && (
                <Styled.NextLessonItem>{t('NextLesson')}</Styled.NextLessonItem>
              )}
              {selectedEvents.length > 2 && isLessonWithNative && (
                <Styled.NextLessonItem>{t('AutoTeacher.LessonWithNative')}</Styled.NextLessonItem>
              )}
            </>
          )}
        </Styled.SelectedEventItems>
      </Styled.MainContainer>
      <Styled.Footer>
        {isMultiple && selectedEvents.length > 2 && (
          <Styled.ApproveButton
            onClick={() => {
              setIsLoading(true)
              onSave()
            }}
            disabled={isLoading}
          >
            {t('AutoTeacher.ConfirmSchedule')}
          </Styled.ApproveButton>
        )}

        {!isMultiple && (
          <Styled.ApproveButton
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true)
              onSave()
            }}
          >
            {t('AutoTeacher.ConfirmSchedule')}
          </Styled.ApproveButton>
        )}
        {isMultiple && (
          <Styled.FooterLabel>
            {t('AutoTeacher.SchduleFooterLabel')}

            {profileData.balanceCount < 7 &&
              t('AutoTeacher.SchduleFooterLabelWithBalance', {
                balanceCount: profileData.balanceCount,
              })}
          </Styled.FooterLabel>
        )}
      </Styled.Footer>

      <MainModal
        open={deleteIndex !== null}
        children={() => {
          return (
            <Styled.DeleteModalContainer>
              <Styled.DeleteModalTitle>{t('DeleteActivity')}?</Styled.DeleteModalTitle>
              <TeacherButton
                whiteTheme
                bgWhite
                children={t('Delete')}
                onClick={handleDeleteEvent}
              />
              <Styled.DeleteModalCancel>{t('Cancel')}</Styled.DeleteModalCancel>
            </Styled.DeleteModalContainer>
          )
        }}
        handleClose={() => setDeleteIndex(null)}
      />

      <MainModal
        open={!!selectedEvent}
        children={() => {
          if (!selectedEvent) return
          return (
            <Styled.EditContainerModal>
              <button className="close-btn" onClick={() => setSelectedEvent(null)}>
                <CloseIconWithCircle light />
              </button>
              <TeachersInfoCard
                editDate={selectedEvent.event.rrule.dtstart}
                onChange={onChange}
                filterData={filterData}
                teacher={selectedEvent.teacher}
                onClose={() => setSelectedEvent(null)}
              />
            </Styled.EditContainerModal>
          )
        }}
        maxWidth={'990px'}
        handleClose={() => setSelectedEvent(null)}
      />
    </Styled.Container>
  )
}
