import React from 'react'
import PlusPage from '@assets/images/plus-page.svg'
import { Tooltip, withStyles } from '@material-ui/core'
import PageBlock from '@components/common/PageBlock'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(65, 71, 82, 0.8)',
    color: '#fff',
    fontSize: 14,
    fontFamily: 'Open Sans',
    padding: '6px 10px',
    borderRadius: 8,
  },
}))(Tooltip)

const PagesController = ({ pages, currentPage, setCurrentPage, onCreatePage, moveCard }) => {
  return (
    <div className="pages-controller">
      <div className="pages-blocks">
        {pages.map(({ id, name, isHomework }, i) => (
          <PageBlock
            key={id}
            id={id}
            index={i}
            name={name}
            moveCard={moveCard}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isHomework={isHomework}
          />
        ))}
      </div>
      <HtmlTooltip title="Новая страница" placement="left">
        <div onClick={onCreatePage} className="create-page-block">
          <img src={PlusPage} alt="" />
        </div>
      </HtmlTooltip>
    </div>
  )
}

export default PagesController
