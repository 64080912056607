import styled from 'styled-components'
import { CustomMaterialCheckbox, Button } from '@components'
import {
  Tooltip,
  TextField,
  List,
  TextareaAutosize,
  withStyles,
  Select,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'

export const PageContainer = styled.div``

export const Container = styled.div`
  margin-top: 40px;
`

export const Checkbox = styled(CustomMaterialCheckbox)`
  margin-right: 22px !important;
  & .MuiButtonBase-root {
    margin-right: 0;
  }
  & svg {
    width: 20px;
    height: 20px;
  }
  & rect {
    stroke: #c2cfe0;
  }
`

export const CustomTooltip = withStyles(() => ({
  popper: {
    margin: '-63px -34px',
  },
  tooltip: {
    backgroundColor: 'rgba(65, 71, 82, 0.9)',
    color: 'white',
    fontSize: 13,
    fontFamily: 'Open Sans',
    padding: 15,
    borderRadius: 8,
    fontWeight: 'normal',
  },
}))(Tooltip)

export const DescriptionContainer = styled(Container)`
  display: flex;
  align-items: start;
`

export const Description = styled.div`
  margin-left: 42px;
`

export const BenefitsContainer = styled(Container)`
  display: flex;
  align-items: start;
`

export const Benefits = styled.div``

export const SourcesContainer = styled(Container)`
  display: flex;
  align-items: start;
`

export const Sources = styled.div``

export const CustomSelect = styled(Select)`
  &.MuiInputBase-root {
    width: 250px;
    font-family: ${props => props.theme.defaultFont};
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 24px;
    color: ${props => props.theme.textColors.greyMiddle};
  }
  &.MuiInput-underline::before,
  &.MuiInput-underline::after,
  &.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(211, 216, 221, 1);
  }
  & .MuiSelect-select:focus {
    background: none;
  }
  & .MuiSelect-icon {
    color: ${props => props.theme.textColors.greyMiddle};
    font-size: 25px;
  }
`

export const CustomMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-family: ${props => props.theme.defaultFont};
    font-style: normal;
    font-weight: 400;
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 24px;
    color: ${props => props.theme.textColors.greyMiddle};
  }
`

export const Title = styled.h1`
  font-family: ${props => props.theme.defaultFont};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: ${props => props.theme.textColors.mainContent};
  padding-bottom: 15px;
`

export const Text = styled.p`
  font-family: ${props => props.theme.defaultFont};
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  padding-bottom: 41px;
  color: ${props => props.theme.textColors.mainContent};
  & span {
    font-size: ${props => props.theme.fontSizes.medium};
    line-height: 19px;
    color: ${props => props.theme.textColors.greyMiddle};
  }
`

export const CustomTextareaAutosize = styled(TextareaAutosize)`
  width: 608px;
  resize: none;
  border-bottom: 1px solid rgba(211, 216, 221, 1);
  color: ${props => props.theme.textColors.greyMiddle};
  font-family: ${props => props.theme.defaultFont};
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.large};
  line-height: 25px;
  letter-spacing: 0.01em;
  padding: 5px;
  &::placeholder {
    color: ${props => props.theme.textColors.greyMiddle};
  }
`

export const Input = styled(TextField)`
  width: 608px;
  & .MuiInput-input,
  .MuiInput-input::placeholder {
    color: ${props =>
      props.isError ? 'red' : props.theme.textColors.greyMiddle};
    font-family: ${props => props.theme.defaultFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 25px;
    letter-spacing: 0.01em;
    opacity: 1;
  }
  & .MuiInput-underline::before,
  .MuiInput-underline::after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: ${props =>
      props.isError ? '1px solid red' : '1px solid rgba(211, 216, 221, 1)'};
  }
  & .MuiInputBase-root {
    width: ${props => props.width}px;
  }
`

export const CustomList = styled(List)`
  & .MuiListItem-gutters {
    padding: 0;
  }
  & .MuiTypography-body1 {
    color: ${props => props.theme.textColors.black};
    font-family: ${props => props.theme.defaultFont};
    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 30px;
    letter-spacing: 0.01em;
  }
`

export const CustomListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 0;
  }
  ${Checkbox} {
    margin-right: 17px !important;
  }
`

export const InfoContainer = styled(Container)`
  margin-left: 42px;
  ${Title} {
    padding-bottom: 41px;
  }
  & .MuiInput-root {
    display: block;
  }
  & .MuiInputBase-root {
    width: 250px;
  }

  ${CustomSelect}, ${Input} {
    margin-bottom: 31px;
  }
`

export const TagsContainer = styled.div`
  margin-left: 42px;
  margin-top: 50px;
  ${Title} {
    padding-bottom: 30px;
  }
`

export const InterestsGoalsContainer = styled.div`
  p {
    color: ${props => props.theme.textColors.greyMiddle};
    font-family: ${props => props.theme.defaultFont};
    font-style: normal;
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes.large};
    line-height: 24px;
  }
  display: flex;
`

export const InterestsContainer = styled.div`
  width: 467px;
  margin-right: 120px;
`

export const GoalsContainer = styled.div`
  width: 467px;
`

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  ${Checkbox} {
    width: 50%;
    margin-right: 0 !important;
    padding: 15px 0;
    & .MuiButtonBase-root {
      margin-right: 17px;
    }
    & .MuiTypography-body1 {
      color: ${props => props.theme.textColors.black};
      font-family: ${props => props.theme.defaultFont};
      font-style: normal;
      font-weight: 400;
      font-size: ${props => props.theme.fontSizes.large};
      line-height: 30px;
      letter-spacing: 0.01em;
    }
    & :nth-child(2n) {
      padding: 0;
    }
  }
`

export const LessonsContainer = styled.div`
  margin-left: 42px;
  margin-top: 40px;
`

export const CustomButton = styled(Button)`
  background: #4284ff;
`
