import { LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

export const ProgressBar = styled(LinearProgress)<{ lineColor?: string; barColor?: string }>`
  &.MuiLinearProgress-colorPrimary {
    background-color: ${props => props.lineColor || props.theme.colors.bgBlueLight2};
  }
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${props => props.barColor || props.theme.textColors.blueLightAccent};
  }
  &.MuiLinearProgress-root {
    height: 5px;
    border-radius: 5px;
    display: block;
    width: 100%;
  }
`

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Percent = styled.div`
  font-family: ${props => props.theme.defaultFont};
  font-size: ${props => props.theme.fontSizes.medium};
  color: #4284FF;
  margin-right: 10px;
`