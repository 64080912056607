import { ITest } from '@models/ITest'
import { TestActionTypes, SET_IS_TEST_REVIEWED } from './types'

const initialState: ITest = {
  isReviewed: false,
}

export const TestReducer = (state: ITest = initialState, action: TestActionTypes) => {
  switch (action.type) {
    case SET_IS_TEST_REVIEWED:
      return { ...state, isReviewed: action.payload }

    default:
      return state
  }
}
