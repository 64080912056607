import { IProfile, IBusinessHours, ILibraryFile, IStudentProfile, IPayment } from '@models/IProfile'

export const SET_PROFILE = 'SET_PROFILE'
export const SET_PROFILE_TEACHER = 'SET_PROFILE_TEACHER'
export const UPDATE_BUSINESS_HOURS = 'UPDATE_BUSINESS_HOURS'
export const SET_PROFILE_FILES = 'SET_FILES'
export const SET_ALL_FILES_LOADING = 'SET_ALL_FILES_LOADING'
export const UPDATE_PROFILE_FILE = 'UPDATE_PROFILE_FILE'
export const DELETE_PROFILE_FILE = 'DELETE_PROFILE_FILE'
export const SET_IS_STUDENT = 'SET_IS_STUDENT'
export const SET_STUDENT_PROMO_CODE = 'SET_STUDENT_PROMO_CODE'
export const SET_STUDENT_PAYMENT = 'SET_STUDENT_PAYMENT'

interface SetProfileAction {
  type: typeof SET_PROFILE
  payload: IProfile | IStudentProfile
}
interface SetProfileTeahcerAction {
  type: typeof SET_PROFILE_TEACHER
  payload: IProfile
}
interface SetProfileFilesAction {
  type: typeof SET_PROFILE_FILES
  payload: ILibraryFile[]
}

interface UpdateProfileFileAction {
  type: typeof UPDATE_PROFILE_FILE
  payload: ILibraryFile
}
interface DeleteProfileFileAction {
  type: typeof DELETE_PROFILE_FILE
  payload: string
}

interface UpdateBusinessHoursAction {
  type: typeof UPDATE_BUSINESS_HOURS
  payload: IBusinessHours[]
}

interface SetAllFilesLoadingAction {
  type: typeof SET_ALL_FILES_LOADING
}

interface SetIsStudentAction {
  type: typeof SET_IS_STUDENT
  payload: boolean
}

interface SetStudentPromoCodeAction {
  type: typeof SET_STUDENT_PROMO_CODE
  payload: string
}

interface SetPayment {
  type: typeof SET_STUDENT_PAYMENT
  payload: IPayment[]
}

export type ProfileActionsTypes =
  | SetProfileAction
  | UpdateBusinessHoursAction
  | SetProfileFilesAction
  | SetProfileTeahcerAction
  | SetAllFilesLoadingAction
  | UpdateProfileFileAction
  | DeleteProfileFileAction
  | SetIsStudentAction
  | SetStudentPromoCodeAction
  | SetPayment
