export const colors = {
  orange: "#FF852D",
  gray: "#C4C4C4",
  white: "#fff",
  red: "#FF0000",
  subGray: "#9C9C9C",
  yellow: "#FF922D",
  purple_blue: "#2B4659",
  zinnwaldite: "#EBCAB7",
  sepia_craiola: "#9C6A4D",
  black: "#000000",
  ocean_blue: "#192A3E",
  blue: "#336A93",
  gainsborough: "#E5E5E5",

  inputBG: "#fff",
  inputErr: "#FF0000",
  inputFilled: "#FF852D",
  input: "#C4C4C4",
  borderColor: "#EBEFF2",

  underLine: "#2B4659",
  underLineCaret: "#FF852D",

  // link
  linkColor: "#9C9C9C"
};

export const sizes = {
  mainFontSize: 20
};
