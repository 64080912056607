import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import store from './configs/store'
import i18n from '@localization/i18nConfig'

import 'rc-dropdown/assets/index.css'
import './stylesheets/index.scss'
import './stylesheets/main.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { history } from '@utils/helpers'
import Theme from './styles/Theme'

ReactDOM.render(
  <Provider store={store}>
    <Theme>
      <Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <App history={history} />
        </I18nextProvider>
      </Suspense>
    </Theme>
  </Provider>,
  document.getElementById('app')
)
serviceWorker.unregister()