import { createAction } from '@reduxjs/toolkit'

import {
  GET_COURSES,
  CREATE_COURSE,
  DELETE_COURSE,
  DUPLICATE_COURSE,
  GET_LESSONS_LENGTH,
  RESET_COURSES_LIST,
  RESET_LESSONS_LENGTH_ARR,
  GET_ALL_LESSONS,
  RESET_ALL_LESSONS,
  PUBLISH_COURSE,
  MODERATION,
  DIVERTED,
  REMOVE_PUBLISH,
  TRANSFORM_TO_MINI_COURSE,
} from '@types/courses'

import {
  GET_LESSONS,
  CREATE_LESSON,
  DELETE_LESSON,
  DUPLICATE_LESSON,
  EDIT_COURSE,
  RESET_LESSONS,
  EDIT_LESSON,
  UPDATE_LESSONS,
} from '@types/course'

import {
  GET_TESTS,
  CREATE_TEST,
  DELETE_TEST,
  DUPLICATE_TEST,
  EDIT_TEST,
  RESET_ALL_TESTS, ASSIGN_TEST
} from "@types/tests";

import { GET_CONSTRUCTOR, CREATE_CONSTRUCTOR, RESET_CONSTRUCTOR } from '@types/lesson'

const getCourses = createAction(GET_COURSES)
const createCourse = createAction(CREATE_COURSE)
const deleteCourse = createAction(DELETE_COURSE)
const duplicateCourse = createAction(DUPLICATE_COURSE)
const getLessonsLength = createAction(GET_LESSONS_LENGTH)
const resetCoursesList = createAction(RESET_COURSES_LIST)
const resetLessonsLengthArr = createAction(RESET_LESSONS_LENGTH_ARR)
const getAllLessons = createAction(GET_ALL_LESSONS)
const resetAllLessons = createAction(RESET_ALL_LESSONS)
const publishCourse = createAction(PUBLISH_COURSE)
const moderation = createAction(MODERATION)
const divertedCourse = createAction(DIVERTED)
const removePublication = createAction(REMOVE_PUBLISH)
const transformToMiniCourse = createAction(TRANSFORM_TO_MINI_COURSE)

const getLessons = createAction(GET_LESSONS)
const createLesson = createAction(CREATE_LESSON)
const deleteLesson = createAction(DELETE_LESSON)
const duplicateLesson = createAction(DUPLICATE_LESSON)
const editCourse = createAction(EDIT_COURSE)
const resetLessons = createAction(RESET_LESSONS)
const editLesson = createAction(EDIT_LESSON)

const getConstructor = createAction(GET_CONSTRUCTOR)
const createConstructor = createAction(CREATE_CONSTRUCTOR)
const resetConstructor = createAction(RESET_CONSTRUCTOR)
const updateLessons = createAction(UPDATE_LESSONS)

const getTests = createAction(GET_TESTS);
const createTest = createAction(CREATE_TEST);
const editTest = createAction(EDIT_TEST);
const deleteTest = createAction(DELETE_TEST);
const duplicateTest = createAction(DUPLICATE_TEST);
const resetTests = createAction(RESET_ALL_TESTS);
const assignTestToStudent = createAction(ASSIGN_TEST);

export {
  createCourse,
  getCourses,
  deleteCourse,
  duplicateCourse,
  getLessonsLength,
  getLessons,
  createLesson,
  deleteLesson,
  duplicateLesson,
  getConstructor,
  createConstructor,
  resetConstructor,
  editCourse,
  resetCoursesList,
  resetLessonsLengthArr,
  resetLessons,
  editLesson,
  getAllLessons,
  resetAllLessons,
  updateLessons,
  publishCourse,
  moderation,
  divertedCourse,
  removePublication,
  transformToMiniCourse,
  getTests,
  createTest,
  editTest,
  deleteTest,
  duplicateTest,
  resetTests,
  assignTestToStudent,
}
