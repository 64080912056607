import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { firebaseAnalytics } from '@configs/firebase'

import { declOfNum } from '@utils/helperst'

import { Text } from '@components/Text'
import StudentButton from './Button'

import EmptyBalanceImg from './Icons/empty_balance.svg'
import FullBalanceImg from './Icons/full_balance.svg'
import StartImg from './Icons/start.svg'

import { theme } from '@styles/Theme'

import { useTranslation } from 'react-i18next'

const Container = styled.div`
  background-color: ${props =>
    props.isBalance
      ? `${props.theme.colors.bgDashboard}80`
      : `${props.theme.colors.hmStartStatus}80`};
  width: 240px;
  height: 180px;
  border-radius: 20px;
  align-self: center;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${props => props.isBalance && `3px 3px 10px rgb(0 0 0 / 10%)`};
`

const BonusPresent = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #f3f5f9;
  width: 70px;
  cursor: pointer;
  &:hover {
    color: #4284ff;
  }
`

const Balance = ({
  to,
  balanceCount,
  showPromoCodeModal,
}: {
  to: string
  balanceCount: number | null
  showPromoCodeModal: () => void
}) => {
  const { t } = useTranslation()
  const isBalanceFull = () => balanceCount && balanceCount > 0

  const goToPaymentPage = () => {
    firebaseAnalytics.logEvent('TOP_UP')
  }

  return (
    <Container isBalance={isBalanceFull()}>
      <div>
        <Text
          display="additionalMediumText"
          style={{ color: isBalanceFull() && theme.colors.bgNav }}
        >
          {t('Balance')}:
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <img src={isBalanceFull() ? FullBalanceImg : EmptyBalanceImg} alt="balance_image" />
        <Text
          display="supperLarge"
          light
          style={{
            marginLeft: '25px',
            color: isBalanceFull() && theme.colors.bgNav,
          }}
        >
          {balanceCount || 0}
          <Text
            display="additionalMediumText"
            light
            style={{
              marginLeft: '5px',
              color: isBalanceFull() && theme.colors.bgNav,
            }}
          >
            {declOfNum(balanceCount || 0, [t('Lesson1'), t('Lesson2'), t('Lessons.Lessons')])}
          </Text>
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={to}>
          {isBalanceFull() ? (
            <StudentButton
              onClick={goToPaymentPage}
              text={t('Balance.TopUp')}
              bgColor={theme.colors.white}
              textColor={theme.colors.bgNav}
              styles={{ border: '1px solid #4D6084' }}
            />
          ) : (
            <StudentButton
              onClick={goToPaymentPage}
              text={t('Balance.Start')}
              bgColor={theme.colors.button}
              img={StartImg}
            />
          )}
        </Link>
        <BonusPresent
          onClick={showPromoCodeModal}
          style={{ color: isBalanceFull() && theme.colors.bgNav }}
        >
          {t('Balance.Get2Lessons')}
        </BonusPresent>
      </div>
    </Container>
  )
}

export default Balance
