import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from 'draft-js';

import { CellContent, IState, CSSProperties } from './types';

const getStyleVal = (elm: HTMLTableRowElement, css: CSSProperties): string =>
  window.getComputedStyle(elm, null).getPropertyValue(css);

export const paddingDiff = (col: HTMLTableRowElement): number => {
  if (getStyleVal(col, 'box-sizing') === 'border-box') return 0;
  let padLeft = getStyleVal(col, 'padding-left');
  let padRight = getStyleVal(col, 'padding-right');
  return parseInt(padLeft) + parseInt(padRight);
};

export const insertPreloadedState = (
  preloadedState: IState<RawDraftContentState>,
): IState<EditorState> => {
  const table = {} as IState<EditorState>['table'];
  const keys = Object.keys(preloadedState.table);
  keys.forEach(k => {
    table[k] = preloadedState.table[k].map(td => ({
      rowContent: EditorState.createWithContent(convertFromRaw(td.rowContent)),
      bgColor: td.bgColor,
      align: td.align,
    }));
  });
  return {
    hideBorder: preloadedState.hideBorder,
    colWidths: preloadedState.colWidths,
    table,
  };
};

export const convertTableForSave = (
  tableState: IState<EditorState>['table'],
): IState<RawDraftContentState>['table'] => {
  const result = {} as IState<RawDraftContentState>['table'];
  const keys = Object.keys(tableState);
  keys.forEach(k => {
    result[k] = tableState[k].map(td => ({
      rowContent: convertToRaw(td.rowContent.getCurrentContent()),
      bgColor: td.bgColor,
      align: td.align,
    }));
  });
  return result;
};

export const createEmptyCell = (): CellContent<EditorState> => ({
  rowContent: EditorState.createEmpty(),
  bgColor: null,
  align: null,
});
