import styled from 'styled-components'

export const TagBar = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

export const TagDescription = styled.div`
  font-size: 16px;
  margin: 30px, 0;
  font-family: 'Open Sans';
`
