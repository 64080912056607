import React from 'react';

const TextColorIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10079 13.7333H13.8992L15.7143 18H18L11.1429 2H8.86052L2 18H4.28571L6.10079 13.7333ZM9.99948 4.56768L12.9912 11.6001H7.00781L9.99948 4.56768Z"
      fill="currentColor"
    />
  </svg>
);

export { TextColorIcon };
