import firebase from 'firebase/app';
import { put, takeEvery, call } from 'redux-saga/effects';

import {
  CREATE_CONSTRUCTOR,
  GET_CONSTRUCTOR,
  GET_CONSTRUCTOR_FULFILLED,
  GET_CONSTRUCTOR_REJECTED,
} from '@types/lesson';

import { firestoreRef, sagaFirebase as firestore } from '@configs/firebase';
import { COURSES_STATUS } from '@utils/constants';

function* getLesson({ payload: { courseId, lessonId } }) {
  try {
    const courseSnapshot = yield call(firestore.getDocument, `courses/${courseId}`);
    const lessonSnapshot = yield call(firestore.getDocument, `courses/${courseId}/lessons/${lessonId}`);
    yield put({
      type: GET_CONSTRUCTOR_FULFILLED,
      payload: {
        currentCourse: courseSnapshot.data(),
        lesson: lessonSnapshot.data(),
      },
    });
  } catch (error) {
    yield put({
      type: GET_CONSTRUCTOR_REJECTED,
      payload: error,
    });
  }
}

function* createLesson({ payload: { courseId, lessonId, constructor } }) {
  try {
    const lessonSnapshot = yield call(firestore.getDocument, `courses/${courseId}/lessons/${lessonId}`);
    const updatedCourse = yield call(firestore.getDocument, `courses/${courseId}`)
    const batch = firestoreRef.batch();
    batch.set(firestoreRef.collection(`courses/${courseId}/lessons`).doc(lessonId), {
      ...lessonSnapshot.data(),
      pages: constructor,
    });
    batch.update(firestoreRef.doc(`courses/${courseId}`), {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      status: updatedCourse.data().status === COURSES_STATUS.MODERATION ? COURSES_STATUS.MODERATION : COURSES_STATUS.CHANGED
    });
    yield batch.commit();
  } catch (error) {
    // it will be better to display somehow in the UI at least that something went wrong
  }
}

function* watchGetLesson() {
  yield takeEvery(GET_CONSTRUCTOR, getLesson);
}

function* watchCreateLesson() {
  yield takeEvery(CREATE_CONSTRUCTOR, createLesson);
}

export default [watchGetLesson(), watchCreateLesson()];
