import React from 'react'
import classNames from 'classnames'

import { CheckCircleIcon, CheckRectIcon } from '@components/Icons'

import './checkbox.sass'

export default function SelectBox(props) {
  const { className, checked, tip, rect, tipAccent, radio, type, ...rest } = props
  const classNamesConfig = {
    [className]: Boolean(className),
  }

  return (
    <label className={classNames('checkbox', classNamesConfig)}>
      <input className="checkbox__input" type={type} checked={checked} {...rest} />
      {rect ? (
        <CheckRectIcon isChecked={checked} />
      ) : (
        <CheckCircleIcon isChecked={checked} className="checkbox__icon" />
      )}

      {radio && <RadionIcon checked={checked} />}

      {tip && (
        <span className="checkbox__tip">
          {tip}
          {tipAccent && <b>&nbsp;{tipAccent}</b>}
        </span>
      )}
    </label>
  )
}

SelectBox.defaultProps = {
  tip: null,
  checked: false,
  tipAccent: null,
  className: null,
  type: 'checkbox',
}

export const RadionIcon = ({ checked }) => {
  if (checked)
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
          stroke="#C2CFE0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.3623 8.00064C4.3623 5.99178 5.9908 4.36328 7.99966 4.36328C10.0085 4.36328 11.637 5.99178 11.637 8.00064C11.637 10.0095 10.0085 11.638 7.99966 11.638C5.9908 11.638 4.3623 10.0095 4.3623 8.00064Z"
          fill="#4284FF"
        />
      </svg>
    )

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
        stroke="#C2CFE0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
