import React, { useMemo } from 'react'
import { IconButton } from '@material-ui/core'
import EditIcon from '../../../Icons/EditIcon'
import CheckMarkIcon from '../../../Icons/CheckMark'
import { useTranslation } from 'react-i18next'
import * as Styled from '../personal-info.styles'
import Step7 from '../Worksheet/Steps/Step7'
import { Button, Content, PrevNextButton } from '../Worksheet/worksheet.styles'

export default function({
  priorities,
  editType,
  handleClickEdit,
  onChange,
}: {
  editType?: string
  handleClickEdit: (data: string) => void
  priorities: { [Name: string]: string }
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()

  const prioritiesData = useMemo(() => {
    const data = {}

    for (const [key, value] of Object.entries(priorities)) {
      data[value] = [...(data[value] || []), key]
    }
    return data
  }, [priorities])

  return (
    <>
      <Styled.PersonalInfoItem>
        <div style={{ display: 'block' }}>
          {Object.keys(prioritiesData).map((key, index) => {
            const values = prioritiesData[key].map(val => val)

            return (
              <div style={{ display: 'flex', marginTop: index === 0 ? 0 : 20 }}>
                <Styled.PersonalInfoLabel style={{ textTransform: 'capitalize' }}>
                  {key === 'important'
                    ? 'Important'
                    : key === 'notImportant'
                    ? 'Not Important'
                    : key === 'neutral'
                    ? 'Neutral'
                    : key === 'speaking'
                    ? 'Speaking'
                    : key === 'reading'
                    ? 'Reading'
                    : key === 'listening'
                    ? 'Listening'
                    : key === 'writing'
                    ? 'Writing'
                    : ''}
                </Styled.PersonalInfoLabel>
                <Styled.PersonalItemCard
                  style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}
                >
                  {values.join(', ')}
                </Styled.PersonalItemCard>
              </div>
            )
          })}
        </div>

        {!editType && <div className="hover-block" />}
        <IconButton className="edit-btn" size="small" onClick={() => handleClickEdit('priorities')}>
          {editType === 'priorities' ? <CheckMarkIcon /> : <EditIcon />}
        </IconButton>
      </Styled.PersonalInfoItem>
      {editType === 'priorities' && (
        <Styled.EditContainer
          style={{
            top: 40,
            bottom: 0,
            right: 0,
            left: 0,
            background: 'white',
          }}
        >
          <Styled.PersonalInfoLabel
            style={{
              width: 'auto',
              fontWeight: 700,
              fontSize: 16,
              margin: '34px 30px 30px 30px',
            }}
          >
            {t('StudentWorkSheetStep7Title')}
          </Styled.PersonalInfoLabel>
          <Content>
            <Step7 priorities={priorities} onChange={onChange} />
          </Content>

          <PrevNextButton style={{ marginBottom: 20 }}>
            <Button style={{ background: '#C2CFE0' }} onClick={() => handleClickEdit('priorities')}>
              Cancel
            </Button>
            <Button onClick={() => handleClickEdit('priorities')}>Save</Button>
          </PrevNextButton>
        </Styled.EditContainer>
      )}
    </>
  )
}
