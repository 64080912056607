import styled from "styled-components";

export const tootTip = styled.span`
  position: absolute;
  top: -10px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c6cff1;
  background: #f0f5fe;
  cursor: pointer;
  &:hover {
    background: #dedefa;
  }
`
