import firebase from 'firebase/app'
import { chatsRef, studentsRef, usersRef } from '@configs/firebase'
import { IMessage } from '@models/IMessage'
import { fetchStudentProfileByUid, fetchTeacherProfileByUid } from './profileAPI'
import { IProfile, IStudentProfile } from '@models/IProfile'

export const chatAPI = {
  sendMessage: async (data: { id: string; message: IMessage }) => {
    try {
      await chatsRef.doc(data.id).update({
        data: firebase.firestore.FieldValue.arrayUnion(data.message),
      })
    } catch (e) {
      if (e.code === 'not-found') {
        await chatsRef
          .doc(data.id)
          .set({ data: firebase.firestore.FieldValue.arrayUnion(data.message) })
      }
    }
  },
  removeMessage: async (data: { id: string; message: any }) => {
    await chatsRef
      .doc(data.id)
      .set({ data: firebase.firestore.FieldValue.arrayRemove(data.message) })
  },
  getMessages: async (id: string): Promise<IMessage[]> => {
    const chatsSnap = await chatsRef.doc(id).get()
    return chatsSnap.exists ? chatsSnap.data().data : []
  },
  fetchStudents: async (studentIds: string[]) => {
    const students: IStudentProfile[] = await Promise.all(
      studentIds.map(id => {
        return fetchStudentProfileByUid(id).then(data => {
          return { ...data, id }
        })
      })
    )
    return students
  },

  fetchTeachers: async (teacherIds: string[]) => {
    const teachers: IProfile[] = await Promise.all(
      teacherIds.map(id => {
        return fetchTeacherProfileByUid(id).then(data => {
          return { ...data, id }
        })
      })
    )
    return teachers
  },

  addUnreadMessage: async ({
    userId,
    isTeacher,
    addUserId,
  }: {
    userId: string
    isTeacher: boolean
    addUserId: string
  }) => {
    const collection = !isTeacher ? usersRef : studentsRef
    await collection.doc(userId).update({
      unreadMessages: firebase.firestore.FieldValue.arrayUnion(addUserId),
    })
  },

  removeUnreadMessage: async ({
    userId,
    isTeacher,
    removeUserId,
  }: {
    userId: string
    isTeacher: boolean
    removeUserId: string
  }) => {
    const collection = isTeacher ? usersRef : studentsRef
    await collection.doc(userId).update({
      unreadMessages: firebase.firestore.FieldValue.arrayRemove(removeUserId, userId),
    })
  },
}
