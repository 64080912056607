import * as React from 'react'
import * as Styled from './styles.profile-info'

import DefaultProfileImg from '@images/people-default.svg'
import { useSelector } from 'react-redux'
import { RootState } from '@store'

interface Props {
  active: boolean
  path: string
}

const ProfileInfo = ({ active, path }: Props) => {
  const profileAvatar = useSelector((state: RootState) => state.profile.profile.avatar)
  const profile = useSelector((state: RootState) => state.profile.profile)

  return (
    <Styled.ProfileInfo $active={active} display="large" dark to={path}>
      <Styled.ProfileImg
        src={profileAvatar || DefaultProfileImg}
        alt="Profile image"
        size="small"
        width={60}
      />
      <span>
        {profile.name || ''}
        <br />
        {profile.lastName || ''}
      </span>
    </Styled.ProfileInfo>
  )
}

export default ProfileInfo
