import { CancelButtonIcon } from '@components/Icons'
import React, { useState } from 'react'
import Comments from './Comments'
import TutorsDropDown from './TutorsDropDown'
import Contacts from './Contacts'
import PersonalData from './PersonalData'
import Progress from './Progress'
import Schedule from './Schedule'
import { IProfile, IStudentProfile } from '@models/IProfile'
import * as Styled from './student-card.styles'
import { updateStudentComments } from '@servise/profileAPI'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { ROLES } from '@utils/constants'

interface Props {
  studentData?: IStudentProfile
  handleClose: () => void
  handleChange: (data: IStudentProfile) => void
}

const StudentCard = ({ studentData, handleClose, handleChange }: Props) => {
  const [changeComment, setChangeComment] = useState(false)
  const isTrialOpen = useSelector(
    (state: RootState) => (state.profile.profile as IProfile)?.isTrialLessonsEnabled
  )
  const isManager = useSelector(
    (state: RootState) => (state.profile.profile as IProfile)?.role === ROLES.MANAGER
  )
  const handleSave = comments => {
    updateStudentComments({
      uid: studentData.id,
      comments: comments,
    }).then(() => {
      setChangeComment(false)
    })
  }

  if (!studentData) {
    return <></>
  }

  return (
    <Styled.StudentCard>
      <button className="close-button" onClick={handleClose}>
        <CancelButtonIcon />
      </button>

      <PersonalData studentData={studentData} handleChange={handleChange} />

      <Styled.Div className="block-main">
        <Schedule studentId={studentData.id} />
        <Contacts
          studentData={studentData}
          handleChange={handleChange}
          isManager={isManager}
          showContacts={isManager || (isTrialOpen && studentData.student) || !isTrialOpen}
        />
        <Progress studentData={studentData} />
      </Styled.Div>
      <Styled.Div>
        <TutorsDropDown studentData={studentData} handleChange={handleChange} />
        <Comments
          studentData={studentData}
          handleChange={handleChange}
          handleSave={handleSave}
          changeComment={changeComment}
          setChangeComment={setChangeComment}
        />
      </Styled.Div>
    </Styled.StudentCard>
  )
}

export default StudentCard
