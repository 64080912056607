import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './steps.styles'
export default function({
  classFrequency,
  onChange,
}: {
  classFrequency?: number[]
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newClassFrequency = classFrequency
    const { name, value } = event.target
    if (name === 'from') {
      newClassFrequency[0] = Number(value)
    } else {
      newClassFrequency[1] = Number(value)
    }
    onChange({ name: 'classFrequency', value: classFrequency })
  }

  return (
    <>
      <Styled.RangeHoursContainer>
        <span>{t('ClassFrequencyFrom')}</span>
        <input
          name="from"
          type="number"
          value={classFrequency[0]}
          onChange={handleOnChange}
        />
        <span>{t('ClassFrequencyTo')}</span>
        <input
          style={
            classFrequency[1] - classFrequency[0] > 10
              ? { borderColor: '#FF922D' }
              : {}
          }
          name="to"
          type="number"
          value={classFrequency[1]}
          onChange={handleOnChange}
        />
        <span>{t('ClassFrequencyToHoursPerWeek')}</span>
      </Styled.RangeHoursContainer>
      {classFrequency[1] - classFrequency[0] > 10 && (
        <div
          style={{
            marginLeft: 30,
            marginTop: 20,
            fontWeight: 400,
            fontSize: 14,
            color: '#7E92AC',
          }}
        >
          {t('ClassFrequencyRecomendation')}
        </div>
      )}
    </>
  )
}
