import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { authRef } from '@configs/firebase'
import { NAVIGATOR, ROLES } from '@utils/constants'

import { IProfile } from '@models/IProfile'

import { RootState } from '@store'

import {
  HomeWorkIcon,
  StudentIcon,
  MenuSearchIcon,
  SupportIcon,
  AddUserIcon,
  LogoutIcon,
  LessonMenuIcon,
  LibraryMenuIcon,
  CalendarMenuIcon,
  PersonIcon,
  MenuAccountingIcon,
  MenuLessonHistoryIcon,
} from '@components/Icons'

import { Counter } from '@pages/student/student.styles'

import * as Styled from './dashboard.styles'
import ConstructorIcon from '@components/Icons/Constructor'
import ConstructorIconActive from '@components/Icons/ConstructorActive'
import StudentPossibleIcon from '@components/Icons/StudentPossibleIcon'
import ChatIcon from '@components/Icons/ChatIcon'
import FactoryIcon from '@components/Icons/FactoryIcon'
import UserIcon from '@components/Icons/UserIcon'
import LeftArrowIcon from '@components/Icons/LeftArrow.svg'
import { MainModal } from '..'
import moment from 'moment'
import { openSupport } from '@utils/helperst'
import NavDrawerIcon from '@components/Icons/nav-drawer.svg'
import { useOnClickOutside } from '../../hooks/handleOutsideClick'
import { IconButton } from '@material-ui/core'
import StatisticsIcon from '@components/Icons/Statistics'

const CustomCounter = styled(Counter)<{ isHomeWorkNotCheked?: boolean; open?: boolean }>`
  position: absolute;
  right: ${props => (props.isHomeWorkNotCheked ? '2px' : '8px')};
  bottom: ${props => (props.isHomeWorkNotCheked ? '5px' : '8px')};

  ${props =>
    props.open &&
    css`
      top: 0;
      bottom: 0;
      margin: auto 0;
    `}
`

export default ({
  open,
  onClickDrawer,
}: {
  open: boolean
  onClickDrawer: (close?: boolean) => void
}) => {
  const {
    teacher_classes,
    teacher_library,
    teacher_homework,
    teacher_profile,
    teacher_lesson_history,
    manager_search_teacher,
    manager_register,
    teacher_calendar,
    logout,
    manager_profile,
    manager_accounting,
    manager_students,
    manager_students_possible,
    manager_constructor,
    teacher_exercise_factory,
    support,
    teacher_chat,
    manager_factory,
    manager_search_user,
    teacher_students,
    manager_statistics,
  } = NAVIGATOR

  const teacherHomeWorkCount = useSelector((state: RootState) => state.homework.teacherCount)
  const profileData = useSelector((state: RootState) => state.profile.profile) as IProfile
  const chat = useSelector((state: RootState) => state.chat)
  const [activeLink, setActiveLink] = useState<string>('')
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  let location = useLocation()
  let history = useHistory()
  let navBarRef = useRef()
  const teahcerLinksBlock = [
    { img: <CalendarMenuIcon />, ...teacher_calendar },
    { img: <StudentIcon />, ...teacher_students },
    { img: <MenuLessonHistoryIcon />, ...teacher_lesson_history },
    { img: <FactoryIcon />, ...teacher_exercise_factory },
    { img: <LessonMenuIcon />, ...teacher_classes },
    { img: <HomeWorkIcon />, ...teacher_homework },
    { img: <ChatIcon />, ...teacher_chat },
    { img: <LibraryMenuIcon />, ...teacher_library },

    {
      img:
        location.pathname !== '/courses' && !location.pathname.match(/course/) ? (
          <ConstructorIcon />
        ) : (
          <ConstructorIconActive />
        ),
      ...manager_constructor,
      name: 'Mini-course builder',
    },
  ]

  const managerLinksBlock = [
    { img: <StudentIcon />, ...manager_students },
    { img: <StudentPossibleIcon />, ...manager_students_possible },
    { img: <MenuAccountingIcon />, ...manager_accounting },
    { img: <MenuSearchIcon />, ...manager_search_teacher },
    { img: <AddUserIcon />, ...manager_register },
    {
      img:
        location.pathname !== '/courses' && !location.pathname.match(/course/) ? (
          <ConstructorIcon />
        ) : (
          <ConstructorIconActive />
        ),
      ...manager_constructor,
    },
    { img: <FactoryIcon />, ...manager_factory },
    { img: <UserIcon />, ...manager_search_user },
    { img: <StatisticsIcon />, ...manager_statistics },
  ]

  const salesManagerLinksBlock = [
    { img: <StudentIcon />, ...manager_students },
    { img: <StudentPossibleIcon />, ...manager_students_possible },
    { img: <MenuSearchIcon />, ...manager_search_teacher },
    { img: <AddUserIcon />, ...manager_register },
  ]

  const curatorLinksBlock = [
    { img: <StudentIcon />, ...manager_students },
    { img: <StudentPossibleIcon />, ...manager_students_possible },
    { img: <MenuSearchIcon />, ...manager_search_teacher },
  ]

  const methodistLinksBlock = [
    { img: <CalendarMenuIcon />, ...teacher_calendar },
    { img: <StudentIcon />, ...teacher_students },
    { img: <MenuLessonHistoryIcon />, ...teacher_lesson_history },
    { img: <LessonMenuIcon />, ...teacher_classes },
    { img: <LibraryMenuIcon />, ...teacher_library },
    { img: <HomeWorkIcon />, ...teacher_homework },
    { img: <ChatIcon />, ...teacher_chat },
    {
      img:
        location.pathname !== '/courses' && !location.pathname.match(/course/) ? (
          <ConstructorIcon />
        ) : (
          <ConstructorIconActive />
        ),
      ...manager_constructor,
    },
    { img: <FactoryIcon />, ...teacher_exercise_factory },
  ]

  const handleLogout = () => {
    authRef.signOut().then(() => {})
  }

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])
  const checkHomeworkDate = localStorage.getItem('checked.homework')
  useEffect(() => {
    async function checkIsUncheckedHomework() {
      const unCheckedHomeworkThreeDays =
        !checkHomeworkDate || moment().diff(moment(checkHomeworkDate), 'days') > 3
      if (unCheckedHomeworkThreeDays && teacherHomeWorkCount > 0) {
        setIsOpenModal(true)
      }
    }
    checkIsUncheckedHomework()
  }, [teacherHomeWorkCount, profileData.bhUpdated, checkHomeworkDate])
  const onCloseHomeworkModal = () => {
    localStorage.setItem('modal.unchecked.homework', 'true')
    setIsOpenModal(false)
  }

  useEffect(() => {
    if (teacherHomeWorkCount > 0) {
      if (localStorage.getItem('isShowHomeworkTooltip') == 'true') {
        setIsOpenTooltip(true)
      } else {
        setIsOpenTooltip(false)
      }
    }
  }, [teacherHomeWorkCount, isOpenModal])

  const handleHomeworkTooltip = () => {
    setIsOpenTooltip(false)
    localStorage.setItem('isShowHomeworkTooltip', 'false')
  }

  useOnClickOutside(navBarRef, () => {
    onClickDrawer && onClickDrawer(true)
  })
  return (
    <Styled.NavBar open={open} ref={navBarRef}>
      <IconButton
        className="drawer-btn"
        onClick={e => {
          e.preventDefault()
          onClickDrawer(false)
        }}
      >
        <img src={NavDrawerIcon} />
      </IconButton>

      <Styled.NavFaceContainer
        open={open}
        active={
          activeLink.includes(teacher_profile.path) || activeLink.includes(manager_profile.path)
        }
        to={`${!profileData.manager ? teacher_profile.path : manager_profile.path}/${
          profileData.id
        }`}
      >
        <Styled.NavFace
          open={open}
          style={{
            backgroundImage: `url(${profileData.avatar ? profileData.avatar : PersonIcon})`,
          }}
        />
        {open && (
          <div className="menu-text">
            <span>{'Profile'}</span>
          </div>
        )}
      </Styled.NavFaceContainer>
      <Styled.NavMenuContainer>
        {profileData.role === ROLES.MANAGER && (
          <>
            {managerLinksBlock.map((link, index) => {
              return (
                <Styled.NavMenuLink
                  key={index}
                  style={{ position: 'relative' }}
                  active={link.path === activeLink}
                  isSearchUser={link.path === '/manager/search/user'}
                  to={link.path}
                  open={open}
                >
                  {link.img}
                  <div className="menu-text">
                    <span>{link.name}</span>
                  </div>
                </Styled.NavMenuLink>
              )
            })}
          </>
        )}

        {profileData.role === ROLES.SALES_MANAGER && (
          <>
            {salesManagerLinksBlock.map((link, index) => {
              return (
                <Styled.NavMenuLink
                  key={index}
                  style={{ position: 'relative' }}
                  active={link.path === activeLink}
                  to={link.path}
                  open={open}
                >
                  {link.img}
                  <div className="menu-text">
                    <span>{link.name}</span>
                  </div>
                </Styled.NavMenuLink>
              )
            })}
          </>
        )}

        {profileData.role === ROLES.MANAGER_ASSISTANT && (
          <>
            {curatorLinksBlock.map((link, index) => {
              return (
                <Styled.NavMenuLink
                  key={index}
                  style={{ position: 'relative' }}
                  active={link.path === activeLink}
                  to={link.path}
                  open={open}
                >
                  {link.img}
                  <div className="menu-text">
                    <span>{link.name}</span>
                  </div>
                </Styled.NavMenuLink>
              )
            })}
          </>
        )}

        {profileData.role === ROLES.TEACHER && (
          <>
            {teahcerLinksBlock.map((link, index) => (
              <Styled.NavMenuLink
                open={open}
                key={`${link.path}_${index}`}
                style={{ position: 'relative' }}
                active={
                  link.path === teacher_calendar.path
                    ? link.path === activeLink
                    : activeLink.includes(link.path)
                }
                to={link.path}
              >
                {link.path === teacher_homework.path &&
                teacherHomeWorkCount > 0 &&
                isOpenTooltip ? (
                  <div className="nav-item-notification" onClick={handleHomeworkTooltip}>
                    {link.img}
                  </div>
                ) : (
                  link.img
                )}

                {link.path === teacher_homework.path && teacherHomeWorkCount > 0 && (
                  <CustomCounter isHomeWorkNotCheked={isOpenTooltip} open={open}>
                    {teacherHomeWorkCount}
                  </CustomCounter>
                )}
                {link.path === teacher_chat.path && chat?.unreadMessages?.length > 0 && (
                  <CustomCounter open={open}>{chat.unreadMessages.length}</CustomCounter>
                )}

                {link.path === teacher_homework.path &&
                teacherHomeWorkCount > 0 &&
                isOpenTooltip ? (
                  <div className="homework-notification">
                    <img className="left-arrow" src={LeftArrowIcon} />
                    <span>You have unchecked homework assignments</span>
                  </div>
                ) : (
                  <div className="menu-text">
                    <span>{link.name}</span>
                  </div>
                )}
              </Styled.NavMenuLink>
            ))}
          </>
        )}

        {profileData.role === ROLES.METHODIST && (
          <>
            {methodistLinksBlock.map((link, index) => (
              <Styled.NavMenuLink
                open={open}
                key={index}
                style={{ position: 'relative' }}
                active={
                  link.path === teacher_calendar.path
                    ? link.path === activeLink
                    : activeLink.includes(link.path)
                }
                to={link.path}
              >
                {link.img}
                {link.path === teacher_homework.path && teacherHomeWorkCount > 0 && (
                  <CustomCounter>{teacherHomeWorkCount}</CustomCounter>
                )}

                {link.path === teacher_chat.path && chat?.unreadMessages?.length > 0 && (
                  <CustomCounter>{chat.unreadMessages.length}</CustomCounter>
                )}
                <div className="menu-text">
                  <span>{link.name}</span>
                </div>
              </Styled.NavMenuLink>
            ))}
          </>
        )}

        <div style={{ marginTop: 'auto', width: '100%' }}>
          <Styled.NavMenuLink
            open={open}
            active={activeLink === support.path}
            to={support.path}
            onClick={e => {
              openSupport(e, profileData)
            }}
          >
            <SupportIcon />
            <div className="menu-text">
              <span>{support.name}</span>
            </div>
          </Styled.NavMenuLink>
          <Styled.NavMenuLink
            active={activeLink === logout.path}
            to=""
            onClick={handleLogout}
            open={open}
          >
            <LogoutIcon />
            <div className="menu-text">
              <span>{logout.name}</span>
            </div>
          </Styled.NavMenuLink>
        </div>
      </Styled.NavMenuContainer>
      <MainModal
        open={isOpenModal}
        children={() => (
          <Styled.UncheckeddHomeworkContainer>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 7V14M12 16.99V17M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                stroke="#FF922D"
                strokeWidth="1.5"
                stroke-linecap="round"
              />
            </svg>

            <Styled.UncheckeddHomeworkTitle>
              You have unchecked homework assignments
            </Styled.UncheckeddHomeworkTitle>
            <Styled.UncheckedHomeworkDesc>
              {' '}
              Please check these homework assignments as soon as possible.
            </Styled.UncheckedHomeworkDesc>
            <Styled.UncheckedHomeworkDesc>
              Students are less motivated to learn if they see that their homework is not checked
              for a long time.{' '}
            </Styled.UncheckedHomeworkDesc>
            <Styled.UnCheckedHomeworkButton
              onClick={() => {
                onCloseHomeworkModal()
                history.push(teacher_homework.path)
              }}
            >
              Check the homework
            </Styled.UnCheckedHomeworkButton>

            <Styled.UnCheckedHomeworkClose onClick={onCloseHomeworkModal}>
              Close
            </Styled.UnCheckedHomeworkClose>
          </Styled.UncheckeddHomeworkContainer>
        )}
        handleClose={onCloseHomeworkModal}
      />
    </Styled.NavBar>
  )
}
