import React from "react";

export default function Font14Icon() {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.28625 9.86559L6.57046 7.52688H2.97123L2.25543 9.86559H0L3.48444 0H6.04374L9.54169 9.86559H7.28625ZM6.07075 5.77957C5.40898 3.66039 5.03533 2.46192 4.94979 2.18414C4.86876 1.90636 4.81023 1.68683 4.77422 1.52554C4.62566 2.09901 4.20023 3.51702 3.49794 5.77957H6.07075Z"
        fill="white"
      />
      <path
        d="M15.5617 9.86559L15.1632 8.84409H15.1092C14.7626 9.27867 14.4047 9.58109 14.0355 9.75134C13.6709 9.91711 13.1937 10 12.6039 10C11.8791 10 11.3074 9.79391 10.8887 9.38172C10.4746 8.96953 10.2675 8.38262 10.2675 7.62097C10.2675 6.82348 10.5466 6.23656 11.1048 5.86022C11.6676 5.47939 12.5139 5.26882 13.6439 5.22849L14.9539 5.18817V4.85887C14.9539 4.09722 14.5622 3.7164 13.7789 3.7164C13.1757 3.7164 12.4666 3.89785 11.6518 4.26075L10.9698 2.87634C11.8386 2.42384 12.802 2.19758 13.86 2.19758C14.8729 2.19758 15.6494 2.41711 16.1897 2.85618C16.7299 3.29525 17 3.96281 17 4.85887V9.86559H15.5617ZM14.9539 6.38441L14.1571 6.41129C13.5583 6.42921 13.1126 6.53674 12.82 6.73387C12.5274 6.931 12.3811 7.23118 12.3811 7.63441C12.3811 8.21237 12.7142 8.50134 13.3805 8.50134C13.8577 8.50134 14.2381 8.3647 14.5217 8.0914C14.8098 7.8181 14.9539 7.4552 14.9539 7.00269V6.38441Z"
        fill="white"
      />
    </svg>
  );
}
