import { TFunction } from 'i18next'

export function getExperienceDescription(
  teacherTeachingExperience: string | undefined,
  language: string,
  t: TFunction
) {
  if (!teacherTeachingExperience) return null

  if (language === 'ru') {
    let yearCaption = ''
    switch (Number(teacherTeachingExperience)) {
      case 1:
        yearCaption = 'год'
        break
      case 2:
      case 3:
      case 4:
        yearCaption = 'года'
        break
      default:
        yearCaption = 'лет'
        break
    }

    return `${t('AutoTeacher.TeachesYears', { years: teacherTeachingExperience })} ${yearCaption}`
  }

  return `Years of experience: ${teacherTeachingExperience}`
}
