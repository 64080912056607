import axios from 'axios'
import { Dispatch } from 'redux'

import { studentsRef, authRef, firebaseTimestamp, cardsRef } from '@configs/firebase'

import { IWord } from '@models/IWord'

import { WordsActionsTypes } from '@store/words/types'
import { addNewWordAction, deleteWordAction, setWords } from '@store/words/actions'
import { FIREBASE_CONFIG } from '@utils/constants'

export enum TranslationLangs {
  ESP = 'es',
  RUS = 'ru',
}

export const getVocabExamples = async (word: string) => {
  const prompt = `Genera tres frases cortas en español que contengan la palabra ${word}. Coloca cada frase dentro de la etiqueta <p>. Asegúrate de que la palabra ${word} esté en negrita usando <b>. No incluyas numeración ni listas.
`
  return axios
    .post(FIREBASE_CONFIG.generateOpenAITextLink, {
      prompt,
    })
    .then(res => res)
    .catch(error => {
      throw error
    })
}

export const getStudentWordsLearned = async (studentId: string) => {
  const snapshot = await studentsRef
    .doc(studentId)
    .collection('words')
    .where('progress', '==', 4)
    .get()

  const words = snapshot.docs.map(doc => ({
    ...doc.data(),
    uid: doc.id,
  })) as IWord[]
  return words
}

export const getStudentWords = async (studentId: string) => {
  const snapshot = await studentsRef
    .doc(studentId)
    .collection('words')
    .orderBy('start', 'desc')
    .get()

  let words = snapshot.docs.map(doc => ({
    ...doc.data(),
    uid: doc.id,
  })) as IWord[]

  return words
}

export const getWordsAndSave = () => {
  return async (dispatch: Dispatch<WordsActionsTypes>) => {
    let cards = await cardsRef.doc('S2QzdgVB8hjIlq3QyaOE').get()
    const cardWords = cards.data()?.cards || []
    const cwords = []

    const studentLang = localStorage.getItem('lang')

    cardWords.map(ele => {
      cwords.push(
        ...ele.words.map(word => {
          return {
            learnword: word.learnWord,
            nativeword: studentLang === 'ru' ? word.translate?.ru : word.translate?.en,
            uid: `${Math.random()
              .toString(16)
              .slice(2)}`,
          }
        })
      )
    })
    const studentId = authRef.currentUser.uid
    const words: IWord[] = await getStudentWords(studentId)
    dispatch(setWords([...words, ...cwords]))
  }
}

export const deleteWord = async (studentUid: string, wordUid: string) => {
  await studentsRef
    .doc(studentUid)
    .collection('words')
    .doc(wordUid)
    .delete()
}

export const deleteWordAndUpdateState = (studentUid: string, wordUid: string) => {
  return async (dispatch: Dispatch<WordsActionsTypes>) => {
    try {
      await deleteWord(studentUid, wordUid)
      dispatch(deleteWordAction(wordUid))
    } catch (err) {
      console.error(err)
    }
  }
}

export const addNewWord = async (studentUid: string, wordItem) => {
  const newWord = { ...wordItem, start: firebaseTimestamp.now(), progress: 0 }
  const res = await studentsRef
    .doc(studentUid)
    .collection('words')
    .add(newWord)

  return res.id
}

export const addNewWordAndSave = (studentUid: string, wordItem) => {
  return async (dispatch: Dispatch<WordsActionsTypes>) => {
    const newWord = { ...wordItem, start: firebaseTimestamp.now(), progress: 0 }
    try {
      const id = await addNewWord(studentUid, newWord)
      dispatch(addNewWordAction({ ...newWord, uid: id }))
    } catch (err) {
      console.error(err)
    }
  }
}
