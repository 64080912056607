import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'
import screenfull from 'screenfull'
import classNames from 'classnames'
import * as Styled from './PlaybackRateDropdown.js'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import 'font-awesome/css/font-awesome.min.css'
import { videoPlayerConfig, PLAYER_STATE } from '@utils/constants'

import { emitNewPlayerStateToServer } from '@servise/syncAPI'

import { videoPlayerActions } from '@store/videoPlayer'

import VideoPlayerControls from '../videoPlayerControls/VideoPlayerControls.js'
import Audio from '../Audio/index.jsx'

import { HideIcon } from '@components/Icons'

import './VideoPlayer.css'
import { getYouTubePreviewImage } from '@utils/helperst'

const { paused, playing, buffering } = PLAYER_STATE

class VideoPlayer extends Component {
  static propTypes = {
    classID: PropTypes.string.isRequired,
    videoPlayerIsMuted: PropTypes.bool,
    videoPlayerIsLoaded: PropTypes.bool,
    videoProgress: PropTypes.number.isRequired,
    userVideoPlayerState: PropTypes.object.isRequired,
    partyVideoPlayerState: PropTypes.object.isRequired,
    onPlayerStateChange: PropTypes.func.isRequired,
    emitNewPlayerStateToServer: PropTypes.func.isRequired,
    setPlayerMutedState: PropTypes.func.isRequired,
    setPlayerProgress: PropTypes.func.isRequired,
    setPlayerIsLoadedState: PropTypes.func.isRequired,
  }

  state = { videoPlayerIsMaximized: false, playbackRate: 1, isOpen: false }

  constructor(props) {
    super(props)
    const { setPlayerIsLoadedState } = props

    // Initially -> always make sure that the videoPlayerLoaded state is
    // reset to false

    setPlayerIsLoadedState(false)
  }

  componentDidMount() {
    const { selectedVideo, setPlayerIsHidden, fileData } = this.props
    if (selectedVideo === fileData.id) {
      setPlayerIsHidden(fileData.isHidden)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      videoPlayerIsLoaded,
      userVideoPlayerState,
      prevPlayerState,
      videoProgress,
      classID,
      userState,
      setPlayerIsHidden,
      selectedVideo,
      fileData,
    } = this.props
    if (selectedVideo === fileData.id) {
      setPlayerIsHidden(fileData.isHidden)

      const prevPartyPlayerState = prevProps.partyVideoPlayerState

      const currentPartyPlayerState = this.props.partyVideoPlayerState
      const userIsBuffering = userVideoPlayerState.playerState === buffering
      const internalVideoPlayer = this.videoPlayer
      const isVideoplayerStateBufering =
        prevProps.userVideoPlayerState.playerState !== userVideoPlayerState.playerState
      const prevPropsIsBufering = prevProps.userVideoPlayerState.playerState === buffering
      if (prevProps.userState.studentIsOnline !== userState.studentIsOnline) {
        if (userState.studentIsOnline) {
          emitNewPlayerStateToServer(
            {
              playerState: PLAYER_STATE.paused,
              timeInVideo: videoProgress,
            },
            classID
          )
        }
      }
      if (prevProps.userState.teacherIsOnline !== userState.teacherIsOnline) {
        if (userState.teacherIsOnline) {
          emitNewPlayerStateToServer(
            {
              playerState: paused,
              timeInVideo: videoProgress,
            },
            classID
          )
        }
      }

      if (isVideoplayerStateBufering) {
        if (prevPropsIsBufering && !userIsBuffering) {
          emitNewPlayerStateToServer(
            {
              playerState: prevPlayerState,
              timeInVideo: userVideoPlayerState.timeInVideo,
            },
            classID
          )
        }
      }

      // As soon as the videoPlayer is loaded, start listening for playerStateChange commands from the server
      if (videoPlayerIsLoaded && internalVideoPlayer && !userIsBuffering) {
        // Handle initial synchronization of our videoPlayer with the rest of the party when we first join a party
        this.handleInitialPlayerStateSynchronization(currentPartyPlayerState, internalVideoPlayer)

        // Handle when a user 'seeks' in a video
        this.handleSeekToCommandsFromServer(
          prevPartyPlayerState,
          currentPartyPlayerState,
          internalVideoPlayer
        )
      }
    }
  }

  handleInitialPlayerStateSynchronization = (currentPartyPlayerState, internalVideoPlayer) => {
    const isInitialPlayerStateForParty = currentPartyPlayerState.timeInVideo === 0
    const isInitialPlayerStateForUser = internalVideoPlayer.getCurrentTime() === 0
    if (!isInitialPlayerStateForParty && isInitialPlayerStateForUser) {
      internalVideoPlayer.seekTo(currentPartyPlayerState.timeInVideo)
    }
  }

  handleSeekToCommandsFromServer = (
    prevPartyPlayerState,
    currentPartyPlayerState,
    internalVideoPlayer
  ) => {
    const partyPlayerStateUpdated = prevPartyPlayerState !== currentPartyPlayerState

    const isNewPlayerState =
      prevPartyPlayerState.playerState !== currentPartyPlayerState.playerState ||
      prevPartyPlayerState.timeInVideo !== currentPartyPlayerState.timeInVideo

    if (partyPlayerStateUpdated && isNewPlayerState) {
      internalVideoPlayer.seekTo(currentPartyPlayerState.timeInVideo)
    }
  }

  constructUserPlayerState = (playerState, videoPlayer) => {
    return {
      playerState,
      timeInVideo: videoPlayer
        ? videoPlayer.getCurrentTime()
        : this.props.partyVideoPlayerState.timeInVideo,
      classID: this.props.classID,
      isLoaded: videoPlayer.getSecondsLoaded(),
    }
  }

  handleMaximizeBtnPressed = (playerCurrentlyMaximized, videoPlayerElem) => {
    screenfull.request(findDOMNode(this.videoPlayer))
  }

  changeFile = () => {
    const { selectedVideo, classID, fileData } = this.props
    if (selectedVideo !== fileData.id) {
      emitNewPlayerStateToServer(
        {
          playerState: playing,
          prevPlayerState: playing,
          timeInVideo: 0,
          selectedVideo: fileData.id,
        },
        classID
      )
    }
  }

  handleDuration = duration => {
    this.props.setPlayerDuration(duration)
  }

  render() {
    const {
      selectedVideo,
      partyVideoPlayerState,
      videoPlayerIsMuted,
      onPlayerStateChange,
      setPlayerProgress,
      setPlayerMutedState,
      setPlayerIsLoadedState,
      videoDuration,
      fileData,
      videoProgress,
      classID,
    } = this.props
    const { videoPlayerIsMaximized } = this.state

    var light = false
    const videoPlayer = this.videoPlayer
    light = fileData.id !== selectedVideo || fileData.isHidden

    const audio = fileData.type === 'audio'
    const height = audio ? { height: '30px' } : { height: '100%' }

    const pointerEvents = fileData.isHidden ? { pointerEvents: 'none' } : null

    const videoIsPlaying =
      partyVideoPlayerState.playerState === playing && fileData.id === selectedVideo
    const wrapperClasses = audio ? 'video-player' : 'video-player player-wrapper'

    const videoPlayerClassNames = classNames(wrapperClasses, {
      maximized: videoPlayerIsMaximized,
    })

    return (
      <div
        className={videoPlayerClassNames}
        style={{ ...height, ...pointerEvents, position: 'relative' }}
      >
        <ReactPlayer
          className="react-player"
          url={fileData.url}
          width={'100%'}
          {...height}
          muted={videoPlayerIsMuted}
          playing={videoIsPlaying}
          onDuration={this.handleDuration}
          ref={e => (this.videoPlayer = e)}
          playbackRate={this.state.playbackRate}
          onBufferEnd={() => {
            onPlayerStateChange(this.constructUserPlayerState(playing, videoPlayer))
          }}
          onClick={this.changeFile}
          onReady={() => {
            setPlayerIsLoadedState(true)
          }}
          onEnded={() => {
            emitNewPlayerStateToServer(
              {
                playerState: paused,
                timeInVideo: 0,
              },
              classID
            )
          }}
          onPlay={() => {
            onPlayerStateChange(this.constructUserPlayerState(playing, videoPlayer))
          }}
          onPause={() => onPlayerStateChange(this.constructUserPlayerState(paused, videoPlayer))}
          onBuffer={() => {
            onPlayerStateChange(this.constructUserPlayerState(buffering, videoPlayer))
          }}
          onProgress={setPlayerProgress}
          config={videoPlayerConfig}
          light={light ? getYouTubePreviewImage(fileData.url) : false}
          style={light ? { position: 'absolute' } : { position: 'absolute', pointerEvents: 'none' }}
        />
        {!audio && !light && (
          <VideoPlayerControls
            partyVideoPlayerState={partyVideoPlayerState}
            classID={classID}
            videoPlayerIsMuted={videoPlayerIsMuted}
            videoPlayerIsMaximized={videoPlayerIsMaximized}
            videoProgress={videoProgress}
            videoDuration={videoDuration}
            handleMuteBtnPressed={() => setPlayerMutedState(!videoPlayerIsMuted)}
            handleMaximizeBtnPressed={() =>
              this.handleMaximizeBtnPressed(
                videoPlayerIsMaximized,
                document.getElementsByClassName('video-player')[0]
              )
            }
          />
        )}
        {audio && !light && (
          <div style={{ position: 'absolute', top: '7x', right: '-1px' }}>
            <Styled.PlaybackRateInfo onClick={() => this.setState({ ...this.state, isOpen: true })}>
              <Styled.ChosenPlaybackRate>
                {this.state.playbackRate === 1
                  ? `${this.state.playbackRate}X`
                  : this.state.playbackRate}
              </Styled.ChosenPlaybackRate>
            </Styled.PlaybackRateInfo>
            {this.state.isOpen && (
              <ClickAwayListener
                onClickAway={() => this.setState({ ...this.state, isOpen: false })}
              >
                <Styled.RateDropdownContainer>
                  <Styled.Options style={{ height: 90 }}>
                    {[1, 0.5, 0.75, 1.25].map(rate => (
                      <Styled.PlaybackRateItem
                        key={rate}
                        onClick={() =>
                          this.setState({ ...this.state, playbackRate: rate, isOpen: false })
                        }
                      >
                        {`${rate}x`}
                      </Styled.PlaybackRateItem>
                    ))}
                  </Styled.Options>
                </Styled.RateDropdownContainer>
              </ClickAwayListener>
            )}
          </div>
        )}
        {fileData.isHidden && <HideIcon />}
        {audio && !light && <Audio fileUrl={fileData.url} />}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    selectedVideo: state.sync.reactPlayerState.selectedVideo,

    prevPlayerState: state.sync.reactPlayerState.prevPlayerState,
    partyVideoPlayerState: state.sync.reactPlayerState.videoPlayerState,
    userVideoPlayerState: state.videoPlayer.videoPlayerState,
    videoPlayerIsMuted: state.videoPlayer.videoPlayerIsMuted,
    videoDuration: state.videoPlayer.videoDuration,
    videoProgress: state.videoPlayer.videoProgress,
    videoPlayerIsMaximized: state.videoPlayer.videoPlayerIsMaximized,
    videoPlayerIsLoaded: state.videoPlayer.videoPlayerIsLoaded,
    userState: state.sync.userState,
  }
}

const mapDispatchToProps = {
  onPlayerStateChange: videoPlayerActions.onPlayerStateChange,
  setPlayerMutedState: videoPlayerActions.setPlayerMutedState,
  setPlayerIsLoadedState: videoPlayerActions.setPlayerIsLoadedState,
  setPlayerProgress: videoPlayerActions.setPlayerProgress,
  setPlayerDuration: videoPlayerActions.setPlayerDurationState,
  setPlayerIsHidden: videoPlayerActions.setPlayerIsHidden,
}
VideoPlayer = connect(mapStateToProps, mapDispatchToProps)(VideoPlayer)
export default VideoPlayer
