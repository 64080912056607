import React from 'react'
function ReduceIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="28"
        height="28"
        rx="14"
        fill="#336A93"
        stroke="#336A93"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C8 14.4477 8.6268 14 9.4 14H20.6C21.3732 14 22 14.4477 22 15C22 15.5523 21.3732 16 20.6 16H9.4C8.6268 16 8 15.5523 8 15Z"
        fill="white"
      />
    </svg>
  )
}

export default ReduceIcon
