import React from 'react'
function AddUserIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.1967 23.1967C3.60322 21.7902 5.51088 21 7.5 21H18C19.9891 21 21.8968 21.7902 23.3033 23.1967C24.7098 24.6032 25.5 26.5109 25.5 28.5V31.5C25.5 32.3284 24.8284 33 24 33C23.1716 33 22.5 32.3284 22.5 31.5V28.5C22.5 27.3065 22.0259 26.1619 21.182 25.318C20.3381 24.4741 19.1935 24 18 24H7.5C6.30653 24 5.16193 24.4741 4.31802 25.318C3.47411 26.1619 3 27.3065 3 28.5V31.5C3 32.3284 2.32843 33 1.5 33C0.671573 33 0 32.3284 0 31.5V28.5C0 26.5109 0.790177 24.6032 2.1967 23.1967Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 6C10.2647 6 8.25 8.01472 8.25 10.5C8.25 12.9853 10.2647 15 12.75 15C15.2353 15 17.25 12.9853 17.25 10.5C17.25 8.01472 15.2353 6 12.75 6ZM5.25 10.5C5.25 6.35786 8.60786 3 12.75 3C16.8921 3 20.25 6.35786 20.25 10.5C20.25 14.6421 16.8921 18 12.75 18C8.60786 18 5.25 14.6421 5.25 10.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 10.5C30.8284 10.5 31.5 11.1716 31.5 12V21C31.5 21.8284 30.8284 22.5 30 22.5C29.1716 22.5 28.5 21.8284 28.5 21V12C28.5 11.1716 29.1716 10.5 30 10.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 16.5C24 15.6716 24.6716 15 25.5 15H34.5C35.3284 15 36 15.6716 36 16.5C36 17.3284 35.3284 18 34.5 18H25.5C24.6716 18 24 17.3284 24 16.5Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default AddUserIcon
