import React from 'react'

export default function BoardIcon(props) {
  return (
    <svg viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.10526 5.5H23.2632V16.5227H26V4.5625C26 3.70078 25.2328 3 24.2895 3H3.07895C2.13559 3 1.36842 3.70078 1.36842 4.5625V19.25H4.10526V5.5ZM21.7081 20.5H14.6877V18H6.47719V20.5H0.684211C0.306184 20.5 0 20.7797 0 21.125V22.375C0 22.7203 0.306184 23 0.684211 23H21.7081C22.0862 23 22.3923 22.7203 22.3923 22.375V21.125C22.3923 20.7797 22.0862 20.5 21.7081 20.5Z" />
      <path
        d="M7.5 12.5C8 9.0001 10.2971 7.72299 12 9.00009C14 10.5 12.3696 15.5 15.5 15.5C18.0043 15.5 18.5 13.5001 18.5 12.0001"
        strokeWidth="2"
        stroke={props.board ? '#E5E5E5' : null}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
