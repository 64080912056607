import * as React from 'react'

const HomeworkMenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18V5H16V18C16 18.3506 16.0602 18.6872 16.1707 19H5C4.44772 19 4 18.5523 4 18ZM19 21H5C3.34315 21 2 19.6569 2 18V3H16H17H18V8H22V9V10V18C22 19.6569 20.6569 21 19 21ZM18 10H20V18C20 18.5523 19.5523 19 19 19C18.4477 19 18 18.5523 18 18V10ZM10 7V11H6V7H10ZM14 10V8H11V10H14ZM14 12V14H6V12H14ZM14 17V15H6V17H14Z"
      />
    </svg>
  )
}

export default HomeworkMenuIcon
