import styled from 'styled-components'

export const ActiveTag = styled.div`
    color: #1556CF;
    font-family: 'Open Sans';
    font-weight: bold;
    cursor: pointer;
`

export const NormalTag = styled.div`
    color: #7E92AC;
    font-family: 'Open Sans';
    font-weight: bold;
    cursor: pointer;
`