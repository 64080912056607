import React from 'react'
import * as Styled from './styles'

interface EventModalDialogProps {
  title: string
  text: string
  visibilityDialog: boolean
  onClick: () => void
}

export const EventModalDialog: React.FC<EventModalDialogProps> = ({
  title,
  text,
  visibilityDialog,
  onClick,
}) => {
  return visibilityDialog ? (
    <Styled.DialogContainer>
      <Styled.DialogTitle>{title}</Styled.DialogTitle>
      <Styled.DialogButton onClick={onClick}>
        <Styled.DialogButtonText>{text}</Styled.DialogButtonText>
      </Styled.DialogButton>
    </Styled.DialogContainer>
  ) : null
}
