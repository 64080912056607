import * as React from 'react'

const CardEmailIcon = props => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#C2CFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40015 8.85125C6.40015 8.85125 11.2299 11.3794 12.1673 11.3794C13.1499 11.3794 17.9273 8.89954 17.9273 8.89954L17.935 8.45647C17.935 7.96731 17.5049 7.57031 16.9736 7.57031H7.36111C6.83023 7.57031 6.40015 7.96731 6.40015 8.45647V8.85125ZM17.9273 10.2289C17.9273 10.2289 13.195 12.598 12.1673 12.598C11.2299 12.598 6.40735 10.2289 6.40783 10.2289L6.40015 15.5458C6.40015 16.035 6.83071 16.432 7.36111 16.432H16.9736C17.5049 16.432 17.935 16.035 17.935 15.5458L17.9273 10.2289Z"
        fill="white"
      />
    </svg>
  )
}

export default CardEmailIcon
