import { IS_DEV_MODE } from './constants'

export enum LogLevelType {
  off = -1,
  error = 0,
  warn = 1,
  info = 2,
  debug = 3,
  silly = 4,
}
const level = IS_DEV_MODE ? LogLevelType.silly : LogLevelType.off
export class ConsoleLogger {
  constructor() {}
  log = (function() {
    if (level !== LogLevelType.off) {
      return Function.prototype.bind.call(
        console.log,
        console,
        '%c' + '[ LOG ] ' + new Date().toISOString() + ': ',
        'color:#7398ff'
      )
    } else {
      return () => {}
    }
  })()

  debug = (function() {
    if (level >= LogLevelType.debug) {
      return Function.prototype.bind.call(
        console.log,
        console,
        '%c' + '[ DEBUG ] ' + new Date().toISOString() + ': ',
        'color:#7398ff'
      )
    } else {
      return () => {}
    }
  })()

  warn = (function() {
    if (level >= LogLevelType.warn) {
      return Function.prototype.bind.call(
        console.warn,
        console,
        '%c' + '[ WARNING ] ' + new Date().toISOString() + ': ',
        'color:#FFD700'
      )
    } else {
      return () => {}
    }
  })()

  info = (function() {
    if (level >= LogLevelType.info) {
      return Function.prototype.bind.call(
        console.log,
        console,
        '%c' + '[ INFO ] ' + new Date().toISOString() + ': ',
        'color:#ADD8E6'
      )
    } else {
      return () => {}
    }
  })()

  error = (function() {
    if (level >= LogLevelType.error) {
      return Function.prototype.bind.call(
        console.error,
        console,
        '%c' + '[ ERROR ] ' + new Date().toISOString() + ': ',
        'color:#DC143C'
      )
    } else {
      return () => {}
    }
  })()

  silly = (function() {
    if (level >= LogLevelType.silly) {
      return Function.prototype.bind.call(
        console.debug,
        console,
        '%c' + '[ SILLY ] ' + new Date().toISOString() + ': ',
        'color:#FFB6C1'
      )
    } else {
      return () => {}
    }
  })()
}

export const logger = new ConsoleLogger()
