import React from 'react'

import { IClassroomBlock, IReactPlayerState } from '@models'

import { AUTH_STATUS, CLASSROOM_BLOCK_TYPES } from '@utils/constants'
import VideoPlayer from '@components/videoPlayer'
import SettingsBlock from './Settings'

import * as Styled from './classroom-files.styles'
import UpArrow from '@images/UpArrow.svg'
import DownArrow from '@images/DownArrow.svg'
import { changeBlockPosition } from '@servise/classroomAPI'
import { AppDispatch } from '@store/index'
import { useDispatch } from 'react-redux'

type TypeProps = {
  fileData: IClassroomBlock
  authStatus: AUTH_STATUS
  classID: string
  reactPlayerState: IReactPlayerState
  isStudent: Boolean
  playbackRate: number
}

const Media = (props: TypeProps) => {
  const dispatch: AppDispatch = useDispatch()

  const handleUpSide = () => {
    dispatch(changeBlockPosition(props.classID, props.fileData.id, true))
  }
  const handleDownSide = () => {
    dispatch(changeBlockPosition(props.classID, props.fileData.id, false))
  }

  return (
    <Styled.FileContainer id={props.fileData.id} type={props.fileData.type}>
      <Styled.FileText>{props.fileData.file_name}</Styled.FileText>
      <Styled.MediaContainer
        style={props.fileData.type === CLASSROOM_BLOCK_TYPES.VIDEO ? { height: 'auto' } : null}
      >
        <VideoPlayer
          fileData={props.fileData}
          classID={props.classID}
          authStatus={props.authStatus}
          reactPlayerState={props.reactPlayerState}
          playbackState={props.playbackRate}
        />
      </Styled.MediaContainer>
      <SettingsBlock fileData={props.fileData} />
      {!props.isStudent ? (
        <Styled.UpDownBlock type={props.fileData.type}>
          <img src={UpArrow} alt="UpArrow" onClick={handleUpSide} />
          <img src={DownArrow} alt="DownArrow" onClick={handleDownSide} />
        </Styled.UpDownBlock>
      ) : null}
    </Styled.FileContainer>
  )
}

export default Media
