import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'
// import { database as db } from '@configs/firebase'
import 'firebase/firestore'
import * as Style from '@components/Homework/HomeWorkCard/homework-card.styles'
import closeIcon from '@assets/icons/round-cancel.svg'
import { formatDateToTemplate } from '@utils/helperst'

import { EngHomeworkStatuses, HomeworkStatuses, ILessonHomework } from '@models/IHomework'

import { getTeacherHomeWorksCount, removeCheckedHomeWork, saveHomeWork } from '@servise/homeworkAPI'

import { RootState } from '@store'
import { Button, Chip, HomeWorkComment, HomeWorkWriting, MainModal, Text } from '@components'
import HomeWorkDescription from '../HomeWorkDescription'
import TopContent, { ClassName, Top } from '../TopContent'
import HomeWorkStatus from '../Status'

import * as Styled from './teacher-homework-card.styles'
import { IStudentProfile } from '@models/IProfile'
import AudioPlayerWaveForm from '@components/Student/TeacherCard/AudioPlayerWaveForm'
import { firebaseTimestamp, storageRef, studentsRef } from '@configs/firebase'
import { useTranslation } from 'react-i18next'
import CheckedAIAvater from '../../../assets/images/CheckedAIAvater.png'
import { getPageCountInfo } from '@pages/student/StudentHomework/helpers'
import { MistakesHint } from '@components/MistakesHint'
import DeleteHomeworkModal from './DeleteHomeworkModal'

interface Props {
  uid: string
  className: string
  studentAvatar: string
  studentName: string
  isWriting: boolean
  isAudio: boolean
  description: string
  date: firebase.firestore.Timestamp
  status: HomeworkStatuses
  writing?: string
  audioURL: string
  teacherWriting: string
  checked: boolean
  comment: string
  studentId: string
  student: IStudentProfile
  homeworkId?: string
  isHomeWork?: boolean
  suggestionByAI?: string
  teacherAvatar?: string
  teacherName?: string

  lessonHomework?: ILessonHomework
}

const TeacherHomeWorkCard = ({
  uid,
  className,
  isWriting,
  isAudio,
  description,
  date,
  status,
  writing,
  audioURL,
  teacherWriting,
  checked,
  studentAvatar,
  studentName,
  comment,
  homeworkId,
  studentId,
  student,
  isHomeWork,
  suggestionByAI,
  lessonHomework,
}: Props) => {
  const loading = useSelector((state: RootState) => state.homework.loading)
  const teacherId = useSelector((state: RootState) => state.profile.profile.id)
  const [checking, setChecking] = useState<boolean>(
    comment && (status as HomeworkStatuses) === HomeworkStatuses.SENT
  )
  const [saved, setSaved] = useState(true)
  const [addTeacherWriting, setAddTeacherWriting] = useState(teacherWriting)
  const [newComment, setNewComment] = useState(comment)
  const [isRemove, setIsRemove] = useState<boolean>(false)
  const [url, setUrl] = useState(null)
  const db = firebase.firestore()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const suggestionByAIComment = useMemo(() => {
    if (typeof suggestionByAI !== 'string') return ''
    let replaced =
      suggestionByAI.split('[').length > 1 ? suggestionByAI.split('[') : suggestionByAI.split('\n')
    replaced = replaced?.filter(item => !!item.trim())
    let comment = ''
    if (replaced && replaced.length > 1) {
      comment = replaced
        .slice(1)
        .join()
        .replace(']', '')
    }
    return comment
  }, [suggestionByAI])
  const handleSave = () => {
    dispatch(
      saveHomeWork(
        {
          comment: newComment ? newComment : suggestionByAIComment,
          status,
          homeworkId,
          studentId,
          teacherWriting: addTeacherWriting || '',
          writing,
          suggestionByAI,
        },
        uid,
        false
      )
    ).then(() => {
      setSaved(true)
    })
  }

  const displayStatus = useMemo(() => {
    switch (status) {
      case HomeworkStatuses.NOT_READY:
        return EngHomeworkStatuses.NOT_STARTED

      case HomeworkStatuses.DONE:
        return EngHomeworkStatuses.DONE
      case HomeworkStatuses.CHECKED:
        return EngHomeworkStatuses.CHECKED
      case HomeworkStatuses.CHECKED_AI:
        return EngHomeworkStatuses.CHECKED_AI
      case HomeworkStatuses.SENT:
        return EngHomeworkStatuses.CHECKED_AI

      case HomeworkStatuses.IN_PROGRESS:
        return EngHomeworkStatuses.IN_PROGRESS

      default:
        return ''
    }
  }, [status])

  const handleDeleteHomework = useCallback(
    async (isMade = false) => {
      setIsRemove(false)
      dispatch(
        removeCheckedHomeWork(studentId, teacherId, uid, !!lessonHomework.isLessonHomework, isMade)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teacherId, studentId, uid]
  )

  const handleFinish = () => {
    dispatch(
      saveHomeWork(
        {
          comment: newComment ? newComment : suggestionByAIComment,
          status: HomeworkStatuses.CHECKED.toString(),
          studentId,
          teacherWriting: addTeacherWriting || '',
          writing: writing ?? '',
          audioURL: audioURL ?? '',
          homeworkId: homeworkId || '',
          suggestionByAI,
          checkAt: firebaseTimestamp.now(),
          description: description || '',
        },
        uid,
        false
      )
    ).then(() => {
      dispatch(getTeacherHomeWorksCount())
      setChecking(false)
    })
  }

  useEffect(() => {
    async function getURL() {
      if (audioURL) {
        const audio = await storageRef
          .ref('audio')
          .child(audioURL)
          .getDownloadURL()
        setUrl(audio)
      }
    }
    getURL()
  }, [audioURL])

  const studentMistakes = useMemo(() => {
    let totalMistakes = 0
    const answers = lessonHomework?.answers
    if (answers) {
      for (const pageId in answers.pageStates) {
        const pageState = answers.pageStates[pageId]
        for (const exerciseId in pageState) {
          const exercise = pageState[exerciseId]
          for (const i in exercise) {
            const item = exercise[i]
            if (item.try) {
              totalMistakes += item.try
            }
          }
        }
      }
    }
    return totalMistakes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonHomework.id])

  const { passedCount, totalCount } = useMemo(
    () => getPageCountInfo(lessonHomework?.answers?.progress),
    [lessonHomework]
  )

  const handleCloseMistakesToast = useCallback(async () => {
    await studentsRef
      .doc(studentId)
      .collection('homeworks')
      .doc(homeworkId)
      .update({ isToastClosed: true })
  }, [studentId, homeworkId])

  if (lessonHomework?.isLessonHomework) {
    return (
      <Styled.HomeWork>
        <Styled.HomeworkItem checked={checked}>
          <Style.CloseBtn className="closeDiv">
            <Style.CancelBtn
              src={closeIcon}
              className="closeBtn"
              onClick={() => setIsRemove(true)}
            />
          </Style.CloseBtn>
          <Top>
            <ClassName>{lessonHomework.name}</ClassName>
            <HomeWorkStatus status={displayStatus} />{' '}
          </Top>
          <Styled.Bottom>
            <Styled.CompletedPages>
              Completed pages: {passedCount}/{totalCount}
            </Styled.CompletedPages>
            <Styled.NumberOfMistakes>
              <span style={{ color: studentMistakes > 15 ? '#dd2e44' : '#7E92AC' }}>
                Number of mistakes:{' '}
              </span>
              <span style={{ color: studentMistakes > 15 ? '#dd2e44' : '#414752' }}>
                {studentMistakes}
              </span>
            </Styled.NumberOfMistakes>
            <Styled.ViewButton>
              <Link to={`/homework/${lessonHomework.id}/page/0/${studentId}`}>View</Link>
            </Styled.ViewButton>
          </Styled.Bottom>
          <MainModal
            open={isRemove}
            children={() => {
              return (
                <DeleteHomeworkModal
                  handleClose={() => setIsRemove(false)}
                  handleDeleteHomework={handleDeleteHomework}
                />
              )
            }}
            handleClose={() => setIsRemove(false)}
          />
        </Styled.HomeworkItem>
        {studentMistakes > 15 && !lessonHomework.isToastClosed ? (
          <MistakesHint onCloseToast={handleCloseMistakesToast} />
        ) : (
          ''
        )}
      </Styled.HomeWork>
    )
  }

  return (
    <Styled.HomeWork>
      <Styled.HomeworkItem checked={checked}>
        <Style.CloseBtn className="closeDiv">
          <Style.CancelBtn src={closeIcon} className="closeBtn" onClick={() => setIsRemove(true)} />
        </Style.CloseBtn>
        <TopContent
          student={student}
          className={className}
          studentName={`${student?.name} ${student?.lastName}`}
          studentAvatar={student?.avatar}
        />
        {date && <Text display="addHomeworkContent">{formatDateToTemplate(date.toDate())}</Text>}
        <HomeWorkDescription description={description} isHomeWork={isHomeWork} />
        {isWriting &&
          writing &&
          ((status as HomeworkStatuses) !== HomeworkStatuses.NOT_READY ||
            (status as HomeworkStatuses) !== HomeworkStatuses.IN_PROGRESS) && (
            <HomeWorkWriting
              status={status}
              writing={writing}
              isEditable={!checked}
              teacherWriting={teacherWriting}
              setAddTeacherWriting={setAddTeacherWriting}
              suggestionByAI={suggestionByAI}
              setSaved={setSaved}
            />
          )}
        {url && audioURL && isAudio && (status as HomeworkStatuses) !== HomeworkStatuses.NOT_READY && (
          <Styled.AudioButton className="audio-btn" isChecked={status !== HomeworkStatuses.SENT}>
            <AudioPlayerWaveForm
              id={'recorded_audio' + uid}
              audio={url}
              isProfile
              style={{
                background: 'rgba(194, 207, 224, 0.15)',
              }}
            />
            {status !== HomeworkStatuses.SENT && (
              <div>
                <HomeWorkStatus status={displayStatus} />
              </div>
            )}
          </Styled.AudioButton>
        )}
        {!checked && (
          <>
            {!isAudio && status === HomeworkStatuses.CHECKED_AI && (
              <Styled.AutocheckByAIHeading>Autocheck by AI</Styled.AutocheckByAIHeading>
            )}
            <Styled.Actions>
              {!checking && !isAudio && status === HomeworkStatuses.CHECKED_AI && (
                <>
                  <Button
                    actionButton={false}
                    border={true}
                    children={'Check and make changes'}
                    customStyles={{
                      color: '#FF922D',
                      borderRadius: '20px',
                      border: '2px solid #FFB800',
                      minWidth: '212px',
                      minHeight: '40px',
                      fontSize: '14px',
                      lineHeight: 'inherit',
                      margin: '20px 155px 20px 0',
                      position: 'absolute',
                      padding: 0,
                    }}
                    onClick={() => setChecking(true)}
                  />
                  <Button
                    whiteTheme={false}
                    children={'Agree and Save'}
                    customStyles={{
                      minWidth: '144px',
                      minHeight: '40px',
                      fontSize: '14px',
                      lineHeight: 'inherit',
                      padding: 0,
                    }}
                    onClick={() => handleFinish()}
                  />
                </>
              )}

              {!checking && status === HomeworkStatuses.SENT && (
                <Button
                  whiteTheme={false}
                  children={'Verify'}
                  customStyles={{
                    minWidth: '118px',
                    minHeight: '40px',
                    fontSize: '14px',
                    lineHeight: 'inherit',
                    padding: 0,
                  }}
                  onClick={() => setChecking(true)}
                />
              )}
              {checking && (
                <>
                  {(!comment && !newComment) || saved || isAudio ? (
                    <Button
                      actionButton={false}
                      children={'Cancel'}
                      customStyles={{
                        minWidth: '118px',
                        minHeight: '40px',
                        fontSize: '14px',
                        lineHeight: 'inherit',
                        padding: 0,
                      }}
                      onClick={() => setChecking(false)}
                    />
                  ) : (
                    <Button
                      actionButton={false}
                      children={'Save'}
                      customStyles={{
                        minWidth: '118px',
                        minHeight: '40px',
                        fontSize: '14px',
                        lineHeight: 'inherit',
                        padding: 0,
                      }}
                      onClick={() => {
                        handleSave()
                      }}
                    />
                  )}
                  <Button
                    whiteTheme={false}
                    children={'Send'}
                    disable={newComment?.length == 0}
                    customStyles={{
                      minWidth: '118px',
                      minHeight: '40px',
                      fontSize: '14px',
                      lineHeight: 'inherit',
                      padding: 0,
                      opacity: newComment?.length == 0 || newComment?.length == undefined ? 0.5 : 1,
                    }}
                    loading={loading}
                    onClick={() => newComment.length > 0 && handleFinish()}
                  />
                </>
              )}
            </Styled.Actions>
          </>
        )}

        {isWriting && checked && status && (
          <Styled.Actions>
            <HomeWorkStatus status={displayStatus} />
          </Styled.Actions>
        )}
        {homeworkId && studentId && !isWriting && !isAudio && (
          <Styled.ViewButton>
            <Link to={`/homework/${homeworkId}/page/0/${studentId}`}>View</Link>
          </Styled.ViewButton>
        )}
        <MainModal
          open={isRemove}
          children={() => {
            return (
              <DeleteHomeworkModal
                handleClose={() => setIsRemove(false)}
                handleDeleteHomework={handleDeleteHomework}
              />
            )
          }}
          handleClose={() => setIsRemove(false)}
        />
      </Styled.HomeworkItem>
      {!checking && !isAudio && status === HomeworkStatuses.CHECKED_AI && (
        <Styled.AutocheckByAICommentContainer>
          <Chip name={t('HomeWork.ArtificialIntelligence')} img={CheckedAIAvater} color="#C2CFE0" />
          <Styled.AutocheckByAIComment id="CheckByAIComment">
            {suggestionByAIComment}
          </Styled.AutocheckByAIComment>
        </Styled.AutocheckByAICommentContainer>
      )}
      {checking && (
        <Styled.CommentInput
          id="hmComment"
          onChange={e => {
            setNewComment(e.target.value)
            setSaved(false)
          }}
        >
          {newComment ? newComment : comment ? comment : suggestionByAIComment}
        </Styled.CommentInput>
      )}
      {comment && checked && <HomeWorkComment comment={comment} />}
    </Styled.HomeWork>
  )
}

export default TeacherHomeWorkCard
