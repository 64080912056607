import { CancelButton } from '@components/ClassroomBlocks/classroom-files.styles'
import { TeacherButton as Button } from '@components'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { CourseProgressType, ICourseProgress } from '@models/IClassroom'
import { Tooltip, ToolTipTitle } from '@pages/student/Classes/CurrentCourse/styles'

import Delete from '@assets/images/blocks/delete.svg'
export const ModalContianer = styled.div`
  margin: 40px 50px;
`
export const Modal = styled.div`
  width: 690px;
`

export const ModalTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #414752;
  margin-bottom: 30px;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`

export const EditProgressLessonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 280px;
  overflow-y: auto;
  background: #f3f5f9;
  opacity: 0.5;
  border-radius: 20px;
  padding: 10px 16px 10px 12px;
  gap: 16px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.bgLightSide1};
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 2px;
  }
`

export const EditLessonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Input = styled.input`
  background: #ffffff;
  height: 50px;
  border: 1px solid #c2cfe0;
  border-radius: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
`

export const LessonRowHeader = styled.span`
  display: flex;
  margin-bottom: 12px;
`

export const LabelHeader = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #414752;
`

export const AddProgressButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1556cf;
  width: fit-content;
  margin-top: 10px;
`

export const InfoIcon = styled.div`
  position: absolute;
  right: 20px;
  bottom: 12px;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`
export const DeleteBtn = styled.div`
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`

export const HelpIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 0.999998 10.3137 0.999999 7C0.999999 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
        fill="#7E92AC"
        stroke="#7E92AC"
        strokeWidth="2"
      />
      <rect x="6.20001" y="5.16699" width="1.58337" height="6.3335" rx="0.5" fill="white" />
      <circle cx="6.9917" cy="2.79169" r="0.791687" fill="white" />
    </svg>
  )
}
export const DeleteIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666672 5.00016C0.666672 4.53993 1.03977 4.16683 1.50001 4.16683H16.5C16.9602 4.16683 17.3333 4.53993 17.3333 5.00016C17.3333 5.4604 16.9602 5.8335 16.5 5.8335H1.50001C1.03977 5.8335 0.666672 5.4604 0.666672 5.00016Z"
        fill="#7E92AC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33334 2.50016C7.11233 2.50016 6.90036 2.58796 6.74408 2.74424C6.5878 2.90052 6.50001 3.11248 6.50001 3.3335V4.16683H11.5V3.3335C11.5 3.11248 11.4122 2.90052 11.2559 2.74424C11.0996 2.58796 10.8877 2.50016 10.6667 2.50016H7.33334ZM13.1667 4.16683V3.3335C13.1667 2.67045 12.9033 2.03457 12.4344 1.56573C11.9656 1.09689 11.3297 0.833496 10.6667 0.833496H7.33334C6.6703 0.833496 6.03441 1.09689 5.56557 1.56573C5.09673 2.03457 4.83334 2.67045 4.83334 3.3335V4.16683H3.16667C2.70643 4.16683 2.33334 4.53993 2.33334 5.00016V16.6668C2.33334 17.3299 2.59673 17.9658 3.06557 18.4346C3.53441 18.9034 4.1703 19.1668 4.83334 19.1668H13.1667C13.8297 19.1668 14.4656 18.9034 14.9344 18.4346C15.4033 17.9658 15.6667 17.3299 15.6667 16.6668V5.00016C15.6667 4.53993 15.2936 4.16683 14.8333 4.16683H13.1667ZM4.00001 5.8335V16.6668C4.00001 16.8878 4.0878 17.0998 4.24408 17.2561C4.40036 17.4124 4.61233 17.5002 4.83334 17.5002H13.1667C13.3877 17.5002 13.5996 17.4124 13.7559 17.2561C13.9122 17.0998 14 16.8878 14 16.6668V5.8335H4.00001Z"
        fill="#7E92AC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33334 8.3335C7.79358 8.3335 8.16667 8.70659 8.16667 9.16683V14.1668C8.16667 14.6271 7.79358 15.0002 7.33334 15.0002C6.8731 15.0002 6.50001 14.6271 6.50001 14.1668V9.16683C6.50001 8.70659 6.8731 8.3335 7.33334 8.3335Z"
        fill="#7E92AC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 8.3335C11.1269 8.3335 11.5 8.70659 11.5 9.16683V14.1668C11.5 14.6271 11.1269 15.0002 10.6667 15.0002C10.2064 15.0002 9.83334 14.6271 9.83334 14.1668V9.16683C9.83334 8.70659 10.2064 8.3335 10.6667 8.3335Z"
        fill="#7E92AC"
      />
    </svg>
  )
}

const getProgressInfo = (type: CourseProgressType) => {
  switch (type) {
    case CourseProgressType.native:
      return {
        tooltip: 'Уроков с носителем',
        title: 'Темы уроков с носителем',
        desc: 'Тема',
      }
    case CourseProgressType.grammar:
      return {
        tooltip: 'Урок по грамматике',
        title: 'Темы грамматических уроков',
        desc: 'Тема (напр. указательные местоимения)',
      }

    case CourseProgressType.probation:
      return {
        tooltip: 'Урок c первым учителем',
        title: 'Темы уроков c первым учителем',
        desc: 'Тема (напр. с кем будет урок)',
      }

    case CourseProgressType.music:
      return {
        tooltip: 'Музыкальный урок',

        title: 'Темы музыкальных уроков',
        desc: 'Тема (напр. название композиции)',
      }

    default:
      return {
        tooltip: 'Урок с носителем',
        title: 'Темы уроков с носителем',
        desc: 'Тема',
      }
  }
}

const EditCourseProgress = ({
  progress,
  onClose,
  onSave,
}: {
  progress: ICourseProgress
  onClose: () => void
  onSave: (data: ICourseProgress) => void
}) => {
  const [lessonProgress, setLessonProgress] = useState<ICourseProgress>(() => {
    let lessons = progress.lessons
    if (lessons.length === 0) {
      lessons = [
        { order: '', subject: '' },
        { order: '', subject: '' },
        { order: '', subject: '' },
      ]
    } else if (lessons.length === 1) {
      lessons = [...lessons, { order: '', subject: '' }, { order: '', subject: '' }]
    } else if (lessons.length === 2) {
      lessons = [...lessons, { order: '', subject: '' }]
    }

    return { ...progress, lessons }
  })

  const handleLesson = () => {
    setLessonProgress(prev => ({
      ...prev,
      lessons: [...prev.lessons, { order: null, subject: '' }],
    }))
  }

  const handleEditData = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target
    setLessonProgress(prev => {
      let lessons = prev.lessons

      lessons[index] = { ...lessons[index], [name]: value }

      return {
        ...prev,
        lessons,
      }
    })
  }

  const handleSave = () => {
    const lessons = lessonProgress.lessons.filter(l => l.order.trim())
    onSave({ ...lessonProgress, lessons })
  }

  const progressInfo = useMemo(() => {
    return getProgressInfo(lessonProgress.type)
  }, [lessonProgress])

  const handleRemove = (order: number) => {
    setLessonProgress(prev => {
      const lessons = prev.lessons.filter(lesson => lesson.order !== order)
      return { ...prev, lessons }
    })
  }
  return (
    <Modal>
      <ModalContianer>
        <ModalTitle>{progressInfo.title}</ModalTitle>
        <ModalBody>
          <LessonRowHeader>
            <LabelHeader style={{ marginRight: 40, width: 100 }}>№ урока</LabelHeader>
            <LabelHeader>{progressInfo.desc}</LabelHeader>
          </LessonRowHeader>

          <EditProgressLessonContainer>
            {lessonProgress.lessons.map((lesson, index) => (
              <EditLessonRow key={index + lessonProgress.type}>
                <Input
                  style={{ width: 100, textAlign: 'center' }}
                  value={lesson.order}
                  name="order"
                  type={'number'}
                  onChange={e => handleEditData(e, index)}
                />
                <div style={{ position: 'relative' }}>
                  <Input
                    name="subject"
                    style={{ width: 340, padding: '0px 40px 0px 15px' }}
                    value={lesson.subject}
                    onChange={e => handleEditData(e, index)}
                  />
                  {lesson.order && (
                    <Tooltip
                      placement="right"
                      title={
                        <ToolTipTitle>{`${progressInfo.tooltip}. ${
                          lesson.subject
                        } (Через ${lesson.order - 1} уроков)`}</ToolTipTitle>
                      }
                    >
                      <InfoIcon>
                        <HelpIcon />
                      </InfoIcon>
                    </Tooltip>
                  )}
                </div>
                {lessonProgress.lessons.length > 1 && (
                  <DeleteBtn onClick={() => handleRemove(lesson.order)}>
                    <DeleteIcon />
                  </DeleteBtn>
                )}
              </EditLessonRow>
            ))}
          </EditProgressLessonContainer>
          <AddProgressButton onClick={handleLesson}>+ Добавить урок</AddProgressButton>
        </ModalBody>
        <ModalFooter>
          <Button whiteTheme bgWhite children="Сохранить" onClick={handleSave} />
          <CancelButton onClick={onClose} style={{ marginTop: 10 }}>
            Отмена
          </CancelButton>
        </ModalFooter>
      </ModalContianer>
    </Modal>
  )
}

export default EditCourseProgress
