import React from 'react'

export default function CrossShairsIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1H13V3.05493C17.1716 3.51608 20.4839 6.82838 20.9451 11H23V13H20.9451C20.4839 17.1716 17.1716 20.4839 13 20.9451V23H11V20.9451C6.82838 20.4839 3.51608 17.1716 3.05493 13H1V11H3.05493C3.51608 6.82838 6.82838 3.51608 11 3.05493V1ZM5.07089 13H7V11H5.07089C5.5094 7.93431 7.93431 5.5094 11 5.07089V7H13V5.07089C16.0657 5.5094 18.4906 7.93431 18.9291 11H17V13H18.9291C18.4906 16.0657 16.0657 18.4906 13 18.9291V17H11V18.9291C7.93431 18.4906 5.5094 16.0657 5.07089 13ZM10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z"
        fill="#C2CFE0"
      />
    </svg>
  )
}
