import styled from 'styled-components'

export const Container = styled.div`
  width: 354px;
  background: #f3f5f9;
  padding: 30px 18px 22px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 354px;
`

export const Title = styled.h5`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;
`

export const TimeZoneLabel = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Grey middle_ default text */

  color: #7e92ac;
`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const FooterLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin-top: 18px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 15px;
`

export const MainContainer = styled.div`
  flex-grow: 1;
`

export const ApproveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  background: #ff922d;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color_menu_dashb */

  color: #f3f5f9;
  width: fit-content;
`
export const SelectedEventItems = styled.div`
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.bgLightSide};
    border-radius: 4px;
  }
`
export const SelectedEventItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 10px 0px 10px 12px;
  :hover {
    background: rgba(194, 207, 224, 0.2);
    border-radius: 10px;
    .delete-btn {
      display: block;
    }
  }
  .delete-btn {
    display: none;
    margin-right: -10px;
  }
`

export const EventTeacherInfo = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  .avatar {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }
`

export const TeacherNameLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;

  /* Headline dark */

  color: #4d6084;
`

export const EventTypeLabel = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Grey middle_ default text */

  color: #7e92ac;
`

export const EventItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 66px;
  height: 60px;

  /* White */

  background: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;

  text-align: center;

  /* Active_platform */

  color: #336a93;
  margin-right: 10px;
  position: relative;

  :hover {
    .edit-btn {
      display: block;
    }
    .delete-btn {
      display: none;
    }
  }

  .edit-btn {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
  }
`

export const NextLessonItem = styled.div`
  box-sizing: border-box;

  width: 314px;
  height: 80px;

  border: 1px dashed #c2cfe0;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Grey middle_ default text */

  color: #7e92ac;
  margin-top: 24px;
`

export const DeleteModalContainer = styled.div`
  width: 436px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DeleteModalTitle = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin: 40px 0px 40px;
`

export const DeleteModalCancel = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin: 20px 0px 30px;
`

export const EditContainerModal = styled.div`
  position: relative;
  width: 990px;
  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`
