import React from 'react'

export default function CheckCircleIcon({ isChecked, ...rest }) {
  if (isChecked) {
    return (
      <svg {...rest} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="m15.663 3.7734c-1.7728-0.7899-3.7534-0.98558-5.6464-0.55787-1.8931 0.42771-3.5972 1.4559-4.8582 2.9312-1.261 1.4753-2.0112 3.3187-2.1389 5.2553-0.1277 1.9366 0.37403 3.8626 1.4304 5.4907 1.0563 1.6281 2.6106 2.8712 4.4312 3.5438 1.8205 0.6725 3.8097 0.7386 5.6708 0.1883 1.8612-0.5502 3.4946-1.6874 4.6566-3.2418 1.1621-1.5545 1.7905-3.4429 1.7916-5.3837v-0.9194c0-0.5523 0.4477-1 1-1s1 0.4477 1 1v0.92c-0.0014 2.3721-0.7694 4.6807-2.1897 6.5806-1.4203 1.8998-3.4167 3.2897-5.6914 3.9623-2.2747 0.6725-4.7059 0.5918-6.931-0.2303-2.2251-0.822-4.1248-2.3413-5.4159-4.3313-1.2911-1.9899-1.9043-4.3439-1.7482-6.7108 0.15608-2.3669 1.0731-4.62 2.6143-6.4232 1.5412-1.8032 3.624-3.0599 5.9377-3.5826 2.3138-0.52276 4.7345-0.28358 6.9012 0.68184 0.5045 0.22478 0.7312 0.81596 0.5064 1.3204-0.2247 0.50448-0.8159 0.73121-1.3204 0.50643z" />
        <path d="m22.707 3.2925c0.3907 0.39033 0.391 1.0235 7e-4 1.4142l-10 10.01c-0.1875 0.1876-0.4419 0.2931-0.7073 0.2932-0.2653 1e-4 -0.5197-0.1053-0.7073-0.2929l-3-3c-0.39052-0.3905-0.39052-1.0237 0-1.4142 0.39053-0.39053 1.0237-0.39053 1.4142 0l2.2925 2.2925 9.2929-9.3022c0.3904-0.39072 1.0235-0.39104 1.4143-7.1e-4z" />
      </svg>
    )
  } else {
    return (
      <svg {...rest} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="m12 3c-4.9706 0-9 4.0294-9 9 0 4.9706 4.0294 9 9 9 4.9706 0 9-4.0294 9-9 0-4.9706-4.0294-9-9-9zm-11 9c0-6.0751 4.9249-11 11-11 6.0751 0 11 4.9249 11 11 0 6.0751-4.9249 11-11 11-6.0751 0-11-4.9249-11-11z" />
      </svg>
    )
  }
}

CheckCircleIcon.defaultProps = {
  isChecked: false,
}
