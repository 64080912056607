import React, { useState, useRef, useEffect, useCallback } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import Cross from '@assets/images/blocks/cross.svg'
import Plus from '@assets/images/blocks/plus.svg'

const ResultBlock = ({ onStateChange, id, preloadedState }) => {
  const initialState = {
    matches: [
      {
        id: 1,
        mark: '',
        desc: '',
      },
    ],
  }

  const [state, setState] = useState(preloadedState || initialState)
  const textareaRefs = useRef([])
  const inputRefs = useRef([])

  const changeState = updatedState => {
    const newState = {
      ...state,
      ...updatedState,
    }
    setState(newState)
    onStateChange(id, newState)
  }

  useEffect(() => {
    textareaRefs.current = textareaRefs.current.slice(0, state.matches.length)
    inputRefs.current = inputRefs.current.slice(0, state.matches.length)
  }, [state.matches])

  const updateTextareaHeights = useCallback(() => {
    textareaRefs.current.forEach((textareaRef, index) => {
      if (textareaRef && inputRefs.current[index]) {
        const textareaHeight = textareaRef.scrollHeight
        inputRefs.current[index].style.height = `${
          index === state.matches.length - 1 ? textareaHeight + 3 : textareaHeight
        }px`
      }
    })
  }, [state.matches])

  useEffect(() => {
    updateTextareaHeights()
  }, [state.matches, updateTextareaHeights])

  const handleMarkChange = (event, wordId) => {
    let value = event.target.value

    value = value.replace(/[^0-9.-]/g, '')
    value = value.replace(',', '.')

    const floatValue = parseFloat(value)
    if (floatValue < 0 || isNaN(floatValue)) {
      value = ''
    } else if (floatValue > 100) {
      value = '100'
    }

    const [firstNumber, secondNumber] = value.split('-').map(num => parseInt(num.trim(), 10))

    if (secondNumber > 100) {
      value = `${firstNumber}-100`
    }

    if (value.startsWith('00')) {
      value = '0'
    }

    changeState({
      matches: state.matches.map(word => (word.id === wordId ? { ...word, mark: value } : word)),
    })
  }

  const handleInput = () => {
    updateTextareaHeights()
  }

  const addBlock = () => {
    const newBlock = {
      id: state.matches.length ? state.matches[state.matches.length - 1].id + 1 : 0,
      mark: '',
      desc: '',
    }

    changeState({
      matches: [...state.matches, newBlock],
    })
  }

  const removeBlock = wordId => {
    changeState({
      matches: state.matches.filter(item => item.id !== wordId),
    })
  }

  return (
    <div className="aaa">
      {state.matches.map(({ id, mark, desc }, index) => (
        <div key={id} className="vocabulary-block result-block">
          <input
            ref={el => (inputRefs.current[index] = el)}
            type="text"
            className="vocabulary-area"
            placeholder="Количество баллов"
            onChange={e => handleMarkChange(e, id)}
            onInput={handleInput}
            value={mark}
            style={{
              borderBottom: index === state.matches.length - 1 ? '1px solid #C2CFE0' : '',
              verticalAlign: 'top',
            }}
          />

          <TextareaAutosize
            ref={el => (textareaRefs.current[index] = el)}
            className="result-desc-area"
            placeholder="а здесь - описание результата"
            value={desc}
            onChange={e =>
              changeState({
                matches: state.matches.map(word =>
                  word.id === id ? { ...word, desc: e.target.value } : word
                ),
              })
            }
            onInput={handleInput}
            style={{
              resize: 'none',
              padding: '10px 20px',
              borderTop: '1px solid #C2CFE0',
              borderLeft: '1px solid #C2CFE0',
              borderRight: '1px solid #C2CFE0',
              borderBottom: index === state.matches.length - 1 ? '1px solid #C2CFE0' : '',
              fontWeight: 400,
              fontSize: 16,
              color: '#414752',
              paddingBottom: index === state.matches.length - 1 ? 11 : 10,
            }}
          />
          <img
            onClick={() => removeBlock(id)}
            style={{ padding: '0 20px', cursor: 'pointer' }}
            src={Cross}
            alt=""
          />
        </div>
      ))}
      <div className="centered-icon-voc" onClick={addBlock}>
        <img style={{ cursor: 'pointer' }} src={Plus} alt="" />
      </div>
    </div>
  )
}

export default ResultBlock
