import styled from 'styled-components'

export const Course = styled.div<{ isBlackFriday?: boolean }>`
  width: 238px;
  height: 370px;
  border-radius: 16px;
  border: ${props =>
    props.isBlackFriday
      ? '2px solid #1C1C1C'
      : '2px solid rgba(225, 236, 255, 1)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  letter-spacing: 0.01em;
  transition: all 0.5s ease;
  &:hover {
    border: 2px solid rgba(255, 184, 0, 1);
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
  }
`

export const NumberLessons = styled.span`
  color: rgba(144, 164, 189, 1);
  font-weight: 700;
  font-size: 36px;
  line-height: 25px;
  flex-grow: 1;
  margin-bottom: -40px;
`

export const CourseClass = styled.div`
  color: rgba(144, 164, 189, 1);
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
  font-family: 'Open Sans';
`

export const BonusLessons = styled.span`
  font-size: 14px;
  color: rgba(144, 164, 189, 1);
  font-weight: bold;
  line-height: 19px;
  transition: all 0.5s ease;
  flex-grow: 1;
  margin-bottom: -15px;
  font-family: 'Open Sans';
`

export const LessonPrice = styled.span`
  color: rgba(65, 71, 82, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 50px;
`

export const Price = styled.span`
  color: rgba(66, 132, 255, 1);
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  ${Course}:hover & {
    color: rgba(255, 146, 45, 1);
  }
`

export const ButtonText = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
`

export const LastPrice = styled.span`
  font-size: 18px;
  line-height: 19px;
  text-decoration: line-through;
  flex-grow: 1;
  font-weight: 600;
  margin-top: 3px;
`

export const BuyButton = styled.button<{ isBlackFriday?: boolean }>`
  color: rgba(255, 255, 255, 1);
  background: ${props =>
    props.isBlackFriday
      ? '#1C1C1C'
      : 'linear-gradient(270.29deg, rgba(66, 132, 255, 1) 18.37%, rgba(66, 132, 255, 1) 99.74%)'};

  letter-spacing: 0.01em;
  border-radius: 12px;
  padding: 5px 45px;
  transition: all 0.5s ease;
  ${Course}:hover & {
    background: linear-gradient(
      270.29deg,
      rgba(255, 146, 45, 1) 18.37%,
      rgba(255, 184, 0, 1) 99.74%
    );
  }
`
