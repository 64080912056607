import React from 'react'
export default function SkipBtn() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 13C23 18.5228 18.5228 23 13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13Z"
        fill="#4284FF"
      />
      <path
        d="M11 18L16 13L11 8"
        stroke="white"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
