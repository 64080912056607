import React from 'react'

export default function LineIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46544 2.3206C6.99559 1.60085 5.13498 2.3663 4.73837 4.179C4.6174 4.73185 4.1067 5.07352 3.59767 4.94214C3.08865 4.81077 2.77406 4.25609 2.89502 3.70325C3.61799 0.398985 6.90105 -0.702721 9.24419 0.444669C10.6425 1.12938 11.1603 2.49646 11.5079 3.41421C11.5387 3.49547 11.5682 3.57321 11.5968 3.64664C11.7946 4.15434 11.9479 4.48402 12.142 4.71484C12.3009 4.90387 12.5153 5.05947 12.9276 5.11237C13.9673 5.24579 14.4932 4.93868 14.7632 4.65219C15.0647 4.33226 15.1848 3.8925 15.1848 3.57566C15.1848 3.00742 15.6089 2.54677 16.1321 2.54677C16.6553 2.54677 17.0794 3.00742 17.0794 3.57566C17.0794 4.35533 16.8096 5.35753 16.0873 6.12407C15.3334 6.92405 14.2015 7.34793 12.7053 7.15595C11.8496 7.04615 11.2146 6.66352 10.7429 6.10245C10.3063 5.58318 10.0476 4.95091 9.85186 4.44846C9.83576 4.40715 9.81994 4.36646 9.80436 4.32639C9.39854 3.2826 9.156 2.65876 8.46544 2.3206Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90287 13.007C5.47334 13.19 5.24757 13.4814 5.16332 13.839C4.83904 15.2152 3.54891 16.0454 2.28175 15.6932C1.01458 15.341 0.250226 13.9398 0.574511 12.5635C1.09312 10.3626 2.5536 8.90793 4.1729 8.21793C5.75443 7.54404 7.59879 7.53781 9.2092 8.27006C11.2403 9.19362 12.0084 11.1098 12.3738 12.0215C12.4066 12.1033 12.4361 12.177 12.4631 12.2411C12.5616 12.476 12.6291 12.6237 12.6893 12.7352C12.7113 12.7758 12.7289 12.8051 12.742 12.8255C12.7599 12.8291 12.7851 12.8333 12.8192 12.8374C13.2568 12.8895 13.5138 12.8446 13.64 12.8067C13.7032 12.7878 13.7397 12.7688 13.757 12.7586C13.7598 12.7569 13.7623 12.7554 13.7645 12.754C13.8043 11.3712 14.8489 10.2635 16.1317 10.2635C17.4397 10.2635 18.5 11.4152 18.5 12.8358C18.5 14.0232 18.0639 15.4673 16.9618 16.5533C15.8008 17.6973 14.1831 18.1752 12.3026 17.9512C11.1108 17.8092 10.1377 17.2952 9.39265 16.4723C8.73083 15.7414 8.36483 14.8798 8.15272 14.3742C7.63218 13.1334 7.57802 13.1055 7.37997 13.0154C6.93722 12.8141 6.37018 12.8079 5.90287 13.007Z"
        fill="white"
      />
    </svg>
  )
}
