import React from 'react'


function BookOpenIcon(props) {
	return (
		<svg { ...props } viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
			<path d='m0.83334 2.5c0-0.46024 0.3731-0.83334 0.83334-0.83334h5c1.1051 0 2.1649 0.43899 2.9463 1.2204 0.78145 0.7814 1.2204 1.8412 1.2204 2.9463v11.667c0 0.4602-0.3731 0.8333-0.8333 0.8333-0.46023 0-0.83332-0.3731-0.83332-0.8333 0-0.442-0.1756-0.8659-0.48816-1.1785s-0.73648-0.4882-1.1785-0.4882h-5.8333c-0.46024 0-0.83334-0.3731-0.83334-0.8333v-12.5zm8.3333 12.113v-8.7799c0-0.66304-0.2634-1.2989-0.73224-1.7678s-1.1047-0.73223-1.7678-0.73223h-4.1667v10.833h5c0.59019 0 1.1642 0.1565 1.6667 0.4465z' />
			<path d='m10.387 2.8871c0.7814-0.7814 1.8412-1.2204 2.9462-1.2204h5c0.4603 0 0.8334 0.3731 0.8334 0.83334v12.5c0 0.4602-0.3731 0.8333-0.8334 0.8333h-5.8333c-0.442 0-0.8659 0.1756-1.1785 0.4882s-0.4882 0.7365-0.4882 1.1785c0 0.4602-0.3731 0.8333-0.8333 0.8333-0.46023 0-0.83332-0.3731-0.83332-0.8333v-11.667c0-1.1051 0.43898-2.1649 1.2204-2.9463zm0.4462 11.726c0.5025-0.29 1.0765-0.4465 1.6667-0.4465h5v-10.833h-4.1667c-0.663 0-1.2989 0.26339-1.7677 0.73223-0.4689 0.46884-0.7323 1.1047-0.7323 1.7678v8.7799z' />
		</svg>
	)
}

export default BookOpenIcon
