import React from 'react';

export default function MenuLessonHistoryIcon() {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.5'>
        <path
          d='M31.5 10.5002H12C11.1 10.5002 10.5 9.90022 10.5 9.00022C10.5 8.10022 11.1 7.50022 12 7.50022H31.5C32.4 7.50022 33 8.10022 33 9.00022C33 9.90022 32.4 10.5002 31.5 10.5002Z'
          fill='white'
        />
        <path
          d='M31.5 19.5002H12C11.1 19.5002 10.5 18.9002 10.5 18.0002C10.5 17.1002 11.1 16.5002 12 16.5002H31.5C32.4 16.5002 33 17.1002 33 18.0002C33 18.9002 32.4 19.5002 31.5 19.5002Z'
          fill='white'
        />
        <path
          d='M31.5 28.5002H12C11.1 28.5002 10.5 27.9002 10.5 27.0002C10.5 26.1002 11.1 25.5002 12 25.5002H31.5C32.4 25.5002 33 26.1002 33 27.0002C33 27.9002 32.4 28.5002 31.5 28.5002Z'
          fill='white'
        />
        <path
          d='M4.5 10.5002C4.35 10.5002 4.35 10.5002 4.2 10.5002C4.05 10.5002 4.05 10.5002 3.9 10.3502C3.75 10.3502 3.75 10.2002 3.6 10.2002C3.45 10.2002 3.45 10.0502 3.45 10.0502C3.3 9.90022 3.15 9.75022 3.15 9.60022C3 9.45022 3 9.15022 3 9.00022C3 8.85022 3 8.55022 3.15 8.40022C3.3 8.25022 3.3 8.10022 3.45 7.95022C3.9 7.50022 4.5 7.35022 5.1 7.65022C5.25 7.80022 5.4 7.80022 5.55 7.95022C5.85 8.25022 6 8.55022 6 9.00022C6 9.45022 5.85 9.75022 5.55 10.0502C5.25 10.3502 4.95 10.5002 4.5 10.5002Z'
          fill='white'
        />
        <path
          d='M4.5 19.5002C4.05 19.5002 3.75 19.3502 3.45 19.0502C3.15 18.7502 3 18.4502 3 18.0002C3 17.8502 3 17.8502 3 17.7002C3 17.5502 3 17.5502 3.15 17.4002C3.15 17.2502 3.3 17.2502 3.3 17.1002C3.3 16.9502 3.45 16.9502 3.45 16.9502C3.6 16.8002 3.75 16.6502 3.9 16.6502C4.5 16.3502 5.1 16.5002 5.55 16.9502L5.7 17.1002C5.7 17.2502 5.85 17.2502 5.85 17.4002C5.85 17.5502 5.85 17.5502 6 17.7002C6 17.8502 6 17.8502 6 18.0002C6 18.4502 5.85 18.7502 5.55 19.0502C5.25 19.3502 4.95 19.5002 4.5 19.5002Z'
          fill='white'
        />
        <path
          d='M4.5 28.5002C4.05 28.5002 3.75 28.3502 3.45 28.0502C3.15 27.7502 3 27.4502 3 27.0002C3 26.8502 3 26.5502 3.15 26.4002C3.3 26.2502 3.3 26.1002 3.45 25.9502C4.05 25.3502 4.95 25.3502 5.55 25.9502C5.85 26.2502 6 26.5502 6 27.0002C6 27.4502 5.85 27.7502 5.55 28.0502C5.25 28.3502 4.95 28.5002 4.5 28.5002Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
