import React, { useCallback } from 'react'
import Modal, { defaultStyles, setAppElement } from 'react-modal'

import ModalHeader from '@components/common/Modal/modalHeader'
import ModalActions from '@components/common/Modal/modalActions'

const customStyles = {
  content: {
    border: 0,
    background: 'transparent',
    height: '100%',
  },
}

defaultStyles.overlay.backgroundColor = 'rgba(48, 72, 89, 0.73)'
defaultStyles.overlay.zIndex = 1071
setAppElement('#app')

const WarningModal = ({
  styles = {},
  visibility,
  onSubmit,
  onClose,
  isOnlyActions,
  localization: { title, description, actionCreate, actionCancelCreation },
}) => {
  const handleCloseModal = useCallback(() => {
    onClose(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal isOpen={visibility} style={customStyles}>
      <div className="modal-container">
        <div className="modal" style={styles}>
          <ModalHeader
            type="warning"
            localization={{
              title,
              description,
            }}
          />
          <ModalActions
            isDisabled={false}
            onSubmit={onSubmit}
            onClose={handleCloseModal}
            localization={{
              actionCreate,
              actionCancelCreation,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default WarningModal
