import React from 'react'

export default function LessonMenuIcon(props) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33 6H31.5V21C31.5 22.6569 30.1569 24 28.5 24H19.5V26.1507L26.2717 30.2138L24.7283 32.7862L19.5 29.6493V33H16.5V29.6493L11.2717 32.7862L9.72826 30.2138L16.5 26.1507V24H7.5C5.84315 24 4.5 22.6569 4.5 21V6H3V3H33V6ZM7.5 6V21H28.5V6H7.5ZM12 18H15V12H12V18ZM19.5 18H16.5V9H19.5V18ZM21 18H24V13.5H21V18Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
