import { ITest } from '@models/ITest'
import { Dispatch } from 'redux'
import { ClassroomActionsTypes } from '@store/classroom/types'
import {
  addFileToClassroomAction,
  createClassroomAction,
  // deleteClassroomAction,
  setClassroomAction,
  setClassroomFilesAction,
  setClassroomsAction,
  updateClassroomAction,
  setStudents,
  setStudent,
  setPublishedCoursesAction,
  setDisplayedCourseAction,
  setLoadingStudentPage,
  setStudentCurrentCourse,
  setStudentDataInClassroomAction,
  removeStudent as removeStudentAction,
} from '@store/classroom/actions'

import {
  // classroomsRef,
  database as db,
  usersRef,
  authRef,
  studentsRef,
  publishedCoursesRef,
  activeCoursesRef,
  firestoreRef,
  firebaseTimestamp,
  courseRef,
  timestampRef,
} from '@configs/firebase'
import { Test } from '@models/IProfile'
import { ILessonHomeworkAnswers } from '@models/index'
//import { calculateSumMark } from '@pages/teacher/Classes'

export const sortTests = (test: ITest[]) => {
  const filterTests = test.filter(item => !item.timestamp)
  return test
    .filter(item => !!item.timestamp)
    .sort(
      (a, b) =>
        new Date(b.timestamp.toDateString()).getTime() -
        new Date(a.timestamp.toDateString()).getTime()
    )
    .concat(filterTests)
}

export const saveTestsAnswers = async (
  answers: ILessonHomeworkAnswers,
  status: string,
  testId: string
) => {
  try {
    const collection = await studentsRef
      .doc(authRef.currentUser.uid)
      .collection('tests')
      .get()

    const test = collection.docs.find(doc => doc.data().id === testId)
    if (!test) {
      return
    }
    test.ref.update({ answers, status })
  } catch (e) {
    console.error(e)
  }
}

export const setTestsSubCollection = async (name, id, pages) => {
  try {
    const studentDoc = studentsRef.doc(authRef.currentUser.uid)
    const subcollectionRef = studentDoc.collection('tests')
    const querySnapshot = await subcollectionRef.where('name', '==', name).get()

    if (querySnapshot.empty) {
      await subcollectionRef.add({ name, id, pages })
    }
  } catch (error) {
    console.error(error)
  }
}

export const setTestPassed = async (assignedTests: Test[]) => {
  try {
    let studentData = studentsRef.doc(authRef.currentUser.uid)
    await studentData.update({ assignedTests })
  } catch (e) {
    console.error(e)
  }
}

// export const deleteTest = (id: string) => {
//   return async (dispatch: Dispatch<ClassroomActionsTypes>) => {
//     let files = await classroomsRef
//       .doc(id)
//       .collection('files')
//       .get()

//     await Promise.all(
//       files.docs.map(async doc => {
//         await classroomsRef
//           .doc(id)
//           .collection('files')
//           .doc(doc.id)
//           .delete()
//       })
//     )

//     await classroomsRef.doc(id).delete()
//     db.ref(id).remove()

//     dispatch(deleteClassroomAction(id))
//   }
// }
