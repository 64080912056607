import React from 'react'

const CopyIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6888_58610)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91212 7.24738C7.52244 7.24738 7.20655 7.56542 7.20655 7.95774V14.351C7.20655 14.7433 7.52244 15.0613 7.91212 15.0613H14.2622C14.6519 15.0613 14.9678 14.7433 14.9678 14.351V7.95774C14.9678 7.56542 14.6519 7.24738 14.2622 7.24738H7.91212ZM5.79541 7.95774C5.79541 6.78078 6.74309 5.82666 7.91212 5.82666H14.2622C15.4313 5.82666 16.3789 6.78078 16.3789 7.95774V14.351C16.3789 15.5279 15.4313 16.4821 14.2622 16.4821H7.91212C6.74309 16.4821 5.79541 15.5279 5.79541 14.351V7.95774Z"
        fill="#7E92AC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.97315 2.27497C2.78602 2.27497 2.60656 2.34981 2.47424 2.48303C2.34192 2.61625 2.26758 2.79693 2.26758 2.98533V9.37857C2.26758 9.56697 2.34192 9.74765 2.47424 9.88087C2.60656 10.0141 2.78602 10.0889 2.97315 10.0889H3.67872C4.0684 10.0889 4.38429 10.407 4.38429 10.7993C4.38429 11.1916 4.0684 11.5096 3.67872 11.5096H2.97315C2.41177 11.5096 1.87337 11.2851 1.47641 10.8855C1.07945 10.4858 0.856445 9.94377 0.856445 9.37857V2.98533C0.856445 2.42013 1.07945 1.87808 1.47641 1.47843C1.87337 1.07877 2.41177 0.854248 2.97315 0.854248H9.32328C9.88466 0.854248 10.4231 1.07877 10.82 1.47843C11.217 1.87808 11.44 2.42013 11.44 2.98533V3.69569C11.44 4.08801 11.1241 4.40605 10.7344 4.40605C10.3447 4.40605 10.0288 4.08801 10.0288 3.69569V2.98533C10.0288 2.79693 9.95451 2.61625 9.82219 2.48303C9.68987 2.34981 9.5104 2.27497 9.32328 2.27497H2.97315Z"
        fill="#7E92AC"
      />
    </g>
    <defs>
      <clipPath id="clip0_6888_58610">
        <rect
          width="16.9337"
          height="17.0486"
          fill="white"
          transform="translate(0.150879 0.143799)"
        />
      </clipPath>
    </defs>
  </svg>
)
export { CopyIcon }
