import React from 'react'
import { useTranslation } from 'react-i18next'

export default function() {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 353,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.46987 23.503C0.206006 23.503 -0.351136 22.585 0.230707 21.4632L10.9406 0.841373C11.5231 -0.280458 12.4762 -0.280458 13.0587 0.841373L23.7686 21.4639C24.3518 22.585 23.794 23.503 22.5301 23.503H1.46987Z"
            fill="#FFCC4D"
          />
          <path
            d="M10.3416 19.3533C10.3416 18.4387 11.086 17.6942 12.0013 17.6942C12.9159 17.6942 13.6604 18.4387 13.6604 19.3533C13.6604 20.2686 12.9152 21.0131 12.0013 21.0131C11.086 21.0131 10.3416 20.2686 10.3416 19.3533ZM10.4692 6.80184C10.4692 5.90849 11.1285 5.35547 12.0006 5.35547C12.8521 5.35547 13.5328 5.92976 13.5328 6.80184V14.9929C13.5328 15.865 12.8521 16.4393 12.0006 16.4393C11.1285 16.4393 10.4692 15.8856 10.4692 14.9929V6.80184Z"
            fill="#231F20"
          />
        </svg>
        <span
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: '#414752',
            marginLeft: 10,
          }}
        >
          {t('CancelLessonNotifyTitle')}
        </span>
      </div>
      <div>
        <span>{t('CancelLessonNotifyText')}</span>
      </div>
    </div>
  )
}
