import { sizes } from "./global";

export function vw($px) {
  return ($px / 1920) * 100 + "vw";
}
export function vh($px) {
  return ($px / 1920) * 100 + "vw";
}

export function rem($px) {
  return $px / sizes.mainFontSize + "rem";
}
export function em($px) {
  return $px / sizes.mainFontSize + "em";
}
