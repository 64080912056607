import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Card from '@components/common/Card'
import CreationModal from '@components/CreationModal'
import ActionCard from '@components/ActionCard'
import WarningModal from '@components/WarningModal'

import {
  createCourse,
  deleteCourse,
  moderation,
  divertedCourse,
  removePublication,
  duplicateCourse,
  publishCourse,
} from '@actions'

import FeatherIcon from '@assets/images/Constructor/feather.svg'
import { toast } from 'react-toastify'
import { COURSES_STATUS, ROLES } from '@utils/constants'

const MiniCoursesTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [visibilityCreationModal, setVisibilityCreationModal] = useState(false)
  const [visibilityDeletingModal, setVisibilityDeletingModal] = useState(false)
  const [visibilityModerationModal, setVisibilityModerationModal] = useState(false)
  const [visibilityPublishModal, setVisibilityPublishModal] = useState(false)
  const [courseId, setCourseId] = useState(null)
  const [miniCourse, setMiniCourse] = useState([])

  const coursesList = useSelector(store => store.courses.coursesList)
  const profile = useSelector(state => state.profile.profile)

  const lessonsLengthArr = useSelector(store => store.courses.lessonsLengthArr)

  const handleOpenCreationModal = useCallback(() => {
    setVisibilityCreationModal(true)
  }, [])

  const handleOpenDeletingModal = useCallback(id => {
    setVisibilityDeletingModal(true)
    setCourseId(id)
  }, [])

  useEffect(() => {
    const totalMiniCourse = coursesList.filter(course => {
      return (
        course.isMini &&
        (!['MANAGER', 'METHODIST'].includes(profile.role) ? course?.createdBy === profile.id : true)
      )
    })
    setMiniCourse(totalMiniCourse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, coursesList])

  const handleCreateCourse = useCallback(
    state => {
      setVisibilityCreationModal(false)
      dispatch(
        createCourse({
          ...state,
          timestamp: new Date().getTime(),
          createdBy: profile?.id,
          isMini: true,
        })
      )
      toast('Курс успешно создан, наполните его уроками :)', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-create-course-success',
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, profile?.id]
  )

  const handleDeleteCourse = useCallback(() => {
    setVisibilityDeletingModal(false)
    dispatch(deleteCourse({ id: courseId }))
  }, [courseId, dispatch])

  const handleDuplicateCourse = useCallback(
    id => {
      dispatch(duplicateCourse({ id }))
    },
    [dispatch]
  )
  const handleDiverted = useCallback(
    id => {
      dispatch(divertedCourse({ id }))
    },
    [dispatch]
  )
  const handleRemovePublication = useCallback(
    id => {
      dispatch(removePublication({ id }))
    },
    [dispatch]
  )

  const handleModeration = useCallback(() => {
    setVisibilityModerationModal(false)
    dispatch(moderation({ id: courseId }))
  }, [courseId, dispatch])

  const handleOpenModerationModal = useCallback(id => {
    setVisibilityModerationModal(true)
    setCourseId(id)
  }, [])

  const handleOpenPublishModal = useCallback(id => {
    setVisibilityPublishModal(true)
    setCourseId(id)
  }, [])

  const handlePublishCourse = useCallback(() => {
    setVisibilityPublishModal(false)
    dispatch(publishCourse({ id: courseId }))
  }, [courseId, dispatch])

  const handleRedirect = useCallback(id => {
    history.push(`course/${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mockCoursesList = useMemo(
    () =>
      coursesList
        .filter(
          course =>
            course.isMini &&
            (!['MANAGER', 'METHODIST'].includes(profile.role)
              ? course?.createdBy === profile.id
              : true &&
                ([
                  COURSES_STATUS.MODERATION,
                  COURSES_STATUS.PUBLISHED,
                  COURSES_STATUS.DIVERTED,
                ].includes(course.status) ||
                  course?.createdBy === profile.id ||
                  !!course.publishedAt))
        )
        .map(({ id, image, name, description, publishedAt, updatedAt, status }) => {
          const currentCourse = lessonsLengthArr.find(({ id: courseId }) => courseId === id)
          return (
            <ActionCard
              publishedAt={publishedAt}
              updatedAt={updatedAt}
              key={id}
              title={name}
              description={description}
              image={image}
              status={status}
              isMini
              role={profile.role}
              lessonsTitleText={t('ActionCard.LessonsTitle')}
              lessonsCount={currentCourse && currentCourse.length}
              actions={
                profile.role !== ROLES.TEACHER
                  ? [
                      (status === '' ||
                        status === COURSES_STATUS.MODERATION ||
                        status === COURSES_STATUS.CHANGED ||
                        status === COURSES_STATUS.DIVERTED) && {
                        id: 'publish-action',
                        name: t('ActionCard.PublishCourseTitle'),
                        onClick: handleOpenPublishModal.bind({}, id),
                      },
                      status === COURSES_STATUS.PUBLISHED && {
                        id: 'remove-publication-action',
                        name: 'Remove from publication',
                        onClick: handleRemovePublication.bind({}, id),
                      },
                      status === COURSES_STATUS.MODERATION && {
                        id: 'course-rivision-action',
                        name: 'Submit course for revision',
                        onClick: handleDiverted.bind({}, id),
                      },
                      {
                        id: 'duplicate-action',
                        name: t('ActionCard.DuplicateCourseTitle'),
                        onClick: handleDuplicateCourse.bind({}, id),
                      },
                      {
                        id: 'delete-action',
                        name: t('ActionCard.DeleteCourseTitle'),
                        onClick: handleOpenDeletingModal.bind({}, id),
                      },
                    ]
                  : [
                      status === '' ||
                        (status === COURSES_STATUS.CHANGED && {
                          id: 'moderation-action',
                          name: t('ActionCard.Moderation'),
                          onClick: handleOpenModerationModal.bind({}, id),
                        }),
                      {
                        id: 'duplicate-action',
                        name: t('ActionCard.DuplicateCourseTitle'),
                        onClick: handleDuplicateCourse.bind({}, id),
                      },
                      {
                        id: 'delete-action',
                        name: t('ActionCard.DeleteCourseTitle'),
                        onClick: handleOpenDeletingModal.bind({}, id),
                      },
                    ]
              }
              onClick={handleRedirect.bind({}, id)}
            />
          )
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      coursesList,
      handleDuplicateCourse,
      handleOpenPublishModal,
      handleRedirect,
      handleDiverted,
      handleRemovePublication,
      lessonsLengthArr,
      t,
    ]
  )

  return (
    <>
      <div className="coursesTab cards-container">
        <Card className="big" onClick={handleOpenCreationModal}>
          <div className="creation-block-container">
            <img src={FeatherIcon} alt="feather" draggable={false} />
            <span>{t('CoursesRoute.CreateNewCourseTitle')}</span>
          </div>
        </Card>
        {lessonsLengthArr.length ? mockCoursesList : null}
        <CreationModal
          visibility={visibilityCreationModal}
          onSubmit={handleCreateCourse}
          onClose={setVisibilityCreationModal}
          localization={{
            title: t('CourseModal.Title'),
            mediaTitle: t('CourseModal.MediaTitle'),
            inputFileTitle: t('CourseModal.InputFileTitle'),
            inputFileSize: t('CourseModal.InputFileSize'),
            inputPlaceholderName: t('CourseModal.InputPlaceholderName'),
            inputPlaceholderDescription: t('CourseModal.InputPlaceholderDescription'),
            actionCreate: t('CourseModal.ActionCreate'),
            actionCancelCreation: t('CourseModal.ActionCancelCreation'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityDeletingModal}
          onSubmit={handleDeleteCourse}
          onClose={setVisibilityDeletingModal}
          localization={{
            title: t('CourseWarningModalDeleteAction.Title'),
            description: t('CourseWarningModalDeleteAction.Description'),
            actionCreate: t('CourseWarningModalDeleteAction.ActionDelete'),
            actionCancelCreation: t('CourseWarningModalDeleteAction.ActionCancelDelete'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityModerationModal}
          onSubmit={handleModeration}
          onClose={setVisibilityModerationModal}
          localization={{
            title: 'Send the course to moderation?',
            description:
              'If the course is OK, it will be published. If any revisions are needed, it will come back to you for editing.',
            actionCreate: 'Send to moderation',
            actionCancelCreation: t('CourseWarningModalDeleteAction.ActionCancelDelete'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityPublishModal}
          onSubmit={handlePublishCourse}
          onClose={setVisibilityPublishModal}
          localization={{
            title: t('CourseWarningModalPublishAction.Title'),
            description: t('CourseWarningModalPublishAction.Description'),
            actionCreate: t('CourseWarningModalPublishAction.ActionPublish'),
            actionCancelCreation: t('CourseWarningModalPublishAction.ActionCancelPublish'),
          }}
        />
      </div>
      {miniCourse.length === 0 && (
        <div
          style={{
            fontWeight: '400px',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.01em',
            width: '300px',
            color: '#7E92AC',
          }}
        >
          {t('EmptyMiniCourseText')}
        </div>
      )}
    </>
  )
}

export default MiniCoursesTab
