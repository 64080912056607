import { TextField, withStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './steps.styles'

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline': {
      borderBottomColor: '#C2CFE0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#C2CFE0',
    },
  },
})(TextField) as any

export default function({
  goal,
  goals,
  onChange,
}: {
  goal?: string
  goals?: { goal: string; deadline: string }[]
  onChange: (data: { name: string; value: any }) => void
}) {
  const { t } = useTranslation()
  const currentGoal = useMemo(() => {
    return goals[goals.length - 1]
  }, [goals])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGoals = [...goals]

    newGoals[newGoals.length - 1].goal = event.target.value

    onChange({ name: 'intermediateGoals', value: newGoals })
  }

  return (
    <Styled.Step4Container>
      <CssTextField
        value={currentGoal.goal}
        multiline
        maxRows={4}
        fullWidth
        placeholder={t('StudentWorkSheetStep4Placeholder')}
        inputProps={{
          style: { fontWeight: 400, fontSize: 14, color: '#414752' },
        }}
        onChange={handleOnChange}
      />

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
        <Styled.WorksheetStartTitle>
          {t('MainGoalTitle')}:
        </Styled.WorksheetStartTitle>
        <span style={{ fontSize: 14, color: '#414752', marginTop: 8 }}>
          {t('Main.Goals.' + goal)}
        </span>
      </div>
    </Styled.Step4Container>
  )
}
