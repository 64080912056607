import React from 'react'

function SMSIcon() {
  return (
    <svg width="48" height="30" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="47" height="29" rx="14.5" fill="#4284FF" />
      <path
        d="M16.9167 9.58268L22.8215 15.4875C23.4724 16.1384 24.5276 16.1384 25.1785 15.4875L31.0833 9.58268M17.3333 20.8327H30.6667C31.1269 20.8327 31.5 20.4596 31.5 19.9993V9.99935C31.5 9.53911 31.1269 9.16602 30.6667 9.16602H17.3333C16.8731 9.16602 16.5 9.53911 16.5 9.99935V19.9993C16.5 20.4596 16.8731 20.8327 17.3333 20.8327Z"
        stroke="white"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="0.5" y="0.5" width="47" height="29" rx="14.5" stroke="#90A4BD" />
    </svg>
  )
}

export default SMSIcon
