import React from 'react'

export default function MenuAccountingIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 9H24V6.08824C24 4.40121 22.6766 3 21 3H6C4.32337 3 3 4.40121 3 6.08824V29.9118C3 31.5988 4.32337 33 6 33H21C22.6766 33 24 31.5988 24 29.9118V27H30C31.6569 27 33 25.6569 33 24V12C33 10.3431 31.6569 9 30 9ZM24 16.5H30V24H24V16.5ZM21 29.9118V6.08824C21 6.05831 20.9961 6.03756 20.9935 6.02373C20.9902 6.00646 20.989 6 21 6H6C6.01098 6 6.00977 6.00646 6.00651 6.02373C6.00391 6.03756 6 6.05831 6 6.08824V29.9118C6 29.9417 6.00391 29.9624 6.00651 29.9763C6.00977 29.9935 6.01098 30 6 30H21C20.989 30 20.9902 29.9935 20.9935 29.9763L20.9935 29.9763C20.9961 29.9624 21 29.9417 21 29.9118ZM7.5 25.5V28.5H10.5V25.5H7.5ZM12 28.5V25.5H15V28.5H12ZM16.5 25.5V28.5H19.5V25.5H16.5ZM7.5 24V21H10.5V24H7.5ZM12 21V24H15V21H12ZM16.5 24V21H19.5V24H16.5ZM19.5 19.5V7.5H7.5V19.5H19.5ZM10.5 10.5V16.5H16.5V10.5H10.5ZM30 12V13.5H24V12H30Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
