import React from "react";
function SkypeIcon(props) {
  return (
    <svg
    {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C5.35294 24 0 18.6471 0 12C0 5.35294 5.35294 0 12 0C18.6471 0 24 5.35294 24 12C24 18.6471 18.6471 24 12 24Z"
        fill="#37AEE2"
      />
      <path
        d="M12.0683 7.23014C14.2448 7.40661 15.4801 8.34779 15.5977 9.23014C15.6565 9.81837 15.3036 10.2301 14.6565 10.2301C13.6565 10.2301 13.5389 8.93602 11.8918 8.93602C11.1271 8.93602 10.4801 9.28896 10.4801 9.93602C10.4801 11.3478 15.8918 10.5243 15.8918 13.7007C15.8918 15.5243 14.4212 16.7007 12.2448 16.7007C10.3624 16.7007 8.42124 15.8184 8.42124 14.3478C8.42124 13.8772 8.77418 13.4654 9.24477 13.4654C10.3624 13.4654 10.3036 15.1713 12.1271 15.1713C13.3624 15.1713 13.7742 14.4654 13.7742 13.9948C13.7742 12.289 8.36242 13.289 8.36242 10.0537C8.36242 8.34779 9.83301 7.11249 12.0683 7.23014ZM5.3036 11.9948C5.3036 15.7007 8.3036 18.7007 12.0095 18.7007C12.4212 18.7007 12.833 18.6419 13.2448 18.5831C13.833 18.936 14.5389 19.1125 15.3036 19.1125C17.4212 19.1125 19.186 17.4066 19.186 15.2301C19.186 14.4654 18.9507 13.7596 18.5977 13.1713C18.6565 12.8184 18.7154 12.4066 18.7154 11.9948C18.7154 8.28896 15.7154 5.34779 12.0095 5.34779C11.5977 5.34779 11.1859 5.34779 10.7742 5.40661C10.1859 5.05367 9.48007 4.8772 8.77418 4.8772C6.59771 4.8772 4.83301 6.6419 4.83301 8.75955C4.83301 9.52426 5.0683 10.2301 5.42124 10.8184C5.36242 11.2301 5.3036 11.5831 5.3036 11.9948Z"
        fill="#FEFEFE"
      />
    </svg>
  );
}

export default SkypeIcon;
