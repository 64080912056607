import { debounce } from 'lodash';
import { generalUtils } from '@utils';

import { emitNewPlayerStateToServer } from '@servise/syncAPI';
import { PLAYER_STATE } from '@utils/constants';

export const videoPlayerActions = {
  SET_USER_PLAYER_STATE: 'SET_USER_PLAYER_STATE',
  SET_PLAYER_LOADED_STATE: 'SET_PLAYER_LOADED_STATE',
  SET_PLAYER_MUTED_STATE: 'SET_PLAYER_MUTED_STATE',
  SET_PLAYER_MAXIMIZED_STATE: 'SET_PLAYER_MAXIMIZED_STATE',
  SET_PLAYER_PROGRESS: 'SET_PLAYER_PROGRESS',
  SET_PLAYER_DURATION: 'SET_PLAYER_DURATION',
  SET_PLAYER_IS_HIDDEN: 'SET_PLAYER_IS_HIDDEN',
  UPDATE_VIDEO_PLAYER_STATE: 'UPDATE_VIDEO_PLAYER_STATE',
  setUserVideoPlayerState: (playerState) => ({
    type: videoPlayerActions.SET_USER_PLAYER_STATE,
    payload: playerState,
  }),
  updateUserVideoPlayerState: () => ({
    type: videoPlayerActions.UPDATE_VIDEO_PLAYER_STATE,
  }),

  setPlayerDurationState: (duration) => ({
    type: videoPlayerActions.SET_PLAYER_DURATION,
    payload: duration,
  }),

  setPlayerIsLoadedState: (bool) => ({
    type: videoPlayerActions.SET_PLAYER_LOADED_STATE,
    payload: bool,
  }),

  setPlayerMutedState: (bool) => ({
    type: videoPlayerActions.SET_PLAYER_MUTED_STATE,
    payload: bool,
  }),

  setPlayerMaximizedState: (bool) => ({
    type: videoPlayerActions.SET_PLAYER_MAXIMIZED_STATE,
    payload: bool,
  }),

  setPlayerProgress: (playerProgress) => ({
    type: videoPlayerActions.SET_PLAYER_PROGRESS,
    payload: playerProgress.playedSeconds,
  }),

  setPlayerIsHidden: (playerIsHidden) => ({
    type: videoPlayerActions.SET_PLAYER_IS_HIDDEN,
    payload: playerIsHidden,
  }),

  handleMaximizeBtnPressed: (playerCurrentlyMaximized, videoPlayerElem) => {
    return async function(dispatch) {
      if (playerCurrentlyMaximized) {
        generalUtils.exitFullScreen();
      } else if (!playerCurrentlyMaximized) {
        generalUtils.requestFullScreenOnElement(videoPlayerElem);
      }

      dispatch(videoPlayerActions.setPlayerMaximizedState(!playerCurrentlyMaximized));
    };
  },

  onPlayerStateChange: (userVideoPlayerState) => {
    return async function(dispatch) {
      debouncedPlayerStateChangeHandler(dispatch, userVideoPlayerState);
    };
  },
};

const debouncedPlayerStateChangeHandler = debounce((dispatch, userVideoPlayerState) => {
  const clientIsReady = userVideoPlayerState.playerState !== PLAYER_STATE.buffering;
  const timeInVideo = userVideoPlayerState.timeInVideo;
  const classID = userVideoPlayerState.classID;
  const videoPlayerState = {
    playerState: userVideoPlayerState.playerState,
    timeInVideo: userVideoPlayerState.timeInVideo,
  };

  if (userVideoPlayerState.isLoaded > userVideoPlayerState.timeInVideo) {
    dispatch(videoPlayerActions.setUserVideoPlayerState(videoPlayerState));
    if (!clientIsReady) {
      emitNewPlayerStateToServer(
        {
          playerState: PLAYER_STATE.paused,
          timeInVideo: timeInVideo,
        },
        classID
      );
    }
  }
}, 500);
