import styled from 'styled-components'

export const FilterTeachersContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 820px;
  flex-grow: 1;
`
export const Title = styled.h2`
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;
`

export const TitleHeader = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const TimeZoneLabel = styled.h2`
  font-size: 14px;
  color: #7e92ac;
  font-weight: 400;
`
export const Desc = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* display: flex;
  align-items: center; */

  /* Text_dark */

  color: #414752;
  margin-top: 20px;
`

export const NewbeeButton = styled.button`
  color: rgba(66, 132, 255, 1);
  display: inline-flex;
  gap: 3px;
  font-weight: 600;
`

export const Button = styled.button<{ bgWhite?: boolean }>`
  padding: 10px 14px;
  ${props => (props.bgWhite ? `border: 3px solid #E1ECFF;` : `background: #E1ECFF;`)}
  border-radius: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Active_platform */

  color: #336a93;
`

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 40px;
`

export const ChooseLessonDesc2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  border-left: 2px solid #4284ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #414752;
  margin-top: 10px;
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #4284ff;
    margin-left: 5px;
    cursor: pointer;
  }
`

export const SecondTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;
  margin-top: 30px;
`

export const Ul = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  /* Text_dark */

  list-style-type: initial;
  color: #414752;
  margin-top: 10px;
  margin-left: 50px;
  li {
    margin-top: 5px;
  }
`
export const RadionButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const WeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
`

export const DayItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const DayItemLabel = styled.h5`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Text_dark */

  color: #414752;

  text-transform: capitalize;
  text-align: center;
`

export const TimeItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
`
export const TimeItem = styled.div<{ selected?: boolean; taken?: boolean }>`
  width: 76px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${props => (props.selected ? '#e1ecff' : 'white')};
  border: 1px solid #9abdfc;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Text_dark */

  color: #414752;

  :hover {
    color: #4284ff;
  }
  ${props =>
    props.taken &&
    `color: #7E92AC;
     background: rgba(194, 207, 224, 0.3);
  `};
`

export const CourseHintWrapper = styled.div`
  width: 547px;
  font-size: 14px;
  padding-bottom: 40px;
  color: rgba(65, 71, 82, 1);
  background: rgba(248, 252, 255, 1);
`

export const CourseHintHeader = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 10px;
`

export const CourseHintInfoImg = styled.img``

export const CourseHintInfo = styled.h4`
  flex: 1;
  font-size: 12px;
  color: rgba(126, 146, 172, 1);
`

export const Content = styled.div`
  padding: 0 55px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ItemTitle = styled.h5`
  font-weight: 700;
  font-size: 16px;
`

export const ItemParagraph = styled.p`
  font-weight: 400;
`

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  & svg {
    width: 100%;
    height: 100%;
  }
`

export const HelpText = styled.div`
  color: rgba(126, 146, 172, 1);
`

export const ChooseAllBtn = styled.button<{ selected?: boolean }>`
  width: fit-content;
  padding: 7.5px 14px;
  color: #4284ff;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #9abdfc;
  border-radius: 4px;
  background: ${props => (props.selected ? '#e1ecff' : 'white')};
`
