import React from 'react'

export default function TextIcon(props) {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.125 8.3705L9.931 2.9525H11.5675L13.75 9.49625H12.274L11.8472 8.051H9.62275L9.1405 9.49625H8.50075L8.50225 9.5H6.472L5.8855 7.5125H2.8255L2.1625 9.5H0.25L3.25 0.5H5.5L8.125 8.3705ZM3.25 5.75H5.5L4.372 2.75L3.25 5.75ZM9.931 6.77H11.5675L10.747 4.58825L9.931 6.77Z"
        fill="white"
      />
    </svg>
  )
}
