import styled, { css } from 'styled-components'
import { colors } from '../../../styles/global'
import { rem } from '../../../styles/fuctions'
import { CLASSROOM_BLOCK_TYPES } from '@utils/constants'
export const ClassroomNotebookContainer = styled.div`
  flex-grow: flex;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  border-radius: 16px;
  margin: 5px;
  border-radius: 0;
  background: '#F3F5F9';
  box-shadow: 'none';
  min-width: 268px;
  position: relative;
`
export const UpDownBlockFactory = styled.div<{ type: string }>`
  display: none;
  position: absolute;
  right: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? '14px' : '55px')};
  bottom: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? '50px' : null)};
  top: ${props => (props.type !== CLASSROOM_BLOCK_TYPES.AUDIO ? null : '20px')};
  img {
    cursor: pointer;
  }
`

export const ScrollContiner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & > .ScrollbarsCustom {
    width: 100%;
    height: 100%;

    & > .ScrollbarsCustom-Wrapper {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      & > .ScrollbarsCustom-Scroller {
        & > .ScrollbarsCustom-Content {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ScrollbarsCustom-Track {
      position: absolute;
      background: ${props => props.theme.colors.scrollTrack};
    }

    .ScrollbarsCustom-Thumb {
      background: ${props => props.theme.colors.scrollThumb};
      cursor: pointer;
      border-radius: 0.25rem;
    }

    .ScrollbarsCustom-ThumbY {
      width: 100%;
    }

    .ScrollbarsCustom-ThumbX {
      height: 100%;
    }

    .ScrollbarsCustom-TrackY {
      width: 7px;
      height: calc(100% - 2rem);
      top: 0.5rem;
      right: 0rem;
    }

    .ScrollbarsCustom-TrackX {
      height: 7px;
      width: calc(100% - 3rem);
      bottom: 0.5rem;
      left: 1.5rem;
    }

    &.trackYVisible {
      & > .ScrollbarsCustom-Wrapper {
        right: 0;
      }

      & > .ScrollbarsCustom-TrackX {
        width: calc(100% - 4rem);
        left: 2rem;
      }
    }
  }
`
export const ClassroomChatContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: #ffffff;
`

export const ClassroomNotesContainer = styled.div`
  flex-grow: flex;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  border-radius: 16px;
  margin: 5px;
  border-radius: 0;
  background: '#F3F5F9';
  box-shadow: 'none';
`

export const AudioContainer = styled.div`
  cursor: move;
  z-index: 99;
  position: absolute;
  left: 35%;
  height: 40px;
`

interface IDropZoneContainer {
  isDragActive: boolean
  isDragReject: boolean
}

export const DropZoneContainer = styled.div<IDropZoneContainer>`
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
  ${props =>
    props.isDragActive &&
    css`
      border: 3px dashed rgba(255, 225, 225, 0.8);
    `}
  ${props =>
    props.isDragReject &&
    css`
      border: 3px dashed red;
    `}
`
export const ClassroomPageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;

  @media (max-width: 1199.98px) {
    flex-wrap: wrap;
    justify-content: space-between;

    ${ClassroomNotebookContainer} {
      order: 2;
    }
    ${ClassroomChatContainer} {
      order: 1;
    }
    ${ClassroomNotesContainer} {
      order: 3;
    }
  }
  @media (min-width: 1200px) {
    > * {
      flex-basis: 240px;
    }
  }

  /* @media (min-width: 576px) {
    margin: 5px;
  } */

  @media (max-width: 1199.98px) and (min-width: 576px) {
    ${ClassroomNotesContainer},
    ${ClassroomNotebookContainer} {
      max-width: 45%;
      width: 100%;
    }

    ${ClassroomChatContainer} {
      min-width: 98%;
      margin-bottom: 30px;
    }
  }
`

export const ClassroomTip = styled.div`
  position: relative;
  text-align: center;
  margin: auto;
  color: #414752;
  font-size: 16px;
  font-weight: 600;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  height: 54px;
  align-items: center;
  border-bottom: 1px solid ${colors.borderColor};

  svg {
    height: 24px;
    width: 24px;
    fill: ${colors.blue};
    margin-right: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    color: #1b3b77;
  }
`

export const TextArea = styled.textarea`
  align-self: flex-end;
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-bottom-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-bottom-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: 500;
  line-height: 18px;
  color: ${props => props.theme.colors.textDark};
  font-size: 14px;
  padding-left: 15px;

  ::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;

    color: #c2cfe0;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
    margin: 20px 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`

export const TeacherRecordAudioBtn = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding-left: 15px;
  padding-right: 20px;
  justify-content: center;
  min-width: 160px;
  height: 40px;
  background: rgba(194, 207, 224, 0.3);
  color: #414752;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 0 auto 18px;
`

export const AudioButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15px 18px;
  position: relative;
  width: 90%;
  .volume-icon {
    display: none;
  }
  .timer {
    position: absolute;
    right: 8px;
  }
  canvas {
    width: 75%;
  }
  .delete-btn {
    svg {
      path {
        fill: #7e92ac;
      }

      width: 16;
      height: 17;
    }
  }

  span {
    font-size: 14px;
    line-height: 19px;
    color: #7e92ac;
    margin: 0px 10px;
  }
  input {
    display: none;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 122px;
  background: #ffffff;
  /* box shadow */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;

  .lesson-time-button {
    height: 30px;
    width: 220px;
  }

  .restangle-button {
    position: relative;
    margin: 5px auto 0;
    right: 0;
  }
`

export const Notes = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  margin-bottom: 10px;
  height: 100%;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
`

export const Notebook = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
`

export const ChatActions = styled.footer<{ isEdit?: boolean }>`
  position: relative;
  display: flex;
  ${props =>
    !props.isEdit
      ? 'border-top:  1px solid #f3f5f9; align-items: center;  height: 50px;'
      : 'justify-content: center; height: 90px; min-height: 90px'};

  .btn {
    position: relative;
    width: calc(100% / 4);
    &:not(:first-of-type) {
      border-left-color: ${colors.borderColor};
    }
    &:hover {
      svg {
        fill: ${colors.blue};
      }
      .board {
        fill: ${colors.blue};
        stroke: ${colors.blue};
      }
    }
  }

  svg {
    fill: #c2cfe0;
    transition: fill 0.3s;
    &:not(.icon) {
      width: ${rem(27)};
      height: ${rem(27)};
    }
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(50%, 10%);
    width: ${rem(16)};
    height: ${rem(16)};
  }
  .save-changes-btn {
    width: 246px;
    color: #ff922d;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    margin-top: 26px;
  }
`

export const FileListContainer = styled.div`
  margin: auto;
  ::-webkit-scrollbar {
    height: 7px;
  }
  z-index: 0;

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
    margin: 0 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`

export const MediaContainer = styled.div`
  position: relative;
  width: 100%;
`

export const PDFDocument = styled.div`
  display: flex;
`

export const PDFContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
`

export const PDFPrevConainer = styled.div`
  display: flex;
  width: 35px;
  background: #c2cfe0;
  opacity: 0.5;
  cursor: pointer;
`

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 710px;
  .page-hide {
    display: none;
  }
  .pdf-spin {
    margin: 100px auto 0;
    width: 0;
    height: 0;
    transform: scale(0.8);

    div {
      color: #ff852d;
    }
  }
`

export const FileText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #414752;
  text-align: left;
  margin-bottom: 20px;
`

export const SaveIcon = styled.div<{ src: string }>`
  background-color: #c2cfe0;
  background-image: url(${props => props.src});
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-position: 5px 4px;
  background-size: 71%;
  background-repeat: no-repeat;
  cursor: pointer;
`

export const BlockSettingsContainer = styled.div`
  display: none;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  right: 0.3rem;
  top: 0.7rem;
  > * {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`
export const FileContainer = styled.div`
  padding: 17px 35px 17px 35px;
  margin-bottom: 30px;
  position: relative;

  &:hover ${BlockSettingsContainer} {
    display: flex;
  }

  &:hover {
    background: RGBA(243, 245, 249, 0.5);
  }
`

export const SketchContainer = styled.div<{ board?: boolean; hide?: boolean }>`
  position: relative;
  width: 100%;
  border: ${props => (props.board ? '1px solid #9c9c9c' : 'none')};
  box-sizing: border-box;
  ${props =>
    props.hide &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  > canvas {
    width: 100%;
  }
  > img {
    width: 100%;
    height: auto;
  }
`

export const SketchSettings = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background-color: #c2cfe0;
  border-radius: 12px;
  width: 24px;
  justify-content: space-around;
  padding: 5px 0;
  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const SketchModal = styled.div<{ active?: boolean }>`
  display: ${props => (props.active ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  position: absolute;
  right: 35px;
  top: 3px;
  height: 24px;
  background-color: #c2cfe0;
  border-radius: 12px;
`

export const SketchSettingsItemContainer = styled.div`
  position: relative;
`

export const PdfPageNumberContainer = styled.div<{ hide?: boolean }>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  position: absolute;
  align-items: center;
  right: 3rem;
  top: 3rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #414752;
`

export const PdfZoomButtons = styled.div<{ hide?: boolean }>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  position: absolute;
  align-items: center;
  right: 2.2rem;
  top: 7rem;
  position: absolute;
  width: 50;
  flex-direction: column;
`

export const PdfActivePageInput = styled.input`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 40px;
  font-weight: 600;
  text-align: center;
  border: 2px solid #d0d0d0;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    display: none;
    margin: auto;
  }
`

export const StartButton = styled.button`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.theme.colors.textDark};
  width: 96px;
`

export const Wrapper = styled.div`
  background: #f3f5f9;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 0;
`

export const Pages = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;
  ::-webkit-scrollbar {
    height: 7px;
  }
  z-index: 0;

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollTrack};
    border-radius: 2px;
    margin: 0 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.scrollThumb};
    border-radius: 2px;
  }
`

export const PageNameWrapper = styled.span<{ active?: boolean }>`
  position: relative;
  display: inline-block;
  color: ${props => (props.active ? '#4284ff' : '#c2cfe0')};
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.01em;
  margin: 0 20px;
  white-space: normal;
  text-align: left;
  min-width: 169px;
  max-width: 169px;
  padding-right: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const HomeWorkPageIcon = styled.img`
  position: absolute;
  top: 0;
  right: 90px;
`

export const MainPanel = styled.div`
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
`

export const AddAIBlockButton = styled.button`
  position: absolute;
  top: -80px;
  right: 30px;
  font-size: 15px;
  padding: 15px 20px;
  background-color: #414752;
  border-radius: 40px;
  color: #fff;
  opacity: 0.7;
`
