import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Card from '@components/common/Card'
import CreationModal from '@components/CreationModal'
import ActionCard from '@components/ActionCard'
import WarningModal from '@components/WarningModal'

import {
  createCourse,
  deleteCourse,
  duplicateCourse,
  publishCourse,
  transformToMiniCourse,
} from '@actions'
import FeatherIcon from '@assets/images/Constructor/feather.svg'
import { toast } from 'react-toastify'

const CoursesTab = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [visibilityCreationModal, setVisibilityCreationModal] = useState(false)
  const [visibilityDeletingModal, setVisibilityDeletingModal] = useState(false)
  const [visibilityPublishModal, setVisibilityPublishModal] = useState(false)
  const [courseId, setCourseId] = useState(null)

  const coursesList = useSelector(store => store.courses.coursesList)

  const lessonsLengthArr = useSelector(store => store.courses.lessonsLengthArr)

  const handleOpenCreationModal = useCallback(() => {
    setVisibilityCreationModal(true)
  }, [])

  const handleCreateCourse = useCallback(
    state => {
      setVisibilityCreationModal(false)
      dispatch(createCourse({ ...state, timestamp: new Date().getTime() }))
      toast('Курс успешно создан, наполните его уроками :)', {
        autoClose: 2000,
        position: 'bottom-center',
        closeButton: false,
        hideProgressBar: true,
        className: 'message-create-course-success',
      })
    },
    [dispatch]
  )

  const handleDeleteCourse = useCallback(() => {
    setVisibilityDeletingModal(false)
    dispatch(deleteCourse({ id: courseId }))
  }, [courseId, dispatch])

  const handleDuplicateCourse = useCallback(
    id => {
      dispatch(duplicateCourse({ id }))
    },
    [dispatch]
  )

  const handleTransformToMiniCourse = useCallback(
    id => {
      dispatch(transformToMiniCourse({ id }))
    },
    [dispatch]
  )

  const handleOpenPublishModal = useCallback(id => {
    setVisibilityPublishModal(true)
    setCourseId(id)
  }, [])

  const handlePublishCourse = useCallback(() => {
    setVisibilityPublishModal(false)
    dispatch(publishCourse({ id: courseId }))
  }, [courseId, dispatch])

  const handleRedirect = useCallback(id => {
    history.push(`course/${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mockCoursesList = useMemo(
    () =>
      coursesList
        .filter(course => !course.isMini)
        .map(({ id, image, name, description, publishedAt, updatedAt }) => {
          const currentCourse = lessonsLengthArr.find(({ id: courseId }) => courseId === id)
          return (
            <ActionCard
              publishedAt={publishedAt}
              updatedAt={updatedAt}
              key={id}
              title={name}
              description={description}
              image={image}
              lessonsTitleText={t('ActionCard.LessonsTitle')}
              lessonsCount={currentCourse && currentCourse.length}
              actions={[
                {
                  id: 'publish-action',
                  name: t('ActionCard.PublishCourseTitle'),
                  onClick: handleOpenPublishModal.bind({}, id),
                },
                {
                  id: 'duplicate-action',
                  name: t('ActionCard.DuplicateCourseTitle'),
                  onClick: handleDuplicateCourse.bind({}, id),
                },
                {
                  id: 'transformToMini-action',
                  name: t('ActionCard.TransformToMiniCourseTitle'),
                  onClick: handleTransformToMiniCourse.bind({}, id),
                },
              ]}
              onClick={handleRedirect.bind({}, id)}
            />
          )
        }),
    [
      coursesList,
      handleDuplicateCourse,
      handleOpenPublishModal,
      handleTransformToMiniCourse,
      handleRedirect,
      lessonsLengthArr,
      t,
    ]
  )

  return (
    <>
      <div className="coursesTab cards-container">
        <Card className="big" onClick={handleOpenCreationModal}>
          <div className="creation-block-container">
            <img src={FeatherIcon} alt="feather" draggable={false} />
            <span>{t('CoursesRoute.CreateNewCourseTitle')}</span>
          </div>
        </Card>
        {lessonsLengthArr.length ? mockCoursesList : null}
        <CreationModal
          visibility={visibilityCreationModal}
          onSubmit={handleCreateCourse}
          onClose={setVisibilityCreationModal}
          localization={{
            title: t('CourseModal.Title'),
            mediaTitle: t('CourseModal.MediaTitle'),
            inputFileTitle: t('CourseModal.InputFileTitle'),
            inputFileSize: t('CourseModal.InputFileSize'),
            inputPlaceholderName: t('CourseModal.InputPlaceholderName'),
            inputPlaceholderDescription: t('CourseModal.InputPlaceholderDescription'),
            checkboxMiniTitle: t('CourseModal.CheckboxMiniTitle'),
            actionCreate: t('CourseModal.ActionCreate'),
            actionCancelCreation: t('CourseModal.ActionCancelCreation'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityDeletingModal}
          onSubmit={handleDeleteCourse}
          onClose={setVisibilityDeletingModal}
          localization={{
            title: t('CourseWarningModalDeleteAction.Title'),
            description: t('CourseWarningModalDeleteAction.Description'),
            actionCreate: t('CourseWarningModalDeleteAction.ActionDelete'),
            actionCancelCreation: t('CourseWarningModalDeleteAction.ActionCancelDelete'),
          }}
        />
        <WarningModal
          isOnlyActions
          visibility={visibilityPublishModal}
          onSubmit={handlePublishCourse}
          onClose={setVisibilityPublishModal}
          localization={{
            title: t('CourseWarningModalPublishAction.Title'),
            description: t('CourseWarningModalPublishAction.Description'),
            actionCreate: t('CourseWarningModalPublishAction.ActionPublish'),
            actionCancelCreation: t('CourseWarningModalPublishAction.ActionCancelPublish'),
          }}
        />
      </div>
    </>
  )
}

export default CoursesTab
