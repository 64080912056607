import styled from 'styled-components'
export const PageContainer = styled.div`
  font-family: Open Sans;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  display: flex;
  background-color: ${props => props.theme.colors.classBg};
`
export const TeacherSelectionContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${props => props.theme.colors.classBg};
  margin: 0 auto;
  flex-grow: 1;
  gap: ${props => (props.selected ? '30px' : '0px')};
`

export const TeachersSelectionMain = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => (props.selected ? '50px 0px 0px 30px' : '50px 30px')};
  position: relative;
  flex-grow: 1;
`

export const CloseModalContainer = styled.div<{ isSent?: boolean }>`
  width: ${props => (props.isSent ? '560px' : '470px')};
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CloseModalTitle = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin: 40px 40px 30px 40px;
`

export const CloseModalDesc = styled.div`
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #7e92ac;
  margin: 0px 56px 40px 56px;
  white-space: pre-line;
`

export const CloseModalCancel = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;

  /* Text_dark */

  color: #414752;
  margin: 20px 0px 30px;
`

export const AllTeachersBtn = styled.button`
  color: #4284ff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: right;
  margin-right: 20px;
  svg {
    margin-left: 10px;
  }
`
export const CloseBtn = styled.button`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7e92ac;
  svg {
    margin-left: 10px;
  }
`

export const Actions = styled.div`
  position: absolute;
  right: 0px;
  top: -20px;
  display: flex;
`

export const TooltipContainer = styled.div`
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  padding: 6px 15px;

  border-radius: 8px;
  background: rgba(65, 71, 82, 0.8);
  /* Note: backdrop-filter has minimal browser support */

  max-width: 226px;
  width: 100%;
`
