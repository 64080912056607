import styled from 'styled-components'

export const Table = styled.table`
  width: calc(100% - 100px);
  background-color: #fff;
  border-collapse: collapse;
  margin: 15px 0;
`

export const TableWrapper = styled.div`
  display: flex;

  .DraftEditor-editorContainer {
    h1,
    h2,
    h3 {
      font-weight: 400;
    }

    h3 {
      font-size: 18px;
    }
  }

  .public-DraftStyleDefault-ltr,
  .DraftEditor-editorContainer,
  .DraftEditor-root,
  .public-DraftEditor-content {
    text-align: inherit;
  }
`

export const ColActions = styled.div`
  display: flex;
  align-self: center;
  margin: 0 15px;

  svg {
    color: #9abdfc;
    margin: 0 7px;
    cursor: pointer;
  }
`

export const RowActions = styled(ColActions)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const FooterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Row = styled.tr<{ hideBorder: boolean }>`
  border: ${({ hideBorder }) => (hideBorder ? '1px solid #F3F5F9' : '1px solid #C2CFE0')};
`

export const Cell = styled.td<{
  selectedBackground: string
  selectedAlign: string
  hideBorder: boolean
}>`
  padding: 5px;
  font-size: 18px;
  position: relative;
  word-break: break-all;
  background-color: ${({ selectedBackground }) => selectedBackground};
  text-align: ${({ selectedAlign }) => selectedAlign};
  border: ${({ hideBorder }) => (hideBorder ? '1px solid #F3F5F9' : '1px solid #C2CFE0')};
  span {
    word-break: break-word;
  }
`

export const Cursor = styled.div<{ tableHeight: number }>`
  position: absolute;
  top: 0;
  right: -2px;
  width: 4px;
  cursor: col-resize;
  user-select: none;
  z-index: 9;
  height: ${({ tableHeight }) => `${tableHeight - 1}px`};

  &:hover {
    background-color: #c2cfe0;
  }
`

export const EditorActionsWrapper = styled.div`
  margin-right: 60px;

  svg {
    margin-right: 22px;
    font-size: 20px;
    color: #9abdfc;
    cursor: pointer;

    &.active {
      color: #4284ff;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`

export const ColorListItem = styled.div<{ providedColor: string }>`
  height: 14px;
  width: 14px;
  background: ${({ providedColor }) => providedColor};
  border-radius: 10px;
  margin-right: 16px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`

export const DropdownOverlay = styled.div<{ hidden: boolean }>`
  display: flex;
  border-radius: 12px;
  padding: 5px 10px;
  background: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  visibility: ${({ hidden }) => (hidden ? 'none' : 'visible')};
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
`

export const DropdownContainer = styled.div``

export const StylePreviewWrapper = styled.div`
  height: 20px;
  position: relative;
`

export const StylePreview = styled.div<{ providedColor: string }>`
  position: absolute;
  top: 0;
  right: 22px;
  background-color: ${({ providedColor }) => providedColor};
  width: 4px;
  height: 4px;
  border-radius: 2px;
`

export const StyledBackgroundListItem = styled.div<{ providedColor: string }>`
  margin-right: 15px;
  height: 14px;
  width: 14px;
  background: ${({ providedColor }) => providedColor};
  border-radius: 10px;
  border: 1px solid #c2cfe0;
  cursor: pointer;
`

export const ActionsContainer = styled.div`
  min-height: 34px;
`
