import { firebaseTimestamp, promotionsRef } from '@configs/firebase'


export const getCurrentPromo = async () => {
  const snapshot = await promotionsRef
    .where('end', '>=', firebaseTimestamp.now())
    .get()

  return snapshot.docs.length > 0 ? snapshot.docs
    .filter(res => {
      return res.data().start.toDate() >= firebaseTimestamp.now()
    }) : null
}