import styled from 'styled-components'

export const PromoCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: rgba(225, 236, 255, 0.5);
  border-radius: 20px;
  padding: 20px;
  line-height: 19px;
`

export const Gift = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`

export const PromoCode = styled.input<{ CancelImg: string }>`
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(225, 236, 255, 1);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(65, 71, 82, 1);
  width: 280px;
  height: 40px;
  padding: 0 5px 0 20px;
  letter-spacing: 0.01em;
  margin-right: 30px;

  ::placeholder {
    color: rgba(144, 164, 189, 1);
    font-size: 14px;
    letter-spacing: normal;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    margin-left: 0.5vw;
    background-image: url(${props => props.CancelImg});
    cursor: pointer;
  }
`

export const PromoText = styled.span`
  color: rgba(144, 164, 189, 1);
  font-size: 14px;
  font-weight: 400;
  width: 22.2vw;
`

export const PromoButton = styled.button`
  color: rgba(66, 132, 255, 1);
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-left: 45px;
`

export const PromoCodeStatus = styled.div<{ status: string }>`
  color: ${props =>
    props.status === 'pending'
      ? 'rgba(144, 164, 189, 1)'
      : props.status === 'success'
      ? 'rgba(39, 174, 96, 1)'
      : 'rgba(221, 46, 68, 1)'};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Check = styled.img`
  margin-right: 0.4vw;
`

export const Loader = styled.img`
  margin-right: 0.4vw;
  width: 24px;
`

export const Container = styled.div`
  width: auto;
  height: inherit;
  display: flex;
`
