import {
  CalendarActionsTypes,
  SET_CALENDAR_EVENTS,
  UPDATE_EVENT,
  DELETE_EVENT,
  ADD_EVENT,
} from './types'
import { ICalendarState } from '@models/ICalendar'

const initialState: ICalendarState = {
  events: [],
  dbEvents: [],
}

export const calendarReducers = (
  state = initialState,
  action: CalendarActionsTypes
): ICalendarState => {
  var events = [...state.events]
  switch (action.type) {
    case SET_CALENDAR_EVENTS:
      return {
        ...state,
        events: action.payload,
      }
    case ADD_EVENT:
      return {
        ...state,
        events,
      }
    case UPDATE_EVENT:
      events = events.map((event, i) => {
        if (event.eventID === action.payload.eventID) {
          return { ...event, ...action.payload }
        } else {
          return event
        }
      })
      return {
        ...state,
        events,
      }
    case DELETE_EVENT:
      events = events.filter(event => event.eventID !== action.payload)

      return { ...state, events }

    default:
      return state
  }
}
