import { IStudentProfile } from '@models/IProfile'
import { getStudentHomeWorkdFinished } from '@servise/homeworkAPI'
import { getStudentWordsLearned } from '@servise/vocabulary'
import React from 'react'
import { useEffect, useState } from 'react'

import * as Styled from './student-card.styles'

const Progress = ({ studentData }: { studentData: IStudentProfile }) => {
  const [learnedWordsCount, setLearnedWordsCount] = useState(0)
  const [finishedHomeWorkCount, setFinishedHomeWorkCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const passedLessons = studentData.passedLessons || 0

  useEffect(() => {
    getStudentWordsLearned(studentData.id).then(words => {
      setLearnedWordsCount(words.length)
      getStudentHomeWorkdFinished(studentData.id, studentData.teachers || []).then(count => {
        setFinishedHomeWorkCount(count)
        setIsLoading(false)
      })
    })
  }, [studentData])

  const progressResult =
    ((learnedWordsCount / 10) % 30) +
    ((finishedHomeWorkCount / 2) % 30) +
    (((passedLessons * 3) / 2) % 40)

  return (
    <Styled.ProsgressContainter>
      <Styled.StudentCardHeader>Progress</Styled.StudentCardHeader>
      <Styled.Div className="progress-content">
        {isLoading ? (
          <Styled.ToCenter>No classes</Styled.ToCenter>
        ) : (
          <>
            <Styled.ProgressRow>
              <Styled.CourseBlock>
                <span>Current course:</span>
                <Styled.CourseLabel>General Spanish</Styled.CourseLabel>
              </Styled.CourseBlock>
              <Styled.ProgressValue>{progressResult}%</Styled.ProgressValue>
            </Styled.ProgressRow>
            <Styled.ProgressBlock>
              <Styled.ProgressRow>
                <span>Completed lessons:</span>
                <Styled.ProgressLabel>{passedLessons}</Styled.ProgressLabel>
              </Styled.ProgressRow>
              <Styled.ProgressRow>
                <span>Completed homework:</span>
                <Styled.ProgressLabel>{finishedHomeWorkCount}</Styled.ProgressLabel>
              </Styled.ProgressRow>

              <Styled.ProgressRow>
                <span>Learnt words:</span>
                <Styled.ProgressLabel>{learnedWordsCount}</Styled.ProgressLabel>
              </Styled.ProgressRow>
            </Styled.ProgressBlock>
          </>
        )}
      </Styled.Div>
    </Styled.ProsgressContainter>
  )
}

export default Progress
