import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: 27px;
`

export const InsertWordWrapper = styled.div`
  margin-top: 40px;
`

export const Image = styled.img`
  margin-right: 22px;
`

export const Text = styled.div`
  font-size: 18px;
  line-height: 42px;
  color: #414752;
  padding-left: 42px;
`

// export const InputFieldWrapper = styled.span`
//   display: inline-flex;
//   margin: 0px 12px;
//   input {
//     &::placeholder {
//       color: #90a4bd;
//     }
//   }

//   .input-word-error {
//     input {
//       border-color: #fcd8d8;
//       background-color: #fcd8d8;
//     }
//   }

//   .input-word-success {
//     input {
//       border-color: #bee2c2;
//       background-color: #bee2c2;
//     }
//   }
// `

export const InputFieldWrapper = styled.span`
  display: inline-flex;
  input {
    &::placeholder {
      color: #90a4bd;
    }
  }
`
export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  background: #fff;
  border: 1px solid #c2cfe0;
  border-radius: 18px;
  margin-bottom: 5px;
  min-width: 64px;
  padding: 0 10px;
  input {
    &::placeholder {
      color: #90a4bd;
    }
  }
  .input-word-error {
    input {
      border-color: #fcd8d8;
      background-color: #fcd8d8;
    }
  }
  .input-word-success {
    input {
      border-color: #bee2c2;
      background-color: #bee2c2;
    }
  }
`
export const EyeButton = styled.img`
  width: 20px;
  margin-left: 8px;
  cursor: pointer;
`

export const DragZone = styled.div`
  width: fit-content;
  min-width: 90px;
  padding: 0px 5px;
  border: 1px solid #c2cfe0;
  border-radius: 18px;
  display: inline-block;
  margin-bottom: -10px;
  color: #9abdfc;
  text-align: center;
  line-height: 34px;
  height: 36px;
  box-sizing: border-box;

  &.hovered-zone {
    border-color: #e1ecff;
    background: #e1ecff;
    cursor: pointer;
  }
`

export const WordList = styled.div`
  position: sticky;
  top: 10px;
  z-index: 2;
  background: #fff;
  > div {
    margin: 20px 15px 20px 0;
  }
`
export const WordListContainer = styled.div`
  display: flex;
  padding: 40px 0 0;
`
export const FragmentList = styled.div`
  z-index: 1;
`
export const DragItem = styled.div`
  display: inline-block;
  background: #f3f5f9;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  padding: 0 14px;
  margin: 3px 0;
  transform: translate(0, 0);
  cursor: move;
  line-height: 34px;
  height: 36px;
  box-sizing: border-box;

  &.hidden-element {
    opacity: 0.4;
  }

  &.correct-choice {
    background-color: #bee2c2;
    border-color: #bee2c2;
  }

  &.wrong-choice {
    background-color: #fcd8d8;
    border-color: #fcd8d8;
  }

  &.hidden-drag-item {
    opacity: 0.4;
  }

  &.hovered-zone {
    border-color: #e1ecff;
    background: #e1ecff;
    cursor: pointer;
  }

  &.captured {
    background: #e1ecff;
  }
`

export const ConstructorDropZone = styled.div<{ isTeacherView: boolean }>`
  min-height: 50px;
  border-bottom: ${props => (props.isTeacherView ? '1px solid #C2CFE0' : '1px solid #C2CFE0')};
  margin-left: 42px;

  > div {
    margin-right: 15px;
  }
`

export const TeacherHint = styled.span`
  color: #9abdfc;
  margin: 0 5px;
`

export const TeacherBottomHint = styled.span`
  color: #9abdfc;
  font-size: 14px;
`

export const ConstructorNumber = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  padding: 0 10px;
  margin: 20px 15px 20px 0;
`

export const SpanishSymbolsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  top: -15px;
`
