import React, { useEffect, useState } from 'react'
import * as Styled from './teacher-card.styles'
import { IconButton } from '@material-ui/core'
import AudioPlayIcon from '../Icons/AudioPlay'
import ReactPlayer from 'react-player'
import VolumeIcon from '../Icons/Volume'
import { AudioContext } from 'standardized-audio-context'

export default function(props) {
  const audioPlayer = React.useRef<ReactPlayer>()
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [audio, setAudio] = useState(null)
  const [waveData, setWaveData] = useState([])
  const [mute, setMute] = useState(false)
  const audioCanvasId = props.id ? props.id : 'audioCanvasId'

  const handlePlay = async () => {
    if (!audio) {
      const audioContext = new AudioContext()

      fetch(props.audio)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          const blob = new Blob([arrayBuffer])
          setAudio(URL.createObjectURL(blob))
          setIsPlaying(true)
          const decode = audioContext.decodeAudioData(arrayBuffer)
          return decode
        })
        .then(audioBuffer => {
          setWaveData(normalizeData(filterData(audioBuffer)))
          setIsPlaying(true)
        })
    } else {
      setIsPlaying(true)
    }
  }
  const handlePause = () => {
    setIsPlaying(false)
  }
  const handleDuration = e => {
    if (e) {
      setDuration(e)
    }
  }

  const handleProgress = e => {
    setPlayedSeconds(e.playedSeconds)
  }

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX
    const bar = document.getElementById(audioCanvasId)
    const barStart = bar.getBoundingClientRect().left + window.scrollX

    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth

    return timePerPixel * clickPositionInBar
  }

  const onTimeUpdate = time => {
    if (audioPlayer.current) {
      if (time >= 1) {
        audioPlayer.current.seekTo(time)
      } else {
        audioPlayer.current.seekTo(0)
      }
    }
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e))
  }

  useEffect(() => {
    const bufferLength = waveData.length
    let canvas = document.getElementById(audioCanvasId)
    if (bufferLength > 0 && canvas) {
      const canvasCtx = canvas.getContext('2d')
      const WIDTH = canvas.offsetWidth
      const HEIGHT = canvas.offsetHeight
      var barWidth = WIDTH / (bufferLength * 2)
      var barHeight
      var x = 0
      const time = (bufferLength / duration) * playedSeconds
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      for (var i = 0; i < bufferLength; i++) {
        barHeight = waveData[i] * HEIGHT
        if (barHeight < 0) {
          barHeight = 0
        }
        if (i < time) {
          canvasCtx.fillStyle = '#4284FF'
        } else {
          canvasCtx.fillStyle = '#C2CFE0'
        }
        canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, HEIGHT)

        x += barWidth * 2
      }
    }
  }, [audioCanvasId, duration, playedSeconds, waveData])

  const normalizeData = filteredData => {
    const multiplier = Math.pow(Math.max(...filteredData), -1)
    return filteredData.map(n => n * multiplier)
  }

  const filterData = audioBuffer => {
    const rawData = audioBuffer.getChannelData(0)
    const samples = props.isProfile ? 45 : 30
    const blockSize = Math.floor(rawData.length / samples)
    const filteredData = []
    for (let i = 0; i < samples; i++) {
      let blockStart = blockSize * i
      let sum = 0
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j])
      }
      filteredData.push(sum / blockSize)
    }
    return filteredData
  }

  const calculateTime = secs => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${returnedSeconds}`
  }

  return (
    <>
      {isPlaying && (
        <ReactPlayer
          url={props.audio}
          playing={isPlaying}
          muted={mute}
          ref={audioPlayer}
          onEnded={() => {
            handlePause()
            if (audioPlayer.current) {
              audioPlayer.current.seekTo(0)
            }
          }}
          onDuration={handleDuration}
          onProgress={handleProgress}
          style={{ display: 'none' }}
          // playsinline={true}
        />
      )}
      <Styled.Player style={props.style}>
        <IconButton
          size="small"
          className="play-icon"
          onClick={() => (!isPlaying ? handlePlay() : handlePause())}
          style={{ width: 44, height: 44, marginLeft: 10 }}
        >
          <AudioPlayIcon playing={isPlaying} />
        </IconButton>
        {audio && (
          <canvas
            id={audioCanvasId}
            style={{ marginRight: 10 }}
            height={24}
            width={props.isProfile ? 234 : 160}
            onClick={handleTimeDrag}
          />
        )}
        <IconButton
          size="small"
          className="play-icon volume-icon"
          onClick={() => setMute(prev => !prev)}
        >
          <VolumeIcon isPlayed={playedSeconds > 0} mute={mute} />
        </IconButton>

        {audio && (
          <span className="timer" style={{ marginLeft: 10 }}>
            {calculateTime(duration - playedSeconds)}
          </span>
        )}
      </Styled.Player>
    </>
  )
}
