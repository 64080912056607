import React from 'react'

const EnrolmentOpen = ({ isActive, onClick }) => (
  <svg
    width="80"
    height="40"
    viewBox="0 0 80 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <rect width="80" height="40" rx="20" fill="#7e92ac" opacity={isActive ? '1' : '0.5'} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8285 12.0742C30.0758 11.9753 30.3516 11.9753 30.5989 12.0742L40.9707 16.2229C41.3645 16.3804 41.6227 16.7618 41.6227 17.1859V23.409C41.6227 23.9818 41.1583 24.4462 40.5855 24.4462C40.0127 24.4462 39.5483 23.9818 39.5483 23.409V18.7179L30.5989 22.2976C30.3516 22.3966 30.0758 22.3966 29.8285 22.2976L19.4567 18.1489C19.0629 17.9914 18.8047 17.61 18.8047 17.1859C18.8047 16.7618 19.0629 16.3804 19.4567 16.2229L29.8285 12.0742ZM37.7928 17.1859L30.2137 20.2176L22.6346 17.1859L30.2137 14.1543L37.7928 17.1859Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9923 17.8105C24.5651 17.8105 25.0294 18.2749 25.0294 18.8477V24.4485C25.0294 24.6819 25.2403 25.2018 26.2788 25.721C27.2688 26.216 28.6844 26.5229 30.2154 26.5229C31.7463 26.5229 33.1619 26.216 34.1519 25.721C35.1904 25.2018 35.4013 24.6819 35.4013 24.4485V18.8477C35.4013 18.2749 35.8656 17.8105 36.4385 17.8105C37.0113 17.8105 37.4756 18.2749 37.4756 18.8477V24.4485C37.4756 25.8656 36.3752 26.9286 35.0796 27.5764C33.7355 28.2484 31.9853 28.5972 30.2154 28.5972C28.4454 28.5972 26.6952 28.2484 25.3511 27.5764C24.0555 26.9286 22.9551 25.8656 22.9551 24.4485V18.8477C22.9551 18.2749 23.4194 17.8105 23.9923 17.8105Z"
      fill="white"
    />
    <path
      d="M51.8182 23.6563L49.0811 20.6605L48.9545 20.522L48.828 20.6605L47.8734 21.7053L47.7678 21.8209L47.8734 21.9365L51.6916 26.1156L51.8182 26.2542L51.9447 26.1156L60.1266 17.1604L60.2322 17.0448L60.1266 16.9291L59.172 15.8844L59.0455 15.7458L58.9189 15.8844L51.8182 23.6563Z"
      fill="white"
      stroke="white"
      strokeWidth="0.342857"
    />
  </svg>
)
export { EnrolmentOpen }
