/* Profile block */
import styled from 'styled-components'
import { CustomLink } from '../../Text'

export const ProfileInfo = styled(CustomLink)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  width: 200px;

  padding: ${props => props.theme.leftSidePadding};
  > span {
    color: ${props => (props.$active ? props.theme.textColors.active : `#414752`)};
    font-weight: 800;
  }
  > span:hover {
    color: ${props => props.theme.textColors.active};
    font-weight: 800;
    opacity: 0.5;
  }
`

export const ProfileImg = styled.img<{ size: 'small' | 'large' }>`
  width: ${props =>
    props.size === 'small'
      ? props.theme.profile.smallImageSize
      : props.theme.profile.largeImageSize}px;
  height: ${props =>
    props.size === 'small'
      ? props.theme.profile.smallImageSize
      : props.theme.profile.largeImageSize}px;
  border-radius: ${props => props.theme.profile.imageBorderRadius};
  object-fit: cover;
  margin-right: 20px;
`
