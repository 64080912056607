import React, { useState, useMemo, useCallback } from 'react'

const Tabs = ({ tabs, activeTab }) => {
  const [currentTab, setCurrentTab] = useState(activeTab === 'last' ? tabs[tabs.length - 1].index : 0)

  const handleChangeTab = useCallback(e => {
    e.target.getAttribute('data-index')!==null &&
      setCurrentTab(+e.target.getAttribute('data-index'))
  }, [])

  const mockTabs = useMemo(
    () =>
      tabs.map(({ name, index, onClick }) => (
        <li
          className={`tab ${currentTab === index ? 'active' : ''}`}
          onClick={(e) => {
            handleChangeTab(e);
            onClick && onClick();
          }}
          key={index}
        >
          <span data-index={index}>{name}</span>
          {currentTab === index && <div className="tab-indicator" />}
        </li>
      )),
    [currentTab, tabs, handleChangeTab]
  )

  return (
    <>
      <div className="tabs">
        <ul className="tab-list">{mockTabs}</ul>
      </div>
      <div className="tab-view">{tabs[currentTab].component}</div>
    </>
  )
}

export default Tabs
