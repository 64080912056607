import * as React from 'react'

const GooglePlayIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0ZM11.647 28.3729C11.5517 28.4249 11.4592 28.4668 11.3712 28.5L21.3044 18.4484L24.3036 21.4446L11.647 28.3729ZM21.7347 18.0123L24.8653 21.1385L29.1684 18.7819C29.6868 18.4989 30.2297 17.7755 29.1684 17.1964L24.8624 14.847L21.7347 18.0123ZM11.6484 7.63862L24.3021 14.5424L21.3015 17.5805L11.2065 7.5C11.3596 7.52022 11.5127 7.56498 11.6484 7.63862ZM20.8712 18.0152L10.4643 7.62418C10.164 7.80179 9.95027 8.15991 9.95315 8.74905C9.95893 9.97501 10.0412 26.3225 10.047 27.352C10.0485 27.8892 10.2116 28.3051 10.5134 28.4971L20.8712 18.0152Z"
        fill="#9ABDFC"
      />
    </svg>
  )
}

export default GooglePlayIcon
