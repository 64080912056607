import React from 'react'

const UserIcon = () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M24 20V18C24 15.7909 22.2091 14 20 14H19.5M16 10C18.2091 10 20 8.20914 20 6C20 3.79086 18.2091 2 16 2M18 20V18C18 15.7909 16.2091 14 14 14H6C3.79086 14 2 15.7909 2 18V20M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
          stroke="white"
          strokeWidth="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default UserIcon
